import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import Urls from "../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import { Lead } from "../Vehicle_Sales/Lead";
import { Booking_M3 } from "../Vehicle_Sales/Booking_M3";
import { Enquiry_details } from "../Vehicle_Sales/Enquiry_details";
import { Money_reciept } from "../Vehicle_Sales/Money_reciept";
import { Vehical_billing } from "../Vehicle_Sales/Vehical_billing";
const $ = window.$;
export class Sales_DB extends React.Component {
  constructor(props) {
    console.log("props",props)
    super(props);
    this.state = {
      sqlfor: props.location.state && props.location.state.data[0] && props.location.state.data[0].pendind_data ? props.location.state.data[0].pendind_data : '',
      dashboard_Click: props.location.state && props.location.state.data[0] && props.location.state.data[0].dashboard_Click ? props.location.state.data[0].dashboard_Click : '',
      title: props.location.state && props.location.state.data[0] && props.location.state.data[0].title ? props.location.state.data[0].title : '',

      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      kwh: "",
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      vistitdetails: [],
      visittest: [],
    };
  }

  componentDidMount() {
    this.visit_details();
    {this.state.dashboard_Click ? this.pendinddata() : this.MasterAPI()};
  }

  pendinddata = (demo) => {
    const url = Urls.python_baseurl + "/SP_VehicleSalesDetails";
    var data = JSON.stringify({
      Dealer_ID: sessionStorage.getItem("dealerid"),
      SqlFor: `'${this.state.sqlfor}'`,
      startdate:"''",
      enddate:"''",
      Dashboard_For:"''"
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          const isNullish = Object.values(res[0][0]).every(value => {
            if (value === null || value === "") {
              return true;
            }
            return false;
          });
          if (isNullish) {
            this.setState({
              rowData: [],
            });
          } else {
            this.setState({
              rowData: res[0],
            });
          }

        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  MasterAPI = (demo) => {
    const url = Urls.python_baseurl + "/AllDetails_sp_alldetails";
    var data = JSON.stringify({
      dealer_id: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  visit_details = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "159",
      ID: "'0'",
      condition: "\"and stage='M0'\"",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res, "jkjkj");
          this.setState({
            vistitdetails: res,
            visittest: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => { });
  };
  visitDetails = () => {
    return (
      <div className=" mt-4">
        <div className=" row justify-content-end ">
          <button
            className="btn Opertion_btn clickable mr-3"
            onClick={() => {
              this.handelSave();
            }}
          >
            Save
          </button>
        </div>
        <div class="row">
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">Visit Details</label>
            <select
              className="form-control"
              value={this.state.visit_details_value}
              onChange={(e) =>
                this.setState({ visit_details_value: e.target.value }, () => {
                  alert(this.state.visit_details_value);
                })
              }
            >
              <option value="0"></option>
              {this.state.visittest.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              className="form-control"
              placeholder=""
              value={this.state.startDate}
              onChange={(e) => this.setState({ startDate: e.target.value })}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="new-visit-details">New Visit Details</label>
            <select
              className="form-control"
              value={this.state.next_visit_details_value}
              onChange={(e) =>
                this.setState({ next_visit_details_value: e.target.value })
              }
            >
              <option value="0"></option>
              {this.state.visittest.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="date2">Date</label>
            <input
              type="date"
              className="form-control"
              placeholder=""
              value={this.state.endDate}
              onChange={(e) => this.setState({ endDate: e.target.value })}
              min={this.state.startDate}
            />
          </div>
        </div>
        <div class="row">
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">Status</label>
            <select
              value={this.state.Status_value}
              onChange={(e) => this.setState({ Status_value: e.target.value })}
              class="form-control"
              placeholder=""
            >
              <option>select</option>
              <option>Open</option>
              <option>Successfully Closed</option>
              <option>Unsuccessfully Closed</option>
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">Remark</label>
            <input
              value={this.state.Remark}
              onChange={(e) => this.setState({ Remark: e.target.value })}
              class="form-control"
              placeholder=""
            ></input>
          </div>
        </div>
      </div>
    );
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  handelClose = () => {
    $("#myModal2").modal("hide");
  };
  PopUp = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(33, 37, 41, 0.8)",
          marginLeft: "-1.5%",
        }}
        class="modal"
        id="myModal2"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" onClick={this.handelClose}>
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.visitDetails()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  MasterTable() {
    let data = this.state.rowData;
    let vs = this.state.vistitdetails;
    if (this.state.vistitdetails != []) {
      data.map((e, index) => {
        data[index]["NextFollowUPName"] = vs.filter((e1) => {
          return e1.ID === data[index].next_obj_id;
        })[0]?.Name;
      });
    }

    console.log(this.state.vistitdetails, "jfkjkjk");
    let columnDefs = []

    if (this.state.sqlfor === "Open") {
      columnDefs = [
        { headerName: "Dealer Name", field: "Dealer_Name", flex: 1,  },
        { headerName: "Dealer Code", field: "DealerCode", flex: 1,  },
        { headerName: "Enquiry No", field: "Inq_no", flex: 1, cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" } },
        { headerName: "Enquiry Date", field: "Inq_date", flex: 1, },
        {
          headerName: "Name",
          field: "name",
          width: "125px"
        },
        { headerName: "Mobile", field: "mobile", width: "120px" },
        { headerName: "Model", field: "model_name", width: "200px" },
        // { headerName: "Type", field: "colour", width: "148px" },
        // { headerName: "Allocate", field: "chassis_no", width: "148px" },

        { headerName: "Quantity", field: "Qty", flex: 1, },
        { headerName: "Balance Quantity", field: "Bal_Qty", flex: 1, },
        {
          headerName: "Booking Status",
          field: "Billing_Status",
          flex: 1,
          cellStyle: (params) => {
            console.log(params);
            return {
              color:
                params.data.booking_status === "Closed"
                  ? "red"
                  : params.data.booking_status === "Open"
                    ? "Green"
                    : params.data.booking_status === "Confirmed"
                      ? "orange"
                      : "",
              fontWeight: "700",
            };
          },
        },
        // {
        //   headerName: "Money Received Status",
        //   field: "Moneyreceive_status",
        //   width: "117px",
        //   cellStyle: (params) => {
        //     console.log(params);
        //     return {
        //       color:
        //         params.data.Moneyreceive_status === "Closed"
        //           ? "red"
        //           : params.data.Moneyreceive_status === "Open"
        //           ? "Green"
        //           : params.data.Moneyreceive_status === "Confirmed"
        //           ? "orange"
        //           : "",
        //       fontWeight: "700",
        //     };
        //   },
        // },

        // {
        //   headerName: "Billing",
        //   field: "Billing_status",
        //   width: "116px",
        //   cellStyle: (params) => {
        //     console.log(params);
        //     return {
        //       color:
        //         params.data.Billing_status === "Closed"
        //           ? "red"
        //           : params.data.Billing_status === "Open"
        //           ? "Green"
        //           : params.data.Billing_status === "Confirmed"
        //           ? "orange"
        //           : "",
        //       fontWeight: "700",
        //     };
        //   },
        // },
      ];
    } else if (this.state.sqlfor === "Pendingformoneyreceipt") {
      columnDefs = [
        { headerName: "Dealer Name", field: "Dealer_Name", flex: 1,  },
        { headerName: "Dealer Code", field: "DealerCode", flex: 1,  },
        {
          headerName: "Vehicle Booking No",
          field: "Booking_No",
          flex: 1,
          cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        },
        { headerName: "Vehicle Booking Date", field: "Booking_Date", flex: 1 },
        { headerName: "Mobile", field: "mobile", flex: 1 },
        { headerName: "Name", field: "name", flex: 1 },
        { headerName: "Quantity", field: "Qty", flex: 1, },
        { headerName: "Balance Quantity", field: "Bal_Qty", flex: 1, },
      

        // {
        //   headerName: "Name",
        //   field: "name",
        //   width: "125px"
        // },
        // { headerName: "Mobile", field: "mobile", width: "120px" },
        // { headerName: "Model", field: "model_name", width: "200px" },
        // // { headerName: "Type", field: "colour", width: "148px" },
        // // { headerName: "Allocate", field: "chassis_no", width: "148px" },
        // { headerName: "Enquiry No", field: "Inq_no", width: "120px" },
        // { headerName: "Enquiry On", field: "Inq_date", width: "120px" },
        // { headerName: "Quantity", field: "Qty", flex: 1, },
        // { headerName: "Balance Quantity", field: "Bal_Qty", flex: 1, },
        // {
        //   headerName: "Booking Status",
        //   field: "Billing_Status",
        //   flex: 1,
        //   cellStyle: (params) => {
        //     console.log(params);
        //     return {
        //       color:
        //         params.data.booking_status === "Closed"
        //           ? "red"
        //           : params.data.booking_status === "Open"
        //             ? "Green"
        //             : params.data.booking_status === "Confirmed"
        //               ? "orange"
        //               : "",
        //       fontWeight: "700",
        //     };
        //   },
        // },
        // {
        //   headerName: "Money Received Status",
        //   field: "Moneyreceive_status",
        //   flex: 1,
        //   cellStyle: (params) => {
        //     console.log(params);
        //     return {
        //       color:
        //         params.data.Moneyreceive_status === "Closed"
        //           ? "red"
        //           : params.data.Moneyreceive_status === "Open"
        //             ? "Green"
        //             : params.data.Moneyreceive_status === "Confirmed"
        //               ? "orange"
        //               : "",
        //       fontWeight: "700",
        //     };
        //   },
        // },

        // {
        //   headerName: "Billing",
        //   field: "Billing_status",
        //   width: "116px",
        //   cellStyle: (params) => {
        //     console.log(params);
        //     return {
        //       color:
        //         params.data.Billing_status === "Closed"
        //           ? "red"
        //           : params.data.Billing_status === "Open"
        //           ? "Green"
        //           : params.data.Billing_status === "Confirmed"
        //           ? "orange"
        //           : "",
        //       fontWeight: "700",
        //     };
        //   },
        // },
      ];
    } else if (this.state.sqlfor === "VehicleBilling") {
      columnDefs = [
        { headerName: "Dealer Name", field: "Dealer_Name", flex: 1,  },
        { headerName: "Dealer Code", field: "DealerCode", flex: 1,  },
        { headerName: "Vehile Invoice No", field: "MoneyReceipt_No", flex:1 },
        { headerName: "Vehile Invoice Date", field: "MoneyReceipt_Date", flex:1 },
        { headerName: "Mobile", field: "mobile", flex:1 },
        {
          headerName: "Name",
          field: "name",
          flex:1
        },
       
        // { headerName: "Model", field: "model_name", flex:1 },
        // { headerName: "Type", field: "colour", width: "148px" },
        // { headerName: "Allocate", field: "chassis_no", width: "148px" },
        { headerName: "Quantity", field: "Qty", flex: 1, },
        { headerName: "Balance Quantity", field: "Bal_Qty", flex: 1, },
      
        {
          headerName: "Booking Status",
          field: "Billing_Status",
          flex:1,
          cellStyle: (params) => {
            console.log(params);
            return {
              color:
                params.data.booking_status === "Closed"
                  ? "red"
                  : params.data.booking_status === "Open"
                    ? "Green"
                    : params.data.booking_status === "Confirmed"
                      ? "orange"
                      : "",
              fontWeight: "700",
            };
          },
        },
        // {
        //   headerName: "Money Received Status",
        //   field: "Moneyreceive_status",
        //   width: "117px",
        //   cellStyle: (params) => {
        //     console.log(params);
        //     return {
        //       color:
        //         params.data.Moneyreceive_status === "Closed"
        //           ? "red"
        //           : params.data.Moneyreceive_status === "Open"
        //             ? "Green"
        //             : params.data.Moneyreceive_status === "Confirmed"
        //               ? "orange"
        //               : "",
        //       fontWeight: "700",
        //     };
        //   },
        // },

        // {
        //   headerName: "Billing",
        //   field: "Billing_status",
        //   width: "116px",
        //   cellStyle: (params) => {
        //     console.log(params);
        //     return {
        //       color:
        //         params.data.Billing_status === "Closed"
        //           ? "red"
        //           : params.data.Billing_status === "Open"
        //             ? "Green"
        //             : params.data.Billing_status === "Confirmed"
        //               ? "orange"
        //               : "",
        //       fontWeight: "700",
        //     };
        //   },
        // },
      ];
    } else if (this.state.showTable === "EndCustomerSale") {
      columnDefs = [
        { headerName: "Dealer Name", field: "Dealer_Name", flex: 1,  },
        { headerName: "Dealer Code", field: "DealerCode", flex: 1,  },
        {
          headerName: "Name",
          field: "name",
          width: "125px"
        },
        { headerName: "Mobile", field: "mobile", width: "120px" },
        { headerName: "Model", field: "model_name", width: "200px" },
        // { headerName: "Type", field: "colour", width: "148px" },
        // { headerName: "Allocate", field: "chassis_no", width: "148px" },
        { headerName: "Enquiry No", field: "Inq_no", width: "120px" },
        { headerName: "Enquiry On", field: "Inq_date", width: "120px" },
        {
          headerName: "Booking Status",
          field: "booking_status",
          width: "117px",
          cellStyle: (params) => {
            console.log(params);
            return {
              color:
                params.data.booking_status === "Closed"
                  ? "red"
                  : params.data.booking_status === "Open"
                    ? "Green"
                    : params.data.booking_status === "Confirmed"
                      ? "orange"
                      : "",
              fontWeight: "700",
            };
          },
        },
        {
          headerName: "Money Received Status",
          field: "Moneyreceive_status",
          width: "117px",
          cellStyle: (params) => {
            console.log(params);
            return {
              color:
                params.data.Moneyreceive_status === "Closed"
                  ? "red"
                  : params.data.Moneyreceive_status === "Open"
                    ? "Green"
                    : params.data.Moneyreceive_status === "Confirmed"
                      ? "orange"
                      : "",
              fontWeight: "700",
            };
          },
        },

        {
          headerName: "Billing",
          field: "Billing_status",
          width: "116px",
          cellStyle: (params) => {
            console.log(params);
            return {
              color:
                params.data.Billing_status === "Closed"
                  ? "red"
                  : params.data.Billing_status === "Open"
                    ? "Green"
                    : params.data.Billing_status === "Confirmed"
                      ? "orange"
                      : "",
              fontWeight: "700",
            };
          },
        },
      ];

    }
    else{
      columnDefs = [
        { headerName: "Dealer Name", field: "Dealer_Name", flex: 1,  },
        { headerName: "Dealer Code", field: "DealerCode", flex: 1,  },
        {
          headerName: "Name",
          field: "name",
          width: "125px"
        },
        { headerName: "Mobile", field: "mobile", width: "120px" },
        { headerName: "Model", field: "model_name", width: "200px" },
        // { headerName: "Type", field: "colour", width: "148px" },
        // { headerName: "Allocate", field: "chassis_no", width: "148px" },
        { headerName: "Enquiry No", field: "Inq_no", width: "120px" },
        { headerName: "Enquiry On", field: "Inq_date", width: "120px" },
        {
          headerName: "Booking Status",
          field: "booking_status",
          width: "117px",
          cellStyle: (params) => {
            console.log(params);
            return {
              color:
                params.data.booking_status === "Closed"
                  ? "red"
                  : params.data.booking_status === "Open"
                    ? "Green"
                    : params.data.booking_status === "Confirmed"
                      ? "orange"
                      : "",
              fontWeight: "700",
            };
          },
        },
        {
          headerName: "Money Received Status",
          field: "Moneyreceive_status",
          width: "117px",
          cellStyle: (params) => {
            console.log(params);
            return {
              color:
                params.data.Moneyreceive_status === "Closed"
                  ? "red"
                  : params.data.Moneyreceive_status === "Open"
                    ? "Green"
                    : params.data.Moneyreceive_status === "Confirmed"
                      ? "orange"
                      : "",
              fontWeight: "700",
            };
          },
        },

        {
          headerName: "Billing",
          field: "Billing_status",
          width: "116px",
          cellStyle: (params) => {
            console.log(params);
            return {
              color:
                params.data.Billing_status === "Closed"
                  ? "red"
                  : params.data.Billing_status === "Open"
                    ? "Green"
                    : params.data.Billing_status === "Confirmed"
                      ? "orange"
                      : "",
              fontWeight: "700",
            };
          },
        },
      ];
    }

    return (
      <div className="">
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={data}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (this.state.sqlfor === "Open") {
                this.setState(
                  {
                    pendingflag: true,
                    pendingEvent3: event.data,
                  },
                  () => {
                    $("#payment_error_modal").modal("hide");
                    $("#payment_error_modal_3").modal("show");
                  }
                );
              }else if(this.state.sqlfor === "Pendingformoneyreceipt"){
                if(event.data.is_confirm==="Y"){
                  this.setState(
                    {
                      pendingflag: true,
                      pendingEvent7: event.data,
                    },
                    () => {
                      $("#payment_error_modal").modal("hide");
                      $("#payment_error_modal_3").modal("show");
                    }
                  );
                }else{
                  this.setState(
                    {
                      pendingflag: true,
                      pendingEvent3: event.data,
                    },
                    () => {
                      $("#payment_error_modal").modal("hide");
                      $("#payment_error_modal_3").modal("show");
                    }
                  );
                }
                
              }
              else if(this.state.sqlfor === "PendingforBilling"){
                this.setState(
                 {
                   pendingflag: true,
                   pendingEvent7: event.data,
                 },
                 () => {
                   $("#payment_error_modal").modal("hide");
                   $("#payment_error_modal_3").modal("show");
                 }
               );
             }
             else if(this.state.sqlfor === "VehicleBilling"){
              this.setState(
                {
                  pendingflag: true,
                  pendingEvent8: event.data,
                },
                () => {
                  $("#payment_error_modal").modal("hide");
                  $("#payment_error_modal_3").modal("show");
                }
              );
           }
              if (event.colDef.headerName == "Action") {
                this.setState({
                  M0_Hdr: event?.data?.M0_Hdr,
                  commit_det: event?.data?.commit_det,
                });
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  displayLead = (id) => {
    const url = Urls.python_baseurl + "/SP_GetM0";
    var data = JSON.stringify({
      iID: id,
      type: "'all'",
      dealer: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      CRMCust: "0",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              LeadDetails: res[0][0],
              flag: true,
            },
            () => {
              $("#payment_error_modal_2").modal("show");
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  followUp = () => {
    return (
      <div>
        <div>
          <div class="">
            <div class="card-body">{this.MasterTable()}</div>
          </div>
        </div>
      </div>
    );
  };
  handelSave = () => {
    const url = Urls.python_baseurl + "/FollowupUpdate_SP_Followup_Update";
    var data = JSON.stringify({
      M0ID: `${this.state.M0_Hdr}`,
      obj_Id: `'${this.state.visit_details_value}'`,
      obj_date: `'${moment(this.state.startDate).format("DD-MM-YYYY")}'`,
      discussion: "''",
      time_spent: "''",
      next_obj_Id: `'${this.state.next_visit_details_value}'`,
      next_date: `'${moment(this.state.endDate).format("DD-MM-YYYY")}'`,
      commit_det: `'${this.state.commit_det}'`,
      remark: `'${this.state.Remark}'`,
      status: `'${this.state.Status_value}'`,
    });
    console.log(data, "hjj");
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          alert("Record Saved Succesfully");
          $("#myModal2").modal("hide");
          this.MasterAPI();
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  dispalyPending = () => {
    return (
      <div class="modal" id="payment_error_modal_3">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => {
                  this.setState({
                    pendingflag: false,
                    pendingEvent: [],
                    Customer_Name: "",
                  });
                }}
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.state.redirect_page === "M1" && (
                <Enquiry_details modelid={"pendingClick"} pendingClick={this.state.pendingEvent} />
              )}
              {this.state.sqlfor === "Open" && (
                <Booking_M3
                  modelid={"pendingClick"} pendingClick3={this.state.pendingEvent3} booking={false}
                ></Booking_M3>
              )}
              {this.state.sqlfor === "Pendingformoneyreceipt" && (
                <Money_reciept
                  modelid={"pendingClick"} pendingClick7={this.state.pendingEvent7}
                ></Money_reciept>
              )}
              {this.state.sqlfor === "VehicleBilling" && (
                <Vehical_billing
                  modelid={"pendingClick"} pendingClick={this.state.pendingEvent8}
                ></Vehical_billing>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <h5 className="heading_text"> {this.state.dashboard_Click ? this.state.title : 'Enquiry'}</h5>
        <div className=" mt-4">{this.followUp()}</div>
        {this.state.pendingflag == true && this.dispalyPending()}
        {/* {this.PopUp()} */}
      </div>
    );
  }
}
