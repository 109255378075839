import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import Strings from "../../../helpers/Strings";
import moment from "moment";
import HiPaaSLogo from "../../components/Images/HiPaaS_logo.png";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

const bcrypt = require("bcryptjs");

export class Chassis_Master extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Chassis_No: "",
      Engine_No: "",
      Model_code: "",
      Model_Name: "",
      Model_Category: "",
      Ins_Date: "",
      Customer_Name: "",
      showTable: true,
      paginationPageSize: 5,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      showerror: "",
      Aggrid_ClaimLineData: "",
      rowData: [],
      showCompanyMaster1: false,
      Sublet: "0.00",
      Lubricant: "0.00",
      Labour: "0.00",
      Part: "0.00",
      Rear_RightInner_Tyre_AXle3_No: "",
      Rear_LeftInner_Tyre_Axle2_No: "",
      Rear_RightOuter_Tyre_Axle3_No: "",
      Rear_LeftInner_Tyre_Axle3_No: "",
      Rear_LeftOuter_Tyre_Axle3_No: "",
      Rear_RightInner_Tyre_Axle2_No: "",
      Rear_RightOuter_Tyre_Axle2_No: "",
      Rear_LeftOuter_Tyre_Axle2_No: "",
      Rear_RightInner_Tyre_Axle1_No: "",
      Front_Tyre_No_RHS: "",
      Front_Tyre_No_LHS: "",
      Tyre_Size_Spare: "",
      Tyre_size_Rear: "",
      Tyre_Size_Front: "",

      Gear_Box_Make: "",
      Gear_Box_No: "",
      Transmission_Make: "",
      Transmission_No: "",
      Rear_Axle3_Make: "",
      Rear_Axle3_No: "",
      Rear_Axle2_Make: "",
      Rear_Axle2_No: "",
      Rear_Axle1_Make: "",
      Rear_Axle1_No: "",
      Addational_Warranty_End_Date: "",
      Front_Axle_Make: "",
      Front_Axle_No: "",
      FIP_No: "",
      Battery_Make: "",
      Battery_No: "",

      RMC_End_HRS: "",
      RMC_Start_HRS: "",
      RMC_End_KM: "",
      RMC_Start_KM: "",
      RMC_End_Date: "",
      RMC_Start_Date: "",
      RMC_Agreement_Type: "",
      RMC_Agreement_No: "",
      Addational_Warranty_End_Date: "",
      In_Addational_Warranty_Start_Date: "",
      Comfort_Plus_End_Date: "",
      comfort_Plus_start_Date: "",
      Aggregate_Warranty_End_Date: "",
      Warranty_End_Date: "",

      Effective_To_Date: "",
      Effective_From_Date: "",
      Service_Name: "",
      Coupon_Code: "",

      Dealer_Code: "",
      HRS: "",
      KMS: "",
      JobcardDate: "",
      Jobcard_No: "",
      SD_Coupon_Code: "",
      SD_Service_Name: "",

      Installation_No: "",
      SAP_Installation_No: "",
      Installation_Date: "",
      SAP_Installation_No: "",
      Customer_Name: "",
      Customer_Name: "",
      Primary_Application: "",
      Secondary_Application: "",
      Road_Type: "",
      Route_Type: "",
      Financier_type: "",
      ID_KMS: "",
      ID_HRS: "",
      couponData: [],
      serviceDetails: [],
      InstallationDetails: [],
      FloatDetails: [],
      OwnershipDetails: [],
    };
  }

  componentDidMount() {
    this.MasterAPICall();
  }

  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "''",
      DealerId: "-1",
      SqlFor: "Chassis",
      HOBrID: "0",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res, "popkkk");
        if (res && res.length > 0) {
          let id = res[0].ID;
          this.setState(
            {
              rowData: res,
            },
            () => {
              this.getChassisDetails(id);
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getChassisDetails = (id) => {
    const url = Urls.python_baseurl + "/ChassisDetails_SP_GetChassisDetails";
    var data = JSON.stringify({
      ID: id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0 && res[0].length > 0) {
          console.log(res[0][0]?.Veh_No, "popkkk");
          this.setState({
            Chassis_No: res[0][0]?.Chassis_no,
            Vehicle_No: res[0][0]?.Veh_No,
            Model_Code: res[0][0]?.model_code,
            Model_Name: res[0][0]?.model_name,
            Model_Group: res[0][0]?.Model_Group,
            Invoice_No: res[0][0]?.Inv_No,
            Invoice_Date: res[0][0]?.Inv_Date,
            Sales_Order_No: res[0][0]?.Sales_Order_No,
            Customer_Name: res[0][0]?.customer_name,
            In_Warranty: res[0][0].in_warranty == "Y" ? "Yes" : "No",
            Ins_Date: moment(res[0][0]?.INS_Date).format("DD-MM-YYYY"),
            Battery_No: res[0][0]?.Battery_no,
            Charger_No: res[0][0]?.Charger_No,
            Motor_No: res[0][0]?.Motor_No,
            Controller_No: res[0][0]?.Control_Unit_No,
            Display_No: res[0][0]?.Display_No,
            IOT_No: res[0][0]?.IOT_No,
            // Change_Model_Code: res[0][0].ChangeModelCode,
            // Change_Model_Name: res[0][0].ChangeModelName,
            // Model_Category: res[0][0].ModelCategory,
            // Coupon_No: res[0][0].CouponNo,
            // Coupon_Blocked: res[0][0].Coupons_block,
            // Direct_Customer: res[0][0].Direct_Customer,
            // Float_Flag: res[0][0].Float_flag,

            // In_KAM: res[0][0].In_KAM,
            // Warranty_End_Date: res[1][0].Warranty_End_Date,
            // couponData: res[2],

            // Battery_Make: res[3][0]?.Battery_Make,
            // Key_No: res[3][0]?.KEYNO,

            // Control_Unit_No: res[3][0]?.Control_Unit_No,
            // FIP_No: res[3][0]?.FIP_No,
            // Front_Axle_No: res[3][0]?.Front_Axle_No,
            // Front_Axle_Make: res[3][0]?.Front_Axle_Make,
            // Rear_Axle_No: res[3][0]?.Rear_Axle_1_No,
            // Rear_Axle_Make: res[3][0]?.Rear_Axle_1_Make,
            // Tyre_Size_Front: res[4][0]?.TYRE_SIZE_FRONT1,
            // Tyre_size_Rear: res[4][0]?.TYRE_SIZE_REAR,
            // Part: res[5][0].PartTotal,
            // Labour: res[5][0].LabourTotal,
            // Lubricant: res[5][0].LubricantTotal,
            // Sublet: res[5][0].SubletTotal,
            // serviceDetails: res[5],
            // InstallationDetails: res[8],
            // FloatDetails: isAllNull ? [] : res[6],
            // OwnershipDetails: isAllNull1 ? [] : res[7],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Chassis No.",
        field: "Chassis No",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Vehicle No.", field: "Vehicle No", flex: "1" },
      // { headerName: "Model Code", field: "Model Code", flex: "1" },
      { headerName: "Model Name", field: "Model Name", flex: "1" },
      // { headerName: "Model Category", field: "Model Category", flex: "1" },
      { headerName: "Customer Name", field: "Customer Name", flex: "1" },
      { headerName: "Ins Date", field: "Ins Date", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (event.colDef.headerName == "Chassis No.") {
                this.getChassisDetails(event.data.ID);
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  Chassis_Header_Details = () => {
    return (
      <div>
        <div className=" row">
          <div className="col-3">
            <div className="quantity_Name">Chassis No.</div>
            <div>{this.state.Chassis_No}</div>
          </div>
          {/* <div className=" col-3">
            <div>Engine No</div>
            <div>{this.state.Engine_No}</div>
          </div> */}
          <div className=" col-3">
            <div className="quantity_Name">Vehicle No.</div>
            <div>{this.state.Vehicle_No}</div>
          </div>
          <div className=" col-3">
            <div className="quantity_Name">Model</div>
            <div>{this.state.Model_Group}</div>
          </div>
          <div className=" col-3">
            <div className="quantity_Name"> Model Code</div>
            <div>{this.state.Model_Code}</div>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <div className="quantity_Name"> Model Name</div>
            <div>{this.state.Model_Name}</div>
          </div>
          <div className=" col-3">
            <div className="quantity_Name">Invoice No.</div>
            <div>{this.state.Invoice_No}</div>
          </div>
          <div className=" col-3">
            <div className="quantity_Name">Invoice Date</div>
            <div>{this.state.Invoice_Date}</div>
          </div>
          <div className=" col-3">
            <div className="quantity_Name">Sales Order No.</div>
            <div>{this.state.Sales_Order_No}</div>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <div className="quantity_Name">Customer Name</div>
            <div>{this.state.Customer_Name}</div>
          </div>
          <div className=" col-3">
            <div className="quantity_Name">In Warranty</div>
            <div>{this.state.In_Warranty}</div>
          </div>
          <div className=" col-3">
            <div className="quantity_Name">Ins Date</div>
            <div>{this.state.Ins_Date}</div>
          </div>

          {/* <div className=" col-3">
            <div>Change Model Code</div>
            <div>{this.state.Change_Model_Code}</div>
          </div> */}
          {/* <div className=" col-3">
            <div>Change Model Name</div>
            <div>{this.state.Change_Model_Name}</div>
          </div>
          <div className=" col-3">
            <div>Model Category</div>
            <div>{this.state.Model_Category}</div>
          </div>
          <div className=" col-3">
            <div>Vehicle Under Observation</div>
            <div>{this.state.Vehicle_Under_Observation}</div>
          </div> */}
        </div>
        {/* <div className=" row mt-2">
          <div className=" col-3">
            <div>Coupon No</div>
            <div>{this.state.Coupon_No}</div>
          </div>
          <div className=" col-3">
            <div>Coupon Blocked</div>
            <div>{this.state.Coupon_Blocked}</div>
          </div>
          <div className=" col-3">
            <div>Direct Customer</div>
            <div>{this.state.Direct_Customer}</div>
          </div>
          <div className=" col-3">
            <div>Float Flag</div>
            <div>{this.state.Float_Flag}</div>
          </div>
        </div> */}

        {/* <div className=" row mt-2">
          <div className=" col-3">
            <div>In RMC</div>
            <div>{this.state.In_RMC}</div>
          </div>
          <div className=" col-3">
            <div>In Aggregate Warranty</div>
            <div>{this.state.In_Aggregate_Warranty}</div>
          </div>

          <div className=" col-3">
            <div>In Warranty</div>
            <div>{this.state.In_Warranty}</div>
          </div>
          <div className=" col-3">
            <div>In KAM</div>
            <div>{this.state.In_KAM}</div>
          </div>
        </div> */}
        {/* <div className=" row mt-2">
          
          <div className=" col-3">
            <div>In Addational Warranty</div>
            <div>{this.state.In_Addational_Warranty}</div>
          </div>
          <div className=" col-3">
            <div>In Comfort Plus</div>
            <div>{this.state.In_Comfort_Plus}</div>
          </div>
        </div> */}
      </div>
    );
  };
  Warranty_RMC_Details = () => {
    return (
      <div style={{ fontSize: "14px" }}>
        <div className=" row">
          <div className=" col-3">
            <div>Warranty End Date</div>
            <div>{this.state.Warranty_End_Date}</div>
          </div>
          {/* <div className=" col-3">
            <div>Aggregate Warranty End Date</div>
            <div>{this.state.Aggregate_Warranty_End_Date}</div>
          </div>
          <div className=" col-3">
            <div>Comfort Plus Start Date</div>
            <div>{this.state.comfort_Plus_start_Date}</div>
          </div>
          <div className=" col-3">
            <div>Comfort Plus End Date</div>
            <div>{this.state.Comfort_Plus_End_Date}</div>
          </div> */}
        </div>
        {/* <div className=" row mt-2">
          <div className=" col-3">
            <div>Addational Warranty Start Date</div>
            <div>{this.state.In_Addational_Warranty_Start_Date}</div>
          </div>
          <div className=" col-3">
            <div>Addational Warranty End Date</div>
            <div>{this.state.Addational_Warranty_End_Date}</div>
          </div>
          <div className=" col-3">
            <div>RMC Agreement No</div>
            <div>{this.state.RMC_Agreement_No}</div>
          </div>
          <div className=" col-3">
            <div>RMC Agreement Type</div>
            <div>{this.state.RMC_Agreement_Type}</div>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <div>RMC Start Date</div>
            <div>{this.state.RMC_Start_Date}</div>
          </div>
          <div className=" col-3">
            <div>RMC End Date</div>
            <div>{this.state.RMC_End_Date}</div>
          </div>
          <div className=" col-3">
            <div>RMC Start KM</div>
            <div>{this.state.RMC_Start_KM}</div>
          </div>
          <div className=" col-3">
            <div>RMC End KM</div>
            <div>{this.state.RMC_End_KM}</div>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <div>RMC Start HRS</div>
            <div>{this.state.RMC_Start_HRS}</div>
          </div>
          <div className=" col-3">
            <div>RMC End HRS</div>
            <div>{this.state.RMC_End_HRS}</div>
          </div>
        </div> */}
      </div>
    );
  };
  Service_Coupon_Details = () => {
    return <div style={{ fontSize: "14px" }}>{this.CouponCodeTable()}</div>;
  };
  Aggregate_Details = () => {
    return (
      <div>
        <div className=" row">
          <div className=" col-3">
            <div className="quantity_Name">Battery No.</div>
            <div>{this.state.Battery_No}</div>
          </div>
          {/* <div className=" col-3">
            <div>Battery Make</div>
            <div>{this.state.Battery_Make}</div>
          </div> */}
          {/* <div className=" col-3">
            <div>Key No</div>
            <div>{this.state.Key_No}</div>
          </div> */}
          <div className=" col-3">
            <div className="quantity_Name">Charger No.</div>
            <div>{this.state.Charger_No}</div>
          </div>
          <div className=" col-3">
            <div className="quantity_Name">Motor No.</div>
            <div>{this.state.Motor_No}</div>
          </div>
          <div className=" col-3">
            <div className="quantity_Name">Controller No.</div>
            <div>{this.state.Controller_No}</div>
          </div>
        </div>
        <div className=" row mt-2">
          {/* <div className=" col-3">
            <div>Control Unit No</div>
            <div>{this.state.Control_Unit_No}</div>
          </div> */}
          <div className=" col-3">
            <div className="quantity_Name">Display No.</div>
            <div>{this.state.Display_No}</div>
          </div>
          <div className=" col-3">
            <div className="quantity_Name">IOT Device ID</div>
            <div>{this.state.IOT_No}</div>
          </div>
          {/* <div className=" col-3">
            <div>FIP No</div>
            <div>{this.state.FIP_No}</div>
          </div> */}
        </div>

        {/* <div className=" row mt-2">
          <div className=" col-3">
            <div>Front Axle No</div>
            <div>{this.state.Front_Axle_No}</div>
          </div>
          <div className=" col-3">
            <div>Front Axle Make</div>
            <div>{this.state.Front_Axle_Make}</div>
          </div>
          <div className=" col-3">
            <div>Addational Warranty End Date</div>
            <div>{this.state.Addational_Warranty_End_Date}</div>
          </div>
          <div className=" col-3">
            <div>Rear Axle No</div>
            <div>{this.state.Rear_Axle_No}</div>
          </div>
          <div className=" col-3">
            <div>Rear Axle Make</div>
            <div>{this.state.Rear_Axle_Make}</div>
          </div>
        </div> */}
        {/* <div className=" row mt-2">
          <div className=" col-3">
            <div>Rear Axle2 No</div>
            <div>{this.state.Rear_Axle2_No}</div>
          </div>
          <div className=" col-3">
            <div>Rear Axle2 Make</div>
            <div>{this.state.Rear_Axle2_Make}</div>
          </div>
          <div className=" col-3">
            <div>Rear Axle3 No</div>
            <div>{this.state.Rear_Axle3_No}</div>
          </div>
          <div className=" col-3">
            <div>Rear Axle3 Make</div>
            <div>{this.state.Rear_Axle3_Make}</div>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <div>Transmission No</div>
            <div>{this.state.Transmission_No}</div>
          </div>
          <div className=" col-3">
            <div>Transmission Make</div>
            <div>{this.state.Transmission_Make}</div>
          </div>
          <div className=" col-3">
            <div>Gear Box No</div>
            <div>{this.state.Gear_Box_No}</div>
          </div>
          <div className=" col-3">
            <div>Gear Box Make</div>
            <div>{this.state.Gear_Box_Make}</div>
          </div>
        </div> */}
      </div>
    );
  };
  Chassis_Tyre_Details = () => {
    return (
      <div style={{ fontSize: "14px" }}>
        <div className=" row">
          <div className=" col-3">
            <div>Tyre Size Front</div>
            <div>{this.state.Tyre_Size_Front}</div>
          </div>
          <div className=" col-3">
            <div>Tyre Size Rear</div>
            <div>{this.state.Tyre_size_Rear}</div>
          </div>
          {/* <div className=" col-3">
            <div>Tyre Size Spare</div>
            <div>{this.state.Tyre_Size_Spare}</div>
          </div>
          <div className=" col-3">
            <div>Front Tyre No LHS</div>
            <div>{this.state.Front_Tyre_No_LHS}</div>
          </div> */}
        </div>
        {/* <div className=" row mt-2">
          <div className=" col-3">
            <div>Front Tyre No RHS</div>
            <div>{this.state.Front_Tyre_No_RHS}</div>
          </div>
          <div className=" col-3">
            <div>Rear RightInner Tyre Axle1 No</div>
            <div>{this.state.Rear_RightInner_Tyre_Axle1_No}</div>
          </div>
          <div className=" col-3">
            <div>Rear LeftOuter Tyre Axle2 No</div>
            <div>{this.state.Rear_LeftOuter_Tyre_Axle2_No}</div>
          </div>
          <div className=" col-3">
            <div>Rear RightOuter Tyre Axle2 No</div>
            <div>{this.state.Rear_RightOuter_Tyre_Axle2_No}</div>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <div>Rear RightInner Tyre Axle2 No</div>
            <div>{this.state.Rear_RightInner_Tyre_Axle2_No}</div>
          </div>
          <div className=" col-3">
            <div>Rear LeftOuter Tyre Axle3 No</div>
            <div>{this.state.Rear_LeftOuter_Tyre_Axle3_No}</div>
          </div>
          <div className=" col-3">
            <div>Rear LeftInner Tyre Axle3 No</div>
            <div>{this.state.Rear_LeftInner_Tyre_Axle3_No}</div>
          </div>
          <div className=" col-3">
            <div>Rear RightOuter Tyre Axle3 No</div>
            <div>{this.state.Rear_RightOuter_Tyre_Axle3_No}</div>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <div>Rear LeftInner Tyre Axle2 No</div>
            <div>{this.state.Rear_LeftInner_Tyre_Axle2_No}</div>
          </div>
          <div className=" col-3">
            <div>Rear RightInner Tyre AXle3 No</div>
            <div>{this.state.Rear_RightInner_Tyre_AXle3_No}</div>
          </div>
        </div> */}
      </div>
    );
  };
  Service_Details = () => {
    return (
      <div style={{ fontSize: "14px" }}>
        <div className=" row">
          <div className=" col-3">
            <label htmlFor="part">Part:</label>
            <input
              value={this.state.Part}
              className=" form-control "
              onChange={(e) => this.setState({ Part: e.target.value })}
              id="part"
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="labour">Labour:</label>
            <input
              value={this.state.Labour}
              className=" form-control "
              onChange={(e) => this.setState({ Labour: e.target.value })}
              id="labour"
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="lubricant">Lubricant:</label>
            <input
              value={this.state.Lubricant}
              className=" form-control "
              onChange={(e) => this.setState({ Lubricant: e.target.value })}
              id="lubricant"
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="sublet">Sublet:</label>
            <input
              value={this.state.Sublet}
              className=" form-control "
              onChange={(e) => this.setState({ Sublet: e.target.value })}
              id="sublet"
            ></input>
          </div>
        </div>
        <div className=" mt-2">
          {this.SeviceDetailsTable()}
          {/* <table class="table shadow mb-2 bg-white rounded">
            <thead>
              <tr>
                <td>Service Name</td>
                <td>Coupon Code</td>
                <td>Jobcard No</td>
                <td>Jobcard Date</td>
                <td>Kms</td>
                <td>HRS</td>
                <td>Dealer Code</td>
              </tr>
            </thead>
            <tbody>
            {
              
              this.state.serviceDetails && this.state.serviceDetails.length>0?this.state.serviceDetails.map((element) => (
              <tr class="">
                <td>{element.Serv_Name}</td>
                <td>{element.coupon_no}</td>
                <td>{element.Jobcard_no}</td>
                <td>{element.Jobcard_date}</td>
                <td>{element.Kms}</td>
                <td>{element.Hrs}</td>
                <td>{element.Dealer_ID}</td>
              </tr>
 )):""
}
            </tbody>
          </table> */}
        </div>
      </div>
    );
  };
  Installation_Details = () => {
    let columnDefs = [
      {
        headerName: "Vehicle Invoice No",
        field: "INS_No",
        flex: "1",
        // cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Vehicle Invoice Date", field: "SAP_INS_No", flex: "1" },
      { headerName: "Vehicle Sale Order No", field: "INS_date", flex: "1" },
      // { headerName: "Customer Name", field: "Customer_name", flex: "1" },
      // { headerName: "Customer Type", field: "CustType", flex: "1" },
      // { headerName: "Primary Application", field: "pri_app_desc", flex: "1" },
      // { headerName: "Secondary Application", field: "sec_app_desc", flex: "1" },
      // { headerName: "Road Type", field: "Road_type", flex: "1" },
      // { headerName: "Route Type", field: "Route_Type", flex: "1" },
      // { headerName: "Financier Type", field: "FinType", flex: "1" },
      // { headerName: "KMS", field: "kms", flex: "1" },
      // { headerName: "Hours", field: "hrs", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.InstallationDetails}
            enableCellTextSelection={true}
            // onCellClicked={(event) =>

            // }
          ></AgGridReact>
        </div>
      </div>
    );
  };
  SeviceDetailsTable() {
    let columnDefs = [
      {
        headerName: "Service Name",
        field: "Serv_Name",
        flex: "1",
      },
      { headerName: "Coupon Code", field: "coupon_no", flex: "1" },
      { headerName: "Jobcard No", field: "Jobcard_no", flex: "1" },
      { headerName: "Jobcard Date", field: "Jobcard_date", flex: "1" },
      { headerName: "Kms", field: "Kms", flex: "1" },
      { headerName: "HRS", field: "Hrs", flex: "1" },
      { headerName: "Dealer Code", field: "Dealer_ID", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.serviceDetails}
            enableCellTextSelection={true}
            // onCellClicked={(event) =>

            // }
          ></AgGridReact>
        </div>
      </div>
    );
  }
  CouponCodeTable() {
    let columnDefs = [
      {
        headerName: "Coupon Code",
        field: "CouponNo",
        flex: "1",
        // cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Service Name", field: "Serv_name", flex: "1" },
      { headerName: "Coupon Amount", field: "LSPSD", flex: "1" },
      { headerName: "Effective From Date", field: "eff_from_date", flex: "1" },
      { headerName: "Effective To Date", field: "Eff_to_date", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.couponData}
            enableCellTextSelection={true}
            // onCellClicked={(event) =>

            // }
          ></AgGridReact>
        </div>
      </div>
    );
  }
  FloatDetailsTable() {
    let columnDefs = [
      {
        headerName: "Jobcard No",
        field: "Jobcard_no",
        flex: "1",
        // cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Jobcard Date", field: "Jobcard_date", flex: "1" },
      { headerName: "GRN No", field: "GRN_No", flex: "1" },
      { headerName: "GRN Date", field: "GRN_date", flex: "1" },
      { headerName: "Parts No", field: "parts_no", flex: "1" },
      {
        headerName: "Dealer Spares Code",
        field: "Dealer_Spares_Code",
        flex: "1",
      },
      { headerName: "Active", field: "Active", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.FloatDetails}
            enableCellTextSelection={true}
            // onCellClicked={(event) =>

            // }
          ></AgGridReact>
        </div>
      </div>
    );
  }
  OwnershipDetailsTable() {
    let columnDefs = [
      {
        headerName: "Customer Name",
        field: "Customer_name",
        flex: "1",
        // cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Address", field: "Address", flex: "1" },
      { headerName: "Phone", field: "Phone1", flex: "1" },
      { headerName: "Sale Invoice No", field: "Sale_Invoice_no", flex: "1" },
      {
        headerName: "Sale Invoice Date",
        field: "Sale_Invoice_date",
        flex: "1",
      },
      { headerName: "INV Amt", field: "INV_Amt", flex: "1" },
      { headerName: "SRN No", field: "SRN_no", flex: "1" },
      { headerName: "SRN Date", field: "SRN_date", flex: "1" },
      { headerName: "Active", field: "active", flex: "1" },
      {
        headerName: "VECV Sale Invoice No",
        field: "VECV_Sale_Invoice_no",
        flex: "1",
      },
      {
        headerName: "VECV Sale Invoice Date",
        field: "VECV_Sale_Invoice_date",
        flex: "1",
      },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.OwnershipDetails}
            enableCellTextSelection={true}
            // onCellClicked={(event) =>

            // }
          ></AgGridReact>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <h4 className="heading_text">Chassis Master</h4>
        <div class="card mt-4">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn collapsed"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Chassis List
              </button>
            </h5>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
          >
            <div class="card-body">{this.masterTable()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                Chassis Header Details{" "}
                {(this.state.Chassis_No ? "- " : "") + this.state.Chassis_No}
              </button>
            </h5>
          </div>
          <div
            id="collapseThree"
            class="collapse show "
            aria-labelledby="headingThree"
          >
            <div class="card-body">{this.Chassis_Header_Details()}</div>
          </div>
        </div>
        {/* <div class="card">
          <div class="card-header" id="headingFour">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                Warranty Details {"-" + this.state.Chassis_No}
              </button>
            </h5>
          </div>
          <div
            id="collapseFour"
            class="collapse show "
            aria-labelledby="headingFour"
          >
            <div class="card-body">{this.Warranty_RMC_Details()}</div>
          </div>
        </div> */}
        {/* <div class="card">
          <div class="card-header" id="headingFive">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#CollapseFive"
                aria-expanded="true"
                aria-controls="CollapseFive"
              >
                Service Coupon Details {"-" + this.state.Chassis_No}
              </button>
            </h5>
          </div>
          <div
            id="CollapseFive"
            class="collapse show "
            aria-labelledby="headingFive"
          >
            <div class="card-body">{this.Service_Coupon_Details()}</div>
          </div>
        </div> */}
        <div class="card">
          <div class="card-header" id="headingSix">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#CollapseSix"
                aria-expanded="true"
                aria-controls="CollapseSix"
              >
                Aggregate Details{" "}
                {(this.state.Chassis_No ? "- " : "") + this.state.Chassis_No}
              </button>
            </h5>
          </div>
          <div
            id="CollapseSix"
            class="collapse show "
            aria-labelledby="headingSix"
          >
            <div class="card-body">{this.Aggregate_Details()}</div>
          </div>
        </div>
        {/* <div class="card">
          <div class="card-header" id="headin11">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#Collapse11"
                aria-expanded="true"
                aria-controls="Collapse11"
              >
                Chassis Tyre Details {"-" + this.state.Chassis_No}
              </button>
            </h5>
          </div>
          <div
            id="Collapse11"
            class="collapse show "
            aria-labelledby="headin11"
          >
            <div class="card-body">{this.Chassis_Tyre_Details()}</div>
          </div>
        </div> */}
        {/* <div class="card">
          <div class="card-header" id="headingSeven">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#CollapseSeven"
                aria-expanded="true"
                aria-controls="CollapseSeven"
              >
                Service Details
              </button>
            </h5>
          </div>
          <div
            id="CollapseSeven"
            class="collapse show "
            aria-labelledby="headingSeven"
          >
            <div class="card-body">{this.Service_Details()}</div>
          </div>
        </div> */}
        {/* <div class="card">
          <div class="card-header" id="headingEight">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#CollapseEight"
                aria-expanded="true"
                aria-controls="CollapseEight"
              >
                Float Details
              </button>
            </h5>
          </div>
          <div
            id="CollapseEight"
            class="collapse show "
            aria-labelledby="headingEight"
          >
            <div class="card-body">
              {this.state.FloatDetails&&this.state.FloatDetails.length>0?this.FloatDetailsTable():null}
              </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingNine">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#CollapseNine"
                aria-expanded="true"
                aria-controls="CollapseNine"
              >
                Ownership Details
              </button>
            </h5>
          </div>
          <div
            id="CollapseNine"
            class="collapse show "
            aria-labelledby="headingNine"
          >
            <div class="card-body">
              {this.state.OwnershipDetails&& this.state.OwnershipDetails.length>0?this.OwnershipDetailsTable():null}
              </div>
          </div>
        </div> */}
        {/* <div class="card">
          <div class="card-header" id="headingTen">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#CollapseTen"
                aria-expanded="true"
                aria-controls="CollapseTen"
              >
                Installation Details
              </button>
            </h5>
          </div>
          <div
            id="CollapseTen"
            class="collapse show "
            aria-labelledby="headingTen"
          >
            <div class="card-body">{this.Installation_Details()}</div>
          </div>
        </div> */}
      </div>
    );
  }
}
