import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import moment from "moment";
const $ = window.$;
const bcrypt = require("bcryptjs");

export class Lubricant_Price extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTable: true,
            paginationPageSize: 10,
            domLayout: "autoHeight",
            autoGroupColumnDef: {
                headerName: "Group",
                minWidth: 170,
                field: "athlete",
                valueGetter: function (params) {
                    if (params.node.group) {
                        return params.node.key;
                    } else {
                        return params.data[params.colDef.field];
                    }
                },
                headerCheckboxSelection: true,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true },
            },
            defaultColDef: {
                cellClass: "cell-wrap-text",
                autoHeight: true,
                sortable: true,
                resizable: true,
                filter: true,
            },
            rowSelection: "never",
            rowGroupPanelShow: "never",
            pivotPanelShow: "never",
            rowData: [],
            showerror: "",
            Aggrid_ClaimLineData: "",
            dealer_name_option: [],
            Labor_Code: "",
            Labor_Name: "",
            ModelGroup: "",
            LaborGroup: "",
            From_date: "",
            To_date: "",
            Hours: "",
            showsecondTable: false,
        };
    }
    componentDidMount() {
        this.MasterAPICall();

    }

    MasterAPICall = () => {
        const url = Urls.python_baseurl + "/SP_GetLubricantPartRate";
        var data = JSON.stringify({
            "DealerID": `'${sessionStorage.getItem('dealerid')}'`
        });
        fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                if (res && res.length > 0) {
                    // res=res[0]
                    this.setState({
                        rowData: res,
                        LubricantCode: res[0]["LubricantCode"],
                        Lubricant_Name: res[0]["Lubricant Name"],
                        Unit: res[0].Unit,
                        Eff_From_Date: res[0]["Eff_From_Date"],
                        Eff_To_Date: res[0].Eff_To_Date,
                        MRP: res[0].MRP,
                        LIST_Price: res[0].LIST_Price,
                        HSN_Code: res[0].HSN_Code,
                        GSTTaxPer: res[0].GSTTaxPer,
                        NDP: res[0].NDP,
                        PackSize: res[0].PackSize,
                        LIST_Price: res[0].LIST_Price,
                        DCS_Update_Date:res[0].DCS_Update_Date

                    });
                }
            })
            .catch((err) => {
                process.env.NODE_ENV == "development" && console.log(err);
            });
    };
    Master2APICall = () => {
        const url = Urls.python_baseurl + "/SP_GetPartPrice";
        var data = JSON.stringify({
            "Dealer_ID": `${sessionStorage.getItem('dealerid')}`,
            "GroupCode": `'02'`,
            "Part_ID": `${this.state.id}`,
            "PartRateFor": `'All'`
        });
        fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                if (res && res.length > 0) {
                    // res=res[0]
                    this.setState({
                        rowData1: res,

                    });
                }
            })
            .catch((err) => {
                process.env.NODE_ENV == "development" && console.log(err);
            });
    };

    masterTable() {
        let columnDefs = [
            { headerName: "Lubricant Code", field: "LubricantCode", width: "130px", cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" }, },
            {
                headerName: "Lubricant Name",
                field: "Lubricant Name",
                width: "150px",

            },
            { headerName: "Unit", field: "Unit", width: 100 },
            { headerName: "Eff From Date", field: "Eff_From_Date", width: 120 },
            { headerName: "Eff To Date", field: "Eff_To_Date", width: 110 },
            { headerName: "HSN Code", field: "HSN_Code", width: 110 },
            { headerName: "GST %", field: "GSTTaxPer", width: 100 },
            { headerName: "DLP", field: "NDP", width: 100 },
            { headerName: "Last Price", field: "LIST_Price", width: 100 },
            { headerName: "MRP", field: "MRP", width: 100, cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" } },
            { headerName: "Pack Size", field: "PackSize", width: 110 },



        ];

        return (
            <div>
                <div className="ag-theme-balham" style={{ padding: "0" }}>
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnDefs}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        defaultColDef={this.state.defaultColDef}
                        suppressRowClickSelection={true}
                        groupSelectsChildren={true}
                        debug={true}
                        rowSelection={this.state.rowSelection}
                        rowGroupPanelShow={this.state.rowGroupPanelShow}
                        pivotPanelShow={this.state.pivotPanelShow}
                        enableRangeSelection={true}
                        paginationAutoPageSize={false}
                        pagination={true}
                        domLayout={this.state.domLayout}
                        paginationPageSize={this.state.paginationPageSize}
                        onGridReady={this.onGridReady}
                        rowData={this.state.rowData}
                        enableCellTextSelection={true}
                        onCellClicked={(event) => {
                            if (event.colDef.field == "Labor Code") {
                                this.setState({
                                    LubricantCode: event.data["LubricantCode"],
                                    Lubricant_Name: event.data["Lubricant Name"],
                                    Unit: event.data.Unit,
                                    Eff_From_Date: event.data["Eff_From_Date"],
                                    Eff_To_Date: event.data.Eff_To_Date,
                                    MRP: event.data.MRP,
                                    LIST_Price: event.data.LIST_Price,
                                    HSN_Code: event.data.HSN_Code,
                                    GSTTaxPer: event.data.GSTTaxPer,
                                    NDP: event.data.NDP,
                                    PackSize: event.data.PackSize,
                                    LIST_Price: event.data.LIST_Price,
                                    DCS_Update_Date:event.data.DCS_Update_Date,
                                    id: event.data.ID,
                                    showsecondTable: false
                                })
                            }
                            if (event.colDef.headerName == "MRP") {
                                this.setState({
                                    showsecondTable: true,
                                    id: event.data.ID,
                                },()=>{
                                    this.Master2APICall()
                                })
                            }
                        }
                        }
                    ></AgGridReact>
                </div>
            </div>
        );
    }

    masterTable2() {
        let columnDefs = [
            { headerName: "Part No.", field: "Part_No", width: "130px", cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" }, },
            {
                headerName: "Part Name",
                field: "Part_Name",
                width: "250px",

            },
            { headerName: "Eff From Date", field: "EffectiveFromDate", flex: 1 },
            { headerName: "Eff To Date", field: "EffectiveToDate", flex: 1 },
            { headerName: "List", field: "LIST", flex: 1 },
            { headerName: "MRP", field: "MRP", flex: 1 },
            { headerName: "DLP", field: "NDP", flex: 1 },


        ];

        return (
            <div>
                <div className="ag-theme-balham" style={{ padding: "0" }}>
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnDefs}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        defaultColDef={this.state.defaultColDef}
                        suppressRowClickSelection={true}
                        groupSelectsChildren={true}
                        debug={true}
                        rowSelection={this.state.rowSelection}
                        rowGroupPanelShow={this.state.rowGroupPanelShow}
                        pivotPanelShow={this.state.pivotPanelShow}
                        enableRangeSelection={true}
                        paginationAutoPageSize={false}
                        pagination={true}
                        domLayout={this.state.domLayout}
                        paginationPageSize={this.state.paginationPageSize}
                        onGridReady={this.onGridReady}
                        rowData={this.state.rowData1}
                        enableCellTextSelection={true}

                    ></AgGridReact>
                </div>
            </div>
        );
    }

    header = () => {
        return (
            <div className="row">
                <div className=" col-3 mt-3">
                    <label>Lubricant Code</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ LubricantCode: e.target.value })}
                        value={this.state.LubricantCode}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label>Lubricant Name</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ Lubricant_Name: e.target.value })}
                        value={this.state.Lubricant_Name}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label>Unit</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ Unit: e.target.value })}
                        value={this.state.Unit}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label>Eff From Date</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ Eff_From_Date: e.target.value })}
                        value={this.state.Eff_From_Date}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label>Eff To Date</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ Eff_To_Date: e.target.value })}
                        value={this.state.Eff_To_Date}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label >HSN Code</label>
                    <input
                        type="text"
                        autoComplete='off'
                        value={this.state.HSN_Code}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label >GST %</label>
                    <input
                        disabled
                        type="text"
                        autoComplete='off'
                        value={this.state.GSTTaxPer}
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label>DLP</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ NDP: e.target.value })}
                        value={this.state.NDP}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label>Last Price</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ LIST_Price: e.target.value })}
                        value={this.state.LIST_Price}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label>MRP</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ MRP: e.target.value })}
                        value={this.state.MRP}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label>Pack Size</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ PackSize: e.target.value })}
                        value={this.state.PackSize?this.state.PackSize:""}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
                <div className=" col-3 mt-3">
                    <label>Update Date</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ DCS_Update_Date: e.target.value })}
                        value={this.state.DCS_Update_Date}
                        disabled
                        className=" form-control"
                    ></input>
                </div>
            </div>

        );
    };


    render() {
        return (
            <div>
                <h4 className="heading_text">Lubricant Price</h4><br></br>
                <div class="row justify-content-end ">
                    {/* <button
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={this.addnew}
          >
            Clear
          </button>
          <button
            type="submit"
            className="btn Opertion_btn clickable "
            onClick={this.Validate}
          >
            Save
          </button> */}
                    {/* <button
            // hidden={sessionStorage.getItem("UserRole") == "10"?false:true}
            // disabled={this.state.double_save}
            type="submit"
            className="btn Opertion_btn clickable mr-5"
            onClick={this.testclickNavigation2}
          >
            Upload File
          </button> */}
                </div>
                <div
                    class="form-group col-md-12 mt-2"
                >

                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button
                                    style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                    class="btn"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Lubricant Price List
                                </button>
                            </h5>
                        </div>

                        <div
                            id="collapseOne"
                            class="collapse"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                        >
                            <div class="card-body">{this.masterTable()}</div>

                        </div>
                    </div>
                    <div class="card" style={{ display: this.state.showsecondTable ? "block" : "none" }}>
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button
                                    style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                    class="btn"
                                    data-toggle="collapse"
                                    data-target="#collapsethree"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Show Lubricant Price History
                                </button>
                            </h5>
                        </div>

                        <div
                            id="collapsethree"
                            class="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                        >
                            <div class="card-body"> {this.state.showsecondTable && this.masterTable2()}</div>

                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button
                                    style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                    class="btn"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                >
                                    Lubricant Price Details
                                </button>
                            </h5>
                        </div>

                        <div
                            id="collapseTwo"
                            class="collapse show"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div class="card-body">{this.header()}</div>
                        </div>
                    </div>

                </div>


            </div>
        );
    }
}
