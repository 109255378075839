import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css"
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
const $ = window.$;
const bcrypt = require("bcryptjs");

export class Supplier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      Type: 0,
      name: "",
      Address_1: "",
      Address_2: "",
      City: "",
      State: "",
      Region: "",
      Country: "",
      Mobile: "",
      Phone: "",
      Email: "",
      PAN_No: "",
      TIN_No: "",
      GSTIN: "",
      double_save:false,
      Active: 0,
      customer_List:[],
      customerType:[],
      rowData: [],
      customerRegion:[],
      customerdistrict:[],
      customerstate:[],
      customerTahsil:[],
      customerdistrict:[],
      customerTahsil:[],
      deliver_Taluka_Option:[],
      Mobileno:"",
      iid: 0,
      typeid:0,
      newcustomer:"",
      Countryid: 1,
      dealar_id:"",
      fin_year: sessionStorage.getItem("fin_year"),
      Dealer_Code: sessionStorage.getItem("Dealer_Code"),
      Type_Dealer_ID:0
    };
  }
  componentDidMount() {
     this.MasterAPICall()
     this.customer_type()
     this.customer_state()
  }
  onSelect = (event, name) => {
    this.setState({
      Active: event.target.value
    })
  }
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "''",
      DealerId: sessionStorage.getItem("dealerid"),
      SqlFor: "'SupplierMaster'",
      HOBrID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          })
        }

      })
      .catch(err => {
        process.env.NODE_ENV == 'development' && console.log(err)
      });
  }
  binddata = (key, res) => {
    this.lead_region();
    this.lead_district(key, res);
  };
  customer_Tahsil = (key) => {
    const url = Urls.python_baseurl + "/Display_Tehsil_sp_tehsildisplay";
    var data = JSON.stringify({
      state_name: this.state.stateid,
      district_name: this.state.district_name,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (key == "District") {
            this.setState({
              customerTahsil: res,
            });
          } else {
            this.setState({
              deliver_Taluka_Option: res,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  customer_state = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: "0",
      type: "'State'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res6587", res);
          this.setState({
            customerstate: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      { headerName: "Supplier Code", field: "Supplier Code", flex: "1", cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" } },
      { headerName: "Name", field: "Name", flex: "1", },
      { headerName: "City", field: "City", flex: "1", },
      { headerName: "State", field: "State", flex: "1", },
      { headerName: "Active", field: "Active", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) =>{
                if (event.colDef.field == "Supplier Code") {
                  console.log("dfsd",event.data.ID);
                  this.setState(
                    {
                      id: event.data.Id,
                    },
                    () => {
                      this.supplier_display();
                    }
                  );
              }}
            }
          ></AgGridReact>
        </div>
      </div>
    );
  }
  supplier_display = () => {
    const url = Urls.python_baseurl + "/SP_GetSupplier";
    var data = JSON.stringify({
      ID: this.state.id,
      // DealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res6576", res);
          if (res[0].state_id !== 0) {
            let result = [
              {
                val: res[0].state_id,
                key: "State",
                tahsilkey: "District",
                districtid: res[0].district,
              },
              {
                val: res[0].DeliveryState,
                key: "Delivery_State",
                tahsilkey: "Delivery_District",
                districtid: res[0].deliverydistrict,
              },
            ];
            console.log(result,"sjbdbhs")
            for (let i = 0; i < result.length; i++) {
              this.setState(
                {
                  stateid: result[i].val,
                  district_name: result[i].districtid,
                },
                () => {
                  // this.get_District(result[i], res);
                  // this.Get_Tahsil(result[i], res);
                  this.setState(
                    {
                      iid:res[0]?.Id,
                      Type: res[0].Sup_Type,
                       typeid:0,
                      name: res[0].Supplier_name,
                      Type_Dealer_ID:res[0].Type_Dealer_ID,
                      newcustomer:res[0].Supplier_name,
                      Address_1: res[0].add1,
                      Address_2: res[0].add2,
                      //address3: res[0].add3,
                      City: res[0].City,
                      pincode: res[0].pincode,
                      State: res[0].state_id,
                      stateid: res[0].state_id,
                      Region: res[0].Region_ID,
                      Country: "INDIA",
                      // Mobileno: res[0].Mobile,
                      Mobileno: res[0].phone2,
                      Phone:res[0].phone1,
                      Email: res[0].E_mail,
                      contact_person: res[0].Contact_Person,
                      contact_person_phone: res[0].Contact_Person_Phone,
                      PAN_No: res[0].PANNo,
                      TIN_No: res[0].TINNo,
                      Active: res[0].Active,
                      GSTIN: res[0].GST_IN,
                       Tahsil: parseInt(res[0].Tehsil),
                       District: res[0].District!=null?parseInt(res[0].District):parseInt(res[0].District),
                       
                    },
                    () => {
                      this.customer_type();
                      this.customer_region();
                      this.Get_Tahsil();
                      this.lead_region();
                      this.lead_district("State", res);
                    }
                  );
                }
              );
            }
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  addnew = () => {

    this.setState({
      Type_Dealer_ID:0,
      iid:0,
      Type: "",
          name: "",
          address1: "",
          address2: "",
          address3: "",
          City: "",
          pincode: "",
          State: "",
           stateid: "",
         Region: "",
          Country: "",
          // Mobileno: res[0].Mobile,
          Phone: "",
          Email: "",
          contact_person: "",
          contact_person_phone: "",
          pan_no: "",
          tinno: "",
          cst: "",
          st_vat: "",
          lbt: "",
          Active: "",
          GS_tinno: "",
          customer_code: "",
          District:"",
          Tahsil:"",
          typeid:0,GSTIN:"",
          Mobileno:"",TIN_No:"", PAN_No:"",Address_1:"",Address_2:""
    })
    // $('#payment_error_modal').modal('show')
  }
  tab() {
    return (
      <div style={{ marginLeft: '2px' }}>
        <nav>

          <button type="button" class="close close-btn" style={{ marginTop: '-2%' }} data-dismiss="modal" aria-label="Close" onClick={() => {
            this.close()
          }}><span class="" aria-hidden="true">&times;</span></button>
        </nav>

      </div>
    )
  }
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  close = () => {
    this.setState({
      Type: "",
      Name: "",
      Address_1: "",
      Address_2: "",
      City: "",
      State: "",
      Region: "",
      Country: "",
      Mobile: "",
      Phone: "",
      Email: "",
      PAN_No: "",
      TIN_No: "",
      GSTIN: "",
      Active: "",
    }, () => { $('#payment_error_modal').modal('hide') })

  }
  onChangeName(event, key) {

    this.setState({
      [key]: event.target.value,
    });

  }
  customerstate = (event) => {
    let row = [];
    this.state.customerstate.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  customerdistrict = (event) => {
    let row = [];
    this.state.customerdistrict.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  customerTahsil = (event) => {
    let row = [];
    this.state.customerTahsil.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Town_Name}</option>);
    });
    return row;
  };
  customerregion = (event) => {
    let row = [];
    this.state.customerRegion.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  customer_List = (event) => {
    let row = [];
    this.state.customer_List.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  customer_type = () => {
    const url = Urls.python_baseurl + "/SupplierType_sp_GetCustTypeForSupplier";
    var data = JSON.stringify({
      type: "S",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res);
          this.setState({
            customerType: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  customerType = (event) => {
    let row = [];
    this.state.customerType.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  customerdatabind = (id) => {
    const url = Urls.python_baseurl + "/SP_GetDealer_CustomerSupplier_Details";
    var data = JSON.stringify({
      ID: id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res[0]);
          this.setState({
            
            // type: res[0].Cust_Type,
            // name: res[0].Customer_name,
            Address_1: res[0][0].Dealer_Address_1,
            Address_2: res[0][0].Dealer_Address_2,
            address3: res[0].add3,
            City: res[0][0].Dealer_City,
            Pincode: res[0][0].Pincode,
            State: res[0][0].Dealer_State_ID,
             stateid: res[0][0].Dealer_State_ID,
            // Region: res[0][0].Dealer_Region_ID,
            Country: "INDIA",
            District: res[0][0].dealer_district_id,
            Tahsil:res[0][0].dealer_taluka_id?parseInt(res[0][0].dealer_taluka_id):0,
            Mobileno: res[0][0].Dealer_Mobile,
            Email: res[0][0].Dealer_Email,
            Phone:res[0][0].Dealer_LandLinePhone,
            GSTIN:res[0][0].GST_No,
            contact_person: res[0].Contact_Person,
            contact_person_phone: res[0].Contact_Person_Phone,
            PAN_No: res[0][0].PANNo,
            TIN_No: res[0][0].TIN_No,
            cst: res[0][0].CST,
            st_vat: res[0][0].VAT_No,
            lbt: res[0][0].LBT,
            active: res[0][0].Active,
            DeliveryGS_tinno: res[0][0].GST_No,
            customer_code: res[0][0].Cust_Code,
            Delivery_State: res[0].DeliveryState,
          },()=>{
            this.customer_region();
            this.lead_region();
            this.lead_district("State", res);
             this.Get_Tahsil();
            
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Get_Tahsil = (key, dummy = []) => {
    
    const url = Urls.python_baseurl + "/Display_Tehsil_sp_tehsildisplay";
    var data = JSON.stringify({
      state_name: this.state.stateid,
      district_name: this.state.District,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {

            this.setState({
              customerTahsil: res,
              // Tahsil: dummy&&dummy[0]?.tehsil,
            });
          
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_region = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: this.state.stateid,
      type: "Region",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res[0]);
          this.setState({
            customerRegion: res,
            Region: res[0].ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_district = (key) => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: this.state.stateid,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65787sjjsj8", res);
          if (key == "State") {
            this.setState({
              customerdistrict: res,
            });
          } else {
            this.setState({
              Delivery_Distric_Option: res,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  customer_region = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: this.state.stateid,
      type: "Region",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res);
          this.setState({
            customerRegion: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  ChangeVal(event, key) {
     console.log("typeidid", key, event.target.value);
    if (key === "Type") {
      if(event.target.value!=16 ){
        this.setState({
          type: "",
          name: "",
          Address_1: "",
          Address_2: "",
          address3: "",
          City: "",
          pincode: "",
          State: "",
           stateid: "",
         Region: "",
          Country: "",
          // Mobileno: res[0].Mobile,
          Phone: "",
          Email: "",
          contact_person: "",
          contact_person_phone: "",
          pan_no: "",
          tinno: "",
          cst: "",
          st_vat: "",
          lbt: "",
          Active: 0,
          GS_tinno: "",
          customer_code: "",
          District:"",
          Tahsil:"",
          typeid:0,GSTIN:"",
          Mobileno:"",TIN_No:"", PAN_No:"",Address_1:"",Address_2:""
        })
      } else{
        this.setState({
          typeid: event.target.value,
        });
       }
       
       if(event.target.value!=15 ){
        this.setState({
          type: "",
          name: "",
          Address_1: "",
          Address_2: "",
          address3: "",
          City: "",
          pincode: "",
          State: "",
           stateid: "",
         Region: "",
          Country: "",
          // Mobileno: res[0].Mobile,
          Phone: "",
          Email: "",
          contact_person: "",
          contact_person_phone: "",
          pan_no: "",
          tinno: "",
          cst: "",
          st_vat: "",
          lbt: "",
          Active: 0,
          GS_tinno: "",
          customer_code: "",
          District:"",
          Tahsil:"",
          typeid:0,
          GSTIN:"",
          Mobileno:"",TIN_No:"", PAN_No:""
        })
      } else{
        this.setState({
          typeid: event.target.value,
                });
       }
        
        
       
      
    }
    this.setState({
      [key]:event.target.value,

    });
    if (key === "name") {
      if(event.target.value=== ""){
        this.setState({
          type: "",
          name: "",
          Address_1: "",
          address2: "",
          address3: "",
          City: "",
          pincode: "",
          State: 0,
           stateid: 0,
         Region: "",
          Country: "",
          // Mobileno: res[0].Mobile,
          Phone: "",
          Email: "",
          contact_person: "",
          contact_person_phone: "",
          pan_no: "",
          tinno: "",
          cst: "",
          st_vat: "",
          lbt: "",
          Active: 0,
          GS_tinno: "",
          customer_code: "",
          District:0,
          Tahsil:"",
          GSTIN:"",
          Mobileno:"",TIN_No:"", PAN_No:""
        })
      } else{
        let Customername=''
           Customername = this.state.customer_List.filter((e) => {
            console.log("ID6767",e,event.target.value);
            return e.ID=== parseInt(event.target.value);
          })[0]?.Name;
          if(Customername!=undefined|| Customername!=null){
            this.setState({
              Type_Dealer_ID:event.target.value,
              name: event.target.value,
              newcustomer:Customername,
              dealar_id:event.target.value
            },
            () => {
              this.customerdatabind(this.state.dealar_id)}
              );
          }else{
            this.setState({
              iid: 0,
              name: event.target.value,
              dealar_id:Customername
            },
            () => {
            
    
              // this.customerdatabind(event.target.value)
    
              // console.log("fgfgfg",event.target.value);
              // let Customername=''
              // Customername = this.state.customer_List.filter((e) => {
              //   console.log("ID",e,event.target.value);
              //   return e.Name=== event.target.value;
              // })[0]?.ID;
              // this.setState({
              //   // name:event.target.value,
              //   Customername:Customername
    
              // },()=>{
              //   alert(event.target.value)
              //   // console.log("gdhfdsf",Customername);
    
              // })
    
              
              // this.binddata(key);
            }
          );
          }
        
      }
      
    }

    if (key === "State" || key === "Delivery_State") {
      this.setState(
        {
          stateid: event.target.value,
        },
        () => {
          this.binddata(key);
        }
      );
    }
 
    if (key === "Region") {
      for (let index = 0; index < this.state.customerRegion.length; index++) {
        if (this.state.customerRegion[index].Name === event.target.value) {
          this.setState(
            {
              stateid: this.state.customerRegion[index].StateId,
            },
            () => {}
          );
        }
      }
    }
    console.log("key1", key, event.target.value);

    if (key === "District" || key === "Delivery_District") {
      this.setState(
        {
          district_name: event.target.value,
        },
        () => {
          this.customer_Tahsil(key);
        }
      );
    }
    if(event.target.value==15)
    {
      this.setState({  
          typeid:event.target.value
      })
      var data = JSON.stringify({
        "ID": "15",
        "Type": "'S'",
        "DealerID": sessionStorage.getItem("dealerid")
      });
       this.get_customer_details(data)
    }
    else if(event.target.value==16)
    {this.setState({  
      typeid:event.target.value
  })
      var data = JSON.stringify({
        "ID": "16",
        "Type": "'S'",
        "DealerID": sessionStorage.getItem("dealerid")
        
      });
      this.get_customer_details(data)
    } 
  }
  get_customer_details= (data) => {
    this.setState({customer_List:[]})
    const url = Urls.python_baseurl + "/SP_GetDealer_CustomerSupplier";
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          
            this.setState({
              customer_List: res,
            });
         
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  }
  onChangeMobile_Number = (e) => {
    const { value } = e.target;
    // Regular expression to allow only numbers
    const mobileNumber = value.replace(/\D/g, '').slice(0, 10); // Limit to 10 characters
    this.setState({ Mobileno: mobileNumber });
  };
  person_phone = (e) => {
    const { value } = e.target;
    // Regular expression to allow only numbers
    const mobileNumber = value.replace(/\D/g, '').slice(0, 10); // Limit to 10 characters
    this.setState({ Phone: mobileNumber });
  };
  renderMemberinfo() {

    return (
      <div>
        <div>
          <div class="card">
            <div class="card-header" id="heading2">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapse2"
                  aria-expanded="false"
                  aria-controls="collapse2"
                >
                  Supplier Details
                </button>
              </h2>
            </div>

            <div id="collapse2" class="collapse show" aria-labelledby="heading2" data-parent="#accordionExample">
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label>Type {this.required()}</label>
                    <select
                      className="form-control "
                      value={this.state.Type}
                      onChange={(event) => this.ChangeVal(event, "Type")}
                    >
                      <option value={0}>Select</option>
                      {this.customerType()}
                    </select>
                  </div>
                  {/* <div class="form-group col-md-3">
                    <label>Type<span style={{ color: "red" }}>*</span></label>
                    <input value={this.state.Type} onChange={(e) => this.onChangeName(e, 'Type')} class="form-control" placeholder=""></input>
                  </div> */}
                 { (this.state.typeid==15 || this.state.typeid==16 )?
          <div class=" col-3">
            <label>Name {this.required()}</label>
            <select
              className="form-control "
              value={this.state.name}
              onChange={(event) => this.ChangeVal(event, "name")}
            >
              <option value="0">Select</option>
              {this.customer_List()}
            </select>
          </div>:
          <div className="col-3">
            <label htmlFor="model-c">Name {this.required()}</label>
            <input 
            autoComplete='off'
              onChange={(e) => this.setState({ name: e.target.value })}
              value={this.state.name}
              id="model-c"
              className=" form-control"
              onKeyPress={(e) => {
                const pattern = /[A-Za-z\s]/ ; // Regular expression pattern for alphabetic characters
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault(); // Prevent input if it's not alphabetic
                }
              }}
            ></input>
          </div>}
                  {/* <div class="form-group col-md-3">
                    <label>Name<span style={{ color: "red" }}>*</span></label>
                    <input value={this.state.Name} onChange={(e) => this.onChangeName(e, 'Name')} class="form-control" placeholder=""></input>

                  </div> */}
                  <div class="form-group col-md-3">
                    <label>Address 1 {this.required()}</label>
                    <input autoComplete='off' value={this.state.Address_1} onChange={(e) => this.onChangeName(e, 'Address_1')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Address 2</label>
                    <input autoComplete='off' value={this.state.Address_2} onChange={(e) => this.onChangeName(e, 'Address_2')} class="form-control" placeholder=""></input>
                  </div>
                  
                  <div class="form-group col-md-3">
                    <label>State {this.required()}</label>
                    <select
                      className="form-control "
                      value={this.state.State}
                      onChange={(event) => this.ChangeVal(event, "State")}
                    >
                      <option value="0">Select</option>
                      {this.customerstate()}
                    </select>
                  </div>
                  <div class=" col-3">
                    <label>District</label>
                    <select
                      className="form-control "
                      value={this.state.District}
                      onChange={(event) => this.ChangeVal(event, "District")}
                    >
                      <option value="0">Select</option>
                      {this.customerdistrict()}
                    </select>
                  </div>
                  <div class=" col-3">
                    <label>Tehsil</label>
                    <select
                      className="form-control "
                      value={this.state.Tahsil}
                      onChange={(event) => this.ChangeVal(event, "Tahsil")}
                    >
                      <option value="0">Select</option>
                      {this.customerTahsil()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>City</label>
                    <input autoComplete='off' value={this.state.City} onChange={(e) => this.onChangeName(e, 'City')} class="form-control" placeholder=""
                    onKeyPress={(e) => {
                      const pattern = /[A-Za-z\s]/ // Regular expression pattern for alphabetic characters
                      const inputChar = String.fromCharCode(e.charCode);
                      if (!pattern.test(inputChar)) {
                        e.preventDefault(); // Prevent input if it's not alphabetic
                      }
                    }}></input>
                  </div>
                  {/* <div class="form-group col-md-3">
                    <label>State<span style={{ color: "red" }}>*</span></label>
                    <input value={this.state.State} onChange={(e) => this.onChangeName(e, 'State')} class="form-control" placeholder=""></input>
                  </div> */}
                  <div class=" col-3">
                    <label>Region</label>
                    <select
                      disabled
                      className="form-control "
                      value={this.state.Region}
                      onChange={(event) => this.ChangeVal(event, "Region")}
                    >
                      <option value="0"></option>
                      {this.customerregion()}
                    </select>
                  </div>
                  {/* <div class="form-group col-md-3">
                    <label>Region</label>
                    <input value={this.state.Region} onChange={(e) => this.onChangeName(e, 'Region')} class="form-control" placeholder=""></input>
                  </div> */}
                  <div class="form-group col-md-3">
                    <label>Country</label>
                    <input value={"India"} disabled onChange={(e) => this.onChangeName(e, 'Country')} class="form-control" placeholder=""></input>
                  </div>
                  <div className=" col-3">
            <label htmlFor="model-c">Mobile No.{this.required()}</label>
            <input
            autoComplete='off'
              onChange={(e) => this.onChangeMobile_Number(e)}
              value={this.state.Mobileno}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
                  {/* <div class="form-group col-md-3">
                    <label>Mobileno<span style={{ color: "red" }}>*</span></label>
                    <input value={this.state.Mobileno} onChange={(e) => this.onChangeName(e, 'Mobileno')} class="form-control" placeholder=""></input>
                  </div> */}
                  <div class="form-group col-md-3">
                    <label>Phone</label>
                    <input autoComplete='off' value={this.state.Phone}onChange={(e) => this.person_phone(e)} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Email</label>
                    <input autoComplete='off' value={this.state.Email} onChange={(e) => this.onChangeName(e, 'Email')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>PAN No</label>
                    <input autoComplete='off' value={this.state.PAN_No}  style={{textTransform:"uppercase"}} onChange={(e) => this.onChangeName(e, 'PAN_No')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>TIN No</label>
                    <input autoComplete='off' value={this.state.TIN_No}  style={{textTransform:"uppercase"}} onChange={(e) => this.onChangeName(e, 'TIN_No')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>GSTIN No</label>
                    <input autoComplete='off' value={this.state.GSTIN} style={{textTransform:"uppercase"}} onChange={(e) => this.onChangeName(e, 'GSTIN')} class="form-control" placeholder=""></input>
                  </div>

                  <div className=" col-3">
                    <label htmlFor="active">Active {this.required()}</label>
                    <select className="form-control list-dashboard" id="Active"
                      value={this.state.Active}
                      onChange={(event) => {
                        this.onSelect(event, 'Active')
                      }}>
                      <option selected value={0}>Select</option>
                      <option value="Y">Y</option>
                      <option value="N">N</option>
                    </select>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
  Validate = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emailisvalid=emailRegex.test(this.state.Email)
    let Deliveryemail=emailRegex.test(this.state.Deliveryemail)
    // if(this.state.Deliveryemail &&!Deliveryemail&&this.state.Deliveryemail!=""){
    //   alert("Please Enter Delivary Valid Email Id.")
    //   return
    // }
    // else
    if(this.state.Type==0 || this.state.Type===""){
      alert("Please Enter Valid Type ")
      return
     }else if(this.state.name===""){
      alert("Please Enter Name")
      return
     }else if(this.state.Address_1===""){
      alert("Please Enter Address ")
      return
     }
     else if(this.state.State==="0" || this.state.State==="" || this.state.State===undefined){
      alert("Please Select Valid State ")
      return
     }else if( this.state.Mobileno=="" ){
      alert("Please Enter Valid Mobile No.")
      return
    }else if( this.state.Mobileno.match(/\d/g).length!==10 ){
      alert("Please Enter Valid Mobile No.")
      return
    }else if(this.state.Phone!="" && this.state.Phone!=null){
       if(this.state.Phone.toString().length!=10){
        alert("Please Enter Valid Phone No")
        return
       }
    }
   
    else if(this.state.Active==0 || this.state.Active=="" || this.state.Active===undefined){
      alert("Please Select Active ")
      return
     }else 
    if(!emailisvalid&&this.state.Email!=""){
      alert("Please Enter Valid Email Id.")
      return
    } 
  //   else
  //  if(this.state.pincode!=""){
  //   if(this.state.pincode.toString().length!=6){
  //   alert("Please Enter Valid Pincode")
  //   return
  //   }
  //  }
   {
    this.save_Supplier()
    
   }
  };
  
  save_Supplier = (event) => {
    
    this.setState({
      double_save:true,
    })
    const url = Urls.python_baseurl + "/SP_Save_Supplier";
    var data = JSON.stringify({
      "ID": `${this.state.iid?this.state.iid:0}`,
      "Dealer_ID": sessionStorage.getItem("dealerid"),
      "HOBr_ID": sessionStorage.getItem("dealerid"),
      "Name": this.state.newcustomer?`'${this.state.newcustomer}'`:`'${this.state.name}'`,
      "Address_1":`'${this.state.Address_1?this.state.Address_1:""}'`,
      "Address_2":`'${this.state.Address_2?this.state.Address_2:""}'`,
      "City": `'${this.state.City?this.state.City:""}'`,
      "Region_ID": this.state.Region?`${this.state.Region}`:"0",
      "State_ID":this.state.State?`${this.state.State}`:"0",
      "Country_ID": `${this.state.Countryid}`,
      "Mobile": this.state.Mobileno?`'${this.state.Mobileno}'`:"''",
      "Phone": this.state.Phone?`'${this.state.Phone}'`:"''",
      "Email": this.state.Email?`'${this.state.Email}'`:"''",
      "Sup_Type": this.state.Type?`${this.state.Type}`:"0",
      "PANNo": this.state.PAN_No?`'${this.state.PAN_No}'`:"''",
      "TIN_No": this.state.TIN_No?`'${this.state.TIN_No}'`:"''",
      "CST_No": "''",
      "STVAT_No": "''",
      "Active": this.state.Active?`'${this.state.Active}'`:"''",
      "supplier_code": "''",
      "Type_Dealer_ID":this.state.Type_Dealer_ID?this.state.Type_Dealer_ID:"0",
      "GST_NO": this.state.GSTIN?`'${this.state.GSTIN}'`:"''",
      "SuppLedgerName": "''",
       "District": this.state.District?`'${this.state.District}'`:"''",
       "Tehsil": this.state.Tahsil?`'${this.state.Tahsil}'`:"''",
       "DlrCode": `'${this.state.Dealer_Code}'`,
       "FinYear": `'${sessionStorage.getItem("fin_year")}'`,
      
    });
    console.log("gfdgfd",data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res[0]?.newID) {
          this.setState({
            double_save:false,
          })
          alert("Record Saved Succesfully");
            window.location.reload()
        }
         if(res[0].ID){
          this.setState({
            double_save:false,
          })
          alert("Record Updated Succesfully");
             window.location.reload()
            
        }
         
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Supplier</h4>
        <div class="row justify-content-end ">
          <button type="submit" className="btn Opertion_btn clickable" onClick={this.addnew}>
          Clear
          </button>
          <button 
           disabled={this.state.double_save}
          type="submit" 
          className="btn Opertion_btn clickable mr-5" 
          onClick={this.Validate}>
            Save
          </button>
        </div>
        <div class="form-group col-md-12 mt-2">
        <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Supplier List
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">{this.masterTable()}</div>
            </div>
          </div>
          {this.renderMemberinfo()}
     
        </div>

      </div>
    );
  }
}
