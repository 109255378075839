import React, { useRef, Component, createRef } from "react";
import "../color.css";
import "../Files/files-styles.css";
import Urls from "../../../helpers/Urls";
import Strings from "../../../helpers/Strings";
export class Send_OTP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paginationPageSize: 10,
      domLayout: "autoHeight",
      fhir_obj: "",
      name: "",
      input0: "",
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      isRequestedAgain: false,
      username:
        props.location &&
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].username
          ? props.location.state.data[0].username
          : "",
      fhir_object_List: [],
      click_count: 0,
    };
    this.inputRefs = [
      createRef(),
      createRef(),
      createRef(),
      createRef(),
      createRef(),
      createRef(),
    ];
  }

  submit = () => {
    this.setState({
      click_count:1,
      isRequestedAgain:true
    })
    let get_otp =
      this.state.input0 +
      this.state.input1 +
      this.state.input2 +
      this.state.input3 +
      this.state.input4 +
      this.state.input5;
    fetch(Urls.python_baseurl + "/verify_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache",
        Accept: "application/json",
      },
      body: JSON.stringify({
        login_name: `${this.state.username}`,
        otp: `${get_otp}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status == true) {
          alert(res.message)
          let sendData = [];
          sendData = [
            {
              username: this.state.username,
            },
          ];
          this.props.history.push("/"+Strings.Reset_Password, {
            data: sendData,
          });
        } else {
          alert("Please Enter Correct OTP!");
          window.location.reload();
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        this.setState({isRequestedAgain:true})
      });
  };
  handleInputChange = (event, currentIndex) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
    const inputValue = event.target.value;

    if (inputValue.length === 1 && currentIndex < this.inputRefs.length - 1) {
      this.inputRefs[currentIndex + 1].current.focus();
    } else if (
      event.key === "Backspace" &&
      event.target.value === "" &&
      currentIndex > 0
    ) {
      event.preventDefault();

      this.inputRefs[currentIndex - 1].current.focus();
    }
  };
  sendotp = async () => {
    this.setState({isRequestedAgain:true})
    fetch(Urls.python_baseurl + "/send_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache",
        Accept: "application/json",
      },
      body: JSON.stringify({
        login_name: `${this.state.username}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status == false) {
          alert(res.message)
        } else {
          alert("OTP Resend SucessFully!");
        }
      }).finally(()=>{
        this.setState({isRequestedAgain:false})
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid bg-body-tertiary d-block ">
          <div
            className=" row justify-content-end "
            style={{ backgroundColor: "var(--main-bg-color)" }}
          >
            <img
              src={require("../../components/Images/Joy_connect_Logo.jpg")}
              alt="logo"
              align="center"
              style={{
                backgroundColor: "var(--main-bg-color)",
                width: "120px",
                marginRight: "20px",
              }}
            />
          </div>
          <div className="row justify-content-center">
            <div className="col-10 mt-3  col-md-6">
              {this.state.isRequestedAgain == true ? (
                <div
                  style={{
                    marginLeft: "45%",
                    position: "absolute",
                    marginTop: "30%",
                    zIndex: "30",
                  }}
                  class="spinner-border text-muted"
                ></div>
              ) : (
                ""
              )}
              <div
                className="card bg-white mb-5 p-5  mt-5 border-0"
                style={{
                  boxShadow: "0 0px 15px rgba(0.1, 0.1, 0.1, .1)",
                  opacity: this.state.isRequestedAgain == true ? "0.4" : "",
                  zIndex: this.state.isRequestedAgain ? 10 : "",
                }}
              >
                <div className="card-body  text-center">
                  <h2 className="heading">Check your email for a code</h2>
                  <p className="message">
                    We’ve sent a 6-digit code to {this.state.email}. The code
                    expires shortly, so please enter it soon.
                  </p>

                  <div className="otp-field mb-4 mt-4">
                    {this.inputRefs.map((inputRef, index) => (
                      <input
                        key={index}
                        name={"input" + index}
                        type="text"
                        ref={inputRef}
                        style={{ fontSize: "20px", width: "40px" }}
                        maxLength="1"
                        onKeyUp={(e) => this.handleInputChange(e, index)}
                      />
                    ))}
                  </div>

                  {this.state.input0 &&
                  this.state.input1 &&
                  this.state.input2 &&
                  this.state.input3 &&
                  this.state.input4 &&
                  this.state.input5 &&
                  !this.state.click_count ? (
                    <button className="btn Opertion_btn mb-3" onClick={this.submit}>
                      Verify
                    </button>
                  ) : (
                    <button disabled className="btn Opertion_btn mb-3">Verify</button>
                  )}
                  <p className="resend text-muted mb-0 message">
                    Didn't receive code?{" "}
                    <a
                      onClick={this.sendotp}
                      style={{
                        color: "var(--main-bg-color)",
                        cursor: "pointer",
                      }}
                    >
                      Request again
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
