import React, { useState, useEffect } from "react";
import Urls from "../../../helpers/Urls";

const Help = () => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoList, setVideoList] = useState([]);
  const MasterAPICall = () => {
    const url = Urls.python_baseurl + "/sp_get_trainingVideo_url"; // Adjust the URL as necessary
    const data = JSON.stringify({});

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.data) {
          setVideoList(res.data);
          setVideoUrl(res.data[0]);
        }
      })
      .catch((err) => {
        console.error("Error fetching video URL:", err);
      });
  };
  const truncateText = (text, wordLimit) => {
    let words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };
  const handelPlayListVideoClick = (e) => {
    setVideoUrl(null);
    setTimeout(() => {
      setVideoUrl(e);
    }, 0);
  };
  useEffect(() => {
    MasterAPICall();
  }, []);

  return (
    <div style={{ maxHeight: "90vh", height: " 90vh", overflow: "hidden" }}>
      <h4 className="heading_text mb-3">Joy Connect Support.</h4>
      <div className="row justify-content-between h-100 pl-3">
        <div className="col-8" style={{ maxHeight: "70%", height: "70%" }}>
          <div>
            {videoUrl ? (
              <video
                width="100%"
                height="100%"
                controls
                controlsList="nodownload"
                autoPlay
              >
                <source src={videoUrl.video_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div class="d-flex h-100  justify-content-center align-items-center ">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
          <div className="h-25 px-2 py-1">
            <h5 className="fw-bolder">{videoUrl?.["Video Name"]}</h5>
            <p>{videoUrl?.["Short Description"]}</p>
          </div>
        </div>
        <div
          className="col-4"
          style={{
            maxHeight: "80%",
            height: "80%",
            overflowY: "scroll",
            backgroundColor: "#f8f9fa",
            border: "1px solid #dee2e6",
            borderRadius: "5px",
            padding: "10px",
            overflowX: "hidden",
          }}
        >
          {videoList.map((e, index) => (
            <div
              className="row justify-content-between mb-3"
              key={index}
              style={{
                backgroundColor: "#ffffff",
                border: "1px solid #dee2e6",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div
                onClick={() => handelPlayListVideoClick(e)}
                className="col-6 p-0"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={e?.image_url}
                  style={{
                    height: "100px",
                    width: "150px",
                    objectFit: "cover",
                    borderRadius: "5px",
                  }}
                  alt={e?.image_name}
                />
              </div>
              <div className="col-6">
                <h6 className="fw-bolder">{e?.["Video Name"]}</h6>
                <p>{truncateText(e?.["Short Description"], 10)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Help;
