import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { keyframes } from '@emotion/react'
const WARNING = "warning";
const SUCCESS = "success";
const INFO = "info";
const ERROR = "error";

export const Notification = (props) => {
  useEffect(()=>{
  if(sessionStorage.getItem("hide-chassis-pending-for-pdi-notificatoion")=="true"&&props.alert_for=="hide-chassis-pending-for-pdi-notificatoion"){
    document.getElementById(id).classList.add("hide-component");
  }
  },[])
  const id=Math.random();
  const handelClose = (alert_for) => {
    document.getElementById(id).classList.add("hide-component");
    if(alert_for=="hide-chassis-pending-for-pdi-notificatoion"){
      sessionStorage.setItem("hide-chassis-pending-for-pdi-notificatoion",true)
    }
  };
  const slideRight = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;
  return (
    <div className="" id={id}>
      <Stack
        sx={{
          maxWidth:"70%",
          width: "fit-content",
          marginTop: props.margin + "px",
          position: "fixed",
          top: "50px",
          zIndex: 9999,
          right: "10px",
          animation: `${slideRight} 1s ease-in-out`,
        }}
        spacing={2}
      >
        <Alert  severity={props.type} onClose={() => handelClose(props.alert_for)}>
          {props.message}
        </Alert>
      </Stack>
    </div>
  );
};

export default Notification;
export { WARNING, SUCCESS, INFO, ERROR };
