import React from "react";
import "../../containers/Files/files-styles.css";
import "../../containers/color.css";
// import { Link } from 'react-router-dom'
import moment from "moment";
import Strings from "../../../helpers/Strings";
import Urls from "../../../helpers/Urls";

let val = "";
let val_in = "";
let val_trans = "";
let isOutbound;
export class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      dealeroption: [],
      dealerid: this.props.dealerid,
    };
  }
  componentDidMount() {
    if (sessionStorage.getItem("UserRole") !== "1") {
      this.getDealer();
    }
  }
  handleStartChange = (date) => {
    this.setState(
      {
        startDate: moment(date).format("YYYY-MM-DD"),
      },
      () => {
        this.props.update("startDate", this.state.startDate);
      }
    );
  };
  handleEndChange = (date) => {
    this.setState(
      {
        endDate: moment(date).format("YYYY-MM-DD"),
      },
      () => {
        this.props.update("endDate", this.state.endDate);
      }
    );
  };
  handelTimeRange = (event) => {
    let day = 0;
    let chartType = "";
    let selected_val = event.target.options[event.target.selectedIndex].text;
    let startDate = "";
    let endDate = "";
    let lastdate = "";
    if (selected_val == "Last week") {
      day = 7;
      chartType = "Datewise";
      lastdate = "lastweek";
    } else if (selected_val == "Last 30 days") {
      day = 30;
      chartType = "Weekwise";
      lastdate = "last30days";
    } else if (selected_val == "Last 90 days") {
      day = 90;
      lastdate = "last90days";
    } else if (selected_val == "Last 180 days") {
      day = 180;
      lastdate = "last180days";
    } else if (selected_val == "Current year") {
      let previousYear = moment().subtract(1, "year").format("YYYY");
      startDate = `${previousYear}-04-01`;
      day = moment().diff(startDate, "days");
      lastdate = "lastyear";
    } else if (selected_val == "This year") {
      day = 1;
      lastdate = "lastyear";
    }
    startDate = moment().subtract(day, "d").format("YYYY-MM-DD");
    endDate = moment().format("YYYY-MM-DD");
    this.props.setData(startDate, endDate, selected_val, chartType, lastdate);
    this.setState({
      startDate: startDate,
      endDate: endDate,
    });
  };
  getDealer = () => {
    const url = Urls.python_baseurl + "/SP_Get_Dealer";
    var data = JSON.stringify({
      Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
      RegionID: "'All'",
      StateID: "'All'",
      DistrictID: "'All'",
      TalukaID: "'All'",
      Dealer_Category: "'All'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            dealeroption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  renderFilters = () => {
    return (
      <div className="form-style" id="filters">
        <div className="form-row">
          {this.props.isTimeRange ? (
            <div className="form-group col-2">
              <div className="list-dashboard">Time Range</div>
              <select
                className="form-control list-dashboard"
                id="state"
                onChange={(event) => {
                  this.handelTimeRange(event);
                }}
                defaultValue={"1"}
              >
                <option value="0">Last One day</option>
                <option value="1">Last week</option>
                <option value="2">Last 30 days</option>
                <option value="3">Last 90 days</option>
                <option value="halfYear">Last 180 days</option>
                <option value="year">Current year</option>
              </select>
            </div>
          ) : null}

          <div className="form-group col-2">
            <div className="list-dashboard">Start Date</div>
            <input
              type="date"
              className="form-control list-dashboard"
              value={this.state?.startDate}
              onChange={(e) => this.handleStartChange(e.target.value)}
              max={this.state?.endDate}
            />
          </div>
          <div className="form-group col-2">
            <div className="list-dashboard">End Date</div>
            <input
              type="date"
              className="form-control list-dashboard"
              value={this.state?.endDate}
              onChange={(e) => this.handleEndChange(e.target.value)}
              min={this.state?.startDate}
            />
          </div>
          {sessionStorage.getItem("UserRole") !== "1" && (
            <div className="form-group col-2">
              <div className="list-dashboard">Dealer</div>
              <select
                className="form-control list-dashboard"
                onChange={(e) =>
                  this.setState({ dealerid: e.target.value }, () => {
                    this.props.update("dealerid", this.state.dealerid);
                  })
                }
                value={this.state.dealerid}
              >
                <option value="">All</option>
                {this.state.dealeroption.map((e) => (
                  <option value={e.ID}>{e.Name}</option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    return this.renderFilters();
  }
}
