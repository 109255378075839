import React from "react";
import moment from "moment";
import "../Dealer_To_Dealer_Tax_Invoice/index.css";
import "../../../containers/color.css";
import Urls from "../../../../helpers/Urls";
import Strings from "../../../../helpers/Strings";
import {
  setDomLayoutAfterPrint,
  setDomLayoutForPrint,
} from "../../../../helpers/getDetails";
import Signfile from "../../../components/Images/Form-22_Signfile.png";
export class Form_22 extends React.Component {
  constructor(props) {
    console.log(props, "hjhjhjhj");
    super(props);
  }
  handelPrint() {
    if (this?.props?.location?.state?.modelid) {
      this.setState(
        {
          EnquiryDetails: this.props.location.state.data,
        },
        () => {
          var tempTitle = document.title;
          document.title =
            "F22-" +
            moment().year() +
            "-" +
            this.state.EnquiryDetails.Invoice_No;
          window.print();
          document.title = tempTitle;
          localStorage.setItem("FromHistory", true);
          this.props.history.push({
            pathname: Strings.vehical_invoice,
            state: {
              id: this.props.location.state.id,
              modelid: this.props.location.state.modelid,
              Status: this.props.location.state.Status,
              name: this.props.location.state.name,
              M_7id: this.props.location.state.M_7id,
            },
          });
        }
      );
    }
  }
  handleSignFileLoad = () => {
    this.handelPrint();
  };
  Invoice = () => {
    return (
      <div>
        <div className="invoice-container-form-22">
          {/* < */}
          <h4
            style={{ margin: "0", padding: "0" }}
            className="heading_text mb-3"
          >
            Form 22
          </h4>
          <p style={{ fontSize: "12px" }}>[See Rules 47 (1) (g), and 127]</p>
          <p>INITIAL CERTIFICATE OF COMPLIANCE WITH POLLUTION STANDARDS,</p>
          <p>SAFETY STANDARDS OF COMPONENTS QUALITY AND ROAD-WORTHINESS</p>
          <p>
            (To be issued by the manufacturer or importer or registered
            association in case of *rickshaw or E-cart)
          </p>
          <p>
            It is certified that the following vehicle complies with the
            provision of the motor vehicles Act, 1988 and the rule Made there
            under as specified below:
          </p>
          <br></br>
          <div style={{ width: "100%" }}>
            <ol>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="left-div">
                    Model/Commercial Name of the Vehicle
                  </div>
                  <div className="right-div">
                    {this.props?.location?.state?.data?.Model_Name}
                  </div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="left-div">
                    Chassis Number (Example: Vehicle Identification Number (VIN)
                    or AT IN or PIN/Trailer Identification Number etc)
                  </div>
                  <div className="right-div">
                    {this.props?.location?.state?.data?.Chassis_No}
                  </div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="left-div">
                    Engine Number (Motor Number in Case of Battery-Operated
                    Vehicles){" "}
                  </div>
                  <div className="right-div">
                    {this.props?.location?.state?.data?.Motor_No}
                  </div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="left-div">Emission Norms Applicable</div>
                  <div className="right-div">
                    {this.props?.location?.state?.data?.emissionnorms}
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="left-div">
                      The Emission, Sound Level for Horn and Pass by noise
                      values of the above vehicle model, obtained during Type
                      Approval Testing as per Central Motor Vehicles Rules, 1989
                      are given below:
                    </div>
                    <div className="right-div">
                      {this.props?.location?.state?.data?.Db}
                    </div>
                  </div>

                  <ol style={{ marginLeft: "3%", marginTop: "3%" }}>
                    <li>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="left-div">
                          Type Approval Certificate No.
                        </div>
                        <div className="right-div">
                          {
                            this.props?.location?.state?.data
                              ?.Type_App_Certificate_No
                          }
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="left-div">Type of Fuel Vehicle</div>
                        <div className="right-div">
                          {this.props?.location?.state?.data?.ClassOfVehicle}
                        </div>
                      </div>
                    </li>
                  </ol>
                </div>
              </li>
            </ol>
          </div>
          <br></br>
          <div style={{ width: "100%" }}>
            <table>
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Pollutant (As Applicable)</th>
                  <th>Units (As Applicable)</th>
                  <th>Value (up to 3 Decimal Places)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>(2)</td>
                  <td>(3)</td>
                  <td>(4)</td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Carbon Monoxide (CO)</td>
                  <td>Mg/km</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Hydro Carbon(THC/HC)</td>
                  <td>Mg/km</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Non-Methane Hydrocarbon (NHMC)</td>
                  <td>Mg/km</td>
                  <td>N/A</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Oxides of Nitrogen (NOx)</td>
                  <td>Mg/km</td>
                  <td>N/A</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br></br>
          <div style={{ textAlign: "left", width: "100%" }}>
            <div>(iv) Noise Level (as applicable) :</div>
            <br></br>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                {" "}
                Horn (for all vehicles other than agriculture tractors and
                constructions equipment vehicles) as installed places) on the
                vehicle <br></br>
                <b>{this.props?.location?.state?.data?.Db}</b> (upto 1 decimal
                place){" "}
              </li>
              <li>
                Pass by Bystander's position:{" "}
                <b>{this.props?.location?.state?.data?.ByStandardPositionDB}</b>
              </li>
              <li>
                Driver-perceived or Operator's ear level (as applicable for
                agricultural tractors and construction Equipment vehicle){" "}
                <b>N/A</b>
              </li>
            </ol>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div style={{ textAlign: "right", width: "100%" }}>
            <div>
              <img
                //  src={require("../../../components/Images/Form-22_Signfile.png")}
                src={Signfile}
                alt="Signfile"
                style={{ width: "130px", height: "70px", marginRight: "20%" }}
                align="center"
                onLoad={() => this.handleSignFileLoad()}
              />
            </div>
            <div>
              (Signatures of Manufacturer or of Authorized Signatory of
              Registered E-rikshaw or E-cart Association)
            </div>
          </div>
          <br></br>
          <br></br>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div
        id="printArea"
        style={{ maxHeight: "100vh", marginTop: "150px", marginLeft: "-10px" }}
      >
        {this.Invoice()}
      </div>
    );
  }
}
