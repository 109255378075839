import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import "@fortawesome/fontawesome-free/css/all.css";
import moment from "moment";
import { faL } from "@fortawesome/free-solid-svg-icons";

export class ErrorLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      errorreportname:"",
      startdate:"",
      enddate:"",
      doubleclick:false
    };
  }
  componentDidMount() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayStr = yesterday.toISOString().split("T")[0];

    const today = new Date();
    const todayStr = today.toISOString().split("T")[0];
    this.setState({
      startdate: yesterdayStr,
      enddate: todayStr,
    });
  }
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Error Description",
        field: "Errordescription",
        flex: "1",
        cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      },
      { headerName: "Creation Date", field: "Cr_date", flex: "1" }
    ];

    return (
      <div className="mx-3 mt-2">
        <div className="ag-theme-balham">
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              console.log("event12", event.data);
              if (event.colDef.field == "Customer Code") {
                this.setState(
                  {
                    id: event.data.ID,
                  },
                  () => {
                    this.customer_display();
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  setValue = ({ name, value }) => {
    this.setState({
      [name]: value,
    });
  };
  getErrorLogs = () => {
    const url = Urls.python_baseurl + "/Get_Error_logs";
    var data = JSON.stringify({
      DealerID: `${sessionStorage.getItem("dealerid")}`,
      table_name: `${this.state.errorreportname}`,
      startdate: `'${
        this.state.startdate
          
      }'`,
      enddate: `'${
        this.state.enddate
      }'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          this.setState({
            rowData: res
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      }).finally(()=>{
        this.setState({
          doubleclick:false
        })
      });
  };
  validate = () => {
    if (!this.state.errorreportname && this.state.errorreportname == "") {
      alert("Please select a Error Report Name");
      return;
    } else if (!this.state.startdate && this.state.startdate == "") {
      alert("Please select start date");
      return;
    } else if (!this.state.enddate && this.state.enddate == "") {
      alert("Please select end date");
      return;
    } else {
      this.setState(
        {
          doubleclick: true,
        },
        () => {
          this.getErrorLogs();
        }
      );
    }
  };
  displayFilter = () => {
    return (
      <div className="row mt-5 p-2 mx-3  card flex-row ">
        <div className="form-group col-md-3">
          <label>Error Report Name {this.required()}</label>
          <select
            onChange={(e) => this.setValue(e.target)}
            name="errorreportname"
            value={this.state.errorreportname}
            className="form-control"
          >
            <option value="0">Select</option>
            <option value="TM_VehicleAcknowledgementErrorLogs">
              Vehicle Acknowledgement Logs
            </option>
            <option value="TM_VehicleSalesBillErrorLogs">
              vehicle Sales Bill Logs
            </option>
          </select>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="startDate">Start Date {this.required()}</label>
          <input
            id="startDate"
            className="form-control"
            type="date"
            name="startdate"
            onChange={(e) => this.setValue(e.target)}
            value={this.state.startdate}
            max={this.state.enddate}
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="endDate">End Date {this.required()}</label>
          <input
            id="endDate"
            className="form-control"
            type="date"
            name="enddate"
            onChange={(e) => this.setValue(e.target)}
            value={this.state.enddate}
            max={this.state.enddate}
            min={this.state.startdate}
          />
        </div>
        <div className="form-group col-md-3 mt-4">
          <button
            onClick={() => {
              this.validate();
            }}
            disabled={this.state.doubleclick}
            className={"btn Opertion_btn clickable"}
          >
            View Error Logs
          </button>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <h4 className="heading_text">Error Logs</h4>
        {this.displayFilter()}
        {this.masterTable()}
      </div>
    );
  }
}
