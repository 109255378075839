import React from "react";
import moment from "moment";
import "./index.css";
import Logo from "../../components/Images/Logo.png";
import "../../containers/color.css";
import Urls from "../../../helpers/Urls";
export class QuotationReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 100,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showFieldOnAddNew: false,
      Model_Category_Option: [],
      Model_Code_Option: [],
      Model_Option: [],
      Invoice_Option: [],
      Model_Id: "",
      ModelRowData: [],
    };
  }
  componentDidMount() {
    this.MasterAPI();
  }
  MasterAPI = async () => {
    const url = Urls.python_baseurl + "/GetQuotation_SP_Quotation_Details";
    var data = JSON.stringify({
      DealerId: "'126'",
      HOBrID: "'126'",
      M1ID: "24",
    });
    let res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    });
    res = await res.json();
    if (res && res.length > 0) {
      this.setState({
        QuotationNumber: res[0]?.Quotation_Number,
        QuotationDate: moment(res[0]?.Quotation_Date).format("DD-MM-YYYY"),
        CustomerPhone: res[0]?.mobile,
        CustomerEmail: res[0]?.E_Mail,
        CustomerName: res[0]?.name,
        ModelName: res[0]?.model_name,
        ModelColor: res[0]?.model_Colour,
        ShowroomPrice: res[0]?.MRP,
        TotalPrice: res[0]?.total_amount,
      });
    }
  };
  handelPrint = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };
  Receipt = () => {
    let currentdate = new Date();
    let datetime =
      "Last Sync: " +
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " @ " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();
    return (
      <div
        id="printablediv"
        className="printable-content mb-2"
        ref={this.refVariable}
      >
        <div className=" row">
          <div className=" col-1">
            <img style={{ width: "120%" }} src={Logo}></img>
          </div>
          <h5
            style={{ color: "var(--main-bg-color)", fontWeight: "600" }}
            className="col-11"
          >
            VEHICLE PURCHASE QUOTE
          </h5>
        </div>
        <br></br>
        <div style={{ marginBottom: "-10px" }} className="row">
          <div
            style={{ color: "var(--main-bg-color)", fontWeight: "600" }}
            className="col-4 text-center "
          >
            Quotation No
          </div>
          <div
            style={{ color: "var(--main-bg-color)", fontWeight: "600" }}
            className="col-4 text-center "
          >
            Quotation Date
          </div>
          <div
            style={{ color: "var(--main-bg-color)", fontWeight: "600" }}
            className="col-4 text-center "
          >
            Due Date
          </div>
        </div>
        <hr></hr>
        <div style={{ marginTop: "-10px" }} className="row">
          <div className="col-4 text-center ">{this.state.QuotationNumber}</div>
          <div className="col-4 text-center ">{this.state.QuotationDate}</div>
          <div className="col-4 text-center ">25/1/2024</div>
        </div>
        <br></br>
        <div className=" row">
          <div className=" col-6">
            <h4
              style={{
                color: "var(--main-bg-color)",
                backgroundColor: "#f2f2f7",
                padding: "4px",
              }}
            >
              To
            </h4>
            <div>{this.state.CustomerName}</div>
            <div>Aadarsh nagar,Tatisilwai, Ranchi, 835103</div>
            <div>{this.state.CustomerEmail}</div>
            <div>{this.state.CustomerPhone}</div>
          </div>
          <div className=" col-6">
            <h4
              style={{
                color: "var(--main-bg-color)",
                backgroundColor: "#f2f2f7",
                padding: "4px",
              }}
            >
              From
            </h4>
            <div>Joy E-Bike</div>
            <div>Pune, Maharashtra</div>
            <div>joyebike@gmail.com</div>
            <div>+91 8703247662</div>
          </div>
        </div>
        <div style={{ marginBottom: "-10px" }} className="row mt-3">
          <div
            style={{ color: "var(--main-bg-color)", fontWeight: "600" }}
            className="col-3 text-center "
          >
            Model Name
          </div>
          <div
            style={{ color: "var(--main-bg-color)", fontWeight: "600" }}
            className="col-3 text-center "
          >
            Model Colour
          </div>
          <div
            style={{ color: "var(--main-bg-color)", fontWeight: "600" }}
            className="col-3 text-center "
          >
            Showroom Price
          </div>
          <div
            style={{ color: "var(--main-bg-color)", fontWeight: "600" }}
            className="col-3 text-center "
          >
            Total Price
          </div>
        </div>
        <hr></hr>
        <div style={{ marginTop: "-10px" }} className="row">
          <div className="col-3 text-center ">{this.state.ModelName} </div>
          <div className="col-3 text-center ">{this.state.ModelColor}</div>
          <div className="col-3 text-center ">
            {this.state.ShowroomPrice + " INR"}
          </div>
          <div className="col-3 text-center ">
            {this.state.TotalPrice + " INR"}
          </div>
        </div>
        <div>
          <table className="quote-table mt-3">
            <thead
              style={{
                backgroundColor: "var(--main-bg-color)",
                color: "white",
              }}
            >
              <tr>
                <th></th>
                <th>DESCRIPTION</th>
                <th>PARTS</th>
                <th>TAX (6%)</th>
                <th>INSTALLATION</th>
                <th>TOTAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>Seats</td>
                <td>$0.00</td>
                <td>$0.00</td>
                <td>$0.00</td>
                <td>$0.00</td>
              </tr>
              <tr>
                <td>02</td>
                <td>Steering Case</td>
                <td>$0.00</td>
                <td>$0.00</td>
                <td>$0.00</td>
                <td>$0.00</td>
              </tr>
              <tr>
                <td>03</td>
                <td>Speakers</td>
                <td>$0.00</td>
                <td>$0.00</td>
                <td>$0.00</td>
                <td>$0.00</td>
              </tr>
              <tr>
                <td>04</td>
                <td>Air Bags</td>
                <td>$0.00</td>
                <td>$0.00</td>
                <td>$0.00</td>
                <td>$0.00</td>
              </tr>
              <tr>
                <td>05</td>
                <td>Vehicle (Car)</td>
                <td>$0.00</td>
                <td>$0.00</td>
                <td>$0.00</td>
                <td>$0.00</td>
              </tr>
              <tr>
                <td colSpan={5}>SUB TOTAL</td>
                <td>$0.00</td>
              </tr>
              <tr>
                <td colSpan={5}>GRAND TOTAL</td>
                <td>$0.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row justify-content-between p-4">
          <div>Due Date: 07/01/2037</div>
          <div>
            <div>Thank you for your business!</div>
            <div style={{ color: "var(--main-bg-color)", fontWeight: "600" }}>
              Joy E-Bike
            </div>
            <div style={{ color: "var(--main-bg-color)", fontWeight: "600" }}>
              {datetime}
            </div>
          </div>
        </div>
        <div className="row justify-content-end ">
          <button
            id="print"
            className="btn Opertion_btn clickable mr-4"
            onClick={() => this.handelPrint()}
          >
            Print
          </button>
        </div>
      </div>
    );
  };
  render() {
    return <div>{this.Receipt()}</div>;
  }
}
