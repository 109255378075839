import React from "react";
import "../../Files/files-styles.css";
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import Strings from "../../../../helpers/Strings";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
const $ = window.$;

export class Money_reciept extends React.Component {
  constructor(props) {
    console.log(props, "poppp");
    super(props);
    this.state = {
      prevID:
        props.modelid == "pendingClick"
          ? props?.pendingClick7?.M3 != undefined
            ? props?.pendingClick7?.M3
            : props?.pendingClick7?.ID
            ? props?.pendingClick7?.ID
            : props.M7_ID
            ? props.M7_ID
            : props?.pendingClick7
            ? props?.pendingClick7?.M3
            : props.id
          : 0,
      // Customer_Name: props?.pendingClick7?.Dealer_Name,
      paginationPageSize: 10,
      Enq_money_id: props && props.vehicle != "vehicle" ? props.id : 0,
      vehicle_id: props && props.vehicle === "vehicle" ? props.id : 0,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      vistitdetails: [],
      rowData: [],
      visit_details: "",
      next_visit_details: "",
      newDate: "",
      Date: "",
      endDate: "",
      startDate: "",
      M7_No: "",
      Allocat_Status: "E",
      MR_ID:
        props.modelid == "pendingClick"
          ? 0
          : props.ID
          ? props.ID
          : props?.pendingClick7?.ID
          ? props?.pendingClick7?.ID
          : props.M7_ID
          ? props.M7_ID
          : props?.pendingClick7
          ? props?.pendingClick7.M7
          : 0,
      M7ID: 0,
      Chassis_No: "",
      engin_no: "",
      company_invoice_no: "",
      ChassisNoOption: [],
      booking_status: props && props.Status === "Y" ? "Confirmed" : "",
      company_invoice_date: moment(new Date()).format("DD-MM-YYYY"),
      Payment_amount: "",
      Payment_mode: "",
      cheque_details: "",
      Receipt_date:
        props && props.ReceiptDetails
          ? moment(props.ReceiptDetails[0][0].M7_Date, "YYYY/MM/DD").format(
              "DD-MM-YYYY"
            )
          : moment(new Date()).format("DD-MM-YYYY"),
      // Payment_mode:
      //   props && props.ReceiptDetails
      //     ? props.ReceiptDetails[0][0].CashLoan
      //     : "",
      // Payment_amount:
      //   props && props.ReceiptDetails
      //     ? props.ReceiptDetails[0][0].MarginAmt
      //     : "",
      // cheque_details:
      //   props && props.ReceiptDetails
      //     ? props.ReceiptDetails[0][0].ChRTGSDet
      //     : "",
      // Cheque_Date:
      //   props && props.ReceiptDetails
      //     ? moment(props.ReceiptDetails[0][0].ChRTGSDate, "YYYY/MM/DD").format(
      //         "YYYY-MM-DD"
      //       )
      //     : "",
      // // Enquiry_No:props?.pendingClick7?.Doc_No
      // ML7_No:
      //   props && props.ReceiptDetails ? props.ReceiptDetails[0][0].M7_no : "",
      // Customer_Name:
      //   props && props.ReceiptDetails ? props.ReceiptDetails[0][0].name : "",
      // mobile_no:
      //   props && props.ReceiptDetails ? props.ReceiptDetails[0][0].mobile : "",
      // booking_no:
      //   props && props.ReceiptDetails ? props.ReceiptDetails[0][0].M3_no : "",
      // booking_date:
      //   props && props.ReceiptDetails
      //     ? props.ReceiptDetails[0][0].Booking_Date
      //     : "",
      // Model_name:
      //   props && props.ReceiptDetails
      //     ? props.ReceiptDetails[0][0].model_name
      //     : "",
      // Quantity:
      //   props && props.ReceiptDetails ? props.ReceiptDetails[0][0].Qty : "",
      // Adv_amt:
      //   props && props.ReceiptDetails
      //     ? props.ReceiptDetails[0][0].Booking_Adv_Amt
      //     : "",
      // M3Hdr:
      //   props && props.ReceiptDetails ? props.ReceiptDetails[0][0].Hdr : "",
      // IS_confirm:
      //   props && props.ReceiptDetails
      //     ? props.ReceiptDetails[0][0].IS_confirm
      //     : "",
      // Total_amount:
      //   props && props.ReceiptDetails
      //     ? props.ReceiptDetails[0][0].total_amount
      //     : "",
      // Model_ID:
      //   props && props.ReceiptDetails ? props.ReceiptDetails[0][0].Model_ID : 0,
      M3_ID:
        props && props.ReceiptDetails ? props.ReceiptDetails[0][0].M3_ID : 0,
      // Payable_amount:props&&props.ReceiptDetails?props.ReceiptDetails[0][0].IS_confirm:"",
      allocat_Detail: [],
      allocation:
        props && props.ReceiptDetails === undefined
          ? []
          : props.ReceiptDetails[2],
      ConditionCode: [
        {
          id: 1,
          accid: "",
          accessories_name: "",
          accessories_amount: "",
          hsn: "",
          igst: "",
          igst_amount: "",
          sgst: "",
          sgst_amount: "",
          cgst: "",
          cgst_amount: "",
          accessories_total_withtax: "",
          options: [],
        },
      ],
      isSavedClick: props.modelid,
      AccessoriesOption: [],
      insuranceList: [],
      SchemeOption: [],
      SourceListOption: [],
      bookingType: [],
      bookingstate: [],
      vistitdetails: [],
      Financier_customer: [],
      Model_Id: "",
      Model_Code_Option: [],
      Model_Category_Option: [],
      Model_Option: [],
      next_visit_details: "",
      visit_details: "",
      booking_ID: 0,
      SourceListOption: [],
      bookingType: [],
      bookingstate: [],
      booking_district: [],
      booking_region: [],
      booking_town: [],
      Attended_By: [],
      AllocatedTo: [],
      Model_Group: [],
      Model_Code: [],
      insuranceList: [],
      Cheque_Date: "",
      Dealer_vehicle_Code: "",
      fin_year: sessionStorage.getItem("fin_year"),
      Dealer_Code: sessionStorage.getItem("Dealer_Code"),
      isDouble: false,
      Model_Category: [],
      isModelChange: false,

      //paymenDetails

      payment_details: [
        {
          id: 0,
          installment_no: "Installment 1",
          payment_mode: "",
          check_details: "",
          check_date: "",
          payment_amount: "",
        },
      ],
    };
  }
  componentDidMount() {
    // this.MasterAPICall();
    //this.visit_details();
    this.GetModelCategory();
    this.GetSourceList();
    this.booking_state();
    this.booking_Type();
    this.Financiercustomer();
    {
      this.props.modelid == "pendingClick" && this.M7_No();
    }
    {
      this.props.modelid !== "pendingClick" && this.getPaymentDetails();
    }
    this.standard_accssories();
    // this.customer_region()
    // this.customer_Tahsil()
  }

  standard_accssories = () => {
    const url = Urls.python_baseurl + "/GetAccessories_SP_GetAccessories";
    var data = JSON.stringify({
      Type: "'Standard'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newConditionArray = this.state.ConditionCode.map((e) => {
            return { ...e, options: res };
          });
          this.setState(
            {
              ConditionCode: newConditionArray,
              AccessoriesOption: res,
              MaxAccessoriesCount: res.length,
            },
            () => {
              this.getM7Details();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  booking_state = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: "0",
      type: "'State'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            bookingstate: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  createrows = (options) => {
    let allocat_Detail_1 = [];
    let savedOption = [];
    for (let index = 0; index < this.state.allocation.length; index++) {
      if (this.state.allocation[index].chassis_ID) {
        savedOption = [
          ...options,
          {
            ID: this.state.allocation[index].chassis_ID,
            Name: this.state.allocation[index].Chassis_No,
          },
        ];
      }
      let data = {
        id: index,
        Chassis_No: this.state.allocation[index].Chassis_No,
        Vehicle_Number: this.state.allocation[index].vehicle_no,
        Motor_Number: this.state.allocation[index].motor_no,
        Battery_Number: this.state.allocation[index].battery_no,
        Charger_Number: this.state.allocation[index].charger_no,
        Controller_Number: this.state.allocation[index].controller_no,
        Display_Number: this.state.allocation[index].display_no,
        IOT_Device_Number: this.state.allocation[index].IOT_device_ID,
        Actual_Battery_Number: this.state.allocation[index].actual_battery_no,
        Actual_Charger_Number: this.state.allocation[index].actual_charger_no,
        Allocation_id: this.state.allocation[index].ID,
        chassis_ID: this.state.allocation[index].chassis_ID,
        ActualController_Unit_No:
          this.state.allocation[index].ActualController_Unit_No,
        ActualMotor_No: this.state.allocation[index].ActualMotor_No,
        ActualDispayNo: this.state.allocation[index].ActualDispayNo,
        ActualIOT_Device_ID: this.state.allocation[index].ActualIOT_Device_ID,
        options: this.state.allocation[index].chassis_ID
          ? savedOption
          : options,
        isSaveChassis: this.state.allocation[index].chassis_ID ? true : false,
        selectedChassis: [
          {
            ID: this.state.allocation[index].chassis_ID,
            Name: this.state.allocation[index].Chassis_No,
          },
        ],
        isSelected: this.state.allocation[index].chassis_ID ? true : false,
      };
      allocat_Detail_1.push(data);
    }
    this.setState(
      {
        allocat_Detail: allocat_Detail_1,
      },
      () => {
        console.log(this.state.allocat_Detail, "kjjkjk");
      }
    );
  };
  GetSourceList = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 154,
      ID: 0,
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            SourceListOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "D",
      DealerId: 126,
      SqlFor: "'M2'",
      HOBrID: 126,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Save_M7_No = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "MR",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              ML7_No:
                this.state.Dealer_Code +
                "" +
                "MR" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              this.handelSave();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  M7_No = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "MR",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // docMax: res[0].DocMaxNo,
              ML7_No:
                this.state.Dealer_Code +
                "" +
                "MR" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              // this.financiaryear();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "ML7 No",
        field: "Customer Code",
        flex: "1",
        cellStyle: { cursor: "pointer" },
      },
      { headerName: "ML7 Date", field: "Customer Name", flex: "1" },
      { headerName: "Name", field: "Address1", flex: "1" },
      { headerName: "Status", field: "Pincode", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              console.log("event12", event.data);
              if (event.colDef.field == "Customer Code") {
                this.setState(
                  {
                    id: event.data.ID,
                  },
                  () => {
                    this.customer_display();
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  Financiercustomer = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "83",
      ID: "0",
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Financier_customer: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Financier_customer = (event) => {
    let row = [];
    this.state.Financier_customer.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  getM7Details = () => {
    const url = Urls.python_baseurl + "/MoneyReceipt_display_SP_GetM7";
    var data = JSON.stringify({
      POId: `${
        this.state.Enq_money_id
          ? this.state.Enq_money_id
          : this.state.isSavedClick == "pendingClick"
          ? "0"
          : this.state.MR_ID
      }`,
      POType: "'All'",
      DealerID: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      iM1ID: `${
        this.state.vehicle_id
          ? this.state.vehicle_id
          : this.state.isSavedClick == "pendingClick"
          ? this.state.prevID
          : "0"
      }`,
      DocType: "'L'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newcondition = res[1].map((e, index) => {
            return {
              id:
                this.state.ConditionCode[this.state.ConditionCode.length - 1]
                  ?.id + index,
              accid: e?.Acc_id,
              accessories_name: this.state.AccessoriesOption.filter(
                (element) => {
                  return e?.Acc_id == element.ID;
                }
              )[0]?.Accesories_Name,
              accessories_amount: e?.Accessories_Amount,
              hsn: e?.HSN,
              igst: e?.IGST,
              igst_amount: e?.IGST_Amount,
              sgst: e?.SGST,
              sgst_amount: e?.SGST_Amount,
              cgst: e?.CSGT,
              cgst_amount: e?.CSGT_Amount,
              accessories_total_withtax: e?.Total_With_Tax,
              options: this.state.AccessoriesOption,
              accesores_ID: e?.ID,
              ["selectedAccessories"]: this.state.AccessoriesOption.filter(
                (element) => {
                  return e?.Acc_id == element.ID;
                }
              )[0],
            };
          });
          this.setState(
            {
              ConditionCode: newcondition,
              Customer_Name: res[0][0].name,
              booking_no: res[0][0].M3_no,
              booking_date:
                res[0][0].Booking_Date &&
                moment(res[0][0].Booking_Date).format("DD-MM-YYYY"),
              Booking_Adv_Amt: res[0][0]?.Booking_Adv_Amt
                ? (res[0][0]?.Booking_Adv_Amt).toFixed(2)
                : "",
              Total_amount:
                res[0][0]?.Total_amount &&
                res[0][0]?.Booking_Adv_Amt &&
                (
                  parseFloat(res[0][0]?.Total_amount) +
                  parseFloat(res[0][0]?.Booking_Adv_Amt)
                ).toFixed(2),
              Payable_amount: res[0][0]?.Total_amount
                ? (res[0][0]?.Total_amount).toFixed(2)
                : "",
              //booking
              Name: res[0][0]?.name,
              address1: res[0][0]?.add1,
              address2: res[0][0]?.add2,
              Region: res[0][0]?.Region_ID,
              State: res[0][0]?.State_ID,
              District: res[0][0]?.District_ID,
              City: res[0][0]?.CITY,
              pincode: res[0][0]?.PIN,
              mobile_no: res[0][0]?.mobile,
              email: res[0][0]?.E_mail,
              Type: res[0][0]?.type_flag_id,
              Title: res[0][0]?.Prefix,
              Town: res[0][0]?.Taluka_ID,
              enquiry_no: res[0][0]?.Inq_no,
              Enquiry_date: res[0][0]?.Inq_date,
              Source: res[0][0]?.Inq_source_id,
              attendedby: res[0][0]?.AttendedBy_Name,
              allocatedto: res[0][0]?.AllocatedTo_Name,
              Quantity: res[0][0]?.Qty,
              AMV_Sale_Rate: res[0][0]?.MRP,
              AMV_Standard_Accessories_Amount:
                res[0][0]?.Standard_Accessories_amount,
              AMV_RTO_Amount: res[0][0]?.RTO_Amount,
              Temp_Insurance_company: res[0][0]?.Insurance_company,
              AMV_Insurance_Year: res[0][0]?.insurance_year,
              AMV_Insurance_Amount: res[0][0]?.insurance_amount,
              AMV_CRTM_Amount: res[0][0]?.CRTM_amount,
              AMV_HPA_Charges: res[0][0]?.HPA_charge,
              AMV_Smart_Card_Amount: res[0][0]?.Smart_card_amount,
              AMV_RSA_Amount: res[0][0]?.RSA_change,
              AMV_Subsidy_Amount: res[0][0]?.subsidiary_amount,
              AMV_Central_Subsidy_Amount: res[0][0]?.centarl_subsidy_amount,
              AMV_Dealer_Proposed_Amount: res[0][0]?.dealer_propsed_discount,
              AMV_Special_Number_Amount: res[0][0]?.special_number_amount,
              AMV_Disbursment_Amount: res[0][0]?.disbursment_amount,
              AMV_RTO_Discount: res[0][0]?.RTO_discount,
              AMV_RTO_Discount_Amount: res[0][0]?.RTO_discount_amount,
              AMV_Other_Charges: res[0][0]?.other_changes,
              Other_Charges_Amount: res[0][0]?.other_charges_amount,
              Exchange_Bonus_Amount: res[0][0]?.Exchange_bonus_with_tax,
              Total: res[0][0]?.total_amount,
              Subsidy_Type: res[0][0]?.Subsity_Type,
              M1_HDR: res[0][0]?.M2_Hdr,
              Booking_No: res[0][0]?.M3_no,
              Booking_Date: res[0][0]?.Booking_Date,
              Other_RTO_Charge: res[0][0]?.Other_RTO,
              Booking_Advance_Amount: res[0][0]?.Booking_Adv_Amt,
              Remark: res[0][0]?.remarks,
              Vehicle_Fund: res[0][0]?.Fund,
              Financier: res[0][0]?.Financier_id,
              HSRP_Charge: res[0][0]?.HSRP_Charge,
              Taxable_Amount: res[0][0]?.Taxable_Amt,
              // Pending_Amount: res[0][0]?.Pending_Amt,
              Total_Accessories_Amount: res[0][0]?.Accesories_Amt,
              SchemeName: res[0][0]?.Scheme_ID,
              Scheme_Amt: res[0][0]?.Scheme_Amt,
              Other_Charges: res[0][0]?.other_charges_amount,
              Quantity_Amount: res[0][0]?.Amount,
              Pending_Amount:
                parseFloat(res[0][0]?.Total_amount).toFixed(2) - parseFloat(res[0][0]?.Booking_Adv_Amt).toFixed(2),
              Grand_Total: res[0][0]?.Total_amount,
              Remark: res[0][0]?.remarks,
              Delivery_Date: res[0][0]?.Delivery_Date
                ? moment(res[0][0]?.Delivery_Date).format("YYYY-MM-DD HH:mm:ss")
                : "",
              Booking_Date: moment(res[0][0]?.Booking_Date).format(
                "DD-MM-YYYY"
              ),
              Payment_mode: res[0][0]?.CashLoan,
              Payment_amount: res[0][0]?.MarginAmt,
              cheque_details:
                res[0][0]?.ChRTGSDet === undefined ? "" : res[0][0]?.ChRTGSDet,
              Cheque_Date:
                res[0][0]?.ChRTGSDate != undefined
                  ? moment(res[0][0]?.ChRTGSDate, "YYYY-MM-DD").format(
                      "YYYY-MM-DD"
                    )
                  : "",
              allocation: this.state.isSavedClick == "saveClcik" ? res[2] : [],
              M3_ID: res[0][0]?.M3_Hdr,
              ML7_No: res[0][0]?.M7_no,
              SchemeName: res[0][0]?.Scheme_Name,
              SchemeAmount: res[0][0]?.Scheme_Amt,
              Booking_Advance_Amount_Each: res[0][0].AdvanceAmt_byquantity,
              IS_confirm: res[0][0].IS_confirm,
              payment_ref_no: res[0][0].M3_Transaction_details,
              payment_mode: res[0][0].M3_Payment_Mode,

              CGST_Amount: res[0][0]?.CGST_Amount,
              IGST_Amount: res[0][0]?.IGST_Amount,
              SGST_Amount: res[0][0]?.SGST_Amount,

              CGST_Per: (res[0][0]?.CGST_Amount * 100) / res[0][0]?.Taxable_Amt,
              IGST_Per: (res[0][0]?.IGST_Amount * 100) / res[0][0]?.Taxable_Amt,
              SGST_Per: (res[0][0]?.SGST_Amount * 100) / res[0][0]?.Taxable_Amt,

              model_group: res[0][0].Model_Gp,

              temp_model_name:
                this.state.isSavedClick == "pendingClick"
                  ? res[0][0].Model_ID
                  : res[0][0].model_name,
              model_code: res[0][0].model_Code,
              model_color: res[0][0].model_Colour,
              Status: res[2]
                ? [2][0].Status != null
                  ? res[2][0].Status
                  : ""
                : "",
            },
            () => {
              if (this.state.model_group) {
                this.GetModelCode(this.state.model_group);
              }
              if (this.state.model_code) {
                this.getInsurance(this.state.model_code);
              }
              this.booking_Region();
              this.booking_district();
              // this.financiaryear();
              this.GetModelDetails();
              this.GetChassisNo();
              this.accessoriesSetup();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  accessoriesSetup = () => {
    let newConditionArray = this.state.ConditionCode;
    let newAccessoriesOptions = this.state.AccessoriesOption;
    if (this.state.ConditionCode.length == 1) {
      for (let i = 0; i < this.state.ConditionCode.length; i++) {
        for (let j = 0; j < this.state.ConditionCode.length; j++) {
          newAccessoriesOptions = newAccessoriesOptions.filter(
            (e) => e.ID != newConditionArray[j].accid
          );
        }
      }
    } else {
      for (let i = 0; i < this.state.ConditionCode.length; i++) {
        for (let j = 0; j < this.state.ConditionCode.length; j++) {
          if (i !== j) {
            newConditionArray[i].options = newConditionArray[i].options.filter(
              (e) => e.ID != newConditionArray[j].accid
            );
            newAccessoriesOptions = newAccessoriesOptions.filter(
              (e) => e.ID != newConditionArray[j].accid
            );
          }
        }
      }
    }
    this.setState(
      {
        AccessoriesOption: newAccessoriesOptions,
      },
      () => {
        console.log(this.state.ConditionCode, "jkjkkj");
      }
    );
  };
  GetModelDetails = () => {
    const url = Urls.python_baseurl + "/model_master_SP_ModelMaster_details";
    var data = JSON.stringify({
      model_id: `'${this.state.Model_Id}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          this.setState({
            Model_Category_value: res[0]?.Model_cat_ID,
            Model_Code_value: this.state.Model_Code_Option.filter(
              (e) => e.Name == res[0]?.Model_code
            )[0]?.ID,
            Model: this.state.Model_Option.filter(
              (e) => e.Name == res[0]?.model_name
            )[0]?.ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCode = (ModelCategory) => {
    const url =
      Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
    var data = JSON.stringify({
      ModelGroup: parseInt(ModelCategory),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Model_Code: res,
            },
            () => {
              this.setState({
                model_name: this.state.temp_model_name,
              });
              this.getInsurance(this.state.model_code);
              this.getScheme(this.state.model_code);
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getInsurance = (ModelCodedata) => {
    const url = Urls.python_baseurl + "/getInsurance_SP_GetInsurance ";
    var data = JSON.stringify({
      ModelCode: `'${ModelCodedata}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              insuranceList: res,
            },
            () => {
              this.setState({
                Insurance_company: this.state.Temp_Insurance_company,
              });
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getScheme = (ModelCodedata) => {
    const url = Urls.python_baseurl + "/GetScheme_SP_GetScheme ";
    var data = JSON.stringify({
      DepartmentId: "5",
      Model_code: `'${ModelCodedata}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            SchemeOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  booking_district = () => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: this.state.State,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              booking_district: res,
            },
            () => {
              this.booking_town();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  booking_town = () => {
    const url = Urls.python_baseurl + "/Display_Tehsil_sp_tehsildisplay";
    var data = JSON.stringify({
      state_name: this.state.State,
      district_name: this.state.District,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            booking_town: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  booking_Region = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: this.state.State,
      type: "Region",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            booking_region: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  booking_Type = () => {
    const url = Urls.python_baseurl + "/sp_GetCustType";
    var data = JSON.stringify({
      Type: "C",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            bookingType: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  allocationdetails = () => {
    return (
      <div>
        <div
          style={{ marginTop: "-10px" }}
          className=" row justify-content-end "
        >
          <button
            type="submit"
            className="btn Opertion_btn clickable mr-3"
            onClick={this.Allocation_Save}
          >
            Save
          </button>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label htmlFor="visit-details">Chassis No</label>
            <select
              className="form-control"
              value={this.state.Chassis_No}
              onChange={(event) => {
                this.onSelect(event, "Chassis_No");
              }}
            >
              <option value="0"></option>
              {/* {this.vistitdetails()} */}
            </select>
          </div>
          <div className=" col-3">
            <label>Vehicle Number</label>
            <input
              disabled
              readOnly
              value={this.state.Vehicle_Number}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Motor Number</label>
            <input
              disabled
              readOnly
              value={this.state.Motor_Number}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Battery Number</label>
            <input
              disabled
              readOnly
              value={this.state.Battery_Number}
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Charger Number</label>
            <input
              disabled
              readOnly
              value={this.state.Charger_Number}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Controller Number</label>
            <input
              disabled
              readOnly
              value={this.state.Controller_Number}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Display Number</label>
            <input
              disabled
              readOnly
              value={this.state.Display_Number}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>IOT Device Number</label>
            <input
              disabled
              readOnly
              value={this.state.IOT_Device_Number}
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Company Invoice No</label>
            <input
              readOnly
              disabled
              onChange={(e) =>
                this.setState({ company_invoice_no: e.target.value })
              }
              value={this.state.company_invoice_no}
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="date">Company Invoice Date </label>
            <input
              disabled
              className="form-control"
              placeholder=""
              value={this.state.company_invoice_date}
            />
          </div>
          {/* <div className=" col-3">
            <label>Company Invoice Date </label>
            <input
              readOnly
              disabled
              value={moment(new Date()).format("DD-MM-YYYY")}
              className=" form-control "
            ></input>
          </div> */}
          <div className=" col-3">
            <label>Qty</label>
            <input
              readOnly
              disabled
              value={this.state.Qty}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Replaced Battery Number</label>
            <input
              value={this.state.Actual_Battery_Number}
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Replaced Charger Number</label>
            <input
              value={this.state.Actual_Charger_Number}
              className=" form-control "
            ></input>
          </div>
        </div>
      </div>
    );
  };
  GetChassiList = (options) => {
    console.log(options, "hgghhg");
    let row = [];
    options?.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(
        <option key={element.ID} value={element.ID}>
          {element.Name}
        </option>
      );
    });
    return row;
  };
  handel_ActionDelete = (id) => {
    let isSaveChassis = this.state.allocat_Detail.filter((e) => e.id == id)[0]
      .isSaveChassis;
    if (isSaveChassis) {
      this.setState(
        {
          selectedid: id,
        },
        () => {
          $("#GLKickOut_Popup").modal("show");
        }
      );
    } else {
      this.Mapper_handlInpuChange(
        { target: { name: "Chassis_No", value: "0" } },
        id
      );
    }
  };
  Allocation_details() {
    this.state.row = [];
    this.state.allocat_Detail.forEach((d, index) => {
      this.state.row.push(
        <tr>
          <td>
            <select
              disabled={d.isSaveChassis}
              autoComplete="off"
              style={{ width: "160px", height: "25px", marginLeft: "-5px" }}
              list="Chassis_No"
              value={d.chassis_ID}
              title={d.chassis_ID}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
              name="Chassis_No"
            >
              <option value={0}>Select</option>
              {this.GetChassiList(d.options)}
            </select>
          </td>
          <td style={{ width: "10%" }}>
            <input
              disabled
              autoComplete="off"
              style={{ width: "160px" }}
              value={d.Vehicle_Number}
              name="Vehicle_Number"
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>

          <td style={{ width: "10%" }}>
            <input
              disabled
              autoComplete="off"
              style={{ width: "160px" }}
              value={d.Motor_Number}
              name={"Motor_Number"}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "90px" }}>
            <input
              style={{ width: "160px" }}
              autoComplete="off"
              name="ActualMotor_No"
              disabled
              //disabled={d.isSaveChassis}
              value={d.ActualMotor_No}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              disabled
              style={{ width: "160px" }}
              autoComplete="off"
              name={d.Battery_Number}
              value={d.Battery_Number}
              // onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              disabled
              // disabled={d.isSaveChassis}
              style={{ width: "160px" }}
              autoComplete="off"
              name="Actual_Battery_Number"
              value={d.Actual_Battery_Number}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              disabled
              style={{ width: "160px" }}
              autoComplete="off"
              name={"Charger_Number"}
              value={d.Charger_Number}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              style={{ width: "160px" }}
              autoComplete="off"
              disabled
              name="Actual_Charger_Number"
              //disabled={d.isSaveChassis}
              value={d.Actual_Charger_Number}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              disabled
              style={{ width: "160px" }}
              autoComplete="off"
              name="Controller_Number"
              value={d.Controller_Number}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "100%" }}>
            <input
              disabled
              //disabled={d.isSaveChassis}
              style={{ width: "160px" }}
              autoComplete="off"
              name="ActualController_Unit_No"
              value={d.ActualController_Unit_No}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              disabled
              style={{ width: "160px" }}
              autoComplete="off"
              name="Display_Number"
              // type="number"
              value={d.Display_Number}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "100%" }}>
            <input
              style={{ width: "160px" }}
              autoComplete="off"
              disabled
              //disabled={d.isSaveChassis}
              name="ActualDispayNo"
              value={d.ActualDispayNo}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              style={{ width: "160px" }}
              autoComplete="off"
              name="IOT_Device_Number"
              type="number"
              disabled
              value={d.IOT_Device_Number}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>

          <td style={{ width: "100%" }}>
            <input
              style={{ width: "160px" }}
              autoComplete="off"
              name="ActualIOT_Device_ID"
              disabled
              //disabled={d.isSaveChassis}
              value={d.ActualIOT_Device_ID}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>

          <td style={{ width: "10%", backgroundColor: "white" }}>
            <img
              src={require("../../../components/Images/trash.png")}
              style={{ width: "20px", cursor: "pointer" }}
              onClick={() => this.handel_ActionDelete(d.id)}
            ></img>
          </td>
        </tr>
      );
    });
    return (
      <div ref={this.messagesContainerRef} className="newMapper-popup">
        <div
          className="row justify-content-end"
          style={{ overflowY: "auto", marginTop: "-15px" }}
        >
          <label
            style={{
              color: "var(--main-bg-color)",
              cursor: "pointer",
              marginTop: "5px",
            }}
            onClick={this.Create_VDI}
          >
            VDI Creation
          </label>
          <button
            disabled={this.state.isDouble}
            type="submit"
            className="btn Opertion_btn clickable mr-4"
            style={{ fontSize: "12px", float: "right", clear: "both" }}
            onClick={this.AllocationValidate}
          >
            Save
          </button>
        </div>
        <div
          ref={this.messagesContainerRef}
          className="newMapper-popup"
          style={{ overflowY: "auto" }}
        >
          <table
            className="table table-bordered"
            id="userList"
            align="center"
            style={{ width: "100%" }}
          >
            <thead>{this.renderTableHeader12()}</thead>
            <tbody>{this.state.row}</tbody>
          </table>
        </div>
      </div>
    );
  }
  scrollToBottom() {
    if (this.messagesContainerRef.current) {
      const container = this.messagesContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }
  renderTableHeader12() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          width: "90%",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        <td style={{ width: "10%" }} className="table-text">
          Chassis No.
        </td>
        <td className="table-text">Vehicle No.</td>
        <td className="table-text">Motor No.</td>
        <td className="table-text">Replaced Motor No.</td>
        <td className="table-text">Battery No.</td>
        <td className="table-text">Replaced Battery No.</td>
        <td className="table-text">Charger No.</td>
        <td className="table-text">Replaced Charger No.</td>
        <td className="table-text">Controller No.</td>
        <td className="table-text">Replaced Controller No.</td>
        <td className="table-text">Display No.</td>
        <td className="table-text">Replaced Display No.</td>
        <td className="table-text">IOT Device No.</td>
        <td className="table-text">Replaced IOT Device No.</td>
        <td className="table-text"></td>
      </tr>
    );
  }
  getChassidetails = async (model_category_id, id) => {
    const url = Urls.python_baseurl + "/chassis_SP_GetAllocationChassisDet";
    var data = JSON.stringify({
      ChassisID: model_category_id,
      Dealer: sessionStorage.getItem("dealerid"),
      HoBr: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            (prev) => {
              return {
                ...prev,
                allocat_Detail: [
                  ...prev.allocat_Detail.map((el, idx) => {
                    if (idx === id) {
                      return {
                        ...el,
                        ["chassis_ID"]: res[0].id,
                        ["Chassis_No"]: res[0].Chassis_no,
                        ["Vehicle_Number"]: res[0].Vehicle_no,
                        ["Motor_Number"]: res[0].motor_no,
                        ["Battery_Number"]: res[0].batteryno,
                        ["Charger_Number"]: res[0].chargerno,
                        ["Controller_Number"]: res[0].controller_unit_no
                          ? res[0].controller_unit_no
                          : "",
                        ["Display_Number"]: res[0].dispayno,
                        ["IOT_Device_Number"]: res[0].IOT_device_ID,
                        ["Actual_Battery_Number"]: res[0].Actual_Battery_No,
                        ["Actual_Charger_Number"]: res[0].Actual_Charger_No,
                        ["ActualController_Unit_No"]:
                          res[0].ActualController_Unit_No,
                        ["ActualMotor_No"]: res[0].ActualMotor_No,
                        ["ActualDispayNo"]: res[0].ActualDispayNo,
                        ["ActualIOT_Device_ID"]: res[0].ActualIOT_Device_ID,
                        ["isSaveChassis"]: false,
                      };
                    } else {
                      return { ...el };
                    }
                  }),
                ],
              };
            },
            () => {
              console.log(this.state.allocat_Detail, "kjjkkj");
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Allocation_Delete = async () => {
    let allocat = [];

    // for (let index = 0; index < this.state.delete_Allocation.length; index++) {
    //   if (this.state.delete_Allocation[index].Chassis_No != null&&this.state.delete_Allocation[index].Chassis_No != "") {
    //     allocat.push(this.state.delete_Allocation[index]);
    //   }
    // }
    let selectedid = "";
    let data1 = this.state.delete_Allocation.map((e) => {
      selectedid = e?.id;
      return {
        ID: `${e.Allocation_id}`,
        UserId: `${parseInt(sessionStorage.getItem("dealerid"))}`,
        HOBrID: `${parseInt(sessionStorage.getItem("dealerid"))}`,
        M7ID: `${this.state.MR_ID}`,
        Chassis: `${e.chassis_ID}`,
        VehicleNo: `'${e.Vehicle_Number}'`,
        MotorNo: `'${e.Motor_Number}'`,
        BatteryNo: `'${e.Battery_Number}'`,
        ChargerNo: `'${e.Charger_Number}'`,
        ControllerNo: e.Controller_Number ? `'${e.Controller_Number}'` : "''",
        DisplayNo: `'${e.Display_Number}'`,
        IOTDeviceID: `'${e.IOT_Device_Number}'`,
        ActualBatteryNo: `'${
          e.Actual_Battery_Number ? e.Actual_Battery_Number : ""
        }'`,
        ActualChargerNo: `'${
          e.Actual_Charger_Number ? e.Actual_Charger_Number : ""
        }'`,
        MTIInv: `''`,
        MTIDate: `''`,
        qty: "1",
        M3Hdr: `${this.state.M3_ID}`,
        Status: "'D'",
        ActualController_Unit_No: `'${
          e.ActualController_Unit_No ? e.ActualController_Unit_No : ""
        }'`,
        ActualMotor_No: `'${e.ActualMotor_No ? e.ActualMotor_No : ""}'`,
        ActualDispayNo: `'${e.ActualDispayNo ? e.ActualDispayNo : ""}'`,
        ActualIOT_Device_ID: `'${
          e.ActualIOT_Device_ID ? e.ActualIOT_Device_ID : ""
        }'`,
      };
    });

    console.log("data1", data1);
    const url = Urls.python_baseurl + "/Save_allocation_SP_SaveM7Allocation";
    var data = JSON.stringify(data1);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0][0].id) {
            // const remaningArray = this.state.allocat_Detail.map((e, index) => {
            //   if (e.id == this.state.selectedid) {
            //     return {
            //       ...e,
            //       Chassis_No: "",
            //       Vehicle_Number: "",
            //       Motor_Number: "",
            //       Battery_Number: "",
            //       Charger_Number: "",
            //       Controller_Number: "",
            //       Display_Number: "",
            //       IOT_Device_Number: "",
            //       Actual_Battery_Number: "",
            //       Actual_Charger_Number: "",
            //       chassis_ID: "",
            //       ActualController_Unit_No: "",
            //       ActualMotor_No: "",
            //       ActualDispayNo: "",
            //       ActualIOT_Device_ID: "",
            //       options: "",
            //       isSaveChassis: false,
            //       options: e.options,
            //     };
            //   } else {
            //     return e;
            //   }
            // });
            this.setState(
              {
                // allocat_Detail:remaningArray
              },
              () => {
                this.Mapper_handlInpuChange(
                  { target: { name: "Chassis_No", value: "0" } },
                  this.state.selectedid
                );
                alert("Record Deleted Succesfully");
                $("#GLKickOut_Popup").modal("hide");
                $("#payment_error_modal_2").modal("hide");
                $("#payment_error_modal_2").modal("show");
              }
            );

            // window.location.reload();
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Allocation_Save = async () => {
    let allocat = [];
    for (let index = 0; index < this.state.allocat_Detail.length; index++) {
      if (
        this.state.allocat_Detail[index].Chassis_No != null &&
        this.state.allocat_Detail[index].Chassis_No != ""
      ) {
        allocat.push(this.state.allocat_Detail[index]);
      }
    }
    let data1 = allocat.map((e) => {
      console.log("eww", e);

      return {
        ID: `${e.Allocation_id}`,
        UserId: `${parseInt(sessionStorage.getItem("dealerid"))}`,
        HOBrID: `${parseInt(sessionStorage.getItem("dealerid"))}`,
        M7ID: `${this.state.MR_ID}`,
        Chassis: `${e.chassis_ID}`,
        VehicleNo: `'${e.Vehicle_Number}'`,
        MotorNo: `'${e.Motor_Number}'`,
        BatteryNo: `'${e.Battery_Number}'`,
        ChargerNo: `'${e.Charger_Number}'`,
        ControllerNo: `'${e.Controller_Number}'`,
        DisplayNo: `'${e.Display_Number}'`,
        IOTDeviceID: `'${e.IOT_Device_Number}'`,
        ActualBatteryNo: `'${
          e.Actual_Battery_Number ? e.Actual_Battery_Number : ""
        }'`,
        ActualChargerNo: `'${
          e.Actual_Charger_Number ? e.Actual_Charger_Number : ""
        }'`,
        MTIInv: `''`,
        MTIDate: `''`,
        qty: "1",
        M3Hdr: `${this.state.M3_ID}`,
        Status: "'E'",
        ActualController_Unit_No: `'${
          e.ActualController_Unit_No ? e.ActualController_Unit_No : ""
        }'`,
        ActualMotor_No: `'${e.ActualMotor_No ? e.ActualMotor_No : ""}'`,
        ActualDispayNo: `'${e.ActualDispayNo ? e.ActualDispayNo : ""}'`,
        ActualIOT_Device_ID: `'${
          e.ActualIOT_Device_ID ? e.ActualIOT_Device_ID : ""
        }'`,
      };
    });

    console.log("data1", data1);
    const url = Urls.python_baseurl + "/Save_allocation_SP_SaveM7Allocation";
    var data = JSON.stringify(data1);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0][0].id) {
            alert("Record Allocated Succesfully");
            this.getM7Details();
            this.setState({
              isDouble: false,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Mapper_handlInpuChange = (e, id) => {
    const { name, value } = e.target;
    if (name == "Chassis_No") {
      if (value != "0") {
        let selectedrow = this.state.allocat_Detail?.filter((e) => e.id == id);
        let selectedChassis = selectedrow[0]?.options?.filter(
          (e) => e.ID == value
        );
        let remaningChassis = selectedrow[0]?.options?.filter(
          (e) => e.ID != value
        );
        let newAllocation = this.state.allocat_Detail.map((e) => {
          if (e.id == id) {
            return { ...e, isSelected: true, selectedChassis: selectedChassis };
          } else {
            if (!e.isSelected) {
              return { ...e, options: remaningChassis };
            } else {
              return {
                ...e,
                options: [...remaningChassis, e.selectedChassis[0]],
              };
            }
          }
        });
        this.setState(
          {
            allocat_Detail: newAllocation,
          },
          () => {
            console.log(this.state.allocat_Detail, "kjkjkjk");
            this.getChassidetails(value, id);
          }
        );
      } else {
        let unselectedChassis = this.state.allocat_Detail.filter(
          (e) => e.id == id
        )[0]?.selectedChassis[0];
        let newAllocation = this.state.allocat_Detail.map((e) => {
          if (e.id != id) {
            return { ...e, options: [...e.options, unselectedChassis] };
          } else {
            return {
              ...e,
              Chassis_No: "",
              Vehicle_Number: "",
              Motor_Number: "",
              Battery_Number: "",
              Charger_Number: "",
              Controller_Number: "",
              Display_Number: "",
              IOT_Device_Number: "",
              Actual_Battery_Number: "",
              Actual_Charger_Number: "",
              chassis_ID: "",
              ActualController_Unit_No: "",
              ActualMotor_No: "",
              ActualDispayNo: "",
              ActualIOT_Device_ID: "",
              isSaveChassis: false,
              options: e.options,
              isSelected: false,
              selectedChassis: [],
            };
          }
        });
        this.setState(
          {
            allocat_Detail: newAllocation,
          },
          () => {
            console.log(this.state.allocat_Detail, "kjkjkjk");
          }
        );
      }
    } else {
      let newAllocation = this.state.allocat_Detail.map((e) => {
        if (e.id == id) {
          return {
            ...e,
            [name]: value,
          };
        } else {
          return e;
        }
      });
      this.setState({
        allocat_Detail: newAllocation,
      });
    }
  };
  showModel = () => {
    return (
      <div className=" row">
        <div className=" col-3">
          <label htmlFor="input-1">Model Category </label>
          <input
            value={this.state.Model_Category_value}
            readOnly
            id="input-1"
            className=" form-control "
          ></input>
        </div>
        <div className=" col-3">
          <label htmlFor="input-2">Model Code </label>
          <input
            value={this.state.Model_Code_value}
            readOnly
            id="input-2"
            className=" form-control "
          ></input>
        </div>
        <div className=" col-3">
          <label htmlFor="input-3">Model </label>
          <input
            value={this.state.Model}
            readOnly
            id="input-3"
            className=" form-control "
          ></input>
        </div>
        <div className=" col-3">
          <label htmlFor="input-3">Quantity </label>
          <input
            onChange={(e) => this.setState({ Quantity: e.target.value })}
            value={this.state.Quantity}
            readOnly
            id="input-3"
            className=" form-control "
          ></input>
        </div>
      </div>
    );
  };
  handledateChange = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  };
  GetChassisNo = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "225",
      ID: sessionStorage.getItem("dealerid"),
      condition: `'and Alloaction.Model_ID=${this.state.temp_model_name}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          const newAllocation = this.state.allocat_Detail.map((e) => {
            if (e.id == this.state.selectedid) {
              return { ...e, options: res };
            } else {
              return e;
            }
          });
          this.setState(
            {
              ChassisNoOption: res,
              allocat_Detail: newAllocation,
            },
            () => {
              if (this.state.vehicle_id === 0) {
                this.createrows(res);
              }
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelAccessoriesSave = (id, msg) => {
    const url = Urls.python_baseurl + "/SP_Save_M7_Accesories";
    let data1 = this.state.ConditionCode.map((e) => {
      return {
        ID:
          this.props.modelid == "pendingClick"
            ? "0"
            : e.accesores_ID
            ? `${e.accesores_ID}`
            : "0",
        M7_Hdr: `${id}`,
        Acc_id: `${e.accid}`,
        cr_date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
        Accessories_Amount: `${e.accessories_amount}`,
        HSN: `'${e.hsn}'`,
        IGST: `${e.igst}`,
        IGST_Amount: `${e.igst_amount}`,
        SGST: `${e.sgst}`,
        SGST_Amount: `${e.sgst_amount}`,
        CSGT: `${e.cgst}`,
        CSGT_Amount: `${e.cgst_amount}`,
        Total_With_Tax: `${e.accessories_total_withtax}`,
      };
    });
    var data = JSON.stringify(data1);
    console.log("hab", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.handelPaymentDetailsSave(id, msg);
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelPaymentDetailsSave = (id, msg) => {
    const url = Urls.python_baseurl + "/SP_Save_MoneyReceived_PaymentDetails";
    let data1 = this.state.payment_details.map((e) => {
      return {
        ID:
          this.props.modelid == "pendingClick"
            ? "0"
            : e.saved_id
            ? `${e.saved_id}`
            : "0",
        M7ID: `${id}`,
        Payment_Instalment: `${e.installment_no.split(" ")[1]}`,
        PAyment_Amount: `'${e.payment_amount}'`,
        Mode_Of_Payment: `${e.payment_mode}`,
        Cheque_transactiondetails: `'${e.check_details}'`,
        Cheque_transactionDate: `'${moment(e.check_date).format(
          "YYYY-MM-DD"
        )}'`,
      };
    });
    var data = JSON.stringify(data1);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          alert(msg);
          window.location.reload();
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelSave = () => {
    const url =
      Urls.python_baseurl + "/money_receipt_save_header_SP_Save_M7Header";
    var data = JSON.stringify({
      iID: this.state.MR_ID,
      dealerID: `${sessionStorage.getItem("dealerid")}`,
      HOBrID: `${sessionStorage.getItem("dealerid")}`,
      M7No: `'${this.state.ML7_No}'`,
      M7Date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      // CashLoan: `'${this.state.Payment_mode}'`,
      CashLoan: `''`,
      MarginAmt: `${this.state.Payment_amount}`,
      // CHRTGSDet: `'${this.state.cheque_details}'`,
      CHRTGSDet: `''`,
      // CHRTGSDate: `'${moment(this.state.Cheque_Date).format("DD-MM-YYYY")}'`,
      CHRTGSDate: `''`,
      Confirm: "'N'",
      OrderStatus: "'N'",
      PrvID: `${
        this.props.modelid == "pendingClick"
          ? this.state.prevID
          : this.state.M3_ID
      }`,
      LossApp: "''",
      DlrCode: `'${this.state.Dealer_Code}'`,
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      Grand_Total: `'${this.state.Grand_Total}'`,
      Accesories_Amt: `'${this.state.Total_Accessories_Amount}'`,
      Model: `'${this.state.model_group}'`,
      Model_Name: `'${this.state.model_name}'`,
      Model_Code: `'${this.state.model_code}'`,
      Model_Colour: `'${this.state.model_color}'`,
      Ex_showroom_price: `'${this.state.AMV_Sale_Rate}'`,
      Quantity: `'${this.state.Quantity}'`,
      Dealer_proposed_discount_per_unit: `'${this.state.AMV_Dealer_Proposed_Amount}'`,
      State_subsidy_amout_per_unit: `'${this.state.AMV_Subsidy_Amount}'`,
      Central_subsidy_amout_per_unit: `'${this.state.AMV_Central_Subsidy_Amount}'`,
      Scheme_name: `'${this.state.SchemeName}'`,
      Scheme_amount_per_unit: `'${this.state.SchemeAmount}'`,
      Taxable_Amount: `'${this.state.Taxable_Amount}'`,
      CGST_amount_per_unit: `'${this.state.CGST_Amount?this.state.CGST_Amount:0}'`,
      SGST_amount_per_unit: `'${this.state.SGST_Amount?this.state.SGST_Amount:0}'`,
      RTO_Amount_Per_unit: `'${this.state.AMV_RTO_Amount}'`,
      RTO_Discount_Per_unit: `'${this.state.AMV_RTO_Discount}'`,
      RTO_Discount_amount_Per_unit: `'${this.state.AMV_RTO_Discount_Amount}'`,
      CRTM_Amount_Per_unit: `'${this.state.AMV_CRTM_Amount}'`,
      HPA_Charge_Per_unit: `'${this.state.AMV_HPA_Charges}'`,
      SmartCard_Charge_Per_unit: `'${this.state.AMV_Smart_Card_Amount}'`,
      HSRP_Charge_Per_unit: `'${this.state.HSRP_Charge}'`,
      Other_RTO_Charge_Per_unit: `'${this.state.Other_RTO_Charge}'`,
      Insurance_Company: `'${this.state.Insurance_company}'`,
      Insurance_Type: `'${this.state.AMV_Insurance_Year}'`,
      Insurance_Amount_Per_unit: `'${this.state.AMV_Insurance_Amount}'`,
      Other_Charges_Per_unit: `'${this.state.Other_Charges}'`,
      Exchange_Bonus_tax_per_unit: `'${this.state.Exchange_Bonus_Amount}'`,
      IGST_amount_per_unit: "'0'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res[0].newID) {
          this.setState(
            {
              flag: false,
            },
            () => {
              this.setState({
                isDouble: false,
                Enq_money_id: res[0].newID,
              });
              if (
                this.state.ConditionCode.length > 0 &&
                this.state.ConditionCode[0].accessories_name
              ) {
                this.handelAccessoriesSave(
                  res[0].newID,
                  "Record Saved Succesfully"
                );
              } else {
                this.handelPaymentDetailsSave(res[0].newID,"Record Saved Succesfully");
                // alert("Record Saved Successfully");
                // window.location.reload();
              }
            }
          );
        }
        if (res[0].ID) {
          this.setState({
            isDouble: false,
          });
          if (
            this.state.ConditionCode.length > 0 &&
            this.state.ConditionCode[0].accessories_name
          ) {
            this.handelAccessoriesSave(res[0].ID, "Record Updated Succesfully");
          } else {
            this.handelPaymentDetailsSave(res[0].ID, "Record Updated Succesfully");
            // alert("Record Updated Succesfully");
            // window.location.reload();
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  maxNo = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      DocName: "'MR'",
      DealerId: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            this.setState(
              {
                flag: false,
              },
              () => {
                this.setState({
                  isDouble: false,
                });
                alert("Record Saved Succesfully");
                window.location.reload();
              }
            );
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelconfirm = () => {
    if (
      this.state.Pending_Amount &&
      parseFloat(this.state.Pending_Amount)?.toFixed(2) !=
        this.state.Payment_amount &&
      parseFloat(this.state.Payment_amount)?.toFixed(2)
    ) {
      alert("Balance Amount and Pending Amount Must Be Same.");
      return;
    }
    for(let i=0;i<this.state.payment_details.length;i++){
      if(this.state.payment_details[i].payment_mode==""){
        alert("Please Select Payment Mode")
        return
      }else if(this.state.payment_details[i].check_details==""){
        alert("Please Enter Check Details")
        return
      }else if(this.state.payment_details[i].check_date==""){
        alert("Please Choose Check Date")
        return
      }else if(this.state.payment_details[i].payment_amount==""){
        alert("Please Enter Payment Amount")
        return
      }
    }
    this.setState({
      isDouble: true,
    });
    const url =
      Urls.python_baseurl + "/money_receipt_save_header_SP_Save_M7Header";
    var data = JSON.stringify({
      iID: this.state.MR_ID,
      dealerID: `${sessionStorage.getItem("dealerid")}`,
      HOBrID: `${sessionStorage.getItem("dealerid")}`,
      M7No: `'${this.state.ML7_No}'`,
      M7Date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      // CashLoan: `'${this.state.Payment_mode}'`,
      CashLoan: `''`,
      MarginAmt: `'${this.state.Payment_amount}'`,
      // CHRTGSDet: `'${this.state.cheque_details}'`,
      CHRTGSDet: `''`,
      // CHRTGSDate: `'${moment(this.state.Cheque_Date).format("DD-MM-YYYY")}'`,
      CHRTGSDate: `''`,
      Confirm: "'Y'",
      OrderStatus: "'N'",
      PrvID: `${this.state.M3_ID}`,
      LossApp: "''",
      ActualController_Unit_No: "''",
      ActualMotor_No: "''",
      ActualDispayNo: "''",
      ActualIOT_Device_ID: "''",
      DlrCode: `'${this.state.Dealer_Code}'`,
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      Grand_Total: `'${this.state.Grand_Total}'`,
      Accesories_Amt: `'${this.state.Total_Accessories_Amount}'`,
      Model: `'${this.state.model_group}'`,
      Model_Name: `'${this.state.model_name}'`,
      Model_Code: `'${this.state.model_code}'`,
      Model_Colour: `'${this.state.model_color}'`,
      Ex_showroom_price: `'${this.state.AMV_Sale_Rate}'`,
      Quantity: `'${this.state.Quantity}'`,
      Dealer_proposed_discount_per_unit: `'${this.state.AMV_Dealer_Proposed_Amount}'`,
      State_subsidy_amout_per_unit: `'${this.state.AMV_Subsidy_Amount}'`,
      Central_subsidy_amout_per_unit: `'${this.state.AMV_Central_Subsidy_Amount}'`,
      Scheme_name: `'${this.state.SchemeName}'`,
      Scheme_amount_per_unit: `'${this.state.SchemeAmount}'`,
      Taxable_Amount: `'${this.state.Taxable_Amount}'`,
      CGST_amount_per_unit: `'${this.state.CGST_Amount?this.state.CGST_Amount:0}'`,
      SGST_amount_per_unit: `'${this.state.SGST_Amount?this.state.SGST_Amount:0}'`,
      RTO_Amount_Per_unit: `'${this.state.AMV_RTO_Amount}'`,
      RTO_Discount_Per_unit: `'${this.state.AMV_RTO_Discount}'`,
      RTO_Discount_amount_Per_unit: `'${this.state.AMV_RTO_Discount_Amount}'`,
      CRTM_Amount_Per_unit: `'${this.state.AMV_CRTM_Amount}'`,
      HPA_Charge_Per_unit: `'${this.state.AMV_HPA_Charges}'`,
      SmartCard_Charge_Per_unit: `'${this.state.AMV_Smart_Card_Amount}'`,
      HSRP_Charge_Per_unit: `'${this.state.HSRP_Charge}'`,
      Other_RTO_Charge_Per_unit: `'${this.state.Other_RTO_Charge}'`,
      Insurance_Company: `'${this.state.Insurance_company}'`,
      Insurance_Type: `'${this.state.AMV_Insurance_Year}'`,
      Insurance_Amount_Per_unit: `'${this.state.AMV_Insurance_Amount}'`,
      Other_Charges_Per_unit: `'${this.state.Other_Charges}'`,
      Exchange_Bonus_tax_per_unit: `'${this.state.Exchange_Bonus_Amount}'`,
      IGST_amount_per_unit: "'0'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res[0].ID) {
          this.setState({
            isDouble: false,
          });
          alert("Record Confirmed Succesfully");
          window.location.reload();
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Validate = () => {
    const isValid =
      this.state.Payment_amount != "" &&
      this.state.Customer_Name != "" &&
      this.state.AMV_Insurance_Amount !== "" &&
      this.state.AMV_Insurance_Year !== "" &&
      this.state.Insurance_company !== "";

      for (let i = 0; i < this.state.payment_details.length; i++) {
        if (this.state.payment_details[i].payment_mode == "") {
          alert("Please Select Payment Mode");
          return;
        } else if (this.state.payment_details[i].check_details == "") {
          alert("Please Enter Check Details");
          return;
        } else if (this.state.payment_details[i].check_date == "") {
          alert("Please Choose Check Date");
          return;
        } else if (this.state.payment_details[i].payment_amount == "") {
          alert("Please Enter Payment Amount");
          return;
        }
      }
    if (isValid) {
      this.setState({
        isDouble: true,
      });
      !(this.state.isSavedClick == "saveClcik") && this.Save_M7_No();
      this.state.isSavedClick == "saveClcik" && this.handelSave();
    } else {
      alert("Please fill all mandatory field.");
    }
  };
  Create_VDI = () => {
    $("#payment_error_modal_2").modal("hide");
    this.props.history.push("/Job_Card_Details", {
      data: [
        {
          VDI_Creation: true,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        },
      ],
    });
  };
  AllocationValidate = () => {
    let Valid_allocat = [];
    for (let index = 0; index < this.state.allocat_Detail.length; index++) {
      if (
        this.state.allocat_Detail[index].Chassis_No != null &&
        this.state.allocat_Detail[index].Chassis_No != "" &&
        this.state.allocat_Detail[index].Chassis_No != 0
      ) {
        console.log("jkkkkkk", this.state.allocat_Detail[index].Chassis_No);
        Valid_allocat.push(this.state.allocat_Detail[index]);
      }
    }
    let isValid = false;
    console.log("uyti", Valid_allocat);
    Valid_allocat.forEach((element) => {
      isValid = element.Chassis_No != "";
    });
    if (isValid) {
      this.setState({
        isDouble: true,
      });
      this.Allocation_Save();
    } else {
      alert("Please fill all mandatory field.");
    }
  };
  getPaymentDetails = () => {
    const url = Urls.python_baseurl + "/SP_GetMoneyReceived_PaymentDetails";
    var data = JSON.stringify({
      M7ID: this.state.Enq_money_id
        ? this.state.Enq_money_id
        : this.state.isSavedClick == "pendingClick"
        ? "0"
        : this.state.MR_ID,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if(res&&res.length>0){
          let result = res.map((e, index) => {
            return {
              id: index,
              saved_id: e.ID,
              installment_no: `Installment ${parseInt(e.Payment_Instalment)}`,
              payment_mode: e.Mode_Of_Payment,
              check_details: e.Cheque_transactiondetails,
              check_date: e.Cheque_transactionDate.split("T")[0],
              payment_amount: e.PAyment_Amount,
            };
          });
          if (res) {
            this.setState({
              payment_details: result,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  M7_PaymentDetails = () => {
    const tableHeader = () => {
      return (
        <tr
          className="table-head-text-view"
          style={{
            backgroundColor: "var(--main-bg-color)",
            width: "90%",
            fontSize: "12px",
            color: "white",
            position: "sticky",
            top: "0",
          }}
        >
          <td style={{ width: "5%" }} className="table-text"></td>
          <td style={{ width: "10%" }} className="table-text">
            Installment No.
          </td>
          <td className="table-text">Payment Mode</td>
          <td className="table-text">Cheque/Transaction Details</td>
          <td className="table-text">Cheque/Transfer Date</td>
          <td className="table-text">Payment Amount</td>
          <td style={{ width: "5%" }} className="table-text"></td>
        </tr>
      );
    };
    const tableBody = () => {
      const handelchange = (e, id) => {
        const { name, value } = e.target;
        let row = this.state.payment_details.map((ele) => {
          if (ele.id == id) {
            return { ...ele, [name]: value };
          } else {
            return ele;
          }
        });
        this.setState(
          {
            payment_details: row,
          },
          () => {
            let Payment_amount = 0;
            this.state.payment_details.map((e) => {
              Payment_amount = Payment_amount + +(e.payment_amount || 0);
            });
            this.setState({
              Payment_amount: Payment_amount,
            });
          }
        );
      };
      const addNew = () => {
        let row = [
          ...this.state.payment_details,
          {
            id: this.state.payment_details.length,
            installment_no: `Installment ${
              this.state.payment_details.length + 1
            }`,
            payment_mode: "",
            check_details: "",
            check_date: "",
            payment_amount: "",
          },
        ];
        this.setState({
          payment_details: row,
        });
      };
      const deleteRow = (id) => {
        let row = this.state.payment_details.filter((e) => e.id !== id);
        row = row.map((e, index) => {
          return { ...e, installment_no: `Installement ${index + 1}` };
        });
        this.setState({
          payment_details: row,
        });
      };
      return this.state.payment_details.map((e, index) => {
        return (
          <tr>
            <td>
              {index === this.state.payment_details.length - 1 && (
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  style={{
                    color: "var(--main-bg-color)",
                    fontSize: "25px",
                    opacity: `${(this.state.Pending_Amount||0)-(this.state.Payment_amount||0)?"100%":"50%"}`,
                    cursor: `${(this.state.Pending_Amount||0)-(this.state.Payment_amount||0)?"pointer":""}`,
                  }}
                  onClick={() => {
                    if((this.state.Pending_Amount||0)-(this.state.Payment_amount||0)){
                      addNew();
                    }
                    
                  }}
                />
              )}
            </td>
            <td>
              <input
                disabled
                className=" form-control"
                value={e.installment_no}
                name="installment_no"
                onClick={(ele) => handelchange(ele, e.id)}
              />
            </td>
            <td>
              <select
                className=" form-control"
                value={e.payment_mode}
                name="payment_mode"
                onChange={(ele) => handelchange(ele, e.id)}
              >
                <option value={0}>Select</option>

                <option value="RTGS">RTGS</option>
                <option value="Cash">Cash</option>
                <option value="NEFT">NEFT</option>
                <option value="DD">DD</option>
                <option value="Cheque">Cheque</option>
                <option value="IMPS">IMPS</option>
                <option value="Credit_Card">Credit Card</option>
                <option value="Debit_Card">Debit Card</option>
                <option value="UPI">UPI</option>
                <option value="Others">Others</option>
              </select>
            </td>

            <td>
              <input
                className=" form-control"
                value={e.check_details}
                name="check_details"
                onChange={(ele) => handelchange(ele, e.id)}
              />
            </td>
            <td>
              <input
                className=" form-control"
                value={e.check_date}
                name="check_date"
                onChange={(ele) => handelchange(ele, e.id)}
                type="date"
              />
            </td>
            <td>
              <input
                className=" form-control"
                value={e.payment_amount}
                name="payment_amount"
                onChange={(ele) => {
                  let number = ele.target.value;
                  console.log("number",number);
                  let newpending_amt=parseFloat(this.state.Pending_Amount).toFixed(2)
                  // alert(newpending_amt)
                  if (isNaN(number) || number < 0||(((this.state.Pending_Amount||0)-(this.state.Payment_amount||0)).toFixed(2))<0) {
                    
                    alert("Payment amount cannot be greater than balance amount")
                    handelchange(
                      { target: { name: "payment_amount", value: "" } },
                      e.id
                    );
                  } else {
                    handelchange(ele, e.id);
                    setTimeout(() => {
                      if ((((this.state.Pending_Amount||0)-(this.state.Payment_amount||0)).toFixed(2))<0) {
                        alert("Payment amount cannot be greater than balance amount")
                        handelchange(
                          { target: { name: "payment_amount", value: "" } },
                          e.id
                        );
                      }
                    }, 0);
                  }
                }}
              />
            </td>
            <td style={{ width: "60px", backgroundColor: "white" }}>
              <img
                src={require("../../../components/Images/trash.png")}
                style={{
                  width: "20px",
                  cursor: `${e.saved_id ? "" : "pointer"}`,
                  opacity: `${e.saved_id ? "50%" : "100%"}`,
                }}
                onClick={() => {
                  if (!e.saved_id) {
                    deleteRow(e.id);
                  }
                }}
              ></img>
            </td>
          </tr>
        );
      });
    };
    return (
      <>
      <table>
      <thead>{tableHeader()}</thead>
      <tbody>{tableBody()}</tbody>
    </table>
    <div className=" mt-3 bold">Balance Amount is {(Math.abs((this.state.Pending_Amount||0)-(this.state.Payment_amount||0)).toFixed(2))} </div>
    </>
    );
  };
  MoneyReceived = () => {
    return (
      <div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Received No. {this.required()}</label>
            <input
              readOnly
              value={this.state.ML7_No}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Received Date {this.required()}</label>
            <input
              readOnly
              value={this.state.Receipt_date}
              className=" form-control "
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="visit-details">
              Payment Mode {this.required()}
            </label>
            <select
              className="form-control"
              value={this.state.Payment_mode}
              onChange={(event) => {
                this.onSelect(event, "Payment_mode");
              }}
            >
              <option value={0}>Select</option>

              <option value="RTGS">RTGS</option>
              <option value="Cash">Cash</option>
              <option value="NEFT">NEFT</option>
              <option value="DD">DD</option>
              <option value="Cheque">Cheque</option>
              <option value="IMPS">IMPS</option>
              <option value="Credit_Card">Credit Card</option>
              <option value="Debit_Card">Debit Card</option>
              <option value="UPI">UPI</option>
              <option value="Others">Others</option>
            </select>
          </div> */}
          <div className=" col-3">
            <label>Payment Amount{this.required()}</label>
            <input
              disabled
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0) {
                  this.setState({ Payment_amount: "" });
                } else {
                  this.setState({
                    Payment_amount: e.target.value,
                  });
                }
              }}
              // onChange={(e) =>
              //   {let number=e.target.value
              //   if(isNaN(number) || number < 0){
              //      {return}
              //   }else{
              //     {this.setState({ Payment_amount: e.target.value })}
              //   }}
              // }
              value={this.state.Payment_amount?this.state.Payment_amount.toFixed(2):0}
              className=" form-control "
              // type="number"
            ></input>
          </div>
        </div>
        <div className=" row mt-1">
          {/* <div className=" col-3">
            <label>Cheque/Transaction Details {this.required()}</label>
            <input
              onChange={(e) =>
                this.setState({ cheque_details: e.target.value })
              }
              value={this.state.cheque_details}
              className=" form-control "
            ></input>
          </div> */}
          {/* <div className=" col-3">
            <label>Cheque/Transfer Date {this.required()}</label>
            <input
              type="date"
              className="form-control"
              placeholder=""
              value={this.state.Cheque_Date}
              onChange={(event) => {
                this.handledateChange(event, "Cheque_Date");
              }}
              min={moment(this.state.Enquiry_date).format("YYYY-MM-DD")}
            />
          </div> */}

          {/* <div className=" col-3">
            <label>Mobile No</label>
            <input
              disabled
              value={this.state.mobile_no}
              className=" form-control "
            ></input>
          </div> */}
          {/* <div className=" col-3">
            <label>Booking No</label>
            <input
              disabled
              value={this.state.booking_no}
              className=" form-control "
            ></input>
          </div> */}
        </div>
        <div className=" row mt-1">{this.M7_PaymentDetails()}</div>
        {/* <div className=" row mt-3">
          <div className=" col-3">
            <label>Booking Date</label>
            <input
              disabled
              className="form-control"
              placeholder=""
              value={this.state.booking_date}
              onChange={(event) => {
                this.handledateChange(event, "booking_date");
              }}
            />
          </div>
          <div className=" col-3">
            <label>Customer Name </label>
            <input
              disabled
              onChange={(e) => this.setState({ Customer_Name: e.target.value })}
              value={this.state.Customer_Name}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Model Name</label>
            <input
              disabled
              onChange={(e) => this.setState({ Model_name: e.target.value })}
              value={this.state.Model_name}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Quantity</label>
            <input
              disabled
              onChange={(e) => this.setState({ Quantity: e.target.value })}
              value={this.state.Quantity}
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className=" row mt-3">
          <div className=" col-3">
            <label>Booking Amount</label>
            <input
              disabled
              onChange={(e) =>
                this.setState({ Booking_Adv_Amt: e.target.value })
              }
              value={this.state.Booking_Adv_Amt}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Total Amount</label>
            <input
              disabled
              onChange={(e) => this.setState({ Total_amount: e.target.value })}
              value={this.state.Total_amount}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Payable Amount</label>
            <input
              disabled
              onChange={(e) =>
                this.setState({ Payable_amount: e.target.value })
              }
              value={this.state.Payable_amount}
              className=" form-control "
            ></input>
          </div>
        </div> */}
      </div>
    );
  };
  visit_details = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "159",
      ID: "'0'",
      condition: "\"and stage='M0'\"",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            vistitdetails: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  vistitdetails = (event) => {
    let row = [];
    this.state.vistitdetails.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  onSelect = (event, key) => {
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
  };
  visitDetails = () => {
    return (
      <div>
        <div class="row">
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">Visit Details</label>
            <select
              className="form-control"
              value={this.state.visit_details}
              onChange={(event) => {
                this.onSelect(event, "visit_details");
              }}
            >
              <option value="0"></option>
              {this.vistitdetails()}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              className="form-control"
              placeholder=""
              value={this.state.startDate}
              onChange={(event) => {
                this.handledateChange(event, "startDate");
              }}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="new-visit-details">New Visit Details</label>
            <select
              className="form-control"
              value={this.state.next_visit_details}
              onChange={(event) => {
                this.onSelect(event, "next_visit_details");
              }}
            >
              <option value="0"></option>
              {this.vistitdetails()}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="date2">Date</label>
            <input
              type="date"
              className="form-control"
              placeholder=""
              value={this.state.endDate}
              onChange={(event) => {
                this.handledateChange(event, "endDate");
              }}
            />
          </div>
        </div>
        <div class="row">
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">Remark</label>
            <input
              value={this.state.Remark}
              onChange={(e) => this.onChangeName(e, "Remark")}
              class="form-control"
              placeholder=""
            ></input>
          </div>
        </div>
      </div>
    );
  };
  handelCancel = () => {
    // alert("Are you sure you want to delet this record.");
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  ClientInformation = () => {
    return (
      <div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Enquiry No.</label>
            <input
              disabled={true}
              value={this.state.enquiry_no}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Enquiry Date</label>
            <input
              disabled={true}
              type="text"
              className="form-control"
              value={
                this.state.Enquiry_date
                  ? moment(this.state.Enquiry_date).format("DD-MM-YYYY")
                  : ""
              }
            />
          </div>
          <div class="form-group col-md-3">
            <label>Customer Name</label>
            <input
              value={this.state.Name}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Mobile</label>
            <input
              value={this.state.mobile_no}
              disabled={true}
              // maxlength="10"
              class="form-control"
              placeholder=""
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Source</label>
            <input
              className="form-control "
              value={
                this.state.SourceListOption.filter((e) => {
                  return e.ID === this.state.Source;
                })[0]?.Name
              }
              disabled={true}
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Type</label>
            <input
              className="form-control "
              // value={this.state.Type}
              disabled={true}
              value={
                this.state.bookingType.filter((e) => {
                  return e.ID === this.state.Type;
                })[0]?.Name
              }
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Title</label>
            <select
              disabled
              className="form-control "
              value={this.state.Title}
              onChange={(event) => this.ChangeVal(event, "Title")}
            >
              <option value="0"></option>
              <option value="1">Mr</option>
              <option value="2">Miss</option>
            </select>
          </div>

          <div class="form-group col-md-3">
            <label>Address 1</label>
            <input
              value={this.state.address1}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Address 2</label>
            <input
              value={this.state.address2}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Pincode</label>
            <input
              value={this.state.pincode}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>City</label>
            <input
              value={this.state.City}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>State</label>
            <input
              className="form-control "
              value={
                this.state.bookingstate.filter((e) => {
                  return e.ID === this.state.State;
                })[0]?.Name
              }
              disabled={true}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>District</label>
            <input
              className="form-control "
              value={
                this.state.booking_district.filter((e) => {
                  return e.ID === this.state.District;
                })[0]?.Name
              }
              disabled={true}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Tehsil</label>
            <input
              className="form-control "
              value={
                this.state.booking_town.filter((e) => {
                  return e.ID === parseInt(this.state.Town);
                })[0]?.Town_Name
              }
              disabled={true}
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Region</label>
            <input
              className="form-control "
              value={
                this.state.booking_region.filter((e) => {
                  return e.ID === parseInt(this.state.Region);
                })[0]?.Name
              }
              disabled={true}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Country </label>
            <input
              disabled={true}
              value={"INDIA"}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Email</label>
            <input
              value={this.state.email}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Attended By</label>
            <input
              className="form-control "
              value={this.state.attendedby}
              disabled={true}
            ></input>
          </div>
          {/* <div class="form-group col-md-3">
            <label>Allocated To</label>
            <input
              className="form-control "
              value={this.state.allocatedto}
              disabled={true}
            ></input>
          </div> */}
        </div>
      </div>
    );
  };
  bookingDetails = () => {
    return (
      <div class="form-row">
        <div className="form-group col-md-3">
          <label>Booking No.</label>
          <input
            readOnly
            value={this.state.Booking_No}
            className=" form-control "
          ></input>
        </div>
        <div className=" form-group col-md-3">
          <label>Booking Date </label>
          <input
            readOnly
            value={this.state.Booking_Date}
            className=" form-control "
          ></input>
        </div>
        <div className=" col-3">
          <label>Vehicle Fund </label>
          <select
            disabled
            onChange={(e) => this.setState({ Vehicle_Fund: e.target.value })}
            value={this.state.Vehicle_Fund}
            className=" form-control "
          >
            <option>Select</option>
            <option value="C">Cash</option>
            <option value="L">Loan</option>
          </select>
        </div>
        {this.state.Vehicle_Fund == "L" && (
          <div className=" col-3">
            <label>Financier</label>
            <select
              disabled
              onChange={(event) => this.ChangeVal(event, "Financier")}
              value={this.state.Financier}
              className=" form-control "
            >
              <option value="0">Select</option>
              {this.Financier_customer()}
            </select>
          </div>
        )}
        <div className="form-group col-md-3">
          <label>Payment Mode </label>
          <select
            disabled
            value={this.state.payment_mode}
            className=" form-control"
          >
            <option value={0}>Select</option>

            <option value="RTGS">RTGS</option>
            <option value="Cash">Cash</option>
            <option value="NEFT">NEFT</option>
            <option value="DD">DD</option>
            <option value="Cheque">Cheque</option>
            <option value="IMPS">IMPS</option>
            <option value="Credit_Card">Credit Card</option>
            <option value="Debit_Card">Debit Card</option>
            <option value="UPI">UPI</option>
            <option value="Others">Others</option>
          </select>
        </div>
        <div className=" form-group col-md-3">
          <label>Cheque/Transaction Details. </label>
          <input
            disabled
            value={this.state.payment_ref_no}
            className=" form-control "
          ></input>
        </div>
        <div className=" col-3">
          <label>Remark</label>
          <input
            disabled
            onChange={(e) => this.setState({ Remark: e.target.value })}
            value={this.state.Remark}
            className=" form-control "
          ></input>
        </div>
        <div className=" col-3">
          <label htmlFor="date">Expected Delivery Date</label>
          <input
            disabled
            className="form-control"
            placeholder=""
            value={this.state.Delivery_Date}
            type="datetime-local"
            onChange={(event) => {
              this.handledateChange(event, "Delivery_Date");
            }}
          />
        </div>
      </div>
    );
  };
  GetModelList = (event) => {
    let row = [];
    this.state.Model_Option.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/ModelGroup_GetModelGroup";
    var data = JSON.stringify({
      id: "null",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Group: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  ModelDetails = () => {
    return (
      <div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Model</label>
            <input
              disabled
              className="form-control "
              value={
                this.state.Model_Group.filter((e) => {
                  return e.ID === parseInt(this.state.modelcat);
                })[0]?.["Model Group"]
              }
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Model Code</label>
            <input
              disabled
              className="form-control "
              value={
                this.state.Model_Code.filter((e) => {
                  return e.ID === parseInt(this.state.modelcode1);
                })[0]?.["Model code"]
              }
              onChange={(event) => this.ChangeVal(event, "ModelCode")}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Model Name</label>
            <input
              disabled
              className="form-control "
              value={
                this.state.Model_Code.filter((e) => {
                  return e.ID === parseInt(this.state.modelcode1);
                })[0]?.["Model name"]
              }
              onChange={(event) => this.ChangeVal(event, "Model")}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Model Colour</label>
            <input
              disabled
              value={
                this.state.Model_Code.filter((e) => {
                  return e.ID === parseInt(this.state.modelcode1);
                })[0]?.Model_Colour
              }
              onChange={(e) => this.onChangeName(e, "Model_Colour")}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Quantity</label>
            <input
              disabled
              value={this.state.Quantity}
              onChange={(e) => this.onChangeName(e, "Quantity")}
              class="form-control"
              placeholder=""
            ></input>
          </div>
        </div>
      </div>
    );
  };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/ModelGroup_GetModelGroup";
    var data = JSON.stringify({
      id: "null",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Category: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetCategory = (event) => {
    let row = [];
    this.state.Model_Category.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element["Model Group"]}</option>);
    });
    return row;
  };
  GetSubsidy = () => {
    const url = Urls.python_baseurl + "/getSubsidy_SP_GetSubsidy";
    var data = JSON.stringify({
      Type: `'${"State"}'`,
      state_id: parseInt(this.state.State),
    });

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let count = 1;
          let stateMin = res[1]?.State_Min || 0;
          let stateMax = res[1]?.State_Max || 0;
          let kwh = parseFloat(this.state.kwh) || 0;

          let subam = kwh * stateMin > stateMax ? stateMax : kwh * stateMin;
          subam = isNaN(subam) ? 0 : subam;

          subam = parseFloat(
            kwh * stateMin > stateMax
              ? stateMax * count
              : kwh * stateMin * count
          );
          subam = isNaN(subam) ? 0 : subam;

          let centralPercentage = res[0]?.Central_Percentage || 0;
          let AMV_Sale_Rate = parseFloat(this.state.AMV_Sale_Rate) || 0;

          let Centerdata = AMV_Sale_Rate * (centralPercentage / 100);
          Centerdata = isNaN(Centerdata) ? 0 : Centerdata;

          let SchemeAmount = parseFloat(this.state.SchemeAmount) || 0;
          let AMV_Dealer_Proposed_Amount =
            parseFloat(this.state.AMV_Dealer_Proposed_Amount) || 0;

          let statetaxamt =
            AMV_Sale_Rate -
            SchemeAmount -
            subam -
            Centerdata -
            AMV_Dealer_Proposed_Amount;
          statetaxamt = isNaN(statetaxamt) ? 0 : statetaxamt;

          let AMV_RTO_Discount = parseFloat(this.state.AMV_RTO_Discount) || 0;
          // let AMV_RTO_Amount =
          //   this.state.model_typecode === "L"
          //     ? 0
          //     : (AMV_Sale_Rate * AMV_RTO_Discount) / 100;
          // AMV_RTO_Amount = isNaN(AMV_RTO_Amount) ? 0 : AMV_RTO_Amount;

          let Booking_Advance_Amount =
            parseFloat(this.state.Booking_Advance_Amount) || 0;
          let Quantity = parseInt(this.state.Quantity) || 1;
          let Booking_Advance_Amount_Each = Booking_Advance_Amount / Quantity;

          this.setState(
            {
              AMV_Subsidy_Amount: subam ? subam : "",
              AMV_Central_Subsidy_Amount: Centerdata ? Centerdata : "",
              Taxable_Amount: statetaxamt,
              // AMV_RTO_Amount: AMV_RTO_Amount,
              Booking_Advance_Amount_Each: isNaN(Booking_Advance_Amount_Each)
                ? 0
                : Booking_Advance_Amount_Each,
            },
            () => {
              // let AMV_RTO_Discount_Amount =
              //   this.state.model_typecode === "L"
              //     ? 0
              //     : (this.state.AMV_RTO_Amount * AMV_RTO_Discount) / 100;
              // AMV_RTO_Discount_Amount = isNaN(AMV_RTO_Discount_Amount)
              //   ? 0
              //   : AMV_RTO_Discount_Amount;

              let AMV_CRTM_Amount = parseFloat(this.state.AMV_CRTM_Amount) || 0;
              let AMV_HPA_Charges = parseFloat(this.state.AMV_HPA_Charges) || 0;
              let AMV_Smart_Card_Amount =
                parseFloat(this.state.AMV_Smart_Card_Amount) || 0;
              let Other_RTO_Charge =
                parseFloat(this.state.Other_RTO_Charge) || 0;
              let HSRP_Charge = parseFloat(this.state.HSRP_Charge) || 0;
              let Total_Accessories_Amount =
                parseFloat(this.state.Total_Accessories_Amount) || 0;
              let AMV_Insurance_Amount =
                parseFloat(this.state.AMV_Insurance_Amount) || 0;
              let Taxable_Amount = parseFloat(this.state.Taxable_Amount) || 0;
              let Other_Charges = parseFloat(this.state.Other_Charges) || 0;
              let Exchange_Bonus_Amount =
                parseFloat(this.state.Exchange_Bonus_Amount) || 0;

              let CGST_Per = parseFloat(this.state.CGST_Per) || 0;
              let IGST_Per = parseFloat(this.state.IGST_Per) || 0;
              let SGST_Per = parseFloat(this.state.SGST_Per) || 0;

              let CGST_Amount = (CGST_Per * statetaxamt) / 100;
              CGST_Amount = isNaN(CGST_Amount) ? 0 : CGST_Amount;

              let IGST_Amount = (IGST_Per * statetaxamt) / 100;
              IGST_Amount = isNaN(IGST_Amount) ? 0 : IGST_Amount;

              let SGST_Amount = (SGST_Per * statetaxamt) / 100;
              SGST_Amount = isNaN(SGST_Amount) ? 0 : SGST_Amount;

              let Grand_Total =
                (parseFloat(AMV_CRTM_Amount) +
                  parseFloat(AMV_HPA_Charges) +
                  parseFloat(AMV_Smart_Card_Amount) +
                  parseFloat(Other_RTO_Charge) +
                  parseFloat(HSRP_Charge) +
                  parseFloat(this.state.AMV_RTO_Amount) +
                  parseFloat(Total_Accessories_Amount) +
                  parseFloat(AMV_Insurance_Amount) +
                  parseFloat(Taxable_Amount) +
                  parseFloat(CGST_Amount) +
                  parseFloat(IGST_Amount) +
                  parseFloat(SGST_Amount) +
                  parseFloat(Other_Charges) -
                  parseFloat(Exchange_Bonus_Amount) -
                  parseFloat(this.state.AMV_RTO_Discount_Amount)) *
                Quantity;
              console.log("Grand_Total", Grand_Total);
              Grand_Total = isNaN(Grand_Total) ? 0 : Grand_Total;

              this.setState({
                // AMV_RTO_Discount_Amount: AMV_RTO_Discount_Amount,
                Grand_Total: Grand_Total,
                Pending_Amount: Grand_Total - Booking_Advance_Amount,
                CGST_Amount: CGST_Amount,
                IGST_Amount: IGST_Amount,
                SGST_Amount: SGST_Amount,
              });
            }
          );
        }
      })
      .catch((err) => {
        if (process.env.NODE_ENV === "development") {
          console.log(err);
        }
      });
  };

  getmodelMRP = (modelId) => {
    const url = Urls.python_baseurl + "/getModelMrp_GetModdelMRP";
    var data = JSON.stringify({
      Dealer: `${sessionStorage.getItem("dealerid")}`,
      ModelID: `${parseInt(modelId)}`,
      StateCode: `${this.state.State ? this.state.State : this.state.stateid}`,

      // ValComboQueryType: 197,
      // iParentId: 0,
      // sAdditionalCondition: `'and Model_cat_ID=${event.target.value}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              AMV_Sale_Rate: res[0][0]?.ex_showroomprice,
              ModelData: res[0][0],
              AMV_CRTM_Amount: this.state.AMV_CRTM_Amount
                ? this.state.AMV_CRTM_Amount
                : res[2][0].crtm_Amt,
              AMV_HPA_Charges: this.state.AMV_HPA_Charges
                ? this.state.AMV_HPA_Charges
                : res[2][0].HPA_Charge,
              AMV_Smart_Card_Amount: this.state.AMV_Smart_Card_Amount
                ? this.state.AMV_Smart_Card_Amount
                : res[2][0].smartcard_Amt,
              model_code: res[1][0]["Model code"],
              model_color: res[1][0].Model_Colour,
              kwh: res[1][0]?.kw ? res[1][0].kw : 1,
              AMV_RTO_Per: res[2][0]?.Percentage,
              Total: res[0][0].MRP + res[0][0].HPA_Charge,
              TotalCopy: res[0][0].MRP + res[0][0].HPA_Charge,
              HSRP_Charge: this.state.AMV_HSRP_Charges
                ? this.state.AMV_HSRP_Charges
                : res[2][0]?.HSRP_Charge,
              Other_RTO_Charge: this.state.Other_RTO_Charges
                ? this.state.Other_RTO_Charges
                : res[2][0]?.other_rto,
              AMV_RTO_Discount:
                this.state.model_typecode === "L" ? 0 : res[2][0]?.discount_per,
              AMV_RTO_Amount:
                (this.state.model_typecode === "L"
                  ? 0
                  : parseFloat(res[0][0]?.ex_showroomprice) *
                    (res[2][0]?.Percentage / 100)) &&
                (this.state.model_typecode === "L"
                  ? 0
                  : parseFloat(res[0][0]?.ex_showroomprice) *
                    (res[2][0]?.Percentage / 100)
                ).toFixed(2),
              AMV_RTO_Discount_Amount:
                (this.state.model_typecode === "L"
                  ? 0
                  : parseFloat(
                      parseFloat(res[0][0]?.ex_showroomprice) *
                        (res[2][0]?.Percentage / 100) *
                        (res[2][0]?.discount_per / 100)
                    )) &&
                (this.state.model_typecode === "L"
                  ? 0
                  : parseFloat(
                      parseFloat(res[0][0]?.ex_showroomprice) *
                        (res[2][0]?.Percentage / 100) *
                        (res[2][0]?.discount_per / 100)
                    )
                ).toFixed(2),
              SchemeName: res[4][0].Scheme_name ? res[4][0].Scheme_name : "",
              SchemeAmount: res[4][0].Amount ? res[4][0].Amount : "",
              Scheme_Id: res[4][0].ID ? res[4][0].ID : "",
              Scheme_Start_Date: res[4][0].start_date
                ? res[4][0].start_date
                : "",
              Scheme_End_Date: res[4][0].End_date ? res[4][0].End_date : "",
              Is_Insurance: res[4][0].Is_Insurance
                ? res[4][0].Is_Insurance
                : "",
            },
            () => {
              this.GetSubsidy(this.state.Subsidy_Type);
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  ChangeVal(event, key) {
    this.setState(
      {
        [key]: event.target.value,
      },
      () => {
        if (key !== "model_group") {
          this.GetSubsidy();
        }
      }
    );
    if (key == "model_group" || key == "model_name") {
      this.setState({
        Grand_Total: "",
        Other_RTO_Charges: "",
        AMV_HSRP_Charges: "",
        AMV_Smart_Card_Amount: "",
        AMV_HPA_Charges: "",
        AMV_CRTM_Amount: "",
        AMV_RTO_Discount_Amount: "",
        AMV_RTO_Discount: "",
        AMV_RTO_Amount: "",
        Taxable_Amount: "",
        AMV_Subsidy_Amount: "",
        Quantity: 1,
        AMV_Central_Subsidy_Amount: "",
        AMV_Dealer_Proposed_Amount: "",
        Quantity_Amount: "",
        Ex_ShowRoom_Price: "",
        AMV_Model_Colour: "",
        Model: "",
        ModelCode: "",
        Scheme_Amount: "",
        Scheme_Name: "",
        Scheme_Id: "",
        Scheme_Start_Date: "",
        Scheme_End_Date: "",
        AMV_Insurance_Amount: "",
        AMV_Insurance_Year: "",
        Insurance_company: "",
        model_code: "",
        model_color: "",
        AMV_Sale_Rate: "",
        Taxable_Amount: "",
        SGST_Amount: "",
        CGST_Amount: "",
        Pending_Amount: "",
        Other_RTO_Charge: "",
        HSRP_Charge: "",
        Booking_Advance_Amount_Each: "",
        SchemeName: "",
        SchemeAmount: "",
      });
    }
    if (key == "model_group") {
      const url =
        Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
      var data = JSON.stringify({
        ModelGroup: parseInt(event.target.value),
        Active: "N",
      });
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.length > 0) {
            this.setState({
              Model_Code: res,
              model_typecode: res[0].Type,
            });
          }
        })
        .catch((err) => {
          process.env.NODE_ENV == "development" && console.log(err);
        });
    }
    if (key == "model_name") {
      let ModelCodedata = "";
      for (let index = 0; index < this.state.Model_Code.length; index++) {
        if (this.state.Model_Code[index].ID === parseInt(event.target.value)) {
          ModelCodedata = this.state.Model_Code[index]["Model code"];
        }
      }
      this.getInsurance(ModelCodedata);
      this.getmodelMRP(event.target.value);
    }
  }
  quotationSubmitted = () => {
    return (
      <>
        <div className=" row justify-content-end  mr-2 mb-2">
          <button
            class="btn light_blue1"
            hidden={!this.state.isModelChange}
            onClick={() => {
              this.setState({ isModelChange: false }, () => {
                this.getM7Details();
              });
            }}
          >
            Cancel
          </button>
          <button
            class="btn light_blue1"
            onClick={() => {
              this.setState({ isModelChange: true });
            }}
            disabled={this.state.Status == "E" ? true : false}
          >
            Change Model
          </button>
        </div>
        <div className=" form-row">
          <div className="form-group col-md-3">
            <label htmlFor="model_group">Model</label>
            <select
              disabled={!this.state.isModelChange}
              value={this.state.model_group}
              onChange={(e) => this.ChangeVal(e, "model_group")}
              id="model_group"
              className=" form-control "
            >
              <option value="0">Select</option>
              {this.GetCategory()}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="model_id">Model Name</label>
            <select
              disabled={!this.state.isModelChange}
              value={this.state.model_name}
              onChange={(e) => this.ChangeVal(e, "model_name")}
              id="model_id"
              className=" form-control "
            >
              <option value="0">Select</option>
              {this.state.Model_Code.map((e) => {
                return <option value={e.ID}>{e["Model name"]}</option>;
              })}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="model_name">Model Code</label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "Model")}
              value={this.state.model_code}
              id="model_name"
              className=" form-control "
            >
              {/* <option>Select</option>
              {this.state.Model_Data_1.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })} */}
            </input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="model_color">Model Colour</label>
            <input
              disabled
              value={this.state.model_color}
              id="model_color"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="sale_rate">Ex Showroom Price</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Sale_Rate")}
              disabled
              value={this.state.AMV_Sale_Rate}
              id="sale_rate"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="sale_rate">Quantity</label>
            <input
              onChange={(e) => this.ChangeVal(e, "Quantity")}
              disabled={!this.state.isModelChange}
              value={this.state.Quantity}
              id="sale_rate"
              className=" form-control "
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
          <label htmlFor="Quantity_Amount">Amount</label>
          <input
            onChange={(e) => this.ChangeVal(e, "Quantity_Amount")}
            disabled
            value={this.state.Quantity_Amount}
            id="Quantity_Amount"
            className=" form-control "
          ></input>
        </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="rto_discount">
              Dealer Proposed Discount Per Unit (-)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Dealer_Proposed_Amount")}
              disabled={!this.state.isModelChange}
              value={this.state.AMV_Dealer_Proposed_Amount}
              id="rto_discount"
              className=" form-control "
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
          <label htmlFor="other_charges">Subsidy Type</label>
          <input
            onChange={(e) => this.ChangeVal(e, "Subsidy_Type")}
            disabled
            value={this.state.Subsidy_Type}
            className=" form-control "
            id="other_charges"
          ></input>
        </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">
              State Subsidy Amount Per Unit (-)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Subsidy_Amount")}
              disabled
              value={
                this.state.AMV_Subsidy_Amount &&
                parseFloat(this.state.AMV_Subsidy_Amount).toFixed(2)
              }
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">
              Central Subsidy Amount Per Unit (-)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Central_Subsidy_Amount")}
              disabled
              value={
                this.state.AMV_Central_Subsidy_Amount &&
                parseFloat(this.state.AMV_Central_Subsidy_Amount).toFixed(2)
              }
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Scheme Name</label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "SchemeName")}
              value={this.state.SchemeName}
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Scheme Amount Per Unit (-)</label>
            <input
              disabled
              value={this.state.SchemeAmount}
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="Total">Taxable Amount</label>
            <input
              disabled
              value={
                this.state.Taxable_Amount &&
                parseFloat(this.state.Taxable_Amount).toFixed(2)
              }
              class="form-control"
              id="Total"
            ></input>
          </div>
          <div hidden class="form-group col-md-3">
            <label htmlFor="Total">IGST Amount Per Unit (+)</label>
            <input
              disabled
              value={
                this.state.IGST_Amount &&
                parseFloat(this.state.IGST_Amount).toFixed(2)
              }
              class="form-control"
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="Total">CGST Amount Per Unit (+)</label>
            <input
              disabled
              value={
                this.state.CGST_Amount &&
                parseFloat(this.state.CGST_Amount).toFixed(2)
              }
              class="form-control"
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="Total">SGST Amount Per Unit (+)</label>
            <input
              disabled
              value={
                this.state.SGST_Amount &&
                parseFloat(this.state.SGST_Amount).toFixed(2)
              }
              class="form-control"
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Booking Advance Amount (-) </label>
            <input
              disabled
              value={this.state.Booking_Advance_Amount}
              className=" form-control "
              type="number"
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="pending_amount">
              Booking Advance Amount Per Unit
            </label>
            <input
              disabled
              value={this.state.Booking_Advance_Amount_Each}
              class="form-control"
              id="pending_amount"
            ></input>
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="rtoamount">RTO Amount Per Unit (+)</label>
            <div>
              <input
                disabled
                value={this.state.AMV_RTO_Amount}
                className=" form-control "
                id="rtoamount"
              ></input>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="rto_discount">RTO Discount Per Unit (%)</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_RTO_Discount")}
              disabled
              value={this.state.AMV_RTO_Discount}
              id="rto_discount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="rto_discount_amount">
              RTO Discount Amount Per Unit (-)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_RTO_Discount_Amount")}
              disabled
              value={this.state.AMV_RTO_Discount_Amount}
              id="rto_discount_amount"
              className=" form-control "
            ></input>
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">CRTM Amount Per Unit (+)</label>
            <div>
              <input
                disabled={!this.state.isModelChange}
                onChange={(e) => this.ChangeVal(e, "AMV_CRTM_Amount")}
                value={this.state.AMV_CRTM_Amount}
                id="crtm_amount"
                className=" form-control "
              ></input>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="hpacharge">HPA Charge Per Unit (+)</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_HPA_Charges")}
              disabled={!this.state.isModelChange}
              value={this.state.AMV_HPA_Charges}
              id="hpacharge"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="smart_card_amount">
              Smart Card Amount Per Unit (+)
            </label>
            <div>
              <input
                disabled={!this.state.isModelChange}
                onChange={(e) => this.ChangeVal(e, "AMV_Smart_Card_Amount")}
                value={this.state.AMV_Smart_Card_Amount}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="HSRP_Charge">HSRP Charge Per Unit (+)</label>
            <input
              onChange={(e) => this.ChangeVal(e, "HSRP_Charge")}
              disabled={!this.state.isModelChange}
              value={this.state.HSRP_Charge}
              id="HSRP_Charge"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Other_RTO_Charge">
              Other RTO Charge Per Unit (+)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "Other_RTO_Charge")}
              disabled={!this.state.isModelChange}
              value={this.state.Other_RTO_Charge}
              id="Other_RTO_Charge"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Insurance_company">
              Insurance Company {this.state.isModelChange && this.required()}
            </label>
            <select
              disabled={!this.state.isModelChange}
              onChange={(e) => this.ChangeVal(e, "Insurance_company")}
              value={this.state.Insurance_company}
              id="Insurance_company"
              className=" form-control "
            >
              <option>Select</option>
              {this.state.insuranceList.map((e) => {
                return (
                  <option value={e.Insurance_Company}>
                    {e.Insurance_Company}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="insurance_year">
              Insurance Type {this.state.isModelChange && this.required()}
            </label>
            <select
              disabled={!this.state.isModelChange}
              onChange={(e) => this.ChangeVal(e, "AMV_Insurance_Year")}
              value={this.state.AMV_Insurance_Year}
              id="insurance_year"
              className=" form-control "
            >
              <option value="0">Select</option>
              <option value="1+4 Comprehensive">1+4 Comprehensive</option>
              <option value="1+4 Third Party">1+4 Third Party</option>
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="insurance_amount">
              Insurance Amount Per Unit (+){" "}
              {this.state.isModelChange && this.required()}
            </label>
            <input
              // disabled={!this.state.isModelChange}
              onChange={(e) => {
                this.ChangeVal(e, "AMV_Insurance_Amount");
              }}
              value={this.state.AMV_Insurance_Amount}
              id="insurance_amount"
              className=" form-control "
              disabled={this.state.Is_Insurance=="Y"?true:this.state.SchemeAmount!=""?true:this.state.isModelChange==false?true:false}
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Total_Accessories_Amount">
              Total Accessories Amount Per Unit (+)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "Insurance_company_Year")}
              disabled
              value={this.state.Total_Accessories_Amount}
              id="Total_Accessories_Amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Other_Charges">Other Charges Per Unit (+)</label>
            <input
              disabled={!this.state.isModelChange}
              onChange={(e) => {
                this.ChangeVal(e, "Other_Charges");
              }}
              value={this.state.Other_Charges}
              id="Other_Charges"
              className=" form-control "
              type="number"
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Exchange_Bonus_Amount">
              Exchange Bonus With Tax Per Unit (-)
            </label>
            <input
              disabled={!this.state.isModelChange}
              onChange={(e) => {
                this.ChangeVal(e, "Exchange_Bonus_Amount");
              }}
              value={this.state.Exchange_Bonus_Amount}
              id="Exchange_Bonus_Amount"
              className=" form-control "
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="pending_amount">Balance Amount</label>
            <input
              disabled
              value={
                this.state.Pending_Amount
                  ? this.state.Pending_Amount.toFixed(2)
                  : ""
              }
              class="form-control"
              id="pending_amount"
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Grand_Total">Grand Total</label>
            <input
              disabled
              value={
                this.state.Grand_Total &&
                parseFloat(this.state.Grand_Total).toFixed(2)
              }
              id="Grand_Total"
              className=" form-control "
            ></input>
          </div>
        </div>
      </>
    );
  };
  accessoriesHeader() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        <td width="25px" className="table-text"></td>
        <td width="20%">Accessories Name</td>
        <td width="8%">Accessories Amount</td>
        <td width="8%">HSN</td>
        <td width="8%">IGST</td>
        <td width="8%">IGST Amount</td>
        <td width="8%">SGST</td>
        <td width="8%">SGST Amount</td>
        <td width="8%">CSGT</td>
        <td width="8%">CSGT Amount</td>
        <td width="20%">Accessories Total With Tax</td>
        <td width="25px" className="table-text"></td>
      </tr>
    );
  }
  accessoriesAddNew = (id) => {
    if (this.state.ConditionCode.length < this.state.MaxAccessoriesCount) {
      this.setState((prev) => {
        return {
          ...prev,
          ConditionCode: [
            ...this.state.ConditionCode,
            {
              id: id + 1,
              accid: "",
              accessories_name: "",
              accessories_amount: "",
              hsn: "",
              igst: "",
              igst_amount: "",
              sgst: "",
              sgst_amount: "",
              cgst: "",
              cgst_amount: "",
              accessories_total_withtax: "",
              options: this.state.AccessoriesOption,
            },
          ],
        };
      });
    } else {
      this.setState({
        disableaddnew: true,
      });
    }
  };
  accessoriesDelet = (id, accid) => {
    if (accid > 0 && this.props.modelid !== "pendingClick") {
      $("#GLKickOut_Popup1").modal("show");
      this.setState({
        delete_id: id,
        accssory_id: accid,
      });
    } else if (this.state.ConditionCode.length > 1) {
      let newConditionArray = this.state.ConditionCode.filter((e) => {
        return e.id != id;
      });
      const removerlist = this.state.ConditionCode.filter((e) => {
        return e.id == id;
      });
      newConditionArray = newConditionArray.map((e) => {
        return {
          ...e,
          options: [...e.options, removerlist[0].selectedAccessories],
        };
      });
      let priceofRemoved = removerlist[0]?.accessories_total_withtax;
      this.setState(
        {
          ConditionCode: [...newConditionArray],
          disableaddnew: false,
          AccessoriesOption: [
            ...this.state.AccessoriesOption,
            removerlist[0].selectedAccessories,
          ],
        },
        () => {
          this.calculateGrandTotal();
        }
      );
    } else {
      const removerlist = this.state.ConditionCode.filter((e) => {
        return e.id == id;
      });
      let priceofRemoved = removerlist[0]?.accessories_total_withtax;
      this.setState(
        {
          ConditionCode: [
            {
              id: 1,
              accid: "",
              accessories_name: "",
              accessories_amount: "",
              hsn: "",
              igst: "",
              igst_amount: "",
              sgst: "",
              sgst_amount: "",
              cgst: "",
              cgst_amount: "",
              accessories_total_withtax: "",
              options: [
                ...this.state.AccessoriesOption,
                removerlist[0].selectedAccessories,
              ],
            },
          ],
          AccessoriesOption: [
            ...this.state.AccessoriesOption,
            removerlist[0].selectedAccessories,
          ],
        },
        () => {
          this.calculateGrandTotal();
        }
      );
    }
  };
  calculateGrandTotal = () => {
    let accessories_amount = 0;
    this.state.ConditionCode.map((e) => {
      accessories_amount +=
        e.accessories_total_withtax && parseFloat(e.accessories_total_withtax);
    });
    console.log(
      this.state.Grand_Total,
      this.state.Total_Accessories_Amount,
      accessories_amount,
      "dsndgdsdh"
    );
    this.setState({
      Grand_Total:
        this.state.Grand_Total -
        this.state.Total_Accessories_Amount +
        accessories_amount,
      Total_Accessories_Amount: accessories_amount,
    });
  };
  delete_api = () => {
    const url = Urls.python_baseurl + "/sp_delete_M7_Accesories";
    var data = JSON.stringify({
      ID: this.state.accssory_id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (this.state.ConditionCode.length == 1) {
            const removerlist = this.state.ConditionCode.filter((e) => {
              return e.id == this.state.delete_id;
            });
            this.setState(
              {
                ConditionCode: [
                  {
                    id: 1,
                    accid: "",
                    accessories_name: "",
                    accessories_amount: "",
                    hsn: "",
                    igst: "",
                    igst_amount: "",
                    sgst: "",
                    sgst_amount: "",
                    cgst: "",
                    cgst_amount: "",
                    accessories_total_withtax: "",
                    options: [
                      ...this.state.AccessoriesOption,
                      removerlist[0]?.selectedAccessories,
                    ],
                  },
                ],
                AccessoriesOption: [
                  ...this.state.AccessoriesOption,
                  removerlist[0]?.selectedAccessories,
                ],
              },
              () => {
                this.calculateGrandTotal();
              }
            );
          } else {
            let newConditionArray = this.state.ConditionCode.filter((e) => {
              return e.id != this.state.delete_id;
            });
            const removerlist = this.state.ConditionCode.filter((e) => {
              return e.id == this.state.delete_id;
            });
            console.log(removerlist, "klklk");
            newConditionArray = newConditionArray.map((e) => {
              return {
                ...e,
                options: [...e?.options, removerlist[0]?.selectedAccessories],
              };
            });
            let priceofRemoved = removerlist[0]?.accessories_total_withtax;
            this.setState(
              {
                ConditionCode: [...newConditionArray],
                AccessoriesOption: [
                  ...this.state.AccessoriesOption,
                  removerlist[0]?.selectedAccessories,
                ],
                disableaddnew: false,
              },
              () => {
                this.calculateGrandTotal();
              }
            );
          }

          $("#GLKickOut_Popup1").modal("hide");
          $("#payment_error_modal_2").modal("hide");
          $("#payment_error_modal_2").modal("show");
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelchange = (key, value, id) => {
    if (key == "accessories_name") {
      let newOptions = this.state.AccessoriesOption;
      let xyz = this.state.ConditionCode.filter((e) => e?.id == id)[0]
        ?.selectedAccessories;
      let newdata = newOptions.filter((e) => {
        return e.Accesories_Name == value;
      });
      let singleaccessoriesamt = 0;
      let data = this.state.ConditionCode;
      let Total_Accessories_Amount = 0;
      if (value == 0) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].id === id) {
            newOptions.push(data[i].selectedAccessories);
            data[i] = {
              id: id,
              accid: "",
              accessories_name: "",
              accessories_amount: "",
              hsn: "",
              igst: "",
              igst_amount: "",
              sgst: "",
              sgst_amount: "",
              cgst: "",
              cgst_amount: "",
              accessories_total_withtax: "",
              options: data[i].options,
              selectedAccessories: {},
            };
          }
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i].id !== id) {
            data[i].options = [
              ...data[i].options,
              newOptions[newOptions.length - 1],
            ];
          }
        }
      } else {
        newOptions = newOptions.filter((e) => {
          return e.Accesories_Name !== value;
        });
        if (xyz && Object.keys(xyz).length > 0) {
          newOptions.push(xyz);
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i]?.id == id) {
            data[i].accessories_name = newdata[0]?.Accesories_Name;
            data[i].accessories_amount = newdata[0]?.NDP;
            data[i].hsn = newdata[0]?.HSN;
            data[i].accid = newdata[0]?.ID;
            data[i]["selectedAccessories"] = newdata[0];
            if (this.state.State == sessionStorage.getItem("Dealer_state")) {
              console.log(Total_Accessories_Amount, "jkjkjkj", 1);
              data[i].igst = 0;
              data[i].sgst = newdata[0]?.SGST;
              data[i].cgst = newdata[0]?.CGST;
              data[i].igst_amount = 0;
              data[i].sgst_amount =
                parseFloat(newdata[0]?.NDP) *
                (parseFloat(newdata[0]?.SGST) / 100);
              data[i].cgst_amount =
                parseFloat(newdata[0]?.NDP) *
                (parseFloat(newdata[0]?.CGST) / 100);
              data[i].accessories_total_withtax = (
                parseFloat(data[i].accessories_amount) +
                data[i].sgst_amount +
                data[i].igst_amount +
                data[i].cgst_amount
              ).toFixed(2);
            } else {
              console.log(Total_Accessories_Amount, "jkjkjkj", 2);
              data[i].igst = newdata[0]?.IGST;
              data[i].sgst = 0;
              data[i].cgst = 0;
              data[i].sgst_amount = 0;
              data[i].cgst_amount = 0;
              data[i].igst_amount =
                parseFloat(newdata[0]?.NDP) *
                (parseFloat(newdata[0]?.IGST) / 100);
              data[i].accessories_total_withtax = (
                parseFloat(data[i].accessories_amount) +
                data[i].sgst_amount +
                data[i].igst_amount +
                data[i].cgst_amount
              ).toFixed(2);
              singleaccessoriesamt = parseFloat(
                data[i].accessories_total_withtax
              );
            }
          } else {
            if (data[i].accessories_name) {
              data[i].options = [...newOptions, data[i].selectedAccessories];
            } else {
              data[i].options = [...newOptions];
            }
          }
        }
      }
      for (let i = 0; i < this.state.ConditionCode.length; i++) {
        Total_Accessories_Amount += this.state.ConditionCode[i]
          .accessories_total_withtax
          ? parseFloat(this.state.ConditionCode[i].accessories_total_withtax)
          : 0;
      }
      this.setState(
        {
          ConditionCode: data,
          AccessoriesOption: newOptions,
        },
        () => {
          this.calculateGrandTotal();
        }
      );
    }
  };
  accessoriesBody() {
    console.log("ConditionCode", this.state.ConditionCode);
    return this.state.ConditionCode.map((d, index) => {
      return (
        <tr key={d.id}>
          <td>
            {index === this.state.ConditionCode.length - 1 && (
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={
                  this.state.IS_confirm !== "Y" && !this.state.disableaddnew
                    ? {
                        color: "var(--main-bg-color)",
                        fontSize: "25px",
                        cursor: "pointer",
                      }
                    : {
                        color: "var(--main-bg-color)",
                        fontSize: "25px",
                        cursor: "default",
                        opacity: "30%",
                      }
                }
                onClick={() =>
                  this.state.IS_confirm !== "Y" &&
                  !this.state.disableaddnew &&
                  this.accessoriesAddNew(d.id)
                }
              />
            )}
          </td>
          <td>
            <select
              onChange={(e) => {
                this.handelchange("accessories_name", e.target.value, d.id);
              }}
              value={d.accessories_name}
              type="text"
              list="accessories_name"
              className=" form-control "
              disabled={this.state.IS_confirm !== "Y" ? false : true}
            >
              <option value={0}>Select</option>
              {d.options.map((e) => {
                return (
                  <option value={e?.Accesories_Name}>
                    {e?.Accesories_Name}
                  </option>
                );
              })}
            </select>
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("accessories_amount", e.target.value, d.id);
              }}
              value={d.accessories_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="text"
              onChange={(e) => {
                this.handelchange("hsn", e.target.value, d.id);
              }}
              value={d.hsn}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("igst", e.target.value, d.id);
              }}
              value={d.igst}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("igst_amount", e.target.value, d.id);
              }}
              value={d.igst_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("sgst", e.target.value, d.id);
              }}
              value={d.sgst}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("sgst_amount", e.target.value, d.id);
              }}
              value={d.sgst_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("cgst", e.target.value, d.id);
              }}
              value={d.cgst}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("cgst_amount", e.target.value, d.id);
              }}
              value={d.cgst_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange(
                  "accessories_total_with_tax",
                  e.target.value,
                  d.id
                );
              }}
              value={d.accessories_total_withtax}
            />
          </td>
          <td style={{ width: "25px", backgroundColor: "white" }}>
            <img
              src={require("../../../components/Images/trash.png")}
              style={
                this.state.IS_confirm !== "Y"
                  ? { width: "20px", cursor: "pointer" }
                  : { width: "20px", cursor: "default", opacity: "30%" }
              }
              onClick={() =>
                this.state.IS_confirm !== "Y" &&
                d.accessories_name &&
                this.accessoriesDelet(d.id, d.accesores_ID)
              }
            ></img>
          </td>
        </tr>
      );
    });
  }
  GLKickOutPopup1 = () => {
    return (
      <div
        class="modal"
        id="GLKickOut_Popup1"
        style={{ marginLeft: "25%" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog-Memberinfo"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog" style={{ maxHeight: "initial" }}>
            <div>
              <div class="modal-body" style={{ fontSize: "16px" }}>
                Are you sure want to delete?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn light_blue1"
                  data-dismiss="modal"
                  onClick={() => {
                    $("#GLKickOut_Popup1").modal("hide");
                    $("#payment_error_modal_2").modal("hide");
                    $("#payment_error_modal_2").modal("show");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn light_blue1"
                  onClick={this.delete_api}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  GLKickOutPopup = () => {
    return (
      <div
        class="modal"
        id="GLKickOut_Popup"
        style={{ marginLeft: "25%" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog-Memberinfo"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog" style={{ maxHeight: "initial" }}>
            <div>
              <div class="modal-body" style={{ fontSize: "16px" }}>
                Are you sure want to delete?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn light_blue1"
                  data-dismiss="modal"
                  onClick={() => {
                    this.handelCancel();
                    $("#GLKickOut_Popup").modal("hide");
                    $("#payment_error_modal_2").modal("hide");
                    $("#payment_error_modal_2").modal("show");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn light_blue1"
                  onClick={this.handelConfirmDelet}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  handelConfirmDelet = () => {
    const deletedobj = this.state.allocat_Detail.filter((e) => {
      return e.id == this.state.selectedid;
    });
    this.setState(
      {
        delete_Allocation: deletedobj,
      },
      () => {
        this.Allocation_Delete();
      }
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">
          Money Received{" "}
          {this.state.booking_status === "Confirmed" ? "(Confirmed)" : ""}
        </h4>{" "}
        <br></br>
        <div
          style={{ marginTop: "-10px" }}
          className=" row justify-content-end "
        >
          {/* <button className=" btn Opertion_btn">Add New</button> */}
          <button
            disabled={this.state.isDouble}
            hidden={this.state.booking_status === "Confirmed" ? true : false}
            type="submit"
            className="btn Opertion_btn clickable "
            onClick={this.Validate}
          >
            Save
          </button>
          <button
            disabled={
              this.state.isDouble || this.state.isSavedClick !== "saveClcik"
            }
            hidden={this.state.booking_status === "Confirmed" ? true : false}
            type="submit"
            className="btn Opertion_btn clickable mr-4"
            style={{ fontSize: "12px", float: "right", clear: "both" }}
            onClick={this.handelconfirm}
          >
            Confirm
          </button>
          {/* <button className=" btn Opertion_btn">Cancel</button> */}
        </div>
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Customer Information
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">{this.ClientInformation()}</div>
          </div>
        </div>
        {/* <div class="card">
          <div class="card-header" id={"headingSeven" + this.props.modelid}>
            <h2 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target={"#collapseSeven" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseSeven" + this.props.modelid}
              >
                Model Details
              </button>
            </h2>
          </div>
          <div
            id={"collapseSeven" + this.props.modelid}
            class="collapse  "
            aria-labelledby={"headingSeven" + this.props.modelid}
          >
            <div class="card-body">{this.ModelDetails()}</div>
          </div>
        </div> */}
        <div class="card">
          <div class="card-header" id="headingSix">
            <h2 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseSix"
                aria-expanded="true"
                aria-controls="collapseSix"
              >
                Booking Details
              </button>
            </h2>
          </div>
          <div
            id={"collapseSix"}
            class="collapse"
            aria-labelledby={"headingSix"}
          >
            <div class="card-body">{this.bookingDetails()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFive">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#CollapseFive"
                aria-expanded="true"
                aria-controls="CollapseFive"
              >
                Accessories
              </button>
            </h5>
          </div>
          <div
            id="CollapseFive"
            class="collapse "
            aria-labelledby="headingFive"
          >
            <div style={{ overflowX: "scroll" }} class="card-body">
              <table
                className="table table-bordered mt-2"
                id="userList"
                align="center"
              >
                <thead>{this.accessoriesHeader()}</thead>
                <tbody>{this.accessoriesBody()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"headingFour" + this.props.modelid}>
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target={"#collapseFour" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseFour" + this.props.modelid}
              >
                Rates & Discount
              </button>
            </h5>
          </div>
          <div
            id={"collapseFour" + this.props.modelid}
            class="collapse"
            aria-labelledby={"headingFour" + this.props.modelid}
          >
            <div class="card-body">{this.quotationSubmitted()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"heading12" + this.props.modelid}>
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target={"#collapse12" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapse12" + this.props.modelid}
              >
                Money Received Details
              </button>
            </h5>
          </div>
          <div
            id={"collapse12" + this.props.modelid}
            class="collapse show "
            aria-labelledby={"heading12" + this.props.modelid}
          >
            <div class="card-body">{this.MoneyReceived()}</div>
          </div>
        </div>
        {this.state.booking_status === "Confirmed" ? (
          <div class="card ">
            <div class="card-header" id="heading13">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapse13"
                  aria-expanded="true"
                  aria-controls="collapse13"
                >
                  Allocation Details
                </button>
              </h5>
            </div>

            <div
              id="collapse13"
              class="collapse show"
              aria-labelledby="heading13"
              data-parent="#accordion"
            >
              <div class="card-body">{this.Allocation_details()}</div>
            </div>
          </div>
        ) : null}
        {this.GLKickOutPopup()}
        {this.GLKickOutPopup1()}
      </div>
    );
  }
}
