import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import Urls from "../../../helpers/Urls";
import "./style.css";

export class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      Active: "",
      Model_Category: "",
      rowData: [],
    };
  }
  componentDidMount() {
    this.MasterAPICall();
  }
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_Get_Users";
    var data = JSON.stringify({
      ID: "0",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res[0],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelAction=(data)=>{
    const url = Urls.python_baseurl + "/Inactive_user";
    var data = JSON.stringify({
      "ID": `${data?.ID}`,
      "active_status": "0"
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
           alert(res.msg)
           window.location.reload()
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  }
  masterTable() {
    
    let columnDefs = [
      {
        headerName: "UserName",
        field: "LoginName",
        flex: "1",
        cellStyle: (params) => {
          if (params.data.Is_Active !== 0) {
              return { color: "var(--main-bg-color)", cursor: "pointer" };
          } else {
              return null;
          }
      }
      
      },
      {
        headerName: "Employee Code",
        field: "Employee_Code",
        flex: "1",
        // cellStyle:{color:"var(--main-bg-color)", cursor:"pointer"}
      },
      {
        headerName: "First Name",
        field: "FirstName",
        flex: "1",
      },
      {
        headerName: "Last Name",
        field: "LastName",
        flex: "1",
      },
      {
        headerName: "Department",
        field: "Departmentname",
        flex: "1",
      },
      {
        headerName: "State",
        field: "state",
        flex: "1",
      },
      {
        headerName: "UserRole",
        field: "UserRole",
        flex: "1",
      },
      {
        headerName: "Action",
        flex: "1",
        cellRenderer: (param) => {
          return <button hidden={param.data.Is_Active==0} onClick={()=>this.handelAction(param.data)} className="Opertion_btn btn">{param.data.Is_Active==0?"Active":"In Active"}</button>;
        }
      },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) =>{
              if(event.colDef.headerName==="UserName"&&event.data.Is_Active!==0){
              this.props.history.push(`/UserManagment?id=${event.data.ID}`,{
                isUpdate:true,
                SelectedRow:event.data
              })
              }
            }
            }
          ></AgGridReact>
        </div>
      </div>
    );
  }
  header = () => {
    return (
      <div className=" card p-3 mt-4">
        <div className=" row">
          <div className=" col-3">
            <label htmlFor="model-c">Model Category</label>
            <input
              value={this.state.Model_Category}
              id="model-c"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="active">Active</label>
            <input
              value={this.state.Active}
              id="active"
              className=" form-control "
              readOnly
            ></input>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div className="form-container">
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <h4 className="heading_text" style={{margin:"0"}}>User List</h4>
        </div>
        <br/>
        <div className=" row justify-content-end ">
        <button onClick={()=>this.props.history.push("/UserManagment")} className="btn Opertion_btn clickable mr-3">Create New</button>
        </div>
        <div>{this.masterTable()}</div>
      </div>
    );
  }
}
