import React from "react";
    import moment from "moment";
    import Logo from "../../components/Images/Logo.png";
    import "../../containers/color.css";
    import Urls from "../../../helpers/Urls";
    import { Tiles } from "../../components/Tiles";
    import ReactApexChart from "react-apexcharts";
    import Strings from "../../../helpers/Strings";
    export class Testing_prototype extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          showTable: true,
          paginationPageSize: 10,
          domLayout: "autoHeight",
          autoGroupColumnDef: {
            headerName: "Group",
            minWidth: 100,
            field: "athlete",
            valueGetter: function (params) {
              if (params.node.group) {
                return params.node.key;
              } else {
                return params.data[params.colDef.field];
              }
            },
            headerCheckboxSelection: true,
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: { checkbox: true },
          },
          defaultColDef: {
            cellClass: "cell-wrap-text",
            autoHeight: true,
            sortable: true,
            resizable: true,
            filter: true,
          },
          rowSelection: "never",
          rowGroupPanelShow: "never",
          pivotPanelShow: "never",
          rowData: [],
          startdate:"",
          enddate:"",
          pichart: {
            series: [1,2,3,4,5,6,7,8],
            options: {
              chart: {
                // width: 380,
                type: "pie",
              },
              labels: [],
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f}%",
                    distance: -50,
                    style: {
                      color: "black",
                    },
                  },
                },
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: "left",
                    },
                  },
                },
              ],
            },
          },
          pichart_2: {
            series: [9,8,7,6,5],
            options: {
              chart: {
                // width: 380,
                type: "pie",
              },
              labels: [],
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f}%",
                    distance: -50,
                    style: {
                      color: "black",
                    },
                  },
                },
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: "left",
                    },
                  },
                },
              ],
            },
          },
          barChartOptions: {
            chart: {
              type: "bar",
              // width: 550,
            },
            xaxis: {
              categories: ["Q1", "Q2", "Q3", "Q4"],
              title: {
                text: "Quarter",
              },
            },
            yaxis: {
              title: {
                text: "Sales",
              },
            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return "INR" + value;
                },
              },
            },
            series: [
              {
                name: "Sales",
                data: [400, 430, 448, 567],
              },
            ],
          },
          summeryDetails: {
            followup: 0,
            pendingm1count: 0,
            PendingPDI:0,
            PendingforMoneyreceived: 0,
            PDI: 1,
            vehiclestock: 0,
            PendingforBilling: 0,
            PendingforAllocation:0,
            B2CSales:0,
            D2D:0,
            D2DDeliveryChallan:0,
            DelarSales:0,
            TotalPDI:0,
            DealerSalesOrder:0
          },
          options:{},
          options2:{}
        };
      }
      componentDidMount() {
        this.showTiles()
        
        }
    
      showTiles = () => {
        let url='';
        let data=[{"name":"Total Files","value":"0","color":"orange"},{"name":"Rejected Files","value":"0","color":"orange"},{"name":"Accepted Files","value":"0","color":"orange"},{"name":"Error","value":"0","color":"orange"}]
         
         return data.map((item) => {
        
          console.log(window.innerWidth, "kkk");
          return (
            <div className="form-group">
              <Tiles
                isClickable={false}
                header_text={item.name}
                value={item.value}
                isenrollment={true}
                color={item.color}
                differentTile={true}
                url={url}
                width={"210px"}
                // onClick={() => {
                //   if (item.name === "Open Enquiry") {
                //     this.props.history.push("/EnquiryList", {
                //       data: [{
                //         open_enquiry: 'open enquiry',
                //         dashboard_Click: true,
                //         title: "Open Enquiry"
                //       }]
    
                //     });
                //   }
                //   else if (item.name === "Today's Followup") {
                //     this.props.history.push("/Followup", {
                //       data: [{
                //         open_enquiry: "Today's Followup",
                //         dashboard_Click: true,
                //         title: "Today's Followup"
                //       }]
                //     });
                //   }
                //   else if (item.name === "Pending For Money Receipt") {
                //     this.props.history.push("/Vehicle_Booking", {
                //       data: [{
                //         pendind_data: 'Pending For Money Received',
                //         dashboard_Click: true,
                //         title: "Pending For Money Receipt"
                //       }]
    
                //     });
    
                //   }
                //   else if (item.name === "Chassis Pending For Vehicle Invoice") {
                //     this.props.history.push("/chassis_pending_for_vehicle_billing", {
                //       data: [{
                //         pendind_data: 'PendingVehicleBilling',
                //         dashboard_Click: true,
                //         title: "Chassis Pending For Vehicle Invoice"
                //       }]
    
                //     });
    
                //   }
                //   else if (item.name === "Retail Sale") {
                //     this.props.history.push("/Vehical_Invoice", {
                //       data: [{
                //         pendind_data: 'M8Details',
                //         dashboard_Click: true,
                //         title: "Retail Sale"
                //       }]
    
                //     });
                //   }
                //   else if (item.name === "Vehicle Stock As On") {
                //     this.props.history.push("/Vehicle_stock", {
                //       data: [{
                //         pendind_data: 'Sales_DB',
                //         dashboard_Click: true,
                //         title: "Vehicle Stock As On"
                //       }]
    
                //     });
    
                //   }
    
                //   else if (item.name === "Total Jobcard") {
                //     this.props.history.push("/JobCard_List", {
                //       data: [{
                //         pendind_data: 'Sales_DB',
                //         dashboard_Click: true
                //       }]
    
                //     });
    
                //   }
                 
                //   else if (item.name === "Chassis Pending For PDI") {
                //     this.props.history.push("/Pending_Jobcard", {
                //       data: [{
                //         pendind_data: 'PendingVehicleforPDI',
                //         dashboard_Click: true,
                //         title: "Pending For PDI"
                //       }]
    
                //     });
    
                //   }
                //   else if (item.name === "Chassis Available for Allocation") {
                //     this.props.history.push("/Pending_Jobcard", {
                //       data: [{
                //         pendind_data: 'PendingforVehicleAllocation',
                //         dashboard_Click: true,
                //         title: "Chassis Available for Allocation"
                //       }]
    
                //     });
    
                //   }
                //   else if (item.name === "Dealer Sales Order") {
                //     this.props.history.push("/Dealer_sales_order", {
                //       data: [{
                        
                //         Delar_PO_List: true,
                //       }]
    
                //     });
    
                //   }
                //   else if (item.name === "Dealer To Dealer Sale") {
                //     this.props.history.push("/Delar_to_Delar_Sales", {
                //       data: [{
                        
                //         Delar_PO_List: true,
                //       }]
    
                //     });
    
                //   }
                //   else if (item.name === "Delivery To Delivery Challan") {
                //     this.props.history.push("/Dealer_to_delar_challan", {
                //       data: [{
                        
                //         Delar_PO_List: true,
                //       }]
    
                //     });
    
                //   }
                 
                // }}
              />
            </div>
          );
        });
      };
      showPieChart = () => {
        return (
          <div style={{margin:"auto"}} className=" pt-3">
            <ReactApexChart
              options={this.state.options}
              series={this.state.pichart.series}
              type="pie"
              width={350}
            />
          </div>
        );
      };
      showSecondPieChart = () => {
        return (
          <div style={{ margin: "auto" }} className="pt-3">
            <ReactApexChart
              options={this.state.options2}
              series={this.state.pichart_2.series}
              type="pie"
              width={350}
            />
          </div>
        );
      };
      showPieChart_2 = () => {
        return (
          <div style={{margin:"auto"}} className=" pt-3">
            <ReactApexChart
              options={this.state.options_sales}
              series={this.state.pichart_sales.series_seles}
              type="pie"
              width={350}
            />
          </div>
        );
      };
      showFilterOption = () => {
        return (
          <div className=" row mt-3">
            {/* <div className="col-3">
              <label htmlFor="dealer-name">Dealer Name</label>
              <input className=" form-control" list="dealer-list"></input>
              <datalist id="dealer-list">
                <option>Electra Motors</option>
                <option>Quantum Charge Autos</option>
                <option>EcoSpark Dealerships</option>
                <option>Lightning Wheels Hub</option>
                <option>GreenDrive Emporium</option>
                <option>ElectriCruisers Outlet</option>
                <option>TeslaSphere Motors</option>
                <option>CurrentFlow Auto Center</option>
                <option>PowerPulse Cars</option>
                <option>SparkDrive Dealers</option>
              </datalist>
            </div> */}
            {/* <div className=" col-3">
              <label>From Date</label>
              <input value={this.state.startdate} className=" form-control " type="date"></input>
            </div>
            <div className=" col-3">
              <label>To Date</label>
              <input value={this.state.enddate} className=" form-control " type="date"></input>
            </div> */}
          </div>
        );
      };
    
      showBarChart = () => {
        return (
          <ReactApexChart
            options={this.state.barChartOptions}
            series={this.state.barChartOptions.series}
            type={this.state.barChartOptions.chart.type}
            // width={400}
          />
        );
      };
      render() {
        return (
          <div style={{ overflowX: "hidden" }} className=" p-2">
            <h4 style={{ marginLeft: "0px" }} className="heading_text">
              Dashboard
            </h4>
            {this.showFilterOption()}
            <br></br>
            <div className=" form-row mt-3">{this.showTiles()}</div>
            <div className=" row ">
              <div className=" card col-5 ml-3">
                <h6 className=" mt-2">Model Wise Enquiries</h6>
                {this.showPieChart()}
              </div>
              <div className=" card col-6 ml-4">
                <h6 className=" mt-2">Model Wise Retail Sale</h6>
                {this.showSecondPieChart()}
              </div>
              {/* <div className=" card col-6 ml-3">
                <h6 className=" mt-2">Vehicle Invoice</h6>
                {this.showBarChart()}
              </div> */}
            </div>
          </div>
        );
      }
    }
    
    