import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import moment from "moment";
const $ = window.$;
const bcrypt = require("bcryptjs");

export class Part_Price extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      dealer_name_option:[],
      Currency:"INR",
      Region:"",
      Region_data:[],
      State:"",
      State_data:[],
      dealer_name_option:[],
      dealer_name:"",
      Part_no:"",
      Part_name:"",
      From_date:"",
      To_date:"",
      Category:"",
      HSN_code:"",
      GST:"",
      NDP_LDP:"",
      List:"",
      MRP:"",
      Active:"",
      Active_part:"",
      Dealer_margin_per:"",
      Dealer_margin_value:"",
      update_date:"",
      xml_creation_date:"",
      historydata:[],
      flaghistory:false
    };
  }
  componentDidMount() {
    this.MasterAPICall();
  }
 
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      "FromDate": "''",
      "ToDate": "''",
      "ModelPart": "'01'",
      "DealerId":  `'${sessionStorage.getItem("dealerid")}'`,
      "SqlFor": "'PartPrice'",
      "HOBrID": "0"
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          // res=res[0]
          this.setState({
            rowData: res,
            // Active: res[0].Active,
            // contact_no: res[0]["Contact_No"],
            // emp_type: res[0]["Employee Type"],
            // emp_name: res[0]["Employee Name"],
            // id: res[0].ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  
  getpricedetails = () => {
    const url = Urls.python_baseurl + "/SP_GetPartPrice";
    var data = JSON.stringify({
      "GroupCode": "'01'",
      "Dealer_ID": `'${sessionStorage.getItem("dealerid")}'`,
      "Part_ID":`'${this.state.id}'`,
      "PartRateFor": this.state.flaghistory?"'All'":"'Max'"
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if(this.state.flaghistory){
            this.setState({
              historydata:res,
              
              
            },()=>{
              if(this.state.flaghistory){
                $("#payment_error_modal_5").modal("show");
              }
             
            });
          }else{
            this.setState({
              Part_no: res[0]["Part No"],
              Part_name: res[0]["Part Name"],
              From_date: res[0]["From Date"],
              To_date: res[0]["To Date"],
              Category: res[0].Part_cat,
              HSN_code: res[0].HSN_Code,
              GST: res[0].GSTTaxper,
              NDP_LDP: res[0].NDP,
              List: res[0].LIST,
              MRP: res[0].MRP,
              Active: res[0].Active,
              dealer_name:res[0].Dealer,
              // Dealer_margin_per: res[0].,
              // Dealer_margin_value: res[0].,
              update_date: res[0].cr_date,
              xml_creation_date: res[0].XML_Cr_Date,
              Dealercode:res[0]["Dealer Code"]
              
            });
          }
         
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      { headerName: "Part No.", field: "Part No",width:"130px",cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" }, },
      {
        headerName: "Part Name",
        field: "Part Name",
        width:"140px",
        
      },
      { headerName: "Dealer Code", field: "Dealer Code", width:"130px" },
      { headerName: "Dealer Name", field: "Dealer Name", width:"130px"},
      { headerName: "From Date", field: "From Date",width:"130px"},
      { headerName: "To Date", field: "To Date",width:"130px"},
      { headerName: "Category", field: "PArt_cat", width:"130px" },
      { headerName: "HSN Code", field: "HSN_Code", width:"130px" },
      { headerName: "GST(%)", field: "GSTTaxPer", width:"130px" },
      { headerName: "NDP/DLP", field: "NDP (INR)",width:"130px"},
      { headerName: "List", field: "LIST (INR)", width:"130px" },
      { headerName: "MRP", field: "MRP (INR)", width:"130px" },
      { headerName: "Active", field: "Active", width:"130px" },
      { headerName: "Dealer Margin(%)", field: "dealer margin", width:"150px" },
      { headerName: "Dealer Margin Value(On NDP)", field: "dealer margin", width:"150px" },
      { headerName: "Parts Price History", field: "Part Price History", width:"170px" ,cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },},
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) =>
            {
              if (event.colDef.field == "Part No") {
                  this.setState({
                    Part_no: "",
                    Part_name: "",
                    From_date: "",
                    To_date: "",
                    Category: "",
                    HSN_code: "",
                    GST:"",
                    NDP_LDP: "",
                    List: "",
                    MRP: "",
                    Active: "",
                    dealer_name:"",
                    // Dealer_margin_per: res[0].,
                    // Dealer_margin_value: res[0].,
                    update_date: "",
                    xml_creation_date: "",
                    Dealercode:"",
                  id: event.data.ID,
                },()=>{
                  
                 this.getpricedetails()
                })
              }else if(event.colDef.field == "Part Price History"){
                this.setState({
                  id: event.data.ID,
                  flaghistory:true
                },()=>{
                  this.getpricedetails()
                })
                
                
              }
            }
            }
          ></AgGridReact>
        </div>
      </div>
    );
  }
  Historytable() {
    let columnDefs = [
      { headerName: "Part No.", field: "Part_No",width:"130px", },
      {headerName: "Part Name", field: "Part_Name", width:"143px",},
     
      { headerName: "Effective From Date", field: "EffectiveFromDate",width:"150px"},
      { headerName: "Effective To Date", field: "EffectiveToDate",width:"150px"},
      { headerName: "List", field: "LIST", width:"90px" },
      { headerName: "MRP", field: "MRP", width:"100px" },
      { headerName: "NDP/DLP", field: "NDP",width:"120px"},
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.historydata}
            enableCellTextSelection={true}
            
          ></AgGridReact>
        </div>
      </div>
    );
  }
  

  header = () => {
    return (
      
        <div className="row">
       <div className=" col-3">
            <label>Region </label>
            <input
            autoComplete='off'
              value={this.state.Region}
              disabled
              className=" form-control"
            ></input>
            
          </div>
          <div className=" col-3">
            <label>State </label>
            <input
            autoComplete='off'
              value={this.state.State}
              disabled
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3">
            <label>Designation</label>
            <input
            autoComplete='off'
              value={this.state.Designation}
              disabled
              className=" form-control"
            ></input>
           
          </div>
          <div className=" col-3">
            <label>Currency</label>
            <input
            autoComplete='off'
              value={this.state.Currency}
              disabled
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3 mt-3">
            <label>Dealer Name</label>
            <input
            autoComplete='off'
              value={this.state.dealer_name}
              disabled
              className=" form-control"
            ></input>
            
          </div>
         
          <div className=" col-3 mt-3">
            <label>Dealer Code</label>
            <input
            autoComplete='off'
              // onChange={(e) => this.setState({ Dealer_Code: e.target.value })}
              value={this.state.Dealer_Code}
              disabled
              className=" form-control"
            ></input>
          </div>
          
        </div>
      
    );
  };
  
  Part_Price_details = () => {
    return (
      
        <div className="row">
            <div className=" col-3 mt-3">
                <label>Part No.</label>
                <input
                    autoComplete='off'
                    // onChange={(e) => this.setState({ Part_no: e.target.value })}
                    value={this.state.Part_no}
                    disabled
                    className=" form-control"
                ></input>
            </div>
            <div className=" col-3 mt-3">
                <label>Part Name</label>
                <input
                disabled
                    autoComplete='off'
                    // onChange={(e) => this.setState({ Part_name: e.target.value })}
                    value={this.state.Part_name}
                    
                    className=" form-control"
                ></input>
            </div>
           
           
            <div className=" col-3 mt-3">
                <label >Dealer Name</label>
                <input
                    autoComplete='off'
                    value={this.state.dealer_name}
                    disabled
                    className=" form-control"
                ></input>
               
            </div>
            <div className=" col-3 mt-3">
                <label>Dealer Code</label>
                <input
                    autoComplete='off'
                    onChange={(e) => this.setState({ Dealercode: e.target.value })}
                    value={this.state.Dealercode}
                    disabled
                    className=" form-control"
                ></input>
            </div>
            <div className=" col-3 mt-3">
                <label >From Date</label>
                <input
                    autoComplete='off'
                    value={this.state.From_date}
                    disabled
                    className=" form-control"
                ></input>
            </div>
            <div className=" col-3 mt-3">
                <label >To Date</label>
                <input
                    autoComplete='off'
                    value={this.state.To_date}
                    
                    disabled
                className=" form-control"
                ></input>
            </div>
            <div className=" col-3 mt-3">
            <label>Category</label>
            <input
            autoComplete='off'
              // onChange={(e) => this.setState({ Category: e.target.value })}
              value={this.state.Category}
              className=" form-control"
              disabled
            ></input>
          </div>
          <div className=" col-3 mt-3">
            <label>HSN Code</label>
            <input
            autoComplete='off'
              value={this.state.HSN_code}
              className=" form-control"
              disabled
            ></input>
          </div>
          <div className=" col-3 mt-3">
            <label>GST(%)</label>
            <input
            autoComplete='off'
            type="number"
            //   onChange={(e) => this.setState({ GST: e.target.value })}
              value={this.state.GST}
              className=" form-control"
              disabled
              max={3}
            ></input>
          </div>
          <div className=" col-3 mt-3">
            <label>NDP/LDP</label>
            <input
            autoComplete='off'
              value={this.state.NDP_LDP}
              className=" form-control"
              disabled
            ></input>
          </div>
          <div className=" col-3 mt-3">
            <label>List</label>
            <input
            autoComplete='off'
              value={this.state.List}
              className=" form-control"
              disabled
            ></input>
          </div>
          <div className=" col-3 mt-3">
            <label>MRP</label>
            <input
            autoComplete='off'
              value={this.state.MRP}
              className=" form-control"
              disabled
            ></input>
          </div>
         
          <div className=" col-3 mt-3">
            <label>Dealer Margin (%)</label>
            <input
            autoComplete='off'
            disabled
            value={this.state.Dealer_margin_per}
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3 mt-3">
            <label>Dealer Margin Value(On NDP)</label>
            <input
            autoComplete='off'
            disabled
            value={this.state.Dealer_margin_value}
              className=" form-control"
            ></input>
          </div>
          <div className="col-3 mt-3">
            <label>Active</label>
            <input
            autoComplete='off'
            disabled
            value={this.state.Active}
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3 mt-3">
            <label> Update Date</label>
            <input
            autoComplete='off'
            disabled
            value={this.state.update_date}
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3 mt-3">
            <label> XML Creation Date</label>
            <input
            autoComplete='off'
            disabled
            value={this.state.xml_creation_date}
              className=" form-control"
            ></input>
          </div>
        </div>
      
    );
  };
  close() {
    var fileInput = document.getElementById("fileInput");
    fileInput.value = "";
    this.setState({
      Type:"0",
      selectedFile:"",
      flaghistory:false
    })
    $("#payment_error_modal_4").modal("hide");
    $("#payment_error_modal_5").modal("hide");

  }
  errorDialog4 = () => {
    return (
      <div
        className="modal"
        id="payment_error_modal_4"
        style={{ marginLeft: "25%",marginTop:"35px" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog-view" style={{ maxWidth: "50%", right: "30%" }}>
          <div className="error-dialog">
            <button
              type="button"
              class="close close-btn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.close();
              }}
            >
              <span class="" aria-hidden="true">
                &times;
              </span>
            </button>
            <div id="exTab1">
              <div class="tab-content clearfix">
                <div class="tab-pane active" id="1a">
                  {this.csvHeaderview()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  errorDialog5 = () => {
    return (
      <div
        className="modal"
        id="payment_error_modal_5"
        style={{ marginLeft: "10%",marginTop:"35px" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog-view" style={{ maxWidth: "75%", right: "30%" }}>
          <div className="error-dialog">
          <h6 className="heading_text" style={{marginLeft:"2px"}}>Part History</h6>
            <button
            style={{marginTop:"-27px"}}
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.close();
              }}
            >
              <span class="" aria-hidden="true">
                &times;
              </span>
            </button>
            <div id="exTab1">
              <div class="tab-content clearfix mt-3">
                <div class="tab-pane active" id="1a">
                  {this.Historytable()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  csvHeaderview() {
      return (
          <div>
              <div className="" style={{ marginTop: "15px" }}>
                  <div class="form-group  col-md-12">
                      <div
                          className="form-group row"
                          style={{
                              // marginLeft: "2px",
                              marginTop: "15px",
                              // alignItems: "baseline",
                          }}
                      >
                          <input

                              style={{ marginLeft: "12px", width: "81px", marginTop: "28px" }}
                              key={this.state.theInputKey || ""}
                              id="fileInput"
                              type="file"
                              onChange={(e) => this.setState({ selectedFile: e.target.files[0], result: e.target.result })}
                          />
                          <span style={{ margin: "0 12px 0 3px", marginTop: "30px" }}>{this.state.selectedFile && this.state.selectedFile.name ? this.state.selectedFile.name : "No File Chosen"}</span>

                          {/* </div> */}
                          <div
                              className="form-group col-3"
                              role="group"
                              aria-label="Basic mixed styles example"
                          >
                              <button
                                  disabled={this.state.double_save}
                                  type="button"
                                  className="btn"
                                  style={{
                                      backgroundColor: "var(--main-bg-color)",
                                      color: "white",
                                      marginTop: "20px",
                                      marginLeft: "5px",
                                  }}
                                  onClick={() => this.onFileUpload()}
                              >
                                  {this.state.loaderforupload && (
                                      <span
                                          className="spinner-grow spinner-grow-sm"
                                          style={{ color: "unset", marginRight: "5px", }}
                                          role="status"
                                          aria-hidden="true"
                                      ></span>
                                  )}
                                  Upload
                              </button>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      );
  }
  testclickNavigation2 = () => {
    $("#payment_error_modal_4").modal("show");
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Part Price</h4><br></br>
        <div class="row justify-content-end ">
          {/* <button
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={this.addnew}
          >
            Clear
          </button>
          <button
            type="submit"
            className="btn Opertion_btn clickable "
            onClick={this.Validate}
          >
            Save
          </button> */}
          {/* <button
            // hidden={sessionStorage.getItem("UserRole") == "10"?false:true}
            // disabled={this.state.double_save}
            type="submit"
            className="btn Opertion_btn clickable mr-5"
            onClick={this.testclickNavigation2}
          >
            Upload File
          </button> */}
        </div>
        <div
          class="form-group col-md-12 mt-2"
        >
          
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Part Price List
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">{this.masterTable()}</div>
            </div>
          </div>
          {/* <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Location Details
                </button>
              </h5>
            </div>

            <div
              id="collapseTwo"
              class="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">{this.header()}</div>
            </div>
          </div> */}
          <div class="card">
            <div class="card-header" id="headingThree">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  Part Price Details
                </button>
              </h5>
            </div>

            <div
              id="collapseThree"
              class="collapse show"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div class="card-body">{this.Part_Price_details()}</div>
            </div>
          </div>
        </div>
        {this.errorDialog4()}
        {this.errorDialog5()}
      </div>
    );
  }
}
