import React from "react";
import moment from "moment";
import "../Dealer_To_Dealer_Tax_Invoice/index.css";
import "../../../containers/color.css";
import Urls from "../../../../helpers/Urls";
import Strings from "../../../../helpers/Strings";
import {
  setDomLayoutAfterPrint,
  setDomLayoutForPrint,
} from "../../../../helpers/getDetails";
export class Gate_Pass extends React.Component {
  constructor(props) {
    console.log(props, "hjjhjh");
    super(props);
    this.state = {
      showTable: true,
    };
  }
  componentDidMount() {
    if (this?.props?.location?.state?.data) {
      this.setState(
        {
          EnquiryDetails: this.props.location.state.data,
        },
        () => {
          var tempTitle = document.title;
          document.title = !this.props?.location?.state?.showOptional?"DCH-"+moment().year()+"-"+this.state.EnquiryDetails.Gatepass_No:"GTP-"+moment().year()+"-"+this.state.EnquiryDetails.Gatepass_No;
          window.print();
          document.title = tempTitle;
          localStorage.setItem("FromHistory", true);
          this.props.history.push({
            pathname: Strings.vehical_invoice,
            state: {
              id: this.props.location.state.id,
              modelid: this.props.location.state.modelid,
              Status: this.props.location.state.Status,
              name: this.props.location.state.name,
              M_7id: this.props.location.state.M_7id,
            },
          });
        }
      );
    }
    this.updateClock();
  }
  convertToWord = (num) => {
    if (!num) return;
    let splittedNum = num.toString().split(".");
    let nonDecimal = splittedNum[0];
    let decimal = splittedNum[1];
    let TotalAmountWithTaxWord =
      this.price_in_words(Number(nonDecimal)) +
      (decimal ? " Rupees and" : " Rupees Only") +
      this.price_in_words(Number(decimal)) +
      (decimal ? "Paise Only" : "");
    return TotalAmountWithTaxWord;
  };
  price_in_words = (price) => {
    var sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt
          ? ""
          : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (
          (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];

    // Check if the price is a valid number
    if (((price += ""), isNaN(parseFloat(price)))) str = "";
    else if (parseFloat(price) > 0 && price.length <= 15) {
      // Adjusted to handle larger numbers including decimal places
      var priceParts = price.split("."); // Split the price into integer and fractional parts
      var integerPart = priceParts[0];
      var fractionalPart = priceParts[1] || "0"; // If there is no fractional part, default to '0'

      // Process integer part
      for (digitIdx = integerPart.length - 1; digitIdx >= 0; digitIdx--) {
        digit = integerPart[digitIdx] - 0;
        nxtDigit = digitIdx > 0 ? integerPart[digitIdx - 1] - 0 : 0;
        switch (integerPart.length - digitIdx - 1) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != integerPart[digitIdx + 1] &&
                    0 != integerPart[digitIdx + 2]
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != integerPart[digitIdx + 1] ||
                    0 != integerPart[digitIdx + 2]
                      ? " and"
                      : " Crore")
                : ""
            );
        }
      }

      str = words.reverse().join("");
    } else str = "";
    return str;
  };
  updateClock = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const timeString = `${hours}:${minutes}:${seconds}`;
    document.getElementById("clock").innerText = timeString;

    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const dateString = now.toLocaleDateString("en-US", options);
    document.getElementById("date").innerText = dateString;
  };
  Invoice = () => {
    return (
      <div>
        <h4 className=" invoice-header heading_text">
          {this.props.location.state?.showOptional
            ? "Vehicle Gate Pass"
            : "Customer Delivery Challan"}
        </h4>
        <div className="invoice-container-gatepass">
          <div className=" card-gatepass">Dealer Information</div>
          <table>
            <tr>
              <div className=" pl-2">
                <p className=" bold">{sessionStorage.getItem("dealer_name")}</p>
                <p>{sessionStorage.getItem("Dealer_Address")}</p>
                <p>
                  <span className=" bold">Phone</span> :{" "}
                  {sessionStorage.getItem("Dealer_Contact")}
                </p>
                <p>
                  <span className=" bold">Email</span> :{" "}
                  {sessionStorage.getItem("Dealer_Email")}
                </p>
                <p>
                  <span className=" bold">GST No.</span> :{" "}
                  {sessionStorage.getItem("Dealer_Gst_No")}
                </p>
              </div>
            </tr>
          </table>
        </div>
        <div className="invoice-container-gatepass">
          <div className=" card-gatepass">Customer Information</div>
          <table>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Name
              </td>
              <td style={{ width: "30%" }}>
                {this.props?.location?.state?.data?.Name}
              </td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Gate Pass No.
              </td>
              <td>{this.props?.location?.state?.data?.Gatepass_No}</td>
            </tr>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Mobile No.
              </td>
              <td>{this.props?.location?.state?.data?.Mobile_No}</td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Gate Pass Date
              </td>
              <td>{this.props?.location?.state?.data?.Gatepass_Date}</td>
            </tr>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Invoice No.
              </td>
              <td>{this.props?.location?.state?.data?.Invoice_No}</td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Address
              </td>
              <td>
                {this.props?.location?.state?.data?.Address1}
                {this.props?.location?.state?.data?.City
                  ? ", " + this.props?.location?.state?.data?.City
                  : ""}
                {this.props?.location?.state?.data?.State
                  ? ", " + this.props?.location?.state?.data?.State
                  : ""}
              </td>
            </tr>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Invoice Date
              </td>
              <td>
                {this.props?.location?.state?.data?.Invoice_Date
                  ? this.props?.location?.state?.data?.Invoice_Date
                  : ""}
              </td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                GST No.
              </td>
              <td>
                {this.props?.location?.state?.data?.GSTIN
                  ? this.props?.location?.state?.data?.GSTIN
                  : ""}
              </td>
            </tr>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                HP By
              </td>
              <td>
                {this.props?.location?.state?.data?.Financier
                  ? this.props?.location?.state?.data?.Financier
                  : ""}
              </td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                {/* GST No. */}
              </td>
              <td></td>
            </tr>
          </table>
        </div>
        <div className="invoice-container-gatepass mt-3">
          <div className=" card-gatepass">Vehicle Information</div>
          <table>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Model Name
              </td>
              <td style={{ width: "30%", wordWrap: "break-word" }}>
                {this.props?.location?.state?.data?.Model_Name}
              </td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Model Colour
              </td>
              <td>{this.props?.location?.state?.data?.Model_Colour}</td>
            </tr>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Chassis No.
              </td>
              <td>{this.props?.location?.state?.data?.Chassis_No}</td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Vehicle No.
              </td>
              <td>{this.props?.location?.state?.data?.Vehicle_No}</td>
            </tr>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Battery No.
              </td>
              <td>{this.props?.location?.state?.data?.Actual_Battery_No?this.props?.location?.state?.data?.Actual_Battery_No:this.props?.location?.state?.data?.Battery_No}</td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Motor No.
              </td>
              <td>{this.props?.location?.state?.data?.ActualMotor_No?this.props?.location?.state?.data?.ActualMotor_No:this.props?.location?.state?.data?.Motor_No}</td>
            </tr>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Controller No.
              </td>
              <td>{this.props?.location?.state?.data?.ActualController_Unit_No?this.props?.location?.state?.data?.ActualController_Unit_No:this.props?.location?.state?.data?.Contoller_No}</td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Charger No.
              </td>
              <td>{this.props?.location?.state?.data?.Actual_Charger_No?this.props?.location?.state?.data?.Actual_Charger_No:this.props?.location?.state?.data?.Charger_No}</td>
            </tr>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Display No.
              </td>
              <td>{this.props?.location?.state?.data?.ActualDispayNo?this.props?.location?.state?.data?.ActualDispayNo:this.props?.location?.state?.data?.Display_No}</td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                IOT Device No.
              </td>
              <td>{this.props?.location?.state?.data?.ActualIOT_Device_ID?this.props?.location?.state?.data?.ActualIOT_Device_ID:this.props?.location?.state?.data?.IOT_Device_NO}</td>
            </tr>
            <tr>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                Quantity
              </td>
              <td>{this.props?.location?.state?.data?.Quantity}</td>
              <td style={{ width: "20%" }} className=" bold-gatepaas">
                {!this.props?.location?.state?.showOptional
                  ? "Ex Showroom Price"
                  : ""}
              </td>
              <td>
                {!this.props?.location?.state?.showOptional
                  ? " ₹ " + this.props?.location?.state?.data?.AMV_Sale_Rate
                  : ""}
              </td>
            </tr>
          </table>
          {!this.props?.location?.state?.showOptional && (
            <table style={{ borderTop: "0px" }}>
              <tr>
                <td style={{ width: "20%" }} className=" bold-gatepaas">
                  Rupees In Words
                </td>
                <td>
                  {this.convertToWord(
                    this.props?.location?.state?.data?.AMV_Sale_Rate
                  )}
                </td>
              </tr>
            </table>
          )}
        </div>
        <div className="invoice-container-gatepass mt-3">
          <div className=" card-gatepass">Accessories Details</div>
          <table>
            <tr>
              <th>Sr No.</th>
              <th>Description</th>
              <th>Quantity</th>
            </tr>
            {this.props?.location?.state?.data?.AccessoriesDetails.map(
              (e, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{e?.Accesories_Name}</td>
                    <td>1</td>
                  </tr>
                );
              }
            )}
          </table>
        </div>
        <div className="invoice-container-gatepass mt-3 px-1">
          <ol className=" pl-3">
            <li>User Manual</li>
            <li>Accessories</li>
            <li>Tool Kit</li>
            <li>Rear View Mirrors</li>
            {this.props?.location?.state?.showOptional && <li>Invoice</li>}
          </ol>
        </div>
        <div className="invoice-container-gatepass mt-3 px-1">
          <div className=" row col-12 justify-content-between ">
            <div>
              <span className=" bold-gatepaas">For, </span>
              <span>{sessionStorage.getItem("dealer_name")}</span>
            </div>
            <div>
              <label style={{ fontWeight: "bold", marginRight: "-32px" }}>
                This is to confirm that I / We have taken the Delivery of
                <br></br>
                Wardwizard EV 2 Wheeler / 3 Wheeler with above Details after
                <br></br>
                Physical Verification and to my entire Satisfaction
              </label>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "30px",
            }}
          >
            <div className=" bold-gatepaas">AUTHORISED SIGNATORY</div>
            <div>
              <span id="clock"></span> <span id="date"></span>
            </div>
            <div style={{ marginRight: "125px", fontWeight: "600" }}>
              Customer Signature
            </div>
          </div>
        </div>
        <div className="invoice-container-gatepass mt-3">
          <div className=" card-gatepass">Terms & Conditions</div>
          <table>
            <tr>
              <ol style={{ paddingLeft: "17px" }}>
                <li>E & OE</li>
                <li>
                  The Vehicle has been thoroughly inspected, tested and is free
                  of any kind of defect and is upto my Satisfaction.
                </li>
                <li>
                  All disputes are subjected to the jurisdiction of the courts
                  of the law at{" "}
                  <span className=" bold">
                    {sessionStorage.getItem("Dealer_District")}
                  </span>{" "}
                  only.
                </li>
                <li>
                  I have checked my particulars and are correct to best of my
                  knowledge.
                </li>
                <li>
                  I give Wardwizard and its Agents/Channel Partners to contact
                  me for any Marketing or Promotion communications through any
                  medium and enable Whatsapp Assistance. I understand Wardwizard
                  Privacy Policy as mentioned on Wardwizard WebSite..
                </li>
              </ol>
            </tr>
          </table>
          <br></br>
          {this.props.location.state?.showOptional && (
            <hr style={{ borderTop: "1px dashed black" }}></hr>
          )}
        </div>
        {this.props.location.state?.showOptional && (
          <div className="invoice-container-gatepass mt-3 mb-3">
            <div className=" card-gatepass">Gate Pass</div>
            <table>
              <tr>
                <td style={{ width: "20%" }} className=" bold-gatepaas">
                  Vehicle No.
                </td>
                <td style={{ width: "30%" }}>
                  {this.props?.location?.state?.data?.Vehicle_No}
                </td>
                <td style={{ width: "20%" }} className=" bold-gatepaas">
                  Gate Pass No.
                </td>
                <td>{this.props?.location?.state?.data?.Gatepass_No}</td>
              </tr>
              <tr>
                <td style={{ width: "20%" }} className=" bold-gatepaas">
                  Customer Name
                </td>
                <td>{this.props?.location?.state?.data?.Name}</td>
                <td style={{ width: "20%" }} className=" bold-gatepaas">
                  Mobile No.
                </td>
                <td>{this.props?.location?.state?.data?.Mobile_No}</td>
              </tr>
            </table>
          </div>
        )}
      </div>
    );
  };
  render() {
    return (
      <div
        style={{ maxHeight: "100vh", marginTop: "200px", marginLeft: "-45px" }}
        id="printArea"
      >
        {this.Invoice()}
      </div>
    );
  }
}
