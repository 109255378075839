import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import Strings from "../../../helpers/Strings";
import moment from "moment";
import HiPaaSLogo from "../../components/Images/HiPaaS_logo.png";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";

const bcrypt = require("bcryptjs");

export class State extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      Active: "",
      State:"",
      State_Code:"",
      Region:"",
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      rowData: [],
    };
  }
  componentDidMount() {
    this.MasterAPICall()
  }
MasterAPICall = ()=>{
  const url = Urls.python_baseurl + "/State_list_SP_GetStateMaster";
  var data = JSON.stringify({
      "ID": "null"
     
  });
  fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: data,
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      if(res && res.length>0){
        this.setState({
          rowData:res,
          Active: res[0].Active,
          State: res[0].State,
          State_Code: res[0]["State Code"],
          Region: res[0].Region,
        })
      }

    })
    .catch(err => {
        process.env.NODE_ENV == 'development' && console.log(err)
    });
}
  masterTable() {
    let columnDefs = [
      { headerName: "Region", field: "Region", flex: "1",cellStyle:{color:"var(--main-bg-color)", cursor:"pointer"} },
      { headerName: "State", field: "State", flex: "2" },
      { headerName: "State Code", field: "State Code", flex: "1" },
      { headerName: "Active", field: "Active", flex: "1" },
      
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) =>
              this.setState({
                Active: event.data.Active,
                State: event.data.State,
                State_Code: event.data["State Code"],
                Region: event.data.Region,
              })
            }
          ></AgGridReact>
        </div>
      </div>
    );
  }
  header = () => {
    return (
      <div className=" card p-3 mt-4">
        <div className=" row">
          <div className=" col-3">
            <label htmlFor="State_Code">Region</label>
            <input
              value={this.state.Region}
              id="State_Code"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="State">State</label>
            <input
              value={this.state.State}
              id="State"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="State_Code">State Code</label>
            <input
              value={this.state.State_Code}
              id="State_Code"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Active">Active</label>
            <input
              value={this.state.Active}
              id="Active"
              className=" form-control "
              readOnly
            ></input>
          </div>
        </div>
        
      </div>
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">State Master</h4>
        {this.header()}
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                State Master List
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">{this.masterTable()}</div>
          </div>
        </div>
      </div>
    );
  }
}
