import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import moment from "moment";

const bcrypt = require("bcryptjs");

export class Employee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "multiple",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      Active: "",
      emp_name: "",
      emp_type: "",
      contact_no: "",
      Empl_code: "",
      Date_of_joining: "",
      email: "",
      id: 0,
      Empolyee_Type_option: [],
      training_details: [],
      select_training: [],
    };
  }
  componentDidMount() {
    this.MasterAPICall();
    this.getEmpolyeeType();
  }
  onSelect = (event, name) => {
    this.setState({
      Active: event.target.options[event.target.selectedIndex].text,
    });
  };
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "''",
      DealerId: sessionStorage.getItem("dealerid"),
      SqlFor: "EmplMaster",
      HOBrID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          // res=res[0]
          this.setState({
            rowData: res,
            // Active: res[0].Active,
            // contact_no: res[0]["Contact_No"],
            // emp_type: res[0]["Employee Type"],
            // emp_name: res[0]["Employee Name"],
            // id: res[0].ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getTrainingList = (id) => {
    const url = Urls.python_baseurl + "/sp_get_document_master";
    var data = JSON.stringify({
      employee_id: `'${id}'`,
      dealer_id: `'${sessionStorage.getItem("dealerid")}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            training_details: res,
            select_training:res.filter(e=>e.active=="Y")
          },()=>{
            console.log(this.state.select_training,"scjkjk")
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      { headerName: "Employee Code", field: "Employee Code", width: "130px" },
      {
        headerName: "Employee Name",
        field: "Employee Name",
        width: "140px",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Designation", field: "Employee Type", width: "130px" },
      { headerName: "Mobile No.", field: "Contact_No", width: "130px" },
      {
        headerName: "Date Of Joining",
        field: "Date of joining",
        width: "130px",
      },
      { headerName: "Email", field: "Email ID", width: "130px" },
      { headerName: "Dealer Name", field: "Dealer Name", width: "130px" },
      { headerName: "Active", field: "Active", width: "130px" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (event.colDef.field == "Employee Name") {
                this.setState(
                  {
                    Active: event.data.Active,
                    contact_no: event.data["Contact_No"],
                    emp_type: event.data["Employee Type"],
                    emp_name: event.data["Employee Name"],
                    Empl_code: event.data["Employee Code"],
                    Date_of_joining: moment(
                      event.data["Date of joining"],
                      "DD-MM-YYYY"
                    ).format("YYYY-MM-DD"),
                    email: event.data["Email ID"],
                    id: event.data.ID,
                    training_details: [],
                    select_training: [],
                  },
                  () => {
                    this.getTrainingList(this.state.id);
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  getEmpolyeeType = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: "0",
      type: "EmpType",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Empolyee_Type_option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  onChangeMobileNumber = (e) => {
    const { value } = e.target;
    // Regular expression to allow only numbers
    const mobileNumber = value.replace(/\D/g, "").slice(0, 10); // Limit to 10 characters
    this.setState({ contact_no: mobileNumber });
  };
  onChange = (event, key) => {
    var minLength = 3;
    var maxLength = 3;
    if (this.state.Empl_code.length < minLength) {
      alert("The employee code must contain a Minimum of 3 characters.");
      this.setState({
        Empl_code: "",
      });
      return;
    }
    if (this.state.Empl_code.length > maxLength) {
      alert("The employee code must contain a Max. of 3 characters.");
      this.setState({
        Empl_code: "",
      });
      return;
    }

    const url = Urls.python_baseurl + "/sp_getemployeecodedetails";
    var data = JSON.stringify({
      dealer_id: sessionStorage.getItem("dealerid"),
      employee_code: `'${
        sessionStorage.getItem("Dealer_Code") + "-" + this.state.Empl_code
      }'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0][0].Name != "") {
            alert("This Employee Already Exists");
            // this.clear()
            this.setState({
              Empl_code: "",
            });
          } else {
            this.setState({
              Empl_code: this.state.Empl_code,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  onChange_contact_no = (event, key) => {
    const url = Urls.python_baseurl + "/SP_Get_EmployeeName";
    var data = JSON.stringify({
      mobile: this.state.contact_no,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].Name != "") {
            alert("This Employee Already Exists");

            this.setState({
              contact_no: "",
            });
          } else {
            this.setState({
              contact_no: this.state.contact_no,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  clear = () => {
    this.setState({
      Empl_code: "",
      Date_of_joining: "",
      email: "",
      emp_name: "",
      emp_type: "",
      contact_no: "",
      Active: "0",
    });
  };
  handledateChange = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  };
  header = () => {
    return (
      <div className="row">
        <div className="col-3">
          <label htmlFor="model-c">
            Employee Code <span style={{ color: "red" }}>*</span>
          </label>
          <input
            disabled={this.state.id != 0 ? true : false}
            autoComplete="off"
            onChange={(e) => this.setState({ Empl_code: e.target.value })}
            onMouseLeave={() => {
              this.state.id == 0 &&
                this.state.Empl_code !== "" &&
                this.state.Empl_code !== undefined &&
                this.onChange();
            }}
            value={this.state.Empl_code}
            id="model-c"
            className=" form-control"
            minlength="3"
            maxlength="3"
            onKeyPress={(e) => {
              const pattern = /[A-Za-z0-9\s]/; // Regular expression pattern for alphabetic characters
              const inputChar = String.fromCharCode(e.charCode);
              if (!pattern.test(inputChar)) {
                e.preventDefault(); // Prevent input if it's not alphabetic
              }
            }}
          ></input>
        </div>
        <div className=" col-3">
          <label htmlFor="model-c">
            Employee Name <span style={{ color: "red" }}>*</span>
          </label>
          <input
            autoComplete="off"
            onChange={(e) => this.setState({ emp_name: e.target.value })}
            value={this.state.emp_name}
            id="model-c"
            className=" form-control"
            onKeyPress={(e) => {
              const pattern = /[A-Za-z\s]/; // Regular expression pattern for alphabetic characters
              const inputChar = String.fromCharCode(e.charCode);
              if (!pattern.test(inputChar)) {
                e.preventDefault(); // Prevent input if it's not alphabetic
              }
            }}
          ></input>
        </div>
        <div className=" col-3">
          <label htmlFor="model-c">
            Designation <span style={{ color: "red" }}>*</span>
          </label>
          <select
            value={this.state.emp_type}
            onChange={(e) => this.setState({ emp_type: e.target.value })}
            id="model-c"
            className=" form-control "
          >
            <option value={"0"}>Select</option>
            {this.state.Empolyee_Type_option &&
              this.state.Empolyee_Type_option.map((e) => (
                <option value={e.Etype}>{e.Etype}</option>
              ))}
          </select>
        </div>
        <div className=" col-3">
          <label htmlFor="model-c">
            Mobile No. <span style={{ color: "red" }}>*</span>
          </label>
          <input
            autoComplete="off"
            onChange={(e) => this.onChangeMobileNumber(e, "contact_no")}
            //  onChange={(e) => this.setState({ Empl_code: e.target.value })}
            onMouseLeave={() => {
              this.state.id == 0 &&
                this.state.contact_no !== "" &&
                this.state.contact_no !== undefined &&
                this.onChange_contact_no();
            }}
            value={this.state.contact_no}
            id="model-c"
            className=" form-control"
          ></input>
        </div>
        <div className=" col-3 mt-2">
          <label htmlFor="model-c">
            Date Of Joining <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="date"
            autoComplete="off"
            value={this.state.Date_of_joining}
            onChange={(event) => {
              this.handledateChange(event, "Date_of_joining");
            }}
            id="model-c"
            className=" form-control"
          ></input>
        </div>
        <div className=" col-3 mt-2">
          <label htmlFor="model-c">Email</label>
          <input
            autoComplete="off"
            onChange={(e) => this.setState({ email: e.target.value })}
            value={this.state.email}
            id="model-c"
            className=" form-control"
          ></input>
        </div>
        <div className="col-3 mt-2">
          <label htmlFor="active">
            Active <span style={{ color: "red" }}>*</span>
          </label>
          <select
            className="form-control list-dashboard"
            id="Active"
            value={this.state.Active}
            onChange={(event) => {
              this.onSelect(event, "Active");
            }}
          >
            <option selected value="0">
              Select
            </option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </div>
      </div>
    );
  };
  addnew = () => {
    this.setState({
      Active: "",
      emp_name: "",
      emp_type: "",
      contact_no: "",
      id: 0,
      Empl_code: "",
      email: "",
      Date_of_joining: "",
    });
  };
  clickNavigation = () => {
    const url = Urls.python_baseurl + "/Employe_Save_SP_Save_Employee";
    var data = JSON.stringify({
      ID: this.state.id,
      dealerID: parseInt(sessionStorage.getItem("dealerid")),
      HOBr_id: parseInt(sessionStorage.getItem("dealerid")),
      p1: `'${this.state.emp_name}'`,
      p2: this.state.Empolyee_Type_option.filter(
        (e) => e.Etype == this.state.emp_type
      )[0]?.ID,
      active: `'${this.state.Active}'`,
      Contact_No: `'${this.state.contact_no}'`,
      Email_ID: `'${this.state.email}'`,
      Date_of_joining: `'${this.state.Date_of_joining}'`,
      Employee_Code: `'${
        sessionStorage.getItem("Dealer_Code") + "-" + this.state.Empl_code
      }'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (this.state.id) {
            alert("Record Updated Scuccesfully");
          } else {
            alert("Record Saved Succesfully");
          }
          window.location.reload();
          //  this.MasterAPICall()
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Validate = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emailisvalid = emailRegex.test(this.state.email);
    if (this.state.Empl_code == "") {
      alert("Please Enter the Employee Code.");
      return;
    } else if (this.state.emp_name == "") {
      alert("Please Enter the Employee Name.");
      return;
    } else if (this.state.emp_type == "" && this.state.emp_type == 0) {
      alert("Please Select the Designation.");
      return;
    } else if (this.state.contact_no == "") {
      alert("Please Select the Mobile No.");
      return;
    } else if (this.state.Date_of_joining == "") {
      alert("Please Select the Date Of Joining");
      return;
    } else if (!emailisvalid && this.state.email != "") {
      alert("Please Enter Valid Email Id.");
      return;
    } else if (
      this.state.Active == "0" ||
      this.state.Active == "" ||
      this.state.Active == undefined
    ) {
      alert("Please Select the Active");
      return;
    } else if (this.state.contact_no != "") {
      if (this.state.contact_no.match(/\d/g).length !== 10) {
        alert("Please Enter Valid Mobile No.");
        return;
      }
    }

    this.clickNavigation();
  };
  handelTrainingDetailsSave = () => {
    if (this.state.select_training.length <= 0) {
      alert("Please select atleast one Training details");
      return;
    }
    for (let i = 0; i < this.state.select_training.length; i++) {
      if (!this.state.select_training[i].date) {
        alert("Please Select Document Date");
        return;
      }
    }
    const url = Urls.python_baseurl + "/sp_save_employee_wise_training";
    var data = JSON.stringify(
      this.state.select_training.map((e) => {
        return {
          employee_id: `'${this.state.id}'`,
          dealer_id: `'${sessionStorage.getItem("dealerid")}'`,
          training_id: `'${e.Document_id}'`,
          active: `'Y'`,
          date: `'${e.date?moment(e.date).format("YYYY-MM-DD"):""}'`,
        };
      })
    );
    console.log(data, "dshdshj");
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          alert("Record Saved Succesfully");
          // window.location.reload();
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  showtrainingDetails() {
    let columnDefs = [
      {
        headerName: "",
        field: "",
        width: "60px",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        checkboxSelection: true,
      },
      { headerName: "Training Type", field: "Training_type", flex: "1" },
      { headerName: "Document Name", field: "Document_name", flex: "1" },
      {
        headerName: "Document Date",
        flex: "1",
        cellRenderer: (params) => {
          const onDateChange = (event) => {
            const newValue = event.target.value;
            const data = params.data;
            const newData = { ...data, ["date"]: newValue };
            params.node.setData(newData);
            let update_selected_traning=this.state.select_training.map((e) => {
              if (e.Document_id == params.data.Document_id) {
                return { ...e, date: newValue };
              } else {
                return e;
              }
            });
            this.setState({
              select_training:update_selected_traning
            },()=>{
              console.log(this.state.select_training,"djhshjdsf")
            })
          };

          return (
            <input
              type="date"
              className="form-control"
              value={params.data.date}
              onChange={onDateChange}
            />
          );
        },
      },
    ];
    return (
      <div>
        <div className=" row justify-content-end mr-2">
          <button
            onClick={this.handelTrainingDetailsSave}
            className="btn Opertion_btn clickable"
          >
            Save
          </button>
        </div>
        <div
          className="ag-theme-balham"
          style={{ padding: "0", marginTop: "30px" }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            overlayLoadingTemplate={this.state.overlayLoadingTemplate}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.training_details}
            enableCellTextSelection={true}
            onCellClicked={(event) => {}}
            onRowSelected={(event) => {
              console.log(this.state.select_training, "dsfhjhjfhdsj");
              if (event.node.isSelected()) {
                this.setState(
                  {
                    select_training: [
                      ...this.state.select_training,
                      event.node.data,
                    ],
                  },
                  () => {
                    console.log(this.state.select_training, "dsfhjhjfhdsj");
                  }
                );
              } else {
                if (this.state.select_training.length > 0) {
                  this.setState({
                    select_training: this.state.select_training.filter(
                      (item) =>
                        item.Document_name !== event.node.data.Document_name
                    ),
                  },()=>{
                    console.log(this.state.select_training,"hjdsjdjs")
                  });
                }
              }
            }}
            onFirstDataRendered={({ api }) => {
              api.forEachNode((node) => {
                if (node.data.active == "Y") {
                  node.setSelected(true);
                } else {
                  node.setSelected(false);
                }
              });
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <h4 className="heading_text">Employee</h4>
        <div class="row justify-content-end ">
          <button
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={this.addnew}
          >
            Clear
          </button>
          <button
            type="submit"
            className="btn Opertion_btn clickable mr-5"
            onClick={this.Validate}
          >
            Save
          </button>
        </div>
        <div class="form-group col-md-12 mt-2">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Employee List
                </button>
              </h5>
            </div>
            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">{this.masterTable()}</div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Employee Details
                </button>
              </h5>
            </div>

            <div
              id="collapseTwo"
              class="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">{this.header()}</div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="heading3">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapsethree"
                  aria-expanded="true"
                  aria-controls="collapsethree"
                >
                  Training Details
                </button>
              </h5>
            </div>

            <div
              id="collapsethree"
              class="collapse show"
              aria-labelledby="heading3"
              data-parent="#accordion"
            >
              <div class="card-body">
                {this.state.training_details.length > 0 &&
                  this.showtrainingDetails()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
