import React from "react";
import moment from "moment";
import Logo from "../../components/Images/Logo.png";
import "../../containers/color.css";
import Urls from "../../../helpers/Urls";
import { Tiles } from "../../components/Tiles";
import ReactApexChart from "react-apexcharts";
import Strings from "../../../helpers/Strings";
import { Filters } from "../../components/Filters";
import Notification from "../../components/Notification";
import { WARNING, SUCCESS, INFO, ERROR } from "../../components/Notification"
export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 100,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      startdate: "",
      enddate: "",
      pichart: {
        series: [],
        options: {
          chart: {
            // width: 380,
            type: "pie",
          },
          labels: [],
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.percentage:.1f}%",
                distance: -50,
                style: {
                  color: "black",
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "left",
                },
              },
            },
          ],
        },
      },
      pichart_2: {
        series: [],
        options: {
          chart: {
            // width: 380,
            type: "pie",
          },
          labels: [],
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.percentage:.1f}%",
                distance: -50,
                style: {
                  color: "black",
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "left",
                },
              },
            },
          ],
        },
      },
      barChartOptions: {
        chart: {
          type: "bar",
          // width: 550,
        },
        xaxis: {
          categories: ["Q1", "Q2", "Q3", "Q4"],
          title: {
            text: "Quarter",
          },
        },
        yaxis: {
          title: {
            text: "Sales",
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return "INR" + value;
            },
          },
        },
        series: [
          {
            name: "Sales",
            data: [400, 430, 448, 567],
          },
        ],
      },
      summeryDetails: {
        followup: 0,
        pendingm1count: 0,
        PendingPDI: 0,
        PendingforMoneyreceived: 0,
        PDI: 1,
        vehiclestock: 0,
        PendingforBilling: 0,
        PendingforAllocation: 0,
        B2CSales: 0,
        D2D: 0,
        D2DDeliveryChallan: 0,
        DelarSales: 0,
        TotalPDI: 0,
        DealerSalesOrder: 0,
      },
      options: {},
      options2: {},
      startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      dealerid:"",
      chassis_pending_for_pdi:0
    };
  }
  componentDidMount() {
    this.MasterAPI();
    this.getPendingChassis()
  }
  MasterAPI = async () => {
    const url = Urls.python_baseurl + "/DashboardCount_sp_dashboardcount";
    var data = JSON.stringify({
      dealer_id: `'${this.state.dealerid?this.state.dealerid:sessionStorage.getItem("dealerid")}'`,
      startdate: `'${moment(this.state.startDate).format("YYYY-MM-DD")}'`,
      enddate: `'${moment(this.state.endDate).format("YYYY-MM-DD")}'`,
    });
    let res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    });
    res = await res.json();
    let newPicChartObj1 = [];
    let newPicChartObj2 = [];
    if (res && res.length > 0) {
      console.log(res[1] && res[1].model_name != null, "kjkkj");

      const isNullish = Object.values(res[1]).every((value) => {
        if (value === null || value === "") {
          return true;
        }
        return false;
      });
      if (isNullish) {
        const labels = "";
        const series = "";
        const labels2 = "";
        const series2 = "";
        this.setState({
          summeryDetails: [],
          pichart: newPicChartObj1,
          pichart_sales: newPicChartObj2,
          options: { series: series, labels: labels },
          options2: { series: series2, labels: labels2 },
        });
      } else {
        const labels =
          res[1][0] && res[1][0].model_name != null
            ? res[1].map((item) => item.model_name)
            : [];
        const series =
          res[1][0] && res[1][0].Count != null
            ? res[1].map((item) => item.Count)
            : [];
        const labels2 =
          res[2][0] && res[2][0].model_name != null
            ? res[2].map((item) => item.model_name)
            : [];
        const series2 =
          res[2][0] && res[2][0].Count != null
            ? res[2].map((item) => item.Count)
            : [];

        newPicChartObj1 = { ...this.state.pichart };
        newPicChartObj1.series = series;
        newPicChartObj2 = { ...this.state.pichart };
        newPicChartObj2.series = series2;

        //  newpicchartobj = this.state.pichart;
        // newpicchartobj.series = series;
        this.setState({
          summeryDetails: res[0][0],
          summeryDetails: res[0][0],
          pichart: newPicChartObj1,
          options: { series: series, labels: labels },
          pichart_2: newPicChartObj2,
          options2: { series: series2, labels: labels2 },
        });
      }
    }
  };
  showTiles = () => {
    console.log(window.innerWidth);
    let url = "";
    const summary = [
      {
        name: "Today's Followup",
        value: this.state.summeryDetails?.followup,
        color: "green",
      },
      {
        name: "Open Enquiry",
        value: this.state.summeryDetails?.pendingm1count,
        color: "orange",
        url: Strings.Sales_DB,
      },
      // {
      //   name: "Today's Followup",
      //   value: this.state.summeryDetails?.followup,
      //   color: "#129DC9",
      // },
      {
        name: "Pending For Money Receipt",
        value: this.state.summeryDetails?.PendingforMoneyreceived,
        color: "orange",
      },
      {
        name: "Chassis Pending For PDI",
        value: this.state.summeryDetails?.PendingPDI,
        color: "orange",
      },
      {
        name: "Chassis Available for Allocation",
        value: this.state.summeryDetails?.PendingforAllocation,
        color: "orange",
      },

      {
        name: "Chassis Pending For Vehicle Invoice",
        value: this.state.summeryDetails?.PendingforBilling,
        color: "orange",
      },
      {
        name: "Retail Sale",
        value: this.state.summeryDetails?.B2CSales,
        color: "green",
      },
      {
        name: "Dealer To Dealer Sale",
        value: this.state.summeryDetails?.D2D,
        color: "green",
      },
      {
        name: "Delivery Challan",
        value: this.state.summeryDetails?.D2DDeliveryChallan,
        color: "green",
      },
      {
        name: "Vehicle Stock As On",
        value: this.state.summeryDetails?.vehiclestock,
        color: "#34ebc6",
      },
      {
        name: "Total Jobcard",
        value: this.state.summeryDetails?.TotalPDI,
        color: "green",
      },
      {
        name: "Dealer Sales Order",
        value: this.state.summeryDetails?.DealerSalesOrder,
        color: "green",
      },
    ];
    let data = [];
    return summary.map((item) => {
      console.log(window.innerWidth, "kkk");
      return (
        <div className="form-group">
          <Tiles
            isClickable={item.name}
            header_text={item.name}
            value={item.value}
            isenrollment={true}
            color={item.color}
            differentTile={true}
            url={url}
            width={"210px"}
            onClick={() => {
              if (item.name === "Open Enquiry") {
                this.props.history.push("/EnquiryList", {
                  data: [
                    {
                      open_enquiry: "open enquiry",
                      dashboard_Click: true,
                      title: "Open Enquiry",
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:true
                    },
                  ],
                });
              } else if (item.name === "Today's Followup") {
                this.props.history.push("/Followup", {
                  data: [
                    {
                      open_enquiry: "Today's Followup",
                      dashboard_Click: true,
                      title: "Today's Followup",
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:false
                    },
                  ],
                });
              } else if (item.name === "Pending For Money Receipt") {
                this.props.history.push("/Vehicle_Booking", {
                  data: [
                    {
                      pendind_data: "Pending For Money Received",
                      dashboard_Click: true,
                      title: "Pending For Money Receipt",
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:false
                    },
                  ],
                });
              } else if (item.name === "Chassis Pending For Vehicle Invoice") {
                this.props.history.push(
                  "/chassis_pending_for_vehicle_billing",
                  {
                    data: [
                      {
                        pendind_data: "PendingVehicleBilling",
                        dashboard_Click: true,
                        title: "Chassis Pending For Vehicle Invoice",
                        startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:false
                      },
                    ],
                  }
                );
              } else if (item.name === "Retail Sale") {
                this.props.history.push("/Vehical_Invoice", {
                  data: [
                    {
                      pendind_data: "M8Details",
                      dashboard_Click: true,
                      title: "Retail Sale",
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:true
                    },
                  ],
                });
              } else if (item.name === "Vehicle Stock As On") {
                this.props.history.push("/Vehicle_stock", {
                  data: [
                    {
                      pendind_data: "Sales_DB",
                      dashboard_Click: true,
                      title: "Vehicle Stock As On",
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:false
                    },
                  ],
                });
              } else if (item.name === "Total Jobcard") {
                this.props.history.push("/JobCard_List", {
                  data: [
                    {
                      pendind_data: "Sales_DB",
                      dashboard_Click: true,
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:true
                    },
                  ],
                });
              } else if (item.name === "Chassis Pending For PDI") {
                this.props.history.push("/Pending_Jobcard", {
                  data: [
                    {
                      pendind_data: "PendingVehicleforPDI",
                      dashboard_Click: true,
                      title: "Pending For PDI",
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:false
                    },
                  ],
                });
              } else if (item.name === "Chassis Available for Allocation") {
                this.props.history.push("/Pending_Jobcard", {
                  data: [
                    {
                      pendind_data: "PendingforVehicleAllocation",
                      dashboard_Click: true,
                      title: "Chassis Available for Allocation",
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:false
                    },
                  ],
                });
              } else if (item.name === "Dealer Sales Order") {
                this.props.history.push("/Dealer_sales_order", {
                  data: [
                    {
                      Delar_PO_List: true,
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:true
                    },
                  ],
                });
              } else if (item.name === "Dealer To Dealer Sale") {
                this.props.history.push("/Delar_to_Delar_Sales", {
                  data: [
                    {
                      Delar_PO_List: true,
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:true
                    },
                  ],
                });
              } else if (item.name === "Delivery Challan") {
                this.props.history.push("/Dealer_to_delar_challan", {
                  data: [
                    {
                      Delar_PO_List: true,
                      startDate:this.state.startDate,
                      endDate:this.state.endDate,
                      dealerid:this.state.dealerid,
                      showtimernagefilter:true
                    },
                  ],
                });
              }
            }}
          />
        </div>
      );
    });
  };
  showPieChart = () => {
    return (
      <div style={{ margin: "auto" }} className=" pt-3">
        <ReactApexChart
          options={this.state.options}
          series={this.state.pichart.series}
          type="pie"
          width={350}
        />
      </div>
    );
  };
  showSecondPieChart = () => {
    return (
      <div style={{ margin: "auto" }} className="pt-3">
        <ReactApexChart
          options={this.state.options2}
          series={this.state.pichart_2.series}
          type="pie"
          width={350}
        />
      </div>
    );
  };
  showPieChart_2 = () => {
    return (
      <div style={{ margin: "auto" }} className=" pt-3">
        <ReactApexChart
          options={this.state.options_sales}
          series={this.state.pichart_sales.series_seles}
          type="pie"
          width={350}
        />
      </div>
    );
  };
  showFilterOption = () => {
    return (
      <div className=" row mt-3">
        {/* <div className="col-3">
          <label htmlFor="dealer-name">Dealer Name</label>
          <input className=" form-control" list="dealer-list"></input>
          <datalist id="dealer-list">
            <option>Electra Motors</option>
            <option>Quantum Charge Autos</option>
            <option>EcoSpark Dealerships</option>
            <option>Lightning Wheels Hub</option>
            <option>GreenDrive Emporium</option>
            <option>ElectriCruisers Outlet</option>
            <option>TeslaSphere Motors</option>
            <option>CurrentFlow Auto Center</option>
            <option>PowerPulse Cars</option>
            <option>SparkDrive Dealers</option>
          </datalist>
        </div> */}
        {/* <div className=" col-3">
          <label>From Date</label>
          <input value={this.state.startdate} className=" form-control " type="date"></input>
        </div>
        <div className=" col-3">
          <label>To Date</label>
          <input value={this.state.enddate} className=" form-control " type="date"></input>
        </div> */}
      </div>
    );
  };

  showBarChart = () => {
    return (
      <ReactApexChart
        options={this.state.barChartOptions}
        series={this.state.barChartOptions.series}
        type={this.state.barChartOptions.chart.type}
        // width={400}
      />
    );
  };
  update = (key, value) => {
    this.setState({
        [key]: value,
    },()=>{
      this.MasterAPI()
    })}
  setData = (startDate, endDate, selected_val) => {
    this.setState({
      startDate,
      endDate,
      selected_val,
    },()=>{
      this.MasterAPI()
    });
  };
  _renderTopbar = () => {
    return (
      <Filters
        isTimeRange={true}
        setData={this.setData}
        onGridChange={this.onGridChange}
        update={this.update}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
      />
    );
  };
  getPendingChassis = (data) => {
    const url = Urls.python_baseurl + "/SP_VehicleSalesDetails";
    var data = JSON.stringify({
      Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
      SqlFor: "'VehicleStock'",
      startdate: "''",
      enddate: "''",
      Dashboard_For: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          const isNullish = Object.values(res[0][0]).every((value) => {
            if (value === null || value === "") {
              return true;
            }
            return false;
          });
          if (isNullish) {
            this.setState({
              rowData: [],
            });
          } else {
            let lightCoralCount = 0;
            for(let i=0;i<res[0].length;i++){
              let today = moment();
              let dbdate = moment(res[0][i].create_date).format("YYYY-MM-DD");
              let differenceInHours = today.diff(dbdate, 'hours');
              let pdi = res[0][i].is_pdi;
              
              if (differenceInHours >= 48 && pdi != 'Y') {
                  lightCoralCount++;
              }
            }
            this.setState({
              chassis_pending_for_pdi:lightCoralCount
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  render() {
    return (
      <div style={{ overflowX: "hidden" }} className=" p-2">
        {this.state.chassis_pending_for_pdi!==0&&(sessionStorage.getItem("UserRole") == "1" )&&<Notification message={`Alert!!! ${this.state.chassis_pending_for_pdi} Vehicles are Pending for PDI for more than  48 Hours.`} type={ERROR} margin={50} alert_for="hide-chassis-pending-for-pdi-notificatoion"/>}
        <h4 style={{ marginLeft: "0px" }} className="heading_text">
          Dashboard
        </h4>
        {this._renderTopbar()}
        {this.showFilterOption()}
        <br></br>
        <div className=" form-row mt-3">{this.showTiles()}</div>
        <div className=" row ">
          <div className=" card col-5 ml-3">
            <h6 className=" mt-2">Model Wise Enquiries</h6>
            {this.showPieChart()}
          </div>
          <div className=" card col-6 ml-4">
            <h6 className=" mt-2">Model Wise Retail Sale</h6>
            {this.showSecondPieChart()}
          </div>
          {/* <div className=" card col-6 ml-3">
            <h6 className=" mt-2">Vehicle Invoice</h6>
            {this.showBarChart()}
          </div> */}
        </div>
      </div>
    );
  }
}
