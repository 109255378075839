import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import "@fortawesome/fontawesome-free/css/all.css";

const bcrypt = require("bcryptjs");

export class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      Aggrid_ClaimLineData: "",
      showerror: "",
      type: "0",
      typeid: 0,
      name: "",
      double_save:false,
      address1: "",
      address2: "",
      address3: "",
      city: "",
      pincode: "",
      State: "0",
      stateid: 0,
      Region: "34554",
      Regionid: 0,
      DeliveryStateid: 0,
      Country: "INDIA",
      Countryid: 1,
      Mobileno: "",
      Phoneno: "",
      email: "",
      customername12:"",
      contact_person: "",
      contact_person_phone: "",
      pan_no: "",
      tinno: "",
      cst: "",
      st_vat: "",
      Customername:"",
      lbt: "",
      active: "0",
      GS_tinno: "",
      customer_code: "",
      Delivery_Name: "",
      Delivery_Mobile: "",
      Deliverycontact_person: "",
      Deliverycontact_person_phone: "",
      Deliveryaddress1: "",
      Deliveryaddress2: "",
      Deliveryaddress3: "",
      Deliverycity: "",
      Deliverypincode: "",
      DeliveryState: "",
      Deliveryemail: "",
      Deliverypan_no: "",
      DeliveryGS_tinno: "",
      Parts: "",
      Lubricant: "",
      Labour: "",
      Tahsil: "",
      Birthdate: "",
      AnniversaryDate: "",
      customerType: [],
      customerstate: [],
      customerdistrict: [],
      customerRegion: [],
      customerTahsil: [],
      customer_List:[],
      Delivery_Distric_Option: [],
      iid: 0,
      typeid:0,
      deliver_Taluka_Option: [],
      dealar_id:"",
      newcustomer:"",
      updateflag:false
    };
  }
  componentDidMount() {
    this.MasterAPICall();
    this.customer_type();
    this.customer_state();
    
    // this.customer_district()
    // this.customer_region()
    // this.customer_Tahsil()
  }
  onSelect = (event, name) => {
    this.setState({
      active: event.target.options[event.target.selectedIndex].text,
    });
  };
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "''",
      DealerId: sessionStorage.getItem("dealerid"),
      SqlFor: "CustomerMasterList",
      HOBrID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res);
          this.setState(
            {
              rowData: res,
              id: res[0]?.ID,
            },
            () => {
              // this.customer_display()
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelSaveAgain = (id) => {
    const url = Urls.python_baseurl + "/DealerWise_SP_DealerWiseCustomer_save";
    var data = JSON.stringify({
      DealerId: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      CustID: `${id}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if(this.state.iid===0){
            alert("Customer Saved Succesfully");
          }else{
            alert("Customer Saved Succesfully");

          }
         this.MasterAPICall()
         this.setState({
          double_save:false,
         },()=>{
          window.location.reload();

         })
               }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  get_customer_details_new= (data) => {
    const url = Urls.python_baseurl + "/SP_GetDealer_CustomerSupplier";
    var data = JSON.stringify({
      "ID": this.state.type,
      "Type": "'C'",
      "DealerID":this.state.dealar_id?this.state.dealar_id: sessionStorage.getItem("dealerid")
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          alert("Record Saved Succesfully");
            // this.setState({
            //   customer_List: res,
            // });
         
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  }
  customer_type = () => {
    const url = Urls.python_baseurl + "/sp_GetCustType";
    var data = JSON.stringify({
      Type: "C",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res);
          this.setState({
            customerType: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  customer_state = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: "0",
      type: "'State'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res6587", res);
          this.setState({
            customerstate: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  customer_district = () => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: "20",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res);
          this.setState({
            customerdistrict: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  customer_region = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: this.state.stateid,
      type: "Region",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res);
          this.setState({
            customerRegion: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  customer_Tahsil = (key) => {
    const url = Urls.python_baseurl + "/Display_Tehsil_sp_tehsildisplay";
    var data = JSON.stringify({
      state_name: this.state.stateid,
      district_name: this.state.district_name,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (key == "District") {
            this.setState({
              customerTahsil: res,
            });
          } else {
            this.setState({
              deliver_Taluka_Option: res,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Get_Tahsil = (key, dummy = []) => {
    
    const url = Urls.python_baseurl + "/Display_Tehsil_sp_tehsildisplay";
    var data = JSON.stringify({
      state_name: this.state.stateid,
      district_name: this.state.District,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {

            this.setState({
              customerTahsil: res,
              // Tahsil: dummy&&dummy[0]?.tehsil,
            });
          
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  customerType = (event) => {
    let row = [];
    this.state.customerType.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };

  customer_List = (event) => {
    let row = [];
    this.state.customer_List.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  customerstate = (event) => {
    let row = [];
    this.state.customerstate.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  customerdistrict = (event) => {
    let row = [];
    this.state.customerdistrict.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  customerTahsil = (event) => {
    let row = [];
    this.state.customerTahsil.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Town_Name}</option>);
    });
    return row;
  };
  customerregion = (event) => {
    let row = [];
    this.state.customerRegion.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  binddata = (key, res) => {
    this.lead_region();
    this.lead_district(key, res);
  };
  customerdatabind = (id) => {
    const url = Urls.python_baseurl + "/SP_GetDealer_CustomerSupplier_Details";
    var data = JSON.stringify({
      ID: id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res[0]);
          this.setState({
            
            // type: res[0].Cust_Type,
            // name: res[0].Customer_name,
            address1: res[0][0].Dealer_Address_1,
            address2: res[0][0].Dealer_Address_2,
            address3: res[0].add3,
            city: res[0][0].Dealer_City,
            pincode: res[0][0].Pincode,
            State: res[0][0].Dealer_State_ID,
             stateid: res[0][0].Dealer_State_ID,
            // Region: res[0][0].Dealer_Region_ID,
            Country: "INDIA",
            District: res[0][0].dealer_district_id,
            Tahsil:res[0][0].dealer_taluka_id?parseInt(res[0][0].dealer_taluka_id):0,
            Phoneno: res[0][0].Dealer_Mobile,
            email: res[0][0].Dealer_Email,
            contact_person: res[0].Contact_Person,
            contact_person_phone: res[0].Contact_Person_Phone,
            pan_no: res[0][0].PANNo,
            tinno: res[0][0].TIN_No,
            cst: res[0][0].CST,
            st_vat: res[0][0].VAT_No,
            lbt: res[0][0].LBT,
            active: res[0][0].Active,
            DeliveryGS_tinno: res[0][0].GST_No,
            customer_code: res[0][0].Cust_Code,
            Delivery_State: res[0].DeliveryState,
          },()=>{
            this.customer_region();
            this.lead_region();
            this.lead_district("State", res);
            this.Get_Tahsil();
            
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_region = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: this.state.stateid,
      type: "Region",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res[0]);
          this.setState({
            customerRegion: res,
            Region: res[0].ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_district = (key) => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: this.state.stateid,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65787sjjsj8", res);
          if (key == "State") {
            this.setState({
              customerdistrict: res,
            });
          } else {
            this.setState({
              Delivery_Distric_Option: res,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  get_District = (key, dummy = []) => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: key.val,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65787sjjsj8", res);
          if (key.key == "State") {
            this.setState({
              customerdistrict: res,
              District: dummy&&dummy[0]?.district,
            });
          } else {
            this.setState({
              Delivery_Distric_Option: res,
              Delivery_District: dummy&&dummy[0]?.deliverydistrict,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };


  ChangeVal_1(event, key){
    const { value } = event.target;
    if (key === "pincode") {
      
      const pincode = value.replace(/\D/g, '').slice(0, 6);
      this.setState({ pincode: pincode });
    }else if(key==="Deliverypincode"){
      
      const pincode_deli = value.replace(/\D/g, '').slice(0, 6);
      this.setState({ Deliverypincode: pincode_deli });
    }
  }
  ChangeVal(event, key) {
   


    console.log("typeidid", key, event.target.value);
    if (key === "type") {
      if(event.target.value!=4 ){
        this.setState({
          type: "",
          name: "",
          address1: "",
          address2: "",
          address3: "",
          city: "",
          pincode: "",
          State: "",
           stateid: "",
         Region: "",
          Country: "",
          // Mobileno: res[0].Mobile,
          Phoneno: "",
          email: "",
          contact_person: "",
          contact_person_phone: "",
          pan_no: "",
          tinno: "",
          cst: "",
          st_vat: "",
          lbt: "",
          active: "",
          GS_tinno: "",
          customer_code: "",
          District:"",
          Tahsil:"",
          typeid:0
        })
      } else{
        this.setState({
          typeid: event.target.value,
                });
       }
       
       if(event.target.value!=6 ){
        this.setState({
          type: "",
          name: "",
          address1: "",
          address2: "",
          address3: "",
          city: "",
          pincode: "",
          State: "",
           stateid: "",
         Region: "",
          Country: "",
          // Mobileno: res[0].Mobile,
          Phoneno: "",
          email: "",
          contact_person: "",
          contact_person_phone: "",
          pan_no: "",
          tinno: "",
          cst: "",
          st_vat: "",
          lbt: "",
          active: "",
          GS_tinno: "",
          customer_code: "",
          District:"",
          Tahsil:"",
          typeid:0

        })
      } else{
        this.setState({
          typeid: event.target.value,
                });
       }
        
        
       
      
    }
    this.setState({
      // [key]:event.target.options[event.target.selectedIndex]? event.target.options[event.target.selectedIndex].value:0,
      [key]:event.target.value,

    });
    if (key === "name") {
      if(event.target.value=== ""){
        this.setState({
          type: "",
          name: "",
          address1: "",
          address2: "",
          address3: "",
          city: "",
          pincode: "",
          State: 0,
           stateid: 0,
         Region: "",
          Country: "",
          // Mobileno: res[0].Mobile,
          Phoneno: "",
          email: "",
          contact_person: "",
          contact_person_phone: "",
          pan_no: "",
          tinno: "",
          cst: "",
          st_vat: "",
          lbt: "",
          active: "",
          GS_tinno: "",
          customer_code: "",
          District:0,
          Tahsil:""
        })
      } else{
        let Customername=''
           Customername = this.state.customer_List.filter((e) => {
            console.log("ID6767",e,event.target.value);
            return e.ID=== parseInt(event.target.value);
          })[0]?.Name;
          if(Customername!=undefined|| Customername!=null){
            this.setState({
              iid: event.target.value,
              name: event.target.value,
              newcustomer:Customername,
              dealar_id:event.target.value
            },
            () => {
             
    
              this.customerdatabind(this.state.dealar_id)}
              );
          }else{
            this.setState({
              iid: 0,
              name: event.target.value,
              dealar_id:Customername
            },
            () => {
            
    
              // this.customerdatabind(event.target.value)
    
              // console.log("fgfgfg",event.target.value);
              // let Customername=''
              // Customername = this.state.customer_List.filter((e) => {
              //   console.log("ID",e,event.target.value);
              //   return e.Name=== event.target.value;
              // })[0]?.ID;
              // this.setState({
              //   // name:event.target.value,
              //   Customername:Customername
    
              // },()=>{
              //   alert(event.target.value)
              //   // console.log("gdhfdsf",Customername);
    
              // })
    
              
              // this.binddata(key);
            }
          );
          }
        
      }
      
    }

    if (key === "State" || key === "Delivery_State") {
      this.setState(
        {
          stateid: event.target.value,
          Tahsil:"0"
        },
        () => {
          this.binddata(key);
        }
      );
    }
 
    if (key === "Region") {
      for (let index = 0; index < this.state.customerRegion.length; index++) {
        if (this.state.customerRegion[index].Name === event.target.value) {
          this.setState(
            {
              stateid: this.state.customerRegion[index].StateId,
            },
            () => {}
          );
        }
      }
    }
    console.log("key1", key, event.target.value);

    if (key === "District" || key === "Delivery_District") {
      this.setState(
        {
          district_name: event.target.value,
        },
        () => {
          this.customer_Tahsil(key);
        }
      );
    }
    if(event.target.value==4 && key === "type")
    {
      this.setState({  
          typeid:event.target.value
      })
      var data = JSON.stringify({
        "ID": "4",
        "Type": "'C'",
        "DealerID": sessionStorage.getItem("dealerid")
      });
       this.get_customer_details(data)
    }
    else if(event.target.value==6 && key === "type")
    {this.setState({  
      typeid:event.target.value
  })
      var data = JSON.stringify({
        "ID": "6",
        "Type": "'C'",
        "DealerID": sessionStorage.getItem("dealerid")
        
      });
      this.get_customer_details(data)
    }

    
  }
  get_customer_details_43= () => {
    const url = Urls.python_baseurl + "/SP_GetDealer_CustomerSupplier";
    if(this.state.typeid===4){
      var data = JSON.stringify({
        "ID": "4",
        "Type": "'C'",
        "DealerID": sessionStorage.getItem("dealerid")
      });
    }else{
      var data = JSON.stringify({
        "ID": "6",
        "Type": "'C'",
        "DealerID": sessionStorage.getItem("dealerid")
      });
    }
   
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          
            this.setState({
              customer_List: res,
            },()=>{
              this.setState({
                name:this.state.customername12
              },()=>{
              })
            });
         
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  }
 
  get_customer_details= (data) => {
    this.setState({customer_List:[]})
    const url = Urls.python_baseurl + "/SP_GetDealer_CustomerSupplier";
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          
            this.setState({
              customer_List: res,
            });
         
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  }
  header = () => {
    
    return (
      <div className="px-3">
        <div className=" row">
          <div class=" col-3">
            <label>Type {this.required()}</label>
            <select
            disabled={this.state.updateflag?true:false}
              className="form-control "
              value={this.state.type}
              onChange={(event) => this.ChangeVal(event, "type")}
            >
              <option value="0">Select</option>
              {this.customerType()}
            </select>
          </div>
          {/* {alert(this.state.type)} */}
          { (this.state.typeid==4 || this.state.typeid==6 )?
          <div class=" col-3">
            <label>Name {this.required()}</label>
            {/* <input
              autoComplete="off"
              className="form-control"
              list="name"
              value={this.state.name}
              name="name"
              onChange={(event) => this.ChangeVal(event, "name")}
              />

            <datalist id="name" style={{ maxHeight: "20em" }}>
            {this.state.customer_List.map((e) => {
              console.log("ghdgfd",e);
                  return <option value={e.Name}>{e.Name}</option>;
                })}
            </datalist> */}


            <select
            disabled={this.state.updateflag?true:false}
              className="form-control "
              value={this.state.name}
              onChange={(event) => this.ChangeVal(event, "name")}
            >
              <option value="0">Select</option>
              {this.customer_List()}
            </select>
          </div>:
          <div className="col-3">
            <label htmlFor="model-c">Name {this.required()}</label>
            <input 
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.setState({ name: e.target.value })}
              value={this.state.name}
              id="model-c"
              className=" form-control"
              onKeyPress={(e) => {
                const pattern = /[A-Za-z\s]/ ; // Regular expression pattern for alphabetic characters
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault(); // Prevent input if it's not alphabetic
                }
              }}
            ></input>
          </div>}
          <div className=" col-3">
            <label htmlFor="model-c">Address 1 {this.required()}</label>
            <input 
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.setState({ address1: e.target.value })}
              value={this.state.address1}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="model-c">Address 2</label>
            <input 
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.setState({ address2: e.target.value })}
              value={this.state.address2}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
         
          <div class=" col-3">
            <label>State {this.required()}</label>
            <select
            disabled={this.state.updateflag?true:false}
              className="form-control "
              value={this.state.State}
              onChange={(event) => this.ChangeVal(event, "State")}
            >
              <option value="0">Select</option>
              {this.customerstate()}
            </select>
          </div>
          <div class=" col-3">
            <label>District</label>
            <select
            disabled={this.state.updateflag?true:false}
              className="form-control "
              value={this.state.District}
              onChange={(event) => this.ChangeVal(event, "District")}
            >
              <option value="0">Select</option>
              {this.customerdistrict()}
            </select>
          </div>
          <div class=" col-3">
            <label>Tehsil</label>
            <select
            disabled={this.state.updateflag?true:false}
              className="form-control "
              value={this.state.Tahsil}
              onChange={(event) => this.ChangeVal(event, "Tahsil")}
            >
              <option value="0">Select</option>
              {this.customerTahsil()}
            </select>
          </div>
          <div className=" col-3">
            <label htmlFor="model-c">City</label>
            <input 
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.setState({ city: e.target.value })}
              value={this.state.city}
              id="model-c"
              className=" form-control"
              onKeyPress={(e) => {
                const pattern = /[A-Za-z\s]/ // Regular expression pattern for alphabetic characters
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault(); // Prevent input if it's not alphabetic
                }
              }}
            ></input>
          </div>
        </div>
        <div className="row mt-2">
          <div className=" col-3">
            <label htmlFor="model-c">Pincode</label>
            <input
            type="number"
            disabled={this.state.updateflag?true:false}
                // onChange={(e) => this.setState({ pincode: e.target.value })}
              value={this.state.pincode}
                onChange={(event) => this.ChangeVal_1(event, "pincode")}

              // id="model-c"
              className=" form-control"
              max={6}
            ></input>
          </div>
          <div class=" col-3">
            <label>Region</label>
            <select
              disabled
              className="form-control "
              value={this.state.Region}
              onChange={(event) => this.ChangeVal(event, "Region")}
            >
              <option value="0"></option>
              {this.customerregion()}
            </select>
          </div>
          <div className=" col-3">
            <label htmlFor="model-c">Country</label>
            <input
              disabled={true}
              onChange={(e) => this.setState({ Country: e.target.value })}
              value={"INDIA"}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="model-c">Mobile No.{this.required()}</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.onChangeMobile_Number(e)}
              value={this.state.Phoneno}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
        </div>
        <div className="row mt-2">
          <div className=" col-3">
            <label htmlFor="model-c">Email {this.required()}</label>
            <input
            disabled={this.state.updateflag?true:false}
              autoComplete='off'
              onChange={(e) => this.setState({ email: e.target.value })}
              value={this.state.email}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="model-c">Contact Person</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) =>
                this.setState({ contact_person: e.target.value })
              }
              value={this.state.contact_person}
              id="model-c"
              className=" form-control"
              onKeyPress={(e) => {
                const pattern = /[A-Za-z\s]/; // Regular expression pattern for alphabetic characters
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault(); // Prevent input if it's not alphabetic
                }
              }}
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="model-c">Contact Person Phone</label>
            <input 
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.oncontact_person_phone(e)}
              value={this.state.contact_person_phone}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          {/* <div className=" col-3">
            <label htmlFor="model-c">Birthdate</label>
            <input
              onChange={(e) => this.setState({ Birthdate: e.target.value })}
              value={this.state.Birthdate}
              id="model-c"
              className=" form-control"
              type="date"
            ></input>
          </div> */}
          <div className=" col-3">
            <label htmlFor="model-c">PAN No</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
            style={{textTransform:"uppercase"}}
              onChange={(e) => this.setState({ pan_no: e.target.value })}
              value={this.state.pan_no}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
        </div>
        <div className="row mt-2">
          {/* <div className=" col-3">
            <label htmlFor="model-c">Anniversary Date</label>
            <input
              onChange={(e) =>
                this.setState({ AnniversaryDate: e.target.value })
              }
              value={this.state.AnniversaryDate}
              id="model-c"
              type="date"
              className=" form-control"
            ></input>
          </div> */}

          <div className=" col-3">
            <label htmlFor="active">Active {this.required()}</label>
            <select
              className="form-control list-dashboard"
              id="Active"
              value={this.state.active}
              onChange={(event) => {
                this.onSelect(event, "active");
              }}
              disabled
            >
              {/* <option selected value="0">Select</option> */}
              <option selected value="Y">Y</option>
              <option value="N">N</option>
            </select>
          </div>
        </div>
      </div>
    );
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  onChangeMobileNumber = (e) => {
    const { value } = e.target;
    // Regular expression to allow only numbers
    const mobileNumber = value.replace(/\D/g, '').slice(0, 10); // Limit to 10 characters
    this.setState({ Delivery_Mobile: mobileNumber });
  };
  onChangeMobile_Number = (e) => {
    const { value } = e.target;
    // Regular expression to allow only numbers
    const mobileNumber = value.replace(/\D/g, '').slice(0, 10); // Limit to 10 characters
    this.setState({ Phoneno: mobileNumber });
  };
  oncontact_person_phone = (e) => {
    const { value } = e.target;
    // Regular expression to allow only numbers
    const mobileNumber = value.replace(/\D/g, '').slice(0, 10); // Limit to 10 characters
    this.setState({ contact_person_phone: mobileNumber });
  };
  onDeliverycontact_person_phone = (e) => {
    const { value } = e.target;
    // Regular expression to allow only numbers
    const mobileNumber = value.replace(/\D/g, '').slice(0, 10); // Limit to 10 characters
    this.setState({ Deliverycontact_person_phone: mobileNumber });
  };
  delivery_details = () => {
    return (
      <div className="px-3">
        <div className=" row">
          <div className=" col-3">
            <label >Delivery Name</label>
            <input
             disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.setState({ Delivery_Name: e.target.value })}
              value={this.state.Delivery_Name}
              id="model-c"
              className=" form-control"
              onKeyPress={(e) => {
                const pattern = /[A-Za-z\s]/; // Regular expression pattern for alphabetic characters
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault(); // Prevent input if it's not alphabetic
                }
              }}
            ></input>
          </div>
          <div className=" col-3">
            <label >Delivery Mobile</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.onChangeMobileNumber(e)}
              value={this.state.Delivery_Mobile}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3">
            <label >Contact Person</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) =>
                this.setState({ Deliverycontact_person: e.target.value })
              }
              value={this.state.Deliverycontact_person}
              id="model-c"
              className=" form-control"
              onKeyPress={(e) => {
                const pattern = /[A-Za-z\s]/; // Regular expression pattern for alphabetic characters
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault(); // Prevent input if it's not alphabetic
                }
              }}
            ></input>
          </div>
          <div className=" col-3">
            <label >Contact Person Phone</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.onDeliverycontact_person_phone(e)}
              value={this.state.Deliverycontact_person_phone}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
        </div>
        <div className="row mt-2">
          <div className=" col-3">
            <label >Address 1</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) =>
                this.setState({ Deliveryaddress1: e.target.value })
              }
              value={this.state.Deliveryaddress1}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3">
            <label >Address 2</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) =>
                this.setState({ Deliveryaddress2: e.target.value })
              }
              value={this.state.Deliveryaddress2}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          
          <div class=" col-3">
            <label>State</label>
            <select
            disabled={this.state.updateflag?true:false}
              className="form-control "
              value={this.state.Delivery_State}
              onChange={(event) => this.ChangeVal(event, "Delivery_State")}
            >
              <option value="0">Select</option>
              {this.customerstate()}
            </select>
          </div>
          <div class=" col-3">
            <label>District</label>
            <select
            disabled={this.state.updateflag?true:false}
              className="form-control "
              value={this.state.Delivery_District}
              onChange={(event) => this.ChangeVal(event, "Delivery_District")}
            >
              <option value="0">Select</option>
              {this.state.Delivery_Distric_Option.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })}
            </select>
          </div>
        </div>
        
        <div className=" row mt-2">
          
          <div class=" col-3">
            <label>Tehsil</label>
            <select
            disabled={this.state.updateflag?true:false}
              className="form-control "
              value={this.state.Delivery_Taluka}
              onChange={(event) => this.ChangeVal(event, "Delivery_Taluka")}
            >
              <option value="0">Select</option>
              {this.state.deliver_Taluka_Option.map((e) => {
                return <option value={e.ID}>{e.Town_Name}</option>;
              })}
            </select>
          </div>
          <div className=" col-3">
            <label >City</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.setState({ Deliverycity: e.target.value })}
              value={this.state.Deliverycity}
              id="model-c"
              className=" form-control"
              onKeyPress={(e) => {
                const pattern = /[A-Za-z\s]/ // Regular expression pattern for alphabetic characters
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault(); // Prevent input if it's not alphabetic
                }
              }}
            ></input>
          </div>
          <div className=" col-3">
            <label >Pincode</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
            type="number"
             onChange={(event) => this.ChangeVal_1(event, "Deliverypincode")}

              // onChange={(e) =>
              //   this.setState({ Deliverypincode: e.target.value })
              // }
              value={this.state.Deliverypincode}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3">
            <label >Email</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
              onChange={(e) => this.setState({ Deliveryemail: e.target.value })}
              value={this.state.Deliveryemail}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
        </div>
        <div className="row mt-2">
          <div className=" col-3">
            <label >PAN No</label>
            <input
            disabled={this.state.updateflag?true:false}
            autoComplete='off'
            style={{textTransform:"uppercase"}}
              onChange={(e) =>
                this.setState({ Deliverypan_no: e.target.value })
              }
              value={this.state.Deliverypan_no}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          <div className=" col-3">
            <label >GSTN No</label>
            <input
            disabled={this.state.updateflag?true:false}
            style={{textTransform:"uppercase"}}
            autoComplete='off'
              onChange={(e) =>
                this.setState({ DeliveryGS_tinno: e.target.value })
              }
              value={this.state.DeliveryGS_tinno}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
        </div>
      </div>
    );
  };
  customer_discounts = () => {
    return (
      <div className="px-3">
        <div className=" row">
          <div className=" col-4">
            <label htmlFor="model-c">Parts</label>
            <input
              onChange={(e) => this.setState({ Parts: e.target.value })}
              value={this.state.Parts}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          <div className=" col-4">
            <label htmlFor="model-c">Lubricant</label>
            <input
              onChange={(e) => this.setState({ Lubricant: e.target.value })}
              value={this.state.Lubricant}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
          <div className=" col-4">
            <label htmlFor="model-c">Labour</label>
            <input
              onChange={(e) => this.setState({ Labour: e.target.value })}
              value={this.state.Labour}
              id="model-c"
              className=" form-control"
            ></input>
          </div>
        </div>
      </div>
    );
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Customer Code",
        field: "Customer Code",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Customer Name", field: "Customer Name", flex: "1" },
      { headerName: "Type", field: "CustType_name", flex: "1" },
      { headerName: "Address 1", field: "Address1", flex: "1" },
      { headerName: "Pincode", field: "Pincode", flex: "1" },
      { headerName: "Mobile", field: "Mobile", flex: "1" },
      // {
      //   headerName: "", field: "pencil", width: 50,flex:1, cellRenderer: (data) => {
      //     console.log("data",data);
      //     // if(data.data){
      //           return <i class="fas fa-pencil-alt"></i>
      //     // }

      //   }, cellStyle: { cursor: 'pointer' }, pinned: 'right'
      // },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              console.log("event12", event.data);
              if (event.colDef.field == "Customer Code") {
                this.setState(
                  {
                    id: event.data.ID,
                  },
                  () => {
                    this.customer_display();
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  customer_display = () => {
    const url = Urls.python_baseurl + "/Onclick_Customer_SP_GetCustomer";
    var data = JSON.stringify({
      ID: this.state.id,
      DealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res6576", res);
          if (res[0].state_id !== 0) {
            let result = [
              {
                val: res[0].state_id,
                key: "State",
                tahsilkey: "District",
                districtid: res[0].district,
              },
              {
                val: res[0].DeliveryState,
                key: "Delivery_State",
                tahsilkey: "Delivery_District",
                districtid: res[0].deliverydistrict,
              },
            ];
            console.log(result,"sjbdbhs")
            for (let i = 0; i < result.length; i++) {
              this.setState(
                {
                  stateid: result[i].val,
                  district_name: result[i].districtid,
                },
                () => {
                  // this.get_District(result[i], res);
                  // this.Get_Tahsil(result[i], res);
                  this.setState(
                    {
                      iid:res[0]?.ID,
                      type: res[0].Cust_Type,
                      updateflag:res[0].Cust_Type==4 || res[0].Cust_Type==6?true:false,
                      // typeid:res[0].Cust_Type,
                      name: res[0].Customer_name,
                      address1: res[0].add1,
                      address2: res[0].add2,
                      address3: res[0].add3,
                      city: res[0].city,
                      pincode: res[0].pincode,
                      State: res[0].state_id,
                      stateid: res[0].state_id,
                      Region: res[0].Region_id,
                      Country: "INDIA",
                      // Mobileno: res[0].Mobile,
                      Phoneno: res[0].Mobile,
                      email: res[0].E_mail,
                      contact_person: res[0].Contact_Person,
                      contact_person_phone: res[0].Contact_Person_Phone,
                      pan_no: res[0].PANNo,
                      tinno: res[0].TINNo,
                      cst: res[0].CST,
                      st_vat: res[0].VAT,
                      lbt: res[0].LBT,
                      active: res[0].Active,
                      GS_tinno: res[0].GST_No,
                      customer_code: res[0].Cust_Code,
                      Delivery_Name: res[0].Delv_name,
                      Delivery_Mobile: res[0].Delv_Mobile,
                      Deliverycontact_person: res[0].Delv_Contact_Person,
                      Deliverycontact_person_phone:
                        res[0].Delv_Contact_phone_no,
                      Deliveryaddress1: res[0].DeliveryAdd1,
                      Deliveryaddress2: res[0].DeliveryAdd2,
                      // Deliveryaddress3: res[0].DeliveryAdd3,
                      Deliverycity: res[0].DeliveryCity,
                      Deliverypincode: res[0].Delv_pincode,
                      Deliveryemail: res[0].Delv_E_mail,
                      Deliverypan_no: res[0].Delv_PANNo,
                      DeliveryGS_tinno: res[0].Delv_GST_No,
                      // Delivery_Taluka: res[0].deliverytehsil,
                      // Delivery_District: res[0].deliverydistrict,
                      Delivery_State: res[0].DeliveryState,
                       Tahsil: parseInt(res[0].tehsil),
                       District: res[0].district!=null?res[0].district:res[0].District_ID,
                       
                    },
                    () => {
                      this.customer_type();
                      console.log("Region", this.state.name);
                      this.customer_region();
                      this.Get_Tahsil();
                      // this.customer_region();
                      this.lead_region();
                      this.lead_district("State", res);
                      // this.Get_Tahsil();
                      // this.get_customer_details_43()
                    }
                  );
                }
              );
            }
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  addnew = () => {
    this.setState({
      iid:0,
      type: "",
      name: "",
      address1: "",
      address2: "",
      address3: "",
      city: "",
      pincode: "",
      State: "",
      Region: "",
      Mobileno: "",
      Phoneno: "",
      email: "",
      contact_person: "",
      contact_person_phone: "",
      pan_no: "",
      tinno: "",
      cst: "",
      st_vat: "",
      lbt: "",
      active: "",
      GS_tinno: "",
      customer_code: "",
      Delivery_Name: "",
      Delivery_Mobile: "",
      Deliverycontact_person: "",
      Deliverycontact_person_phone: "",
      Deliveryaddress1: "",
      Deliveryaddress2: "",
      Deliveryaddress3: "",
      Deliverycity: "",
      Deliverypincode: "",
      Deliveryemail: "",
      Deliverypan_no: "",
      DeliveryGS_tinno: "",
      Parts: "",
      Lubricant: "",
      Labour: "",
      Tahsil: "",
      District:"",
      Delivery_State:"",
      Delivery_District:"",
      Delivery_Taluka:"",
      updateflag:false
    });
  };
  savecustomer = (event) => {
    this.setState({
      double_save:true,
    })
        const url = Urls.python_baseurl + "/sp_Customer_save";
    var data = JSON.stringify({
      iID: `${this.state.iid?this.state.iid:0}`,
      CustType: this.state.typeid?this.state.typeid:this.state.type,
      Name: this.state.newcustomer?`'${this.state.newcustomer}'`:`'${this.state.name}'`,
      Address1: `'${this.state.address1?this.state.address1:""}'`,
      Address2: `'${this.state.address2?this.state.address2:""}'`,
      Address3: `''`,
      City: `'${this.state.city?this.state.city:""}'`,
      pincode: `'${this.state.pincode?this.state.pincode:""}'`,
      Region: this.state.Region?`${this.state.Region}`:"0",
      State: this.state.State?`${this.state.State}`:"0",
      Country: `${this.state.Countryid}`,
      Mobile: this.state.Phoneno?`'${this.state.Phoneno}'`:"''",
      Phone: `''`,
      Email: this.state.email?`'${this.state.email}'`:"''",
      ContactPerson: this.state.contact_person?`'${this.state.contact_person}'`:"''",
      ContactPersonPhone: this.state.contact_person_phone?`'${this.state.contact_person_phone}'`:"''",
      PANNo: this.state.pan_no?`'${this.state.pan_no}'`:"''",
      TINNo: `''`,
      CSTNO: `''`,
      STVATNO: `''`,
      LBT: `''`,
      // DealerActive: this.state.active?`'${this.state.active}'`:"''",
      DealerActive:"'Y'",
      CustType_Dealer_ID: this.state.dealar_id?this.state.dealar_id:sessionStorage.getItem("dealerid"),
      Deliveryadd1: this.state.Deliveryaddress1?`'${this.state.Deliveryaddress1}'`:"''",
      Deliveryadd2: this.state.Deliveryaddress2?`'${this.state.Deliveryaddress2}'`:"''",
      Deliveryadd3: `''`,
      Deliverycity: this.state.Deliverycity?`'${this.state.Deliverycity}'`:"''",
      deliverystate: this.state.Delivery_State?`${this.state.Delivery_State}`:"0",
      GstNo: this.state.DeliveryGS_tinno?`'${this.state.DeliveryGS_tinno}'`:"''",
      Delv_Contact_Person:this.state.Deliverycontact_person? `'${this.state.Deliverycontact_person}'`:"''",
      Delv_Contact_phone_no: this.state.Deliverycontact_person_phone?`'${this.state.Deliverycontact_person_phone}'`:"''",
      Delv_pincode:this.state.Deliverypincode? `'${this.state.Deliverypincode}'`:"''",
      Delv_E_mail:this.state.Deliveryemail? `'${this.state.Deliveryemail}'`:"''",
      Delv_PANNo: this.state.Deliverypan_no?`'${this.state.Deliverypan_no}'`:"''",
      Delv_GST_No: this.state.DeliveryGS_tinno?`'${this.state.DeliveryGS_tinno}'`:"''",
      DlvName: this.state.Delivery_Name?`'${this.state.Delivery_Name}'`:"''",
      DlvMobile:this.state.Delivery_Mobile? `'${this.state.Delivery_Mobile}'`:"''",
      deliverydistrict:this.state.Delivery_District? `'${this.state.Delivery_District}'`:"''",
      deliverytehsil: this.state.Delivery_Taluka?`'${this.state.Delivery_Taluka}'`:"''",
      district: this.state.District?`'${this.state.District}'`:"''",
      tehsil: this.state.Tahsil?`'${this.state.Tahsil}'`:"''",


      
    });
    console.log("gfdgfd",data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res[0]?.newID) {
          this.handelSaveAgain(res[0]?.newID);
        }
         if(res[0].ID){
           this.handelSaveAgain(res[0]?.ID);
          //  alert("Record Updated Succesfully");
        }
        // window.location.reload()
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Validate = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emailisvalid=emailRegex.test(this.state.email)
    let Deliveryemail=emailRegex.test(this.state.Deliveryemail)
    if(this.state.Deliveryemail &&!Deliveryemail&&this.state.Deliveryemail!=""){
      alert("Please Enter Delivary Valid Email Id.")
      return
    }
    else
    if(this.state.type==="0"){
      alert("Please Enter Valid Type ")
      return
     }else if(this.state.name===""){
      alert("Please Enter Valid Name")
      return
     }else if(this.state.address1===""){
      alert("Please Enter Address 1")
      return
     }
     else if(this.state.State==="0" || this.state.State==="" || this.state.State===undefined){
      alert("Please Enter Valid State ")
      return
     } else if(this.state.Phoneno==""){
      alert("Please Enter Mobile No.")
      return
     }
     else if(this.state.Phoneno.match(/\d/g).length!==10 ){
      alert("Please Enter Valid Mobile No.")
      return
    }else if(!emailisvalid){
      alert("Please Enter Valid Email.")
      return
    }
    // else if(this.state.active==="0" || this.state.active==="" || this.state.active===undefined){
    //   alert("Please Select Active ")
    //   return
    //  }
     else 
    if(!emailisvalid&&this.state.email!=""){
      alert("Please Enter Valid Email Id.")
      return
    } 
    else
   if(this.state.pincode!=""){
    if(this.state.pincode.toString().length!=6){
    alert("Please Enter Valid Pincode")
    return
    }
   }else if(this.state.Phoneno.toString().length!=10){
    alert("Please Enter Valid Mobile No")
    return
   }else if(this.state.Deliverypincode!=""){
    if(this.state.Deliverypincode.toString().length!=6){
    alert("Please Enter Valid Delivery Pincode")
    return
    }
   } 
   {
    this.savecustomer();
   }
    
  
   
    // let isValid = 
    //   this.state.type !== "" &&
    //   this.state.name !== "" &&
    //   this.state.State !== "" &&
    //   this.state.Phoneno !== "" &&
    //   this.state.active !== ""
    // if (isValid) {
    //  this.savecustomer();
    // } else {
    //   alert("Please fill all mandatory field.");
    // }
  
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Customer</h4>
        <div class="row justify-content-end ">
          <button
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={this.addnew}
          >
            Clear
          </button>
          <button
            disabled={this.state.updateflag?true:this.state.double_save}
            type="submit"
            className="btn Opertion_btn clickable mr-5"
            onClick={this.Validate}
          >
            Save
          </button>
        </div>
        <div class=" col-md-12 mt-2">
          <div class="card">
            <div class="card-header" id="headingFour">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  Customer List
                </button>
              </h2>
            </div>

            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionExample"
            >
              <div class="card-body">{this.masterTable()}</div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Customer Details
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">{this.header()}</div>
            </div>
          </div><br></br>
          {/* <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Delivery Details
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div class="card-body">{this.delivery_details()}</div>
            </div>
          </div> */}
          {/* <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  Customer Discount Category Details
                </button>
              </h2>
            </div>

            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div class="card-body">{this.customer_discounts()}</div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
