import React from "react";
import "../../Files/files-styles.css";
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import Strings from "../../../../helpers/Strings";
import moment from "moment";
import { Booking_M3 } from "../Booking_M3";
import { Quotation_Invoice } from "../../Report/Quotation_Invoice";
import { Gate_Pass } from "../../Report/Gate_Pass";
const $ = window.$;
export class Vehical_billing extends React.Component {
  constructor(props) {
    console.log(props, "jslkdk");
    super(props);
    this.state = {
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      Customer_Name: "",
      Doc_No: props?.pendingClick?.Doc_No,
      M8_ID: props?.pendingClick?.ID,
      M7_ID: props?.pendingClick?.AllocationID,
      issaveClicked: props?.modelid,
      M8_No: "",
      ML8_No: "",
      Model_Category_Option: [],
      Model_Code_Option: [],
      Model_Option: [],
      FinancerOption: [],
      Chassis_No: "",
      Branch: "",
      Do_amount: "",
      Do_Date: "",
      Doc_No: "",
      Quantity: 1,
      Trailer_Amount: "",
      Trailer_chassis: "",
      Interest_Rate: "",
      Tenure: "",
      Margin_Money: "",
      Loan_amount: "",
      ML8_No: "",
      Consignee_name: "",
      Consignee_address: "",
      Consignee_GSTIN: "",
      Consignee_TIN: "",
      Do_No: "",
      AllocatedTo: [],
      Allocated_By: [],
      GSTIN: "",
      iscancelloader: false,
      issaveloader: false,
      Status: props?.Status,
      Model_Code: [],
      Model_Group: [],
      Financier_customer: [],
      ConditionCode: [],
      SchemeOption: [],
      insuranceList: [],
      AccessoriesOption: [],
      Dealer_vehicle_Code: "",
      fin_year: sessionStorage.getItem("fin_year"),
      Dealer_Code: sessionStorage.getItem("Dealer_Code"),
      // M7_Hdr:props?.pendingClick?.ID
    };
  }
  componentDidMount() {
    this.state.issaveClicked != "saveClcik" && this.M8_No();
    this.getM8Details();
  }
  GetModelDetails = () => {
    const url = Urls.python_baseurl + "/model_master_SP_ModelMaster_details";
    var data = JSON.stringify({
      model_id: `'${this.state.Model_Id}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          this.setState({
            Model_Category_value: res[0]?.Model_cat_ID,
            Model_Code_value: this.state.Model_Code_Option.filter(
              (e) => e.Name == res[0]?.Model_code
            )[0]?.ID,
            Model: this.state.Model_Option.filter(
              (e) => e.Name == res[0]?.model_name
            )[0]?.ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModel = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 197,
      ID: 0,
      condition: "'and Model_cat_ID=1002'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "D",
      DealerId: 126,
      SqlFor: "'M2'",
      HOBrID: 126,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  saveM8_No = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "I",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              ML8_No:
                this.state.Dealer_Code +
                "" +
                "M8" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              this.gatePassNo();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  M8_No = () => {
    this.setState({ leadno: "" });

    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "I",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // M8_No: res[0].DocMaxNo,
              ML8_No:
                this.state.Dealer_Code +
                "" +
                "M8" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              // this.financiaryear();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  gatePassNo = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "GP",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // M8_No: res[0].DocMaxNo,
              Gatepass_No:
                this.state.Dealer_Code +
                "" +
                "GP" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              this.handelSave("N");
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  // financiaryear = () => {
  //   const url = Urls.python_baseurl + "/SP_GetDataFromDataQuery";
  //   var data = JSON.stringify({
  //     ValGetDataQueryType: "15",
  //     iParentId: `${sessionStorage.getItem("dealerid")}`,
  //     sAdditionalCondition: "''",
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState(
  //           {
  //             finacial_year: res[0].Value,
  //           },
  //           () => {
  //             this.delarcode();
  //           }
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  // delarcode = () => {
  //   const url = Urls.python_baseurl + "/Get_Dealer_SP_GET_Dealercode";
  //   var data = JSON.stringify({
  //     DealerID: sessionStorage.getItem("dealerid"),
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState({
  //           Dealer_vehicle_Code:res[0].Dealer_vehicle_Code,
  //           ML8_No:
  //             res[0].Dealer_vehicle_Code +
  //             "" +
  //             "M8" +
  //             "" +
  //             this.state.finacial_year +
  //             "" +
  //             this.state.M8_No.toString().padStart(6, '0'),
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  handelSave = (iscancel) => {
    this.setState({
      isDouble: true,
    });
    const url = Urls.python_baseurl + "/save_VehicleBilling_SP_Save_M8Header";
    var data = JSON.stringify({
      ID: `${this.state.issaveClicked == "saveClcik" ? this.state.M8_ID : 0}`,
      UserId: `${sessionStorage.getItem("dealerid")}`,
      HOBrID: `${sessionStorage.getItem("dealerid")}`,
      M8_No: `'${this.state.ML8_No}'`,
      M8_Date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      M7_Hdr: `'${this.state.M7_Hdr}'`,
      IS_Confirm: `'N'`,
      Is_Cancel: `'${iscancel}'`,
      ChangeFinc: `${this.state.Financer_ID ? this.state.Financer_ID : 0}`,
      Total: `${this.state.VehiclePrice ? this.state.VehiclePrice : 0}`,
      Rate: `${this.state.AMV_Sale_Rate}`,
      Grandtotal: `${
        this.state.GrandTotal_Amount ? this.state.GrandTotal_Amount : 0
      }`,
      TCSPer: `${this.state.TCS ? this.state.TCS : 0}`,
      TCSAmt: `${this.state.TCS_Amount ? this.state.TCS_Amount : 0}`,
      AllotId: `${
        this.state.issaveClicked == "saveClcik"
          ? this.state.AllotId
          : this.state.M7_ID
      }`,
      Disc: `${
        this.state.AMV_Dealer_Proposed_Amount
          ? this.state.AMV_Dealer_Proposed_Amount
          : 0
      }`,
      Qty: "1",
      TCS_App: "0",
      Chassis_No: `${this.state.Chassis_No}`,
      GSTNo: `'${this.state.GSTIN ? this.state.GSTIN : 0}'`,
      CGST_Per: `${this.state.CGST ? this.state.CGST : 0}`,
      IGST_Per: `${this.state.IGST ? this.state.IGST : 0}`,
      SGST_Per: `${this.state.SGST ? this.state.SGST : 0}`,
      CGST_Amt: `${this.state.CGST_Amount ? this.state.CGST_Amount : 0}`,
      IGST_Amt: `${this.state.IGST_Amount ? this.state.IGST_Amount : 0}`,
      SGST_Amt: `${this.state.SGST_Amount ? this.state.SGST_Amount : 0}`,
      Gatepass_No: `'${this.state.Gatepass_No}'`,
      Gatepass_Date: `'${
        this.state.issaveClicked == "saveClcik"
          ? this.state.Gatepass_Date
          : moment(new Date()).format("DD-MM-YYYY")
      }'`,
      DlrCode: `'${this.state.Dealer_Code}'`,
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      Taxable_Amt:`${this.state.Taxable_Amt}`
    });

    console.log(data, "data");
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res[0][0].ID) {
          if (this.state.issaveClicked == "saveClcik") {
            this.setState({
              isDouble: false,
            });
            if (iscancel == "Y") {
              alert("Record Cancelled Succesfully");
              window.location.reload();
            } else {
              alert("Record Updated Succesfully");
              window.location.reload();
            }
          } else {
            alert("Record Saved Succesfully");
            window.location.reload();
          }
          sessionStorage.setItem("ActiveTab", "Vehicle Invoice");
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  handelConfirmed = (iscancel) => {
    this.setState({
      isDouble: true,
    });
    const url = Urls.python_baseurl + "/save_VehicleBilling_SP_Save_M8Header";
    var data = JSON.stringify({
      ID: `${this.state.issaveClicked == "saveClcik" ? this.state.M8_ID : 0}`,
      UserId: `${sessionStorage.getItem("dealerid")}`,
      HOBrID: `${sessionStorage.getItem("dealerid")}`,
      M8_No: `'${this.state.ML8_No}'`,
      M8_Date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      M7_Hdr: `'${this.state.M7_Hdr}'`,
      IS_Confirm: `'Y'`,
      Is_Cancel: `'${iscancel}'`,
      ChangeFinc: `${this.state.Financer_ID ? this.state.Financer_ID : 0}`,
      Total: `${this.state.VehiclePrice ? this.state.VehiclePrice : 0}`,
      Rate: `${this.state.AMV_Sale_Rate}`,
      Grandtotal: `${
        this.state.GrandTotal_Amount ? this.state.GrandTotal_Amount : 0
      }`,
      TCSPer: `${this.state.TCS ? this.state.TCS : 0}`,
      TCSAmt: `${this.state.TCS_Amount ? this.state.TCS_Amount : 0}`,
      AllotId: `${
        this.state.issaveClicked == "saveClcik"
          ? this.state.AllotId
          : this.state.M7_ID
      }`,
      Disc: `${
        this.state.AMV_Dealer_Proposed_Amount
          ? this.state.AMV_Dealer_Proposed_Amount
          : 0
      }`,
      Qty: "1",
      TCS_App: "0",
      Chassis_No: `${this.state.Chassis_No}`,
      GSTNo: `'${this.state.GSTIN ? this.state.GSTIN : 0}'`,
      CGST_Per: `${this.state.CGST ? this.state.CGST : 0}`,
      IGST_Per: `${this.state.IGST ? this.state.IGST : 0}`,
      SGST_Per: `${this.state.SGST ? this.state.SGST : 0}`,
      CGST_Amt: `${this.state.CGST_Amount ? this.state.CGST_Amount : 0}`,
      IGST_Amt: `${this.state.IGST_Amount ? this.state.IGST_Amount : 0}`,
      SGST_Amt: `${this.state.SGST_Amount ? this.state.SGST_Amount : 0}`,
      Gatepass_No: `'${this.state.Gatepass_No}'`,
      Gatepass_Date: `'${
        this.state.issaveClicked == "saveClcik"
          ? this.state.Gatepass_Date
          : moment(new Date()).format("DD-MM-YYYY")
      }'`,
      DlrCode: `'${this.state.Dealer_Code}'`,
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      Taxable_Amt:`${this.state.Taxable_Amt}`
    });

    console.log(data, "data");
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res[0][0].ID) {
          if (this.state.issaveClicked == "saveClcik") {
            this.setState({
              isDouble: false,
            });
            alert("Record Confirmed Succesfully");
            window.location.reload();
          }
          sessionStorage.setItem("ActiveTab", "Vehicle Invoice");
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  updateMaxNo = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      DocName: "'M8'",
      DealerId: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            this.setState(
              {
                flag: false,
              },
              () => {
                this.UpdategatePassNo();
                // sessionStorage.setItem("ActiveTab", "Money Received");
                // this.props.history.push("/tiles_tabs");
              }
            );
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  UpdategatePassNo = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      DocName: "'GP'",
      DealerId: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            this.setState(
              {
                flag: false,
                isDouble: false,
              },
              () => {
                alert("Record Saved Succesfully");
                window.location.reload();
                // sessionStorage.setItem("ActiveTab", "Money Received");
                // this.props.history.push("/tiles_tabs");
              }
            );
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "ML8 No",
        field: "Customer Code",
        flex: "1",
        cellStyle: { cursor: "pointer" },
      },
      { headerName: "ML8 Date", field: "Customer Name", flex: "1" },
      { headerName: "Customer Name", field: "Address1", flex: "1" },
      { headerName: "Chassis Name", field: "Address1", flex: "1" },
      { headerName: "Status", field: "Pincode", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              console.log("event12", event.data);
              if (event.colDef.field == "Customer Code") {
                this.setState(
                  {
                    id: event.data.ID,
                  },
                  () => {
                    this.customer_display();
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  GetFinancerOption = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "83",
      ID: "0",
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "podetails");
        if (res && res.length > 0) {
          this.setState({ FinancerOption: res });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCode = (ModelCategory) => {
    const url =
      Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
    var data = JSON.stringify({
      ModelGroup: parseInt(ModelCategory),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Model_Code: res,
            },
            () => {
              let ModelCodedata = "";
              for (
                let index = 0;
                index < this.state.Model_Code.length;
                index++
              ) {
                if (
                  this.state.Model_Code[index].ID ===
                  parseInt(this.state.modelcode1)
                ) {
                  ModelCodedata = this.state.Model_Code[index]["Model code"];
                }
              }
              this.getInsurance(ModelCodedata);
              this.getScheme(ModelCodedata);
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/ModelGroup_GetModelGroup";
    var data = JSON.stringify({
      id: "null",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Group: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  standard_accssories = () => {
    const url = Urls.python_baseurl + "/GetAccessories_SP_GetAccessories";
    var data = JSON.stringify({
      Type: "'Standard'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              AccessoriesOption: res,
            },
            () => {
              {
                // this.state.isSavedClick == "saveClcik" &&
                this.getM8Details();
              }
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getM8Details = () => {
    // alert(this.state.M7ID)
    const url = Urls.python_baseurl + "/VehicleBilling_display_SP_GetM8";
    var data = JSON.stringify({
      POId: `${this.state.issaveClicked == "saveClcik" ? this.state.M8_ID : 0}`,
      POType: "'New'",
      DealerID: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      iM1ID: `${this.state.M7_ID}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        let index = 0;
        if (this.state.issaveClicked == "saveClcik") {
          index = 2;
        }
        if (res && res.length > 0) {
          let newcondition = res[1].map((e, index) => {
            console.log(e, "jkjkjk");
            return {
              id: index,
              accid: e?.Acc_id,
              accessories_name: e?.Accesories_Name,
              accessories_amount: e?.Accessories_Amount,
              hsn: e?.HSN,
              igst: e?.IGST,
              igst_amount: e?.IGST_Amount,
              sgst: e?.SGST,
              sgst_amount: e?.SGST_Amount,
              cgst: e?.CSGT,
              cgst_amount: e?.CSGT_Amount,
              accessories_total_withtax: e?.Total_With_Tax,
            };
          });
          const roundToTwoDecimalPlaces = (value) => {
            return Math.round((value + Number.EPSILON) * 100) / 100;
          };
          if (
            sessionStorage.getItem("Dealer_state") == res[index][0]?.State_ID
          ) {
            this.setState({
              showIGST: false,
              IGST: 0,
              SGST: res[index][0]?.SGST,
              CGST: res[index][0]?.CGST,
              // SGST_Amount: roundToTwoDecimalPlaces(
              //   ((res[index][0]?.SGST ? parseFloat(res[index][0]?.SGST) : 0) /
              //     100) *
              //     (res[index][0]?.Taxable_Amt
              //       ? parseFloat(res[index][0]?.Taxable_Amt)
              //       : 0)
              // ),

              // CGST_Amount: roundToTwoDecimalPlaces(
              //   ((res[index][0]?.CGST ? parseFloat(res[index][0]?.CGST) : 0) /
              //     100) *
              //     (res[index][0]?.Taxable_Amt
              //       ? parseFloat(res[index][0]?.Taxable_Amt)
              //       : 0)
              // ),

              VehiclePrice:
                roundToTwoDecimalPlaces(
                  ((res[index][0]?.SGST_Amount ? parseFloat(res[index][0]?.SGST_Amount) : 0))
                ) +
                roundToTwoDecimalPlaces(
                  ((res[index][0]?.CGST_Amount ? parseFloat(res[index][0]?.CGST_Amount) : 0) )
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.Taxable_Amt
                    ? parseFloat(res[index][0]?.Taxable_Amt)
                    : 0
                ),

              GrandTotal_Amount:
              roundToTwoDecimalPlaces(
                ((res[index][0]?.SGST_Amount ? parseFloat(res[index][0]?.SGST_Amount) : 0))
              ) +
              roundToTwoDecimalPlaces(
                ((res[index][0]?.CGST_Amount ? parseFloat(res[index][0]?.CGST_Amount) : 0) )
              )  +
                roundToTwoDecimalPlaces(
                  res[index][0]?.Taxable_Amt
                    ? parseFloat(res[index][0]?.Taxable_Amt)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.insurance_amount
                    ? parseFloat(res[index][0]?.insurance_amount)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.CRTM_amount
                    ? parseFloat(res[index][0]?.CRTM_amount)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.Smart_card_amount
                    ? parseFloat(res[index][0]?.Smart_card_amount)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.Other_RTO
                    ? parseFloat(res[index][0]?.Other_RTO)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.Accesories_Amt
                    ? parseFloat(res[index][0]?.Accesories_Amt)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.HSRP_Charge
                    ? parseFloat(res[index][0]?.HSRP_Charge)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.other_charges_amount
                    ? parseFloat(res[index][0]?.other_charges_amount)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  (res[index][0]?.RTO_Amount
                    ? parseFloat(res[index][0]?.RTO_Amount)
                    : 0) -
                    (res[index][0]?.RTO_discount_amount
                      ? parseFloat(res[index][0]?.RTO_discount_amount)
                      : 0)
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.HPA_charge
                    ? parseFloat(res[index][0]?.HPA_charge)
                    : 0
                ) -
                roundToTwoDecimalPlaces(
                  res[index][0]?.Exchange_bonus_with_tax
                    ? parseFloat(res[index][0]?.Exchange_bonus_with_tax)
                    : 0
                ),
            });
          } else {
            this.setState({
              showIGST: true,
              IGST: res[index][0]?.IGST,
              SGST: 0,
              CGST: 0,
              // IGST_Amount: roundToTwoDecimalPlaces(
              //   ((res[index][0]?.IGST ? parseFloat(res[index][0]?.IGST) : 0) /
              //     100) *
              //     (res[index][0]?.Taxable_Amt
              //       ? parseFloat(res[index][0]?.Taxable_Amt)
              //       : 0)
              // ),

              VehiclePrice:
                roundToTwoDecimalPlaces(
                  ((res[index][0]?.IGST_Amount ? parseFloat(res[index][0]?.IGST_Amount) : 0))
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.Taxable_Amt
                    ? parseFloat(res[index][0]?.Taxable_Amt)
                    : 0
                ),

              GrandTotal_Amount:
                roundToTwoDecimalPlaces(
                  ((res[index][0]?.IGST_Amount ? parseFloat(res[index][0]?.IGST_Amount) : 0))
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.Taxable_Amt
                    ? parseFloat(res[index][0]?.Taxable_Amt)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.CRTM_amount
                    ? parseFloat(res[index][0]?.CRTM_amount)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.Accesories_Amt
                    ? parseFloat(res[index][0]?.Accesories_Amt)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.insurance_amount
                    ? parseFloat(res[index][0]?.insurance_amount)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.Smart_card_amount
                    ? parseFloat(res[index][0]?.Smart_card_amount)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.Other_RTO
                    ? parseFloat(res[index][0]?.Other_RTO)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  (res[index][0]?.RTO_Amount
                    ? parseFloat(res[index][0]?.RTO_Amount)
                    : 0) -
                    (res[index][0]?.RTO_discount_amount
                      ? parseFloat(res[index][0]?.RTO_discount_amount)
                      : 0)
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.other_charges_amount
                    ? parseFloat(res[index][0]?.other_charges_amount)
                    : 0
                ) +
                roundToTwoDecimalPlaces(
                  res[index][0]?.HPA_charge
                    ? parseFloat(res[index][0]?.HPA_charge)
                    : 0
                ) -
                roundToTwoDecimalPlaces(
                  res[index][0]?.Exchange_bonus_with_tax
                    ? parseFloat(res[index][0]?.Exchange_bonus_with_tax)
                    : 0
                ),
            });
          }
          this.setState(
            {
              AllotId: res[index][0]?.AllotId,
              ConditionCode: newcondition,
              Name: res[index][0]?.name,
              address1: res[index][0]?.add1,
              address2: res[index][0]?.add2,
              Region: res[index][0]?.Region_ID,
              State: res[index][0]?.state,
              State_ID: res[index][0]?.State_ID,
              District: res[index][0]?.District_Name,
              City: res[index][0]?.CITY,
              pincode: res[index][0]?.PIN,
              mobile_no: res[index][0]?.mobile,
              email: res[index][0]?.E_mail,
              Type: res[index][0]?.custtype_name,
              Title: res[index][0]?.Prefix,
              Taluka: res[index][0]?.town_name,
              Region: res[index][0]?.region_name,
              Enquiry_no: res[index][0]?.Inq_no,
              Enquiry_date: res[index][0]?.Inq_date
                ? moment(res[index][0]?.Inq_date).format("DD-MM-YYYY")
                : "",
              Source: res[index][0]?.inqSource,
              M7_Hdr: res[index][0]?.M7_Hdr,
              Attended_By: res[index][0]?.AttendedBy_Name,
              Allocated_To: res[index][0]?.AllocatedTo_Name,
              modelcat: res[index][0]?.Model_Gp,
              modelcode1: res[index][0]?.Model_ID,
              Quantity: res[index][0]?.Qty,
              AMV_Sale_Rate: res[index][0]?.MRP,
              AMV_Standard_Accessories_Amount:
                res[index][0]?.Standard_Accessories_amount,
              AMV_RTO_Amount: res[index][0]?.RTO_Amount,
              Insurance_company: res[index][0]?.Insurance_company,
              AMV_Insurance_Year: res[index][0]?.insurance_year,
              AMV_Insurance_Amount: res[index][0]?.insurance_amount,
              AMV_CRTM_Amount: res[index][0]?.CRTM_amount,
              AMV_HPA_Charges: res[index][0]?.HPA_charge,
              AMV_Smart_Card_Amount: res[index][0]?.Smart_card_amount,
              AMV_RSA_Amount: res[index][0]?.RSA_change,
              AMV_Subsidy_Amount: res[index][0]?.subsidiary_amount,
              AMV_Central_Subsidy_Amount: res[index][0]?.centarl_subsidy_amount,
              AMV_Dealer_Proposed_Amount:
                res[index][0]?.dealer_propsed_discount,
              AMV_Special_Number_Amount: res[index][0]?.special_number_amount,
              AMV_Disbursment_Amount: res[index][0]?.disbursment_amount,
              AMV_RTO_Discount: res[index][0]?.RTO_discount,
              AMV_RTO_Discount_Amount: res[index][0]?.RTO_discount_amount,
              AMV_Other_Charges: res[index][0]?.other_changes,
              Other_Charges_Amount: res[index][0]?.other_charges_amount,
              Exchange_Bonus_Amount: res[index][0]?.Exchange_bonus_with_tax,
              Total: res[index][0]?.total_amount,
              Subsidy_Type: res[index][0]?.Subsity_Type,
              M1_HDR: res[index][0]?.M2_Hdr,
              Financer_ID: res[index][0]?.Financier_id,
              Booking_No: res[index][0]?.M3_no,
              Booking_Date: res[index][0]?.Booking_Date,
              Other_RTO_Charge: res[index][0]?.Other_RTO,
              Booking_Advance_Amount: res[index][0]?.Booking_Adv_Amt,
              Remark: res[index][0]?.remarks,
              Vehicle_Fund: res[index][0]?.Fund,
              HSRP_Charge: res[index][0]?.HSRP_Charge,
              Taxable_Amt: res[index][0]?.Taxable_Amt,
              Pending_Amount: res[index][0]?.Pending_Amt,
              Total_Accessories_Amount: res[index][0]?.Accesories_Amt,
              SchemeName: res[index][0]?.Scheme_Name,
              Scheme_Amt: res[index][0]?.Scheme_Amt,
              Other_Charges: res[index][0]?.other_charges_amount,
              Quantity_Amount: res[index][0]?.Amount,
              Grand_Total: res[index][0]?.Total_amount,
              Remark: res[index][0]?.remarks,
              Delivery_Date: res[index][0]?.Delivery_Date
                ? moment(res[index][0]?.Delivery_Date).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : "",
              Booking_Date: moment(res[index][0]?.Booking_Date).format(
                "DD-MM-YYYY"
              ),
              Payment_mode: res[index][0]?.CashLoan,
              Payment_amount: res[index][0]?.MarginAmt,
              cheque_details: res[index][0]?.ChRTGSDet,
              Cheque_Date:
                res[index][0]?.ChRTGSDate &&
                moment(res[index][0]?.ChRTGSDate).format("DD-MM-YYYY"),
              allocation: this.state.isSavedClick == "saveClcik" ? res[2] : [],
              M3_ID: res[index][0]?.M3_Hdr,
              ML7_No: res[index][0]?.M7_no,
              Receipt_date: res[index][0]?.M7_Date
                ? moment(res[index][0]?.M7_Date).format("DD-MM-YYYY")
                : "",
              Actual_Charger_No: res[index][0]?.Actual_Charger_No,
              Actual_Battery_No: res[index][0]?.Actual_Battery_No,
              IOTDevice_No: res[index][0]?.IOT_Device_ID,
              Display_No: res[index][0]?.Display_No,
              Controller_No: res[index][0]?.Controller_No,
              Charger_No: res[index][0]?.Charger_No,
              Battery_No: res[index][0]?.Battery_No,
              Motor_No: res[index][0]?.Motor_No,
              Vehicle_No: res[index][0]?.Vehicle_No,
              Chassis_No: res[index][0]?.chassis_no,
              Model_Group: res[index][0]?.model_gr_description,
              Model_Code: res[index][0]?.model_code,
              Model_Name: res[index][0]?.model_name,
              Model_Color: res[index][0]?.model_Colour,
              GSTIN: res[index][0]?.GST_No,
              ML8_No: res[index][0]?.M8_No,
              M8_IsConfirm: res[index][0]?.M8_IsConfirm,
              M8_Date: res[index][0]?.M8_Date
                ? moment(res[index][0]?.M8_Date).format("DD-MM-YYYY")
                : "",
              Gatepass_Date: res[index][0]?.Gatepass_Date
                ? moment(res[index][0]?.Gatepass_Date).format("DD-MM-YYYY")
                : "",
              Gatepass_No: res[index][0]?.Gatepass_No,
              Financier_name: res[index][0]?.Financier_name?res[index][0]?.Financier_name:res[index][0]?.financier_name,
              ActualController_Unit_No: res[index][0]?.ActualController_Unit_No,
              ActualDispayNo: res[index][0]?.ActualDispayNo,
              ActualMotor_No: res[index][0]?.ActualMotor_No,
              ActualIOT_Device_ID: res[index][0]?.ActualIOT_Device_ID,
              FinalRTOAMT:
                (res[index][0]?.RTO_Amount
                  ? parseFloat(res[index][0]?.RTO_Amount)
                  : 0) -
                (res[index][0]?.RTO_discount_amount
                  ? parseFloat(res[index][0]?.RTO_discount_amount)
                  : 0),
              Booking_Advance_Amount: res[index][0]?.AdvanceAmt_byquantity,
              Db: res[index][0]?.Db,
              typeofbody: res[index][0]?.typeofbody,
              emissionnorms: res[index][0]?.emissionnorms,
              GrossWt: res[index][0]?.GrossWt,
              FieldType: res[index][0]?.FieldType,
              Uweight: res[index][0]?.Uweight,
              ClassOfVehicle: res[index][0]?.ClassOfVehicle,
              ByStandardPositionDB: res[index][0]?.Pass_by_Bystanders_position_Db,
              Type_App_Certificate_No: res[index][0]?.Type_Approval_certificate_No,
              mfg_date:res[index][0]?.mfg_date,
              payment_ref_no:res[index][0].M3_Transaction_details,
              payment_mode:res[index][0].M3_Payment_Mode,
              CGST_Amount:res[0][0]?.CGST_Amount,
              IGST_Amount:res[0][0]?.IGST_Amount,
              SGST_Amount:res[0][0]?.CGST_Amount,
            },
            () => {
              const invoiceDPrint = {
                Charger_No: res[index][0]?.Charger_No,
                Display_No: res[index][0]?.Display_No,
                Vehicle_No: res[index][0]?.Vehicle_No,
                email:this.state.email,
                pincode:this.state.pincode,
                Name: this.state.Name,
                mobile_no: this.state.mobile_no,
                District: this.state.District,
                City: this.state.City,
                State: this.state.State,
                address1: this.state.address1,
                InvoiceDate: this.state.M8_Date,
                InvoiceNo: this.state.ML8_No,
                Quantity: this.state.Quantity,
                Model_Color: this.state.Model_Color,
                Model_Name: this.state.Model_Name,
                Model_Code: this.state.Model_Code,
                Model_Group: this.state.Model_Group,
                Chassis_No: this.state.Chassis_No,
                GrandTotal_Amount: this.state.GrandTotal_Amount,
                VehiclePrice: this.state.VehiclePrice,
                IGST_Amount: this.state.IGST_Amount,
                CGST_Amount: this.state.CGST_Amount,
                SGST_Amount: this.state.SGST_Amount,
                Total_Accessories_Amount: this.state.Total_Accessories_Amount,
                Total: this.state.Quantity_Amount,
                Exchange_Bonus_Amount: this.state.Exchange_Bonus_Amount,
                Other_Charges_Amount: this.state.Other_Charges_Amount,
                AMV_RTO_Discount_Amount: this.state.AMV_RTO_Discount_Amount,
                AMV_Smart_Card_Amount: this.state.AMV_Smart_Card_Amount,
                AMV_HPA_Charges: this.state.AMV_HPA_Charges,
                AMV_CRTM_Amount: this.state.AMV_CRTM_Amount,
                AMV_RTO_Amount: this.state.AMV_RTO_Amount,
                Taxable_Amt: this.state.Taxable_Amt,
                AMV_Insurance_Amount: this.state.AMV_Insurance_Amount,
                Other_RTO_Charge: this.state.Other_RTO_Charge,
                showIGST: this.state.showIGST,
                IGST: this.state.IGST,
                CGST: this.state.CGST,
                SGST: this.state.SGST,
                Motor_No: this.state.Motor_No,
                Battery_No: this.state.Battery_No,
                Charger_No: this.state.Charger_No,
                IOTDevice_No: this.state.IOTDevice_No,
                GSTIN: this.state.GSTIN,
                Controller_No: this.state.Controller_No,
                Financier: this.state.Financier_name,
                ConditionCode:this.state.ConditionCode,
                SchemeName: this.state.SchemeName,
                Scheme_Amt: this.state.Scheme_Amt,
                StateSubsidyAmt:this.state.AMV_Subsidy_Amount,
                CentralSubsidyAmt:this.state.AMV_Central_Subsidy_Amount,
                AMV_Sale_Rate:this.state.AMV_Sale_Rate,
                AMV_Dealer_Proposed_Amount:this.state.AMV_Dealer_Proposed_Amount,
                ActualController_Unit_No: this.state.ActualController_Unit_No,
              ActualDispayNo: this.state.ActualDispayNo,
              ActualMotor_No: this.state.ActualMotor_No,
              ActualIOT_Device_ID: this.state.ActualIOT_Device_ID,
              Actual_Charger_No: this.state.Actual_Charger_No,
              Actual_Battery_No: this.state.Actual_Battery_No,
              HSRP_Charge:this.state.HSRP_Charge
              };
              const Gate_PassDetails = {
                mfg_date:this.state.mfg_date,
                Type_App_Certificate_No:this.state.Type_App_Certificate_No,
                ByStandardPositionDB:this.state.ByStandardPositionDB,
                AMV_Sale_Rate:this.state.AMV_Sale_Rate,
                AccessoriesDetails:res[1],
                Financier: this.state.Financier_name,
                Quantity:this.state.Quantity,
                GSTIN:this.state.GSTIN,
                Name: this.state.Name,
                Mobile_No: this.state.mobile_no,
                Chassis_No: this.state.Chassis_No,
                Motor_No: this.state.Motor_No,
                Battery_No: this.state.Battery_No,
                Charger_No: this.state.Charger_No,
                IOT_Device_NO: this.state.IOTDevice_No,
                Invoice_Date: this.state.M8_Date,
                Invoice_No: this.state.ML8_No,
                Contoller_No: this.state.Controller_No,
                City: this.state.City,
                State: this.state.State,
                Address1: this.state.address1,
                Display_No: this.state.Display_No,
                Model_Name: this.state.Model_Name,
                Model_Colour: this.state.Model_Color,
                Vehicle_No: this.state.Vehicle_No,
                Gatepass_No: this.state.Gatepass_No,
                Gatepass_Date: this.state.Gatepass_Date,
                Db: this.state.Db,
                typeofbody: this.state.typeofbody,
                emissionnorms: this.state.emissionnorms,
                GrossWt: this.state.GrossWt,
                FieldType: this.state.FieldType,
                Uweight: this.state.Uweight,
                ClassOfVehicle: this.state.ClassOfVehicle,
                ActualController_Unit_No: this.state.ActualController_Unit_No,
              ActualDispayNo: this.state.ActualDispayNo,
              ActualMotor_No: this.state.ActualMotor_No,
              ActualIOT_Device_ID: this.state.ActualIOT_Device_ID,
              Actual_Charger_No: this.state.Actual_Charger_No,
              Actual_Battery_No: this.state.Actual_Battery_No,
              };
              this.setState({
                invoiceDPrint: invoiceDPrint,
                Gate_PassDetails: Gate_PassDetails,
              });
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  ChangeVal(event, key) {
    this.setState({
      [key]: event.target.value,
    });
  }

  required = () => {
    return (
      <span style={{ fontSize: "14px" }} className=" text-danger ">
        *
      </span>
    );
  };
  Enquiry_Details = () => {
    return (
      <div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Enquiry No.</label>
            <input
              disabled={true}
              value={this.state.Enquiry_no}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Enquiry Date</label>
            <input
              disabled={true}
              type="text"
              className="form-control"
              value={this.state.Enquiry_date}
            />
          </div>
          <div class="form-group col-md-3">
            <label>Customer Name</label>
            <input
              value={this.state.Name}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Mobile</label>
            <input
              value={this.state.mobile_no}
              disabled={true}
              // maxlength="10"
              class="form-control"
              placeholder=""
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Source</label>
            <input
              value={this.state.Source}
              className="form-control "
              disabled={true}
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Type</label>
            <input
              className="form-control "
              // value={this.state.Type}
              disabled={true}
              value={this.state.Type}
            ></input>
          </div>
          {/* <div class="form-group col-md-3">
            <label>Title</label>
            <input
              disabled
              className="form-control "
              value={this.state.Title}
              onChange={(event) => this.ChangeVal(event, "Title")}
            ></input>
          </div> */}

          <div class="form-group col-md-3">
            <label>Address 1</label>
            <input
              value={this.state.address1}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Address 2</label>
            <input
              value={this.state.address2}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Pincode</label>
            <input
              value={this.state.pincode}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>City</label>
            <input
              value={this.state.City}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>State</label>
            <input
              value={this.state.State}
              className="form-control "
              disabled={true}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>District</label>
            <input
              value={this.state.District}
              className="form-control "
              disabled={true}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Tehsil</label>
            <input
              value={this.state.Taluka}
              className="form-control "
              disabled={true}
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Region</label>
            <input
              value={this.state.Region}
              className="form-control "
              disabled={true}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Country </label>
            <input
              disabled={true}
              value={"INDIA"}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Email</label>
            <input
              value={this.state.email}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Attended By</label>
            <input
              value={this.state.Attended_By}
              className="form-control "
              disabled={true}
            ></input>
          </div>
          {/* <div class="form-group col-md-3">
            <label>Allocated To</label>
            <input
              value={this.state.Allocated_To}
              className="form-control "
              disabled={true}
            ></input>
          </div> */}

          <div className=" col-3">
            <label>Remark</label>
            <input
              disabled
              onChange={(e) => this.setState({ Remark: e.target.value })}
              value={this.state.Remark}
              className=" form-control "
            ></input>
          </div>
        </div>
      </div>
    );
  };
  VehicleInvoiceDetails = () => {
    return (
      <div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Invoice No.</label>
            <input
              readOnly
              value={this.state.ML8_No}
              className=" form-control "
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Invoice Date </label>
            <input
              readOnly
              value={
                this.state.M8_Date
                  ? this.state.M8_Date
                  : moment(new Date()).format("DD-MM-YYYY")
              }
              className=" form-control "
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Customer GST Number</label>
            <input
              value={this.state.GSTIN}
              onChange={(e) => this.setState({ GSTIN: e.target.value })}
              className="form-control "
            ></input>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Chassis No.</label>
              <input
                readOnly
                value={this.state.Chassis_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Vehicle No.</label>
              <input
                readOnly
                value={this.state.Vehicle_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Motor No.</label>
              <input
                readOnly
                value={this.state.Motor_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Battery No.</label>
              <input
                readOnly
                value={this.state.Battery_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Charger No.</label>
              <input
                readOnly
                value={this.state.Charger_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Controller No.</label>
              <input
                readOnly
                value={this.state.Controller_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Display No.</label>
              <input
                readOnly
                value={this.state.Display_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>IOT Device No.</label>
              <input
                readOnly
                value={this.state.IOTDevice_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Replaced Battery No.</label>
              <input
                readOnly
                value={this.state.Actual_Battery_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Replaced Charger No.</label>
              <input
                readOnly
                value={this.state.Actual_Charger_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Replaced Controller No.</label>
              <input
                readOnly
                value={this.state.ActualController_Unit_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Replaced Motor No.</label>
              <input
                readOnly
                value={this.state.ActualMotor_No}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Replaced Display No.</label>
              <input
                readOnly
                value={this.state.ActualDispayNo}
                className=" form-control "
              ></input>
            </div>
          </div>
          <div class="form-group col-md-3">
            <div>
              <label>Replaced IOT Device No.</label>
              <input
                readOnly
                value={this.state.ActualIOT_Device_ID}
                className=" form-control "
              ></input>
            </div>
          </div>
        </div>
      </div>
    );
  };
  M7Fileds = () => {
    return (
      <div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Receipt No</label>
            <input
              readOnly
              value={this.state.Receipt_No}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Receipt Date </label>
            <input
              readOnly
              value={this.state.Receipt_date}
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">Payment Mode</label>
            <select
              className="form-control"
              value={this.state.Payment_mode}
              onChange={(event) => {
                this.setState({
                  Payment_mode: event.target.value,
                });
              }}
            >
              <option value="0">Select</option>
              <option value="Cash">Cash </option>
              <option value="Cheque">Cheque</option>
              <option value="debit_card">Debit Card</option>
              <option value="credit_card">Credit Card</option>
            </select>
          </div>
          <div className=" col-3">
            <label>Payment Amount </label>
            <input
              onChange={(e) =>
                this.setState({ Payment_amount: e.target.value })
              }
              value={this.state.Payment_amount}
              className=" form-control "
              type="number"
            ></input>
          </div>
        </div>
        <div className=" row mt-1">
          <div className=" col-3">
            <label>Cheque/Transaction Details</label>
            <input
              onChange={(e) =>
                this.setState({ cheque_details: e.target.value })
              }
              value={this.state.cheque_details}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Cheque/Transfer Date</label>
            <input
              type="date"
              className="form-control"
              placeholder=""
              value={this.state.Cheque_Date}
              onChange={(event) => {
                this.handledateChange(event, "Cheque_Date");
              }}
            />
          </div>
        </div>
      </div>
    );
  };
  ModelDetails = () => {
    return (
      <div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Model</label>
            <input
              disabled
              className="form-control "
              value={this.state.Model_Group}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Model Code</label>
            <input
              disabled
              className="form-control "
              value={this.state.Model_Code}
              onChange={(event) => this.ChangeVal(event, "ModelCode")}
            >
              {/* <option value="0">---select---</option>
                      {this.state.Model_Code.map((e) => {
                return <option value={e.ID}>{e["Model code"]}</option>;
              })} */}
            </input>
          </div>
          <div class="form-group col-md-3">
            <label>Model Name</label>
            <input
              disabled
              className="form-control "
              value={this.state.Model_Name}
              onChange={(event) => this.ChangeVal(event, "Model")}
            >
              {/* <option value="0">---Select---</option>
                      {this.GetModelList()} */}
            </input>
          </div>
          <div class="form-group col-md-3">
            <label>Model Colour</label>
            <input
              disabled
              value={this.state.Model_Color}
              onChange={(e) => this.onChangeName(e, "Model_Colour")}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Quantity</label>
            <input
              disabled
              value={this.state.Quantity}
              onChange={(e) => this.onChangeName(e, "Quantity")}
              class="form-control"
              placeholder=""
            ></input>
          </div>
        </div>
      </div>
    );
  };
  getScheme = (ModelCodedata) => {
    const url = Urls.python_baseurl + "/GetScheme_SP_GetScheme ";
    var data = JSON.stringify({
      DepartmentId: "5",
      Model_code: `'${ModelCodedata}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            SchemeOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  accessoriesHeader() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        {/* <td width="25px" className="table-text"></td> */}
        <td>Accessories Name</td>
        <td>Accessories Amount</td>
        <td>HSN</td>
        <td>IGST</td>
        <td>IGST Amount</td>
        <td>SGST</td>
        <td>SGST Amount</td>
        <td>CSGT</td>
        <td>CSGT Amount</td>
        <td>Accessories Total With Tax</td>
        {/* <td width="25px" className="table-text"></td> */}
      </tr>
    );
  }
  accessoriesBody() {
    return this.state.ConditionCode.map((d, index) => {
      return (
        <tr key={d.id}>
          {/* <td>
            {index === this.state.ConditionCode.length - 1 && (
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{
                  color: "var(--main-bg-color)",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
                onClick={() => this.accessoriesAddNew(d.id)}
              />
            )}
          </td> */}
          <td>
            <input
              disabled
              onChange={(e) => {
                this.handelchange("accessories_name", e.target.value, d.id);
              }}
              value={d.accessories_name}
              type="text"
              list="accessories_name"
            />
          </td>
          <td>
            <input
              disabled
              type="number"
              onChange={(e) => {
                this.handelchange("accessories_amount", e.target.value, d.id);
              }}
              value={d.accessories_amount}
            />
          </td>
          <td>
            <input
              disabled
              type="text"
              onChange={(e) => {
                this.handelchange("hsn", e.target.value, d.id);
              }}
              value={d.hsn}
            />
          </td>
          <td>
            <input
              disabled
              type="number"
              onChange={(e) => {
                this.handelchange("igst", e.target.value, d.id);
              }}
              value={d.igst}
            />
          </td>
          <td>
            <input
              disabled
              type="number"
              onChange={(e) => {
                this.handelchange("igst_amount", e.target.value, d.id);
              }}
              value={d.igst_amount}
            />
          </td>
          <td>
            <input
              disabled
              type="number"
              onChange={(e) => {
                this.handelchange("sgst", e.target.value, d.id);
              }}
              value={d.sgst}
            />
          </td>
          <td>
            <input
              disabled
              type="number"
              onChange={(e) => {
                this.handelchange("sgst_amount", e.target.value, d.id);
              }}
              value={d.sgst_amount}
            />
          </td>
          <td>
            <input
              disabled
              type="number"
              onChange={(e) => {
                this.handelchange("cgst", e.target.value, d.id);
              }}
              value={d.cgst}
            />
          </td>
          <td>
            <input
              disabled
              type="number"
              onChange={(e) => {
                this.handelchange("cgst_amount", e.target.value, d.id);
              }}
              value={d.cgst_amount}
            />
          </td>
          <td>
            <input
              disabled
              type="number"
              onChange={(e) => {
                this.handelchange(
                  "accessories_total_with_tax",
                  e.target.value,
                  d.id
                );
              }}
              value={d.accessories_total_withtax}
            />
          </td>
          {/* <td style={{ width: "10%", backgroundColor: "white" }}>
            <img
              src={require("../../../components/Images/trash.png")}
              style={{ width: "20px", cursor: "pointer" }}
              onClick={() => this.accessoriesDelet(d.id)}
            ></img>
          </td> */}
        </tr>
      );
    });
  }
  bookingDetails = () => {
    return (
      <div class="form-row">
        <div className="form-group col-md-3">
          <label>Booking No.</label>
          <input
            readOnly
            value={this.state.Booking_No}
            className=" form-control "
          ></input>
        </div>
        <div className=" form-group col-md-3">
          <label>Booking Date </label>
          <input
            readOnly
            value={this.state.Booking_Date}
            className=" form-control "
          ></input>
        </div>
        <div className=" col-3">
          <label>Vehicle Fund </label>
          <select
            disabled
            onChange={(e) => this.setState({ Vehicle_Fund: e.target.value })}
            value={this.state.Vehicle_Fund}
            className=" form-control "
          >
            <option>Select</option>
            <option value="C">Cash</option>
            <option value="L">Loan</option>
          </select>
        </div>
        {this.state.Vehicle_Fund == "L" && ( <div className=" col-3">
          <label>Financier</label>
          <input
            disabled
            value={this.state.Financier_name}
            className=" form-control "
          ></input>
        </div>)}
        <div className="form-group col-md-3">
          <label>Payment Mode </label>
          <select
            disabled
            value={this.state.payment_mode}
            className=" form-control"
          >
            <option value={0}>Select</option>

            <option value="RTGS">RTGS</option>
            <option value="Cash">Cash</option>
            <option value="NEFT">NEFT</option>
            <option value="DD">DD</option>
            <option value="Cheque">Cheque</option>
            <option value="IMPS">IMPS</option>
            <option value="Credit_Card">Credit Card</option>
            <option value="Debit_Card">Debit Card</option>
            <option value="UPI">UPI</option>
            <option value="Others">Others</option>
          </select>
        </div>
        <div className=" form-group col-md-3">
          <label>Cheque/Transaction Details. </label>
          <input
          disabled
            value={this.state.payment_ref_no}
            className=" form-control "
          ></input>
        </div>
        <div className=" col-3">
          <label>Remark</label>
          <input
            disabled
            onChange={(e) => this.setState({ Remark: e.target.value })}
            value={this.state.Remark}
            className=" form-control "
          ></input>
        </div>
        <div className=" col-3">
          <label htmlFor="date">Expected Delivery Date</label>
          <input
            disabled
            type="datetime-local"
            className="form-control"
            placeholder=""
            value={this.state.Delivery_Date}
            onChange={(event) => {
              this.handledateChange(event, "Delivery_Date");
            }}
          />
        </div>
      </div>
    );
  };
  getInsurance = (ModelCodedata) => {
    const url = Urls.python_baseurl + "/getInsurance_SP_GetInsurance ";
    var data = JSON.stringify({
      ModelCode: `'${ModelCodedata}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            insuranceList: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  quotationSubmitted = () => {
    return (
      <div>
        <div className=" form-row">
          <div className="form-group col-md-3">
            <label htmlFor="sale_rate">Ex Showroom Price</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Sale_Rate")}
              disabled
              value={this.state.AMV_Sale_Rate}
              id="sale_rate"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="sale_rate">Quantity</label>
            <input
              onChange={(e) => this.ChangeVal(e, "Quantity")}
              disabled
              value={this.state.Quantity}
              id="sale_rate"
              className=" form-control "
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="Quantity_Amount">Amount</label>
            <input
              onChange={(e) => this.ChangeVal(e, "Quantity_Amount")}
              disabled
              value={this.state.Quantity_Amount}
              id="Quantity_Amount"
              className=" form-control "
            ></input>
          </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="rto_discount">Dealer Proposed Discount (-)</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Dealer_Proposed_Amount")}
              disabled
              value={this.state.AMV_Dealer_Proposed_Amount}
              id="rto_discount"
              className=" form-control "
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="other_charges">Subsidy Type</label>
            <input
              onChange={(e) => this.ChangeVal(e, "Subsidy_Type")}
              disabled
              value={this.state.Subsidy_Type}
              className=" form-control "
              id="other_charges"
            ></input>
          </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">State Subsidy Amount (-)</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Subsidy_Amount")}
              disabled
              value={this.state.AMV_Subsidy_Amount&&parseFloat(this.state.AMV_Subsidy_Amount).toFixed(2)}
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Central Subsidy Amount (-)</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Central_Subsidy_Amount")}
              disabled
              value={parseFloat(this.state.AMV_Central_Subsidy_Amount).toFixed(
                2
              )}
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Scheme Name</label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "SchemeName")}
              value={this.state.SchemeName}
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Scheme Amount (-)</label>
            <input
              onChange={(e) => this.ChangeVal(e, "Scheme_Amount")}
              disabled
              value={this.state.Scheme_Amt}
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="Total">Taxable Amount</label>
            <input
              disabled
              value={this.state.Taxable_Amt&&this.state.Taxable_Amt.toFixed(2)}
              onChange={(e) => this.onChangeName(e, "Total")}
              class="form-control"
              placeholder=""
              id="Total"
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="Total">Booking Advance Amount</label>
            <input
              disabled
              value={this.state.Booking_Advance_Amount}
              onChange={(e) => this.onChangeName(e, "Total")}
              class="form-control"
              placeholder=""
              id="Total"
            ></input>
          </div>
          {/* <div class="form-group col-md-3">
            <label>Booking Advance Amount (-)</label>
            <input
              disabled
              value={this.state.Booking_Advance_Amount}
              className=" form-control "
              type="number"
            ></input>
          </div> */}
          {/* <div class="form-group col-md-3">
          <label htmlFor="pending_amount">Balance Amount</label>
          <input
            disabled
            value={this.state.Pending_Amount}
            onChange={(e) => this.onChangeName(e, "Pending_Amount")}
            class="form-control"
            id="pending_amount"
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="rtoamount">RTO Amount (+)</label>
          <div>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_RTO_Amount")}
              value={this.state.AMV_RTO_Amount}
              
              className=" form-control "
              placeholder="RTO Amount"
              id="rtoamount"
            ></input>
          </div>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="rto_discount">RTO Discount (%)</label>
          <input
            onChange={(e) => this.ChangeVal(e, "AMV_RTO_Discount")}
            disabled
            value={this.state.AMV_RTO_Discount}
            id="rto_discount"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="rto_discount_amount">RTO Discount Amount (-)</label>
          <input
            onChange={(e) => this.ChangeVal(e, "AMV_RTO_Discount_Amount")}
            disabled
            value={this.state.AMV_RTO_Discount_Amount}
            id="rto_discount_amount"
            className=" form-control "
          ></input>
        </div>

        <div className="form-group col-md-3">
          <label htmlFor="crtm_amount">CRTM Amount (+)</label>
          <div>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_CRTM_Amount")}
              value={this.state.AMV_CRTM_Amount}
              
              placeholder="CRTM Amount"
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="hpacharge">HPA Charge (+)</label>
          <input
            onChange={(e) => this.ChangeVal(e, "AMV_HPA_Charges")}
            disabled
            value={this.state.AMV_HPA_Charges}
            id="hpacharge"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="smart_card_amount">Smart Card Amount (+)</label>
          <div>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_Smart_Card_Amount")}
              value={this.state.AMV_Smart_Card_Amount}
              
              placeholder="Smart Card Amount"
              id="smart_card_amount"
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="HSRP_Charge">HSRP Charge (+)</label>
          <input
            onChange={(e) => this.ChangeVal(e, "HSRP_Charge")}
            disabled
            value={this.state.HSRP_Charge}
            id="HSRP_Charge"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="Other_RTO_Charge">Other RTO Charge (+)</label>
          <input
            onChange={(e) => this.ChangeVal(e, "Other_RTO_Charge")}
            disabled
            value={this.state.Other_RTO_Charge}
            id="Other_RTO_Charge"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="Insurance_company">Insurance Company</label>
          <select
            disabled
            onChange={(e) => this.ChangeVal(e, "Insurance_company")}
            value={this.state.Insurance_company}
            id="Insurance_company"
            className=" form-control "
          >
            <option>Select</option>
            {this.state.insuranceList.map((e) => {
              return <option>{e.Insurance_Company}</option>;
            })}
          </select>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="insurance_year">Insurance Year</label>
          <input
            disabled
            onChange={(e) => this.ChangeVal(e, "AMV_Insurance_Year")}
            value={this.state.AMV_Insurance_Year}
            id="insurance_year"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="insurance_amount">Insurance Amount (+)</label>
          <input
            disabled
            onChange={(e) => {
              this.ChangeVal(e, "AMV_Insurance_Amount");
              this.setState({
                Grand_Total:
                  this.state.Pending_Amount +
                  (this.state.AMV_RTO_Amount
                    ? parseFloat(this.state.AMV_RTO_Amount)
                    : 0) +
                  (this.state.Other_Charges
                    ? parseFloat(this.state.Other_Charges)
                    : 0) +
                  (this.state.Total_Accessories_Amount
                    ? parseFloat(this.state.Total_Accessories_Amount)
                    : 0) +
                  (e.target.value ? parseFloat(e.target.value) : 0) +
                  (this.state.Other_RTO_Charge
                    ? parseFloat(this.state.Other_RTO_Charge)
                    : 0) +
                  (this.state.HSRP_Charge
                    ? parseFloat(this.state.HSRP_Charge)
                    : 0) +
                  (this.state.AMV_Smart_Card_Amount
                    ? parseFloat(this.state.AMV_Smart_Card_Amount)
                    : 0) +
                  (this.state.AMV_HPA_Charges
                    ? parseFloat(this.state.AMV_HPA_Charges)
                    : 0) +
                  (this.state.AMV_CRTM_Amount
                    ? parseFloat(this.state.AMV_CRTM_Amount)
                    : 0) -
                  (this.state.Exchange_Bonus_Amount
                    ? parseFloat(this.state.Exchange_Bonus_Amount)
                    : 0) -
                  (this.state.AMV_RTO_Discount_Amount
                    ? parseFloat(this.state.AMV_RTO_Discount_Amount)
                    : 0) -
                  parseFloat(
                    this.state.SchemeOption.filter(
                      (e) => e.ID == this.state.SchemeName
                    )[0]?.Amount
                      ? this.state.SchemeOption.filter(
                          (e) => e.ID == this.state.SchemeName
                        )[0]?.Amount
                      : 0
                  ),
              });
            }}
            value={this.state.AMV_Insurance_Amount}
            id="insurance_amount"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="Total_Accessories_Amount">
            Total Accessories Amount (+)
          </label>
          <input
            onChange={(e) => this.ChangeVal(e, "Insurance_company_Year")}
            disabled
            value={this.state.Total_Accessories_Amount}
            id="Total_Accessories_Amount"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="Other_Charges">Other Charges (+)</label>
          <input
            disabled
            onChange={(e) => {
              this.ChangeVal(e, "Other_Charges");
              this.setState({
                Grand_Total:
                  this.state.Pending_Amount +
                  (this.state.AMV_RTO_Amount
                    ? parseFloat(this.state.AMV_RTO_Amount)
                    : 0) +
                  (e.target.value ? parseFloat(e.target.value) : 0) +
                  (this.state.Total_Accessories_Amount
                    ? parseFloat(this.state.Total_Accessories_Amount)
                    : 0) +
                  (this.state.AMV_Insurance_Amount
                    ? parseFloat(this.state.AMV_Insurance_Amount)
                    : 0) +
                  (this.state.Other_RTO_Charge
                    ? parseFloat(this.state.Other_RTO_Charge)
                    : 0) +
                  (this.state.HSRP_Charge
                    ? parseFloat(this.state.HSRP_Charge)
                    : 0) +
                  (this.state.AMV_Smart_Card_Amount
                    ? parseFloat(this.state.AMV_Smart_Card_Amount)
                    : 0) +
                  (this.state.AMV_HPA_Charges
                    ? parseFloat(this.state.AMV_HPA_Charges)
                    : 0) +
                  (this.state.AMV_CRTM_Amount
                    ? parseFloat(this.state.AMV_CRTM_Amount)
                    : 0) -
                  (this.state.Exchange_Bonus_Amount
                    ? parseFloat(this.state.Exchange_Bonus_Amount)
                    : 0) -
                  (this.state.AMV_RTO_Discount_Amount
                    ? parseFloat(this.state.AMV_RTO_Discount_Amount)
                    : 0) -
                  parseFloat(
                    this.state.SchemeOption.filter(
                      (e) => e.ID == this.state.SchemeName
                    )[0]?.Amount
                      ? this.state.SchemeOption.filter(
                          (e) => e.ID == this.state.SchemeName
                        )[0]?.Amount
                      : 0
                  ),
              });
            }}
            value={this.state.Other_Charges}
            id="Other_Charges"
            className=" form-control "
            type="number"
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="Exchange_Bonus_Amount">
            Exchange Bonus With Tax (-)
          </label>
          <input
            disabled
            onChange={(e) => {
              this.ChangeVal(e, "Exchange_Bonus_Amount");
              this.setState({
                Grand_Total:
                  this.state.Pending_Amount +
                  (this.state.AMV_RTO_Amount
                    ? parseFloat(this.state.AMV_RTO_Amount)
                    : 0) +
                  (this.state.Other_Charges
                    ? parseFloat(this.state.Other_Charges)
                    : 0) +
                  (this.state.Total_Accessories_Amount
                    ? parseFloat(this.state.Total_Accessories_Amount)
                    : 0) +
                  (this.state.AMV_Insurance_Amount
                    ? parseFloat(this.state.AMV_Insurance_Amount)
                    : 0) +
                  (this.state.Other_RTO_Charge
                    ? parseFloat(this.state.Other_RTO_Charge)
                    : 0) +
                  (this.state.HSRP_Charge
                    ? parseFloat(this.state.HSRP_Charge)
                    : 0) +
                  (this.state.AMV_Smart_Card_Amount
                    ? parseFloat(this.state.AMV_Smart_Card_Amount)
                    : 0) +
                  (this.state.AMV_HPA_Charges
                    ? parseFloat(this.state.AMV_HPA_Charges)
                    : 0) +
                  (this.state.AMV_CRTM_Amount
                    ? parseFloat(this.state.AMV_CRTM_Amount)
                    : 0) -
                  (e.target.value ? parseFloat(e.target.value) : 0) -
                  (this.state.AMV_RTO_Discount_Amount
                    ? parseFloat(this.state.AMV_RTO_Discount_Amount)
                    : 0) -
                  parseFloat(
                    this.state.SchemeOption.filter(
                      (e) => e.ID == this.state.SchemeName
                    )[0]?.Amount
                      ? this.state.SchemeOption.filter(
                          (e) => e.ID == this.state.SchemeName
                        )[0]?.Amount
                      : 0
                  ),
              });
            }}
            value={this.state.Exchange_Bonus_Amount}
            id="Exchange_Bonus_Amount"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="Grand_Total">Grand Total</label>
          <input
            disabled
            // onChange={(e) => this.ChangeVal(e, "Grand_Total")}
            value={
              this.state.Grand_Total &&
              parseFloat(this.state.Grand_Total).toFixed(2)
            }
            id="Grand_Total"
            className=" form-control "
          ></input>
        </div> */}
          {/* <div className="form-group col-md-3">
          <label htmlFor="special_number_amount">Special Number Amount</label>
          <input
            disabled
            onChange={(e) => this.ChangeVal(e, "AMV_Special_Number_Amount")}
            value={this.state.AMV_Special_Number_Amount}
            id="special_number_amount"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="disbursnment_amount">Disbursment Amount</label>
          <input
            onChange={(e) => this.ChangeVal(e, "AMV_Disbursment_Amount")}
            disabled
            value={this.state.AMV_Disbursment_Amount}
            id="disbursnment_amount"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="other_charges">Other Charges</label>
          <input
            onChange={(e) => this.ChangeVal(e, "AMV_Other_Charges")}
            disabled
            value={this.state.AMV_Other_Charges}
            className=" form-control "
            id="other_charges"
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="oher_charges_amount">Other Charges Amount</label>
          <input
            onChange={(e) => this.ChangeVal(e, "Other_Charges_Amount")}
            disabled
            value={this.state.Other_Charges_Amount}
            id="oher_charges_amount"
            className=" form-control "
          ></input>
        </div> */}
        </div>
        <div>{this.renderB2BSale()}</div>
      </div>
    );
  };
  renderB2BSale = () => {
    return (
      <table
        style={{ width: "40%" }}
        className="table table-bordered mt-2"
        id="userList"
        align="center"
      >
        <thead>
          <tr
            className="table-head-text-view"
            style={{
              backgroundColor: "var(--main-bg-color)",
              fontSize: "12px",
              color: "white",
              // position: "re",
              top: "0",
              padding: "0",
            }}
          >
            <td>Particular</td>
            <td className="itemvalue2 ">Amount</td>
          </tr>
        </thead>
        <tbody className=" p-0">
          <tr>
            <td>Taxable Amount</td>
            <td className=" itemvalue2 ">
              {this.state.Taxable_Amt && this.state.Taxable_Amt.toFixed(2)}
            </td>
          </tr>
          {sessionStorage.getItem("Dealer_state") != this.state.State_ID && (
            <tr>
              <td>
                IGST{" "}
                <span style={{ fontWeight: "600" }}>{`(${
                  this.state.IGST ? " " + this.state.IGST + " %" : ""
                })`}</span>
              </td>
              <td className=" itemvalue2 ">
                {this.state.IGST_Amount && this.state.IGST_Amount.toFixed(2)}
              </td>
            </tr>
          )}
          {sessionStorage.getItem("Dealer_state") == this.state.State_ID && (
            <tr>
              <td>
                SGST{" "}
                <span style={{ fontWeight: "600" }}>{`(${
                  this.state.SGST ? " " + this.state.SGST + " %" : ""
                })`}</span>
              </td>
              <td className=" itemvalue2 ">
                {this.state.SGST_Amount && this.state.SGST_Amount.toFixed(2)}
              </td>
            </tr>
          )}
          {sessionStorage.getItem("Dealer_state") == this.state.State_ID && (
            <tr>
              <td>
                CGST{" "}
                <span style={{ fontWeight: "600" }}>{`(${
                  this.state.CGST ? " " + this.state.CGST + " %" : ""
                })`}</span>
              </td>
              <td className=" itemvalue2 ">
                {this.state.CGST_Amount && this.state.CGST_Amount.toFixed(2)}
              </td>
            </tr>
          )}
          <tr>
            <td>Vehicle Price</td>
            <td className=" itemvalue2 ">
              {this.state.VehiclePrice && this.state.VehiclePrice.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>Company Accessories (+)</td>
            <td className=" itemvalue2 ">
              {this.state.Total_Accessories_Amount &&
                this.state.Total_Accessories_Amount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>RTO Amount (+)</td>
            <td className=" itemvalue2 ">
              {this.state.FinalRTOAMT && this.state.FinalRTOAMT.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>Insurance Amount (+)</td>
            <td className=" itemvalue2 ">
              {this.state.AMV_Insurance_Amount &&
                this.state.AMV_Insurance_Amount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>HPA Charge (+)</td>
            <td className=" itemvalue2 ">
              {this.state.AMV_HPA_Charges &&
                this.state.AMV_HPA_Charges.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>CRTM Charge (+)</td>
            <td className=" itemvalue2 ">
              {this.state.AMV_CRTM_Amount &&
                this.state.AMV_CRTM_Amount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>Smart Card Amount (+)</td>
            <td className=" itemvalue2 ">
              {this.state.AMV_Smart_Card_Amount &&
                this.state.AMV_Smart_Card_Amount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>HSRP Charge (+)</td>
            <td className=" itemvalue2 ">
              {this.state. HSRP_Charge &&
                this.state. HSRP_Charge.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>Other RTO Charge (+)</td>
            <td className=" itemvalue2 ">
              {this.state.Other_RTO_Charge &&
                this.state.Other_RTO_Charge.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>Other Charges (+)</td>
            <td className=" itemvalue2 ">
              {this.state.Other_Charges && this.state.Other_Charges.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>Exchange Bonus With Tax (-)</td>
            <td className=" itemvalue2 ">
              {this.state.Exchange_Bonus_Amount &&
                this.state.Exchange_Bonus_Amount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td className=" itemvalue2 ">
              {this.state.GrandTotal_Amount &&
                this.state.GrandTotal_Amount.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>Round off (+/-)</td>
            <td className=" itemvalue2 ">
              .
              {(this.state.GrandTotal_Amount &&
                this.state.GrandTotal_Amount.toFixed(2).split(".")[1]) > 50
                ? 100 -
                  (this.state.GrandTotal_Amount &&
                    this.state.GrandTotal_Amount.toFixed(2).split(".")[1])
                :
                  (this.state.GrandTotal_Amount &&
                    this.state.GrandTotal_Amount.toFixed(2).split(".")[1])}
            </td>
          </tr>
          <tr>
            <td>Final Total Amount</td>
            <td className=" itemvalue2 ">
              {this.state.GrandTotal_Amount &&
                Math.round(this.state.GrandTotal_Amount).toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  MoneyReceived = () => {
    return (
      <div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Received No.</label>
            <input
              disabled
              value={this.state.ML7_No}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Received Date</label>
            <input
              disabled
              value={this.state.Receipt_date}
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">Payment Mode</label>
            <select
              disabled
              className="form-control"
              value={this.state.Payment_mode}
              onChange={(event) => {
                this.onSelect(event, "Payment_mode");
              }}
            >
              <option value="0">Select</option>
              <option value="RTGS">RTGS</option>
            <option value="Cash">Cash</option>
            <option value="NEFT">NEFT</option>
            <option value="DD">DD</option>
            <option value="Cheque">Cheque</option>
            <option value="IMPS">IMPS</option>
            <option value="Credit_Card">Credit Card</option>
            <option value="Debit_Card">Debit Card</option>
            <option value="UPI">UPI</option>
            <option value="Others">Others</option>
            </select>
          </div>
          <div className=" col-3">
            <label>Payment Amount</label>
            <input
              disabled
              onChange={(e) =>
                this.setState({ Payment_amount: e.target.value })
              }
              value={this.state.Payment_amount}
              className=" form-control "
              type="number"
            ></input>
          </div>
        </div>
        <div className=" row mt-1">
          <div className=" col-3">
            <label>Cheque/Transaction Details</label>
            <input
              disabled
              onChange={(e) =>
                this.setState({ cheque_details: e.target.value })
              }
              value={this.state.cheque_details}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Cheque/Transfer Date</label>
            <input
              disabled
              className="form-control"
              placeholder=""
              value={this.state.Cheque_Date}
              onChange={(event) => {
                this.handledateChange(event, "Cheque_Date");
              }}
            />
          </div>
          {/* <div className=" col-3">
            <label>Mobile No</label>
            <input
              disabled
              value={this.state.mobile_no}
              className=" form-control "
            ></input>
          </div> */}
          {/* <div className=" col-3">
            <label>Booking No</label>
            <input
              disabled
              value={this.state.booking_no}
              className=" form-control "
            ></input>
          </div> */}
        </div>
        {/* <div className=" row mt-3">
          <div className=" col-3">
            <label>Booking Date</label>
            <input
              disabled
              className="form-control"
              placeholder=""
              value={this.state.booking_date}
              onChange={(event) => {
                this.handledateChange(event, "booking_date");
              }}
            />
          </div>
          <div className=" col-3">
            <label>Customer Name </label>
            <input
              disabled
              onChange={(e) => this.setState({ Customer_Name: e.target.value })}
              value={this.state.Customer_Name}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Model Name</label>
            <input
              disabled
              onChange={(e) => this.setState({ Model_name: e.target.value })}
              value={this.state.Model_name}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Quantity</label>
            <input
              disabled
              onChange={(e) => this.setState({ Quantity: e.target.value })}
              value={this.state.Quantity}
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className=" row mt-3">
          <div className=" col-3">
            <label>Booking Amount</label>
            <input
              disabled
              onChange={(e) =>
                this.setState({ Booking_Adv_Amt: e.target.value })
              }
              value={this.state.Booking_Adv_Amt}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Total Amount</label>
            <input
              disabled
              onChange={(e) => this.setState({ Total_amount: e.target.value })}
              value={this.state.Total_amount}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Payable Amount</label>
            <input
              disabled
              onChange={(e) =>
                this.setState({ Payable_amount: e.target.value })
              }
              value={this.state.Payable_amount}
              className=" form-control "
            ></input>
          </div>
        </div> */}
      </div>
    );
  };
  SaveFunction = () => {
    this.setState({
      isDouble: true,
    });
    !(this.state.issaveClicked == "saveClcik") && this.gatePassNo();
    this.state.issaveClicked == "saveClcik" && this.handelSave("N");
  };

  ConfirmFunction = () => {
    this.setState({
      isDouble: true,
    });
    this.state.issaveClicked == "saveClcik" && this.handelConfirmed("N");
  };
  handelGatePassPrint = (showOptional) => {
    $("#payment_error_modal_2").modal("hide");
    this.props.history.push({
      pathname: Strings.Gate_Pass,
      state: {
        data: this.state.Gate_PassDetails,
        id: this.state.M8_ID,
        M_7id: this.state.M7_ID,
        modelid: this.props.modelid,
        Status: this.props.Status,
        name: this.props.name,
        showOptional:showOptional
      },
    });
  };
  handelPrintTaxInvoice = () => {
    $("#payment_error_modal_2").modal("hide");
    this.props.history.push({
      pathname: Strings.Quotation_Invoice,
      state: {
        data: this.state.invoiceDPrint,
        id: this.state.M8_ID,
        M_7id: this.state.M7_ID,
        modelid: this.props.modelid,
        Status: this.props.Status,
        name: this.props.name,
      },
    });
  };
  handelPrintForm22 = () => {
    $("#payment_error_modal_2").modal("hide");
    this.props.history.push({
      pathname: Strings.Form_22,
      state: {
        data: this.state.Gate_PassDetails,
        id: this.state.M8_ID,
        M_7id: this.state.M7_ID,
        modelid: this.props.modelid,
        Status: this.props.Status,
        name: this.props.name,
      },
    });
  };
  handelPrintForm21 = () => {
    $("#payment_error_modal_2").modal("hide");
    this.props.history.push({
      pathname: Strings.Form_21,
      state: {
        data: this.state.Gate_PassDetails,
        id: this.state.M8_ID,
        M_7id: this.state.M7_ID,
        modelid: this.props.modelid,
        Status: this.props.Status,
        name: this.props.name,
      },
    });
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Vehicle Billing</h4>
        <div className=" row justify-content-end mt-2 mb-1 ">
          <button
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={() => this.SaveFunction()}
            disabled={
              (this.state.Status != "Cancelled" ? false : true) ||
              this.state.isDouble ||
              this.state.M8_IsConfirm == "Y"
            }
          >
            {this.state.issaveloader && (
              <span
                class="spinner-grow spinner-grow-sm"
                style={{ color: "unset" }}
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Save
          </button>
          <button
            type="submit"
            className="btn Opertion_btn clickable mr-3"
            onClick={() => this.ConfirmFunction()}
            disabled={
              this.state.issaveClicked == "saveClcik" &&
              this.state.Status != "Cancelled" &&
              this.state.M8_IsConfirm == "N"
                ? false
                : true
            }
          >
            {this.state.isconfirmloader && (
              <span
                class="spinner-grow spinner-grow-sm"
                style={{ color: "unset", marginRight: "5px" }}
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Confirm
          </button>
          {/* <button
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={() => this.handelSave("Y")}
            disabled={
              (this.state.issaveClicked == "saveClcik" &&
              this.state.Status != "Cancelled"
                ? false
                : true)||this.state.isDouble
            }
          >
            {this.state.iscancelloader && (
              <span
                class="spinner-grow spinner-grow-sm"
                style={{ color: "unset" }}
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Cancel
          </button> */}
          <button
            hidden={this.state.issaveClicked == "pendingClick" ? true : false}
            type="submit"
            disabled={
              (this.state.M8_IsConfirm == "Y" &&
              this.state.Status != "Cancelled" &&
              this.state.Gate_PassDetails
                ? false
                : true) || this.state.isDouble
            }
            onClick={
              this.state.M8_IsConfirm != "N" &&
              this.state.Gate_PassDetails &&
              this.handelPrintTaxInvoice
            }
            className={"btn Opertion_btn clickable mr-3"}
          >
            Print Tax Invoice
          </button>
          <label
            hidden={this.state.issaveClicked == "pendingClick" ? true : false}
            type="submit"
            className=" mr-3 mt-2"
            style={
              (this.state.M8_IsConfirm != "N" && this.state.Gate_PassDetails)
                ? { color: "var(--main-bg-color)", fontWeight: "600"  }
                : { color: "var(--main-bg-color)", fontWeight: "600",cursor:"default" }
            }
            onClick={() => {
              if (
                this.state.M8_IsConfirm != "N" &&
                this.state.Gate_PassDetails
              ) {
                this.handelGatePassPrint(true);
              }
            }}
          >
            Print Gate Pass
          </label>
          <label
            hidden={this.state.issaveClicked == "pendingClick" ? true : false}
            type="submit"
            className=" mr-3 mt-2"
            style={
              (this.state.M8_IsConfirm != "N" && this.state.Gate_PassDetails)
                ? { color: "var(--main-bg-color)", fontWeight: "600"  }
                : { color: "var(--main-bg-color)", fontWeight: "600",cursor:"default" }
            }
            onClick={() => {
              if (
                this.state.M8_IsConfirm != "N" &&
                this.state.Gate_PassDetails
              ) {
                this.handelGatePassPrint(false);
              }
            }}
          >
            Customer Delivery Challan
          </label>
          <label
            hidden={this.state.issaveClicked=="pendingClick"?true:false}
            type="submit"
            className=" mr-3 mt-2"
            style={
              (this.state.M8_IsConfirm != "N" && this.state.Gate_PassDetails)
                ? { color: "var(--main-bg-color)", fontWeight: "600"  }
                : { color: "var(--main-bg-color)", fontWeight: "600",cursor:"default" }
            }
            onClick={() => {
              if (
                this.state.M8_IsConfirm != "N" &&
                this.state.Gate_PassDetails
              ) {
                this.handelPrintForm22()
              }
            }}
          >
            Form 22
          </label>
          <label
            hidden={this.state.issaveClicked=="pendingClick"?true:false}
            type="submit"
            className=" mr-3 mt-2"
            style={
              (this.state.M8_IsConfirm != "N" && this.state.Gate_PassDetails)
                ? { color: "var(--main-bg-color)", fontWeight: "600"  }
                : { color: "var(--main-bg-color)", fontWeight: "600",cursor:"default" }
            }
            onClick={() => {
              if (
                this.state.M8_IsConfirm != "N" &&
                this.state.Gate_PassDetails
              ) {
                this.handelPrintForm21();
              }             
            }}
          >
            Form 21
          </label>
        </div>
        <div class="card">
          <div class="card-header" id={"headingSix" + this.props.modelid}>
            <h2 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target={"#collapseSix" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseSix" + this.props.modelid}
              >
                Customer Information
              </button>
            </h2>
          </div>
          <div
            id={"collapseSix" + this.props.modelid}
            class="collapse"
            aria-labelledby={"headingSix" + this.props.modelid}
          >
            <div class="card-body">{this.Enquiry_Details()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"headingSeven" + this.props.modelid}>
            <h2 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target={"#collapseSeven" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseSeven" + this.props.modelid}
              >
                Model Details
              </button>
            </h2>
          </div>
          <div
            id={"collapseSeven" + this.props.modelid}
            class="collapse  "
            aria-labelledby={"headingSeven" + this.props.modelid}
          >
            <div class="card-body">{this.ModelDetails()}</div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id={"headingSix12" + this.props.modelid}>
            <h2 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target={"#collapseSix12" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseSix12" + this.props.modelid}
              >
                Booking Details
              </button>
            </h2>
          </div>
          <div
            id={"collapseSix12" + this.props.modelid}
            class="collapse  "
            aria-labelledby={"headingSix12" + this.props.modelid}
          >
            <div class="card-body">{this.bookingDetails()}</div>
          </div>
        </div>

        <div class="card">
          <div class="card-header" id={"headingFive" + this.props.modelid}>
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target={"#CollapseFive" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"CollapseFive" + this.props.modelid}
              >
                Accessories
              </button>
            </h5>
          </div>
          <div
            id={"CollapseFive" + this.props.modelid}
            class="collapse "
            aria-labelledby={"headingFive" + this.props.modelid}
          >
            <div style={{ overflowX: "scroll" }} class="card-body">
              <table
                className="table table-bordered mt-2"
                id="userList"
                align="center"
              >
                <thead>{this.accessoriesHeader()}</thead>
                <tbody>{this.accessoriesBody()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"heading12" + this.props.modelid}>
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target={"#collapse12" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapse12" + this.props.modelid}
              >
                Money Received Details
              </button>
            </h5>
          </div>
          <div
            id={"collapse12" + this.props.modelid}
            class="collapse"
            aria-labelledby={"heading12" + this.props.modelid}
          >
            <div class="card-body">{this.MoneyReceived()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"headingSeven12"+ this.props.modelid}>
            <h2 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target={"#collapseSeven12"+ this.props.modelid}
                aria-expanded="true show"
                aria-controls={"collapseSeven12"+ this.props.modelid}
              >
                Invoice Details
              </button>
            </h2>
          </div>
          <div
            id={"collapseSeven12"+ this.props.modelid}
            class="collapse show "
            aria-labelledby={"heading"+ this.props.modelid}
          >
            <div class="card-body">{this.VehicleInvoiceDetails()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"headingFour" + this.props.modelid}>
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target={"#collapseFour" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseFour" + this.props.modelid}
              >
                Rates & Discount
              </button>
            </h5>
          </div>
          <div
            id={"collapseFour" + this.props.modelid}
            class="collapse"
            aria-labelledby={"headingFour" + this.props.modelid}
          >
            <div class="card-body">{this.quotationSubmitted()}</div>
          </div>
        </div>
        {/* <Quotation_Invoice invoiceDPrint={this.state.invoiceDPrint}/> */}
        {/* <div >
          <Gate_Pass Gate_PassDetails={this.state.Gate_PassDetails} />
        </div> */}
      </div>
    );
  }
}
