import React from "react";
import "../../Files/files-styles.css";
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import Strings from "../../../../helpers/Strings";
import moment from "moment";

export class Quotation_M2 extends React.Component {
  constructor(props) {
    console.log(props?.location?.state?.data?.ID, "abc");
    super(props);
    this.state = {
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      vistitdetails: [],
      Customer_Name: props?.location?.state?.data?.Dealer_Name,
      Doc_No: props?.location?.state?.data?.Doc_No,
      M1ID: props?.location?.state?.data?.ID,
      Quotation_No: "",
      lead_no: "",
      finacial_year: "",
      TCS_Applicable: "",
      Deliver_Week: "",
      Competitor_Model: "",
      Competitor: "",
      Enquiry_No: "",
      Proposed_Discount: "",
      Standard_Discount: "",
      Likely_Buy_Date: "",
      Quotation_Cometitor: "",
      CompetitorOption: [],
      Model_Code_value: "",
      Model_Category_value: "",
      Quantity: "",
      QuotationId:0,
      visit_details:"",
      next_visit_details:"",
      DetailsDiscountAPP: [],
      Model_Id: "",
      DetailsFor: [
        {
          Value: "",
          Id: "",
          QuotID: "",
        },
        {
          Value: "",
          Id: "",
          QuotID: "",
        },
        {
          Value: "",
          Id: "",
          QuotID: "",
        },
        {
          Value: "",
          Id: "",
          QuotID: "",
        },
        {
          Value: "",
          Id: "",
          QuotID: "",
        },
      ],
      Model_Code_Option: [],
      Model_Category_Option: [],
      Model_Option: [],
    };
  }

  QuotationNumber = () => {
    this.setState({ leadno: "" });
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: "2324",
      sDocName: "Q",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res6500", res);
          this.setState(
            {
              lead_no: res[0].DocMaxNo,
            },
            () => {
              this.financiaryear();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  financiaryear = () => {
    const url = Urls.python_baseurl + "/SP_GetDataFromDataQuery";
    var data = JSON.stringify({
      ValGetDataQueryType: "15",
      iParentId: "126",
      sAdditionalCondition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65876", res);
          this.setState(
            {
              finacial_year: res[0].Value,
            },
            () => {
              this.delarcode();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  delarcode = () => {
    const url = Urls.python_baseurl + "/Get_Dealer_SP_GET_Dealercode";
    var data = JSON.stringify({
      DealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res6555", res);

          this.setState({
            delar_code: res[0].Dealer_vehicle_Code,
            Quotation_No:
              res[0].Dealer_vehicle_Code +
              "" +
              this.state.finacial_year +
              "" +
              this.state.lead_no,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  componentDidMount() {
    this.MasterAPICall();
    this.visit_details();
    this.QuotationNumber();
    this.GetCompetitor();
    this.GetAllQuotationDetails();
    this.GetModel();
    this.GetModelCategory();
    this.GetModelCode();
  }
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "D",
      DealerId: 126,
      SqlFor: "'M2'",
      HOBrID: 126,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Customer Code",
        field: "Customer Code",
        flex: "1",
        cellStyle: { cursor: "pointer" },
      },
      { headerName: "Customer Name", field: "Customer Name", flex: "1" },
      { headerName: "Address 1", field: "Address1", flex: "1" },
      { headerName: "Pincode", field: "Pincode", flex: "1" },
      { headerName: "mobile", field: "Mobile", flex: "1" },
      // {
      //   headerName: "", field: "pencil", width: 50,flex:1, cellRenderer: (data) => {
      //     console.log("data",data);
      //     // if(data.data){
      //           return <i class="fas fa-pencil-alt"></i>
      //     // }

      //   }, cellStyle: { cursor: 'pointer' }, pinned: 'right'
      // },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              console.log("event12", event.data);
              if (event.colDef.field == "Customer Code") {
                this.setState(
                  {
                    id: event.data.ID,
                  },
                  () => {
                    this.customer_display();
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  setValue = (key, value) => {
    const newState = { ...this.state };
    newState["DetailsFor"][key].Value = value;
    this.setState(newState, () => {
      if (key == 3) {
        this.setValue(
          4,
          this.state.DetailsFor[2]?.Value - this.state.DetailsFor[3]?.Value
        );
        this.setValue(
          1,
          this.state.DetailsFor[0]?.Value - this.state.DetailsFor[2]?.Value
        );
      }
    });
  };
  GetModelCode = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 198,
      ID: 0,
      condition: "'and Model_cat_ID=1002'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Code_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 94,
      ID: 0,
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Category_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModel = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 197,
      ID: 0,
      condition: "'and Model_cat_ID=1002'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelDetails = () => {
    const url = Urls.python_baseurl + "/model_master_SP_ModelMaster_details";
    var data = JSON.stringify({
      model_id: `'${this.state.Model_Id}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          console.log(res, "mpop");
          this.setState({
            Model_Category_value: res[0]?.Model_cat_ID,
            Model_Code_value: this.state.Model_Code_Option.filter(
              (e) => e.Name == res[0]?.Model_code
            )[0]?.ID,
            Model: this.state.Model_Option.filter(
              (e) => e.Name == res[0]?.model_name
            )[0]?.ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  detailsForDiscountApproval = () => {
    return (
      <div>
        <table style={{ width: "100%" }}>
          <thead className="p-3">
            <tr
              style={{
                backgroundColor: "var(--main-bg-color)",
                color: "white",
              }}
            >
              <th style={{ padding: "10px" }}>Description</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr className="mt-2" style={{ borderBottom: "1px solid #ddd" }}>
              <td>Vehicle Price (MRP)</td>
              <td>
                <input
                  readOnly
                  style={{ width: "200px" }}
                  className="form-control"
                  type="number"
                  value={this.state.DetailsFor[0]?.Value}
                />
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid #ddd" }}>
              <td>Customer Proposed Price</td>
              <td>
                <input
                  readOnly
                  style={{ width: "200px" }}
                  className="form-control"
                  type="number"
                  value={
                    this.state.DetailsFor[0]?.Value -
                    this.state.DetailsFor[2]?.Value
                  }
                />
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid #ddd" }}>
              <td>Discount Offered</td>
              <td>
                <input
                  onChange={(e) => this.setValue(2, e.target.value)}
                  style={{ width: "200px" }}
                  className="form-control"
                  type="number"
                  value={this.state.DetailsFor[2]?.Value}
                />
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid #ddd" }}>
              <td>Dealer Share</td>
              <td>
                <input
                  style={{ width: "200px" }}
                  className="form-control"
                  type="number"
                  value={this.state.DetailsFor[3]?.Value}
                  onChange={(e) => this.setValue(3, e.target.value)}
                />
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid #ddd" }}>
              <td>Company Share</td>
              <td>
                <input
                  style={{ width: "200px" }}
                  className="form-control"
                  type="number"
                  value={
                    this.state.DetailsFor[2]?.Value -
                    this.state.DetailsFor[3]?.Value
                  }
                  readOnly
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  showModel = () => {
    return (
      <div className=" row">
        <div className=" col-3">
          <label htmlFor="input-1">Model Category {this.required()}</label>
          <select
            value={this.state.Model_Category_value}
            readOnly
            id="input-1"
            className=" form-control "
          >
            <option>Select</option>
            {this.state.Model_Category_Option.map((e) => {
              return <option value={e.ID}>{e.Name}</option>;
            })}
          </select>
        </div>
        <div className=" col-3">
          <label htmlFor="input-2">Model Code {this.required()}</label>
          <select
            value={this.state.Model_Code_value}
            readOnly
            id="input-2"
            className=" form-control "
          >
            <option>Select</option>
            {this.state.Model_Code_Option.map((e) => {
              return <option value={e.ID}>{e.Name}</option>;
            })}
          </select>
        </div>
        <div className=" col-3">
          <label htmlFor="input-3">Model {this.required()}</label>
          <select
            value={this.state.Model}
            readOnly
            id="input-3"
            className=" form-control "
          >
            <option>Select</option>
            {this.state.Model_Option.map((e) => {
              return <option value={e.ID}>{e.Name}</option>;
            })}
          </select>
        </div>
        <div className=" col-3">
          <label htmlFor="input-3">Quantity {this.required()}</label>
          <input
            value={this.state.Quantity}
            readOnly
            id="input-3"
            className=" form-control "
            type="number"
          ></input>
        </div>
      </div>
    );
  };
  clickNavigation = (event) => {
    this.props.history.push("/Enquiry", {});
  };
  handelSave = () => {
    const url = Urls.python_baseurl + "/save_Quotation_SP_Save_M2Header";
    var data = JSON.stringify({
      iID: 0,
      dealerID: parseInt(sessionStorage.getItem("dealerid")),
      HOBrID: parseInt(sessionStorage.getItem("dealerid")),
      QutNo: `'${this.state.Quotation_No}'`,
      QutDate: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      RFPNo: "''",
      RFPDate: "''",
      RFPGen: "''",
      Cancel: "'N'",
      Confirm: "'Y'",
      OrderStatus: "'N'",
      M1ID: this.state.M1ID,
      M2No: `'${this.state.Quotation_No}'`,
      M2Date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      Competitor: parseInt(this.state.Competitor),
      CompModel: `'${this.state.Competitor_Model}'`,
      CompDiscamt: `''`,
      LikelyBuyDate: `'${moment(this.state.Likely_Buy_Date).format(
        "DD-MM-YYYY"
      )}'`,
      LossAppNo: `''`,
      Modelcat: 1,
      model: 1,
      Qty: 1,
      DelWeeks: this.state.Deliver_Week,
      TCSApp: `'${this.state.TCS_Applicable}'`,
      StandardDisc: `'${this.state.Standard_Discount}'`,
    });
    console.log("newdata", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("newdt", res[0]);
        if (res && res.length > 0) {
          if (res[0].newID) {
            this.setState({
              M2save_Id:res[0].newID,
            },()=>{
              this.visit_details_save()
            })
            for (let i = 0; i < this.state.DetailsFor?.length; i++) {
              console.log("popop", i);
              const url_1 =
                Urls.python_baseurl +
                "/save_quotation_SP_SaveM2QuotationDetails";
              var data_1 = JSON.stringify({
                ID: "0",
                UserId: sessionStorage.getItem("dealerid"),
                HOBrID: sessionStorage.getItem("dealerid"),
                M2ID: `${res[0].newID}`,
                QuotId: `${this.state.DetailsFor[i]?.QuotId}`,
                Value: `${this.state.DetailsFor[i].Value}`,
                loopflag: i,
                RFPNo: "''",
                Confirm: "'Y'",
                StandardDisc: `'${this.state.Standard_Discount}'`,
              });
              fetch(url_1, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: data_1,
              })
                .then((res) => res.json())
                .then((res) => {
                  console.log(res, "mnm");
                  if (res && res.length > 0) {
                    if (res[0].newID) {
                    }
                  }
                })
                .catch((err) => {
                  process.env.NODE_ENV == "development" && console.log(err);
                });
            }
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
  };
  visit_details_save = (event) => {
    const url = Urls.python_baseurl + "/Save_mo_SP_Save_M0Objectives";
    var data = JSON.stringify({
      
        "ID": "0",
        "UserId": sessionStorage.getItem('UserId'),
        "HOBrID": sessionStorage.getItem('UserId'),
        "Lead_hdr_ID": parseInt(this.state.M2save_Id),
        "obj_Id": this.state.visit_details,
        "obj_date": `'${moment(this.state.startDate).format("DD/MM/YYYY")}'`,
        "discussion": "''",
        "time_spent": "''",
        "next_obj_Id": this.state.next_visit_details,
        "next_date": `'${moment(this.state.endDate).format("DD/MM/YYYY")}'`,
        "commit_det": "''"
    });
    console.log("newdata",data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if(res[0].newID){
            this.maxNo()  
          }
        }

      })
      .catch(err => {
        process.env.NODE_ENV == 'development' && console.log(err)
      });
     
  }
  maxNo = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      "FinYear": "'2324'",
        "DocName": "'Q'",
        "DealerId": sessionStorage.getItem("dealerid")
    });
    console.log("newdata",data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if(res[0].ID){
            alert("Record Saved Succesfully");
            sessionStorage.setItem("ActiveTab","Quotation")
        this.props.history.push("/tiles_tabs")
          }
        }

      })
      .catch(err => {
        process.env.NODE_ENV == 'development' && console.log(err)
      });
     
  }
  Validate = () => {
    let isValid =
      this.state.Likely_Buy_Date.trim() !== "" &&
      this.state.Proposed_Discount.trim() !== "" &&
      this.state.Competitor.trim() !== "" &&
      this.state.Competitor_Model.trim() !== "" &&
      this.state.TCS_Applicable.trim() !== "";

    if (isValid) {
      this.handelSave();
    } else {
      alert("Please fill all mandatory field.");
    }
  };
  quotationSubmitted = () => {
    return (
      <div>
        <div style={{marginTop:"-10px"}} className=" row justify-content-end ">
          <button
            type="submit"
            className="btn Opertion_btn clickable mr-3"
            style={{ fontSize: "12px", float: "right", clear: "both" }}
            onClick={this.Validate}
          >
            Save
          </button>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Quotation No</label>
            <input
              readOnly
              value={this.state.Quotation_No}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Quotation Date {this.required()}</label>
            <input
              readOnly
              value={moment(new Date()).format("DD-MM-YYYY")}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Customer Name </label>
            <input
              onChange={(e) => this.setState({ Customer_Name: e.target.value })}
              value={this.state.Customer_Name}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Likely Buy Date {this.required()}</label>
            <input
              onChange={(e) =>
                this.setState({ Likely_Buy_Date: e.target.value })
              }
              type="date"
              value={this.state.Likely_Buy_Date}
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Standard Discount</label>
            <input
              readOnly
              value={this.state.Standard_Discount}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Proposed Discount {this.required()}</label>
            <input
              onChange={(e) =>
                this.setState({ Proposed_Discount: e.target.value })
              }
              value={this.state.Proposed_Discount}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Enquiry No</label>
            <input
              readOnly
              value={this.state.Doc_No}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Competitor {this.required()}</label>
            <select
              onChange={(e) => this.setState({ Competitor: e.target.value })}
              value={this.state.Competitor}
              className=" form-control "
            >
              <option>Select</option>
              {this.state.CompetitorOption.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })}
            </select>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Competitor Model {this.required()}</label>
            <input
              onChange={(e) =>
                this.setState({ Competitor_Model: e.target.value })
              }
              value={this.state.Competitor_Model}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Delivery Week</label>
            <input
              onChange={(e) => this.setState({ Deliver_Week: e.target.value })}
              value={this.state.Deliver_Week}
              className=" form-control "
              type="number"
            ></input>
          </div>
          <div className=" col-3">
            <label>TCS Applicable {this.required()}</label>
            <select
              onChange={(e) =>
                this.setState({ TCS_Applicable: e.target.value })
              }
              value={this.state.TCS_Applicable}
              className=" form-control "
            >
              <option>Select</option>
              <option>Y</option>
              <option>N</option>
            </select>
          </div>
        </div>
      </div>
    );
  };
  visit_details = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "159",
      ID: "'0'",
      condition: "\"and stage='M0'\"",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            vistitdetails: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  vistitdetails = (event) => {
    let row = [];
    this.state.vistitdetails.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  onSelect = (event, key) => {
    console.log("event", event, key);
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
  };
  handledateChange = (event, key) => {
    console.log("key", event.target.value, key);
    this.setState({
      [key]: event.target.value,
    });
  };
  visitDetails = () => {
    return (
      <div class="row">
        <div className=" col-3">
          <label htmlFor="visit-details">Visit Details</label>
          <select
            className="form-control"
            value={this.state.visit_details}
            onChange={(event) => {
              this.onSelect(event, "visit_details");
            }}
          >
            <option value="0"></option>
            {this.vistitdetails()}
          </select>
        </div>
        <div className=" col-3">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            className="form-control"
            placeholder=""
            value={this.state.startDate}
            onChange={(event) => {
              this.handledateChange(event, "startDate");
            }}
          />
        </div>
        <div className=" col-3">
          <label htmlFor="new-visit-details">New Visit Details</label>
          <select
            className="form-control"
            value={this.state.next_visit_details}
            onChange={(event) => {
              this.onSelect(event, "next_visit_details");
            }}
          >
            <option value="0"></option>
            {this.vistitdetails()}
          </select>
        </div>
        <div className=" col-3">
          <label htmlFor="date2">Date</label>
          <input
            type="date"
            className="form-control"
            placeholder=""
            value={this.state.endDate}
            onChange={(event) => {
              this.handledateChange(event, "endDate");
            }}
          />
        </div>
      </div>
    );
  };
  handelCancel = () => {
    alert("Are you sure you want to delet this record.");
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };

  GetAllQuotationDetails = () => {
    const url = Urls.python_baseurl + "/quotation_display_SP_GetM2";
    var data = JSON.stringify({
      POId: "0",
      POType: "'New'",
      DealerID: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      iM1ID: this.state.M1ID,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res,"lkl")
        if (res && res.length > 0) {
          this.setState(
            {
              Quantity: res[0][0].Qty,
              Model_Id: res[0][0].Model_ID,
            },
            () => {
              const setValue = (key, value) => {
                const newState = { ...this.state };
                newState["DetailsFor"][key] = value;
                this.setState(newState);
              };
              setValue(0, res[3][0]);
              setValue(1, res[3][1]);
              setValue(2, res[3][2]);
              setValue(3, res[3][3]);
              setValue(4, res[3][4]);
              this.GetModelDetails();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetCompetitor = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "156",
      ID: "'0'",
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pio");
        if (res && res.length > 0) {
          this.setState({
            CompetitorOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Quotation</h4>
        {/* <div class="card">
                    <div class="card-header" id="headingFour">
                        <h2 class="mb-0">
                            <button
                                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                class="btn"
                                data-toggle="collapse"
                                data-target="#collapseFour"
                                aria-expanded="true"
                                aria-controls="collapseFour"
                            >
                                 QUOTATION LIST
                            </button>

                        </h2>
                    </div>

                    <div id="collapseFour" class="collapse " aria-labelledby="headingFour" data-parent="#accordionExample">

                        <div class="card-body">{this.masterTable()}</div>
                    </div>
                </div> */}
        <div class="card mt-4">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                Model
              </button>
            </h5>
          </div>
          <div
            id="collapseThree"
            class="collapse show "
            aria-labelledby="headingThree"
          >
            <div class="card-body">{this.showModel()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFour">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                Quotation Submitted
              </button>
            </h5>
          </div>
          <div
            id="collapseFour"
            class="collapse show "
            aria-labelledby="headingFour"
          >
            <div class="card-body">{this.quotationSubmitted()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFive">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#CollapseFive"
                aria-expanded="true"
                aria-controls="CollapseFive"
              >
                Visit Details
              </button>
            </h5>
          </div>
          <div
            id="CollapseFive"
            class="collapse show "
            aria-labelledby="headingFive"
          >
            <div class="card-body">{this.visitDetails()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingSix">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#CollapseSix"
                aria-expanded="true"
                aria-controls="CollapseSix"
              >
                Details for Discount Approval
              </button>
            </h5>
          </div>
          <div
            id="CollapseSix"
            class="collapse show "
            aria-labelledby="headingSix"
          >
            <div class="card-body">{this.detailsForDiscountApproval()}</div>
          </div>
        </div>
      </div>
    );
  }
}
