import React from "react";
import "../../Files/files-styles.css";
// import '../Claims/Dashboard/styles.css'
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import DatePicker from "react-datepicker";
import moment from "moment";
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import Strings from "../../../../helpers/Strings";
// import { post } from 'axios';
const $ = window.$;
export class Lead extends React.Component {
  constructor(props) {
    console.log(props?.LeadDetails?.District_ID, "props");
    super(props);
    this.state = {
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      ethnicity_map: [],
      Ethnicity: [],
      leadno: props?.LeadDetails?.Tr_Num ? props?.LeadDetails?.Tr_Num : 0,
      Title: props?.LeadDetails?.Prefix ? props?.LeadDetails?.Prefix : "",
      Type: props?.LeadDetails?.type_flag_id
        ? props?.LeadDetails?.type_flag_id
        : "",
      Name: "",
      M0_id: 0,
      address1: "",
      address2: "",
      ext_mit_cust: "",
      Date_1: "",
      Date_2: "",
      pincode: "",
      stateid: "",
      district_name: "",
      State: props?.LeadDetails?.State_ID ? props?.LeadDetails?.State_ID : 0,
      District: props?.LeadDetails?.District_ID
        ? props?.LeadDetails?.District_ID
        : 0,
      primary_application: 0,
      secondary_application: 0,
      CRMCustID: 0,
      newID_crm_id: 0,
      startDate: "",
      total_no_fleet: "",
      kam_customer: "",
      call_ticket_number: "",
      call_ticket_date: "",
      visit_details: "",
      discussion: "",
      next_visit_details: "",
      HiPaaS_ID: "",
      date: "",
      email: "",
      Mobileno: "",
      newID_crm: "",
      newdate: "",
      time_spent: "",
      Commitment: "",
      loaderforupload: false,

      City: "",
      financiar: "",
      finacial_year: "",
      delar_code: "",
      lead_no: "",
      Country: "INDIA",
      Country_id: 1,
      newID: 0,
      lead_nodoc: "",
      leadno_new: "",
      leadType: [],
      leaddistrict: [],
      leadstate: [],
      leadregion: [],
      vistitdetails: [],
      Financier_customer: [],
      rowData: [],
      leadtown: [],
      DocName: "M0",
      tableData: [
        { id: 1, name: "Item 1", quantity: 0 },
        { id: 2, name: "Item 2", quantity: 0 },
        { id: 3, name: "Item 3", quantity: 0 },
      ],
      Doc_Date: props?.LeadDetails?.M0_Date,
      email: props?.LeadDetails?.E_mail,
      Country: props?.LeadDetails?.Country_Id,
      Region: props?.LeadDetails?.Region_ID ? props?.LeadDetails?.Region_ID : 0,
      Town: props?.LeadDetails?.taluka_id ? props?.LeadDetails?.taluka_id : 0,
      // District:props?.LeadDetails?.District_ID,
      // State:props?.LeadDetails?.State_ID,
      City: props?.LeadDetails?.CITY,
      pincode: props?.LeadDetails?.PIN,
      address2: props?.LeadDetails?.add2,
      address1: props?.LeadDetails?.add1,
      Mobileno: props?.LeadDetails?.mobile,
      Name: props?.LeadDetails?.name,
    };
  }

  componentDidMount() {
    this.MasterAPICall();
    this.Lead_type();
    {
      this.state.leadno === 0 && this.createleadno();
    }
    {
      (!(this.state.State === 0) || this.state.State === 0) &&
        this.lead_state();
    }
    // this.visit_details()
    {
      (!(this.state.District === 0) || this.state.District === 0) &&
        this.lead_district_new();
    }
    // this.Financiercustomer()
    {
      (!(this.state.Region === 0) || this.state.Region === 0) &&
        this.lead_region();
    }
    {
      this.state.Town != 0 && this.lead_town();
    }
  }
  lead_district_new = () => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: this.state.State,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res657878", res);
          this.setState({
            leaddistrict: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "D",
      DealerId: sessionStorage.getItem("dealerid"),
      SqlFor: "'M0Master'",
      HOBrID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              rowData: res[0],
            },
            () => {
              console.log("rowData", this.state.rowData);
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  onChangeName(event, key) {
    console.log("even", key, event.target.value);
    this.setState(
      {
        [key]: event.target.value,
      },
      () => {
        if (key === "Mobileno" && event.target.value.length == 10) {
          const url =
            Urls.python_baseurl +
            "/get_customer_byMobile_sp_Customerwise_mobile";
          var data = JSON.stringify({
            mobile: `'${event.target.value}'`,
          });
          fetch(url, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: data,
          })
            .then((res) => res.json())
            .then((res) => {
              if (res && res.length > 0) {
                console.log("res657878", res);
                this.setState(
                  {
                    Doc_Date: res[0].Customer_name,
                    Mobileno: res[0].Mobile,
                    email: res[0].E_mail,
                    Type: res[0].Type,

                    // Country:,
                    Region: res[0].Region_id,
                    // Town:,
                    District: res[0].District_ID,
                    State: res[0].state_id,
                    City: res[0].city,
                    pincode: res[0].pincode,
                    address2: res[0].add2,
                    address1: res[0].add1,
                    Mobileno: res[0].mobile,
                    Name: res[0].Customer_name,
                  },
                  () => {
                    this.lead_region();
                    this.lead_district_new();
                  }
                );
              }
            })
            .catch((err) => {
              process.env.NODE_ENV == "development" && console.log(err);
            });
        }
      }
    );
  }
  getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };
  ChangeVal=(event, key) =>{
    console.log("kkkk", key, event.target.value);
    this.setState({
      [key]: event.target.value,
    });
    if (key === "State") {
      for (let index = 0; index < this.state.leadstate.length; index++) {
        if (this.state.leadstate[index].ID === parseInt(event.target.value)) {
          this.setState(
            {
              stateid: this.state.leadstate[index].Name,
            },
            () => {
              this.binddata();
            }
          );
        }
      }
    }
    if (key == "District") {
      console.log("gh", this.state.leaddistrict.length);
      for (let index = 0; index < this.state.leaddistrict.length; index++) {
        console.log(
          "hjjhb",
          this.state.leaddistrict[index].ID,
          parseInt(event.target.value)
        );
        if (
          this.state.leaddistrict[index].ID === parseInt(event.target.value)
        ) {
          this.setState(
            {
              district_name: this.state.leaddistrict[index].Name,
            },
            () => {
              console.log("Regionid", this.state.district_name);
              this.lead_town();
            }
          );
        }
      }
    }
  }
  handleStartChange = (date) => {
    console.log("date", date);
    this.setState({
      startDate: date,
    });
  };

  onSelect = (event, key) => {
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
  };
  handledateChange = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  };
  createleadno = () => {
    this.Lead_no();
  };
  Lead_type = () => {
    const url = Urls.python_baseurl + "/sp_GetCustType";
    var data = JSON.stringify({
      Type: "C",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res);
          this.setState({
            leadType: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Lead_no = () => {
    this.setState({ leadno: "" });
    if (this.state.DocName === "M0") {
      const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
      var data = JSON.stringify({
        sFinancialYear: "2324",
        sDocName: this.state.DocName,
        iDealerID: sessionStorage.getItem("dealerid"),
      });
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.length > 0) {
            console.log("res6500", res);
            this.setState(
              {
                lead_no: res[0].DocMaxNo,
              },
              () => {
                this.financiaryear();
              }
            );
          }
        })
        .catch((err) => {
          process.env.NODE_ENV == "development" && console.log(err);
        });
    } else {
      const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
      var data = JSON.stringify({
        sFinancialYear: "2324",
        sDocName: this.state.DocName,
        iDealerID: sessionStorage.getItem("dealerid"),
      });
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.length > 0) {
            console.log("res6500", res);
            this.setState(
              {
                lead_nodoc: res[0].DocMaxNo + 1,
              },
              () => {
                this.financiaryear();
              }
            );
          }
        })
        .catch((err) => {
          process.env.NODE_ENV == "development" && console.log(err);
        });
    }
  };
  financiaryear = () => {
    const url = Urls.python_baseurl + "/SP_GetDataFromDataQuery";
    var data = JSON.stringify({
      ValGetDataQueryType: "15",
      iParentId: sessionStorage.getItem("dealerid"),
      sAdditionalCondition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65876", res);
          this.setState(
            {
              finacial_year: res[0].Value,
            },
            () => {
              this.delarcode();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  delarcode = () => {
    const url = Urls.python_baseurl + "/Get_Dealer_SP_GET_Dealercode";
    var data = JSON.stringify({
      DealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res6555", res);
          if (this.state.DocName === "CRMPE") {
            this.setState(
              {
                delar_code: res[0].Dealer_vehicle_Code,
                leadno_new:
                  res[0].Dealer_vehicle_Code +
                  "" +
                  "CRMPE" +
                  "" +
                  this.state.finacial_year +
                  "" +
                  this.state.lead_nodoc,
              },
              () => {
                this.lead_CRM_save();
              }
            );
          } else {
            this.setState({
              delar_code: res[0].Dealer_vehicle_Code,
              leadno:
                res[0].Dealer_vehicle_Code +
                "" +
                "M0" +
                "" +
                this.state.finacial_year +
                "" +
                this.state.lead_no,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  binddata = () => {
    this.lead_region();
    this.lead_district();
  };
  lead_town = () => {
    const url = Urls.python_baseurl + "/Display_Tehsil_sp_tehsildisplay";
    var data = JSON.stringify({
      state_name: this.state.stateid,
      district_name: this.state.district_name,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("re6578788", res);
          this.setState({
            leadtown: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_region = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: this.state.State,
      type: "Region",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res[0].Name);
          this.setState({
            leadregion: res,
            Region: res[0].ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_district = () => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: this.state.State,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res657878", res);
          this.setState({
            leaddistrict: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_state = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: "0",
      type: "'State'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res);
          this.setState({
            leadstate: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  visit_details = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "159",
      ID: "'0'",
      condition: "\"and stage='M0'\"",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res);
          this.setState({
            vistitdetails: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Financiercustomer = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "83",
      ID: "0",
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65", res);
          this.setState({
            Financier_customer: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  leadType = (event) => {
    let row = [];
    this.state.leadType.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  leadno = (event) => {
    let row = [];
    this.state.leadno.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.DocMaxNo}>{element.DocMaxNo}</option>);
    });
    return row;
  };
  leaddistrict = (event) => {
    let row = [];
    this.state.leaddistrict.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  leadtown12 = (event) => {
    let row = [];
    this.state.leadtown.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Town_Name}</option>);
    });
    return row;
  };
  vistitdetails = (event) => {
    let row = [];
    this.state.vistitdetails.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  leadstate = (event) => {
    let row = [];
    this.state.leadstate.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  leadregion = (event) => {
    let row = [];
    this.state.leadregion.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  Financier_customer = (event) => {
    let row = [];
    this.state.Financier_customer.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  visitDetails = () => {
    return (
      <div class="row">
        <div className=" col-3">
          <label htmlFor="visit-details">Visit Details</label>
          <select
            className="form-control"
            value={this.state.visit_details}
            onChange={(event) => {
              this.onSelect(event, "visit_details");
            }}
          >
            <option value="0"></option>
            {this.vistitdetails()}
          </select>
        </div>
        <div className=" col-3">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            id="date"
            className=" form-control "
            value={this.state.Date_1}
            onChange={(event) => {
              this.handledateChange(event, "Date_1");
            }}
          ></input>
        </div>
        <div className=" col-3">
          <label htmlFor="new-visit-details">New Visit Details</label>
          <select
            className="form-control"
            value={this.state.next_visit_details}
            onChange={(event) => {
              this.onSelect(event, "next_visit_details");
            }}
          >
            <option value="0"></option>
            {this.vistitdetails()}
          </select>
        </div>
        <div className=" col-3">
          <label htmlFor="date2">Date</label>
          <input
            type="date"
            id="date2"
            className=" form-control "
            value={this.state.Date_2}
            onChange={(event) => {
              this.handledateChange(event, "Date_2");
            }}
          ></input>
        </div>
      </div>
    );
  };
  masterTable() {
    let columnDefs = [
      { headerName: "M0 Number", field: "M0 Number", flex: "1" },
      { headerName: "M0 Date", field: "M0 Date", flex: "1" },
      { headerName: "Type", field: "Type", flex: "1" },
      { headerName: "Name", field: "Name", flex: "1" },
      { headerName: "City", field: "City", flex: "1" },
      { headerName: "State", field: "State", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {}}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  renderMemberinfo() {
    return (
      <div>
        <div>
          {/* <div class="card">
                        <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                                <button
                                    style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                    class="btn"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="true"
                                    aria-controls="collapseThree"
                                >
                                    LEAD DETAILS
                                </button>
                            </h2>
                        </div>

                        <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                            <div class="card-body">
                            {this.masterTable()}
                            </div>
                        </div>
                    </div> */}
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  LEAD DETAILS
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label>Doc No </label>
                    <input
                      disabled={true}
                      value={this.state.leadno}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  {/* <div class="form-group col-md-3">
                                        <label>Lead No</label>
                                        <select className="form-control " value={this.state.Type} onChange={(event) => this.ChangeVal(event, 'Type')}>
                                            <option value="0"></option>
                                            {this.leadno()}
                                        </select>
                                    </div> */}
                  <div class="form-group col-md-3">
                    <label>Doc Date {this.required()}</label>
                    <input
                      disabled={true}
                      className="form-control"
                      value={
                        this.state.Doc_Date
                          ? this.state.Doc_Date
                          : moment(new Date()).format("DD/MM/YYYY")
                      }
                      // onChange={(event) => { this.handledateChange(event, 'leaddate') }}
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Type{this.required()}</label>
                    <select
                      className="form-control "
                      value={this.state.Type}
                      onChange={(event) => this.ChangeVal(event, "Type")}
                    >
                      <option value="0"></option>
                      {this.leadType()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Title{this.required()}</label>
                    <select
                      className="form-control "
                      value={this.state.Title}
                      onChange={(event) => this.ChangeVal(event, "Title")}
                    >
                      <option value="0"></option>
                      <option value="1">Mr</option>
                      <option value="2">Miss</option>
                    </select>
                  </div>

                  <div class="form-group col-md-3">
                    <label>Name{this.required()}</label>
                    <input
                      value={this.state.Name}
                      onChange={(e) => this.onChangeName(e, "Name")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Mobile{this.required()}</label>
                    <input
                      value={this.state.Mobileno}
                      onChange={(e) => this.onChangeName(e, "Mobileno")}
                      maxlength="10"
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>

                  <div class="form-group col-md-3">
                    <label>Address 1{this.required()}</label>
                    <input
                      value={this.state.address1}
                      onChange={(e) => this.onChangeName(e, "address1")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Address 2</label>
                    <input
                      value={this.state.address2}
                      onChange={(e) => this.onChangeName(e, "address2")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>

                  <div class="form-group col-md-3">
                    <label>Pincode{this.required()}</label>
                    <input
                      value={this.state.pincode}
                      onChange={(e) => this.onChangeName(e, "pincode")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>City{this.required()}</label>
                    <input
                      value={this.state.City}
                      onChange={(e) => this.onChangeName(e, "City")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>State{this.required()}</label>
                    <select
                      className="form-control "
                      value={this.state.State}
                      onChange={(event) => {
                        this.ChangeVal(event, "State")
                      }}
                    >
                      <option value="0"></option>
                      {this.leadstate()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>District{this.required()}</label>
                    <select
                      className="form-control "
                      value={this.state.District}
                      onChange={(event) => this.ChangeVal(event, "District")}
                    >
                      <option value="0"></option>
                      {this.leaddistrict()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Taluka</label>
                    <select
                      className="form-control "
                      value={this.state.Town}
                      onChange={(event) => this.ChangeVal(event, "Town")}
                    >
                      <option value="0"></option>
                      {this.leadtown12()}
                    </select>
                  </div>

                  <div class="form-group col-md-3">
                    <label>Region</label>
                    <select
                      className="form-control "
                      value={this.state.Region}
                      onChange={(event) => this.ChangeVal(event, "Region")}
                    >
                      <option value="0"></option>
                      {this.leadregion()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Country </label>
                    <input
                      disabled={true}
                      value={"INDIA"}
                      onChange={(e) => this.onChangeName(e, "Country")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  {/* <div class="form-group col-md-3">
                                        <label>Existing Company Customer{this.required()}</label>
                                        <select className="form-control " value={this.state.ext_mit_cust} onChange={(event) => this.ChangeVal(event, 'ext_mit_cust')}>
                                            <option value="0"></option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                            
                                        </select>                   
                                     </div> */}

                  <div class="form-group col-md-3">
                    <label>Email</label>
                    <input
                      value={this.state.email}
                      onChange={(e) => this.onChangeName(e, "email")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  {/* <div class="form-group col-md-3">
                                        <label>Primary Application</label>
                                        <select className="form-control " value={this.state.primary_application} onChange={(event) => this.ChangeVal(event, 'primary_application')}>
                                            <option value="0"></option>
                                            {this.TransactionType1()}
                                        </select>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>Secondary Application</label>
                                        <select className="form-control " value={this.state.secondary_application} onChange={(event) => this.ChangeVal(event, 'secondary_application')}>
                                            <option value="0"></option>
                                            {this.TransactionType1()}
                                        </select>
                                    </div> */}
                  {/* <div class="form-group col-md-3">
                                        <label>Financier Of The Customer</label>
                                        <select className="form-control " value={this.state.financiar} onChange={(event) => this.ChangeVal(event, 'financiar')}>
                                            <option value="0"></option>
                                            {this.Financier_customer()}
                                        </select>
                                    </div> */}

                  {/* <div class="form-group col-md-3">
                                        <label>Body Builder Details</label>
                                        <input value={this.state.body_builder} onChange={(e) => this.onChangeName(e, 'body_builder')} class="form-control" placeholder=""></input>
                                    </div> */}
                  {/* <div class="form-group col-md-3">
                                        <label>Last Purchase Date</label>
                                        <input type="date" className="form-control" placeholder=''
                                            value={this.state.startDate}
                                            onChange={(event) => { this.handledateChange(event, 'startDate') }} />
                                    </div> */}
                  {/* <div class="form-group col-md-3">
                                        <label>Total Number Of Fleet</label>
                                        <input  value={this.state.total_no_fleet} onChange={(e) => this.onChangeName(e, 'total_no_fleet')} class="form-control" placeholder=""></input>
                                    </div> */}
                  {/* <div class="form-group col-md-3">
                                        <label>KAM Customer</label>
                                        <select className="form-control " value={this.state.kam_customer} onChange={(event) => this.ChangeVal(event, 'kam_customer')}>
                                            <option value="0"></option>
                                            <option value="yes">Y</option>
                                            <option value="no">N</option>
                                            
                                        </select>      
                                    </div> */}
                  {/* <div class="form-group col-md-2">
                                        <label>Call Ticket Number</label><br></br>
                                        <input value={this.state.call_ticket_number} onChange={(e) => this.onChangeName(e, 'Error_Message')} class="form-control" placeholder=""></input>
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label>Call Ticket Date</label><br></br>
                                        <DatePicker   className="form-control list-dashboard"
                                            selected={this.state.call_ticket_date?new Date(moment(this.state.call_ticket_date).format('YYYY-MM-DD hh:mm')):""}
                                            onChange={this.handlecall_ticket_dateChange}
                                        />                                    
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                            <button
                                    style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                    class="btn"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                >
                                     VISIT DETAILS
                                </button>
                                
                            </h2>
                        </div>
                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="card-body">
                            {this.visitDetails()}
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
    );
  }
  clickNavigation = (event) => {
    this.props.history.push("/Enquiry", {});
  };
  validateForm = () => {
    let formIsValid = true;
    if (this.state.email != "") {
      //regular expression for email validation
      var pattern =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (!pattern.test(this.state.email)) {
        formIsValid = false;
        alert("Please Enter Valid Email Id.");
      }
    }
    return formIsValid;
  };

  Validate = () => {
    let isValid =
      this.state.Type !== "" &&
      this.state.Title !== "" &&
      this.state.address1 !== "" &&
      this.state.Name !== "" &&
      this.state.City !== "" &&
      this.state.State !== "" &&
      this.state.Mobileno !== "" &&
      this.state.District !== "";
    // this.state.ext_mit_cust.trim()!=="";
    if (isValid) {
      this.saveLead();
    } else {
      alert("Please fill all mandatory field.");
    }
  };
  saveLead = (event) => {
    const url =
      Urls.python_baseurl + "/Save_Call_Tck_Sp_CRM_CustomerMaster_Save";
    var data = JSON.stringify({
      CRMCustID: 0,
      GlobalCustID: 0,
      CustType: parseInt(this.state.Type),
      Title: parseInt(this.state.Title),
      IsMTICust: "'Y'",
      Name: `'${this.state.Name}'`,
      Address1: `'${this.state.address1}'`,
      Address2: `'${this.state.address2}'`,
      pincode: `'${this.state.pincode}'`,
      State: parseInt(this.state.State),
      District: parseInt(this.state.District),
      Region: this.state.Region,
      City: `'${this.state.City}'`,
      Country: this.state.Country_id,
      Mobile: `'${this.state.Mobileno}'`,
      Email: `'${this.state.email}'`,
      priApp: this.state.primary_application,
      secApp: this.state.secondary_application,
    });
    console.log("newdata", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("res65009", res[0].newID);
          this.setState(
            {
              CRMCustID: res[0].newID,
              DocName: "CRMPE",
            },
            () => {
              console.log("CRMCustID", this.state.CRMCustID);
              this.saveM0details();

              // this.createleadno()
              // if(this.state.leadno_new.length>0)
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  saveM0details = (event) => {
    const url = Urls.python_baseurl + "/Save_Lead_Sp_M0Master_Save";
    var data = JSON.stringify({
      iID: 0,
      sID: 0,
      iDealerID: sessionStorage.getItem("dealerid"),
      iHOBrId: sessionStorage.getItem("dealerid"),
      CustType: parseInt(this.state.Type),
      prefix: "1",
      Name: `'${this.state.Name}'`,
      firstName: "''",
      lastname: "''",
      Address1: `'${this.state.address1}'`,
      Address2: `'${this.state.address2}'`,
      City: `'${this.state.City}'`,
      pincode: `'${this.state.pincode}'`,
      Region: this.state.Region,
      State: parseInt(this.state.State),
      District: parseInt(this.state.District),
      Taluka: parseInt(this.state.Town),
      Country: "1",
      Mobile: `'${this.state.Mobileno}'`,
      Email: `'${this.state.email}'`,
      priApp: "0",
      secApp: "0",
      MTICust: `'${this.state.ext_mit_cust}'`,
      M0Financier: 0,
      bodybuilder: "''",
      Doneby: "'D'",
      LastPurchdate: `'${this.state.startDate}'`,
      FleetQty: "0",
      KAM: `'${this.state.kam_customer}'`,
    });
    console.log("newdata", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              M0_id: res[0].ID,
            },
            () => {
              this.lead_CRM_save();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_CRM_save = (event) => {
    const url = Urls.python_baseurl + "/Save_Lead_SP_M0Details";
    var data = JSON.stringify({
      iID: 0,
      iDealerID: sessionStorage.getItem("dealerid"),
      iHOBrId: sessionStorage.getItem("dealerid"),
      iCustID: parseInt(this.state.M0_id),
      LeadNo: `'${this.state.leadno}'`,
      DocDate: `'${moment(new Date()).format("DD/MM/YYYY")}'`,
      Confirm: "'Y'",
      Cancel: "'N'",
      TcktID: "0",
      D: "'D'",
    });
    console.log("newdata", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              newID_crm_id: res[0].newID,
            },
            () => {
              this.maxNo();
              // this.lead_Object_save()
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_Object_save = (event) => {
    const url = Urls.python_baseurl + "/Save_mo_SP_Save_M0Objectives";
    var data = JSON.stringify({
      ID: "0",
      UserId: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      Lead_hdr_ID: parseInt(this.state.newID_crm_id),
      obj_Id: this.state.visit_details,
      obj_date: `'${moment(this.state.Date_1).format("DD/MM/YYYY")}'`,
      discussion: "''",
      time_spent: "''",
      next_obj_Id: this.state.next_visit_details,
      next_date: `'${moment(this.state.Date_2).format("DD/MM/YYYY")}'`,
      commit_det: "''",
    });
    console.log("newdata", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].newID) {
            this.maxNo();
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  maxNo = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: "'2324'",
      DocName: "'M0'",
      DealerId: sessionStorage.getItem("dealerid"),
    });
    console.log("newdata", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            alert("Record Saved Succesfully");
            sessionStorage.setItem("ActiveTab", "Lead");
            this.props.history.push("/tiles_tabs");
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  addnew = () => {
    this.createleadno();
    this.setState({
      Title: "",
      Type: "",
      Name: "",
      address1: "",
      address2: "",
      ext_mit_cust: "",
      pincode: "",
      State: "",
      District: "",
      primary_application: "",
      secondary_application: "",
      startDate: "",
      total_no_fleet: "",
      kam_customer: "",
      call_ticket_number: "",
      call_ticket_date: "",
    });
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Lead</h4>
        <div
          class="form-group col-md-12"
          style={{ padding: "0px", marginLeft: "-29px " }}
        >
          {/* <button type="submit" className="btn Opertion_btn clickable" style={{ fontSize: "12px", float: 'left' }} onClick={this.clickNavigation}>
                        Back
                    </button> */}
          <button
            type="submit"
            className="btn Opertion_btn clickable"
            style={{ fontSize: "12px", float: "right" }}
            onClick={this.Validate}
          >
            Save
          </button>
          {/* <button type="submit" className="btn Opertion_btn clickable" style={{ fontSize: "12px", float: 'right' }} onClick={this.addnew}>
                        Add New
                    </button> */}
        </div>
        <div style={{ marginTop: "45px", marginRight: "25px" }}>
          {this.renderMemberinfo()}
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    );
  }
}
