const Strings ={
     Demo:"Demo",
     Model_Code:"Model_Code",
     State:"State",
     Plan_Depot:"Plan_Depot",
     Model_Price:"Model_Price",
     Employee:'Employee',
     Enquiry: "Enquiry",
     Lead: "Lead",
     Enquiry_details: "Enquiry_details",
     Supplier:'Supplier',
     Customer:"Customer",
     Chassis_Master:"Chassis_Master",
     Company_Master:'Company_Master',
     Quotation_M2:"Quotation_M2",
     Tax:"Tax",
     Booking_M3:"Booking_M3",
     tiles_tabs:'tiles_tabs',
     Money_reciept:"Money_reciept",
     Vehical_billing:"Vehical_billing",
     Dealer_Po:"Dealer_Po",
     Dealer_Vehicle_Receipt:"Dealer_Vehicle_Receipt",
     Enquiry_List:"Enquiry_List",
     QuotationReceipt:"QuotationReceipt",
     FollowUP:"FollowUP",
     Dashboard:"Dashboard",
     JobCard_List:'JobCard_List',
     Chassis_details:"Job_Card_Details",
     Sales_DB:"Sales_DB",
     Pending_Jobcard:"Pending_Jobcard",
     Vehicle_stock:"Vehicle_Stock",
     Billling_list:"Money_Received",
     vehical_invoice:"Vehical_Invoice",
     booking_list:"Vehicle_Booking",
     Confirmed_Enquiry:"EnquiryList",
     Vehicle_Receipt_dealer_to_dealer:"Vehicle_Receipt_dealer_to_dealer",
     MenuMaster: "MenuMaster",
     UserManagment: "UserManagment",
     UserList: "UserList",
     chassis_pending_for_vehicle_billing:"chassis_pending_for_vehicle_billing",
     VehicleChallan:"VehicleChallan",
     Quotation_Report:"Quotation_Report",
     Quotation_Invoice:"Quotation_Invoice",
     Gate_Pass:"Gate_Pass",
     Dealer_To_Dealer_Tax_Invoice:"Dealer_To_Dealer_Tax_Invoice",
     Delar_to_Delar_Sales:"Delar_to_Delar_Sales",
     Dealer_to_delar_challan:"Dealer_to_delar_challan",
     Form_22:"Form_22",
     Stock_Report:"Stock_Report",
     Form_21:"Form_21",
     Vehicle_Sales_Report:"Vehicle_Sales_Report",
     Reports:"Reports",
     Vehicle_Purchase_Report:"Vehicle_Purchase_Report",
     Service_Report:"Service_Report",
     Dealer_to_Dealer_purches:"Dealer_to_Dealer_Purchase",
     MenuMaster:"MenuMaster",
     Upload_Dealer_Data:'Upload_Dealer_Data',
     Autogenrated_agrid:'Autogenrated_agrid',
     User_Report:"User_Report",
     FileDisplay: 'FileDisplay',
     Dealer_seles_order:"Dealer_sales_order",
     ErrorLogs:"ErrorLogs",
     DealerPoApproval:"DealerPOApproval",
     Scheme:"Scheme",
     Dashboard_prototype:'Dashboard_prototype',
     Testing_prototype:'Testing_prototype',
     ForgotPassword:"ForgotPassword",
     Send_OTP:"Send_OTP",
     Reset_Password:"Reset_Password",
     Job_Card_Approval:"Job_Card_Approval",
     Part:"Part",
     PaymentApproval:"PaymentApproval",
     Part_Price:"Part_Price",
     Part_Catalogue:"Part_Catalogue",
     Labor_schedule:"Labor_schedule",
     Labor_Code:"Labor_Code",
     Lubricant_Price:"Lubricant_Price",
     Help:"Help",
     Customer_Dealer_Po:"Dealer_To_Dealer_Po",
     UserUsagesReport:"DMS_Usage-Report_Card"
}
export default Strings;