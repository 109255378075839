import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import Strings from "../../../helpers/Strings";
import moment from "moment";
import HiPaaSLogo from "../../components/Images/HiPaaS_logo.png";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
const $ = window.$;
const bcrypt = require("bcryptjs");

export class Scheme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      Active: "",
      Scheme_Type: "",
      Depot_Flag: "",
      Amount: "",
      scheme_Name: "",
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      rowData: [],
      Model_Code: [],
      model_code: "",
      DepartmentName: "",
      Modelcode_name: "",
      id: 0,
      double_save: false,
      start_date: "",
      End_date: "",
      businessType:"",
      Type:"",
      Insurance:""
    };
  }
  componentDidMount() {
    this.MasterAPICall();
    this.GetModel_Code();
  }
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/GetScheme_SP_GetScheme";
    var data = JSON.stringify({
      DepartmentId: "0",
      Model_code: "''",

    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res && res.length > 0) {
          this.setState({
            rowData: res,
            // Active: res[0].Active,
            // Scheme_Type: res[0].Scheme_Type,
            // scheme_Name: res[0].scheme_Name,
            // start_date:moment(res[0].start_date).format("YYYY-MM-DD"),
            // End_date:moment(res[0].End_date).format("YYYY-MM-DD"),
            // scheme_department: res[0].scheme_department,
            // Amount: res[0].Amount,
            // model_code: res[0].model_id,
            // model_id: res[0].model_id,
            // DepartmentName:res[0].DepartmentName
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModel_Code = () => {
    const url = Urls.python_baseurl + "/GetModelCode";
    var data = JSON.stringify({
      ID: "null",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Code: res,
            model_typecode: res[0].Type,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Scheme Name",
        field: "scheme_Name",
         width: "220px",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Type", field: "type",  width: "80px", },
      { headerName: "Scheme Department", field: "DepartmentName",  width: "150px", },
      { headerName: "Scheme Type", field: "Scheme_Type",  width: "130px", },
      { headerName: "Start Date", field: "start_date",  width: "130px", },
      { headerName: "End Date", field: "End_date",  width: "130px", },
      { headerName: "Model", field: "model_name",  width: "400px", },
      { headerName: "Model Code", field: "model_code",  width: "130px", },
      { headerName: "Amount", field: "Amount",  width: "120px", },
      { headerName: "Insurance", field: "is_insurance",  width: "120px", },
      { headerName: "Active", field: "Active",  width: "90px", },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (event.colDef.field === "scheme_Name") {
                this.setState({
                  id: event.data.ID,
                  Active: event.data.Active,
                  Scheme_Type: event.data.Scheme_Type,
                  scheme_Name: event.data.scheme_Name,
                  start_date:moment(event.data.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
                  End_date: moment(event.data.End_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
                  scheme_department: event.data.scheme_department,
                  Amount: event.data.Amount,
                  model_code: event.data.model_id,
                  model_id: event.data.model_id,
                  DepartmentName: event.data.DepartmentName,
                  Modelcode_name: event.data.model_code,
                  Insurance:event.data.is_insurance?event.data.is_insurance:"",
                  businessType:event.data.type
                  });
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  handledateChange = (event, key) => {
    console.log("dsds", event.target.value);
    this.setState({
      [key]: event.target.value,
    });
  };
  testclickNavigation2 = () => {
    $("#payment_error_modal_4").modal("show");
  };
  close() {
    var fileInput = document.getElementById("fileInput");
    fileInput.value = "";
    this.setState({
      Type:"0",
      selectedFile:""
    })
    $("#payment_error_modal_4").modal("hide");

  }
  
  errorDialog4 = () => {
    return (
      <div
        className="modal"
        id="payment_error_modal_4"
        style={{ marginLeft: "25%",marginTop:"35px" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog-view" style={{ maxWidth: "50%", right: "30%" }}>
          <div className="error-dialog">
            <button
              type="button"
              class="close close-btn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.close();
              }}
            >
              <span class="" aria-hidden="true">
                &times;
              </span>
            </button>
            <div id="exTab1">
              <div class="tab-content clearfix">
                <div class="tab-pane active" id="1a">
                  {this.csvHeaderview()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  csvHeaderview() {
    return (
      <div>
        <div className="" style={{ marginTop: "15px" }}>
          <div class="form-group  col-md-12">
            <div
              className="form-group row"
              style={{
                // marginLeft: "2px",
                marginTop: "15px",
                // alignItems: "baseline",
              }}
            >
              <div class="form-group col-5 col-md-4">
                <label>Type {this.required()}</label>
                <select
            //  disabled={sessionStorage.getItem("UserRole") == "10"?false:true}
            className="form-control list-dashboard"
            id="Type"
            value={this.state.Type}
            onChange={(event) => {
              this.ChangeVal(event, "Type");
            }}
          >
            <option value="0">Select</option>
            <option value="B2B">B2B</option>
            <option value="B2C">B2C</option>
          </select>
              </div>
              
              <input
          
          style={{ marginLeft: "12px", width: "81px",marginTop:"28px" }}
          key={this.state.theInputKey || ""}
              id="fileInput"
              type="file"
          onChange={(e) => this.setState({ selectedFile: e.target.files[0], result: e.target.result })}
        />
        <span style={{ margin: "0 12px 0 3px" ,marginTop:"30px"}}>{this.state.selectedFile && this.state.selectedFile.name ? this.state.selectedFile.name : "No File Chosen"}</span>

              {/* </div> */}
              <div
                className="form-group col-3"
                role="group"
                aria-label="Basic mixed styles example"
              >
                <button
                disabled={this.state.double_save}
                  type="button"
                  className="btn"
                  style={{
                    backgroundColor: "var(--main-bg-color)",
                    color: "white",
                    marginTop: "20px",
                    marginLeft: "5px",
                  }}
                  onClick={() => this.onFileUpload()}
                >
                  {this.state.loaderforupload && (
                    <span
                      className="spinner-grow spinner-grow-sm"
                      style={{ color: "unset", marginRight: "5px", }}
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Upload
                </button>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    );
  }
  validation_check = () => {
    let formIsValid = true;
    if (this.state.Type == "" || this.state.Type == "0" || this.state.Type == undefined) {
      formIsValid = false;
      alert("Please enter the Type");
    } 
    return formIsValid;
  };
  onFileUpload = (e) => {
    let check=this.validation_check()  
    if (check) {
     
      this.setState({ loaderforupload: true ,double_save: true,})
      let data1 = new FormData();
      data1.append("file", this.state.selectedFile);
      data1.append("Type", this.state.Type);
      console.log(data1, "sujjj")
      if (data1) {
        var requestOptions = {
          method: "POST",
          body: data1,
          redirect: "follow",
        };

        fetch(
          Urls.python_baseurl + "/Scheme_Master_upload_file",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {

            if (result.Status) {
              alert(result.success)

            } else {
              alert(result.success)
            }
          }
          )
          .catch(err => {
            console.log(err)
          }).finally((r) => {
            $("#payment_error_modal_4").modal("hide");
            // window.location.reload()   
            var fileInput = document.getElementById("fileInput");
            fileInput.value = "";
            this.setState({
              selectedFile: "",
              loaderforupload: false,
              btn_disable: false,
              Type: "",
              double_save: false
            }, () => {
              this.MasterAPICall();
            })
          })
      }
    }
   }
  header = () => {
    return (
        <div className=" row">
          <div className=" form-group col-md-3">
            <label htmlFor="scheme_Name">Scheme Name {this.required()}</label>
            <input
            disabled
            // disabled={sessionStorage.getItem("UserRole") == "10"?false:true}
              onChange={(e) => this.setState({ scheme_Name: e.target.value })}
              value={this.state.scheme_Name}
              id="scheme_Name"
              className=" form-control"
              onKeyPress={(e) => {
                const pattern = /[A-Za-z\s]/; // Regular expression pattern for alphabetic characters
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault(); // Prevent input if it's not alphabetic
                }
              }}
            ></input>
          </div>
          <div className=" form-group col-md-3">
          <label>Type</label>
                <select
                disabled
            // disabled={sessionStorage.getItem("UserRole") == "10"?false:true}
            className="form-control list-dashboard"
            id="businessType"
            value={this.state.businessType}
            onChange={(event) => {
              this.ChangeVal(event, "businessType");
            }}
          >
            <option value="0">Select</option>
            <option value="B2B">B2B</option>
            <option value="B2C">B2C</option>
          </select>
          </div>
          <div className=" form-group col-md-3">
            <label htmlFor="Scheme_Type">Scheme Type {this.required()}</label>
            <input
            disabled
              // disabled={sessionStorage.getItem("UserRole") == "10"?false:true}
              onChange={(e) => this.setState({ Scheme_Type: e.target.value })}
              value={this.state.Scheme_Type}
              id="Scheme_Type"
              className=" form-control"
              onKeyPress={(e) => {
                const pattern = /[A-Za-z\s]/; // Regular expression pattern for alphabetic characters
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault(); // Prevent input if it's not alphabetic
                }
              }}
            ></input>
          </div>
          
          {/* <div className=" form-group col-md-3">
            <label htmlFor="DepartmentName">Scheme Department</label>
            <input
              onChange={(e) => this.setState({ DepartmentName: e.target.value })}
              value={this.state.DepartmentName}
              id="DepartmentName"
              className=" form-control"
              
            ></input>
          </div> */}
          <div className=" form-group col-md-3">
            <label htmlFor="start_date">Start Date {this.required()}</label>
            <input
            disabled
              // disabled={sessionStorage.getItem("UserRole") == "10"?false:true}
              type="date"
              value={this.state.start_date}
              id="start_date"
              className=" form-control "
              onChange={(event) => {
                this.handledateChange(event, "start_date");
              }}
              min={
                this.state.start_date
                  ? new Date().toISOString().split("T")[0]
                  : new Date().toISOString().split("T")[0]
              }
              max={
                this.state.End_date
                  && new Date(this.state.End_date).toISOString().split("T")[0]
                  //: new Date().toISOString().split("T")[0]
              }
            ></input>
          </div>
          <div className=" form-group col-md-3">
            <label htmlFor="End_date">End Date {this.required()}</label>
            <input
            disabled
              // disabled={sessionStorage.getItem("UserRole") == "10"?false:true}
              type="date"
              value={this.state.End_date}
              id="End_date"
              className=" form-control "
              onChange={(event) => {
                this.handledateChange(event, "End_date");
              }}
              min={
                this.state.start_date
                  ? new Date(this.state.start_date).toISOString().split("T")[0]
                  : new Date().toISOString().split("T")[0]
              }
            ></input>
          </div>

        <div className=" form-group col-md-3">
          <label htmlFor="model_id">Model Code {this.required()}</label>
          <select
          disabled
            // disabled={sessionStorage.getItem("UserRole") == "10"?false:true}
            value={this.state.model_code}
            onChange={(e) => this.ChangeVal(e, "model_code")}
            id="model_id"
            className=" form-control "
          >
            <option value="0">Select</option>
            {this.state.Model_Code.map((e) => {
              return <option value={e.ID}>{e["Model code"]}</option>;
            })}
          </select>
        </div>
        <div className=" form-group col-md-3">
          <label htmlFor="Amount">Amount {this.required()}</label>
          <input
          disabled
          // disabled={sessionStorage.getItem("UserRole") == "10"?false:true}
            value={this.state.Amount}
            id="Amount"
            className=" form-control "
            onChange={(e) => {
              let number = e.target.value;

              if (isNaN(number) || number < 0) {
                this.setState({ Amount: "" });
              } else {
                this.ChangeVal(e, "Amount");
              }
            }}
          ></input>
        </div>
       { this.state.businessType=="B2C"?
        <div className=" form-group col-md-3">
            <label htmlFor="Scheme_Type"> Insurance{this.state.businessType=="B2C"&&this.required()}</label>
            <select
            disabled
          // disabled={sessionStorage.getItem("UserRole") == "10"?false:true}
            className="form-control list-dashboard"
            id="Insurance"
            value={this.state.Insurance}
            onChange={(event) => {
              this.ChangeVal(event, "Insurance");
            }}
          >
            <option value="0">Select</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
          </div>:null}
        <div className=" form-group col-md-3">
          <label htmlFor="Active">Active {this.required()}</label>
          <select
          
          disabled={sessionStorage.getItem("UserRole") == "10"?false:true}
            className="form-control list-dashboard"
            id="Active"
            value={this.state.Active}
            onChange={(event) => {
              this.ChangeVal(event, "Active");
            }}
          >
            <option value="0">Select</option>
            <option value="Y">Y</option>
            <option value="N">N</option>
          </select>
        </div>
        </div>
    );
  };
  ChangeVal(event, key) {
    console.log("dssd", event.target.value, key);
    this.setState({
      [key]: event.target.value,
    });
    if (key == "model_code") {
      let modelcode = "";
      modelcode = this.state.Model_Code.filter((e) => {
        console.log("ID6767", e, event.target.value);
        return e.ID === parseInt(event.target.value);
      })[0]?.["Model code"];

      this.setState({
        Modelcode_name: modelcode,
      });
    }
  }
  addnew = () => {
    var fileInput = document.getElementById("fileInput");
    fileInput.value = "";
    this.setState({
      selectedFile:"",
      Active: "",
      scheme_Name: "",
      Scheme_Type: "",
      DepartmentName: "",
      id: 0,
      start_date: "",
      End_date: "",
      Amount: "",
      model_code: "",
      model_id: "",
      Modelcode_name: "",
      Insurance:"",
      businessType:"",
      type:"",
      selectedFile:"",
      theInputKey: "",
    });
  };
  clickNavigation = () => {
    this.setState({
      double_save: true,
    });
    const url = Urls.python_baseurl + "/SP_InsertSchemeMaster";
    var data = JSON.stringify({
      id: `${this.state.id}`,
      Scheme_Type: `'${this.state.Scheme_Type}'`,
      scheme_Name: `'${this.state.scheme_Name}'`,
      start_date: `'${this.state.start_date}'`,
      End_date: `'${this.state.End_date}'`,
      Active: `'${this.state.Active}'`,
      Amount: `'${this.state.Amount}'`,
      model_code: `'${this.state.Modelcode_name}'`,
      model_id: `'${this.state.model_code}'`,
      is_insurance:this.state.businessType=="B2C"?`'${this.state.Insurance}'`:"''",
      type:`'${this.state.businessType}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        // if (res && res.length > 0) {
          
          if(res.status){
            if (this.state.id) {
              alert(res.Message)
              this.setState({
                double_save: false,
              },()=>{
                this.MasterAPICall()
                window.location.reload();
              });
            } else {
              alert(res.Message)
              this.setState({
                double_save: false,
              },()=>{
                this.MasterAPICall()
                window.location.reload();
              });
            }
          }else{
            alert(res.Message)
            this.setState({
              double_save: false,
            });
          }
         
          // window.location.reload();
          //  this.MasterAPICall()
        // }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Validate = () => {
    if (this.state.scheme_Name == "") {
      alert("Please Enter the Scheme Name.");
      return;
    } else if (
      this.state.businessType == "0" ||
      this.state.businessType == "" ||
      this.state.businessType == undefined
    ) {
      alert("Please Select the Type");
      return;
    }
    else if (this.state.Scheme_Type == "") {
      alert("Please Enter the Scheme Type.");
      return;
    }
    // else if(this.state.DepartmentName == ""){
    //   alert("Please Select the Department Name.")
    //   return
    // }
    else if (this.state.start_date == "") {
      alert("Please Select the Start Date");
      return;
    } else if (this.state.End_date == "") {
      alert("Please Select the End Date");
      return;
    } else if (this.state.model_code == "" || this.state.model_code == "0") {
      alert("Please Select the Model Code");
      return;
    } else if (this.state.Amount == "") {
      alert("Please Select the Amount");
      return;
    }else if(this.state.businessType=="B2C"){
      if (
        this.state.Insurance == "0" ||
        this.state.Insurance == "" ||
        this.state.Insurance == undefined
      ) {
        alert("Please Select the Insurance");
        return;
      }
    }
   
     else if (
      this.state.Active == "0" ||
      this.state.Active == "" ||
      this.state.Active == undefined
    ) {
      alert("Please Select the Active");
      return;
    }
    this.clickNavigation();
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  render() {
    return (
      <div>
        <h4 className="heading_text" style={{ paddingBottom: "0px" }}>
          Scheme
        </h4>
        <br></br>
        <div class="row justify-content-end ">
        <button
           hidden={sessionStorage.getItem("UserRole") == "10"?false:true}
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={this.addnew}
          >
            Clear
          </button>
          <button
            hidden={sessionStorage.getItem("UserRole") == "10"?false:true}
            disabled={this.state.double_save}
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={this.Validate}
          >
            Save
          </button>
          <button
            hidden={sessionStorage.getItem("UserRole") == "10"?false:true}
            disabled={this.state.double_save}
            type="submit"
            className="btn Opertion_btn clickable mr-3"
            onClick={this.testclickNavigation2}
          >
            Upload File
          </button>
        </div>
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Scheme List
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">{this.masterTable()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Scheme Details
              </button>
            </h5>
          </div>

          <div
            id="collapseTwo"
            class="collapse show"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <div class="card-body">{this.header()}</div>
          </div>
        </div>
        {this.errorDialog4()}
      </div>
    );
  }
}
