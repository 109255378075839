import React from "react";
import "../Files/files-styles.css";
// import '../Claims/Dashboard/styles.css'
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Urls from "../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import { ListFilter } from "../../components/ListFilter";
import moment from "moment";

// import Strings from '../../helpers/Strings';
// // import { post } from 'axios';
// import { Alert } from "../../../../helpers/getDetails";
const $ = window.$;
export class Dealer_seles_order extends React.Component {
  constructor(props) {
    console.log(props, "dsadhg");
    super(props);
    this.state = {
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      ethnicity_map: [],
      Ethnicity: [],
      Input_Code: "",
      Text: "",
      Output_FHIR_Code: "",
      Code_System: "",
      Display: "",
      Definition: "",
      Type: "",
      rowData: [],
      HiPaaS_ID: "",
      loaderforupload: false,
      startDate:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].startDate
          ? props.location.state.data[0].startDate
          : moment().subtract(7, "d").format("YYYY-MM-DD"),
      endDate:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].endDate
          ? props.location.state.data[0].endDate
          : moment().format("YYYY-MM-DD"),
      dealerid:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].dealerid
          ? props.location.state.data[0].dealerid
          : sessionStorage.getItem("dealerid"),
      showtimerange:
        props.location.state &&
        props.location.state.data &&
        props.location.state.data[0] &&
        props.location.state.data[0].showtimernagefilter
          && props.location.state.data[0].showtimernagefilter
          ,
    };
  }
  componentDidMount() {
    this.MasterAPICall();
  }

  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SelectPodetails_SP_SelectPODetails";
    var data = JSON.stringify({
      Dealer_Id: `'${this.state.dealerid}'`,
      StartDate: `'${this.state.startDate}'`,
      EndDate: `'${this.state.endDate}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        const value = {
          data: {
            ID: "",
          },
        };
        if (res && res.length > 0) {
          value.data["PO No"] = res[0]["PO No"];
          this.setState({
            rowData: res,
          });
        } else if (res.length == 0) {
          this.setState({
            rowData: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        // this.GetModelDetails();
      });
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Sales Order No.",
        field: "PO No",
        width: "150px",
        cellStyle: {
          color: "var(--main-bg-color)",
          cursor: "pointer",
        },
      },
      { headerName: "Dealer Name", field: "Dealer_Name", width: "150px" },
      { headerName: "PO Total", field: "TotalPOAmt", flex: "1" },
      { headerName: "Approved Total", field: "Approve_Amount", flex: "1" },
      { headerName: "Total Quantity", field: "Total_Quantity", flex: "1" },
      { headerName: "Approved Quantity", field: "Approve_Quantity", flex: "1" },
      {
        headerName: "Total Payment ",
        field: "TotalPaymentReceived",
        flex: "1",
      },
      { headerName: "Total Payment % ", field: "Paymentpercentage", flex: "1" },
      { headerName: "Status", field: "Acknowledgement_Status", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (sessionStorage.getItem("UserRole") == "1") {
                if (event.colDef.headerName == "Sales Order No.") {
                  this.setState(
                    {
                      Active: event.data.Active,
                      Model_Category: event.data["Model Category"],
                    },
                    () => {
                      this.props.history.push("/Dealer_Po", {
                        POID: event.data.ID,
                        D2Dsales: true,
                        // Isconfirm: event.data.status,
                      });
                    }
                  );
                }
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  clickNavigation = () => {
    this.props.history.push("/Job_Card_Details", {});
  };
  refreshScreen = (startDate, endDate, dealerid) => {
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        dealerid: dealerid,
      },
      () => {
        this.MasterAPICall();
      }
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text" style={{ marginLeft: "0px" }}>
          Dealer Sales Order List
        </h4>
        <br />
        <br />
        <ListFilter
          refreshScreen={this.refreshScreen}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          dealerid={this.state.dealerid}
          showtimerange={this.state.showtimerange}
        />
        {/* {this.EthnicityHeaderview()} */}
        {/* <div class="form-group col-md-12" style={{padding:'0px'}}>
                    <button type="submit" className="btn Opertion_btn clickable" style={{ fontSize: "12px", float:'right',marginRight:"30px" }} onClick={this.clickNavigation}>
                        Add JobCard
                    </button>
                    </div>  */}
        <div>
          <br></br>
          {this.masterTable()}
          {/* {this.errorDialog()} */}
        </div>
      </div>
    );
  }
}
