import React from "react";
import "../Files/files-styles.css";
// import '../Claims/Dashboard/styles.css'
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import DatePicker from "react-datepicker";
import moment from "moment";
import Urls from "../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import { Tiles } from "../../components/Tiles";
import Strings from "../../../helpers/Strings";
// import { post } from 'axios';
 import {Vehical_billing} from "../../containers/Vehicle_Sales/Vehical_billing"
// import { Vehical_billing } from "../../Vehical_billing";
import { faL } from "@fortawesome/free-solid-svg-icons";
const $ = window.$;
export class chassis_pending_for_vehicle_billing extends React.Component {
  constructor(props) {
    console.log(props, "ppppppp");
    super(props);
    this.state = {
      ActiveTab: sessionStorage.getItem("ActiveTab"),
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      ethnicity_map: [],
      Ethnicity: [],
      Title: "",
      enquiry_no: "",
      enquiry_date: "",
      Source: "",
      Source_name: "",
      source_mobile: "",
      address1: "",
      address2: "",
      ext_mit_cust: "",
      pincode: "",
      State: "",
      District: "",
      primary_application: "",
      secondary_application: "",
      startDate: "",
      total_no_fleet: "",
      kam_customer: "",
      call_ticket_number: "",
      call_ticket_date: "",
      HiPaaS_ID: "",
      visit_details: "",
      discussion: "",
      next_visit_details: "",
      HiPaaS_ID: "",
      date: "",
      newdate: "",
      time_spent: "",
      Commitment: "",
      m1_count: 0,
      // m2_count: 0,
      m3_count: 0,
      m7_count: 0,
      m8_count: 0,
      loaderforupload: false,
      redirect_page: "",
      tableData: [
        { id: 1, name: "Item 1", quantity: 0 },
        { id: 2, name: "Item 2", quantity: 0 },
        { id: 3, name: "Item 3", quantity: 0 },
      ],
      rowData: [],
      PendingrowData: [],
      flag: false,
       Booking_M3_Details: [],
      name: "",
      pendingflag: false,
      Enquiry_Props: {},
      pendingEvent: "",
      sqlfor: props.location.state && props.location.state.data[0] && props.location.state.data[0].pendind_data ? props.location.state.data[0].pendind_data : '',
      dashboard_Click: props.location.state && props.location.state.data[0] && props.location.state.data[0].dashboard_Click ? props.location.state.data[0].dashboard_Click : '',
      title: props.location.state && props.location.state.data[0] && props.location.state.data[0].title ? props.location.state.data[0].title : '',

    };
  }

  componentDidMount() {
    
    this.MasterAPICall()
  }
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_VehicleSalesDetails";
    var data =JSON.stringify({
        Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
        SqlFor:`'${this.state.sqlfor?this.state.sqlfor:"MoneyReceipt"}'`,
        startdate:"''",
        enddate:"''",
        Dashboard_For:`'${this.state.sqlfor}'`

      });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
            const isNullish = Object.values(res[0][0]).every(value => {
                if (value === null || value==="") {
                  return true;
                }
                return false;
              });
              if(isNullish){
                this.setState({
                    rowData: [],
                  });
              }else{
                this.setState({
                    rowData: res[0],
                  });
              }
         
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("storage", this.handleStorageChange);
  }
  componentDidUpdate() {
    if (this.state.ActiveTab != sessionStorage.getItem("ActiveTab")) {
      window.location.reload();
    }
  }
  onChangeName(event, key) {
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
  }
  ChangeVal(event, key) {
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
  }
  handleStartChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  onSelect = (event, key) => {
    console.log("event", event, key);
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
  };
  handledateChange = (event, key) => {
    console.log("key", event.target.value, key);
    this.setState({
      [key]: event.target.value,
    });
  };
  handlecall_ticket_dateChange = (date) => {
    this.setState(
      {
        call_ticket_date: date,
      },
      () => {}
    );
  };
  PendingList = (Docname) => {
    const url =
      Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";

    var data = JSON.stringify({
      GetRecordFor: Docname,
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res, "popio");
          this.setState({
            PendingrowData: res[0],

            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
          $("#payment_error_modal").modal("show");
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  m1_count = () => {
    const url =
      Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";
    var data = JSON.stringify({
      GetRecordFor: "'M1'",
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("ssssssss", res[1][0]?.count);
          this.setState({
            m1_count: res[1][0]?.count,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  
  m3_count = () => {
    const url =
      Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";
    var data = JSON.stringify({
      GetRecordFor: "'M3'",
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            m3_count: res[1][0]?.count,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  m7_count = () => {
    const url =
      Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";
    var data = JSON.stringify({
      GetRecordFor: "'M7'",
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            m7_count: res[1][0]?.count,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  m8_count = () => {
    const url =
      Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";
    var data = JSON.stringify({
      GetRecordFor: "'M8'",
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            m8_count: res[1][0]?.count,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handleInputChange = (id, event) => {
    const { name, value } = event.target;

    // Update the quantity for the corresponding item
  };
  clickNavigation = (event) => {
    this.props.history.push("/Enquiry", {});
  };
  saveLead = (event) => {};
  handleSort = (event) => {
    this.setState({ rowData: [] });
    if (event === "m0" || this.state.ActiveTab == "Lead") {
      this.setState({ showTable: "m0" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'M0Master'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    } else if (event === "m1" || this.state.ActiveTab == "Enquiry") {
      this.setState({ showTable: "m1" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'M1Details'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    } else if (event === "m2" || this.state.ActiveTab == "Quotation") {
      this.setState({ showTable: "m2" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'M2Details'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    } else if (event === "m3" || this.state.ActiveTab == "Vehicle Booking") {
      this.setState({ showTable: "m3" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'M3Details'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    } else if (event === "m7" || this.state.ActiveTab == "Money Received") {
      this.setState({ showTable: "m7" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'M7Details'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    } else if (event === "m8" || this.state.ActiveTab == "Vehicle Invoice") {
      this.setState({ showTable: "m8" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'m8Details'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    }
  };
  // forcedReferes;


    
  displayVehicleInvoice = () => {
    $("#payment_error_modal_2").modal("show");
  };
  masterTable2() {
    let columnDefs = [
        { headerName: "", field: "DealerCode4", width: "140px",  cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
            cellRenderer: (data) => {
              if(data){
                return "Create Invoice"
              }
            }, },
        { headerName: "Dealer Name", field: "Dealer_Name", width: "140px",  },
        { headerName: "Dealer Code", field: "DealerCode", width: "130px",  },
        {  headerName: "Chassis No.", field: "Chassis_no", width: "150px",  },
        { headerName: "Vehicle No.", field: "Vehicle_No", width: "140px",  },
        { headerName: "Battery No.", field: "BatteryNo", width: "140px",  },
        { headerName: "Charger No.", field: "ChargerNo", width: "140px",  },
        { headerName: "Model Name", field: "model_name", width: "140px",  },

        { headerName: "Money Receipt No.", field: "MoneyReceipt_No", width: "140px",  },
        { headerName: "Money Receipt Date", field: "MoneyReceipt_Date", width: "140px",  },
    ];
    

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              console.log("event12", event.data);
              
                if (event.colDef.field == "DealerCode4") {
                    this.setState(
                        {
                          pendingflag: true,
                          pendingEvent8: event.data,
                        },
                        () => {
                          $("#payment_error_modal").modal("hide");
                          $("#payment_error_modal_3").modal("show");
                        }
                      );
                
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  tab() {
    return (
      <div style={{ marginLeft: "2px" }}>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {/* <a
              className={
                this.state.ActiveTab != "" && this.state.ActiveTab == "Lead"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-home-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Lead" }, () =>
                  setTimeout(() => {
                    this.handleSort("m0");
                    sessionStorage.setItem("ActiveTab", "Lead");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Lead
            </a> */}
            <a
              className={
                this.state.ActiveTab != "" && this.state.ActiveTab == "Enquiry"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-home-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Enquiry" }, () =>
                  setTimeout(() => {
                    this.handleSort("m1");
                    sessionStorage.setItem("ActiveTab", "Enquiry");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Enquiry
            </a>
            {/* <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Quotation"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Quotation" }, () =>
                  setTimeout(() => {
                    this.handleSort("m2");
                    sessionStorage.setItem("ActiveTab", "Quotation");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Quotation
            </a> */}
            <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Vehicle Booking"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Vehicle Booking" }, () =>
                  setTimeout(() => {
                    this.handleSort("m3");
                    sessionStorage.setItem("ActiveTab", "Vehicle Booking");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Vehicle Booking
            </a>
            <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Money Received"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Money Received" }, () =>
                  setTimeout(() => {
                    this.handleSort("m7");
                    sessionStorage.setItem("ActiveTab", "Money Received");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Money Received
            </a>
            <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Vehicle Invoice"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Vehicle Invoice" }, () =>
                  setTimeout(() => {
                    this.handleSort("m8");
                    sessionStorage.setItem("ActiveTab", "Vehicle Invoice");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Vehicle Invoice
            </a>

            {/* <a className="nav-item nav-link" id="nav-contact-tab" onClick={() => this.handleSort('Resubmit')} data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Ready to Submit</a> */}
          </div>
        </nav>
      </div>
    );
  }

  _renderSummaryDetails = () => {
    let row = [];
    let summary = [];
    summary = [
      // { name: 'Total Files', value: data ? data.RejectedCount ? Number(data.TotalFiles) + Number(data.RejectedCount) : data.TotalFiles : 0 },
      // {
      //   name: "Total Pending Enquiry",
      //   value: this.state.m1_count,
      //   color: "#129DC9",
      // },
      // {
      //   name: "Total Pending Quotation",
      //   value: this.state.m2_count,
      //   color: "green",
      // },
      {
        name: "Total Pending Vehicle Booking",
        value: this.state.m3_count,
        color: "orange",
      },
      {
        name: "Total Pending Money Received",
        value: this.state.m7_count,
        color: "#55eb34",
      },
      {
        name: "Total Pending Vehicle Invoice",
        value: this.state.m8_count,
        color: "#34ebc6",
      },
    ];
    let array = summary;

    array.forEach((item) => {
      let addon = "";
      let claimStatus = "";
      let subtitle = "";
      let availitySent = "";
      let url = "";
      let data = [];

      row.push(
        <Tiles
          isClickable={item.name != "Eligibility Errors"}
          header_text={item.name}
          value={item.value}
          isenrollment={true}
          color={item.color}
          differentTile={true}
          onClick={() => {
            if (this.state.m1_count && item.name === "Total Pending Enquiry") {
              this.PendingList("M1");
              this.setState({
                redirect_page: "M1",
              });
            } else if (
              this.state.m3_count &&
              item.name === "Total Pending Vehicle Booking"
            ) {
              this.PendingList("M3");
              this.setState({
                redirect_page: "M3",
              });
            } else if (
              this.state.m7_count &&
              item.name === "Total Pending Money Received"
            ) {
              this.PendingList("M7");
              this.setState({
                redirect_page: "M7",
              });
            } else if (
              this.state.m8_count &&
              item.name === "Total Pending Vehicle Invoice"
            ) {
              this.PendingList("M8");
              this.setState({
                redirect_page: "M8",
              });
            }
          }}
        />
      );
    });

    return <div className="row padding-left">{row}</div>;
  };
  close() {
    return (
      <div style={{ marginLeft: "2px" }}>
        <nav>
          <button
            type="button"
            class="close close-btn"
            style={{ marginTop: "-2%" }}
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              this.close();
            }}
          >
            <span class="" aria-hidden="true">
              &times;
            </span>
          </button>
        </nav>
      </div>
    );
  }
  masterTable() {
    let columnDefs = [
      {
        headerName: "Doc No",
        field: "Doc_No",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Customer Name", field: "Dealer_Name", flex: "1" },
      { headerName: "Mobile", field: "mobile", flex: "1" },
      
    ];
    if(this.state.redirect_page==="M8"){
      columnDefs.push(
        { headerName: "Chassis No.", field: "Chassis_no", flex: "1"}
      )
    }

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.PendingrowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (
                event.colDef.field == "Doc_No" &&
                this.state.redirect_page === "M1"
              ) {
                // this.props.history.push("/Enquiry_details", {
                //   data: event.data.ID,
                //   Dealer_Name: event.data.Dealer_Name,
                // });
                // $("#payment_error_modal").modal("hide");
                this.setState(
                  {
                    pendingflag: true,
                    pendingEvent: event.data,
                  },
                  () => {
                    $("#payment_error_modal").modal("hide");
                    $("#payment_error_modal_3").modal("show");
                  }
                );
              } else if (
                event.colDef.field == "Doc_No" &&
                this.state.redirect_page === "M2"
              ) {
                this.props.history.push("/Quotation_M2", {
                  data: event.data,
                });
                $("#payment_error_modal").modal("hide");
              } else if (
                event.colDef.field == "Doc_No" &&
                this.state.redirect_page === "M3"
              ) {
                // this.props.history.push("/Booking_M3", {
                //   data: event.data,
                // });
                this.setState(
                  {
                    pendingflag: true,
                    pendingEvent3: event.data,
                  },
                  () => {
                    $("#payment_error_modal").modal("hide");
                    $("#payment_error_modal_3").modal("show");
                  }
                );
              } else if (
                event.colDef.field == "Doc_No" &&
                this.state.redirect_page === "M7"
              ) {
                // this.props.history.push("/Money_reciept", {
                //   data: event.data,
                // });
                this.setState(
                  {
                    pendingflag: true,
                    pendingEvent7: event.data,
                  },
                  () => {
                    $("#payment_error_modal").modal("hide");
                    $("#payment_error_modal_3").modal("show");
                  }
                );
              } else if (
                event.colDef.field == "Doc_No" &&
                this.state.redirect_page === "M8"
              ) {
                // this.props.history.push("/Vehical_billing", {
                //   data: event.data,
                // });
                this.setState(
                  {
                    pendingflag: true,
                    pendingEvent8: event.data,
                  },
                  () => {
                    $("#payment_error_modal").modal("hide");
                    $("#payment_error_modal_3").modal("show");
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  errorDialog = () => {
    return (
      <div class="modal" id="payment_error_modal">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div style={{ marginTop: "-20px" }} class="modal-body">
              {this.masterTable()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  displayPopUp = () => {
    return (
      <div class="modal" id="payment_error_modal_2">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => {
                  this.setState({
                    flag: false,
                    id:0,
                    EnquiryDetails:[]
                    // pendingEvent: [],
                    // Customer_Name: "",
                  });
                }}
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
             
             <Vehical_billing
                modelid={"pendingClick"}   pendingClick={this.state.pendingEvent8}
                ></Vehical_billing>
            </div>
          </div>
        </div>
      </div>
    );
  };
  dispalyPending = () => {
    return (
      <div class="modal" id="payment_error_modal_3">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => {
                  this.setState({
                    pendingflag: false,
                    pendingEvent: [],
                    Customer_Name: "",
                  });
                }}
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
             
              { (
                <Vehical_billing
                modelid={"pendingClick"}   pendingClick={this.state.pendingEvent8}
                ></Vehical_billing>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  handelLead() {
    this.setState(
      {
        id:0,
        EnquiryDetails:[],
        flag: true,
      },
      () => {
        $("#payment_error_modal_2").modal("show");
      }
    );
  }
  render() {
    return (
      <div>
        <h4 className="heading_text" style={{marginLeft:"0px"}}>{this.state.title}</h4><br></br>
        {this.masterTable2()}

        {this.errorDialog()}
        {this.state.flag == true && this.displayPopUp()}
        {this.state.pendingflag == true && this.dispalyPending()}
      </div>
    );
  }
}
