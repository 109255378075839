import React from "react";
import moment from "moment";
import "../../../containers/color.css";
import "./index.css";
import Strings from "../../../../helpers/Strings";
import {
  setDomLayoutAfterPrint,
  setDomLayoutForPrint,
} from "../../../../helpers/getDetails";
export class Dealer_To_Dealer_Tax_Invoice extends React.Component {
  constructor(props) {
    console.log(props, "jjkjjk");
    super(props);
    this.state = {
      ModelRow: props.location?.state?.data?props.location?.state?.data:[],
      TaxDetails: props.location?.state?.tax_raw,
      Lr_No: props.location?.state?.data[0]?.LR_No,
      Lr_Date: props.location?.state?.data[0]?.LR_Date
        ? props.location?.state?.data[0]?.LR_Date
        : "",
      DC_no: props.location?.state?.data[0]?.DC_no,
      DC_Date: props.location?.state?.data[0]?.DC_Date
        ? props.location?.state?.data[0]?.DC_Date
        : "",
      Invoice_No: props.location?.state?.data[0]?.Tr_no,
      Invoice_Date: props.location?.state?.data[0]?.ref_date
        ? props.location?.state?.data[0]?.ref_date
        : "",
      Customer_Name: props.location?.state?.Customer_Name,
    };
  }

  componentDidMount() {
    if (this?.props?.location?.state?.data) {
      let quantity = 0,
        IGST_AMT = 0,
        CGST_AMT = 0,
        SGST_AMT = 0,
        Total = 0,
        TotalAmountWithTax = 0,
        TIGSTAMT = 0,
        TCGSTAMT = 0,
        TSGSTAMT = 0,
        TTaxableAMT = 0,
        discount = 0;
      for (let i = 0; i < this.state.ModelRow.length; i++) {
        quantity += this.state.ModelRow[i].Qty;
        Total += parseFloat(this.state.ModelRow[i].total);
        discount += parseFloat(this.state.ModelRow[i].Discount);
      }
      for (let i = 0; i < this.state.TaxDetails.length; i++) {
        TotalAmountWithTax += this.state.TaxDetails[i].TotalAmt_WithTax;
        TIGSTAMT += parseFloat(this.state.TaxDetails[i].IGST_AMT);
        TSGSTAMT += parseFloat(this.state.TaxDetails[i].SGST_AMT);
        TCGSTAMT += parseFloat(this.state.TaxDetails[i].CGST_AMT);
        TTaxableAMT += parseFloat(this.state.TaxDetails[i].TaxableAmount);
      }
      this.setState(
        {
          quantity: quantity,
          Total: Total,
          TCGSTAMT: TCGSTAMT,
          TSGSTAMT: TSGSTAMT,
          TIGSTAMT: TIGSTAMT,
          TTaxableAMT: TTaxableAMT,
          TotalAmountWithTax: TotalAmountWithTax,
          discount: discount,
        },
        () => {
          var tempTitle = document.title;
          document.title = this.props.location?.state?.vehicleChallan == true?"DLC-"+moment().year()+"-"+this.state.Invoice_No:"SLI-"+moment().year()+"-"+this.state.Invoice_No;
          window.print();
          document.title = tempTitle;
          localStorage.setItem("FromHistory", true);
          this.props.history.push({
            pathname:
              this.props.location?.state?.vehicleChallan == false
                ? Strings.Vehicle_Receipt_dealer_to_dealer
                : Strings.VehicleChallan,
            state: {
              id: this.props.location?.state?.MAinHdr,
              Customer_Name: this.state.Customer_Name,
            },
          });
        }
      );
    }
  }
  convertToWord = (num) => {
    if (!num) return;
    let splittedNum = num.toString().split(".");
    let nonDecimal = splittedNum[0];
    let decimal = splittedNum[1];
    let TotalAmountWithTaxWord =
      this.price_in_words(Number(nonDecimal)) +
      (decimal ? " Rupees and" : " Rupees Only") +
      this.price_in_words(Number(decimal)) +
      (decimal ? "Paise Only" : "");
    return TotalAmountWithTaxWord;
  };
  price_in_words = (price) => {
    var sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt
          ? ""
          : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (
          (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];

    // Check if the price is a valid number
    if (((price += ""), isNaN(parseFloat(price)))) str = "";
    else if (parseFloat(price) > 0 && price.length <= 15) {
      // Adjusted to handle larger numbers including decimal places
      var priceParts = price.split("."); // Split the price into integer and fractional parts
      var integerPart = priceParts[0];
      var fractionalPart = priceParts[1] || "0"; // If there is no fractional part, default to '0'

      // Process integer part
      for (digitIdx = integerPart.length - 1; digitIdx >= 0; digitIdx--) {
        digit = integerPart[digitIdx] - 0;
        nxtDigit = digitIdx > 0 ? integerPart[digitIdx - 1] - 0 : 0;
        switch (integerPart.length - digitIdx - 1) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != integerPart[digitIdx + 1] &&
                    0 != integerPart[digitIdx + 2]
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != integerPart[digitIdx + 1] ||
                    0 != integerPart[digitIdx + 2]
                      ? " and"
                      : " Crore")
                : ""
            );
        }
      }

      str = words.reverse().join("");
    } else str = "";
    return str;
  };
  show_TaxInvoice = () => {
    let total=0;
    this.state.ModelRow.map(e=>{
         total=total+(parseInt(e.Qty)*parseFloat(e.Rate))
    })
    return (
      <div>
        <h4 className="invoice-header heading_text">
          {this.props.location?.state?.vehicleChallan == true
            ? "Delivery Challan"
            : "Tax Invoice"}
        </h4>
        <div className="invoice-container-d-d">
          <div className=" row">
            <table>
              <tr>
                <div className=" pl-2">
                  <p className=" bold">
                    {sessionStorage.getItem("dealer_name")}
                  </p>
                  <p>{sessionStorage.getItem("Dealer_Address")}</p>
                  <p>
                    <span className=" bold">Phone</span> :{" "}
                    {sessionStorage.getItem("Dealer_Contact")}
                  </p>
                  <p>
                    <span className=" bold">Email</span> :{" "}
                    {sessionStorage.getItem("Dealer_Email")}
                  </p>
                  <p>
                    <span className=" bold">GST No.</span> :{" "}
                    {sessionStorage.getItem("Dealer_Gst_No")}
                  </p>
                </div>
              </tr>
            </table>
            <table className="col-6 ">
              <tr>
                <td style={{ display: "block", border: "none" }}>
                  <div style={{ fontWeight: "600" }}>
                    {this.state?.Customer_Name?.toUpperCase()}
                  </div>
                  <div>
                    <span>{this.state?.ModelRow[0]?.Address}</span>
                    {/* <span>
                      {this.state.ModelRow[0]?.city != "" &&
                        " " + this.state.ModelRow[0]?.city}
                    </span>
                    <span>
                      {this.state.ModelRow[0]?.District &&
                        " " + this.state.ModelRow[0]?.District}
                    </span>
                    <span>
                      {this.state.ModelRow[0]?.state &&
                        " " + this.state.ModelRow[0]?.state + " "}
                    </span>-
                    <span>{this.state.ModelRow[0]?.pincode}</span> */}
                  </div>
                  <div>
                    <span className=" bold">Phone</span> : +91{" "}
                    {this.state.ModelRow[0]?.mobile}
                  </div>
                  <div>
                    <span className=" bold">Email</span> :
                    {this.state.ModelRow[0]?.E_mail}
                  </div>
                  {/* <div>{this.state.ModelRow[0]?.PANNo}</div> */}
                  <div>
                    <span className=" bold">Category</span> :{" "}
                    {this.state.ModelRow[0]?.Dealer_Category}
                  </div>
                  <div>
                    <span className=" bold">GST No.</span>{" "}
                    {this.state.ModelRow[0]?.GST_No}
                  </div>
                </td>
              </tr>
            </table>
            <table className=" col-6">
              <tr style={{ height: "45px" }}>
                <td>
                  <div>
                    {this.props.location?.state?.vehicleChallan == true
                      ? "Challan No."
                      : "Invoice No."}
                  </div>
                  <div>{this.state.Invoice_No}</div>
                </td>
                <td>
                  <div>
                    {this.props.location?.state?.vehicleChallan == true
                      ? "Challan Date"
                      : "Invoice Date"}
                  </div>
                  <div>{this.state.Invoice_Date}</div>
                </td>
              </tr>
              {/* <tr style={{ height: "45px" }}>
                <td>
                  <div>Delivery Note</div>
                  <div></div>
                </td>
                <td>
                  <div>Model/Terms of Payment</div>
                  <div></div>
                </td>
              </tr> */}
              {/* <tr style={{ height: "45px" }}>
                <td>
                  <div>Reference No. & Date</div>
                  <div>MNSR/EV/2324/006</div>
                </td>
                <td>
                  <div>Other Reference</div>
                  <div>24-Dec-23</div>
                </td>
              </tr> */}
              {/* <tr style={{ height: "45px" }}>
                <td>
                  <div>Buyer's Order No.</div>
                  <div>MNSR/EV/2324/006</div>
                </td>
                <td>
                  <div>Dated</div>
                  <div>24-Dec-23</div>
                </td>
              </tr> */}
              {this.props.location?.state?.vehicleChallan == false && (
                <tr style={{ height: "45px" }}>
                  <td>
                    <div>Dispatch Doc No.</div>
                    <div>{this.state.DC_no}</div>
                  </td>
                  <td>
                    <div>Dispatch Doc Date</div>
                    <div>{this.state.DC_Date}</div>
                  </td>
                </tr>
              )}
              <tr style={{ height: "45px" }}>
                <td>
                  <div>LR No.</div>
                  <div>{this.state.Lr_No}</div>
                </td>
                <td>
                  <div>LR Date</div>
                  <div>{this.state.Lr_Date}</div>
                </td>
              </tr>
              <tr></tr>
            </table>
          </div>
          <div className=" row">
            <table style={{ whiteSpace: "nowrap" }}>
              <thead>
                <th>SI No.</th>
                <th>Description of Goods</th>
                <th>HSN/SAC</th>
                <th>Quantity</th>
                <th>Rate</th>
                <th>Per</th>
                <th>Disc</th>
                <th>Amount</th>
              </thead>
              <tbody style={{ verticalAlign: "top", textAlign: "end" }}>
                {this.state?.ModelRow?.map((e, index) => {
                  console.log("jjjjj",e);
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td style={{ width: "fit-content", textAlign: "left" }}>
                        <p className="bold">{e?.model_gr_description}</p>
                        <p>{e?.model_name}</p>

                        <p>Colour: {e?.colour}</p>
                        <p>Chassis No. {e?.chassis_no}</p>
                        <p>Battery No. {e?.ActualBatteryNo?e?.ActualBatteryNo:e?.BatteryNo}</p>
                        <p>Motor No. {e?.ActualMotor_No?e?.ActualMotor_No:e?.Motor_No}</p>
                        <p>Charger No. {e?.ActualChargerNo?e?.ActualChargerNo:e?.ChargerNo}</p>
                        <p>IOT No. {e?.ActualIOT_Device_ID?e?.ActualIOT_Device_ID:e?.IOT_Device_ID}</p>
                        <p>Display No. {e?.ActualDispayNo?e?.ActualDispayNo:e?.DispayNo}</p>
                        <p>Controller Unit No. {e?.ActualController_Unit_No?e?.ActualController_Unit_No:e?.Controller_Unit_No}</p>
                        <p>Vehicle No. {e?.Vehicle_No}</p>
                      </td>
                      <td>{e?.HSN_Code}</td>
                      <td>{e?.Qty}</td>
                      <td style={{ fontWeight: "600" }}>{e?.Rate}</td>
                      <td>Pcs</td>
                      <td>
                        {/* {`${
                        (e?.Discount * 100) / e?.Rate
                          ? ((e?.Discount * 100) / e?.Rate).toFixed(2)
                          : ""
                      }`} */}
                        {e?.Discount ? e?.Discount : 0}
                      </td>
                      <td>{e?.total ? e?.total : 0}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tbody
                style={{
                  verticalAlign: "top",
                  textAlign: "end",
                  fontWeight: "bold",
                }}
              >
                <td></td>
                <td>Total</td>
                <td></td>
                <td>{this.state.quantity} Pcs</td>
                <td>₹ {total}</td>
                <td></td>
                <td>₹ {this.state.discount ? this.state.discount : 0}</td>
                <td>₹ {this.state.Total}</td>
              </tbody>
            </table>
          </div>
          <div className=" row">
            <table>
              <tr>
                <td>
                  <div>
                    Amount in words:{" "}
                    <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {this.convertToWord(this.state.Total)}
                    </span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div className=" row">
            <table style={{ whiteSpace: "nowrap" }}>
              <thead>
                <tr>
                  <th style={{ width: "10%" }}>HSN/SAC</th>
                  <th style={{ width: "20%" }}>HSN Description</th>
                  <th>Taxable Value</th>
                  <th colspan="2" className="text-center ">
                    IGST
                    <div className="groupcolumn">
                      <span>Rate</span>
                      <span>Amount</span>
                    </div>
                  </th>
                  <th colspan="2" className="text-center ">
                    CGST
                    <div className="groupcolumn">
                      <span>Rate</span>
                      <span>Amount</span>
                    </div>
                  </th>
                  <th colspan="2" className="text-center ">
                    SGST
                    <div className="groupcolumn">
                      <span>Rate</span>
                      <span>Amount</span>
                    </div>
                  </th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "end", fontWeight: "600" }}>
                {this.state?.TaxDetails?.map((e) => {
                  return (
                    <tr>
                      <td style={{ textAlign: "start" }}>{e?.HSN_Code}</td>
                      <td style={{ textAlign: "start" }}>
                        {e?.HSN_Description}
                      </td>
                      <td>₹ {e?.TaxableAmount}</td>
                      <td style={{ width: "92px" }}>{e?.IGST}%</td>
                      <td style={{ width: "92px" }}>
                        ₹ {e?.IGST_AMT?.toFixed(2)}
                      </td>
                      <td style={{ width: "92px" }}>{e?.CGST}%</td>
                      <td style={{ width: "92px" }}>
                        ₹ {e?.CGST_AMT?.toFixed(2)}
                      </td>
                      <td style={{ width: "92px" }}>{e?.SGST}%</td>
                      <td style={{ width: "92px" }}>
                        ₹ {e?.SGST_AMT?.toFixed(2)}
                      </td>
                      <td>
                        ₹{" "}
                        {e?.TotalAmt_WithTax && e?.TotalAmt_WithTax.toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tbody style={{ textAlign: "end", fontWeight: "600" }}>
                <td>Total</td>
                <td></td>
                <td>₹ {this.state?.TTaxableAMT?.toFixed(2)}</td>
                <td></td>
                <td>₹ {this.state?.TIGSTAMT?.toFixed(2)}</td>
                <td></td>
                <td>₹ {this.state?.TCGSTAMT?.toFixed(2)}</td>
                <td></td>
                <td>₹{this.state?.TSGSTAMT?.toFixed(2)}</td>
                <td>
                  ₹{" "}
                  {this.state?.TotalAmountWithTax &&
                    this.state?.TotalAmountWithTax.toFixed(2)}
                </td>
              </tbody>
            </table>
          </div>
          <div className=" row">
            <tr>
              <td style={{ border: "none" }}>
                <span>Total Amount in words :</span>
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  {this.convertToWord(
                    this.state?.TotalAmountWithTax &&
                      this.state?.TotalAmountWithTax.toFixed(2)
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <div
                style={{ width: "100%", textAlign: "end", paddingRight: "5px" }}
              >
                <p style={{ fontWeight: "bold" }}>
                  for, {sessionStorage.getItem("dealer_name")}
                </p>
                <p style={{ marginTop: "30px" }}>Authorised Signatory</p>
              </div>
            </tr>
            <tr className=" pl-4">
              <p style={{ marginLeft: "-12px" }} className=" bold">
                Terms & Conditions
              </p>
              <ol>
                <li>Goods once sold will not be taken back or exchanged.</li>
                <li>All Disputes subject to Jurisdiction only. </li>
                <li>
                  Our Risk & Responsibility Ceases as soon as goods leave our
                  premises.{" "}
                </li>
                <li>
                  Subject to{" "}
                  <span className=" bold">
                    {sessionStorage.getItem("Dealer_District")}
                  </span>{" "}
                  Jurisdiction only E & OE{" "}
                </li>
              </ol>
              <p style={{ marginLeft: "-12px" }}>
                All rights on the environmental attributes, or any other similar
                benefits generated by the vehicles during the lifetime will be
                at the sole discretion and ownership of the OEM
              </p>
            </tr>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div style={{display:"flex", justifyContent:"center"}}>
      <body id="printArea">
        <div className="page-header" style={{ textAlign: 'center' }}>
        </div>
        <div className="page-footer"></div>
        <table className=" border-0 ">
          <thead className=" thead border-0 ">
            <tr className=" border-0 ">
              <td className=" border-0 ">
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>
          <tbody className=" border-0 ">
            <tr className=" border-0 ">
              <td className=" border-0 ">
                <div className="page">{this.show_TaxInvoice()}</div>
              </td>
            </tr>
          </tbody>

          <tfoot className=" tfoot border-0 ">
            <tr className=" border-0 ">
              <td className=" border-0 ">
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </body>
      </div>
    );
  }
}
