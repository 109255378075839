import React, { Component } from "react";
import Strings from "../../../helpers/Strings";
import "../Files/files-styles.css";
import Urls from "../../../helpers/Urls";
const bcrypt = require("bcryptjs");

export class Reset_Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
      message: "",
      isValid: "",
      username:
        props.location &&
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].username
          ? props.location.state.data[0].username
          : "",
      feedback: false,
      isEightChar: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isResetStarted: false,
    };
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      message: "", // Clear any previous error messages
    });
    const newPassword = event.target.value;
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    let isPassValid = regex.test(newPassword);
    if (!isPassValid) {
      this.setState({ feedback: true });
    } else {
      this.setState({ feedback: false });
    }
    if (newPassword.length < 8) {
      this.setState({ isEightChar: false });
    } else {
      this.setState({ isEightChar: true });
    }
    if (/[A-Z]/.test(newPassword)) {
      this.setState({ hasUpperCase: true });
    } else {
      this.setState({ hasUpperCase: false });
    }
    if (/[a-z]/.test(newPassword)) {
      this.setState({ hasLowerCase: true });
    } else {
      this.setState({ hasLowerCase: false });
    }
    if (/[0-9]/.test(newPassword)) {
      this.setState({ hasNumber: true });
    } else {
      this.setState({ hasNumber: false });
    }
    if (/[@#$%^&*()_+{}\[\]:;<>,.?~\\\/\|=!\-]/.test(newPassword)) {
      this.setState({ hasSpecialChar: true });
    } else {
      this.setState({ hasSpecialChar: false });
    }
  }

  handleSubmit = (event) => {
    // Perform password validation and reset password logic here
    const { newPassword, confirmPassword } = this.state;
    if (newPassword && confirmPassword) {
    }
    if (newPassword !== confirmPassword) {
      this.setState({ message: "Passwords do not match" });
      return;
    } else {
      const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
      let isPassValid = regex.test(newPassword);
      if (isPassValid) {
        let salt = bcrypt.genSaltSync(10);
        let encryptUserPass = bcrypt.hashSync(newPassword, salt);
        if (!this.state.isResetStarted) {
          this.setState({ isResetStarted: true });
          fetch(Urls.python_baseurl+"/update_password", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",

              "Cache-Control": "no-cache, no-store",
              Expires: 0,
              Pragma: "no-cache",
              Accept: "application/json",
            },
            body: JSON.stringify({
              login_name: `${this.state.username}`,
              passwd: `${encryptUserPass}`,
            }),
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.Status == true) {
                alert("Password Changed Succesfully!");
                this.props.history.push("/");
              } else {
                alert(res.message)
              }
            })
            .catch((err) => {
              process.env.NODE_ENV == "development" && console.log(err);
            })
            .finally(() => {
              this.setState({ isResetStarted: false });
            });
        }
      }
    }
  };

  render() {
    return (
      <div className="container-fluid bg-body-tertiary d-block ">
        <div
          className=" row justify-content-end "
          style={{ backgroundColor: "var(--main-bg-color)" }}
        >
          <img
            src={require("../../components/Images/Joy_connect_Logo.jpg")}
            alt="logo"
            align="center"
            style={{
              backgroundColor: "var(--main-bg-color)",
              width: "120px",
              marginRight: "20px",
            }}
          />
        </div>
        <div className="row justify-content-center">
          <div className="col-10 mt-3  col-md-6">
            {this.state.isResetStarted == true ? (
              <div
                style={{
                  marginLeft: "45%",
                  position: "absolute",
                  marginTop: "30%",
                }}
                class="spinner-border text-muted"
              ></div>
            ) : (
              ""
            )}
            <div
              className="card bg-white mb-5 p-5 mt-5 border-0"
              style={{
                boxShadow: "0 0px 15px rgba(0.1, 0.1, 0.1, .1)",
                opacity: this.state.isResetStarted == true ? "0.4" : "",
              }}
            >
              <div className="card-body px-5 text-center">
                <div className="">
                  <h2 className="heading">Reset Password</h2>
                  <p className="message">
                    Set the new Password for your account.
                  </p>
                  <div className="row justify-content-center mt-3">
                    <div className="col-6">
                      <label
                        style={{ display: "block", textAlign: "left" }}
                        className="justify-content-start"
                      >
                        New Password:
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        name="newPassword"
                        value={this.state.newPassword}
                        onChange={(event) => this.handleInputChange(event)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center mt-3">
                    <div className="col-6">
                      <label
                        style={{ display: "block", textAlign: "left" }}
                        className="justify-content-start"
                      >
                        Confirm Password:
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        onChange={(event) => this.handleInputChange(event)}
                        required
                      />
                    </div>
                  </div>

                  <div className=" mt-3">
                    {this.state.newPassword && this.state.confirmPassword ? (
                      <button
                        onClick={this.handleSubmit}
                        style={{ marginLeft: "-5px" }}
                        className="btn Opertion_btn mb-3 col-6"
                        type="submit"
                      >
                        Reset Password
                      </button>
                    ) : (
                      <button
                        disabled
                        style={{ marginLeft: "-5px" }}
                        className="btn Opertion_btn mb-3 col-5"
                        type="submit"
                      >
                        Reset Password
                      </button>
                    )}
                  </div>
                  {this.state.message && (
                    <p className=" text-danger ">{this.state.message}</p>
                  )}
                  {this.state.feedback ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "10px",
                      }}
                    >
                      <h5>Password must conatin the following</h5>
                      <div className="feedback">
                        {this.state.hasUpperCase ? (
                          <p className="green">
                            A Capital (uppercase) letter!{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>
                          </p>
                        ) : (
                          <p className="red">A Capital (uppercase) letter!</p>
                        )}
                        {this.state.hasNumber ? (
                          <p className="green">
                            A number!{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>
                          </p>
                        ) : (
                          <p className="red">A number!</p>
                        )}
                        {this.state.hasLowerCase ? (
                          <p className="green">
                            A lowercse letter!{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>
                          </p>
                        ) : (
                          <p className="red">A lowercse letter!</p>
                        )}
                        {this.state.hasSpecialChar ? (
                          <p className="green">
                            A special character!{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>
                          </p>
                        ) : (
                          <p className="red">A special character!</p>
                        )}
                        {this.state.isEightChar ? (
                          <p className="green">
                            Minimum 8 character!{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-check-lg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                            </svg>
                          </p>
                        ) : (
                          <p className="red">Minimum 8 character!</p>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
