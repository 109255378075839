import React from "react";
import ReactDOM from "react-dom";
import { Redirect } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Strings from "./helpers/Strings";
import { Header } from "./app/components/Header";
// import { LicenseManager } from "ag-grid-enterprise";
import { Login } from "./app/containers/Login";
import { Demo } from "./app/containers/Demo";
import { createRoot } from "react-dom/client";
import { Model_Code } from "./app/containers/Master/Model_Code";
import { Plan_Depot } from "./app/containers/Master/Plan_Depot";
import { State } from "./app/containers/Master/State";
import { Model_Price } from "./app/containers/Master/Model_Price";
import { Employee } from "./app/containers/Master/Employee";
import { Enquiry } from "./app/containers/Vehicle_Sales/Enquiry";
import { Lead } from "./app/containers/Vehicle_Sales/Lead";
import { Enquiry_details } from "./app/containers/Vehicle_Sales/Enquiry_details";
import { Supplier } from "./app/containers/Master/Supplier";
import { Customer } from "./app/containers/Master/Customer";
import { DynamicMenu } from "./app/components/DynamicMenue";
import { Chassis_Master } from "./app/containers/Master/Chassis_Master";
import { Navbar } from "./app/components/DynamicMenue/NavBar";
import { Company_Master } from "./app/containers/Master/Company_Master";
import { Quotation_M2 } from "./app/containers/Vehicle_Sales/Quotation_M2";
import { Tax } from "./app/containers/Master/Tax";
import { Booking_M3 } from "./app/containers/Vehicle_Sales/Booking_M3";
import { tiles_tabs } from "./app/containers/Vehicle_Sales/tiles_tabs";
import { Money_reciept } from "./app/containers/Vehicle_Sales/Money_reciept";
import { Vehical_billing } from "./app/containers/Vehicle_Sales/Vehical_billing";
import { Dealer_Po } from "./app/containers/Vehicle_Purchase/Dealer_Po/Dealer_Po";
import { Dealer_Vehicle_Receipt } from "./app/containers/Vehicle_Purchase/Dealer_Vehicle_Receipt/Dealer_Vehicle_Receipt";
import { Enquiry_List } from "./app/containers/Vehicle_Sales/Enquiry_List";
import { QuotationReceipt } from "./app/containers/QuotationReceipt";
import { FollowUP } from "./app/containers/FollowUP";
import { Dashboard } from "./app/containers/Dashboard";
import { JobCard_List } from "./app/containers/Services/JobCard_List";
import { Chassis_details } from "./app/containers/Chassis_details";
import { DynamicSidebar } from "./app/components/DynamicSidebar";

import { Sales_DB } from "./app/containers/Sales_DB";
import { Pending_Jobcard } from "./app/containers/Pending_Jobcard";
import { Vehicle_stock } from "./app/containers/Vehicle_stock";
import { Billling_list } from "./app/containers/Vehicle_Sales/Billling_list";
import { vehical_invoice } from "./app/containers/Vehicle_Sales/vehical_invoice";
import { booking_list } from "./app/containers/Vehicle_Sales/booking_list";
import { Confirmed_Enquiry } from "./app/containers/Vehicle_Sales/Confirmed_Enquiry";
import { Vehicle_Receipt_dealer_to_dealer } from "./app/containers/Vehicle_Purchase/Vehicle_Receipt_dealer_to_dealer.js/Vehicle_Receipt_dealer_to_dealer";

import { MenuMaster } from "./app/containers/Admin/MenuMaster";
import UserManagment from "./app/containers/Admin/UserManagment";
import { UserList } from "./app/containers/Admin/UserList";
import { chassis_pending_for_vehicle_billing } from "./app/containers/chassis_pending_for_vehicle_billing";
import { VehicleChallan } from "./app/containers/Vehicle_Purchase/VehicleChallan";
import { Quotation_Report } from "./app/containers/Report/Quotation_Report";
import { Quotation_Invoice } from "./app/containers/Report/Quotation_Invoice";
import { Gate_Pass } from "./app/containers/Report/Gate_Pass";
import { Dealer_To_Dealer_Tax_Invoice } from "./app/containers/Report/Dealer_To_Dealer_Tax_Invoice";
import { Delar_to_Delar_Sales } from "./app/containers/Delar_to_Delar_Sales";
import { Dealer_to_delar_challan } from "./app/containers/Dealer_to_delar_challan";
import { Form_22 } from "./app/containers/Report/Form_22";
import { Stock_Report } from "./app/containers/Stock_Report";
import { Form_21 } from "./app/containers/Report/Form_21";
import { Reports } from "./app/containers/Reports";
import { Vehicle_Sales_Report } from "./app/containers/Dealer_Report/Vehicle_Sales_Report";
import { Vehicle_Purchase_Report } from "./app/containers/Dealer_Report/Vehicle_Purchase_Report";
import { Service_Report } from "./app/containers/Dealer_Report/Service_Report";
import { Dealer_to_Dealer_purches } from "./app/containers/Dealer_to_Dealer_purches";
import { Upload_Dealer_Data } from "./app/containers/Upload_Dealer_Data";
import { Autogenrated_agrid } from "./app/containers/Master/Autogenrated_agrid";

import { User_Report } from "./app/containers/Dealer_Report/User_Report";
import FileDisplay from "./app/containers/filedisplay/index";
import { Dealer_seles_order } from "./app/containers/Dealer_seles_order";
import { ErrorLogs } from "./app/containers/ErrorLogs";
import { DealerPoApproval } from "./app/containers/DM_Approval/DealerPoApproval";
import { Scheme } from "./app/containers/Master/Scheme";
import {
  FollowUp_List,
  Followup_List,
} from "./app/containers/Vehicle_Sales/FollowUp_List";
import { Dashboard_prototype } from "./app/containers/Master/Dashboard_prototype";
import { Testing_prototype } from "./app/containers/Master/Testing_prototype";
import { Forgot_Password } from "./app/containers/Forgot_Password/Forgot_Password";
import { Send_OTP } from "./app/containers/Forgot_Password/Send_OTP";
import { Reset_Password } from "./app/containers/Forgot_Password/Reset_Password";
import { Job_Card_Approval } from "./app/containers/DM_Approval/Job_Card_Approval";
import { Part } from "./app/containers/Master/Part";
import { PaymentApproval } from "./app/containers/DM_Approval/PaymentApproval";
import { Part_Price } from "./app/containers/Master/Part_Price";
import { Part_Catalogue } from "./app/containers/Master/Part_Catalogue";
import { Labor_schedule } from "./app/containers/Master/Labor_schedule";
import { Labor_Code } from "./app/containers/Master/Labor_Code";
import { Lubricant_Price } from "./app/containers/Master/Lubricant_Price";
import Help from "./app/containers/Help";
import { Customer_Dealer_Po } from "./app/containers/Vehicle_Sales/Customer_Dealer_Po/Customer_Dealer_Po";
import { UserUsagesReport } from "./app/containers/UserUsagesReport";


// LicenseManager.setLicenseKey('CompanyName=HiPaaS Inc,LicensedApplication=HiPaaS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-007466,ExpiryDate=23_March_2021_[v2]_MTYxNjQ1NzYwMDAwMA==5449f6cc0f6b5dc99cfaad6a2982e250');

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
    const token = sessionStorage.getItem("token");
    let loggedIn;

    if (token == null) {
      loggedIn = false;
    } else {
      loggedIn = true;
    }

    this.state = {
      loggedIn,
      // timeout: 1200000,
      // timeOut: 1000 * 2880, //8
      timeOut: 1000 * 4880,
      // timeOut: 1000 * 600,
      isTimedOut: false,
    };

    this.handleFlag = this.handleFlag.bind(this);
    this.idleTimer = null;
    this.onAction = this.onAction.bind(this);
    this.onActive = this.onActive.bind(this);
    this.onIdle = this.onIdle.bind(this);
  }

  componentDidMount() {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }

  handleFlag(loggedIn) {
    this.setState({
      loggedIn: loggedIn,
    });
  }

  onAction(e) {
    this.setState({ isTimedOut: false });
  }

  onActive(e) {
    this.setState({ isTimedOut: false });
  }

  onIdle(e) {
    const isTimedOut = this.state.isTimedOut;

    if (isTimedOut) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  render() {
    let data = [];
    data = [{ apiflag: "1" }];
    let InitialScreen =
      sessionStorage.getItem("InitialScreen") == "null"
        ? "Dashboard"
        : "Dashboard";
    return (
      <Router>
        {/* <ScrollToTop /> */}
        {/* <IsLoggedIn /> */}
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          // onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeOut}
        />

        {this.state.loggedIn === false ? (
          <div>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => (
                  <Login handleFlag={this.handleFlag} {...props} />
                )}
              />
              <Route
                path={"/" + Strings.ForgotPassword}
                component={Forgot_Password}
              />
              <Route path={"/" + Strings.Send_OTP} component={Send_OTP} />
              <Route
                path={"/" + Strings.Reset_Password}
                component={Reset_Password}
              />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </div>
        ) : (
          <div className="container-fluid white-background">
            <Header OutToInboundJump={() => this.content.getData("inbound")} />
            <br />
            <br />
            <br />
            <br />
            {/* <Navbar/> */}
            <div className="row">
              <div className="sidebar-width nopadding white-background col-2">
                <DynamicSidebar
                  ref={(instance) => {
                    this.content = instance;
                  }}
                  handleFlag={this.handleFlag}
                />
              </div>
              <div
                id="Col-12"
                className="col-10"
                style={{ minHeight: "100vh", marginTop: "20px" }}
              >
                <Switch>
                  <Route exact path="/">
                    {this.state.loggedIn == true ? (
                      <Redirect
                        to={{
                          pathname: "/" + Strings[InitialScreen],
                          state: { data },
                        }}
                      />
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Route>
                  <Route path={"/" + Strings.Demo} component={Demo} />

                  <Route
                    path={"/" + Strings.Model_Code}
                    component={Model_Code}
                  />
                  <Route
                    path={"/" + Strings.Plan_Depot}
                    component={Plan_Depot}
                  />
                  <Route path={"/" + Strings.State} component={State} />
                  <Route
                    path={"/" + Strings.Model_Price}
                    component={Model_Price}
                  />
                  <Route path={"/" + Strings.Employee} component={Employee} />
                  <Route path={"/" + Strings.Enquiry} component={Enquiry} />
                  <Route path={"/" + Strings.Lead} component={Lead} />
                  <Route
                    path={"/" + Strings.Enquiry_details}
                    component={Enquiry_details}
                  />
                  <Route path={"/" + Strings.Supplier} component={Supplier} />

                  <Route path={"/" + Strings.Customer} component={Customer} />
                  <Route
                    path={"/" + Strings.Chassis_Master}
                    component={Chassis_Master}
                  />

                  <Route
                    path={"/" + Strings.Company_Master}
                    component={Company_Master}
                  />
                  <Route
                    path={"/" + Strings.Quotation_M2}
                    component={Quotation_M2}
                  />
                  <Route
                    path={"/" + Strings.tiles_tabs}
                    component={tiles_tabs}
                  />
                  <Route path={"/" + Strings.Tax} component={Tax} />
                  <Route
                    path={"/" + Strings.Booking_M3}
                    component={Booking_M3}
                  />
                  <Route
                    path={"/" + Strings.Money_reciept}
                    component={Money_reciept}
                  />
                  <Route
                    path={"/" + Strings.Vehical_billing}
                    component={Vehical_billing}
                  />
                  <Route path={"/" + Strings.Dealer_Po} component={Dealer_Po} />
                  <Route
                    path={"/" + Strings.Enquiry_List}
                    component={Enquiry_List}
                  />

                  <Route
                    path={"/" + Strings.Dealer_Vehicle_Receipt}
                    component={Dealer_Vehicle_Receipt}
                  />
                  <Route
                    path={"/" + Strings.Dealer_Vehicle_Receipt}
                    component={Dealer_Vehicle_Receipt}
                  />
                  <Route
                    path={"/" + Strings.QuotationReceipt}
                    component={QuotationReceipt}
                  />
                  <Route path={"/" + Strings.Dashboard} component={Dashboard} />
                  <Route
                    path={"/" + Strings.JobCard_List}
                    component={JobCard_List}
                  />
                  <Route
                    path={"/" + Strings.Chassis_details}
                    component={Chassis_details}
                  />
                  <Route path={"/" + Strings.Demo} component={Demo} />
                  <Route
                    path={"/" + Strings.Model_Code}
                    component={Model_Code}
                  />
                  <Route
                    path={"/" + Strings.Plan_Depot}
                    component={Plan_Depot}
                  />
                  <Route path={"/" + Strings.State} component={State} />
                  <Route
                    path={"/" + Strings.Model_Price}
                    component={Model_Price}
                  />
                  <Route path={"/" + Strings.Employee} component={Employee} />
                  <Route path={"/" + Strings.Enquiry} component={Enquiry} />
                  <Route path={"/" + Strings.Lead} component={Lead} />
                  <Route
                    path={"/" + Strings.Enquiry_details}
                    component={Enquiry_details}
                  />
                  <Route path={"/" + Strings.Supplier} component={Supplier} />

                  <Route path={"/" + Strings.Customer} component={Customer} />
                  <Route
                    path={"/" + Strings.Chassis_Master}
                    component={Chassis_Master}
                  />

                  <Route
                    path={"/" + Strings.Company_Master}
                    component={Company_Master}
                  />
                  <Route
                    path={"/" + Strings.Quotation_M2}
                    component={Quotation_M2}
                  />
                  <Route
                    path={"/" + Strings.tiles_tabs}
                    component={tiles_tabs}
                  />
                  <Route path={"/" + Strings.Tax} component={Tax} />
                  <Route
                    path={"/" + Strings.Booking_M3}
                    component={Booking_M3}
                  />
                  <Route
                    path={"/" + Strings.Money_reciept}
                    component={Money_reciept}
                  />
                  <Route
                    path={"/" + Strings.Vehical_billing}
                    component={Vehical_billing}
                  />
                  <Route path={"/" + Strings.Dealer_Po} component={Dealer_Po} />
                  <Route
                    path={"/" + Strings.Enquiry_List}
                    component={Enquiry_List}
                  />

                  <Route
                    path={"/" + Strings.Dealer_Vehicle_Receipt}
                    component={Dealer_Vehicle_Receipt}
                  />
                  <Route
                    path={"/" + Strings.Dealer_Vehicle_Receipt}
                    component={Dealer_Vehicle_Receipt}
                  />
                  <Route
                    path={"/" + Strings.QuotationReceipt}
                    component={QuotationReceipt}
                  />
                  <Route
                    path={"/" + Strings.FollowUP}
                    component={FollowUp_List}
                  />
                  <Route path={"/" + Strings.Dashboard} component={Dashboard} />
                  <Route
                    path={"/" + Strings.JobCard_List}
                    component={JobCard_List}
                  />
                  <Route path={"/" + Strings.Sales_DB} component={Sales_DB} />
                  <Route
                    path={"/" + Strings.Pending_Jobcard}
                    component={Pending_Jobcard}
                  />
                  <Route
                    path={"/" + Strings.Vehicle_stock}
                    component={Vehicle_stock}
                  />
                  <Route
                    path={"/" + Strings.Billling_list}
                    component={Billling_list}
                  />
                  <Route
                    path={"/" + Strings.vehical_invoice}
                    component={vehical_invoice}
                  />
                  <Route
                    path={"/" + Strings.booking_list}
                    component={booking_list}
                  />
                  <Route
                    path={"/" + Strings.Confirmed_Enquiry}
                    component={Confirmed_Enquiry}
                  />

                  <Route
                    path={"/" + Strings.Chassis_details}
                    component={Chassis_details}
                  />
                  <Route
                    path={"/" + Strings.Vehicle_Receipt_dealer_to_dealer}
                    component={Vehicle_Receipt_dealer_to_dealer}
                  />
                  <Route
                    path={"/" + Strings.MenuMaster}
                    component={MenuMaster}
                  />
                  <Route
                    path={"/" + Strings.UserManagment}
                    component={UserManagment}
                  />
                  <Route path={"/" + Strings.UserList} component={UserList} />
                  <Route
                    path={"/" + Strings.chassis_pending_for_vehicle_billing}
                    component={chassis_pending_for_vehicle_billing}
                  />
                  <Route
                    path={"/" + Strings.VehicleChallan}
                    component={VehicleChallan}
                  />
                  <Route
                    path={"/" + Strings.Quotation_Report}
                    component={Quotation_Report}
                  />
                  <Route
                    path={"/" + Strings.Quotation_Invoice}
                    component={Quotation_Invoice}
                  />
                  <Route path={"/" + Strings.Gate_Pass} component={Gate_Pass} />
                  <Route
                    path={"/" + Strings.Dealer_To_Dealer_Tax_Invoice}
                    component={Dealer_To_Dealer_Tax_Invoice}
                  />
                  <Route
                    path={"/" + Strings.Delar_to_Delar_Sales}
                    component={Delar_to_Delar_Sales}
                  />
                  <Route
                    path={"/" + Strings.Dealer_to_delar_challan}
                    component={Dealer_to_delar_challan}
                  />
                  <Route path={"/" + Strings.Form_22} component={Form_22} />
                  <Route
                    path={"/" + Strings.Stock_Report}
                    component={Stock_Report}
                  />
                  <Route path={"/" + Strings.Form_21} component={Form_21} />
                  <Route
                    path={"/" + Strings.Vehicle_Sales_Report}
                    component={Vehicle_Sales_Report}
                  />
                  <Route
                    path={"/" + Strings.Vehicle_Purchase_Report}
                    component={Vehicle_Purchase_Report}
                  />
                  <Route
                    path={"/" + Strings.Service_Report}
                    component={Service_Report}
                  />
                  <Route path={"/" + Strings.Reports} component={Reports} />
                  <Route
                    path={"/" + Strings.Dealer_to_Dealer_purches}
                    component={Dealer_to_Dealer_purches}
                  />
                  <Route
                    path={"/" + Strings.MenuMaster}
                    component={MenuMaster}
                  />
                  <Route
                    path={"/" + Strings.Upload_Dealer_Data}
                    component={Upload_Dealer_Data}
                  />
                  <Route
                    path={"/" + Strings.Autogenrated_agrid}
                    component={Autogenrated_agrid}
                  />
                  <Route
                    path={"/" + Strings.Dealer_seles_order}
                    component={Dealer_seles_order}
                  />
                  <Route path={"/" + Strings.Scheme} component={Scheme} />

                  <Route
                    path={"/" + Strings.User_Report}
                    component={User_Report}
                  />
                  <Route
                    path={"/" + Strings.FileDisplay}
                    component={FileDisplay}
                  />
                  <Route path={"/" + Strings.ErrorLogs} component={ErrorLogs} />
                  <Route
                    path={"/" + Strings.DealerPoApproval}
                    component={DealerPoApproval}
                  />
                  <Route
                    path={"/" + Strings.Dashboard_prototype}
                    component={Dashboard_prototype}
                  />
                  <Route
                    path={"/" + Strings.Testing_prototype}
                    component={Testing_prototype}
                  />
                  <Route
                    path={"/" + Strings.Job_Card_Approval}
                    component={Job_Card_Approval}
                  />
                  <Route
                    path={"/" + Strings.Part}
                    component={Part}
                  />
                  <Route
                    path={"/" + Strings.PaymentApproval}
                    component={PaymentApproval}
                  />
                  <Route
                    path={"/" + Strings.Part_Price}
                    component={Part_Price}
                  />
                  <Route
                    path={"/" + Strings.Part_Catalogue}
                    component={Part_Catalogue}
                  />
                  <Route
                    path={"/" + Strings.Labor_schedule}
                    component={Labor_schedule}
                  />
                   <Route
                    path={"/" + Strings.Labor_Code}
                    component={Labor_Code}
                  />
                   <Route
                    path={"/" + Strings.Lubricant_Price}
                    component={Lubricant_Price}
                  />
                   <Route
                    path={"/" + Strings.Help}
                    component={Help}
                  />
                    <Route
                    path={"/" + Strings.Customer_Dealer_Po}
                    component={Customer_Dealer_Po}
                  />
                   <Route
                    path={"/" + Strings.UserUsagesReport}
                    component={UserUsagesReport}
                  />
                  {/* <Route component={PageNotFound} /> */}
                </Switch>
              </div>
            </div>
          </div>
        )}
      </Router>
    );
  }
}
const container = document.getElementById("app");
const root = createRoot(container);
root.render(<PrivateRoute />);
