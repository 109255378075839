import React from "react";
import "../../Files/files-styles.css";
// import '../Claims/Dashboard/styles.css'
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import Strings from "../../../../helpers/Strings";
// import { post } from 'axios';
import { Alert } from "../../../../helpers/getDetails";
import { ListFilter } from "../../../components/ListFilter";
import moment from "moment";
const $ = window.$;
export class JobCard_List extends React.Component {
  constructor(props) {
    console.log(props, "dsjhdsj");
    super(props);
    this.state = {
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      ethnicity_map: [],
      Ethnicity: [],
      Input_Code: "",
      Text: "",
      Output_FHIR_Code: "",
      Code_System: "",
      Display: "",
      Definition: "",
      Type: "",
      rowData: [],
      HiPaaS_ID: "",
      loaderforupload: false,
      startDate:
        props.location.state &&
        props?.location?.state?.data &&
        props.location.state.data[0] &&
        props.location.state.data[0].startDate
          ? props.location.state.data[0].startDate
          : "",
      endDate:
        props.location.state &&
        props?.location?.state?.data &&
        props.location.state.data[0] &&
        props.location.state.data[0].endDate
          ? props.location.state.data[0].endDate
          : "",
      dealerid:
        props.location.state &&
        props?.location?.state?.data &&
        props.location.state.data[0] &&
        props.location.state.data[0].dealerid
          ? props.location.state.data[0].dealerid
          : sessionStorage.getItem("dealerid"),
          showtimerange:
        props.location.state &&
        props.location.state.data &&
        props.location.state.data[0] &&
        props.location.state.data[0].showtimernagefilter
          && props.location.state.data[0].showtimernagefilter
          ,
    };
  }

  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: `'${this.state.startDate}'`,
      ToDate: `'${this.state.endDate}'`,
      ModelPart: "''",
      DealerId: `'${this.state.dealerid}'`,
      SqlFor: "'JobcardList'",
      HOBrID: `0`,
    });
    console.log("ddd", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          this.setState({
            rowData: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  componentDidMount() {
    this.MasterAPICall();
  }

  masterTable() {
    let columnDefs = [
      {
        headerName: "Job Card No",
        field: "JobCard No",
        flex: "1",
        cellStyle: sessionStorage.getItem("UserRole") == "1" && {
          color: "var(--main-bg-color)",
          cursor: "pointer",
        },
      },
      { headerName: "Job Card Date", field: "Job Date", flex: "1" },
      { headerName: "Job Card Type", field: "Job Type", flex: "1" },
      { headerName: "Chassis No", field: "Chassis No", flex: "1" },
      { headerName: "Vehicle No", field: "Vehicle No", flex: "1" },
      { headerName: "Status", field: "status", flex: "1" },
      {
        headerName: "Approval Status",
        cellRenderer: (params) => {
          let status = '';
          let color = '';
      
          if (params.data.Approval_Flag === "Y" && params.data.Is_Approved === "") {
            status = "Pending For Approval";
            color = "orange";
          } else if (params.data.Approval_Flag === "Y" && params.data.Is_Approved === "Y") {
            status = "Approved";
            color = "green";
          } else if (params.data.Approval_Flag === "Y" && params.data.Is_Approved === "N") {
            status = "Rejected";
            color = "red";
          } else {
            status = "Approval Not Required";
            color="#0091AD"
          }
      
          return <span style={{ color:color }}>{status}</span>;
        },
        flex: "1"
      }
      //   { headerName: "Vehicle No", field: "Active", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (sessionStorage.getItem("UserRole") == "1") {
                if (event.colDef.field == "JobCard No") {
                  this.setState(
                    {
                      Active: event.data.Active,
                      Model_Category: event.data["Model Category"],
                    },
                    () => {
                      this.props.history.push("/Job_Card_Details", {
                        data_2: event.data.ID,
                        Isconfirm: event.data.status,
                      });
                    }
                  );
                }
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  clickNavigation = () => {
    this.props.history.push("/Job_Card_Details", {
      data: [
        {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
        },
      ],
    });
  };
  refreshScreen = (startDate, endDate, dealerid) => {
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        dealerid: dealerid,
      },
      () => {
        this.MasterAPICall();
      }
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Job Card List</h4>
        {/* {this.EthnicityHeaderview()} */}
        <br />
        <br />
        {sessionStorage.getItem("UserRole") == "1" ? (
          <div class="form-group col-md-12" style={{ padding: "0px" }}>
            <button
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", marginRight: "30px" }}
              onClick={this.clickNavigation}
            >
              Add New
            </button>
          </div>
        ) : null}<br></br>
        <div style={{ marginLeft: sessionStorage.getItem("UserRole")=="1"?"":"15px" }}>
        <ListFilter
            refreshScreen={this.refreshScreen}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dealerid={this.state.dealerid}
            showtimerange={this.state.showtimerange}
          />
        </div>
        <div class="form-group col-md-12" style={{ marginRight: "15px" }}>
          {this.masterTable()}
          {/* {this.errorDialog()} */}
        </div>
      </div>
    );
  }
}
