import React, { useState, useEffect } from "react";
import Urls from "../../../helpers/Urls";
import { useHistory, useLocation } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
const bcrypt = require("bcryptjs");
const fetchMethod = {
  method: "post",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  body: JSON.stringify({
    ID: "",
  }),
};
export default function UserManagment(props) {
  console.log(props, "jhjhjh");
  const history = useHistory();
  const location = useLocation();
  const [agOption, setAgOption] = useState({
    showTable: true,
    paginationPageSize: 10,
    domLayout: "autoHeight",
    autoGroupColumnDef: {
      headerName: "Group",
      minWidth: 100,
      field: "athlete",
      valueGetter: function (params) {
        if (params.node.group) {
          return params.node.key;
        } else {
          return params.data[params.colDef.field];
        }
      },
      headerCheckboxSelection: true,
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: { checkbox: true },
      headerCheckboxSelection: true,
    },
    defaultColDef: {
      cellClass: "cell-wrap-text",
      autoHeight: true,
      sortable: true,
      resizable: true,
      filter: true,
    },
    rowSelection: "multiple",
    rowGroupPanelShow: "never",
    pivotPanelShow: "never",
    rowData: [],
    showFieldOnAddNew: true,
  });
  const [doubleClick, setDoubleClick] = useState(false);

  const [form, setForm] = useState({
    ID: 0,
    firstName: "",
    lastName: "",
    employeeCode: "",
    userRole: "",
    department: "",
    state: "",
    username: "",
    password: "",
    UserRole_ID: "",
    Email: "",
    selected_dealer: "",
    ownerShip: [],
    md_selected_dealer: "",
    UserInitials: "",
    dealer_vehicle_code: "",
  });

  const [options, setOptions] = useState({
    department: [],
    userRole: [],
    state: [],
    DealerList: [],
    MD_dealer_list: [],
  });

  const [owner, setOwner] = useState({
    left: [],
    right: [],
    selected: "",
  });

  const [header, setHeader] = useState({
    headerName: "",
    userRoleID: "",
  });

  const [selectedUser, setselectedUser] = useState([]);

  useEffect(() => {
    if (form.state == "" || form.department == "") {
      return;
    }
    fetch(`${Urls.python_baseurl}/SP_Get_DealerList`, {
      ...fetchMethod,
      body: JSON.stringify({
        State_ID: `${form.state}`,
        DepartmentID: `${form.department}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setOptions((option) => {
          return { ...option, DealerList: res };
        });
        if (form.FormHistoryDealerID) {
          // console.log(res, "jjkjk");
          setForm((prev) => {
            return {
              ...prev,
              selected_dealer: res?.filter(
                (e) => e?.ID == form.FormHistoryDealerID
              )[0]?.Dealer_Name,
            };
          });
        }
      });
  }, [form.state, form.department]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "md_selected_dealer") {
      let md_selected_dealerid = options.MD_dealer_list.filter(
        (e) => e.Dealer_Name == value
      )[0]?.dealer_vehicle_code;
      setForm((prev) => {
        return { ...prev, dealer_vehicle_code: md_selected_dealerid };
      });
    }
    let userrolename = "";
    let UserInitials = "";
    if (name == "userRole") {
      userrolename = e.target.options[e.target.selectedIndex].text;
      options.userRole.map((e, idx) => {
        if (value == e.ID) {
          UserInitials = e.UserInitials;
        }
      });
      setForm((prev) => {
        return {
          ...prev,
          [name]: value,
          UserRole_ID: userrolename,
          UserInitials: UserInitials,
          dealer_vehicle_code: "",
          md_selected_dealer: "",
        };
      });
      setOwner((prev) => {
        return { ...prev, left: [] };
      });
      setHeader({ headerName: "", userRoleID: "" });
      setselectedUser([]);
    }
    setForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  useEffect(() => {
    fetch(`${Urls.python_baseurl}/SP_Get_UserRole`, fetchMethod)
      .then((res) => res.json())
      .then((res) => {
        setOptions((option) => {
          return { ...option, userRole: res[0] };
        });
      });
    fetch(`${Urls.python_baseurl}/SP_Get_Departments`, fetchMethod)
      .then((res) => res.json())
      .then((res) => {
        setOptions((option) => {
          return { ...option, department: res[0] };
        });
      });
    fetch(`${Urls.python_baseurl}/SP_EGPDealer_FillDropdown`, {
      ...fetchMethod,
      body: JSON.stringify({
        id: "0",
        type: "'State'",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setOptions((option) => {
          return { ...option, state: res };
        });
      });
  }, []);

  useEffect(() => {
    if (form.userRole && form.UserRole_ID !== "MD Dealer") {
      fetch(`${Urls.python_baseurl}/Find_Child_Role_Id`, {
        ...fetchMethod,
        body: JSON.stringify({
          UserRole: parseInt(form.userRole),
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res[0].ChildRoleID == 1) {
            fetch(`${Urls.python_baseurl}/Find_All_Child_Dealer_User`, {
              ...fetchMethod,
              body: JSON.stringify({
                // userRole_ID: form.userRole,
                // state_ID: form.state,
                UserID: form.ID,
                UserRole: form.userRole,
                // StateID: form.state,
              }),
            })
              .then((res) => res.json())
              .then((res) => {
                // console.log(res, "jkkjkjjkj");
                let container = [];

                for (let i = 0; i < res.length; i++) {
                  let flag = false;
                  for (let j = 0; j < container.length; j++) {
                    if (res[i].ID == container[j].ID&&res[i].Department_ID==container[j].Department_ID) {
                      flag = true;
                      break;
                    }
                  }
                  if (!flag) {
                    container.push(res[i]);
                  }
                }

                setOwner((owner) => {
                  return { ...owner, left: container };
                });
                setHeader((prev) => {
                  return {
                    ...prev,
                    headerName: res[0]?.UserRole,
                    userRoleID: res[0]?.UserRole_ID,
                  };
                });
              });
          } else {
            fetch(`${Urls.python_baseurl}/Find_All_Child_User`, {
              ...fetchMethod,
              body: JSON.stringify({
                // userRole_ID: form.userRole,
                // state_ID: form.state,
                UserID: form.ID,
                UserRole: form.userRole,
                // StateID: form.state,
              }),
            })
              .then((res) => res.json())
              .then((res) => {
                // console.log(res, "jkkjkjjkj");
                let container = [];
                if (res[0]?.UserRole == "Dealer") {
                  for (let i = 0; i < res.length; i++) {
                    let flag = false;
                    for (let j = 0; j < container.length; j++) {
                      if (res[i].ID == container[j].ID) {
                        flag = true;
                        break;
                      }
                    }
                    if (!flag) {
                      container.push(res[i]);
                    }
                  }
                } else {
                  container = res;
                }
                setOwner((owner) => {
                  return { ...owner, left: container };
                });
                setHeader((prev) => {
                  return {
                    ...prev,
                    headerName: res[0]?.UserRole,
                    userRoleID: res[0]?.UserRole_ID,
                  };
                });
              });
          }
        });
    }
  }, [form.userRole]);

  useEffect(() => {
    if (form.UserRole_ID == "MD Dealer") {
      fetch(`${Urls.python_baseurl}/SP_Get_MDDealer`, {
        ...fetchMethod,
        body: JSON.stringify({}),
      })
        .then((res) => res.json())
        .then((res) => {
          let fun = res.filter(
            (e) => e.ID == props?.location?.state?.SelectedRow?.dealer_ID
          )[0];
          setOptions((option) => {
            return { ...option, MD_dealer_list: res };
          });
          {
            form.dealer_vehicle_code == "" &&
              setForm((prev) => {
                return {
                  ...prev,
                  md_selected_dealer: fun?.Dealer_Name ? fun?.Dealer_Name : "",
                  dealer_vehicle_code: fun?.dealer_vehicle_code
                    ? fun?.dealer_vehicle_code
                    : "",
                };
              });
          }
        });
    }
  }, [form.userRole]);
  useEffect(() => {
    if (form.md_selected_dealer !== "") {
      fetch(`${Urls.python_baseurl}/Find_All_Distributor_Code_Child_User`, {
        ...fetchMethod,
        body: JSON.stringify({
          UserID: form.ID,
          Distributor_Code: form.dealer_vehicle_code,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setOwner((owner) => {
            return { ...owner, left: res };
          });
        });
    }
  }, [form.md_selected_dealer]);

  const handleList = (e, action) => {
    if (action === "select") {
      setOwner((owner) => {
        return {
          ...owner,
          selected: e,
        };
      });
    } else if (action === "left") {
      if (owner.selected) {
        if (owner.selected.ID) {
          const inRight = owner.right.filter((o) => o.ID === owner.selected.ID);
          if (inRight && Array.isArray(inRight) && inRight.length > 0) {
            const slected = owner.selected;
            setOwner((owner) => {
              return {
                right: owner.right.filter((o) => o.ID !== slected.ID),
                selected: "",
                left: [...owner.left, slected],
              };
            });
          }
        }
      }
    } else if (action === "right") {
      if (owner.selected.ID) {
        const inLeft = owner.left.filter((o) => o.ID === owner.selected.ID);
        if (inLeft && Array.isArray(inLeft) && inLeft.length > 0) {
          const slected = owner.selected;
          setOwner((owner) => {
            return {
              left: owner.left.filter((o) => o.ID !== slected.ID),
              selected: "",
              right: [...owner.right, slected],
            };
          });
        }
      }
    }
  };
  const Validation = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emailisvalid = emailRegex.test(form.Email);
    if (!emailisvalid && form.Email != "") {
      alert("Please Enter Valid Email Id.");
      return;
    }
    if (form.password != "") {
      let _pattern =
        /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/;
      if (!_pattern.test(form.password)) {
        alert("Please Enter Valid Password.");
        return;
      }
    }
    if (form.userRole == "") {
      alert("Please Select User Role");
      return;
    } else if (
      form.firstName == "" &&
      form.UserRole_ID !== "MD Dealer" &&
      form.UserRole_ID !== "Dealer"
    ) {
      alert("Please Enter First Name");
      return;
    } else if (
      form.lastName == "" &&
      form.UserRole_ID !== "MD Dealer" &&
      form.UserRole_ID !== "Dealer"
    ) {
      alert("Please Enter Last Name");
      return;
    } else if (
      form.md_selected_dealer == "" &&
      form.UserRole_ID == "MD Dealer"
    ) {
      alert("Please Select MD Dealer");
      return;
    } else if (
      form.employeeCode == "" &&
      form.UserRole_ID !== "MD Dealer" &&
      form.UserRole_ID !== "Dealer"
    ) {
      alert("Please Enter Employee Code");
      return;
    } else if (form.department == "") {
      alert("Please Select department");
      return;
    } else if (form.password == "") {
      alert("Please Enter Password");
      return;
    } else if (form.Email == "") {
      alert("Please Enter Email");
      return;
    } else if (form.state == "") {
      alert("Please Select State");
      return;
    } else if (form.UserRole_ID == "Dealer" && form.selected_dealer == "") {
      alert("Please Select Dealer");
      return;
    } else if (form.UserRole_ID != "Dealer" && selectedUser.length === 0) {
      let str =
        "Please Select Atleast One " +
        (header.headerName ? header.headerName : "Dealer");
      alert(str);
      return;
    } else {
      setDoubleClick(true);
      handleSave();
    }
  };

  const handleSave = () => {
    let dept_type = options.department.filter((e) => e.ID == form.department)[0]
      ?.Department;
    dept_type = dept_type?.toUpperCase();
    let dealerID = 0;
    let username = "";
    if (form.userRole == 1) {
      options.DealerList.map((e, idx) => {
        if (e.Dealer_Name === form.selected_dealer) {
          dealerID = e.ID;
          let stringWithHyphen = e.Dealer_Vehicle_Code;
          let stringWithoutHyphen = stringWithHyphen.replace(/-/g, "");
          username = form.UserInitials + stringWithoutHyphen + dept_type;
        }
      });
    } else if (form.dealer_vehicle_code != "") {
      options.MD_dealer_list.map((e, idx) => {
        if (e.Dealer_Name === form.md_selected_dealer) {
          dealerID = e.ID;
          let stringWithHyphen = e.dealer_vehicle_code;
          let stringWithoutHyphen = stringWithHyphen.replace(/-/g, "");
          username = form.UserInitials + stringWithoutHyphen + dept_type;
        }
      });
    } else {
      username = form.UserInitials + form.employeeCode + dept_type;
    }

    let salt = bcrypt.genSaltSync(10);
    let encryptUserPass = form.ID
      ? form.password
      : bcrypt.hashSync(form.password, salt);
    fetch(`${Urls.python_baseurl}/SP_Save_Users`, {
      ...fetchMethod,
      body: JSON.stringify({
        ID: `${form.ID}`,
        Employee_Code: `${form.employeeCode ? form.employeeCode : 0}`,
        UserDept_ID: `${form.department}`,
        UserRole_ID: `${form.userRole}`,
        Ownership: `'${selectedUser.join(",")}'`,
        State_ID: `${form.state}`,
        Email: `'${form.Email}'`,
        LoginName: `'${form.ID ? form.username : username}'`,
        password: `'${encryptUserPass}'`,
        Is_AdminCreatedPwd: `''`,
        Is_Active: "1",
        childroleid: `${header.userRoleID ? header.userRoleID : 0}`,
        dealer_ID: `${dealerID}`,
        FirstName: `'${form.firstName}'`,
        LastName: `'${form.lastName}'`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          // console.log(res);
          if (form.UserRole_ID === "Dealer") {
            if (location?.state?.isUpdate) {
              if (res[0][0].ID) {
                fetch(`${Urls.python_baseurl}/SP_Save_Sys_Users`, {
                  ...fetchMethod,
                  body: JSON.stringify({
                    UserId: `${res[0][0].ID}`,
                    DUserID: `${dealerID}`,
                  }),
                })
                  .then((res) => res.json())
                  .then((res) => {
                    alert("Record Updated Successfully!!");
                    history.push("/UserList");
                  });
              } else {
                setDoubleClick(false);
                alert(res[0][0].Message);
              }
            } else {
              if (res[0][0].newID) {
                fetch(`${Urls.python_baseurl}/SP_Save_Sys_Users`, {
                  ...fetchMethod,
                  body: JSON.stringify({
                    UserId: `${res[0][0].newID}`,
                    DUserID: `${dealerID}`,
                  }),
                })
                  .then((res) => res.json())
                  .then((res) => {
                    alert("Record Saved Successfully!!");
                    history.push("/UserList");
                  });
              } else {
                setDoubleClick(false);
                alert(res[0][0].Message);
              }
            }
          } else {
            if (location?.state?.isUpdate) {
              fetch(`${Urls.python_baseurl}/Save_M_User_Hierarchy`, {
                ...fetchMethod,
                body: JSON.stringify({
                  ID: res[0][0].ID,
                }),
              })
                .then((res) => res.json())
                .then((res) => {
                  alert("Record Updated Successfully!!");
                  history.push("/UserList");
                });
            } else {
              fetch(`${Urls.python_baseurl}/Save_M_User_Hierarchy`, {
                ...fetchMethod,
                body: JSON.stringify({
                  ID: res[0][0].newID,
                }),
              })
                .then((res) => res.json())
                .then((res) => {
                  alert("Record Saved Successfully!!");
                  history.push("/UserList");
                });
            }
          }
        }
      });
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const params = query.get("id");
    if (params) {
      fetch(`${Urls.python_baseurl}/SP_Get_Users`, {
        ...fetchMethod,
        body: JSON.stringify({
          ID: params,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(res,"kjkjj")
          const obj = res[0][0];
          setForm((form) => {
            return {
              ...form,
              firstName: obj.FirstName,
              lastName: obj.LastName,
              employeeCode: obj.Employee_Code,
              userRole: obj.UserRole_ID,
              department: obj.UserDept_ID,
              state: obj.State_ID,
              username: obj.LoginName,
              password: obj.password,
              Email: obj.Email,
              ID: obj.ID,
              FormHistoryDealerID: obj.Dealer_ID,
              UserRole_ID: props?.location?.state?.SelectedRow?.UserRole,
              Dealer_Name: obj?.Dealer_Name,
              md_selected_dealer: obj?.Dealer_Name ? obj?.Dealer_Name : "",
              dealer_vehicle_code: obj?.Dealer_vehicle_code
                ? obj?.Dealer_vehicle_code
                : "",
            };
          });
        });
    }
  }, []);
  const showASMUser = () => {
    let columnDefs = [];
    if (header.headerName == "Dealer" || form.UserRole_ID == "MD Dealer") {
      columnDefs = [
        {
          headerName: "",
          field: "",
          width: "60px",
          checkboxSelection: true,
          cellStyle: (params) => {
            return params.data.flag == 1
              ? { "pointer-events": "none", opacity: "0.4" }
              : "";
          },
        },

        {
          headerName: "Dealer Code",
          field: "Dealer_Vehicle_Code",
          width: "150px",
        },
        {
          headerName: "Dealer Name",
          field: "Dealer_Name",
          flex: "1",
        },
        {
          headerName: "Dealer Mobile",
          field: "Dealer_Mobile",
          flex: "1",
        },
        {
          headerName: "Department",
          flex: "1",
          cellRenderer:(params=>{
            return options?.department?.filter(e=>e.ID==params.data.Department_ID)[0].Department
          })
        },
      ];
    } else {
      columnDefs = [
        {
          headerName: "",
          field: "",
          width: "60px",
          checkboxSelection: true,
          cellStyle: (params) => {
            return params.data.flag == 1
              ? { "pointer-events": "none", opacity: "0.4" }
              : "";
          },
        },
        {
          headerName: "First Name",
          field: "FirstName",
          flex: "1",
        },
        {
          headerName: "Last Name",
          field: "LastName",
          flex: "1",
        },
        {
          headerName: "User Name",
          field: "LoginName",
          flex: "1",
        },
        {
          headerName: "Email",
          field: "Email",
          flex: "1",
        },
        {
          headerName: "Employee Code",
          field: "Employee_Code",
          flex: "1",
        },
      ];
    }
    if (owner.left.length > 0) {
      return (
        <div>
          <div
            className="ag-theme-balham"
            style={{ padding: "0", marginTop: "20px" }}
          >
            <AgGridReact
              modules={agOption.modules}
              columnDefs={columnDefs}
              autoGroupColumnDef={agOption.autoGroupColumnDef}
              defaultColDef={agOption.defaultColDef}
              suppressRowClickSelection={true}
              groupSelectsChildren={true}
              debug={true}
              rowSelection={agOption.rowSelection}
              rowGroupPanelShow={agOption.rowGroupPanelShow}
              pivotPanelShow={agOption.pivotPanelShow}
              enableRangeSelection={true}
              paginationAutoPageSize={false}
              pagination={true}
              overlayLoadingTemplate={agOption.overlayLoadingTemplate}
              domLayout={agOption.domLayout}
              paginationPageSize={agOption.paginationPageSize}
              rowData={owner.left}
              enableCellTextSelection={true}
              onRowSelected={(event) => {
                if (header.headerName == "Dealer") {
                  if (event.node.isSelected()) {
                    setselectedUser((prev) => {
                      return [...prev, event.node.data.mr_id];
                    });
                  } else {
                    if (selectedUser.length > 0) {
                      setselectedUser(
                        selectedUser.filter(
                          (item) => item !== event.node.data.mr_id
                        )
                      );
                    }
                  }
                } else {
                  if (event.node.isSelected()) {
                    setselectedUser((prev) => {
                      return [...prev, event.node.data.mr_id];
                    });
                  } else {
                    if (selectedUser.length > 0) {
                      setselectedUser(
                        selectedUser.filter(
                          (item) => item !== event.node.data.mr_id
                        )
                      );
                    }
                  }
                }
              }}
              onFirstDataRendered={({ api }) => {
                api.forEachNode((node) => {
                  const isSelected = node.data.flag == 2;
                  node.setSelected(isSelected);
                });
              }}
            ></AgGridReact>
          </div>
        </div>
      );
    }
  };
  return (
    <div className="form-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h4 className="heading_text" style={{ margin: "0" }}>
          User Creation
        </h4>
        <div className="actions_save">
          <button
            disabled={doubleClick}
            className="btn Opertion_btn clickable mr-3"
            onClick={Validation}
          >
            Save
          </button>
          <button
            disabled={doubleClick}
            className="btn Opertion_btn clickable mr-3"
            style={{ marginLeft: "20px" }}
            onClick={() => history.push("/UserList")}
          >
            Cancel
          </button>
        </div>
      </div>
      <div className="formInputs">
        <div className="form-input ">
          <label>
            User Role<span style={{ color: "red" }}>*</span>
          </label>
          <select
            disabled={form.ID ? true : false}
            class="form-control"
            name="userRole"
            value={form.userRole}
            onChange={handleInputChange}
          >
            <option selected value="" disabled>
              Select
            </option>
            {options.userRole.map((e, idx) => {
              return (
                <option key={idx} value={e.ID}>
                  {e.UserRole}
                </option>
              );
            })}
          </select>
        </div>
        {form?.UserRole_ID == "MD Dealer" &&
          form?.userRole !== "" &&
          options?.MD_dealer_list &&
          !form.ID && (
            <div className="form-input">
              <label>
                MD Dealer List <span style={{ color: "red" }}>*</span>
              </label>
              <input
                autoComplete="off"
                className="form-control"
                list="md_selected_dealer"
                value={form.md_selected_dealer}
                name="md_selected_dealer"
                onChange={handleInputChange}
              />

              <datalist id="md_selected_dealer" style={{ maxHeight: "20em" }}>
                {options &&
                  options?.MD_dealer_list &&
                  options?.MD_dealer_list.length > 0 &&
                  options.MD_dealer_list.map((e, idx) => {
                    // console.log(e);
                    return <option key={idx}>{e.Dealer_Name}</option>;
                  })}
              </datalist>
            </div>
          )}
        {form?.UserRole_ID == "MD Dealer" &&
        form?.userRole !== "" &&
        options?.MD_dealer_list &&
        form.ID ? (
          <div className="form-input">
            <label>
              MD Dealer List <span style={{ color: "red" }}>*</span>
            </label>
            <input
              autoComplete="off"
              className="form-control"
              list="md_selected_dealer"
              value={form.Dealer_Name}
              name="md_selected_dealer"
              onChange={handleInputChange}
              disabled
            />
          </div>
        ) : (
          ""
        )}
        {form.UserRole_ID !== "MD Dealer" && form.UserRole_ID !== "Dealer" && (
          <div className="form-input">
            <label>
              First Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              // hidden={form.userRole != "" && (form.UserRole_ID=="MD Dealer"||form.UserRole_ID=="Dealer") && !form.ID ? false : true}
              class="form-control"
              type="text"
              name="firstName"
              value={form.firstName}
              onChange={handleInputChange}
            />
          </div>
        )}
        {form.UserRole_ID !== "MD Dealer" && form.UserRole_ID !== "Dealer" && (
          <div className="form-input">
            <label>
              Last Name<span style={{ color: "red" }}>*</span>
            </label>
            <input
              class="form-control"
              type="text"
              name="lastName"
              value={form.lastName}
              onChange={handleInputChange}
            />
          </div>
        )}
        {/* <div className="form-input">
          <label>
            Username<span style={{ color: "red" }}>*</span>
          </label>
          <input
            disabled={form.ID ? true : false}
            class="form-control"
            type="text"
            name="username"
            value={form.username}
            onChange={handleInputChange}
          />
        </div> */}
        <div className="form-input">
          <label>
            Password<span style={{ color: "red" }}>*</span>
          </label>
          <input
            disabled={form.ID ? true : false}
            class="form-control"
            type="password"
            name="password"
            value={form.password}
            onChange={handleInputChange}
          />
        </div>
        {form.UserRole_ID !== "MD Dealer" && form.UserRole_ID !== "Dealer" && (
          <div className="form-input">
            <label>
              Employee Code<span style={{ color: "red" }}>*</span>
            </label>
            <input
              class="form-control"
              type="text"
              name="employeeCode"
              value={form.employeeCode}
              onChange={handleInputChange}
              disabled={form.userRole != "" && !form.ID ? false : true}
            />
          </div>
        )}
        <div className="form-input">
          <label>
            Email<span style={{ color: "red" }}>*</span>
          </label>
          <input
            disabled={form.ID ? true : false}
            class="form-control"
            type="text"
            name="Email"
            value={form.Email}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-input ">
          <label>
            Department<span style={{ color: "red" }}>*</span>
          </label>
          <select
            class="form-control"
            name="department"
            value={form.department}
            onChange={handleInputChange}
            disabled={form.userRole != "" && !form.ID ? false : true}
          >
            <option selected value="" disabled>
              Select
            </option>
            {options.department.map((e, idx) => {
              return (
                <option key={idx} value={e.ID}>
                  {e.Department}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form-input">
          <label>
            State<span style={{ color: "red" }}>*</span>
          </label>
          <select
            class="form-control"
            name="state"
            value={form.state}
            disabled={form.userRole != "" && !form.ID ? false : true}
            onChange={handleInputChange}
          >
            <option selected value="" disabled>
              Select
            </option>
            {options.state.map((e, idx) => {
              return (
                <option key={idx} value={e.ID}>
                  {e.Name}
                </option>
              );
            })}
          </select>
        </div>

        {form?.userRole == 1 &&
          form?.userRole !== "" &&
          form?.state !== "" &&
          options?.DealerList &&
          !form.ID && (
            <div className="form-input">
              <label>
                Dealer List <span style={{ color: "red" }}>*</span>
              </label>
              <input
                autoComplete="off"
                className="form-control"
                list="selected_dealer"
                value={form.selected_dealer}
                name="selected_dealer"
                onChange={handleInputChange}
              />

              <datalist id="selected_dealer" style={{ maxHeight: "20em" }}>
                {options &&
                  options?.DealerList &&
                  options?.DealerList.length > 0 &&
                  options.DealerList.map((e, idx) => {
                    // console.log(e);
                    return <option key={idx}>{e.Dealer_Name}</option>;
                  })}
              </datalist>
            </div>
          )}
        {form?.userRole == 1 &&
        form?.userRole !== "" &&
        form?.state !== "" &&
        options?.DealerList &&
        form.ID ? (
          <div className="form-input">
            <label>
              Dealer List <span style={{ color: "red" }}>*</span>
            </label>
            <input
              autoComplete="off"
              className="form-control"
              list="selected_dealer"
              value={form.Dealer_Name}
              disabled={form.userRole != "" && !form.ID ? false : true}
            />
          </div>
        ) : (
          ""
        )}
        {/* {(form?.userRole != 1 && form?.userRole !== "") &&
          <div className="form-input transfer_box">
            <label>Ownership</label>
            <div className="boxes">
              <div className="rect_1">
                <ul>
                  {owner?.left?.map((o) => {
                    return (
                      <li
                        style={
                          o.ID === owner?.selected?.ID
                            ? {
                              backgroundColor: "wheat",
                            }
                            : {}
                        }
                        onClick={() => handleList(o, "select")}
                      >
                        {o.firstName && o.lastName && o.LoginName ? o.firstName + " " + o.lastName + "(" + o.LoginName + ")" : ""}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="icons">
                <span onClick={() => handleList("_", "right")}> &#8594;</span>
                <span onClick={() => handleList("_", "left")}>&#8592;</span>
              </div>
              <div className="rect_1">
                <ul>
                  {owner?.right?.map((o) => {
                    return (
                      <li
                        style={
                          o.ID === owner?.selected?.ID
                            ? {
                              backgroundColor: "wheat",
                            }
                            : {}
                        }
                        onClick={() => handleList(o, "select")}
                      >
                        {o.firstName && o.lastName && o.LoginName ? o.firstName + " " + o.lastName + "(" + o.LoginName + ")" : ""}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        } */}
      </div>
      <h6
        className="heading_text"
        style={{ marginTop: "25px", marginLeft: "0px", fontSize: "16px" }}
      >
        {form?.userRole &&
          form?.dealer_vehicle_code != "" &&
          owner.left.length > 0 &&
          "Dealer List"}
        {form?.userRole &&
        form?.userRole != 1 &&
        owner.left.length > 0 &&
        form.UserRole_ID != "MD Dealer"
          ? header.headerName
            ? header.headerName + " List"
            : ""
          : ""}
      </h6>
      <div>
        {form?.userRole &&
          form?.userRole != 1 &&
          form.UserRole_ID != "MD Dealer" &&
          showASMUser()}
      </div>
      <div>
        {form?.userRole && form?.dealer_vehicle_code != "" && showASMUser()}
      </div>
    </div>
  );
}
