import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

const bcrypt = require("bcryptjs");

export class Dashboard_prototype extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      Active: "",
      emp_name: "",
      emp_type: "",
      contact_no: "",
      Empl_code: "",
      Date_of_joining: "",
      email: "",
      id: 0,
      Empolyee_Type_option: [],
      reactCode: '',
      show:false,
      Tilename:''
    };
  }
  componentDidMount() {
 

  }
  onSelect = (event, name) => {
    this.setState({
      Type: event.target.options[event.target.selectedIndex].value,
    });
  };



  codeGen = () => {
    var labelArray = this.state.Tilename.split(',');

    var summary = [];

    labelArray.forEach(function (label) {
      var columnDef = {
        name:label.trim(),
        value:'0',
        color: "orange",
        // url:Strings.Sales_DB
      };
      summary.push(columnDef);
    })
    let reactCode = `import React from "react";
    import moment from "moment";
    import Logo from "../../components/Images/Logo.png";
    import "../../containers/color.css";
    import Urls from "../../../helpers/Urls";
    import { Tiles } from "../../components/Tiles";
    import ReactApexChart from "react-apexcharts";
    import Strings from "../../../helpers/Strings";
    export class Testing_prototype extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          showTable: true,
          paginationPageSize: 10,
          domLayout: "autoHeight",
          autoGroupColumnDef: {
            headerName: "Group",
            minWidth: 100,
            field: "athlete",
            valueGetter: function (params) {
              if (params.node.group) {
                return params.node.key;
              } else {
                return params.data[params.colDef.field];
              }
            },
            headerCheckboxSelection: true,
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: { checkbox: true },
          },
          defaultColDef: {
            cellClass: "cell-wrap-text",
            autoHeight: true,
            sortable: true,
            resizable: true,
            filter: true,
          },
          rowSelection: "never",
          rowGroupPanelShow: "never",
          pivotPanelShow: "never",
          rowData: [],
          startdate:"",
          enddate:"",
          pichart: {
            series: [],
            options: {
              chart: {
                // width: 380,
                type: "pie",
              },
              labels: [],
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f}%",
                    distance: -50,
                    style: {
                      color: "black",
                    },
                  },
                },
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: "left",
                    },
                  },
                },
              ],
            },
          },
          pichart_2: {
            series: [],
            options: {
              chart: {
                // width: 380,
                type: "pie",
              },
              labels: [],
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: true,
                    format: "<b>{point.name}</b>: {point.percentage:.1f}%",
                    distance: -50,
                    style: {
                      color: "black",
                    },
                  },
                },
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: "left",
                    },
                  },
                },
              ],
            },
          },
          barChartOptions: {
            chart: {
              type: "bar",
              // width: 550,
            },
            xaxis: {
              categories: ["Q1", "Q2", "Q3", "Q4"],
              title: {
                text: "Quarter",
              },
            },
            yaxis: {
              title: {
                text: "Sales",
              },
            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return "INR" + value;
                },
              },
            },
            series: [
              {
                name: "Sales",
                data: [400, 430, 448, 567],
              },
            ],
          },
          summeryDetails: {
            followup: 0,
            pendingm1count: 0,
            PendingPDI:0,
            PendingforMoneyreceived: 0,
            PDI: 1,
            vehiclestock: 0,
            PendingforBilling: 0,
            PendingforAllocation:0,
            B2CSales:0,
            D2D:0,
            D2DDeliveryChallan:0,
            DelarSales:0,
            TotalPDI:0,
            DealerSalesOrder:0
          },
          options:{},
          options2:{}
        };
      }
      componentDidMount() {
        this.showTiles()
        
        }
    
      showTiles = () => {
        let url='';
        let data=${JSON.stringify(summary)}
         
         return data.map((item) => {
        
          console.log(window.innerWidth, "kkk");
          return (
            <div className="form-group">
              <Tiles
                isClickable={item.name}
                header_text={item.name}
                value={item.value}
                isenrollment={true}
                color={item.color}
                differentTile={true}
                url={url}
                width={"210px"}
              />
            </div>
          );
        });
      };
      showPieChart = () => {
        return (
          <div style={{margin:"auto"}} className=" pt-3">
            <ReactApexChart
              options={this.state.options}
              series={this.state.pichart.series}
              type="pie"
              width={350}
            />
          </div>
        );
      };
      showSecondPieChart = () => {
        return (
          <div style={{ margin: "auto" }} className="pt-3">
            <ReactApexChart
              options={this.state.options2}
              series={this.state.pichart_2.series}
              type="pie"
              width={350}
            />
          </div>
        );
      };
      showPieChart_2 = () => {
        return (
          <div style={{margin:"auto"}} className=" pt-3">
            <ReactApexChart
              options={this.state.options_sales}
              series={this.state.pichart_sales.series_seles}
              type="pie"
              width={350}
            />
          </div>
        );
      };
      showFilterOption = () => {
        return (
          <div className=" row mt-3">
            {/* <div className="col-3">
              <label htmlFor="dealer-name">Dealer Name</label>
              <input className=" form-control" list="dealer-list"></input>
              <datalist id="dealer-list">
                <option>Electra Motors</option>
                <option>Quantum Charge Autos</option>
                <option>EcoSpark Dealerships</option>
                <option>Lightning Wheels Hub</option>
                <option>GreenDrive Emporium</option>
                <option>ElectriCruisers Outlet</option>
                <option>TeslaSphere Motors</option>
                <option>CurrentFlow Auto Center</option>
                <option>PowerPulse Cars</option>
                <option>SparkDrive Dealers</option>
              </datalist>
            </div> */}
            {/* <div className=" col-3">
              <label>From Date</label>
              <input value={this.state.startdate} className=" form-control " type="date"></input>
            </div>
            <div className=" col-3">
              <label>To Date</label>
              <input value={this.state.enddate} className=" form-control " type="date"></input>
            </div> */}
          </div>
        );
      };
    
      showBarChart = () => {
        return (
          <ReactApexChart
            options={this.state.barChartOptions}
            series={this.state.barChartOptions.series}
            type={this.state.barChartOptions.chart.type}
            // width={400}
          />
        );
      };
      render() {
        return (
          <div style={{ overflowX: "hidden" }} className=" p-2">
            <h4 style={{ marginLeft: "0px" }} className="heading_text">
              Dashboard
            </h4>
            {this.showFilterOption()}
            <br></br>
            <div className=" form-row mt-3">{this.showTiles()}</div>
            <div className=" row ">
              <div className=" card col-5 ml-3">
                <h6 className=" mt-2">Model Wise Enquiries</h6>
                {/* {this.showPieChart()} */}
              </div>
              <div className=" card col-6 ml-4">
                <h6 className=" mt-2">Model Wise Retail Sale</h6>
                {/* {this.showSecondPieChart()} */}
              </div>
              {/* <div className=" card col-6 ml-3">
                <h6 className=" mt-2">Vehicle Invoice</h6>
                {this.showBarChart()}
              </div> */}
            </div>
          </div>
        );
      }
    }
    
    `


    this.setState({
      reactCode: reactCode,
      show:true
    })
  }
  handledateChange = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  };

  header = () => {
    return (
      <div className=" card p-3">
        <div className="row">

          <div className="col-3 mt-2">
           
          </div>
        </div>
        <div className="row">

          <div className="col-3 mt-2">
            <label htmlFor="active">Enter the Tiles Name <span style={{ color: "red" }}>*</span></label>
            <textarea

              autoComplete='off'
              value={this.state.Tilename}
              onChange={(event) => {
                this.handledateChange(event, "Tilename");
              }}

              id="model-c"
              className=" form-control"
            ></textarea>
          </div>
          {/* <div className="col-3 mt-2">
            <label htmlFor="active">Enter the Table Column <span style={{ color: "red" }}>*</span></label>
            <textarea

              autoComplete='off'
              value={this.state.Tablenm}
              onChange={(event) => {
                this.handledateChange(event, "Tablenm");
              }}

              id="model-c"
              className=" form-control"
            ></textarea>
          </div>
          <div className="col-3 mt-2">
            <label htmlFor="active">Pie chart</label>
            <input  id="model-c"
               type="checkbox"></input>
          </div>
          <div className="col-3 mt-2">
            <label htmlFor="active">Table Chart</label>
            <input  id="model-c"
               type="checkbox"></input>
          </div> */}
          <div className="col-3 mt-2" >
            <button style={{ marginTop: "25px" }}
              type="submit"
              className="btn Opertion_btn clickable mr-5"
              onClick={this.codeGen}
            >
              Get Code
            </button>
          </div>

        </div>
        <div className="row">

          {/* 
          <div className="col-6 mt-6">
            <textarea

              autoComplete='off'
              value={this.state.reactCode}
              id="model-c"
              className=" form-control"
            ></textarea >
          </div> */}
          {this.state.show ?
          <div className="col-11 mt-6" contentEditable="true">
            <SyntaxHighlighter style={dracula}>
              {this.state.reactCode}
              <button className="copy-button" onClick={this.copyToClipboard}>
                <FontAwesomeIcon icon={faCopy} />
              </button>
            </SyntaxHighlighter>
          </div>:null}
        </div>
      </div>
    );
  };
  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.reactCode);
    // You can also provide feedback to the user that the code has been copied, if needed
    alert('Code copied to clipboard');
  };
  addnew = () => {
    this.setState({
      Active: "",
      emp_name: "",
      emp_type: "",
      contact_no: "",
      id: 0,
      Empl_code: "",
      email: "",
      Date_of_joining: ""
    });
  };


  render() {
    return (
      <div>
        <h4 className="heading_text"></h4>
  
        <div
          class="form-group col-md-12 mt-2"
        >
          {this.header()}

        </div>
      </div>
    );
  }
}
