import React from "react";
import moment from "moment";
import "../Dealer_To_Dealer_Tax_Invoice/index.css";
import Logo from "../../../components/Images/Logo.png";
import "../../../containers/color.css";
import Urls from "../../../../helpers/Urls";
import Strings from "../../../../helpers/Strings";
import {
  setDomLayoutAfterPrint,
  setDomLayoutForPrint,
} from "../../../../helpers/getDetails";
export class Quotation_Invoice extends React.Component {
  constructor(props) {
    console.log(props,"dasjkdhdgh")
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 100,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      conditioncode: props.location.state?.data?.ConditionCode
        ? props.location?.state?.data?.ConditionCode
        : [],
    };
  }
  componentDidMount() {
    if (this?.props?.location?.state?.data) {
      this.setState(
        {
          EnquiryDetails: this.props.location?.state?.data,
        },
        () => {
          var tempTitle = document.title;
          document.title = "VIN-"+moment().year()+"-"+this.state.EnquiryDetails.InvoiceNo;
          window.print();
          document.title = tempTitle;
          localStorage.setItem("FromHistory", true);
          this.props.history.push({
            pathname: Strings.vehical_invoice,
            state: {
              id: this.props.location.state.id,
              modelid: this.props.location.state.modelid,
              Status: this.props.location.state.Status,
              name: this.props.location.state.name,
              M_7id: this.props.location.state.M_7id,
            },
          });
        }
      );
    }
  }

  convertToWord = (num) => {
    if (!num) return;
    let splittedNum = num.toString().split(".");
    let nonDecimal = splittedNum[0];
    let decimal = splittedNum[1];
    let TotalAmountWithTaxWord =
      this.price_in_words(Number(nonDecimal)) +
      (decimal ? " Rupees and" : "") +
      this.price_in_words(Number(decimal)) +
      (decimal ? "paise" : "");
    return TotalAmountWithTaxWord;
  };
  price_in_words = (price) => {
    var sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt
          ? ""
          : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (
          (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];

    // Check if the price is a valid number
    if (((price += ""), isNaN(parseFloat(price)))) str = "";
    else if (parseFloat(price) > 0 && price.length <= 15) {
      // Adjusted to handle larger numbers including decimal places
      var priceParts = price.split("."); // Split the price into integer and fractional parts
      var integerPart = priceParts[0];
      var fractionalPart = priceParts[1] || "0"; // If there is no fractional part, default to '0'

      // Process integer part
      for (digitIdx = integerPart.length - 1; digitIdx >= 0; digitIdx--) {
        digit = integerPart[digitIdx] - 0;
        nxtDigit = digitIdx > 0 ? integerPart[digitIdx - 1] - 0 : 0;
        switch (integerPart.length - digitIdx - 1) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != integerPart[digitIdx + 1] &&
                    0 != integerPart[digitIdx + 2]
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != integerPart[digitIdx + 1] ||
                    0 != integerPart[digitIdx + 2]
                      ? " and"
                      : " Crore")
                : ""
            );
        }
      }

      str = words.reverse().join("");
    } else str = "";
    return str;
  };
  Invoice = () => {
    return (
      <div>
        <h4 className=" invoice-header heading_text">Tax Invoice</h4>
        <div>
          <table className="invoice-container" >
            <tr>
              <div className=" pl-2">
                <p className=" bold">{sessionStorage.getItem("dealer_name")}</p>
                <p>{sessionStorage.getItem("Dealer_Address")}</p>
                <p>
                  <span className=" bold">Phone</span> :{" "}
                  {sessionStorage.getItem("Dealer_Contact")}
                </p>
                <p>
                  <span className=" bold">Email</span> :{" "}
                  {sessionStorage.getItem("Dealer_Email")}
                </p>
                <p>
                  <span className=" bold">GST No.</span> :{" "}
                  {sessionStorage.getItem("Dealer_Gst_No")}
                </p>
              </div>
            </tr>
            <tr>
              <td>
                <div className="bold">
                  {this.props.location.state?.data?.Name.toUpperCase()}
                </div>
                <div>
                  {" "}
                  {this.props.location.state?.data?.address1},{" "}
                  {this.props.location.state?.data?.City},{" "}
                  {this.props.location.state?.data?.District},{" "}
                  {this.props.location.state?.data?.State}-
                  {this.props.location.state?.data?.pincode}
                </div>
                <div>
                  <span className="bold">Mobile No:</span>
                  <span> {this.props.location.state?.data?.mobile_no}</span>
                </div>
                <div>
                  <span className="bold">Email:</span>
                  <span> {this.props.location.state?.data?.email}</span>
                </div>
                {this.props.location.state?.data?.GSTIN ? (
                  <div>
                    <span className="bold">Customer GST No:</span>
                    <span> {this.props.location?.state?.data?.GSTIN}</span>
                  </div>
                ) : (
                  ""
                )}
              </td>
              <td>
                <div>
                  <span className="bold">Invoice No:</span>
                  <span> {this.props.location.state?.data?.InvoiceNo}</span>
                </div>
                <div>
                  <span className="bold">Invoice Date:</span>
                  <span> {this.props.location.state?.data?.InvoiceDate}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td className="bold">Vehicle Information</td>
              <td className=" row justify-content-center border-0 ">
                <b>Sales Bill Amount</b>
              </td>
            </tr>
            <tr>
              <td style={{ verticalAlign: 'top' }}>
                <div>
                  <div className="mt-1  row px-3">
                    <div className="bold quotation_name">
                      <span>Model Name</span> <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {this.props.location?.state?.data?.Model_Name}
                    </div>
                  </div>
                  <div className="mt-1 row px-3">
                    <div className="bold quotation_name">
                      <span>Colour</span> <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {" "}
                      {this.props.location.state?.data?.Model_Color}
                    </div>
                  </div>
                  <div className="mt-1 row px-3">
                    <div className="bold quotation_name">
                      <span>Quantity</span> <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {" "}
                      {this.props.location.state?.data?.Quantity}
                    </div>
                  </div>
                  <div className="mt-1 row px-3">
                    <div className="bold quotation_name">
                      <span>Chassis No.</span> <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {" "}
                      {this.props.location.state?.data?.Chassis_No}
                    </div>
                  </div>
                  <div className="mt-1  row px-3">
                    <div className="bold quotation_name">
                      <span>Battery No.</span> <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {this.props.location?.state?.data?.Actual_Battery_No?this.props.location?.state?.data?.Actual_Battery_No:this.props.location?.state?.data?.Battery_No}
                    </div>
                  </div>
                  <div className="mt-1  row px-3">
                    <div className="bold quotation_name">
                      <span>Motor No.</span> <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {this.props.location.state?.data?.ActualMotor_No?this.props.location.state?.data?.ActualMotor_No:this.props.location.state?.data?.Motor_No}
                    </div>
                  </div>
                  <div className="mt-1  row px-3">
                    <div className="bold quotation_name">
                      <span>Charger No.</span> <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {this.props.location.state?.data?.Actual_Charger_No?this.props.location.state?.data?.Actual_Charger_No:this.props.location.state?.data?.Charger_No}
                    </div>
                  </div>
                  <div className="mt-1  row px-3">
                    <div className="bold quotation_name">
                      <span>Controller No.</span>{" "}
                      <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {this.props.location?.state?.data?.ActualController_Unit_No?this.props.location?.state?.data?.ActualController_Unit_No:this.props.location?.state?.data?.Controller_No}
                    </div>
                  </div>
                  <div className="mt-1  row px-3">
                    <div className="bold quotation_name">
                      <span>IOT No.</span>{" "}
                      <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {this.props.location?.state?.data?.ActualIOT_Device_ID?this.props.location?.state?.data?.ActualIOT_Device_ID:this.props.location?.state?.data?.IOTDevice_No}
                    </div>
                  </div>
                  <div className="mt-1  row px-3">
                    <div className="bold quotation_name">
                      <span>Display No.</span>{" "}
                      <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {this.props.location?.state?.data?.ActualDispayNo?this.props.location?.state?.data?.ActualDispayNo:this.props.location?.state?.data?.Display_No}
                    </div>
                  </div>
                  <div  className="mt-1  row px-3">
                    <div className="bold quotation_name">
                      <span>Vehicle No.</span>{" "}
                      <span className="mr-1"> : </span>
                    </div>
                    <div className="itemvalue">
                      {this.props.location?.state?.data?.Vehicle_No}
                    </div>
                  </div>
                  {this.props.location?.state?.data?.Scheme_Amt!=0&&<div style={{border:"1px solid #ddd", padding:"5px", marginTop:"10px", fontFamily:"mogra"}}>
                    <div style={{fontWeight:"600"}} className="mt-1 row px-3">
                      With this purchase you have availed the following ongoing Scheme Benefits :
                    </div>
                    <div className="mt-1 row px-3">
                      <div style={{fontWeight:"700"}}>
                        <span><span className=" text-danger ">*</span> Discount</span> <span> : </span> <span> ₹{" "}{this.props.location?.state?.data?.Scheme_Amt} (on Ex-showroom Price)</span>
                      </div>
                    </div>
                    <div>
                      {!this.props.location?.state?.data?.AMV_Insurance_Amount&&<div style={{fontWeight:"600"}}><span className=" text-danger ">*</span> Discount on Insurance (FREE INSURANCE)</div>}
                      <div style={{fontWeight:"600"}}>
                        M/S {sessionStorage.getItem("dealer_name")} CONGRATULATE you on the purchase of Joy-E Bike Model Name {`"${this.props.location?.state?.data?.Model_Name}"`} and Wishes Happy Riding.
                      </div>
                    </div>
                    </div>}
                </div>
                
              </td>
              <td>
                <table>
                  <tr>
                    <td className="bold quotation_name1 left-border">
                      Particular
                    </td>
                    <td className=" bold itemvalue2 right-border">Amount</td>
                  </tr>
                  <tr>
                    <td className="bold quotation_name1 left-border">
                    Ex Showroom Price
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.AMV_Sale_Rate &&
                        this.props.location?.state?.data?.AMV_Sale_Rate.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                 
                  
                  <tr>
                    <td className="bold quotation_name1 left-border">
                    State Subsidy Amount
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.StateSubsidyAmt &&
                        this.props.location?.state?.data?.StateSubsidyAmt.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bold quotation_name1 left-border">
                    Central Subsidy Amount
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.CentralSubsidyAmt &&
                        this.props.location?.state?.data?.CentralSubsidyAmt.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  
                  {this.props.location?.state?.data?.showIGST && (
                    <tr>
                      <td className="bold quotation_name1 left-border">
                        IGST ({this.props.location?.state?.data?.IGST}%)
                      </td>
                      <td className=" itemvalue2 right-border">
                        {" "}
                        ₹{" "}
                        {this.props.location?.state?.data?.IGST_Amount &&
                          this.props.location?.state?.data?.IGST_Amount.toFixed(
                            2
                          )}
                      </td>
                    </tr>
                  )}
                  {!this.props.location?.state?.data?.showIGST && (
                    <tr>
                      <td className="bold quotation_name1 left-border">
                        SGST ({this.props.location?.state?.data?.SGST}%)
                      </td>
                      <td className=" itemvalue2 right-border">
                        {" "}
                        ₹{" "}
                        {this.props.location?.state?.data?.SGST_Amount &&
                          this.props.location?.state?.data?.SGST_Amount.toFixed(
                            2
                          )}
                      </td>
                    </tr>
                  )}
                  {!this.props.location?.state?.data?.showIGST && (
                    <tr>
                      <td className="bold quotation_name1 left-border">
                        CGST ({this.props.location?.state?.data?.CGST}%)
                      </td>
                      <td className=" itemvalue2 right-border">
                        {" "}
                        ₹{" "}
                        {this.props.location?.state?.data?.CGST_Amount &&
                          this.props.location?.state?.data?.CGST_Amount.toFixed(
                            2
                          )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="bold quotation_name1 left-border">
                    Taxable Amount
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.Taxable_Amt &&
                        this.props.location?.state?.data?.Taxable_Amt.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                   {this.props.location?.state?.data?.SchemeName!="" &&<tr>
                    <td className="bold quotation_name1 left-border">
                    {this.props.location?.state?.data?.SchemeName}
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.Scheme_Amt &&
                        this.props.location?.state?.data?.Scheme_Amt.toFixed(
                          2
                        )}
                    </td>
                  </tr>}
                  <tr>
                    <td className="bold quotation_name1 left-border">
                    Dealer Proposed Discount
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.AMV_Dealer_Proposed_Amount &&
                        this.props.location?.state?.data?.AMV_Dealer_Proposed_Amount.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bold quotation_name1 left-border">
                      Vehicle Price
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.VehiclePrice &&
                        this.props.location?.state?.data?.VehiclePrice.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td className="bold quotation_name1 left-border">
                    Accessory Charges
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data
                        ?.Total_Accessories_Amount &&
                        this.props.location?.state?.data?.Total_Accessories_Amount.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr className="mt-1  u-border ">
                    <td className="bold quotation_name1 left-border">
                      RTO Amount
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.AMV_RTO_Amount &&
                        this.props.location?.state?.data?.AMV_RTO_Amount.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr className="mt-1  u-border ">
                    <td className="bold quotation_name1 left-border">
                      RTO Discount Amount
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data
                        ?.AMV_RTO_Discount_Amount &&
                        this.props.location?.state?.data?.AMV_RTO_Discount_Amount.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr className="mt-1  u-border ">
                    <td className="bold quotation_name1 left-border">
                      Insurance Amount
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.AMV_Insurance_Amount &&
                        this.props.location?.state?.data?.AMV_Insurance_Amount.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr className="mt-1  u-border ">
                    <td className="bold quotation_name1 left-border">
                      HPA Charges
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.AMV_HPA_Charges &&
                        this.props.location?.state?.data?.AMV_HPA_Charges.toFixed(
                          2
                        )}
                    </td>
                  </tr>

                  <tr className="mt-1  u-border ">
                    <td className="bold quotation_name1 left-border">
                      CRTM Amount
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.AMV_CRTM_Amount &&
                        this.props.location?.state?.data?.AMV_CRTM_Amount.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr className="mt-1  u-border">
                    <td className="bold quotation_name1 left-border">
                      Smart Card Amount
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data
                        ?.AMV_Smart_Card_Amount &&
                        this.props.location?.state?.data?.AMV_Smart_Card_Amount.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr className="mt-1 u-border">
                    <td className="bold quotation_name1 left-border">
                      HSRP Amount
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.HSRP_Charge &&
                        this.props.location?.state?.data?.HSRP_Charge.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr className="mt-1 u-border">
                    <td className="bold quotation_name1 left-border">
                      Other RTO Charges
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.Other_RTO_Charge &&
                        this.props.location?.state?.data?.Other_RTO_Charge.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr className="mt-1 u-border">
                    <td className="bold quotation_name1 left-border">
                      Other Charges
                    </td>
                    <td className=" itemvalue2 right-border">
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data?.Other_Charges_Amount &&
                        this.props.location?.state?.data?.Other_Charges_Amount.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                  <tr className="mt-1 px-3">
                    <td
                      style={{ borderBottom: "none" }}
                      className="bold quotation_name1 left-border"
                    >
                      Exchange Bonus With Tax
                    </td>
                    <td
                      style={{ borderBottom: "none" }}
                      className=" itemvalue2 right-border"
                    >
                      {" "}
                      ₹{" "}
                      {this.props.location?.state?.data
                        ?.Exchange_Bonus_Amount &&
                        this.props.location?.state?.data?.Exchange_Bonus_Amount.toFixed(
                          2
                        )}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td className=" bold">Total</td>
              <td className=" text-end ">
                <div className=" row px-3 justify-content-end ">
                  
                  <b>
                  ₹{" "}
                    {this.props.location?.state?.data?.GrandTotal_Amount &&
                    Math.round(this.props.location?.state?.data?.GrandTotal_Amount).toFixed(
                      2
                    )}</b>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="bold">
                Rupees In Words :{" "}
                {this.convertToWord(
                  parseInt(this.props.location?.state?.data?.GrandTotal_Amount)
                )}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="bold">
                Bank Name : {this.props.location?.state?.data?.Financier}
              </td>
            </tr>
            {/* <tr>
              <td colSpan={2} className="px-4">
                <div className=" row justify-content-between ">
                  <div>
                    <div className="mt-5">AUTHORISED SIGINATORY</div>
                  </div>
                </div>
              </td>
            </tr> */}
          </table>
          {this.state.conditioncode[0].Acc_id !== null && (
            <div className="invoice-container" style={{ width: "100%" }}>
              {/* <thead> */}
              <tr style={{ width: "100%" }}>
                <td className="bold_new">Accessories Name</td>
                <td className="bold_new">Accessories Amount</td>
                <td className="bold_new">HSN</td>
                <td className="bold_new">IGST</td>
                <td className="bold_new">IGST Amount</td>
                <td className="bold_new">SGST</td>
                <td className="bold_new">SGST Amount</td>
                <td className="bold_new">CSGT</td>
                <td className="bold_new">CSGT Amount</td>
                <td className="bold_new">Accessories Total With Tax</td>
              </tr>
              {/* </thead> */}
              <tbody style={{ textAlign: "end", fontWeight: "600" }}>
                {this.state.conditioncode.map((e) => {
                  console.log("vgfg", e);
                  return (
                    <tr>
                      <td style={{ textAlign: "start" }}>
                        {e?.accessories_name}
                      </td>
                      <td style={{ textAlign: "start" }}>
                        ₹{e?.accessories_amount}
                      </td>
                      <td>{e?.hsn}</td>
                      <td style={{ width: "92px" }}>{e?.igst}%</td>
                      <td style={{ width: "92px" }}>
                        ₹ {e?.igst_amount?.toFixed(2)}
                      </td>
                      <td style={{ width: "92px" }}>{e?.sgst}%</td>
                      <td style={{ width: "92px" }}>
                        ₹ {e?.sgst_amount?.toFixed(2)}
                      </td>
                      <td style={{ width: "92px" }}>{e?.cgst}%</td>
                      <td style={{ width: "92px" }}>
                        ₹ {e?.cgst_amount?.toFixed(2)}
                      </td>
                      <td>
                        ₹{" "}
                        {e?.accessories_total_withtax &&
                          e?.accessories_total_withtax.toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </div>
          )}
          <div className="invoice-container" style={{ padding: "0px" }}>
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <div>
                      <div>
                        <div>
                          for,{" "}
                          <span className=" bold">
                            {sessionStorage.getItem("dealer_name")}
                          </span>
                        </div>
                        <div className="mt-5">AUTHORISED SIGINATORY</div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="invoice-container">
            <p style={{ marginLeft: "-12px" }} className=" bold">
              Terms & Conditions
            </p>
            <ol>
              <li>Goods once sold will not be taken back or exchanged.</li>
              <li>All Disputes subject to Jurisdiction only. </li>
              <li>
                Our Risk & Responsibility Ceases as soon as goods leave our
                premises.{" "}
              </li>
              <li>
                Subject to{" "}
                <span className=" bold">
                  {sessionStorage.getItem("Dealer_District")}
                </span>{" "}
                Jurisdiction only E & OE{" "}
              </li>
            </ol>
            <p style={{ marginLeft: "-12px" }}>
              All rights on the environmental attributes, or any other similar
              benefits generated by the vehicles during the lifetime will be at
              the sole discretion and ownership of the OEM
            </p>
          </div>
        </div>
      </div>
    );
  };
  MasterAPI = async () => {
    const url = Urls.python_baseurl + "/GetQuotation_SP_Quotation_Details";
    var data = JSON.stringify({
      DealerId: "'126'",
      HOBrID: "'126'",
      M1ID: "24",
    });
    let res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    });
    res = await res.json();
    if (res && res.length > 0) {
      this.setState({
        QuotationNumber: res[0]?.Quotation_Number,
        QuotationDate: moment(res[0]?.Quotation_Date).format("DD-MM-YYYY"),
        CustomerPhone: res[0]?.mobile,
        CustomerEmail: res[0]?.E_Mail,
        CustomerName: res[0]?.name,
        ModelName: res[0]?.model_name,
        ModelColor: res[0]?.model_Colour,
        ShowroomPrice: res[0]?.MRP,
        TotalPrice: res[0]?.total_amount,
      });
    }
  };
  render() {
    return (
      <div style={{display:"flex", justifyContent:"center"}}>
      <body id="printArea">
        <div className="page-header" style={{ textAlign: "center" }}></div>
        <div className="page-footer"></div>
        <table className=" border-0 ">
          <thead className=" border-0 ">
            <tr className=" border-0 ">
              <td className=" border-0 ">
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>
          <tbody className=" border-0 ">
            <tr className=" border-0 ">
              <td className=" border-0 ">
                <div className="page">{this.Invoice()}</div>
              </td>
            </tr>
          </tbody>

          <tfoot className=" border-0 ">
            <tr className=" border-0 ">
              <td className=" border-0 ">
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </body>
      </div>
    );
  }
}
