import React from "react";
import "../../Files/files-styles.css";
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import Strings from "../../../../helpers/Strings";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const $ = window.$;
export class Booking_M3 extends React.Component {
  constructor(props) {
    console.log(props, "abhg");
    super(props);
    this.state = {
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      Customer_Name: "",
      Status: props.Status,
      bookingflag: props.booking,
      Booking_Date: !props?.Booking_M3_Details
        ? moment(new Date()).format("DD-MM-YYYY")
        : moment(props?.Booking_M3_Details?.M3_Date).format("DD-MM-YYYY"),
      // Enquiry_No:props?.location?.state?.data?.Doc_No,
      Enquiry_No: props?.pendingClick3?.Doc_No,
      isSavedClick: props.modelid,
      M2_no:
        props && props.pendingClick3
          ? props.pendingClick3.M1
            ? props.pendingClick3.M1
            : props.pendingClick3.ID
          : props.pendingClick3
          ? props.pendingClick3.M1
          : props.id
          ? props.id
          : 0,
      // props?.pendingClick3?.ID?props?.pendingClick3?.ID:props?.pendingClick3?.M1?props?.pendingClick3?.M1:0,
      M3_id: props.id ? props.id : 0,
      PO_Date: "",
      PO_No: "",
      //  Booking_No: props?.Booking_M3_Details?props.Booking_M3_Details[0][0]?.M3_No:"",
      vistitdetails: [],
      Financier_customer: [],
      Customer_Name: props?.pendingClick3?.Dealer_Name,
      Doc_No: props?.pendingClick3?.Doc_No,
      M1ID: props?.pendingClick3?.ID,
      Model_Id: "",
      Model_Code_Option: [],
      Model_Category_Option: [],
      Model_Option: [],
      next_visit_details: "",
      visit_details: "",
      booking_ID: 0,
      SourceListOption: [],
      bookingType: [],
      bookingstate: [],
      booking_district: [],
      booking_region: [],
      booking_town: [],
      Attended_By: [],
      AllocatedTo: [],
      Model_Group: [],
      Model_Code: [],
      insuranceList: [],
      M1_HDR: 0,
      ConditionCode: [
        {
          id: 1,
          accid: "",
          accessories_name: "",
          accessories_amount: "",
          hsn: "",
          igst: "",
          igst_amount: "",
          sgst: "",
          sgst_amount: "",
          cgst: "",
          cgst_amount: "",
          accessories_total_withtax: "",
          options: [],
        },
      ],
      AccessoriesOption: [],
      insuranceList: [],
      SchemeOption: [],
      Total_Accessories_Amount: "",
      Remarks: "''",
      Vehicle_Fund: 0,
      Financier: "0",
      TCS_App: "''",
      Exchange_bonus_with_tax: "",
      Total_amount: "",
      Insurance_company: "'HDFC'",
      insurance_year: "",
      insurance_amount: "",
      other_charges_amount: "",
      SchemeName: "''",
      Scheme_Amt: "",
      Scheme_ID: "",
      Taxable_Amt: "",
      Pending_Amount: "",
      Total_Accessories_Amount: "",
      Delivery_Date: "",
      Dealer_vehicle_Code: "",
      AMV_HPA_Charges: "",
      accssory_id: 0,
      delete_id: 0,
      Booking_Advance_Amount: "",
      AMV_Insurance_Amount: "",
      Exchange_Bonus_Amount: "",
      Other_Charges: "",
      fin_year: sessionStorage.getItem("fin_year"),
      Dealer_Code: sessionStorage.getItem("Dealer_Code"),
      isSaveClicked: false,
      isConfirmClicked: false,
      disableaddnew: false,
      isCretBooking: false,
      directconfirm: false,
      reasonLost: [],
      Cancel_Remark: "",
      payment_ref_no: "",
      loan_amount: "",
      payment_mode: 0,
    };
  }
  componentDidMount() {
    this.standard_accssories();
    // {
    //   this.state.isSavedClick == "pendingClick" && this.displaydata();
    // }
    this.booking_Type();
    this.visit_details();
    this.Financiercustomer();
    {
      !this.props?.Booking_M3_Details &&
        !(this.state.isSavedClick == "saveClcik") &&
        this.BookingNo();
    }
    this.GetSourceList();
    this.booking_state();
    this.GetModelCategory();
    this.reason_lost();
    if (this.state.modelcat) {
      this.GetModelCode(this.state.modelcat);
    }
    // {
    //   !(this.state.Region === 0) && this.booking_Region();
    // }
    {
      !(this.state.District === 0) && this.booking_district();
    }
  }
  reason_lost = () => {
    const url = Urls.python_baseurl + "/SP_Get_InqLossReason";
    var data = JSON.stringify({});
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            reasonLost: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getInsurance = (ModelCodedata) => {
    const url = Urls.python_baseurl + "/getInsurance_SP_GetInsurance ";
    var data = JSON.stringify({
      ModelCode: `'${ModelCodedata}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            insuranceList: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getmodelMRP = (modelId) => {
    const url = Urls.python_baseurl + "/getModelMrp_GetModdelMRP";
    var data = JSON.stringify({
      Dealer: sessionStorage.getItem("dealerid"),
      ModelID: parseInt(modelId),
      StateCode: `${this.state.State ? this.state.State : this.state.stateid}`,

      // ValComboQueryType: 197,
      // iParentId: 0,
      // sAdditionalCondition: `'and Model_cat_ID=${event.target.value}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Scheme_Name:
                this.state.IS_confirm == "Y"
                  ? this.state.Scheme_Name
                  : res[4][0].Scheme_name,
              Scheme_Amount:
                this.state.IS_confirm == "Y"
                  ? this.state.Scheme_Amount
                  : res[4][0].Amount,
              Scheme_Id:
                this.state.IS_confirm == "Y"
                  ? this.state.Scheme_Id
                  : res[4][0].ID,
              Scheme_Start_Date:
                this.state.IS_confirm == "Y"
                  ? this.state.Scheme_Start_Date
                  : res[4][0].start_date,
              Scheme_End_Date:
                this.state.IS_confirm == "Y"
                  ? this.state.Scheme_End_Date
                  : res[4][0].End_date,
              Is_Insurance:
                this.state.IS_confirm == "Y"
                  ? this.state.Is_Insurance
                  : res[4][0].Is_Insurance
                  ? res[4][0].Is_Insurance
                  : "",
            },
            () => {}
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getScheme = (ModelCodedata) => {
    const url = Urls.python_baseurl + "/GetScheme_SP_GetScheme ";
    var data = JSON.stringify({
      DepartmentId: "5",
      Model_code: `'${ModelCodedata}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            SchemeOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  standard_accssories = () => {
    const url = Urls.python_baseurl + "/GetAccessories_SP_GetAccessories";
    var data = JSON.stringify({
      Type: "'Standard'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newConditionArray = this.state.ConditionCode.map((e) => {
            return { ...e, options: res };
          });
          this.setState(
            {
              ConditionCode: newConditionArray,
              AccessoriesOption: res,
              MaxAccessoriesCount: res.length,
            },
            () => {
              this.state.isSavedClick != "saveClcik" && this.displaydata();
              {
                this.state.isSavedClick == "saveClcik" &&
                  this.displayBookingM3();
              }
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  displaydata = () => {
    const url = Urls.python_baseurl + "/GetBookingDet_SP_Getbookingdetails ";
    var data = JSON.stringify({
      Id: this.state.M2_no,
      Dealer_Id: parseInt(sessionStorage.getItem("dealerid")),
      HOBrID: parseInt(sessionStorage.getItem("dealerid")),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newcondition = res[1].map((e, index) => {
            return {
              id:
                this.state.ConditionCode[this.state.ConditionCode.length - 1]
                  ?.id + index,
              accid: e?.Acc_id,
              // accessories_name: e.Accesories_Name,
              accessories_name: this.state.AccessoriesOption.filter(
                (element) => {
                  return e?.Acc_id == element.ID;
                }
              )[0]?.Accesories_Name,
              accessories_amount: e?.Accessories_Amount,
              hsn: e?.HSN,
              igst: e?.IGST,
              igst_amount: e?.IGST_Amount,
              sgst: e?.SGST,
              sgst_amount: e?.SGST_Amount,
              cgst: e?.CSGT,
              cgst_amount: e?.CSGT_Amount,
              accessories_total_withtax: e?.Total_With_Tax,
              accesores_ID: e?.id,
              options: this.state.AccessoriesOption,
              ["selectedAccessories"]: this.state.AccessoriesOption.filter(
                (element) => {
                  return e?.Acc_id == element.ID;
                }
              )[0],
            };
          });

          this.setState(
            {
              isCretBooking: true,
              Name: res[0][0]?.name,
              address1: res[0][0]?.add1,
              address2: res[0][0]?.add2,
              Region: res[0][0]?.Region_ID,
              State: res[0][0]?.State_ID,
              District: res[0][0]?.District_ID,
              City: res[0][0]?.CITY,
              pincode: res[0][0]?.PIN,
              Mobileno: res[0][0]?.mobile,
              email: res[0][0]?.E_mail,
              Type: res[0][0]?.type_flag_id,
              Title: res[0][0]?.Prefix,
              Town: res[0][0]?.Taluka_ID,
              enquiry_no: res[0][0]?.Inq_no,
              Enquiry_date: moment(res[0][0]?.Inq_date).format("YYYY-MM-DD"),
              Source: res[0][0]?.Inq_source_id,
              attendedby: res[0][0]?.AttendedBy_Name,
              allocatedto: res[0][0]?.AllocatedTo_Name,
              modelcat: res[0][0]?.Model_Gp,
              modelcode1: res[0][0]?.Model_ID,
              Quantity: res[0][0]?.Qty,
              AMV_Sale_Rate: res[0][0]?.MRP,
              // Quantity_Amount: res[0][0]?.MRP ? parseFloat(res[0][0]?.MRP) : 0,
              AMV_Standard_Accessories_Amount:
                res[0][0]?.Standard_Accessories_amount,
              AMV_RTO_Amount: res[0][0]?.RTO_Amount,
              Insurance_company: res[0][0]?.Insurance_company,
              AMV_Insurance_Year: res[0][0]?.insurance_year,
              AMV_Insurance_Amount: res[0][0]?.insurance_amount,
              AMV_CRTM_Amount: res[0][0]?.CRTM_amount,
              AMV_HPA_Charges: res[0][0]?.HPA_Change,
              AMV_Smart_Card_Amount: res[0][0]?.Smart_card_amount,
              AMV_RSA_Amount: res[0][0]?.RSA_change,
              AMV_Subsidy_Amount: res[0][0]?.subsidiary_amount,
              AMV_Central_Subsidy_Amount: res[0][0]?.centarl_subsidy_amount,
              AMV_Dealer_Proposed_Amount: res[0][0]?.dealer_propsed_discount,
              AMV_Special_Number_Amount: res[0][0]?.special_number_amount,
              AMV_Disbursment_Amount: res[0][0]?.disbursment_amount,
              AMV_RTO_Discount: res[0][0]?.RTO_discount,
              AMV_RTO_Discount_Amount: res[0][0]?.RTO_discount_amount,
              AMV_Other_Charges: res[0][0]?.other_changes,
              // Other_Charges_Amount: res[0][0]?.other_charges_amount,
              // Exchange_Bonus_Amount: res[0][0]?.Exchange_bonus_with_tax,
              Total: res[0][0]?.total_amount,
              Subsidy_Type: res[0][0]?.Subsity_Type,
              M1_HDR: res[0][0]?.M2_Hdr,
              // Booking_No: res[0][0]?.M3_no,
              // Booking_Date: res[0][0]?.Booking_Date,
              Other_RTO_Charge: res[0][0]?.Other_RTO,
              Booking_Advance_Amount: res[0][0]?.Booking_Adv_Amt,
              // Vehicle_Fund: res[0][0]?.Fund,
              // Financier: res[0][0]?.Financier_id,
              HSRP_Charge: res[0][0]?.HSRP_Charge,
              Taxable_Amt: res[0][0]?.Taxable_Amt,
              IGST_Amount:((res[0][0]?.Taxable_Amt*(res[0][0].IGST?res[0][0].IGST:1))/100),
              CGST_Amount:((res[0][0]?.Taxable_Amt*(res[0][0].CGST?res[0][0].CGST:1))/100),
              SGST_Amount:((res[0][0]?.Taxable_Amt*(res[0][0].SGST?res[0][0].SGST:1))/100),
              // Pending_Amount: res[0][0]?.Taxable_Amt,
              // Other_Charges: res[0][0]?.other_charges_amount,
              // Quantity_Amount: res[0][0]?.Amount,
              Total_Accessories_Amount: res[0][0].Standard_Accesories,
              Grand_Total: (res[0][0]?.Grand_Total+(((res[0][0]?.Taxable_Amt*(res[0][0].CGST?res[0][0].CGST:1))/100)+((res[0][0]?.Taxable_Amt*(res[0][0].SGST?res[0][0].SGST:1))/100))*res[0][0].Qty),
              ConditionCode: newcondition,
            },
            () => {
              console.log("Enquiry_date", this.state.Enquiry_date);
              if (this.state.modelcat) {
                this.GetModelCode(this.state.modelcat);
              }
              this.booking_Region();
              this.booking_district();
              // this.financiaryear();
              this.GetModelDetails();
              this.accessoriesSetup();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  displayBookingM3 = (id) => {
    const url = Urls.python_baseurl + "/Booking_display_SP_GetM3";
    var data = JSON.stringify({
      POId: `${this.state.M3_id}`,
      POType: "'Max'",
      DealerID: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      iM1ID: "0",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newcondition = res[1].map((e, index) => {
            return {
              id:
                this.state.ConditionCode[this.state.ConditionCode.length - 1]
                  ?.id + index,
              accid: e?.Acc_id,
              accessories_name: this.state.AccessoriesOption.filter(
                (element) => {
                  return e?.Acc_id == element.ID;
                }
              )[0]?.Accesories_Name,
              accessories_amount: e?.Accessories_Amount,
              hsn: e?.HSN,
              igst: e?.IGST,
              igst_amount: e?.IGST_Amount,
              sgst: e?.SGST,
              sgst_amount: e?.SGST_Amount,
              cgst: e?.CSGT,
              cgst_amount: e?.CSGT_Amount,
              accessories_total_withtax: e?.Total_With_Tax,
              accesores_ID: e?.id,
              options: this.state.AccessoriesOption,
              ["selectedAccessories"]: this.state.AccessoriesOption.filter(
                (element) => {
                  return e?.Acc_id == element.ID;
                }
              )[0],
            };
          });
          this.setState(
            {
              ConditionCode: newcondition,
              Name: res[0][0]?.name,
              address1: res[0][0]?.add1,
              address2: res[0][0]?.add2,
              Region: res[0][0]?.Region_ID,
              State: res[0][0]?.State_ID,
              District: res[0][0]?.District_ID,
              City: res[0][0]?.CITY,
              pincode: res[0][0]?.PIN,
              Mobileno: res[0][0]?.mobile,
              email: res[0][0]?.E_mail,
              Type: res[0][0]?.type_flag_id,
              Title: res[0][0]?.Prefix,
              Town: res[0][0]?.Taluka_ID,
              enquiry_no: res[0][0]?.Inq_no,
              Enquiry_date: res[0][0]?.Inq_date,
              Source: res[0][0]?.Inq_source_id,
              attendedby: res[0][0]?.AttendedBy_Name,
              allocatedto: res[0][0]?.AllocatedTo_Name,
              modelcat: res[0][0]?.Model_Gp,
              modelcode1: res[0][0]?.Model_ID,
              Quantity: res[0][0]?.Qty,
              AMV_Sale_Rate: res[0][0]?.MRP,
              AMV_Standard_Accessories_Amount:
                res[0][0]?.Standard_Accessories_amount,
              AMV_RTO_Amount: res[0][0]?.RTO_Amount,
              Insurance_company: res[0][0]?.Insurance_company,
              AMV_Insurance_Year: res[0][0]?.insurance_year,
              AMV_Insurance_Amount: res[0][0]?.insurance_amount,
              AMV_CRTM_Amount: res[0][0]?.CRTM_amount,
              AMV_HPA_Charges: res[0][0]?.HPA_charge,
              AMV_Smart_Card_Amount: res[0][0]?.Smart_card_amount,
              AMV_RSA_Amount: res[0][0]?.RSA_change,
              AMV_Subsidy_Amount: res[0][0]?.subsidiary_amount,
              AMV_Central_Subsidy_Amount: res[0][0]?.centarl_subsidy_amount,
              AMV_Dealer_Proposed_Amount: res[0][0]?.dealer_propsed_discount,
              AMV_Special_Number_Amount: res[0][0]?.special_number_amount,
              AMV_Disbursment_Amount: res[0][0]?.disbursment_amount,
              AMV_RTO_Discount: res[0][0]?.RTO_discount,
              AMV_RTO_Discount_Amount: res[0][0]?.RTO_discount_amount,
              AMV_Other_Charges: res[0][0]?.other_changes,
              Other_Charges_Amount: res[0][0]?.other_charges_amount,
              Exchange_Bonus_Amount: res[0][0]?.Exchange_bonus_with_tax,
              Total: res[0][0]?.total_amount,
              Subsidy_Type: res[0][0]?.Subsity_Type,
              M1_HDR: res[0][0]?.M2_Hdr,
              Booking_No: res[0][0]?.M3_no,
              Booking_Date: res[0][0]?.Booking_Date
                ? moment(res[0][0]?.Booking_Date, "YYYY-MM-DD").format(
                    "DD-MM-YYYY"
                  )
                : "",
              Other_RTO_Charge: res[0][0]?.Other_RTO,
              Booking_Advance_Amount: res[0][0]?.Booking_Adv_Amt,
              Remark: res[0][0]?.remarks,
              Vehicle_Fund: res[0][0]?.Fund,
              Financier: res[0][0]?.Financier_id,
              HSRP_Charge: res[0][0]?.HSRP_Charge,
              Taxable_Amt: res[0][0]?.Taxable_Amt,
              CGST_Amount:res[0][0]?.CGST_Amount,
              IGST_Amount:res[0][0]?.IGST_Amount,
              SGST_Amount:res[0][0]?.CGST_Amount,
              Pending_Amount: res[0][0]?.Pending_Amt,
              Total_Accessories_Amount: res[0][0]?.Accesories_Amt,
              Other_Charges: res[0][0]?.other_charges_amount,
              // Quantity_Amount: res[0][0]?.Amount,
              Grand_Total: res[0][0]?.Total_amount,
              Delivery_Date: res[0][0]?.Delivery_Date
                ? moment(res[0][0].Delivery_Date).format("YYYY-MM-DD HH:mm:ss")
                : "",
              M3_Confirm_ID: res[0][0]?.M3_ID,
              IS_confirm: res[0][0]?.IS_confirm,
              Scheme_End_Date: res[0][0]?.Scheme_End_date,
              Scheme_Start_Date: res[0][0]?.Scheme_start_date,
              Scheme_Id: res[0][0]?.Scheme_Id,
              Scheme_Amount: res[0][0]?.Scheme_Amt,
              Scheme_Name: res[0][0]?.Scheme_Name,
              Cancel_Remark: res[2][0]?.rsn_Id,
              lost_ID: res[2][0]?.ID,
              M3lost: res[0][0]?.M3_Lost,
              payment_mode: res[0][0]?.Payment_Mode,
              payment_ref_no: res[0][0]?.Transaction_details,
              loan_amount: res[0][0]?.financial_loan_amount,
            },
            () => {
              if (this.state.modelcat) {
                this.GetModelCode(this.state.modelcat);
              }
              this.booking_Region();
              this.booking_district();
              this.GetModelDetails();
              this.calculateGrandTotal();
              this.accessoriesSetup();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  accessoriesSetup = () => {
    let newConditionArray = this.state.ConditionCode;
    let newAccessoriesOptions = this.state.AccessoriesOption;
    if (this.state.ConditionCode.length == 1) {
      for (let i = 0; i < this.state.ConditionCode.length; i++) {
        for (let j = 0; j < this.state.ConditionCode.length; j++) {
          newAccessoriesOptions = newAccessoriesOptions.filter(
            (e) => e.ID != newConditionArray[j].accid
          );
        }
      }
    } else {
      for (let i = 0; i < this.state.ConditionCode.length; i++) {
        for (let j = 0; j < this.state.ConditionCode.length; j++) {
          if (i !== j) {
            newConditionArray[i].options = newConditionArray[i].options.filter(
              (e) => e.ID != newConditionArray[j].accid
            );
            newAccessoriesOptions = newAccessoriesOptions.filter(
              (e) => e.ID != newConditionArray[j].accid
            );
          }
        }
      }
    }
    this.setState(
      {
        AccessoriesOption: newAccessoriesOptions,
      },
      () => {
        console.log(this.state.ConditionCode, "jkjkkj");
      }
    );
  };
  GetSourceList = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 154,
      ID: 0,
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            SourceListOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  booking_town = () => {
    const url = Urls.python_baseurl + "/Display_Tehsil_sp_tehsildisplay";
    var data = JSON.stringify({
      state_name: this.state.State,
      district_name: this.state.District,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            booking_town: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  booking_Region = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: this.state.State,
      type: "Region",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            booking_region: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  booking_state = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: "0",
      type: "'State'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            bookingstate: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  booking_Type = () => {
    const url = Urls.python_baseurl + "/sp_GetCustType";
    var data = JSON.stringify({
      Type: "C",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            bookingType: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  booking_district = () => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: this.state.State,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              booking_district: res,
            },
            () => {
              this.booking_town();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  GetModelCode = (ModelCategory) => {
    const url =
      Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
    var data = JSON.stringify({
      ModelGroup: parseInt(ModelCategory),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Model_Code: res,
            },
            () => {
              let ModelCodedata = "";
              for (
                let index = 0;
                index < this.state.Model_Code.length;
                index++
              ) {
                if (
                  this.state.Model_Code[index].ID ===
                  parseInt(this.state.modelcode1)
                ) {
                  ModelCodedata = this.state.Model_Code[index]["Model code"];
                }
              }
              this.getInsurance(ModelCodedata);
              this.getmodelMRP(this.state.modelcode1);
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/ModelGroup_GetModelGroup";
    var data = JSON.stringify({
      id: "null",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Group: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  saveBookingNo = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "M3",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Booking_No:
                this.state.Dealer_Code +
                "" +
                "M3" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              this.handelSave();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  BookingNo = () => {
    this.setState({ leadno: "" });
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "M3",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // docMax: res[0].DocMaxNo,
              Booking_No:
                this.state.Dealer_Code +
                "" +
                "M3" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              // this.financiaryear();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  // financiaryear = () => {
  //   const url = Urls.python_baseurl + "/SP_GetDataFromDataQuery";
  //   var data = JSON.stringify({
  //     ValGetDataQueryType: "15",
  //     iParentId: sessionStorage.getItem("dealerid"),
  //     sAdditionalCondition: "''",
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState(
  //           {
  //             finacial_year: res[0].Value,
  //           },
  //           () => {
  //             this.delarcode();
  //           }
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  // delarcode = () => {
  //   const url = Urls.python_baseurl + "/Get_Dealer_SP_GET_Dealercode";
  //   var data = JSON.stringify({
  //     DealerID: sessionStorage.getItem("dealerid"),
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState({
  //           delar_code: res[0].Dealer_vehicle_Code,
  //           Booking_No:
  //             res[0].Dealer_vehicle_Code +
  //             "" +
  //             "M3"+
  //             this.state.finacial_year +
  //             "" +
  //             this.state.docMax.toString().padStart(6, '0'),
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  Financiercustomer = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "83",
      ID: "0",
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Financier_customer: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Financier_customer = (event) => {
    let row = [];
    this.state.Financier_customer.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };

  GetModelList = (event) => {
    let row = [];
    this.state.Model_Option.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  Get_Modeldata = (event) => {
    let row = [];
    this.state.Model_Code_Option.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  GetCategory = (event) => {
    let row = [];
    this.state.Model_Category_Option.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  onChangeName(event, key) {
    this.setState({
      [key]: event.target.value,
    });
  }
  calculateGrandTotal = () => {

    this.setState(
      {
        Grand_Total:
          (this.state.Taxable_Amt +
            (this.state.AMV_RTO_Amount
              ? parseFloat(this.state.AMV_RTO_Amount)
              : 0) +
            (this.state.Other_Charges
              ? parseFloat(this.state.Other_Charges)
              : 0) +
            (this.state.Total_Accessories_Amount
              ? parseFloat(this.state.Total_Accessories_Amount)
              : 0) +
            (this.state.HSRP_Charge ? parseFloat(this.state.HSRP_Charge) : 0) +
            (this.state.AMV_Smart_Card_Amount
              ? parseFloat(this.state.AMV_Smart_Card_Amount)
              : 0) +
            (this.state.AMV_HPA_Charges
              ? parseFloat(this.state.AMV_HPA_Charges)
              : 0) +
            (this.state.AMV_Insurance_Amount
              ? parseFloat(this.state.AMV_Insurance_Amount)
              : 0) +
            (this.state.Other_RTO_Charge
              ? parseFloat(this.state.Other_RTO_Charge)
              : 0) +
            (this.state.SGST_Amount
                ? parseFloat(this.state.SGST_Amount)
                : 0) +
            (this.state.CGST_Amount
                ? parseFloat(this.state.CGST_Amount)
                : 0) +
            (this.state.AMV_CRTM_Amount
              ? parseFloat(this.state.AMV_CRTM_Amount)
              : 0) -
            (this.state.Exchange_Bonus_Amount
              ? parseFloat(this.state.Exchange_Bonus_Amount)
              : 0) -
            (this.state.AMV_RTO_Discount_Amount
              ? parseFloat(this.state.AMV_RTO_Discount_Amount)
              : 0)) *
          this.state.Quantity,
      },
      () => {
        this.setState({
          Pending_Amount: this.state.Booking_Advance_Amount
            ? parseFloat(this.state.Grand_Total) -
              parseFloat(this.state.Booking_Advance_Amount)
            : parseFloat(this.state.Grand_Total),
          // Quantity_Amount:(this.state.Grand_Total)/(this.state.Quantity)
        });
      }
    );
  };
  ChangeVal(event, key) {
    console.log("ddww", key, event.target.value);
    this.setState(
      {
        [key]: event.target.value,
      },
      () => {
        this.calculateGrandTotal();
      }
    );
    if (key == "SchemeName") {
      let SchemeAmount = this.state.SchemeOption.filter(
        (e) => e.ID == event.target.value
      )[0]?.Amount
        ? this.state.SchemeOption.filter((e) => e.ID == event.target.value)[0]
            ?.Amount
        : 0;
      if (this.state.Taxable_Amt_Copy) {
        this.setState(
          {
            SchemeAmount: SchemeAmount,
            Taxable_Amt: this.state.Taxable_Amt_Copy - SchemeAmount,
          },
          () => {
            this.calculateGrandTotal();
          }
        );
      } else {
        this.setState(
          {
            SchemeAmount: SchemeAmount,
            Taxable_Amt_Copy: this.state.Taxable_Amt,
            Taxable_Amt: this.state.Taxable_Amt - SchemeAmount,
          },
          () => {
            this.calculateGrandTotal();
          }
        );
      }
    }
  }
  onSelect = (event, key) => {
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
  };
  handledateChange = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  };
  handelAccessoriesSave = (id) => {
    const url = Urls.python_baseurl + "/SaveAccesories_SP_Save_M3_Accesories";
    let data1 = this.state.ConditionCode.map((e) => {
      return {
        ID:
          this.state.isCretBooking == true
            ? "0"
            : e.accesores_ID
            ? `${e.accesores_ID}`
            : "0",
        M3_Hdr: `${id}`,
        Acc_id: `${e.accid}`,
        cr_date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
        Accessories_Amount: `${e.accessories_amount}`,
        HSN: `'${e.hsn}'`,
        IGST: `${e.igst}`,
        IGST_Amount: `${e.igst_amount}`,
        SGST: `${e.sgst}`,
        SGST_Amount: `${e.sgst_amount}`,
        CSGT: `${e.cgst}`,
        CSGT_Amount: `${e.cgst_amount}`,
        Total_With_Tax: `${e.accessories_total_withtax}`,
      };
    });
    var data = JSON.stringify(data1);
    console.log("hab", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "hhjjkj");
        if (res && res.length > 0) {
          if (this.state.isSavedClick == "saveClcik") {
            if (this.state.directconfirm) {
              // alert("Record Updated Succesfully");
              window.location.reload();
              // $("#myModal2").modal("hide");
              // $("#payment_error_modal_2").modal("hide");
              // sessionStorage.setItem("ActiveTab", "Vehicle Booking");
              // this.props.history.push("/tiles_tabs");
            } else {
              alert("Record Updated Succesfully");
              window.location.reload();
              // $("#myModal2").modal("hide");
              // $("#payment_error_modal_2").modal("hide");
              // sessionStorage.setItem("ActiveTab", "Vehicle Booking");
              // this.props.history.push("/tiles_tabs");
            }
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelSave = () => {
    const url = Urls.python_baseurl + "/save_booking_SP_Save_M3Header";
    var data = JSON.stringify({
      iID: `${this.state.M3_Confirm_ID ? this.state.M3_Confirm_ID : 0}`,
      dealerID: `${parseInt(sessionStorage.getItem("dealerid"))}`,
      HOBrID: `${parseInt(sessionStorage.getItem("dealerid"))}`,
      M3No: `'${this.state.Booking_No}'`,
      M3Date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      PoNo: "0",
      PODate: `''`,
      MTIProformaNo: "0",
      MTIProformaDate: "''",
      remarks: `'${this.state.Remark ? this.state.Remark : ""}'`,
      fund: `'${this.state.Vehicle_Fund ? this.state.Vehicle_Fund : ""}'`,
      Cancel: "'N'",
      Confirm: "'N'",
      OrderStatus: "'N'",
      M2ID: `${
        this.state.bookingflag
          ? this.state.M1_HDR
          : this.state.M3_id
          ? this.state.M3_id
          : parseInt(this.state.M2_no)
      }`, //id
      LikelyDate:
        this.state.Likely_Buy_Date != ""
          ? `'${moment(this.state.Likely_Buy_Date).format("DD-MM-YYYY")}'`
          : "''",
      BookingAmt: `${
        this.state.Booking_Advance_Amount
          ? this.state.Booking_Advance_Amount
          : ""
      }`,
      BookingDate: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      LossAppNo: "''",
      Financier: `${
        parseInt(this.state.Financier) ? parseInt(this.state.Financier) : 0
      }`,
      TCS_App: `''`,
      Exchange_bonus_with_tax: `${
        this.state.Exchange_Bonus_Amount ? this.state.Exchange_Bonus_Amount : 0
      }`,
      Total_amount: `${this.state.Grand_Total ? this.state.Grand_Total : ""}`,
      Insurance_company: `'${
        this.state.Insurance_company ? this.state.Insurance_company : 0
      }'`,
      insurance_year: `'${
        this.state.AMV_Insurance_Year ? this.state.AMV_Insurance_Year : ""
      }'`,
      insurance_amount: `${
        this.state.AMV_Insurance_Amount ? this.state.AMV_Insurance_Amount : 0
      }`,
      other_charges_amount: `${
        this.state.Other_Charges ? this.state.Other_Charges : 0
      }`,
      Scheme_Name: `'${this.state.Scheme_Name ? this.state.Scheme_Name : ""}'`,
      Scheme_Amt: `${this.state.Scheme_Amount ? this.state.Scheme_Amount : 0}`,
      Scheme_ID: `${this.state.Scheme_Id ? this.state.Scheme_Id : 0}`,
      Scheme_start_date: `'${
        this.state.Scheme_Start_Date ? this.state.Scheme_Start_Date : ""
      }'`,
      Scheme_End_date: `'${
        this.state.Scheme_End_Date ? this.state.Scheme_End_Date : ""
      }'`,
      Taxable_Amt: `${this.state.Taxable_Amt ? this.state.Taxable_Amt : 0}`,
      Pending_Amt: `${
        this.state.Pending_Amount ? this.state.Pending_Amount : 0
      }`,
      Accesories_Amt: `${
        this.state.Total_Accessories_Amount
          ? this.state.Total_Accessories_Amount
          : 0
      }`,
      Delivery_Date: this.state.Delivery_Date
        ? `'${moment(this.state.Delivery_Date).format("YYYY-MM-DD HH:mm:ss")}'`
        : "''",
      HPA_Charge: `${
        this.state.AMV_HPA_Charges ? this.state.AMV_HPA_Charges : 0
      }`,
      DlrCode: `'${this.state.Dealer_Code}'`,
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      AdvanceAmt_byquantity: `${
        this.state.Booking_Advance_Amount && this.state.Quantity
          ? (this.state.Booking_Advance_Amount / this.state.Quantity).toFixed(2)
          : 0
      }`,
      Transaction_details: `'${this.state.payment_ref_no}'`,
      Payment_Mode: `'${this.state.payment_mode}'`,
      financial_loan_amount: `'${this.state.loan_amount}'`,
      CGST_Amount:`${this.state.CGST_Amount ? this.state.CGST_Amount : 0}`,
      SGST_Amount:`${this.state.SGST_Amount ? this.state.SGST_Amount : 0}`,
      IGST_Amount:"0"
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (this.state.M3_id) {
            if (this.state.ConditionCode[0].accid != 0) {
              this.handelAccessoriesSave(res[0].ID);
            } else {
              alert("Record Updated Succesfully");
              window.location.reload();
              // $("#myModal2").modal("hide");
              // $("#payment_error_modal_2").modal("hide");
              // sessionStorage.setItem("ActiveTab", "Vehicle Booking");
              // this.props.history.push("/tiles_tabs");
            }
          } else {
            if (res[0].newID) {
              this.setState(
                {
                  booking_ID: res[0].newID,
                },
                () => {
                  if (this.state.ConditionCode[0].accid != 0) {
                    this.handelAccessoriesSave(res[0].newID);
                  }
                  this.setState({
                    flag: false,
                  });
                  alert("Record Saved Succesfully");
                  window.location.reload();
                  // $("#myModal2").modal("hide");
                  // $("#payment_error_modal_2").modal("hide");
                  // $("#payment_error_modal_3").modal("hide");
                  // sessionStorage.setItem("ActiveTab", "Vehicle Booking");
                  // this.props.history.push("/tiles_tabs");
                }
              );
            }
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelConfirm = () => {
    this.setState({
      isConfirmClicked: true,
    });
    const url = Urls.python_baseurl + "/save_booking_SP_Save_M3Header";
    var data = JSON.stringify({
      iID: `${this.state.M3_Confirm_ID ? this.state.M3_Confirm_ID : 0}`,
      dealerID: `${parseInt(sessionStorage.getItem("dealerid"))}`,
      HOBrID: `${parseInt(sessionStorage.getItem("dealerid"))}`,
      M3No: `'${this.state.Booking_No}'`,
      M3Date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      PoNo: "0",
      PODate: `''`,
      MTIProformaNo: "0",
      MTIProformaDate: "''",
      remarks: `'${this.state.Remark ? this.state.Remark : ""}'`,
      fund: `'${this.state.Vehicle_Fund ? this.state.Vehicle_Fund : ""}'`,
      Cancel: "'N'",
      Confirm: "'Y'",
      OrderStatus: "'N'",
      M2ID: `${
        this.state.bookingflag ? this.state.M1_HDR : parseInt(this.state.M2_no)
      }`, //id
      LikelyDate:
        this.state.Likely_Buy_Date != ""
          ? `'${moment(this.state.Likely_Buy_Date).format("DD-MM-YYYY")}'`
          : "''",
      BookingAmt: `${
        this.state.Booking_Advance_Amount
          ? this.state.Booking_Advance_Amount
          : ""
      }`,
      BookingDate: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      LossAppNo: "''",
      Financier: `${
        parseInt(this.state.Financier) ? parseInt(this.state.Financier) : 0
      }`,
      TCS_App: `''`,
      Exchange_bonus_with_tax: `${
        this.state.Exchange_Bonus_Amount ? this.state.Exchange_Bonus_Amount : 0
      }`,
      Total_amount: `${this.state.Grand_Total ? this.state.Grand_Total : ""}`,
      Insurance_company: this.state.Insurance_company
        ? `'${this.state.Insurance_company}'`
        : "''",
      insurance_year: `'${
        this.state.AMV_Insurance_Year ? this.state.AMV_Insurance_Year : ""
      }'`,
      insurance_amount: `${
        this.state.AMV_Insurance_Amount ? this.state.AMV_Insurance_Amount : 0
      }`,
      other_charges_amount: `${
        this.state.Other_Charges ? this.state.Other_Charges : 0
      }`,
      Scheme_Name: `'${this.state.Scheme_Name ? this.state.Scheme_Name : ""}'`,
      Scheme_Amt: `${this.state.Scheme_Amount ? this.state.Scheme_Amount : 0}`,
      Scheme_ID: `${this.state.Scheme_Id ? this.state.Scheme_Id : 0}`,
      Scheme_start_date: `'${
        this.state.Scheme_Start_Date ? this.state.Scheme_Start_Date : ""
      }'`,
      Scheme_End_date: `'${
        this.state.Scheme_End_Date ? this.state.Scheme_End_Date : ""
      }'`,
      Taxable_Amt: `${this.state.Taxable_Amt ? this.state.Taxable_Amt : 0}`,
      Pending_Amt: `${
        this.state.Pending_Amount ? this.state.Pending_Amount : 0
      }`,
      Accesories_Amt: `${
        this.state.Total_Accessories_Amount
          ? this.state.Total_Accessories_Amount
          : 0
      }`,
      Delivery_Date: this.state.Delivery_Date
        ? `'${moment(this.state.Delivery_Date).format("YYYY-MM-DD HH:mm:ss")}'`
        : "''",
      HPA_Charge: `${
        this.state.AMV_HPA_Charges ? this.state.AMV_HPA_Charges : 0
      }`,
      DlrCode: `'${this.state.Dealer_Code}'`,
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      AdvanceAmt_byquantity: `${
        this.state.Booking_Advance_Amount && this.state.Quantity
          ? (this.state.Booking_Advance_Amount / this.state.Quantity).toFixed(2)
          : 0
      }`,
      Transaction_details: `'${this.state.payment_ref_no}'`,
      Payment_Mode: `'${this.state.payment_mode}'`,
      financial_loan_amount: `'${this.state.loan_amount}'`,
      CGST_Amount:`${this.state.CGST_Amount ? this.state.CGST_Amount : 0}`,
      SGST_Amount:`${this.state.SGST_Amount ? this.state.SGST_Amount : 0}`,
      IGST_Amount:"0"
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            this.setState(
              {
                booking_ID: res[0].newID,
                directconfirm: true,
              },
              () => {
                this.handelAccessoriesSave(this.state.M3_Confirm_ID);
                alert("Record Confirmed Succesfully");
                window.location.reload();
                // $("#myModal2").modal("hide");
                // $("#payment_error_modal_2").modal("hide");
                // sessionStorage.setItem("ActiveTab", "Vehicle Booking");
              }
            );
          } else {
            this.setState({
              M1_HDR: 0,
              M3_id: 0,
              M1_HDR: 0,
              Name: "",
              address1: "",
              address2: "",
              Region: "",
              State: "",
              District: "",
              City: "",
              pincode: "",
              Mobileno: "",
              email: "",
              Type: 0,
              Title: 0,
              Town: 0,
              enquiry_no: 0,
              Enquiry_date: "",
              Source: 0,
              attendedby: 0,
              allocatedto: 0,
              modelcat: 0,
              modelcode1: 0,
              Quantity: 0,
              AMV_Sale_Rate: 0,
              AMV_Standard_Accessories_Amount: 0,
              AMV_RTO_Amount: 0,
              Insurance_company: 0,
              AMV_Insurance_Year: 0,
              AMV_Insurance_Amount: 0,
              AMV_CRTM_Amount: 0,
              AMV_HPA_Charges: 0,
              AMV_Smart_Card_Amount: 0,
              AMV_RSA_Amount: 0,
              AMV_Subsidy_Amount: 0,
              AMV_Central_Subsidy_Amount: 0,
              AMV_Dealer_Proposed_Amount: 0,
              AMV_Special_Number_Amount: 0,
              AMV_Disbursment_Amount: 0,
              AMV_RTO_Discount: 0,
              AMV_RTO_Discount_Amount: 0,
              AMV_Other_Charges: 0,
              Other_Charges_Amount: 0,
              Exchange_Bonus_Amount: 0,
              Total: 0,
              Subsidy_Type: 0,
              M1_HDR: 0,
              Booking_Advance_Amount: res[0][0].Booking_Adv_Amt,
              Remark: res[0][0].remarks,
              Vehicle_Fund: res[0][0].Fund,
              Financier: res[0][0].Financier_id,
            });
            alert("Record  Succesfully");
            $("#payment_error_modal_2").modal("hide");
            sessionStorage.setItem("ActiveTab", "Vehicle Booking");
            // this.props.history.push("/tiles_tabs")
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  visit_details_save = (event) => {
    const url = Urls.python_baseurl + "/Save_mo_SP_Save_M0Objectives";
    var data = JSON.stringify({
      ID: "0",
      UserId: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      Lead_hdr_ID: parseInt(this.state.booking_ID),
      obj_Id: this.state.visit_details,
      obj_date: `'${moment(this.state.startDate).format("DD-MM-YYYY")}'`,
      discussion: "''",
      time_spent: "''",
      next_obj_Id: this.state.next_visit_details,
      next_date: `'${moment(this.state.endDate).format("DD-MM-YYYY")}'`,
      commit_det: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].newID) {
            this.maxNo();
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  maxNo = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      DocName: "'M3'",
      DealerId: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            this.setState({
              flag: false,
            });
            alert("Record Saved Succesfully");
            window.location.reload();
            $("#myModal2").modal("hide");
            $("#payment_error_modal_2").modal("hide");
            $("#payment_error_modal_3").modal("hide");
            sessionStorage.setItem("ActiveTab", "Vehicle Booking");
            this.props.history.push("/tiles_tabs");
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelDetails = () => {
    const url = Urls.python_baseurl + "/model_master_SP_ModelMaster_details";
    var data = JSON.stringify({
      model_id: `'${this.state.Model_Id}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          this.setState({
            Model_Category_value: res[0]?.Model_cat_ID,
            Model_Code_value: this.state.Model_Code_Option.filter(
              (e) => e.Name == res[0]?.Model_code
            )[0]?.ID,
            Model: this.state.Model_Option.filter(
              (e) => e.Name == res[0]?.model_name
            )[0]?.ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Validate = () => {
    if (this.state.Vehicle_Fund == "L") {
      if (this.state.Financier == "0") {
        alert("Please Select the Financier. ");
        return;
      }
    }
    if (
      this.state.Booking_Advance_Amount == "" ||
      this.state.Booking_Advance_Amount === undefined
    ) {
      alert("Please Enter the Booking Advance Amount.");
      return;
    } else if (this.state.Vehicle_Fund == 0) {
      alert("Please Select the Cash/Finance. ");
      return;
    } else if (this.state.Vehicle_Fund == "L" && this.state.loan_amount == "") {
      alert("Please Enter the Loan Amount. ");
      return;
    } else if (this.state.payment_mode == 0) {
      alert("Please Select Payment Mode. ");
      return;
    } else if (this.state.payment_mode!="Cash" && this.state.payment_ref_no == "") {
      alert("Please Enter the  Cheque/Transaction Details. ");
      return;
    } else if (this.state.Delivery_Date == "") {
      alert("Please Enter the Expected Delivery Date. ");
      return;
    } else if (
      this.state.Insurance_company == "" &&
      this.state.Is_Insurance != "Y"
    ) {
      alert("Please Select the Insurance Company. ");
      return;
    } else if (
      this.state.Insurance_company == 0 &&
      this.state.Is_Insurance != "Y"
    ) {
      alert("Please Select the Insurance Company. ");
      return;
    } else if (
      this.state.AMV_Insurance_Year == "" &&
      this.state.Is_Insurance != "Y"
    ) {
      alert("Please Enter the Insurance Type. ");
      return;
    } else if (
      this.state.AMV_Insurance_Amount == "" &&
      this.state.Is_Insurance != "Y"
    ) {
      alert("Please Enter the Insurance Amount. ");
      return;
    } else {
      this.setState({
        isSaveClicked: true,
      });
    }
    !(this.state.isSavedClick == "saveClcik") && this.handelSave();
    this.state.isSavedClick == "saveClcik" && this.handelSave();
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  ClientInformation = () => {
    return (
      <div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Enquiry No</label>
            <input
              disabled={true}
              value={this.state.enquiry_no}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Enquiry Date</label>
            <input
              disabled={true}
              type="text"
              className="form-control"
              value={
                this.state.Enquiry_date
                  ? moment(this.state.Enquiry_date).format("DD-MM-YYYY")
                  : ""
              }
            />
          </div>
          <div class="form-group col-md-3">
            <label>Customer Name</label>
            <input
              value={this.state.Name}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Mobile</label>
            <input
              value={this.state.Mobileno}
              disabled={true}
              // maxlength="10"
              class="form-control"
              placeholder=""
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Source</label>
            <input
              className="form-control "
              value={
                this.state.SourceListOption.filter((e) => {
                  return e.ID === this.state.Source;
                })[0]?.Name
              }
              disabled={true}
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Type</label>
            <input
              className="form-control "
              // value={this.state.Type}
              disabled={true}
              value={
                this.state.bookingType.filter((e) => {
                  return e.ID === this.state.Type;
                })[0]?.Name
              }
            ></input>
          </div>
          {/* <div class="form-group col-md-3">
            <label>Title</label>
            <select
              disabled
              className="form-control "
              value={this.state.Title}
              onChange={(event) => this.ChangeVal(event, "Title")}
            >
              <option value="0"></option>
              <option value="1">Mr</option>
              <option value="2">Miss</option>
            </select>
          </div> */}

          <div class="form-group col-md-3">
            <label>Address 1</label>
            <input
              value={this.state.address1}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Address 2</label>
            <input
              value={this.state.address2}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Pincode</label>
            <input
              value={this.state.pincode}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>City</label>
            <input
              value={this.state.City}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>State</label>
            <input
              className="form-control "
              value={
                this.state.bookingstate.filter((e) => {
                  return e.ID === this.state.State;
                })[0]?.Name
              }
              disabled={true}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>District</label>
            <input
              className="form-control "
              value={
                this.state.booking_district.filter((e) => {
                  return e.ID === this.state.District;
                })[0]?.Name
              }
              disabled={true}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Tehsil</label>
            <input
              className="form-control "
              value={
                this.state.booking_town.filter((e) => {
                  return e.ID === parseInt(this.state.Town);
                })[0]?.Town_Name
              }
              disabled={true}
            ></input>
          </div>

          <div class="form-group col-md-3">
            <label>Region</label>
            <input
              className="form-control "
              value={
                this.state.booking_region.filter((e) => {
                  return e.ID === parseInt(this.state.Region);
                })[0]?.Name
              }
              disabled={true}
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Country </label>
            <input
              disabled={true}
              value={"INDIA"}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Email</label>
            <input
              value={this.state.email}
              disabled={true}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Attended By</label>
            <input
              className="form-control "
              value={this.state.attendedby}
              disabled={true}
            ></input>
          </div>
          {/* <div class="form-group col-md-3">
            <label>Allocated To</label>
            <input
              className="form-control "
              value={this.state.allocatedto}
              disabled={true}
            ></input>
          </div> */}
        </div>
      </div>
    );
  };
  bookingDetails = () => {
    return (
      <div class="form-row">
        <div className="form-group col-md-3">
          <label>Booking No {this.required()}</label>
          <input
            readOnly
            value={this.state.Booking_No}
            className=" form-control "
          ></input>
        </div>
        <div className=" form-group col-md-3">
          <label>Booking Date {this.required()}</label>
          <input
            readOnly
            value={this.state.Booking_Date}
            className=" form-control "
          ></input>
        </div>
        <div className=" form-group col-md-3">
          <label>Cash/Finance {this.required()}</label>
          <select
            onChange={(e) => this.setState({ Vehicle_Fund: e.target.value })}
            value={this.state.Vehicle_Fund}
            className=" form-control "
          >
            <option value="0">Select</option>
            <option value="C">Cash</option>
            <option value="L">Loan</option>
          </select>
        </div>
        {this.state.Vehicle_Fund == "L" && (
          <div className=" form-group col-md-3">
            <label>Loan Amount {this.required()}</label>
            <input
              value={this.state.loan_amount}
              className=" form-control "
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0) {
                  this.setState({ loan_amount: "" });
                } else {
                  this.ChangeVal(e, "loan_amount");
                }
              }}
            ></input>
          </div>
        )}
        {this.state.Vehicle_Fund == "L" && (
         <div className=" form-group col-md-3">
          <label>Financier</label>
          <select
            onChange={(event) => this.ChangeVal(event, "Financier")}
            value={this.state.Financier}
            className=" form-control "
          >
            <option value="0">Select</option>
            {this.Financier_customer()}
          </select>
        </div>)}
        <div className=" form-group col-md-3">
          <label>Booking Advance Amount (-) {this.required()}</label>
          <input
            onChange={(e) => {
              let number = e.target.value;
              if (isNaN(number) || number < 0) {
                this.setState({ Booking_Advance_Amount: "" });
              } else if (e.target.value > this.state.Grand_Total) {
                alert("Booking Advance Amount not greater than Grand Total");
                return;
              } else {
                this.ChangeVal(e, "Booking_Advance_Amount");
                let pending_amount =
                  this.state.Grand_Total && this.state.Grand_Total != ""
                    ? parseFloat(this.state.Grand_Total) - e.target.value
                    : "";
                this.setState({
                  // Booking_Advance_Amount: e.target.value,
                  Pending_Amount: pending_amount,
                });
              }
            }}
            value={this.state.Booking_Advance_Amount}
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label>Payment Mode {this.required()}</label>
          <select
            value={this.state.payment_mode}
            onChange={(e) => {
              this.ChangeVal(e, "payment_mode");
            }}
            className=" form-control"
          >
            <option value={0}>Select</option>

            <option value="RTGS">RTGS</option>
            <option value="Cash">Cash</option>
            <option value="NEFT">NEFT</option>
            <option value="DD">DD</option>
            <option value="Cheque">Cheque</option>
            <option value="IMPS">IMPS</option>
            <option value="Credit_Card">Credit Card</option>
            <option value="Debit_Card">Debit Card</option>
            <option value="UPI">UPI</option>
            <option value="Others">Others</option>
          </select>
        </div>
        <div className=" form-group col-md-3">
          <label>Cheque/Transaction Details. {this.required()}</label>
          <input
            onChange={(e) => {
              this.ChangeVal(e, "payment_ref_no");
            }}
            value={this.state.payment_ref_no}
            className=" form-control "
          ></input>
        </div>
       
       
        <div className=" form-group col-md-3">
          <label>Remark</label>
          <input
            onChange={(e) => this.setState({ Remark: e.target.value })}
            value={this.state.Remark}
            className=" form-control "
          ></input>
        </div>

        <div className=" form-group col-md-3">
          <label htmlFor="date">Expected Delivery Date{this.required()}</label>
          {/* <input
            type="datetime-local"
            className="form-control"
            placeholder=""
            value={moment(this.state.Delivery_Date).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
            onChange={(event) => {
              this.handledateChange(event, "Delivery_Date");
            }}
            min={moment(this.state.Enquiry_date).format("YYYY-MM-DD HH:mm")}
            //min={new Date(moment(this.state.Enquiry_date).format("YYYY-MM-DD HH:mm:ss")).toISOString().split("T")[0]}
          /> */}
          <DatePicker
            value={
              this.state.Delivery_Date
                ? moment(this.state.Delivery_Date).format("YYYY-MM-DD HH:mm:ss")
                : ""
            }
            className=" form-control "
            onChange={(data) => {
              this.setState({
                Delivery_Date: data,
              });
            }}
            min={moment(this.state.Enquiry_date).format("YYYY-MM-DD HH:mm")}
            showTimeSelect
            timeIntervals={1}
            dateFormat="Pp"
          />
        </div>
        <div className=" form-group col-md-3">
          <label>Booking Advance Amount Per Unit</label>
          <input
            disabled
            value={
              this.state.Booking_Advance_Amount && this.state.Quantity
                ? (
                    this.state.Booking_Advance_Amount / this.state.Quantity
                  ).toFixed(2)
                : ""
            }
            className=" form-control "
          ></input>
        </div>
      </div>
    );
  };
  ModelDetails = () => {
    return (
      <div>
        <div class="form-row">
          <div class="form-group col-md-3">
            <label>Model</label>
            <input
              disabled
              className="form-control "
              value={
                this.state.Model_Group.filter((e) => {
                  return e.ID === parseInt(this.state.modelcat);
                })[0]?.["Model Group"]
              }
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Model Code</label>
            <input
              disabled
              className="form-control "
              value={
                this.state.Model_Code.filter((e) => {
                  return e.ID === parseInt(this.state.modelcode1);
                })[0]?.["Model code"]
              }
              onChange={(event) => this.ChangeVal(event, "ModelCode")}
            >
              {/* <option value="0">---select---</option>
                      {this.state.Model_Code.map((e) => {
                return <option value={e.ID}>{e["Model code"]}</option>;
              })} */}
            </input>
          </div>
          <div class="form-group col-md-3">
            <label>Model Name</label>
            <input
              disabled
              className="form-control "
              value={
                this.state.Model_Code.filter((e) => {
                  return e.ID === parseInt(this.state.modelcode1);
                })[0]?.["Model name"]
              }
              onChange={(event) => this.ChangeVal(event, "Model")}
            >
              {/* <option value="0">---Select---</option>
                      {this.GetModelList()} */}
            </input>
          </div>
          <div class="form-group col-md-3">
            <label>Model Colour</label>
            <input
              disabled
              value={
                this.state.Model_Code.filter((e) => {
                  return e.ID === parseInt(this.state.modelcode1);
                })[0]?.Model_Colour
              }
              onChange={(e) => this.onChangeName(e, "Model_Colour")}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Quantity</label>
            <input
              disabled
              value={this.state.Quantity}
              onChange={(e) => this.onChangeName(e, "Quantity")}
              class="form-control"
              placeholder=""
            ></input>
          </div>

          {/* <div class="form-group col-md-3">
                    <label>
                      Total Amount<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                       disabled
                      value={this.state.Total}
                      onChange={(e) => this.onChangeName(e, "Total")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>           */}
        </div>
      </div>
    );
  };
  quotationSubmitted = () => {
    return (
      <div>
        <div className=" form-row">
          <div className="form-group col-md-3">
            <label htmlFor="sale_rate">Ex Showroom Price</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Sale_Rate")}
              disabled
              value={this.state.AMV_Sale_Rate}
              id="sale_rate"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="sale_rate">Quantity</label>
            <input
              onChange={(e) => this.ChangeVal(e, "Quantity")}
              disabled
              value={this.state.Quantity}
              id="sale_rate"
              className=" form-control "
            ></input>
          </div>
          {/* <div  hidden className="form-group col-md-3">
            <label htmlFor="Quantity_Amount">Amount</label>
            <input
              onChange={(e) => this.ChangeVal(e, "Quantity_Amount")}
              disabled
              value={this.state.Quantity_Amount}
              id="Quantity_Amount"
              className=" form-control "
            ></input>
          </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="rto_discount">
              Dealer Proposed Discount Per Unit (-)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Dealer_Proposed_Amount")}
              disabled
              value={this.state.AMV_Dealer_Proposed_Amount}
              id="rto_discount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">
              State Subsidy Amount Per Unit (-)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Subsidy_Amount")}
              disabled
              value={this.state.AMV_Subsidy_Amount}
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">
              Central Subsidy Amount Per Unit (-)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Central_Subsidy_Amount")}
              disabled
              value={
                this.state.AMV_Central_Subsidy_Amount
                  ? parseFloat(this.state.AMV_Central_Subsidy_Amount).toFixed(2)
                  : ""
              }
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Scheme Name</label>
            <input
              // onChange={(e) => this.ChangeVal(e, "SchemeName")}
              value={this.state.Scheme_Name ? this.state.Scheme_Name : ""}
              className=" form-control "
              disabled
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Scheme Amount Per Unit (-)</label>
            <input
              // onChange={(e) => this.ChangeVal(e, "Scheme_Amount")}
              disabled
              value={this.state.Scheme_Amount ? this.state.Scheme_Amount : ""}
              className=" form-control "
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Scheme Name</label>
            <select
              onChange={(e) => this.ChangeVal(e, "SchemeName")}
              value={this.state.SchemeName}
              id="crtm_amount"
              className=" form-control "
            >
              <option value={0}>Select</option>
              {this.state.SchemeOption.map((e) => {
                return <option value={e.ID}>{e.scheme_Name}</option>;
              })}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Scheme Amount Per Unit (-)</label>
            <input
              onChange={(e) => this.ChangeVal(e, "Scheme_Amount")}
              disabled
              value={this.state.SchemeAmount}
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div> */}
          <div class="form-group col-md-3">
            <label htmlFor="Total">Taxable Amount</label>
            <input
              disabled
              value={
                this.state.Taxable_Amt &&
                parseFloat(this.state.Taxable_Amt).toFixed(2)
              }
              onChange={(e) => this.onChangeName(e, "Total")}
              class="form-control"
              placeholder=""
              id="Total"
            ></input>
          </div>
          <div hidden class="form-group col-md-3">
            <label htmlFor="Total">IGST Amount Per Unit (+)</label>
            <input
              disabled
              value={
                this.state.IGST_Amount &&
                parseFloat(this.state.IGST_Amount).toFixed(2)
              }
              class="form-control"
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="Total">CGST Amount Per Unit (+)</label>
            <input
              disabled
              value={
                this.state.CGST_Amount &&
                parseFloat(this.state.CGST_Amount).toFixed(2)
              }
              class="form-control"
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="Total">SGST Amount Per Unit (+)</label>
            <input
              disabled
              value={
                this.state.SGST_Amount &&
                parseFloat(this.state.SGST_Amount).toFixed(2)
              }
              class="form-control"
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="rtoamount">RTO Amount Per Unit (+)</label>
            <div>
              <input
                disabled
                onChange={(e) => this.ChangeVal(e, "AMV_RTO_Amount")}
                value={this.state.AMV_RTO_Amount}
                className=" form-control "
                placeholder="RTO Amount"
                id="rtoamount"
              ></input>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="rto_discount">RTO Discount Per Unit (%)</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_RTO_Discount")}
              disabled
              value={this.state.AMV_RTO_Discount}
              id="rto_discount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="rto_discount_amount">
              RTO Discount Amount Per Unit (-)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_RTO_Discount_Amount")}
              disabled
              value={this.state.AMV_RTO_Discount_Amount}
              id="rto_discount_amount"
              className=" form-control "
            ></input>
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">CRTM Amount Per Unit (+)</label>
            <div>
              <input
                disabled
                onChange={(e) => this.ChangeVal(e, "AMV_CRTM_Amount")}
                value={this.state.AMV_CRTM_Amount}
                placeholder="CRTM Amount"
                id="crtm_amount"
                className=" form-control "
              ></input>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="hpacharge">HPA Charge Per Unit (+)</label>
            <input
              // onChange={(e) => this.ChangeVal(e, "AMV_HPA_Charges")}
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0) {
                  return;
                } else {
                  this.ChangeVal(e, "AMV_HPA_Charges");
                  // this.setState(
                  //   {
                  //     Grand_Total:
                  //       this.state.Taxable_Amt +
                  //       (this.state.AMV_RTO_Amount
                  //         ? parseFloat(this.state.AMV_RTO_Amount)
                  //         : 0) +
                  //       (this.state.Other_Charges
                  //         ? parseFloat(this.state.Other_Charges)
                  //         : 0) +
                  //       (this.state.Total_Accessories_Amount
                  //         ? parseFloat(this.state.Total_Accessories_Amount)
                  //         : 0) +
                  //       (e.target.value ? parseFloat(e.target.value) : 0) +
                  //       (this.state.Other_RTO_Charge
                  //         ? parseFloat(this.state.Other_RTO_Charge)
                  //         : 0) +
                  //       (this.state.HSRP_Charge
                  //         ? parseFloat(this.state.HSRP_Charge)
                  //         : 0) +
                  //       (this.state.AMV_Smart_Card_Amount
                  //         ? parseFloat(this.state.AMV_Smart_Card_Amount)
                  //         : 0) +
                  //       (this.state.AMV_Insurance_Amount
                  //         ? parseFloat(this.state.AMV_Insurance_Amount)
                  //         : 0) +
                  //       (this.state.AMV_CRTM_Amount
                  //         ? parseFloat(this.state.AMV_CRTM_Amount)
                  //         : 0) -
                  //       (this.state.Exchange_Bonus_Amount
                  //         ? parseFloat(this.state.Exchange_Bonus_Amount)
                  //         : 0) -
                  //       (this.state.AMV_RTO_Discount_Amount
                  //         ? parseFloat(this.state.AMV_RTO_Discount_Amount)
                  //         : 0),
                  //     //   -
                  //     // parseFloat(
                  //     // this.state.SchemeOption.filter(
                  //     // (e) => e.ID == this.state.SchemeName
                  //     // )[0]?.Amount
                  //     // ? this.state.SchemeOption.filter(
                  //     // (e) => e.ID == this.state.SchemeName
                  //     // )[0]?.Amount
                  //     // : 0
                  //     // ),
                  //   },
                  //   () => {
                  //     this.setState({
                  //       Pending_Amount:
                  //         parseFloat(this.state.Grand_Total) -
                  //         parseFloat(this.state.Booking_Advance_Amount),
                  //     });
                  //   }
                  // );
                }
              }}
              value={this.state.AMV_HPA_Charges}
              id="hpacharge"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="smart_card_amount">
              Smart Card Amount Per Unit (+)
            </label>
            <div>
              <input
                disabled
                onChange={(e) => this.ChangeVal(e, "AMV_Smart_Card_Amount")}
                value={this.state.AMV_Smart_Card_Amount}
                placeholder="Smart Card Amount"
                id="smart_card_amount"
                className=" form-control "
              ></input>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="HSRP_Charge">HSRP Charge Per Unit (+)</label>
            <input
              onChange={(e) => this.ChangeVal(e, "HSRP_Charge")}
              disabled
              value={this.state.HSRP_Charge}
              id="HSRP_Charge"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Other_RTO_Charge">
              Other RTO Charge Per Unit (+)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "Other_RTO_Charge")}
              disabled
              value={this.state.Other_RTO_Charge}
              id="Other_RTO_Charge"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Insurance_company">
              Insurance Company {this.required()}
            </label>
            <select
              // onChange={(e) => this.ChangeVal(e, "Insurance_company")}
              onChange={(e) =>
                this.setState({ Insurance_company: e.target.value })
              }
              value={this.state.Insurance_company}
              id="Insurance_company"
              className=" form-control "
              disabled={this.state.Is_Insurance == "Y" ? true : false}
            >
              <option value="0">Select</option>
              {this.state.insuranceList.map((e) => {
                return (
                  <option value={e.Insurance_Company}>
                    {e.Insurance_Company}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Insurance_company">
              Insurance Type {this.required()}
            </label>
            <select
              // onChange={(e) => this.ChangeVal(e, "Insurance_company")}
              onChange={(e) =>
                this.setState({ AMV_Insurance_Year: e.target.value })
              }
              value={this.state.AMV_Insurance_Year}
              id="Insurance_company"
              className=" form-control "
              disabled={this.state.Is_Insurance == "Y" ? true : false}
            >
              <option value="0">Select</option>
              <option value="1+4 Comprehensive">1+4 Comprehensive</option>
              <option value="1+4 Third Party">1+4 Third Party</option>
            </select>
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="insurance_year">
              Insurance Year {this.required()}
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Insurance_Year")}
              value={this.state.AMV_Insurance_Year}
              id="insurance_year"
              className=" form-control "
            ></input>
          </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="insurance_amount">
              Insurance Amount Per Unit (+) {this.required()}
            </label>
            <input
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0) {
                  return;
                }
                if (/^0/.test(number)) {
                  number = number.replace(/^0/, "");
                } else {
                  this.ChangeVal(e, "AMV_Insurance_Amount");
                  // this.setState(
                  //   {
                  //     Grand_Total:
                  //       this.state.Taxable_Amt +
                  //       (this.state.AMV_RTO_Amount
                  //         ? parseFloat(this.state.AMV_RTO_Amount)
                  //         : 0) +
                  //       (this.state.Other_Charges
                  //         ? parseFloat(this.state.Other_Charges)
                  //         : 0) +
                  //       (this.state.Total_Accessories_Amount
                  //         ? parseFloat(this.state.Total_Accessories_Amount)
                  //         : 0) +
                  //       (e.target.value ? parseFloat(e.target.value) : 0) +
                  //       (this.state.Other_RTO_Charge
                  //         ? parseFloat(this.state.Other_RTO_Charge)
                  //         : 0) +
                  //       (this.state.HSRP_Charge
                  //         ? parseFloat(this.state.HSRP_Charge)
                  //         : 0) +
                  //       (this.state.AMV_Smart_Card_Amount
                  //         ? parseFloat(this.state.AMV_Smart_Card_Amount)
                  //         : 0) +
                  //       (this.state.AMV_HPA_Charges
                  //         ? parseFloat(this.state.AMV_HPA_Charges)
                  //         : 0) +
                  //       (this.state.AMV_CRTM_Amount
                  //         ? parseFloat(this.state.AMV_CRTM_Amount)
                  //         : 0) -
                  //       (this.state.Exchange_Bonus_Amount
                  //         ? parseFloat(this.state.Exchange_Bonus_Amount)
                  //         : 0) -
                  //       (this.state.AMV_RTO_Discount_Amount
                  //         ? parseFloat(this.state.AMV_RTO_Discount_Amount)
                  //         : 0),
                  //     //   -
                  //     // parseFloat(
                  //     // this.state.SchemeOption.filter(
                  //     // (e) => e.ID == this.state.SchemeName
                  //     // )[0]?.Amount
                  //     // ? this.state.SchemeOption.filter(
                  //     // (e) => e.ID == this.state.SchemeName
                  //     // )[0]?.Amount
                  //     // : 0
                  //     // ),
                  //   },
                  //   () => {
                  //     this.setState({
                  //       Pending_Amount:
                  //         parseFloat(this.state.Grand_Total) -
                  //         parseFloat(this.state.Booking_Advance_Amount),
                  //     });
                  //   }
                  // );
                }
              }}
              value={this.state.AMV_Insurance_Amount}
              id="insurance_amount"
              className=" form-control "
              disabled={this.state.Is_Insurance == "Y" ? true : false}
              // type="number"
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Total_Accessories_Amount">
              Total Accessories Amount Per Unit (+)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "Total_Accessories_Amount")}
              disabled
              value={
                this.state.Total_Accessories_Amount
                  ? parseFloat(this.state.Total_Accessories_Amount).toFixed(2)
                  : 0
              }
              id="Total_Accessories_Amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Other_Charges">Other Charges Per Unit (+)</label>
            <input
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0) {
                  return;
                } else {
                  this.ChangeVal(e, "Other_Charges");
                  // this.setState(
                  //   {
                  //     Grand_Total:
                  //       this.state.Taxable_Amt +
                  //       (this.state.AMV_RTO_Amount
                  //         ? parseFloat(this.state.AMV_RTO_Amount)
                  //         : 0) +
                  //       (e.target.value ? parseFloat(e.target.value) : 0) +
                  //       (this.state.Total_Accessories_Amount
                  //         ? parseFloat(this.state.Total_Accessories_Amount)
                  //         : 0) +
                  //       (this.state.AMV_Insurance_Amount
                  //         ? parseFloat(this.state.AMV_Insurance_Amount)
                  //         : 0) +
                  //       (this.state.Other_RTO_Charge
                  //         ? parseFloat(this.state.Other_RTO_Charge)
                  //         : 0) +
                  //       (this.state.HSRP_Charge
                  //         ? parseFloat(this.state.HSRP_Charge)
                  //         : 0) +
                  //       (this.state.AMV_Smart_Card_Amount
                  //         ? parseFloat(this.state.AMV_Smart_Card_Amount)
                  //         : 0) +
                  //       (this.state.AMV_HPA_Charges
                  //         ? parseFloat(this.state.AMV_HPA_Charges)
                  //         : 0) +
                  //       (this.state.AMV_CRTM_Amount
                  //         ? parseFloat(this.state.AMV_CRTM_Amount)
                  //         : 0) -
                  //       (this.state.Exchange_Bonus_Amount
                  //         ? parseFloat(this.state.Exchange_Bonus_Amount)
                  //         : 0) -
                  //       (this.state.AMV_RTO_Discount_Amount
                  //         ? parseFloat(this.state.AMV_RTO_Discount_Amount)
                  //         : 0),
                  //     //   -
                  //     // parseFloat(
                  //     // this.state.SchemeOption.filter(
                  //     // (e) => e.ID == this.state.SchemeName
                  //     // )[0]?.Amount
                  //     // ? this.state.SchemeOption.filter(
                  //     // (e) => e.ID == this.state.SchemeName
                  //     // )[0]?.Amount
                  //     // : 0
                  //     // ),
                  //   },
                  //   () => {
                  //     this.setState({
                  //       Pending_Amount:
                  //         parseFloat(this.state.Grand_Total) -
                  //         parseFloat(this.state.Booking_Advance_Amount),
                  //     });
                  //   }
                  // );
                }
              }}
              value={this.state.Other_Charges}
              id="Other_Charges"
              className=" form-control "
              // type="number"
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Exchange_Bonus_Amount">
              Exchange Bonus With Tax Per Unit (-)
            </label>
            <input
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0) {
                  return;
                } else {
                  this.ChangeVal(e, "Exchange_Bonus_Amount");
                  // this.setState(
                  //   {
                  //     Grand_Total:
                  //       this.state.Taxable_Amt +
                  //       (this.state.AMV_RTO_Amount
                  //         ? parseFloat(this.state.AMV_RTO_Amount)
                  //         : 0) +
                  //       (this.state.Other_Charges
                  //         ? parseFloat(this.state.Other_Charges)
                  //         : 0) +
                  //       (this.state.Total_Accessories_Amount
                  //         ? parseFloat(this.state.Total_Accessories_Amount)
                  //         : 0) +
                  //       (this.state.AMV_Insurance_Amount
                  //         ? parseFloat(this.state.AMV_Insurance_Amount)
                  //         : 0) +
                  //       (this.state.Other_RTO_Charge
                  //         ? parseFloat(this.state.Other_RTO_Charge)
                  //         : 0) +
                  //       (this.state.HSRP_Charge
                  //         ? parseFloat(this.state.HSRP_Charge)
                  //         : 0) +
                  //       (this.state.AMV_Smart_Card_Amount
                  //         ? parseFloat(this.state.AMV_Smart_Card_Amount)
                  //         : 0) +
                  //       (this.state.AMV_HPA_Charges
                  //         ? parseFloat(this.state.AMV_HPA_Charges)
                  //         : 0) +
                  //       (this.state.AMV_CRTM_Amount
                  //         ? parseFloat(this.state.AMV_CRTM_Amount)
                  //         : 0) -
                  //       (e.target.value ? parseFloat(e.target.value) : 0) -
                  //       (this.state.AMV_RTO_Discount_Amount
                  //         ? parseFloat(this.state.AMV_RTO_Discount_Amount)
                  //         : 0),
                  //     //    -
                  //     // parseFloat(
                  //     //   this.state.SchemeOption.filter(
                  //     //     (e) => e.ID == this.state.SchemeName
                  //     //   )[0]?.Amount
                  //     //     ? this.state.SchemeOption.filter(
                  //     //         (e) => e.ID == this.state.SchemeName
                  //     //       )[0]?.Amount
                  //     //     : 0
                  //     // ),
                  //   },
                  //   () => {
                  //     this.setState({
                  //       Pending_Amount:
                  //         parseFloat(this.state.Grand_Total) -
                  //         parseFloat(this.state.Booking_Advance_Amount),
                  //     });
                  //   }
                  // );
                }
              }}
              value={this.state.Exchange_Bonus_Amount}
              id="Exchange_Bonus_Amount"
              className=" form-control "
              // type="number"
            ></input>
          </div>

          {/* <div className="form-group col-md-3">
          <label htmlFor="special_number_amount">Special Number Amount</label>
          <input
            disabled
            onChange={(e) => this.ChangeVal(e, "AMV_Special_Number_Amount")}
            value={this.state.AMV_Special_Number_Amount}
            id="special_number_amount"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="disbursnment_amount">Disbursment Amount</label>
          <input
            onChange={(e) => this.ChangeVal(e, "AMV_Disbursment_Amount")}
            disabled
            value={this.state.AMV_Disbursment_Amount}
            id="disbursnment_amount"
            className=" form-control "
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="other_charges">Other Charges</label>
          <input
            onChange={(e) => this.ChangeVal(e, "AMV_Other_Charges")}
            disabled
            value={this.state.AMV_Other_Charges}
            className=" form-control "
            id="other_charges"
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="oher_charges_amount">Other Charges Amount</label>
          <input
            onChange={(e) => this.ChangeVal(e, "Other_Charges_Amount")}
            disabled
            value={this.state.Other_Charges_Amount}
            id="oher_charges_amount"
            className=" form-control "
          ></input>
        </div> */}
   
          <div className="form-group col-md-3">
            <label htmlFor="Grand_Total">Grand Total</label>
            <input
              disabled
              // onChange={(e) => this.ChangeVal(e, "Grand_Total")}
              value={
                this.state.Grand_Total &&
                parseFloat(this.state.Grand_Total).toFixed(2)
              }
              id="Grand_Total"
              className=" form-control "
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label htmlFor="pending_amount">Balanced Amount</label>
            <input
              disabled
              value={
                this.state.Pending_Amount
                  ? parseFloat(this.state.Pending_Amount).toFixed(2)
                  : ""
              }
              onChange={(e) => this.onChangeName(e, "Pending_Amount")}
              class="form-control"
              id="pending_amount"
            ></input>
          </div>
        </div>
      </div>
    );
  };
  Booking_cancel = () => {
    return (
      <div>
        <div class="form-row mt-2">
          <div class="form-group col-md-3">
            <label htmlFor="Cancel_Remark">Remark {this.required()}</label>
            <select
              value={this.state.Cancel_Remark}
              onChange={(e) => this.ChangeVal(e, "Cancel_Remark")}
              id="Cancel_Remark"
              className=" form-control "
            >
              <option value="0">Select</option>
              {this.state.reasonLost.map((e) => {
                return <option value={e.ID}>{e.reason}</option>;
              })}
            </select>
            {/* <label>
                      Remark <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      
                      value={this.state.Cancel_Remark}
                      onChange={(e) => this.onChangeName(e, "Cancel_Remark")}
                      class="form-control"
                      placeholder=""
                    ></input> */}
          </div>
          <div class="form-group col-md-3">
            {/* {this.state.enq_confirm != "N" && this.state.enq_cancel != "N" && this.state.Is_booking != "N"  && this.state.M1lost != "N"  && this.state.M1LostConfirm != "N"  ? (
                      ""
                    ) : ( */}

            {this.state.IS_confirm == "N" && this.state.M3lost == "N" ? (
              <button
                disabled={this.state.isSaveClicked}
                // hidden={this.state.enq_confirm == "Y" && this.state.enq_cancel == "Y" && this.state.Is_booking == "Y" && this.state.M1lost == "Y" && this.state.M1LostConfirm == "Y"}
                type="submit"
                className={"btn Opertion_btn clickable"}
                onClick={() => {
                  this.lostbooking();
                }}
                style={{ marginTop: "25px" }}
              >
                {this.state.saveLoader && (
                  <span
                    class="spinner-grow spinner-grow-sm"
                    style={{ color: "unset", marginRight: "5px" }}
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Cancel
              </button>
            ) : null}
            {/* )} */}
          </div>
        </div>
      </div>
    );
  };
  lostbooking = () => {
    this.Validate_cancel();
    // $("#enquirylost_Popup").modal("show");
  };
  Validate_cancel = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emailisvalid = emailRegex.test(this.state.email);

    if (
      this.state.Cancel_Remark == "" ||
      this.state.Cancel_Remark == "0" ||
      this.state.Cancel_Remark == null
    ) {
      alert("Please Select the Remark.");
      $("#enquirylost_Popup").modal("hide");
      $("#payment_error_modal_2").modal("hide");
      $("#payment_error_modal_2").modal("show");
      return;
    }
    this.setState({
      isSaveClicked: true,
    });
    $("#enquirylost_Popup").modal("show");
    // this.Cancel_Enquiry();
  };
  visit_details = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "159",
      ID: "'0'",
      condition: "\"and stage='M0'\"",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            vistitdetails: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  vistitdetails = (event) => {
    let row = [];
    this.state.vistitdetails.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  visitDetails = () => {
    return (
      <div class="row">
        <div className=" col-3">
          <label htmlFor="visit-details">Visit Details</label>
          <select
            className="form-control"
            value={this.state.visit_details}
            onChange={(event) => {
              this.onSelect(event, "visit_details");
            }}
          >
            <option value="0"></option>
            {this.vistitdetails()}
          </select>
        </div>
        <div className=" col-3">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            className="form-control"
            placeholder=""
            value={this.state.startDate}
            onChange={(event) => {
              this.handledateChange(event, "startDate");
            }}
          />
        </div>
        <div className=" col-3">
          <label htmlFor="new-visit-details">New Visit Details</label>
          <select
            className="form-control"
            value={this.state.next_visit_details}
            onChange={(event) => {
              this.onSelect(event, "next_visit_details");
            }}
          >
            <option value="0"></option>
            {this.vistitdetails()}
          </select>
        </div>
        <div className=" col-3">
          <label htmlFor="date2">Date</label>
          <input
            type="date"
            className="form-control"
            placeholder=""
            value={this.state.endDate}
            onChange={(event) => {
              this.handledateChange(event, "endDate");
            }}
          />
        </div>
      </div>
    );
  };
  renderFileUploadTableHeader() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          width: "90%",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        <td width="25px" className="table-text"></td>

        <td>Document Number</td>
        <td>Document Attachment</td>
        <td width="25px" className="table-text"></td>
      </tr>
    );
  }
  // handelFileUploadAddNew = (id) => {
  //   this.setState((prev) => {
  //     return {
  //       ...prev,
  //       ConditionCode: [
  //         ...this.state.ConditionCode,
  //         {
  //           id: id + 1,
  //           Doc_Name: "",
  //           Doc_Attachment: "",
  //         },
  //       ],
  //     };
  //   });
  // };
  accessoriesHeader() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        <td width="25px" className="table-text"></td>
        <td width="20%">Accessories Name</td>
        <td width="8%">Accessories Amount</td>
        <td width="8%">HSN</td>
        <td width="8%">IGST</td>
        <td width="8%">IGST Amount</td>
        <td width="8%">SGST</td>
        <td width="8%">SGST Amount</td>
        <td width="8%">CSGT</td>
        <td width="8%">CSGT Amount</td>
        <td width="20%">Accessories Total With Tax</td>
        <td width="25px" className="table-text"></td>
      </tr>
    );
  }
  accessoriesAddNew = (id) => {
    if (this.state.ConditionCode.length < this.state.MaxAccessoriesCount) {
      this.setState(
        (prev) => {
          return {
            ...prev,
            ConditionCode: [
              ...this.state.ConditionCode,
              {
                id: id + 1,
                accid: "",
                accessories_name: "",
                accessories_amount: "",
                hsn: "",
                igst: "",
                igst_amount: "",
                sgst: "",
                sgst_amount: "",
                cgst: "",
                cgst_amount: "",
                accessories_total_withtax: "",
                options: this.state.AccessoriesOption,
              },
            ],
          };
        },
        () => {
          console.log(this.state.ConditionCode, "kkjjk");
        }
      );
    } else {
      this.setState({
        disableaddnew: true,
      });
    }
  };
  accessoriesDelet = (id, accid) => {
    if (accid > 0) {
      $("#GLKickOut_Popup").modal("show");
      this.setState({
        delete_id: id,
        accssory_id: accid,
      });
    } else if (this.state.ConditionCode.length > 1) {
      let newConditionArray = this.state.ConditionCode.filter((e) => {
        return e.id != id;
      });
      const removerlist = this.state.ConditionCode.filter((e) => {
        return e.id == id;
      });
      newConditionArray = newConditionArray.map((e) => {
        return {
          ...e,
          options: [...e.options, removerlist[0].selectedAccessories],
        };
      });
      let priceofRemoved = removerlist[0]?.accessories_total_withtax;
      this.setState(
        {
          ConditionCode: [...newConditionArray],
          Total_Accessories_Amount:
            this.state.Total_Accessories_Amount - priceofRemoved,
          disableaddnew: false,
          AccessoriesOption: [
            ...this.state.AccessoriesOption,
            removerlist[0].selectedAccessories,
          ],
        },
        () => {
          this.calculateGrandTotal();
        }
      );
    } else {
      const removerlist = this.state.ConditionCode.filter((e) => {
        return e.id == id;
      });
      console.log(removerlist, "jkjkj");
      let priceofRemoved = removerlist[0]?.accessories_total_withtax;
      this.setState(
        {
          ConditionCode: [
            {
              id: 1,
              accid: "",
              accessories_name: "",
              accessories_amount: "",
              hsn: "",
              igst: "",
              igst_amount: "",
              sgst: "",
              sgst_amount: "",
              cgst: "",
              cgst_amount: "",
              accessories_total_withtax: "",
            },
          ],
          Total_Accessories_Amount:
            this.state.Total_Accessories_Amount - priceofRemoved,
        },
        () => {
          this.calculateGrandTotal();
        }
      );
    }
  };
  delete_api = () => {
    const url = Urls.python_baseurl + "/SP_Delete_M3_Accesories";
    var data = JSON.stringify({
      M3_ID: this.state.accssory_id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (this.state.ConditionCode.length == 1) {
            const removerlist = this.state.ConditionCode.filter((e) => {
              return e.id == this.state.delete_id;
            });
            this.setState({
              ConditionCode: [
                {
                  id: 1,
                  accid: "",
                  accessories_name: "",
                  accessories_amount: "",
                  hsn: "",
                  igst: "",
                  igst_amount: "",
                  sgst: "",
                  sgst_amount: "",
                  cgst: "",
                  cgst_amount: "",
                  accessories_total_withtax: "",
                  options: [
                    ...this.state.AccessoriesOption,
                    removerlist[0]?.selectedAccessories,
                  ],
                },
              ],
              AccessoriesOption: [
                ...this.state.AccessoriesOption,
                removerlist[0]?.selectedAccessories,
              ],
            });
            let newConditionArray = this.state.ConditionCode.filter((e) => {
              return e.id != this.state.delete_id;
            });

            newConditionArray = newConditionArray.map((e) => {
              return {
                ...e,
                options: [...e.options, removerlist[0].selectedAccessories],
              };
            });
            let priceofRemoved = removerlist[0]?.accessories_total_withtax;
            this.setState(
              {
                Total_Accessories_Amount:
                  this.state.Total_Accessories_Amount - priceofRemoved,
                disableaddnew: false,
                AccessoriesOption: [
                  ...this.state.AccessoriesOption,
                  removerlist[0].selectedAccessories,
                ],
              },
              () => {
                this.calculateGrandTotal();
              }
            );
          } else {
            let newConditionArray = this.state.ConditionCode.filter((e) => {
              return e.id != this.state.delete_id;
            });
            const removerlist = this.state.ConditionCode.filter((e) => {
              return e.id == this.state.delete_id;
            });
            console.log(removerlist, "klklk");
            newConditionArray = newConditionArray.map((e) => {
              return {
                ...e,
                options: [...e?.options, removerlist[0]?.selectedAccessories],
              };
            });
            let priceofRemoved = removerlist[0]?.accessories_total_withtax;
            this.setState(
              {
                ConditionCode: [...newConditionArray],
                Total_Accessories_Amount:
                  this.state.Total_Accessories_Amount - priceofRemoved,
                AccessoriesOption: [
                  ...this.state.AccessoriesOption,
                  removerlist[0]?.selectedAccessories,
                ],
                disableaddnew: false,
              },
              () => {
                this.calculateGrandTotal();
              }
            );
          }

          $("#GLKickOut_Popup").modal("hide");
          $("#payment_error_modal_2").modal("hide");
          $("#payment_error_modal_2").modal("show");
          // this.setState({
          //   ConditionCode:[]
          // },()=>{
          //   this.displayBookingM3()
          // })
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GLKickOutPopup = () => {
    return (
      <div
        class="modal"
        id="GLKickOut_Popup"
        style={{ marginLeft: "25%" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog-Memberinfo"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog" style={{ maxHeight: "initial" }}>
            <div>
              <div class="modal-body" style={{ fontSize: "16px" }}>
                Are you sure want to delete?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn light_blue1"
                  data-dismiss="modal"
                  onClick={() => {
                    $("#GLKickOut_Popup").modal("hide");
                    $("#payment_error_modal_2").modal("hide");
                    $("#payment_error_modal_2").modal("show");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn light_blue1"
                  onClick={this.delete_api}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  handelchange = (key, value, id) => {
    if (key == "accessories_name") {
      let newOptions = this.state.AccessoriesOption;
      let xyz = this.state.ConditionCode.filter((e) => e?.id == id)[0]
        ?.selectedAccessories;
      let newdata = newOptions.filter((e) => {
        return e.Accesories_Name == value;
      });
      let singleaccessoriesamt = 0;
      let data = this.state.ConditionCode;
      let Total_Accessories_Amount = 0;
      if (value == 0) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].id === id) {
            newOptions.push(data[i].selectedAccessories);
            data[i] = {
              id: id,
              accid: "",
              accessories_name: "",
              accessories_amount: "",
              hsn: "",
              igst: "",
              igst_amount: "",
              sgst: "",
              sgst_amount: "",
              cgst: "",
              cgst_amount: "",
              accessories_total_withtax: "",
              options: data[i].options,
              selectedAccessories: {},
            };
          }
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i].id !== id) {
            data[i].options = [
              ...data[i].options,
              newOptions[newOptions.length - 1],
            ];
          }
        }
      } else {
        newOptions = newOptions.filter((e) => {
          return e.Accesories_Name !== value;
        });
        if (xyz && Object.keys(xyz).length > 0) {
          newOptions.push(xyz);
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i]?.id == id) {
            data[i].accessories_name = newdata[0]?.Accesories_Name;
            data[i].accessories_amount = newdata[0]?.NDP;
            data[i].hsn = newdata[0]?.HSN;
            data[i].accid = newdata[0]?.ID;
            data[i]["selectedAccessories"] = newdata[0];
            if (this.state.State == sessionStorage.getItem("Dealer_state")) {
              console.log(Total_Accessories_Amount, "jkjkjkj", 1);
              data[i].igst = 0;
              data[i].sgst = newdata[0]?.SGST;
              data[i].cgst = newdata[0]?.CGST;
              data[i].igst_amount = 0;
              data[i].sgst_amount =
                parseFloat(newdata[0]?.NDP) *
                (parseFloat(newdata[0]?.SGST) / 100);
              data[i].cgst_amount =
                parseFloat(newdata[0]?.NDP) *
                (parseFloat(newdata[0]?.CGST) / 100);
              data[i].accessories_total_withtax = (
                parseFloat(data[i].accessories_amount) +
                data[i].sgst_amount +
                data[i].igst_amount +
                data[i].cgst_amount
              ).toFixed(2);
            } else {
              console.log(Total_Accessories_Amount, "jkjkjkj", 2);
              data[i].igst = newdata[0]?.IGST;
              data[i].sgst = 0;
              data[i].cgst = 0;
              data[i].sgst_amount = 0;
              data[i].cgst_amount = 0;
              data[i].igst_amount =
                parseFloat(newdata[0]?.NDP) *
                (parseFloat(newdata[0]?.IGST) / 100);
              data[i].accessories_total_withtax = (
                parseFloat(data[i].accessories_amount) +
                data[i].sgst_amount +
                data[i].igst_amount +
                data[i].cgst_amount
              ).toFixed(2);
              singleaccessoriesamt = parseFloat(
                data[i].accessories_total_withtax
              );
            }
          } else {
            if (data[i].accessories_name) {
              data[i].options = [...newOptions, data[i].selectedAccessories];
            } else {
              data[i].options = [...newOptions];
            }
          }
        }
      }
      for (let i = 0; i < this.state.ConditionCode.length; i++) {
        Total_Accessories_Amount += this.state.ConditionCode[i]
          .accessories_total_withtax
          ? parseFloat(this.state.ConditionCode[i].accessories_total_withtax)
          : 0;
      }
      this.setState(
        {
          ConditionCode: data,
          Total_Accessories_Amount: Total_Accessories_Amount,
          AccessoriesOption: newOptions,
        },
        () => {
          console.log(this.state.ConditionCode, "jkjkkj");
          this.calculateGrandTotal();
        }
      );
    }
  };
  accessoriesBody() {
    console.log("ConditionCode", this.state.ConditionCode);
    return this.state.ConditionCode.map((d, index) => {
      return (
        <tr key={d.id}>
          <td>
            {index === this.state.ConditionCode.length - 1 && (
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={
                  this.state.IS_confirm !== "Y" && !this.state.disableaddnew
                    ? {
                        color: "var(--main-bg-color)",
                        fontSize: "25px",
                        cursor: "pointer",
                      }
                    : {
                        color: "var(--main-bg-color)",
                        fontSize: "25px",
                        cursor: "default",
                        opacity: "30%",
                      }
                }
                onClick={() =>
                  this.state.IS_confirm !== "Y" &&
                  !this.state.disableaddnew &&
                  this.accessoriesAddNew(d.id)
                }
              />
            )}
          </td>
          <td>
            <select
              onChange={(e) => {
                this.handelchange("accessories_name", e.target.value, d.id);
              }}
              value={d.accessories_name}
              type="text"
              list="accessories_name"
              className=" form-control "
              disabled={this.state.IS_confirm !== "Y" ? false : true}
            >
              <option value={0}>Select</option>
              {d.options.map((e) => {
                return (
                  <option value={e?.Accesories_Name}>
                    {e?.Accesories_Name}
                  </option>
                );
              })}
            </select>
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("accessories_amount", e.target.value, d.id);
              }}
              value={d.accessories_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="text"
              onChange={(e) => {
                this.handelchange("hsn", e.target.value, d.id);
              }}
              value={d.hsn}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("igst", e.target.value, d.id);
              }}
              value={d.igst}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("igst_amount", e.target.value, d.id);
              }}
              value={d.igst_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("sgst", e.target.value, d.id);
              }}
              value={d.sgst}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("sgst_amount", e.target.value, d.id);
              }}
              value={d.sgst_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("cgst", e.target.value, d.id);
              }}
              value={d.cgst}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("cgst_amount", e.target.value, d.id);
              }}
              value={d.cgst_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange(
                  "accessories_total_with_tax",
                  e.target.value,
                  d.id
                );
              }}
              value={d.accessories_total_withtax}
            />
          </td>
          <td style={{ width: "25px", backgroundColor: "white" }}>
            <img
              src={require("../../../components/Images/trash.png")}
              style={
                this.state.IS_confirm !== "Y"
                  ? { width: "20px", cursor: "pointer" }
                  : { width: "20px", cursor: "default", opacity: "30%" }
              }
              onClick={() =>
                this.state.IS_confirm !== "Y" &&
                this.accessoriesDelet(d.id, d.accesores_ID)
              }
            ></img>
          </td>
        </tr>
      );
    });
  }
  handelCancel = () => {
    $("#enquirylost_Popup").modal("hide");
  };
  BookinglostPopup = () => {
    return (
      <div
        class="modal"
        id="enquirylost_Popup"
        style={{ marginLeft: "25%" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog-Memberinfo"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog" style={{ maxHeight: "initial" }}>
            <div>
              <div class="modal-body" style={{ fontSize: "16px" }}>
                Are you sure want to Cancel Booking?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn light_blue1"
                  data-dismiss="modal"
                  onClick={() => {
                    $("#enquirylost_Popup").modal("hide");
                    $("#payment_error_modal_2").modal("hide");
                    $("#payment_error_modal_2").modal("show");
                    this.setState({
                      isSaveClicked: false,
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn light_blue1"
                  onClick={() => {
                    this.Cancel_booking();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  Cancel_booking = (event) => {
    const url = Urls.python_baseurl + "/SP_Save_M3LostReason";
    var data = JSON.stringify({
      ID: this.state.lost_ID ? this.state.lost_ID : 0,
      UserId: `'${sessionStorage.getItem("UserId")}'`,
      HOBrID: `'${sessionStorage.getItem("dealerid")}'`,
      Lead_hdr_ID: `'${
        this.state.M3_Confirm_ID ? this.state.M3_Confirm_ID : 0
      }'`,
      rsn_Id: `'${this.state.Cancel_Remark}'`,
      CompID: `''`,
      CompetitorMake: `''`,
      Qty: `${parseInt(this.state.Quantity)}`,
      UserRoleID: `'${sessionStorage.getItem("UserRole")}'`,
    });

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].newID) {
            alert("Booking Cancelled Succesfully");
            this.setState({
              isSaveClicked: false,
            });
          }

          window.location.reload();
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">
          Booking{" "}
          {this.state.IS_confirm == "Y"
            ? "(Confirmed)"
            : this.state.M3lost == "Y"
            ? "(Cancelled)"
            : ""}
        </h4>
        <br></br>
        <div
          style={{ marginTop: "-10px" }}
          className=" row justify-content-end "
        >
          <button
            hidden={
              this.state.IS_confirm == "Y"
                ? true
                : this.state.M3lost == "Y"
                ? true
                : false
            }
            disabled={this.state.isSaveClicked || this.state.isConfirmClicked}
            type="submit"
            className="btn Opertion_btn clickable"
            style={{ fontSize: "12px", float: "right", clear: "both" }}
            onClick={this.Validate}
          >
            Save
          </button>
          <button
            hidden={
              this.state.IS_confirm == "Y"
                ? true
                : this.state.M3lost == "Y"
                ? true
                : false
            }
            type="submit"
            className="btn Opertion_btn clickable mr-4"
            style={{ fontSize: "12px", float: "right", clear: "both" }}
            onClick={this.handelConfirm}
            disabled={
              this.state.isSaveClicked ||
              this.state.isConfirmClicked ||
              this.state.isSavedClick == "pendingClick"
            }
          >
            Confirm
          </button>
          <br></br>
        </div>
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Customer Information
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">{this.ClientInformation()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"headingSeven" + this.props.modelid}>
            <h2 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target={"#collapseSeven" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseSeven" + this.props.modelid}
              >
                Model Details
              </button>
            </h2>
          </div>
          <div
            id={"collapseSeven" + this.props.modelid}
            class="collapse  "
            aria-labelledby={"headingSeven" + this.props.modelid}
          >
            <div class="card-body">{this.ModelDetails()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingSix">
            <h2 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseSix"
                aria-expanded="true"
                aria-controls="collapseSix"
              >
                Booking Details
              </button>
            </h2>
          </div>
          <div
            id={"collapseSix"}
            class="collapse show"
            aria-labelledby={"headingSix"}
          >
            <div class="card-body">{this.bookingDetails()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFive">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#CollapseFive"
                aria-expanded="true"
                aria-controls="CollapseFive"
              >
                Accessories
              </button>
            </h5>
          </div>
          <div
            id="CollapseFive"
            class="collapse show "
            aria-labelledby="headingFive"
          >
            <div style={{ overflowX: "scroll" }} class="card-body">
              <table
                // className="table table-bordered mt-2"
                id="userList"
                align="center"
                style={{ width: "130%" }}
              >
                <thead>{this.accessoriesHeader()}</thead>
                <tbody>{this.accessoriesBody()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"headingFour" + this.props.modelid}>
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target={"#collapseFour" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseFour" + this.props.modelid}
              >
                Rates & Discount
              </button>
            </h5>
          </div>
          <div
            id={"collapseFour" + this.props.modelid}
            class="collapse"
            aria-labelledby={"headingFour" + this.props.modelid}
          >
            <div class="card-body">{this.quotationSubmitted()}</div>
          </div>
        </div>
        {this.state.isSavedClick == "saveClcik" ? (
          <div class="card">
            <div class="card-header" id="headingFour">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  Booking Cancel
                </button>
              </h2>
            </div>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionExample"
            >
              <div class="card-body">{this.Booking_cancel()}</div>
            </div>
          </div>
        ) : null}
        {this.GLKickOutPopup()}
        {this.BookinglostPopup()}
        <br></br>
        <br></br>
      </div>
    );
  }
}
