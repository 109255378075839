import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useEffect, useState } from "react";
import Urls from "../../../helpers/Urls";
import { useLocation, useHistory } from "react-router-dom";
import "./fileDisplay.css";

export default function FileDisplay() {
  const [file, setFile] = useState([]);
  const [error, setError] = useState(false)
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location?.state?.pathValue) {
      fetch(location?.state?.pathValue).then((res) => {
        console.log("🚀 ~ fetch ~ res:", res)
        if(res.status === 200){
          setFile([{ uri: res.url }]);
        }else{
       setError(true)
        }
      });
    }
  }, []);

  return (
    <div>
      <button
        stlye={{ float: "right" }}
        onClick={() => history.push("JobCard_List")}
        className="btn Opertion_btn clickable"
      >
        Back
      </button>
      {error && <div>
        <h1 className="error_file">File not found</h1>
        </div>}
      {file?.[0]?.uri && !error && (
        <div className="container_file">
          <DocViewer 
          documents={file} 
          pluginRenderers={DocViewerRenderers}
          />
        </div>
      )}
    </div>
  );
}
