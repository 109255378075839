import React from "react";
import "../../containers/Files/files-styles.css";
// import '../../containers/Claim_276_RealTime/Real_Time_276/style.css'
import "../../containers/color.css";
import { Link } from "react-router-dom";
// import { addNotation } from '../../../helpers/getDetails';

export class Tiles extends React.Component {
  constructor(props) {
    console.log(props, "eeeeeee");
    super(props);
    this.state = {};
  }

  _getstyles = () => {
    let style =
      this.props.header_text.toLowerCase().indexOf("total sent to availity") !=
        -1 || this.props.header_text == "Reconciled"
        ? "green summary-title"
        : this.props.header_text.toLowerCase().indexOf("total") != -1
        ? "blue summary-title"
        : this.props.header_text == "Manual Correction" ||
          this.props.header_text == "Match Not Found" ||
          this.props.header_text.toLowerCase().indexOf("add") != -1 ||
          this.props.header_text.toLowerCase().indexOf("exception") != -1
        ? "orange summary-title"
        : this.props.header_text.toLowerCase().indexOf("term") != -1 ||
          this.props.header_text.toLowerCase().indexOf("accepted") != -1 ||
          this.props.header_text.toLowerCase().indexOf("paid") != -1 ||
          this.props.header_text.toLowerCase().indexOf("error resolved") !=
            -1 ||
          this.props.header_text.toLowerCase() == "valid transaction"
        ? "green summary-title"
        : this.props.header_text.toLowerCase().indexOf("rejected") != -1 ||
          this.props.header_text.toLowerCase().indexOf("failed") != -1 ||
          this.props.header_text.toLowerCase().indexOf("denied") != -1 ||
          this.props.header_text.toLowerCase().indexOf("error") != -1 ||
          this.props.header_text.toLowerCase().indexOf("invalid") != -1
        ? "red summary-title"
        : this.props.header_text
            .toLowerCase()
            .indexOf("avg response time (sec)") != -1
        ? "dark_red summary-title"
        : "blue summary-title";
    return style;
  };

  renderDiffTile = () => {
    return this.props.isClickable ? (
      <div
        className="summary-enrollment summary-container"
        style={{
          paddingLeft: "16px",
          width: `${this.props.width ? this.props.width : "200px"}`,
          cursor: this.props.value!=0?"pointer":"",
        }}
        onClick={(event) => {
          if (this.props.value != 0) {
            this.props.onClick(event);
          }
        }}
      >
        <div className="summary-header">{this.props.header_text}</div>
        <div
          className="summary-title blue summary-title"
          style={{ color: this.props.color }}
        >
          {this.props.value}
        </div>
      </div>
    ) : (
      <div
        className="summary-enrollment summary-container"
        style={{
          paddingLeft: "16px",
          width: `${this.props.width ? this.props.width : "200px"}`,
          cursor: this.props.value!=0?"pointer":"",
        }}
        onClick={(event) => {
          if (this.props.value != 0) {
            this.props.onClick(event);
          }
        }}
      >
        <div className="summary-header">{this.props.header_text}</div>
        <div className="summary-title" style={{ color: this.props.color }}>
          {this.props.value}
        </div>
      </div>
    );
  };

  render() {
    return this.props.differentTile ? this.renderDiffTile() : null;
  }
}
