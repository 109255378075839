import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import Strings from "../../../helpers/Strings";
import moment from "moment";
import HiPaaSLogo from "../../components/Images/HiPaaS_logo.png";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";

const bcrypt = require("bcryptjs");

export class Model_Code extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      Active: "",
      Next_Service_HRS: "",
      Next_Service_KMS: "",
      Next_Service_Days: "",
      Model_body_Type: "",
      HS_Code: "",
      Model_Category: "",
      Model_Name: "",
      Model_Code: "",
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      rowData: [],
    };
  }
  componentDidMount() {
    this.MasterAPICall()
    const element=document.getElementsByClassName("ag-header-icon ag-header-cell-menu-button")
    console.log(element,"pppp")
    for(let i=0;i<element.length;i++){
      console.log(element[i],"pppp")
      element[i].ariaHidden="false"
      // element[i]
    }
  }

  MasterAPICall = ()=>{
    const url = Urls.python_baseurl + "/GetModelCode";
    var data = JSON.stringify({
        "ID": "null"
       
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        if(res && res.length>0){
          this.setState({
            rowData:res,
            Active: res[0].Active,
            typeofbody: res[0].typeofbody,
            emissionnorms: res[0].emissionnorms,
            FieldType: res[0].FieldType,
            GrossWeight: res[0].GrossWeight,
            HSN_Code: res[0].HSN_Code,
            Model_Gr_description: res[0].Model_Gr_description,
            colour: res[0].colour,
            unladen_Weight: res[0].unladen_Weight,
            KW: res[0].KW,
            Model_Name: res[0]["Model Name"],
            Model_Code: res[0]["Model code"],
          })
        }
  
      })
      .catch(err => {
          process.env.NODE_ENV == 'development' && console.log(err)
      });
  }

  masterTable() {
    let columnDefs = [
      { headerName: "Model Code", field: "Model code", width:"120",cellStyle:{color:"var(--main-bg-color)", cursor:"pointer"} },
      { headerName: "Model Name", field: "Model Name", width:"140" },
      { headerName: "Model", field: "Model_Gr_description", width:"120" },
      { headerName: "HSN Code", field: "HSN_Code", width:"120" },
      { headerName: "Type of Body", field: "typeofbody", width:"120" },
      {
        headerName: "Emission Norms",
        field: "emissionnorms",
        width:"120",
      },
      { headerName: "Field Type", field: "FieldType", width:"120" },
      { headerName: "Gross Weight", field: "GrossWeight", width:"120" },
      { headerName: "Colour", field: "colour", width:"120" },
      { headerName: "Unladen Weight", field: "unladen_Weight", width:"120" },
      { headerName: "KW", field: "KW", width:"120" },
      { headerName: "Active", field: "Active", width:"120" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) =>{
              if(event.colDef.field=="Model code"){
                this.setState({
                  Active: event.data.Active,
                  typeofbody: event.data.typeofbody,
                  emissionnorms: event.data.emissionnorms,
                  FieldType: event.data.FieldType,
                  GrossWeight: event.data.GrossWeight,
                  HSN_Code: event.data.HSN_Code,
                  Model_Gr_description: event.data.Model_Gr_description,
                  colour: event.data.colour,
                  unladen_Weight: event.data.unladen_Weight,
                  KW: event.data.KW,
                  Model_Name: event.data["Model Name"],
                  Model_Code: event.data["Model code"],
                })
              }
            }
            }
          ></AgGridReact>
        </div>
      </div>
    );
  }
  header = () => {
    return (
      <div className=" card p-3 mt-4">
        <div className=" row">
        <div className=" col-3">
            <label htmlFor="model-c">Model</label>
            <input
              value={this.state.Model_Gr_description}
              id="model-c"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="model-c">Model Code</label>
            <input
              value={this.state.Model_Code}
              id="model-c"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="model-n">Model Name</label>
            <input
              value={this.state.Model_Name}
              id="model-n"
              className=" form-control"
              readOnly
            ></input>
          </div>
          {/* <div className=" col-3">
            <label htmlFor="active">Model Category</label>
            <input
              value={this.state.Model_Category}
              id="active"
              className=" form-control "
              readOnly
            ></input>
          </div> */}
          <div className=" col-3">
            <label htmlFor="HS_Code">HSN Code</label>
            <input
              value={this.state.HSN_Code}
              id="HS_Code"
              className=" form-control"
              readOnly
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label htmlFor="Blood_Type">Type of Body</label>
            <input
              value={this.state.typeofbody}
              id="Blood_Type"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="emissionnorms">Emission Norms</label>
            <input
              value={this.state.emissionnorms}
              id="emissionnorms"
              className=" form-control "
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="FieldType">Field Type</label>
            <input
              value={this.state.FieldType}
              id="FieldType"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="GrossWeight">Gross Weight</label>
            <input
              value={this.state.GrossWeight}
              id="GrossWeight"
              className=" form-control"
              readOnly
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
        <div className=" col-3">
            <label htmlFor="colour">Colour</label>
            <input
              value={this.state.colour}
              id="colour"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="unladen_Weight">Unladen Weight</label>
            <input
              value={this.state.unladen_Weight}
              id="unladen_Weight"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="KW">KW</label>
            <input
              value={this.state.KW}
              id="KW"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Active">Active</label>
            <input
              value={this.state.Active}
              id="Active"
              className=" form-control"
              readOnly
            ></input>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Model Code</h4><br></br>
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Model Code List
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">{this.masterTable()}</div>
          </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Model Code Details
                </button>
              </h5>
            </div>

            <div
              id="collapseTwo"
              class="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">{this.header()}</div>
            </div>
          </div>
      </div>
    );
  }
}
