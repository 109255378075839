import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

const bcrypt = require("bcryptjs");

export class Autogenrated_agrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      Active: "",
      emp_name: "",
      emp_type: "",
      contact_no: "",
      Empl_code: "",
      Date_of_joining: "",
      email: "",
      id: 0,
      Empolyee_Type_option: [],
      reactCode: '',
      show:false,
    };
  }
  componentDidMount() {

  }
  onSelect = (event, name) => {
    this.setState({
      Type: event.target.options[event.target.selectedIndex].value,
    });
  };


  codeGen = () => {
    var labelArray = this.state.Date_of_joining.split(',');

    var columnDefs = [];

    labelArray.forEach(function (label) {
      var columnDef = {
        headerName: label.trim(),
        field: label.trim().toLowerCase(),
        sortable: true,
        filter: true
      };
      columnDefs.push(columnDef);
    })
    console.log(columnDefs)
    let reactCode = `
import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";


export class aggrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
     
    };
  }
  componentDidMount() {

  }
    masterTable() {
      let columnDefs =${JSON.stringify(columnDefs)}  
      return (
        <div>
          <div className="ag-theme-balham" style={{ padding: "0" }}>
            <AgGridReact
              modules={this.state.modules}
              columnDefs={columnDefs}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              defaultColDef={this.state.defaultColDef}
              suppressRowClickSelection={true}
              groupSelectsChildren={true}
              debug={true}
              rowSelection={this.state.rowSelection}
              rowGroupPanelShow={this.state.rowGroupPanelShow}
              pivotPanelShow={this.state.pivotPanelShow}
              enableRangeSelection={true}
              paginationAutoPageSize={false}
              pagination={true}
              domLayout={this.state.domLayout}
              paginationPageSize={this.state.paginationPageSize}
              onGridReady={this.onGridReady}
              rowData={this.state.rowData}
              enableCellTextSelection={true}
             ></AgGridReact>
          </div>
        </div>
      );
    }    
  render() {
    return (
      <div>
         <div class="card">
                 <div class="card-body">{this.masterTable()}</div>
            </div>
  
      </div>
    );
  }
}
    `


    this.setState({
      reactCode: reactCode,
      show:true
    })
  }
  handledateChange = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  };

  header = () => {
    return (
      <div className=" card p-3">
        <div className="row">

          <div className="col-3 mt-2">
            <label htmlFor="Type">Type <span style={{ color: "red" }}>*</span></label>
            <select
              className="form-control list-dashboard"
              id="Type"
              value={this.state.Type}
              onChange={(event) => {
                this.onSelect(event, "Type");
              }}
            >
              <option selected value="0">Select</option>
              <option value="agrid">Ag Grid Table</option>
              {/* <option value="N">N</option> */}
            </select>
          </div>
        </div>
        <div className="row">

          <div className="col-3 mt-2">
            <label htmlFor="active">Enter the Mapping Name <span style={{ color: "red" }}>*</span></label>
            <textarea

              autoComplete='off'
              value={this.state.Date_of_joining}
              onChange={(event) => {
                this.handledateChange(event, "Date_of_joining");
              }}

              id="model-c"
              className=" form-control"
            ></textarea>
          </div>
          <div className="col-3 mt-2" >
            <button style={{ marginTop: "25px" }}
              type="submit"
              className="btn Opertion_btn clickable mr-5"
              onClick={this.codeGen}
            >
              Get Code
            </button>
          </div>

        </div>
        <div className="row">

          {/* 
          <div className="col-6 mt-6">
            <textarea

              autoComplete='off'
              value={this.state.reactCode}
              id="model-c"
              className=" form-control"
            ></textarea >
          </div> */}
          {this.state.show ?
          <div className="col-11 mt-6" contentEditable="true">
            <SyntaxHighlighter style={dracula}>
              {this.state.reactCode}
              <button className="copy-button" onClick={this.copyToClipboard}>
                <FontAwesomeIcon icon={faCopy} />
              </button>
            </SyntaxHighlighter>
          </div>:null}
        </div>
      </div>
    );
  };
  copyToClipboard = () => {
    navigator.clipboard.writeText(this.state.reactCode);
    // You can also provide feedback to the user that the code has been copied, if needed
    alert('Code copied to clipboard');
  };
  addnew = () => {
    this.setState({
      Active: "",
      emp_name: "",
      emp_type: "",
      contact_no: "",
      id: 0,
      Empl_code: "",
      email: "",
      Date_of_joining: ""
    });
  };


  render() {
    return (
      <div>
        <h4 className="heading_text"></h4>
  
        <div
          class="form-group col-md-12 mt-2"
        >
          {this.header()}

        </div>
      </div>
    );
  }
}
