import React from "react";
import "./style.css";
import Urls from "../../../helpers/Urls";
import "../color.css";
import Strings from "../../../helpers/Strings";
import moment from "moment";
import HiPaaSLogo from "../../components/Images/HiPaaS_logo.png";
// import logo from'../../components/Images/Logo.png';
import logo from "../../components/Images/Joy_connect_Logo.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const bcrypt = require("bcryptjs");

export class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      EmailId: "",
      User_P: "",
      errors: {},
      loginStarted: false,
      disabled: "block",
    };
    this.loginUser = this.loginUser.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this.lodderfalse = this.lodderfalse.bind(this);
  }

  checkuser = async () => {
    const url = Urls.python_baseurl + "/SP_check_User";

    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        LoginName: `${this.state.EmailId}`,
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache",
        Accept: "application/json",
      },
      method: "POST",
      // body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "test");
        if (res && res.length > 0) {
          if (res[0].password) {
            if (bcrypt.compareSync(this.state.User_P, res[0].password)) {
              this.loginUser(1);
            } else {
              this.showAlert();
            }
          } else {
            this.showAlert();
          }
        } else {
          this.showAlert();
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  showAlert = () => {
    alert("You have entered wrong username or password");
    this.lodderfalse();
  };

  loginUser = async (flag) => {
    const url = Urls.python_baseurl + "/SP_Login";
    let data = JSON.stringify({
      Is_active: flag,
      LoginName: this.state.EmailId,
    });

    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        // 'LoginName': this.state.EmailId,
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache",
        Accept: "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      // .then(res => {
      //     console.log("res1",res)
      //     if(res[0].Login == 1 && (res[0].role_desc == 'Payment Poster' || res[0].role_desc == 'Research Team')){
      //         sessionStorage.setItem('isOutbound', true)
      //     }
      //     return res
      // })
      .then((res) => {
        console.log(res, "test1");
        if (res && res.length > 0) {
          this.setState({
            loggedIn: true,
            loginStarted: false,
          });
          setTimeout(() => {
            sessionStorage.setItem("token", "sdjjasdnjas");
            sessionStorage.setItem("UserId", res[0][0]?.ID);
            sessionStorage.setItem("Username", res[0][0]?.LoginName);
            sessionStorage.setItem("Employee_Code", res[0][0]?.Employee_Code);
            sessionStorage.setItem(
              "dealerid",
              res && res[1].length > 0 && res[1][0]?.Dealer_ID
                ? res[1][0]?.Dealer_ID
                : res[0][0]?.Dealer_ID
            );
            sessionStorage.setItem("UserRole", res[0][0]?.UserRole_ID);
            sessionStorage.setItem(
              "fin_year",
              res && res[1].length > 0 && res[1][0]?.FinYear
                ? res[1][0]?.FinYear
                : ""
            );
            sessionStorage.setItem(
              "Dealer_Code",
              res && res[1].length > 0 && res[1][0]?.Dealer_Code
                ? res[1][0]?.Dealer_Code
                : ""
            );
            sessionStorage.setItem("Dealer_state", res[0][0]?.State_ID);
            sessionStorage.setItem(
              "dealer_name",
              res && res[1].length > 0 && res[1][0]?.dealer_name
                ? res[1][0]?.dealer_name
                : ""
            );
            sessionStorage.setItem(
              "Dealer_Pin_Code",
              res && res[1].length > 0 && res[1][0]["Pin Code"]
                ? res[1][0]["Pin Code"]
                : ""
            );
            sessionStorage.setItem(
              "Dealer_Address",
              res && res[1].length > 0 && res[1][0]?.Address
                ? res[1][0]?.Address
                : ""
            );
            sessionStorage.setItem(
              "Dealer_Contact",
              res && res[1].length > 0 && res[1][0]["Contact No"]
                ? res[1][0]["Contact No"]
                : ""
            );
            sessionStorage.setItem(
              "Dealer_Email",
              res && res[1].length > 0 && res[1][0]["E-Mail ID"]
                ? res[1][0]["E-Mail ID"]
                : ""
            );
            sessionStorage.setItem(
              "Dealer_Gst_No",
              res && res[1].length > 0 && res[1][0]["GST NO"]
                ? res[1][0]["GST NO"]
                : ""
            );
            sessionStorage.setItem(
              "Dealer_District",
              res && res[1].length > 0 && res[1][0]["District Name"]
                ? res[1][0]["District Name"]
                : ""
            );
            sessionStorage.setItem(
              "Dealer_Category",
              res && res[1].length > 0 && res[1][0]["Category"]
                ? res[1][0]["Category"]
                : ""
            );
            this.props.handleFlag(this.state.loggedIn);
          }, 100);
        } else {
          this.setState(
            {
              loginStarted: false,
            },
            () => {
              alert("You have entered wrong username or password");
            }
          );
        }
      })
      .catch((err) => {
        console.log("error is this ", err);
        this.setState({
          loginStarted: false,
        });
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  getCalenderData = () => {
    fetch(`${Urls.File_upload}/HolidayList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          let PublicHolidays = result.map(function (obj) {
            return moment(obj.holidayDate, "YYYY-MM-DD").format("MM/DD/YYYY");
          });
          sessionStorage.setItem(
            "PublicHolidays",
            JSON.stringify(PublicHolidays)
          );
        }
      });
  };

  onHandleChange(event, key) {
    this.setState({
      [key]: event.target.value,
    });
  }
  _onBlur() {
    console.log("on blur is hit");
    this.validateForm();
  }

  lodderfalse() {
    this.setState({
      loginStarted: false,
    });
  }

  validateForm() {
    let formIsValid = true;
    if (this.state.User_P == "" && this.state.EmailId == "") {
      formIsValid = false;
      this.refs.User_P.focus();
      this.refs.EmailId.focus();
      alert("Please Enter your UserName & Password.");
      this.lodderfalse();
    } else if (this.state.EmailId == "") {
      formIsValid = false;
      this.refs.EmailId.focus();
      alert("Please Enter your UserName .");
      this.lodderfalse();
    } else if (this.state.User_P == "") {
      formIsValid = false;
      this.refs.User_P.focus();
      alert("Please Enter your Password.");
      this.lodderfalse();
    }
    // else if (this.state.EmailId != "") {
    //     //regular expression for email validation
    //     var pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    //     if (!pattern.test(this.state.EmailId)) {
    //         formIsValid = false;
    //         alert("Please Enter Valid UserName.");
    //         this.lodderfalse()
    //     }
    // }

    return formIsValid;
  }
  handleKeyPress = (event) => {
    if (event.which == 13 || event.keyCode == 13) {
      this.setState(
        {
          loginStarted: true,
        },
        () => {
          setTimeout(() => {
            if (this.validateForm()) {
              this.checkuser();
              // this.directLogin()
            }
          }, 100);
        }
      );
    }
  };
  directLogin = () => {
    this.setState({
      loggedIn: true,
      loginStarted: false,
    });
    setTimeout(() => {
      sessionStorage.setItem("token", "sdjjasdnjas");
      sessionStorage.setItem("UserId", 75);
      sessionStorage.setItem("dealerid", 126);
      sessionStorage.setItem("UserRole", 6);
      sessionStorage.setItem("fin_year", "2324");
      sessionStorage.setItem("Dealer_state", 20);
      sessionStorage.setItem("hide-chassis-pending-for-pdi-notificatoion",false)
      this.props.handleFlag(this.state.loggedIn);
    }, 100);
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-9"
            style={{
              minHeight: "100vh",
              marginLeft: "-15px",
              backgroundColor: "var(--main-bg-color) ",
            }}
          >
            <img src={logo} alt="logo" className="Hipaas_logo" align="center" />
            {/* <div
                  className=" text-center "
                  style={{ marginTop: "25%", color: "white" }}
                >
                  <h1>HIPAAS</h1>
                  <h1>JOY E-BIKE</h1>
                </div> */}

            {/* <p className="para">
                  HiPaaS is a HIPAA compliant microservices-based Healthcare
                  cloud platform to enable EHR integration, resolve
                  interoperability issues, <br />
                  simplify healthcare standards and aggregate healthcare data
                </p> */}
            {/* <a
                  href="https://www.hipaas.com/"
                  target="blank"
                  className="contact"
                >
                  {" "}
                  Contact Us{" "}
                </a> */}
          </div>
          <div className="col-3 vartical-center">
            {this.state.loginStarted == true ? (
              <div
                style={{
                  marginLeft: "45%",
                  position: "absolute",
                  marginTop: "50%",
                }}
                class="spinner-border text-muted"
              ></div>
            ) : (
              ""
            )}
            <div
              style={{
                opacity: this.state.loginStarted == true ? "0.4" : "",
              }}
            >
              <div style={{ padding: "20px" }}>
                <span
                  style={{
                    color: "var(--gray-dark-color)",
                    fontSize: "16px",
                  }}
                >
                  Log in to <b>Joy e-Bike DMS </b>
                </span>
                <p>
                  Enter your username and password to log into your Joy e-Bike
                  DMS
                </p>
              </div>
              <div
                className="form-group"
                style={{ paddingLeft: "20px", marginTop: "-5px" }}
              >
                <label className="header_login">
                  <b>Username</b>
                </label>
                <input
                  disabled={this.state.loginStarted}
                  ref="EmailId"
                  onChange={(e) => this.onHandleChange(e, "EmailId")}
                  name="email"
                  type="text"
                  className="form-control shadow-none widthText"
                  id="Email"
                  placeholder="Enter Username"
                  onKeyPress={this.handleKeyPress}
                />
                {/* <div className="errorMsg">{this.state.errors.EmailId}</div> */}
              </div>
              <div className="form-group" style={{ paddingLeft: "20px" }}>
                <label className="header_login">
                  <b>Password</b>
                </label>
                <input
                  disabled={this.state.loginStarted}
                  ref="User_P"
                  onChange={(e) => this.onHandleChange(e, "User_P")}
                  name="password"
                  type="password"
                  className="form-control shadow-none widthText"
                  id="Password"
                  placeholder="Enter Password"
                  onKeyPress={this.handleKeyPress}
                />
                {/* <div className="errorMsg">{this.state.errors.User_P}</div> */}
              </div>
              {/* <div style={{ marginTop: "15px", paddingLeft: "22px" }} >
                            <a href="#" style={{ color: "var(--login-color)", fontSize: "12px" }}>Forgot your password?</a>
                        </div> */}

              <div style={{ paddingLeft: "10px" }}>
                <button
                  data-toggle="modal"
                  data-target="#myModal10"
                  disabled={this.state.loginStarted}
                  onClick={(event) => {
                    this.setState(
                      {
                        loginStarted: true,
                      },
                      () => {
                        setTimeout(() => {
                          if (this.validateForm()) {
                            this.checkuser();
                            // this.directLogin()
                          }
                          // this.loginUser(event)
                        }, 100);
                      }
                    );
                  }}
                  className="btn btn-demo"
                >
                  Log in to Account
                </button>
                {/* <div className="row justify-content-end mr-2">
                <Link to={"/"+Strings.ForgotPassword} class="ml-3" style={{color:"var(--main-bg-color)"}}>Forgot Password</Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
