import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import Urls from "../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
const $ = window.$;
export class FollowUP extends React.Component {
  constructor(props) {
    // console.log("props",moment(props.EnquiryDetails[1][0].next_date).format("YYYY-MM-DD"))
    super(props);
    this.state = {
      paginationPageSize: 10,
      sqlfor: props.location.state && props.location.state.data[0] && props.location.state.data[0].pendind_data ? props.location.state.data[0].pendind_data  : 'All',
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      kwh: "",
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      vistitdetails: [],
      visittest: [],
    };
  }

  componentDidMount() {
    this.visit_details();
    this.MasterAPI();
  }
  MasterAPI = (demo) => {
    const url = Urls.python_baseurl + "/SP_VehicleSalesDetails";
    var data = JSON.stringify({
      Dealer_ID:sessionStorage.getItem("dealerid"),
      SqlFor:"'Followup'",
      startdate:"''",
      enddate:"''",
      Dashboard_For:"''"
      
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res[0],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  visit_details = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "159",
      ID: "'0'",
      condition: "\"and stage='M0'\"",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res, "jkjkj");
          this.setState({
            vistitdetails: res,
            visittest: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {});
  };
  visitDetails = () => {
    return (
      <div className=" mt-4">
        <div className=" row justify-content-end ">
          <button
            className="btn Opertion_btn clickable mr-3"
            onClick={() => {
              this.handelSave();
            }}
          >
            Save
          </button>
        </div>
        <div class="row">
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">Visit Details</label>
            <select
              className="form-control"
              value={this.state.visit_details_value}
              onChange={(e) =>
                this.setState({ visit_details_value: e.target.value }, () => {
                
                })
              }
            >
              <option value="0"></option>
              {this.state.visittest.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              className="form-control"
              placeholder=""
              value={this.state.startDate}
              onChange={(e) => this.setState({ startDate: e.target.value })}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="new-visit-details">New Visit Details</label>
            <select
              className="form-control"
              value={this.state.next_visit_details_value}
              onChange={(e) =>
                this.setState({ next_visit_details_value: e.target.value })
              }
            >
              <option value="0"></option>
              {this.state.visittest.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="date2">Date</label>
            <input
              type="date"
              className="form-control"
              placeholder=""
              value={this.state.endDate}
              onChange={(e) => this.setState({ endDate: e.target.value })}
              min={this.state.startDate}
            />
          </div>
        </div>
        <div class="row">
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">Status</label>
            <select
              value={this.state.Status_value}
              onChange={(e) => this.setState({ Status_value: e.target.value })}
              class="form-control"
              placeholder=""
            >
              <option>select</option>
              <option>Open</option>
              <option>Successfully Closed</option>
              <option>Unsuccessfully Closed</option>
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">Remark</label>
            <input
              value={this.state.Remark}
              onChange={(e) => this.setState({ Remark: e.target.value })}
              class="form-control"
              placeholder=""
            ></input>
          </div>
        </div>
      </div>
    );
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  handelClose = () => {
    $("#myModal2").modal("hide");
  };
  PopUp = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(33, 37, 41, 0.8)",
          marginLeft: "-1.5%",
        }}
        class="modal"
        id="myModal2"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" onClick={this.handelClose}>
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.visitDetails()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  MasterTable() {
    let data = this.state.rowData;
    let vs = this.state.vistitdetails;
    if (this.state.vistitdetails != []) {
      data.map((e, index) => {
        data[index]["NextFollowUPName"] = vs.filter((e1) => {
          return e1.ID === data[index].next_obj_id;
        })[0]?.Name;
      });
    }

    console.log(this.state.vistitdetails, "jfkjkjk");
    let columnDefs = [
      {
        headerName: "Name",
        field: "name",
        width: "148px",
      },
      { headerName: "Mobile", field: "mobile", width: "148px" },
      { headerName: "Vehicle", field: "model_name", width: "148px" },
      // { headerName: "Type", field: "colour", width: "148px" },
      // { headerName: "Allocate", field: "chassis_no", width: "148px" },
      { headerName: "Enquiry No", field: "Inq_no", width: "148px" },
      { headerName: "Enquiry On", field: "Inq_date", width: "148px" },
      { headerName: " Remark", field: "remark", width: "148px" },
      { headerName: "Status", field: "status", width: "148px" },
      // { headerName: "Charger No", field: "ChargerNo", width: "148px" },
      {
        headerName: "Next Followup ",
        field: "NextFollowUPName",
        width: "148px",
      },
      {
        headerName: "Action",
        field: "Motor_No",
        width: "145px",
        cellRenderer: (data) => {
          if (data.data) {
            return (
              <button
                className="btn Opertion_btn clickable mr-3"
                onClick={() => {
                  $("#myModal2").modal("show");
                }}
              >
                Add
              </button>
            );
          }
        },
      },
    ];

    return (
      <div className="">
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={data}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (event.colDef.headerName == "Action") {
                this.setState({
                  M0_Hdr: event?.data?.M0_Hdr,
                  commit_det: event?.data?.commit_det,
                });
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  followUp = () => {
    return (
      <div>
        <div>
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Follow Up
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">{this.MasterTable()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  handelSave = () => {
    const url = Urls.python_baseurl + "/FollowupUpdate_SP_Followup_Update";
    var data = JSON.stringify({
      M0ID: `${this.state.M0_Hdr}`,
      obj_Id: `'${this.state.visit_details_value}'`,
      obj_date: `'${moment(this.state.startDate).format("DD-MM-YYYY")}'`,
      discussion: "''",
      time_spent: "''",
      next_obj_Id: `'${this.state.next_visit_details_value}'`,
      next_date: `'${moment(this.state.endDate).format("DD-MM-YYYY")}'`,
      commit_det: `'${this.state.commit_det}'`,
      remark: `'${this.state.Remark}'`,
      status: `'${this.state.Status_value}'`,
    });
    console.log(data, "hjj");
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          alert("Record Saved Succesfully");
          $("#myModal2").modal("hide");
          this.MasterAPI();
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  render() {
    return (
      <div>
        <h5 className="heading_text" style={{marginLeft:"0px"}}>Follow Up</h5>
        <div className=" mt-4">{this.MasterTable()}</div>
        {this.PopUp()}
      </div>
    );
  }
}
