import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
const $ = window.$;
const bcrypt = require("bcryptjs");

export class Vehicle_Purchase_Report extends React.Component {
  constructor(props) {
    console.log(props, "hjjhjh");
    super(props);
    this.state = {
      domLayout: "autoHeight",
      paginationPageSize: 10,
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        cellRenderer: "agGroupCellRenderer",
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      selectedReport: "",
      Model_Code_Options: [],
      Title: this.props?.location?.state?.data?.Title,
      MenuOption: [],
    };
  }
  componentDidMount() {
    this.getMenue();
  }
  getMenue = () => {
    const url = Urls.python_baseurl + "/SP_Get_MenuReports";
    var data = JSON.stringify({});
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newenueOption = res?.filter((e) => e?.Menu == this.state.Title);
          newenueOption = newenueOption.map((e) => {
            return { ...e, checked: false };
          });
          this.setState(
            {
              MenuOption: newenueOption,
            },
            () => {
              let FromHistory = localStorage.getItem("FromHistory");
              if (
                this.props?.location?.state?.data?.report_Name &&
                FromHistory == "true"
              ) {
                let newRes = this.state.MenuOption.map((e) => {
                  if (e.ID == this.props?.location?.state?.data?.id) {
                    return { ...e, checked: true };
                  } else {
                    return { ...e, checked: false };
                  }
                });
                localStorage.setItem("FromHistory", false);
                this.setState({
                  selectedReport: this.props?.location?.state?.data,
                  MenuOption: newRes,
                });
              }
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  displayRadioValue = () => {
    let ele = document.getElementsByName("options");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].checked) {
        let report_name = ele[i].getAttribute("report_name");
        return {
          id: ele[i].value,
          report_Name: report_name,
          Title: this.state.Title,
          url:"/Vehicle_Purchase_Report"
        };
      }
    }
  };
  showReport = () => {
    return (
      <div className=" row px-3">
        {this.state.MenuOption.map((e) => {
          return (
            <div className="form-group col-md-3 glassMorphism p-2 pl-4 ml-1">
              <label htmlFor={e?.menueName}>
                <input
                  name="options"
                  id={e?.menueName}
                  value={e?.ID}
                  report_name={e?.Report_Name}
                  className="form-check-input"
                  type="radio"
                  style={{ width: "16px", height: "16px", marginTop: "-4px" }}
                  checked={e?.checked}
                  onClick={(e) => {
                    this.setState(
                      {
                        selectedReport: this.displayRadioValue(),
                      },
                      () => {
                        console.log(this.state.selectedReport, "jjhjh");
                        let newRes = this.state.MenuOption?.map((e) => {
                          if (e?.ID == this.state.selectedReport?.id) {
                            return { ...e, checked: true };
                          } else {
                            return { ...e, checked: false };
                          }
                        });
                        this.setState({
                          MenuOption: newRes,
                        });
                      }
                    );
                  }}
                />
              </label>
              <span>{e?.Report_Name}</span>
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Reports</h4>
        <br></br>
        <div className="container-fluid mt-3  p-3 pl-4 card">
          {this.showReport()}
          <div className=" row justify-content-end ">
            <button
              onClick={() => {
                if (!this.state.selectedReport) {
                  alert("Please Select the Report ");
                  return;
                }
                this.props.history.push("/Reports", {
                  selectedReport: this.state.selectedReport,
                });
              }}
              className="btn Opertion_btn clickable mr-2"
            >
              Preview
            </button>
          </div>
        </div>
      </div>
    );
  }
}
