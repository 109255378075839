import React from 'react';
import Urls from '../../../helpers/Urls';
import '../color.css'
import Strings from '../../../helpers/Strings';
import moment from 'moment';
import HiPaaSLogo from '../../components/Images/HiPaaS_logo.png';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
 import 'ag-grid-enterprise/styles/ag-theme-balham.css';

const bcrypt = require('bcryptjs');

export class Demo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paginationPageSize: 5,
            domLayout: 'autoHeight',
            autoGroupColumnDef: {
                headerName: 'Group',
                minWidth: 170,
                field: 'athlete',
                valueGetter: function (params) {
                    if (params.node.group) {
                        return params.node.key;
                    } else {
                        return params.data[params.colDef.field];
                    }
                },
                headerCheckboxSelection: true,
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: { checkbox: true },
            },
            defaultColDef: {
                cellClass: 'cell-wrap-text',
                autoHeight: true,
                sortable: true,
                resizable: true,
                filter: true,
            },
            rowSelection: 'never',
            rowGroupPanelShow: 'never',
            pivotPanelShow: 'never',
            rowData: [],
            showerror: '',
            Aggrid_ClaimLineData: ''
        }
        }
    _ClaimLineTable() {
        let rowData=[
            {"ClaimID":"abc","ServiceLineCount":"123","ServiceDate":"12/5/1213","ProcedureDate":"12/45/34","PaidServiceUnitCount":"hhhh"},
            {"ClaimID":"abc","ServiceLineCount":"124","ServiceDate":"12/6/1213","ProcedureDate":"12/45/34","PaidServiceUnitCount":"hhhh"},
            {"ClaimID":"abc","ServiceLineCount":"125","ServiceDate":"12/3/1213","ProcedureDate":"12/45/34","PaidServiceUnitCount":"hhhh"},
            {"ClaimID":"abc","ServiceLineCount":"128","ServiceDate":"12/3/1213","ProcedureDate":"12/45/34","PaidServiceUnitCount":"hhhh"}
        ]
        let columnDefs = [
            // { headerName: "Molina Claim ID", field: "MolinaClaimID" },
            { headerName: "X12 Claim ID", field: "ClaimID" },

            { headerName: "Service Line No.", field: "ServiceLineCount" },
            { headerName: " Service Date", field: "ServiceDate" },
            { headerName: "Procedure Code", field: "ProcedureDate" },
            { headerName: "Unit", field: "PaidServiceUnitCount", flex: 1 },

        ]

        return (
            <div>
                <div className="ag-theme-balham" style={{ padding: '0' }}>
                    <h6 className="font-size">Claim Line Data</h6>
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnDefs}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        defaultColDef={this.state.defaultColDef}
                        suppressRowClickSelection={true}
                        groupSelectsChildren={true}
                        debug={true}
                        rowSelection={this.state.rowSelection}
                        rowGroupPanelShow={this.state.rowGroupPanelShow}
                        pivotPanelShow={this.state.pivotPanelShow}
                        enableRangeSelection={true}
                        paginationAutoPageSize={false}
                        pagination={true}
                        domLayout={this.state.domLayout}
                        paginationPageSize={this.state.paginationPageSize}
                        onGridReady={this.onGridReady}
                        rowData={rowData}
                        enableCellTextSelection={true}

                    >
                    </AgGridReact>
                </div>
            </div>
        )
    }
    render() {
        return (
          <div>{this._ClaimLineTable()}</div>
        );
    }
}