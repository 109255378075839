import React from "react";
import "../Files/files-styles.css";
import Urls from "../../../helpers/Urls";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { ListFilter } from "../../components/ListFilter";
import * as XLSX from "xlsx";
const $ = window.$;
export class UserUsagesReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      ethnicity_map: [],
      Ethnicity: [],
      Input_Code: "",
      Text: "",
      Output_FHIR_Code: "",
      Code_System: "",
      Display: "",
      Definition: "",
      Type: "",
      rowData: [],
      HiPaaS_ID: "",
      loaderforupload: false,
      dealerid:
        props.location.state &&
        props?.location?.state?.data &&
        props.location.state.data[0] &&
        props.location.state.data[0].dealerid
          ? props.location.state.data[0].dealerid
          : sessionStorage.getItem("dealerid"),
      showtimerange:
        props.location.state &&
        props.location.state.data &&
        props.location.state.data[0] &&
        props.location.state.data[0].showtimernagefilter &&
        props.location.state.data[0].showtimernagefilter,
      columnDefs: [
        { headerName: "Dealer Name", field: "Dealer_Name", width: 120 },
        { headerName: "D Code", field: "DealerCode", width: 120 },
        { headerName: "Location", field: "City", width: 120 },
        { headerName: "State", field: "State", width: 120 },
        { headerName: "Go Live Date", field: "Go Live Date", width: 120 },
        { headerName: "ASM Name", field: "ASM Name", width: 120 },
        { headerName: "RM Name", field: "RSM Name", width: 120 },
        { headerName: "ZM Name", field: "ZSM Name", width: 120 },
        { headerName: "National Head", field: "National Head", width: 120 },
        { headerName: "Total Stock", field: "Stock_Count", width: 120 },
        {
          headerName: "Total Enquiries",
          field: "TotalEnquiry_Count",
          width: 120,
        },
        {
          headerName: "Open Enquiries",
          field: "OpenEnquiry_Count",
          width: 120,
        },
        {
          headerName: "Closed Enquiries",
          field: "ClosedEnquiry_Count",
          width: 120,
        },
        {
          headerName: "Last Update of Enquiries",
          field: "EnquiryLastUpdateDate",
          width: 120,
        },
        {
          headerName: "Pending Money Reciept",
          field: "Booking_Count",
          width: 120,
        },
        {
          headerName: "Last Update of Money Reciept",
          field: "MoneyReceiptLastUpdateDate",
          width: 120,
        },
        { headerName: "Total Invoice", field: "Invoice_Count", width: 120 },
        {
          headerName: "Last Update of Invoice",
          field: "SalesInvoiceLastUpdateDate",
          width: 120,
        },
        // { headerName: "PO", field: "PO_Count", width: 120 },
        // { headerName: "Job Card", field: "Jobcard_Count", width: 120 },
        { headerName: "Last Login", field: "LastLoginDateTime", width: 120 },
      ],
      rowData: [],
      userRowData: [],
      gridOptions: {
        getRowStyle: this.getRowStyle,
      },
      showDealer: true,
      startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
  }
  componentDidMount() {
    this.getUserTableData();
    this.MasterAPICall();
  }
  MasterAPICall = (data) => {
    const url = Urls.python_baseurl + "/SP_Report_ActiveDealersDetails";
    var data = JSON.stringify({
      StartDate: `'${this.state.startDate}'`,
      EndDate: `'${this.state.endDate}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          const isNullish = Object.values(res[0]).every((value) => {
            if (value === null || value === "") {
              return true;
            }
            return false;
          });
          if (isNullish) {
            this.setState({
              rowData: [],
            });
          } else {
            this.setState({
              rowData: res,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getUserTableData = (data) => {
    const url = Urls.python_baseurl + "/SP_Report_UsersOtherthanDealers";
    var data = JSON.stringify({});
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          const isNullish = Object.values(res[0]).every((value) => {
            if (value === null || value === "") {
              return true;
            }
            return false;
          });
          if (isNullish) {
            this.setState({
              rowData: [],
            });
          } else {
            this.setState({
              userRowData: res,
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  masterTable() {
    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={this.state.columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            rowData={this.state.rowData}
            gridOptions={this.state.gridOptions}
            enableCellTextSelection={true}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  showUserTable() {
    let columnDefs = [
      { headerName: "First Name", field: "FirstName", flex: 1 },
      { headerName: "Last Name", field: "LastName", flex: 1 },
      { headerName: "Login Name", field: "LoginName", flex: 1 },
      { headerName: "Last Login ", field: "LastLoginDateTime", flex: 1 },
    ];
    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            rowData={this.state.userRowData}
            gridOptions={this.state.gridOptions}
            enableCellTextSelection={true}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  refreshScreen = (startDate, endDate, dealerid) => {
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        dealerid: dealerid,
      },
      () => {
        this.MasterAPICall();
      }
    );
  };
  handelExport = () => {
    let TableName = [];
    let exportMapper = [];
    if (this.state.showDealer) {
      TableName = ["Dealer Usages Report"];
      exportMapper = [
        [
          { label: "Dealer Name", key: "Dealer_Name" },
          { label: "D Code", key: "DealerCode" },
          
          { label: "Location", key: "City" },
          { label: "State", key: "State" },
          { label: "Go Live Date", key: "Go Live Date" },
          { label: "Last Login", key: "LastLoginDateTime" },
          { label: "ASM Name", key: "ASM Name" },
          { label: "ASM Login Name", key: "ASM Login Name" },
          { label: "Last Login", key: " ASM Last Login Date Time" },
          { label: "RM Name", key: "RSM Name" },
          { label: "RM Login Name", key: "RSM Login Name" },
          { label: "Last Login", key: "RSM Last Login Date Time" },
          { label: "ZM Name", key: "ZSM Name" },
          { label: "ZM Login Name", key: "ZSM Login Name" },
          { label: "Last Login", key: "ZSM Last Login Date Time" },
          { label: "National Head", key: "National Head" },
          { label: "National Head Login Name", key: "National Head Login Name" },
          { label: "Last Login", key: "National Head Login Date time" },
          { label: "Total Stock", key: "Stock_Count" },
          {
            label: "Total Enquiries",
            key: "TotalEnquiry_Count",
            width: 120,
          },
          {
            label: "Open Enquiries",
            key: "OpenEnquiry_Count",
            width: 120,
          },
          {
            label: "Closed Enquiries",
            key: "ClosedEnquiry_Count",
            width: 120,
          },
          {
            label: "Last Update of Enquiries",
            key: "EnquiryLastUpdateDate",
            width: 120,
          },
          {
            label: "Pending Money Reciept",
            key: "Booking_Count",
            width: 120,
          },
          {
            label: "Last Update of Money Reciept",
            key: "MoneyReceiptLastUpdateDate",
            width: 120,
          },
          {
            label: "Total Invoice",
            key: "Invoice_Count",
            width: 120,
          },
          {
            label: "Last Update of Invoice",
            key: "SalesInvoiceLastUpdateDate",
            width: 120,
          },
          // { label: "PO", key: "PO_Count" },
          // { label: "Job Card", key: "Jobcard_Count" },
         
        ],
      ];
    } else {
      TableName = ["User Usages Report"];
      exportMapper = [
        [
          { label: "First Name", key: "FirstName" },
          { label: "Last Name", key: "LastName" },
          { label: "Login Name", key: "LoginName" },
          { label: "Last Login", key: "LastLoginDateTime" },
        ],
      ];
    }

    const XLSX = require("xlsx");

    const workbook = XLSX.utils.book_new();

    const currentExportMapper = exportMapper[0];

    if (!currentExportMapper) {
      console.error(`exportMapper for sheet ${TableName[0]} is undefined`);
      return;
    }

    const columnHeaders = currentExportMapper.map((item) => item.label);
    let dataWithHeaders = [];
    if (this.state.showDealer) {
      dataWithHeaders = [
        columnHeaders,
        ...this.state.rowData.map((row) =>
          currentExportMapper.map((mapping) => row[mapping.key])
        ),
      ];
    } else {
      dataWithHeaders = [
        columnHeaders,
        ...this.state.userRowData.map((row) =>
          currentExportMapper.map((mapping) => row[mapping.key])
        ),
      ];
    }

    const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders);

    XLSX.utils.book_append_sheet(workbook, ws, TableName[0]);
    let name = this.state.showDealer
      ? "Dealer Usages Report"
      : "User Usages Report";
    XLSX.writeFile(workbook, name + ".xlsx");
    const headerStyle = {
      fill: { patternType: "solid", fgColor: { rgb: "FFFF00" } },
    };
  };
  render() {
    return (
      <div>
        <h4 className="heading_text mb-3" style={{ marginLeft: "0px" }}>
          DMS Usage - Report Card
        </h4>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class={`nav-link ${this.state.showDealer && "active"}`}
              aria-current="page"
              onClick={() => {
                this.setState((prev) => {
                  return {
                    ...prev,
                    showDealer: !this.state.showDealer,
                  };
                });
              }}
              href="#"
            >
              Dealers
            </a>
          </li>
          <li class="nav-item">
            <a
              class={`nav-link ${!this.state.showDealer && "active"}`}
              href="#"
              onClick={() => {
                this.setState((prev) => {
                  return {
                    ...prev,
                    showDealer: !this.state.showDealer,
                  };
                });
              }}
            >
              Users
            </a>
          </li>
        </ul>
        <div className=" mt-3">
          {this.state.showDealer ? (
            <div>
              <ListFilter
                refreshScreen={this.refreshScreen}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                dealerid={this.state.dealerid}
                showtimerange={true}
              />
              <div className=" row justify-content-end ">
                <button
                  onClick={() => {
                    this.handelExport();
                  }}
                  style={{ marginTop: "-20px" }}
                  className="btn Opertion_btn clickable mr-3 mt-3"
                >
                  Export
                </button>
              </div>
              {this.masterTable()}
            </div>
          ) : (
            <div>
              {" "}
              <div className=" row justify-content-end ">
                <button
                  onClick={() => {
                    this.handelExport();
                  }}
                  style={{ marginTop: "-20px" }}
                  className="btn Opertion_btn clickable mr-3 mt-3"
                >
                  Export
                </button>
              </div>
              {this.showUserTable()}
            </div>
          )}
        </div>
      </div>
    );
  }
}
