import React from 'react'
import "../../Files/files-styles.css"
// import '../Claims/Dashboard/styles.css'
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Urls from '../../../../helpers/Urls';
import { AgGridReact } from 'ag-grid-react';
import Strings from '../../../../helpers/Strings';
// import { post } from 'axios';
const $ = window.$;
export class Enquiry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paginationPageSize: 10,
            overlayLoadingTemplate: '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
            domLayout: 'autoHeight',
            autoGroupColumnDef: {
                headerName: 'Group',
                minWidth: 300,
                field: 'athlete',
                valueGetter: function (params) {
                    if (params.node.group) {
                        return params.node.key;
                    } else {
                        return params.data[params.colDef.field];
                    }
                },
                headerCheckboxSelection: true,
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: { checkbox: true },
            },
            defaultColDef: {
                cellClass: 'cell-wrap-text',
                autoHeight: true,
                sortable: true,
                resizable: true,
                filter: true,
            },
            rowSelection: 'never',
            rowGroupPanelShow: 'never',
            pivotPanelShow: 'never',
            ethnicity_map: [],
            Ethnicity: [],
            Input_Code: "",
            Text: "",
            Output_FHIR_Code: "",
            Code_System: "",
            Display: "",
            Definition: "",
            Type: "",

            HiPaaS_ID: "",
            loaderforupload: false,
             Ethnicity:[{
                "Prospet":"Wipra Test",
                "Mobile":"8888876601",
                "Vehicle":"Apache",
                "Type":"Finance",
                "Allocate":"Wipra",
                "EnqNo":"0000069",
                "EnqOn":"02/01/2024",
                "NxFoll":"02/01/2024",
                "BKNo":"",
                "ClReason":""
    
    
            }]
        }
    }

    MasterAPICall = () => {
        const url = Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";
        var data = JSON.stringify({
          "GetRecordFor":'', 
          "sDealerIds":'', 
          "UserRole":'',  
    
        });
        fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res && res.length > 0) {
              this.setState({
                rowData: res,
                // Active: res[0].Active,
                // Model_Category: res[0]["Model Category"],
              })
            }
    
          })
          .catch(err => {
            process.env.NODE_ENV == 'development' && console.log(err)
          });
      }

    onGridReady = (params) => {
        this.gridApi = params.api
        this.MasterAPICall()
    }

   

    masterTable() {
        let columnDefs = [
          { headerName: "Employee Name", field: "Model Category", flex: "1", cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" } },
          { headerName: "Contact No.", field: "Model Category", flex: "1",  },
          { headerName: "Employee Type", field: "Model Category", flex: "1",  },
          { headerName: "Dealer Name", field: "Model Category", flex: "1",  },
          { headerName: "Active", field: "Active", flex: "1" },
        ];
    
        return (
          <div>
            <div className="ag-theme-balham" style={{ padding: "0" }}>
              <AgGridReact
                modules={this.state.modules}
                columnDefs={columnDefs}
                autoGroupColumnDef={this.state.autoGroupColumnDef}
                defaultColDef={this.state.defaultColDef}
                suppressRowClickSelection={true}
                groupSelectsChildren={true}
                debug={true}
                rowSelection={this.state.rowSelection}
                rowGroupPanelShow={this.state.rowGroupPanelShow}
                pivotPanelShow={this.state.pivotPanelShow}
                enableRangeSelection={true}
                paginationAutoPageSize={false}
                pagination={true}
                domLayout={this.state.domLayout}
                paginationPageSize={this.state.paginationPageSize}
                onGridReady={this.onGridReady}
                rowData={this.state.rowData}
                enableCellTextSelection={true}
                onCellClicked={(event) =>
                  this.setState({
                    Active: event.data.Active,
                    Model_Category: event.data["Model Category"],
                  })
                }
              ></AgGridReact>
            </div>
          </div>
        );
      }
    validation_check() {
        let formIsValid = true;
        if (this.state.Input_Code == "") {
            formIsValid = false;
            alert("Please Enter the Input Code.");
        } else if (this.state.Text == "") {
            formIsValid = false;
            alert("Please Enter the Description.");
        } else if (this.state.Output_FHIR_Code == "") {
            formIsValid = false;
            alert("Please Enter the Output FHIR Code.");
        }
        else if (this.state.Display == "") {
            formIsValid = false;
            alert("Please Enter the Display.");

        }
        else if (this.state.Type == "") {
            formIsValid = false;
            alert("Please Enter the Transaction Type .");

        }
        else if (this.state.Code_System == "") {
            formIsValid = false;
            alert("Please Enter the Code System.");
        }
        // else if (this.state.Definition == "") {
        //     formIsValid = false;
        //     alert("Please Enter the Definition.");
        // }
        return formIsValid;
    }



    EthnicitySave = () => {
        var check = this.validation_check()
        if (check) {
            this.setState({ loaderforupload: true })
            let query = new URL(Urls.File_upload + "/Save_Ethnicity_data")
            query.search = new URLSearchParams({
                HiPaaS_ID: this.state.HiPaaS_ID ? this.state.HiPaaS_ID : "",
                Input_Code: this.state.Input_Code ? this.state.Input_Code : "",
                Text: this.state.Text ? this.state.Text : "",
                Output_FHIR_Code: this.state.Output_FHIR_Code ? this.state.Output_FHIR_Code : "",
                Code_System: this.state.Code_System ? this.state.Code_System : "",
                Display: this.state.Display ? this.state.Display : "",
                Type: this.state.Type ? this.state.Type : "",
                Definition: this.state.Definition ? this.state.Definition : "",
                customer_id: sessionStorage.getItem("customer_id"),
            })
            if (Strings.isDev) { process.env.NODE_ENV == 'development' && console.log(query.href) }
            fetch(query.href, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // 'user-id': sessionStorage.getItem('user-id'),
                    'Cache-Control': 'no-cache, no-store',
                    'Expires': 0,
                    'Pragma': 'no-cache',
                    'Accept': 'application/json',
                },
                // body: JSON.stringify({ query: query })
            })
                .then(res => res.json())
                .then(res => {
                    // console.log("res",res.message)
                    alert(res.message)

                })
                .catch(err => {
                    process.env.NODE_ENV == 'development' && console.log(err)
                }).finally(() => {
                    this.setState({
                        loaderforupload: false, HiPaaS_ID: "", Input_Code: "", Text: "", Output_FHIR_Code: "",
                        Code_System: "", Display: "", Type: "", Definition: ""
                    })
                    this.getEthnicity()
                    // window.location.reload()
                })
        }
    }
    exportData = () => {
        this.gridApi.exportDataAsCsv();
    };
    onFileUpload = (e) => { 
        this.setState({CSVloader: true})
        const exampleFileReader = new FileReader()
        let FileName = this.state.selectedFile.name;
        exampleFileReader.onload = async (e) => { 
         let text = (e.target.result)
          this.setState({
              EDIFileResult:text
          },()=>{
            //   console.log(this.state.EDIFileResult)
            this.EDI_File_upload(FileName,this.state.EDIFileResult)
          })
        };
        exampleFileReader.readAsText(this.state.selectedFile)
    }

    EDI_File_upload = (FileName,EDIFileResult) => {
    
        const url = Urls.python_baseurl +"/load_csv_to_sql";
        var data = JSON.stringify({
            "data": EDIFileResult,
            "path": "/app/files",
            "filename": FileName,
            "seprator": ",",
            "action": "Insert",
            "tablename": "Ethnicity",
            "transaction_type": "",
            "file_Name_check": false,
            "have_header": true,
            "count": false,
            "filedetails_create": false
        })
        // console.log("Python data", data)
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            },
            method: 'POST',
            body: data,
        }

        ).then(res => res.json())
            .then(res => {
                console.log("res",res)
                var msg=res.Message;
                alert(msg)
                this.getEthnicity()
            
            })
            .catch(err => {
                console.log(err)
            }).finally((r)=>{               
                this.setState({
                    CSVloader: false
                })},()=>{
                    // window.location.reload()
                })

    }
    clickNavigation = (event) => {
        console.log("event.data",this.state.Ethnicity);
        this.props.history.push('/Lead', {
            data: this.state.Ethnicity
           })

        // this.setState({
        //     clickedError: 'true',
        //     row: [],
        //     json_List: [],
        //     answers_mapname: []
        // }, () => {
        //     $('#payment_error_modal').modal('show')
        // })

    }
    errorDialog = () => {
        return (
            <div className="modal" id="payment_error_modal" role="dialog" aria-labelledby="myModalLabel2" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog-view">
                    <div className="error-dialog">
                        <button type="button" class="close close-btn" data-dismiss="modal" aria-label="Close" onClick={() => {
                            this.close()
                        }}><span class="" aria-hidden="true">&times;</span></button>
                        <div id="exTab1">
                            <ul class="nav nav-pills">
                                <li class="active">
                                    <a href="#1a" style={{padding:'8px',fontSize:'13px'}} data-toggle="tab">Add Ethnicity</a>
                                </li>
                                <li><a href="#2a" style={{padding:'8px',fontSize:'13px'}} data-toggle="tab">CSV Upload</a>
                                </li>
                              
                            </ul>

                            <div class="tab-content clearfix">
                                <div class="tab-pane active" id="1a">
                                   {this.EthnicityHeaderview()}
                                </div>
                                <div class="tab-pane" id="2a">
                                    <div class="container mt-5">
                                        <div class="col-sm-12 col-lg-4 mr-auto ml-auto border p-4">
                                            <form method="post" enctype="multipart/form-data">
                                                <div class="form-group">
                                                    <label><strong style={{color:'#8d8f90'}}>Upload Files</strong></label>
                                                    <div class="custom-file">
                                                        <input type="file" name="files[]" multiple class="custom-file-input form-control" id="customFile" />
                                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <button type="button" name="upload" value="upload" id="upload" style={{backgroundColor:"var(--main-bg-color)"}} class="btn btn-block btn-dark"><i class="fa fa-fw fa-upload"></i> Upload</button>
                                                </div>
                                            </form>
                                        </div>
                                </div>
                               
                            </div>
                        </div>
                     
                    </div>
                </div>
                </div>
                </div>
        )
    }
    close() {
        this.setState({
            HiPaas_ID: "", Input_Code: "",Text: "",
            Output_FHIR_Code: "", Display: "", Code_System: "",
            transaction_type: ""
        }, () => {
            $('#payment_error_modal').modal('hide')
        })
    }
    EthnicityHeaderview() {
        return (
            <div>
                {/* <h5 style={{ fontWeight: "300", color: "#505656", fontSize: "18px", marginBottom: '0px' }}>Ethnicity</h5> */}
                <div className="" style={{ marginTop: "15px" }}>
                    <div class="form-row">
                        <br></br>
                        <div class="form-group col-md-3">
                            <label>Input Code</label>
                            <input value={this.state.Input_Code == "NULL" ? '' : this.state.Input_Code} onChange={(e) => this.setState({ Input_Code: e.target.value })} class="form-control" placeholder=""></input>
                        </div>

                        <div class="form-group col-md-3">
                            <label>Description</label>
                            <input value={this.state.Text == "NULL" ? '' : this.state.Text} onChange={(e) => this.setState({ Text: e.target.value })} class="form-control" placeholder=""></input>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Output FHIR Code</label>
                            <input value={this.state.Output_FHIR_Code == "NULL" ? '' : this.state.Output_FHIR_Code} onChange={(e) => this.setState({ Output_FHIR_Code: e.target.value })} class="form-control" placeholder=""></input>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Display</label>
                            <input value={this.state.Display == "NULL" ? '' : this.state.Display} onChange={(e) => this.setState({ Display: e.target.value })} class="form-control" placeholder=""></input>
                        </div>
                        {/* <div class="form-group col-md-3">
                            <label>Definition</label>
                            <input value={this.state.Definition == "NULL" ? '' : this.state.Definition} onChange={(e) => this.setState({ Definition: e.target.value })} class="form-control" placeholder=""></input>
                        </div> */}
                        <div class="form-group col-md-3">
                            <label>Code System</label>
                            <input value={this.state.Code_System == "NULL" ? '' : this.state.Code_System} onChange={(e) => this.setState({ Code_System: e.target.value })} class="form-control" placeholder=""></input>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Transaction Type </label>
                            <input value={this.state.Type == "NULL" ? '' : this.state.Type} onChange={(e) => this.setState({ Type: e.target.value })} class="form-control" placeholder=""></input>
                        </div>
                        <div class="form-group col-md-3">
                            <button type="button" onClick={this.EthnicitySave} className="btn btn-display" style={{marginTop: "25px" }} >
                                {this.state.loaderforupload && <span class="spinner-grow spinner-grow-sm" style={{ color: 'unset', marginRight: '5px' }} role="status" aria-hidden="true"></span>}
                                Save
                            </button>
                        </div>
                        {/* <div className="row">
                            <div className='col-2'>
                                <input key={this.state.theInputKey || ""} type="file" style={{ marginLeft: "5px" }} onChange={(e) => this.setState({ selectedFile: e.target.files[0] })} />
                            </div>
                            <div className='col-2'>
                                <button style={{ marginBottom: "0px" }} class="btn light_blue1" onClick={this.onFileUpload}>
                                {this.state.CSVloader && <span class="spinner-grow spinner-grow-sm" style={{ color: 'unset', marginRight: '5px' }} role="status" aria-hidden="true"></span>}
                                    Upload  CSV
                                </button>
                            </div>
                            <div className='col-8'>
                                <button type="submit" className="btn light_blue1 clickable" style={{ fontSize: "12px", float: "right", marginLeft: "855px",marginRight:"5px" }} onClick={this.exportData}>{this.state.exportbtnloader && <span class="spinner-grow spinner-grow-sm" style={{ color: 'unset', marginRight: '5px' }} role="status" aria-hidden="true"></span>}
                                    Export Data
                                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <h4 className="heading_text">Enquiry</h4>
                {/* {this.EthnicityHeaderview()} */}
                <div class="form-group col-md-12" style={{padding:'0px'}}>
                    <button type="submit" className="btn light_blue1 clickable" style={{ fontSize: "12px", float:'right',marginRight:"30px" }} onClick={this.clickNavigation}>
                        Add Enquiry
                    </button>
                    </div> 
                <div  class="form-group col-md-12" style={{ marginTop: "45px",marginRight:"15px" }}>
                {this.renderEnquiryTable()}
                {/* {this.errorDialog()} */}
                </div>
                
            </div>
        );
    }
}
