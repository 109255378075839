import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";

const bcrypt = require("bcryptjs");

export class Dealer_to_Dealer_purches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 100,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showFieldOnAddNew: true,
      Model_Category_Option: [],
      Model_Code_Option: [],
      Model_Option: [],
      Invoice_Option: [],
      Model_Id: "",
      ModelRowData: [],
      Supplier_List:[],
      idforconfirm: 0,
      Receipt_Date: moment(new Date()).format("DD-MM-YYYY"),
      status: "Open",
      
      isSelectTag: true,
      flag: "insert",
      tax_raw:[],
      model_details:[],
      MAinHdr:0,
      Gross_amount:0,
      Dealer_vehicle_Code:"",
      Gross_Amt:0,
      double_save:false,
      Invoice_No:'',
      Supplier_name:"0",
      Delivery_No:'',
      VehConditionID:1,
      fin_year:sessionStorage.getItem("fin_year"),
      Dealer_Code:sessionStorage.getItem("Dealer_Code"),
      supplier_dealer_id:0,
      Supplier_id:0
    };
  }
  componentDidMount() {
    this.MasterAPICall();
    // this.GetSupplierList();
    // this.GetInvoiceList();
    this.GetModel();

  }
  SaveVehicle_Receipt_No = () => {
    this.setState({handelSave:true})
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "DDP",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Vehicle_Receipt_NO:
              this.state.Dealer_Code +
              "" +
              "DDP" +
              "" +
              this.state.fin_year +
              "" +
              res[0].DocMaxNo.toString().padStart(6, '0')
            },
            () => {
              this.handelSave("N")
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Vehicle_Receipt_No = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "DDP",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // veh_num: res[0].DocMaxNo,
              Vehicle_Receipt_NO:
              this.state.Dealer_Code +
              "" +
              "DDP" +
              "" +
              this.state.fin_year +
              "" +
              res[0].DocMaxNo.toString().padStart(6, '0')
            },
            () => {
              // this.financiaryear_Vehicle_Receipt();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  // financiaryear_Vehicle_Receipt = () => {
  //   const url = Urls.python_baseurl + "/SP_GetDataFromDataQuery";
  //   var data = JSON.stringify({
  //     ValGetDataQueryType: "15",
  //     iParentId: sessionStorage.getItem("dealerid"),
  //     sAdditionalCondition: "''",
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState(
  //           {
  //             finacial_year_no: res[0].Value,
  //           },
  //           () => {
  //             this.delarcode_Vehicle_Receipt();
  //           }
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  // delarcode_Vehicle_Receipt = () => {
  //   const url = Urls.python_baseurl + "/Get_Dealer_SP_GET_Dealercode";
  //   var data = JSON.stringify({
  //     DealerID: sessionStorage.getItem("dealerid"),
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res) {
  //         this.setState({
  //           Dealer_vehicle_Code:res[0].Dealer_vehicle_Code,
  //           Vehicle_Receipt_NO:
  //             res[0].Dealer_vehicle_Code +
  //             "" +
  //             "VR" +
  //             "" +
  //             this.state.finacial_year_no +
  //             "" +
  //             this.state.veh_num.toString().padStart(6, '0'),
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  MasterAPICall = () => {
    const url =
      Urls.python_baseurl + "/SP_GetDealerPurchaseVehReceiptDetails";
    var data = JSON.stringify({
        Dealer_ID: `${sessionStorage.getItem("dealerid")}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res, "hjhjh");
          this.setState({
            rowData: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCode = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 198,
      ID: 0,
      condition: "'and Model_cat_ID=1002'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Code_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 94,
      ID: 0,
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Category_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModel = () => {
 
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 197,
      ID: 0,
      condition: "'and Model_cat_ID=1002'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetInvoiceList = (delarID) => {
    const url = Urls.python_baseurl + "/SP_GetDealerPurchaseInvNo";
    var data = JSON.stringify({
      purchase_Id :sessionStorage.getItem("dealerid"),
      Dealer_ID:sessionStorage.getItem("dealerid")
        // sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length> 0&&res[0][0]?.Tr_no!==null ) {
          this.setState({
            Invoice_Option: res[0],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetSupplierList = () => {
    const url = Urls.python_baseurl + "/SP_Get_Supplierlist";
    var data = JSON.stringify({
      DealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Supplier_List: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getModelCode = async (model_category_id) => {
    const url =
      Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
    var data = JSON.stringify({
      ModelGroup: parseInt(model_category_id),
    });
    let res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    });
    res = await res.json();
    if (res && res.length > 0) {
      console.log(res, "bhjhjbhjb");
      return res;
    }
  };
  getModelNameAndRate = (model_code_id, id) => {
    const url = Urls.python_baseurl + "/getModelMrp_GetModdelMRP";
    var data = JSON.stringify({
      Dealer: sessionStorage.getItem("dealerid"),
      ModelID: parseInt(model_code_id),
      StateCode: "20",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("rtes", res[1][0]);
        if (res && res.length > 0) {
          this.setState(
            {
              ["Model_Price" + id]: res[0][0].MRP,
              ["Model" + id]: res[1][0]["Model name"],
              ["Model_Colour" + id]: res[1][0].Model_Colour,
            },
            () => { }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetVehgicleBillingDetails = async () => {
   
    const url =
      Urls.python_baseurl + "/SP_GetDealerPurchaseVehReceipt";
      var data = JSON.stringify({
          "Tr_no": "''",
          "POType": "'ALL'",
          "DealerId": `${sessionStorage.getItem("dealerid")}`,
          "HOBrID": `${sessionStorage.getItem("dealerid")}`,
          "Hdr_ID": `${this.state.MAinHdr}`
      });
    let res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    });
    res = await res.json();
    if (res && res.length > 0) {
      let arr = [];
      for (let i = 0; i < res[0].length; i++) {
        arr.push(res[0][i]?.Model_GP);
      }
      console.log(res[2], "sdsjkjk");
      // for (let i = 0; i < arr.length; i++) {
      //   let val = await this.getModelCode(arr[i]);
      //   res[0][i]["model_code"] = val?.filter((e) => {
      //     return e.ID === res[0][i].model_ID;
      //   })[0]["Model code"];
      //   // console.log(val, "jbjhhjb");
      //   res[0][i]["model_name"] = val?.filter((e) => {
      //     return e.ID === res[0][i].model_ID;
      //   })[0]["Model name"];
      // }
      this.setState(
        {
          // ModelRowData: res[0],
          // tax_raw:res[1].length>0 ? res[1]:[],
          // model_details:res[0]?res[0].length>0 ? res[0]:[]:[],

          ModelRowData: res[0].length>0 ? res[0]:[],
          tax_raw:res[1].length>0 ? res[1]:[],
          model_details:res[2].length>0 ? res[2]:[],   
          double_save: res[0][0]?.Is_Confirm == "Y" ? true : false,
          isSelectTag: false,
        },
        () => {
          this.modelTable();
          console.log(res[0], "sssssssss");
        }
      );
    }
    if (res && res.length > 0) {
      let sum = 0,
        IGST = 0,
        CGST = 0,
        SGST = 0,
        TCS = 0;
   

      for (let i = 0; i < res[0].length; i++) {
    
        sum += res[0][i].rate ? res[0][i].rate : 0;
        IGST += res[0][i]?.IGST_Amt ? res[0][i]?.IGST_Amt : 0;
        CGST += res[0][i]?.CGST_Amt ? res[0][i]?.CGST_Amt : 0;
        SGST += res[0][i]?.SGST_Amt ? res[0][i]?.SGST_Amt : 0;
        TCS += res[0][i]?.TCS_Amt ? res[0][i]?.TCS_Amt : 0;
      }
     
      console.log(sum, IGST, CGST, SGST, TCS, "popoopp");
      this.setState({
        Supplier_name:res[0][0].Dealer_Name,
        Supplier:res[0][0].Dealer_Name,
        Supplier_id:res[0][0].SupplierID,
        Gross_amount:res[0][0]?.Gross_Amt,
        Receipt_Date: res[0][0]?.ref_date,
        Vehicle_Receipt_NO: res[0][0]?.Tr_no,
        ModelRowData: res[0],
        Invoice_Date: res[0][0]?.inv_date
          ? res[0][0]?.inv_date
          : "",
        Delivery_No: res[0][0]?.LR_No,
        Invoice_No: res[0][0]?.inv_no,
        Vehicle_Condition: res[0][0]?.VehConditionID,
        LR_Date: res[0][0]?.LR_Date
          ? res[0][0]?.LR_Date
          : "",
        TotalSum: sum,
        IGST: res[0][0].IGST_Per ? res[0][0].IGST_Per : 0,
        SGST: res[0][0].SGST_Per ? res[0][0].SGST_Per : 0,
        CGST: res[0][0].CGST_Per ? res[0][0].CGST_Per : 0,
        TCS: res[0][0].TCS_Per ? res[0][0].TCS_Per : 0,
        GrandTotalPer: IGST + CGST + SGST + TCS,
        IGST_Amount: IGST,
        SGST_Amount: SGST,
        CGST_Amount: CGST,
        TCS_Amount: TCS,
        GrandTotal_Amount: (IGST + SGST + CGST + TCS + sum).toFixed(2),
      });
    }
  };
  // GetModelDetails = () => {
  //   const url = Urls.python_baseurl + "/model_master_SP_ModelMaster_details";
  //   var data = JSON.stringify({
  //     model_id: `'${this.state.Model_Id}'`,
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res) {
  //         console.log(res, "mpop");
  //         this.setState({
  //           Model_Category: res[0]?.Model_cat_ID,
  //           Model_Code: this.state.Model_Code_Option.filter(
  //             (e) => e.Name == res[0]?.Model_code
  //           )[0]?.ID,
  //           Model: this.state.Model_Option.filter(
  //             (e) => e.Name == res[0]?.model_name
  //           )[0]?.ID,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };

  save_hsn_Tax = (id) => {
   
    let data1 = this.state.tax_raw.map((e) => {
      return {
        "ID": `'${e.CGST_AMT}'`  ,
        "UserId": `${sessionStorage.getItem("dealerid")}`,
        "Invoice_No": `'${e.Invoice_No}'`  ,
        "HSN_Code": `'${e.HSN_Code}'`  ,
        "TaxableAmount": `'${e.TaxableAmount}'`  ,
        "IGST": `'${e.IGST}'`  ,
        "IGST_AMT": `'${e.IGST_AMT}'`  ,
        "SGST": `'${e.SGST}'`  ,
        "SGST_AMT": `'${e.SGST_AMT}'`  ,
        "CGST": `'${e.CGST}'`  ,
        "CGST_AMT": `'${e.CGST_AMT}'`  ,
        "Receipt_id":'0'   ,
        "Receipt_Hdr_ID":`${id}`   
      };
    });
 
 
    const url = Urls.python_baseurl + "/SP_Save_dealerVehReceipt_HSNWise_TaxDetails";
    var data = JSON.stringify(data1);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          alert("Record Saved Successfully.");
          //  window.location.reload();
          if (res[0].ID) {
            // this.Investigation_save()
            // this.Attachment_save()
             
            // this.setState({
            //   saveLoader:false
            // },()=>{
             
            // })
            // window.location.reload()
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        // window.location.reload();
      });
  };

  
  save_model = (id) => {
   
    let data1 = this.state.model_details.map((e) => {
      return {
        "ID": '0',
        "UserId": `${sessionStorage.getItem("dealerid")}`,
        "Invoice_No":`'${this.state.Invoice_No}'`,
        "HSN_Code": `'${e.HSN_Code}'`,
        "model_id": `${e.model_ID}`,
        "qty": `${e.Qty}`,
        "rate": `${e.rate}`,
        "total": `${e.TotalAmt}`  ,
        "Receipt_id":'0'   ,
        "Receipt_Hdr_ID":`${id}`   

      };
    });
 
 
    const url = Urls.python_baseurl + "/SP_Save_dealerVehReceipt_ModelWise";
    var data = JSON.stringify(data1);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            // this.Investigation_save()
            // this.Attachment_save()
             
            // this.setState({
            //   saveLoader:false
            // },()=>{
             
            // })
            // window.location.reload()
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        // window.location.reload();
      });
  };
  handelSave = (status) => {
    console.log("ModelRowData",this.state.ModelRowData);
    this.setState({
      double_save:true,
    })
    let iid = 0;
    let valid = true
    if(this.state.Invoice_No==""){
      alert("Please Enter the Invoice No")
      return
    }
    else if(this.state.Delivery_No=="")
    {
      alert("Please Enter the LR No.")
      return
    }
    else if(this.state.Vehicle_Receipt_NO=="")
    {
      alert("Please Enter the Vehicle_Receipt_NO")
      return
    }
    // for (let i = 0; i < this.state.ModelRowData.length; i++) {
    //   valid = (valid && this.state.ModelRowData[i].VehConditionID && this.state.ModelRowData[i].VehConditionID != "0") ? true : false
    // }
    if (!valid) {
      this.setState({
        double_save:false,
      })
      alert("Please Select Vehicle Condition")
        
      return
    }
    // console.log(valid,"jkjkjk")
    let data1 = this.state.ModelRowData?.map((e) => {

      if (this.state.MAinHdr!=0) {
        iid = e?.ID
      }
      console.log(e?.VehConditionID, "uhhj");
      return {
        iID: `${iid}`,
        UserId: `${sessionStorage.getItem("dealerid")}`,
        HOBrID: `${sessionStorage.getItem("dealerid")}`,
        Tr_no: `'${this.state.Vehicle_Receipt_NO}'`,
        ref_date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
        inv_no: `'${this.state.Invoice_No}'`,
        inv_date:this.state.Invoice_Date!=null? `'${this.state.Invoice_Date}'`:"''",
      
        LR_No: `'${this.state.Delivery_No}'`,
        PO_No: `''`,
        PO_date: "''",
        inv_amt: `'${e?.rate}'`,
        chassis_no: `'${e?.chassis_no}'`,
        // engine_no: "''",
        model_ID: `${e?.model_ID}`,
        Model_GP: `${e?.Model_GP}`,
        rate: `'${e?.rate}'`,
        Qty: `'${e?.Qty}'`,
        TotalAmt: `'${e?.TotalAmt}'`,
        Discount: `'${e?.Discount}'`,
        // CST_TaxID: "0",
        // CST_Amt: "''",
        // VAT_TaxID: "0",
        // VAT_Amt: "''",
        // Add1_TaxID: "0",
        // VATSURCHAMT: "''",
        // Add2_TaxID: "0",
        // ADDVATAMT: "''",
        TCS_Per: `'${e?.TCS_Per ? e.TCS_Per : 0}'`,
        TCS_Amt: `'${e?.TCS_Amt ? e?.TCS_Amt : 0}'`,
        ParkingLocation: "''",
        VehConditionID: `${e?.VehConditionID}`,
        Is_Confirm:  "'N'",
        Is_Cancel: "'N'",
        // PF: "0",
        // other: "0",
        Modelcat: "0",
        vehGST: "''",
        Vehicle_No: `'${e?.Vehicle_No}'`,
        BatteryNo: `'${e?.BatteryNo}'`,
        ChargerNo: `'${e?.ChargerNo}'`,
        Controller_Unit_No: `'${e?.Controller_Unit_No}'`,
        Motor_No: `'${e?.Motor_No}'`,
        DispayNo: `'${e?.DispayNo}'`,
        IOT_Device_ID: `'${e?.IOT_Device_ID}'`,
        DC_No:  `'${e?.DC_no}'`,
        Sales_Order_No: `'${e?.SAles_Order_No ? e?.SAles_Order_No : ""}'`,
        DC_Date: `'${e?.DC_Date}'`,
        SGST_Amt: `'${e?.SGST_Amt ? e?.SGST_Amt : 0}'`,
        SGST_Per: `'${e?.SGST_Per ? e?.SGST_Per : 0}'`,
        CGST_Amt: `'${e?.CGST_Amt ? e?.CGST_Amt : 0}'`,
        CGST_Per: `'${e?.CGST_Per ? e?.CGST_Per : 0}'`,
        IGST_Amt: `'${e?.IGST_Amt ? e?.IGST_Amt : 0}'`,
        IGST_Per: `'${e?.IGST_Per ? e?.IGST_Per : 0}'`,
        LR_Date: e.LR_Date!=null?`'${e?.LR_Date}'`:"''",
        is_Stock:`'${e?.is_stock}'`,
        MAinHdr: `${this.state.MAinHdr}`,
        Gross_Amt:`${this.state.Gross_amount}`,
        DlrCode: `'${this.state.Dealer_Code}'`,
        FinYear: `'${sessionStorage.getItem("fin_year")}'`,
        SupplierID:`"${parseInt(this.state.Supplier_id)?parseInt(this.state.Supplier_id):"0"}"`,
      };
    });
    
    let data2 = this.state.model_details.map((e) => {
      let ID=0
      if (this.state.MAinHdr!=0) {
        ID = e?.ID
      }
    
      return {
        "ID":  `${ID}`,
        "UserId": `${sessionStorage.getItem("dealerid")}`,
        "Invoice_No":`'${this.state.Invoice_No}'`,
        "HSN_Code": `'${e.HSN_Code}'`,
        "model_id": `${e.model_ID}`,
        "qty": `${e.Qty}`,
        "rate": `${e.rate}`,
        "total": `${e.TotalAmt}`  ,
        "Receipt_id":'0'   ,
        "Receipt_Hdr_ID":'0'   

      };
    });
    let data3 = this.state.tax_raw.map((e) => {
      let ID=0
      if (this.state.MAinHdr!=0) {
        ID = e?.ID
      }
   
      return {
        "ID":  `${ID}`,
        "UserId": `${sessionStorage.getItem("dealerid")}`,
        "Invoice_No": `'${e.Invoice_No}'`  ,
        "HSN_Code": `'${e.HSN_Code}'`  ,
        "TaxableAmount": `'${e.TaxableAmount}'`  ,
        "IGST": `'${e.IGST}'`  ,
        "IGST_AMT": `'${e.IGST_AMT}'`  ,
        "SGST": `'${e.SGST}'`  ,
        "SGST_AMT": `'${e.SGST_AMT}'`  ,
        "CGST": `'${e.CGST}'`  ,
        "CGST_AMT": `'${e.CGST_AMT}'`  ,
        "Receipt_id":'0'   ,
        "Receipt_Hdr_ID":'0',
        "TotalAmt_WithTax":`'${e.TotalAmt_WithTax}'`  ,
        "TotalTaxAmt":`'${e.TotalTaxAmt}'`  ,

      };
    });
 
 

    let combineddata=[data1,data2,data3]
    console.log(combineddata, "vhj");
    const url =
     Urls.python_baseurl + "/Save_Full_DealerPurchaseReceipt";
    var data = JSON.stringify(combineddata);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (this.state.MAinHdr == 0) {
          this.setState(
            {
              MAinHdr: res[0][0].ID,

            },()=>{
              alert("Record Saved Successfully.");
              this.GetVehgicleBillingDetails();
              this.MasterAPICall()
            })
        }
        else {
          this.setState(
            {
              MAinHdr: res[0][0].ID,
              double_save: false
            },
            () => {
              this.GetVehgicleBillingDetails();
              this.MasterAPICall()
            })
          alert("Record Updated Successfully.");
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => { });
  };

  handelconfirm = (status) => {
    this.setState({
      double_save:true,
    })
    if(!this.state.Vehicle_Receipt_NO){
      alert("Please Click on Add New First To Generate Receipt No.")
      return
    }
    else if(this.state.Invoice_No==""){
      alert("Please Enter the Invoice No")
      return
    }
    else if(this.state.Delivery_No=="")
    {
      alert("Please Enter the LR No.")
      return
    }
    else if(this.state.Vehicle_Receipt_NO=="")
    {
      alert("Please Enter the Vehicle_Receipt_NO")
      return
    }
    let iid = 0;
    let valid = true
    // for (let i = 0; i < this.state.ModelRowData.length; i++) {
    //   valid = (valid && this.state.ModelRowData[i].VehConditionID && this.state.ModelRowData[i].VehConditionID != "0") ? true : false
    // }
    if (!valid) {
      this.setState({
        double_save:false,
      })
      alert("Please Select Vehicle Condition")
        
      return
    }
    // console.log(valid,"jkjkjk")
    let data1 = this.state.ModelRowData?.map((e) => {
      if (this.state.MAinHdr != 0) {
        iid = e?.ID
      }
      console.log(e, "uhhj");
      return {
        iID: `${iid}`,
        UserId: `${sessionStorage.getItem("dealerid")}`,
        HOBrID: `${sessionStorage.getItem("dealerid")}`,
        Tr_no: `'${this.state.Vehicle_Receipt_NO}'`,
        ref_date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
        inv_no: `'${this.state.Invoice_No}'`,
        inv_date: `'${this.state.Invoice_Date}'`,
        LR_No: `'${this.state.Delivery_No}'`,
        PO_No: `''`,
        PO_date: "''",
        inv_amt: `'${e?.rate}'`,
        chassis_no: `'${e?.chassis_no}'`,
        // engine_no: "''",
        model_ID: `${e?.model_ID}`,
        Model_GP: `${e?.Model_GP}`,
        rate: `'${e?.rate}'`,
        Qty: `'${e?.Qty}'`,
        TotalAmt: `'${e?.TotalAmt}'`,
        Discount: `'${e?.Discount}'`,
        // CST_TaxID: "0",
        // CST_Amt: "''",
        // VAT_TaxID: "0",
        // VAT_Amt: "''",
        // Add1_TaxID: "0",
        // VATSURCHAMT: "''",
        // Add2_TaxID: "0",
        // ADDVATAMT: "''",
        TCS_Per: `'${e?.TCS_Per ? e.TCS_Per : 0}'`,
        TCS_Amt: `'${e?.TCS_Amt ? e?.TCS_Amt : 0}'`,
        ParkingLocation: "''",
        VehConditionID: `${e?.VehConditionID}`,
        Is_Confirm:  "'Y'",
        Is_Cancel: "'N'",
        // PF: "0",
        // other: "0",
        Modelcat: "0",
        vehGST: "''",
        Vehicle_No: `'${e?.Vehicle_No}'`,
        BatteryNo: `'${e?.BatteryNo}'`,
        ChargerNo: `'${e?.ChargerNo}'`,
        Controller_Unit_No: `'${e?.Controller_Unit_No}'`,
        Motor_No: `'${e?.Motor_No}'`,
        DispayNo: `'${e?.DispayNo}'`,
        IOT_Device_ID: `'${e?.IOT_Device_ID}'`,
        DC_No:  `'${e?.DC_no}'`,
        Sales_Order_No: `'${e?.SAles_Order_No ? e?.SAles_Order_No : ""}'`,
        DC_Date: `'${e?.DC_Date}'`,
        SGST_Amt: `'${e?.SGST_Amt ? e?.SGST_Amt : 0}'`,
        SGST_Per: `'${e?.SGST_Per ? e?.SGST_Per : 0}'`,
        CGST_Amt: `'${e?.CGST_Amt ? e?.CGST_Amt : 0}'`,
        CGST_Per: `'${e?.CGST_Per ? e?.CGST_Per : 0}'`,
        IGST_Amt: `'${e?.IGST_Amt ? e?.IGST_Amt : 0}'`,
        IGST_Per: `'${e?.IGST_Per ? e?.IGST_Per : 0}'`,
        LR_Date: `'${e?.LR_Date}'`,
        is_Stock: `'${e?.is_stock}'`,
        MAinHdr: `${this.state.MAinHdr}`,
        Gross_Amt: `${this.state.Gross_amount}`,
        DlrCode: `'${this.state.Dealer_Code}'`,
        FinYear: `'${sessionStorage.getItem("fin_year")}'`,
        SupplierID:parseInt(this.state.Supplier_id)?`${parseInt(this.state.Supplier_id)}`:0,

      };
    });

    let data2 = this.state.model_details.map((e) => {
      let ID = 0
      if (this.state.MAinHdr != 0) {
        ID = e?.ID
      }

      return {
        "ID": `${ID}`,
        "UserId": `${sessionStorage.getItem("dealerid")}`,
        "Invoice_No": `'${this.state.Invoice_No}'`,
        "HSN_Code": `'${e.HSN_Code}'`,
        "model_id": `${e.model_ID}`,
        "qty": `${e.Qty}`,
        "rate": `${e.rate}`,
        "total": `${e.TotalAmt}`,
        "Receipt_id": '0',
        "Receipt_Hdr_ID": '0'

      };
    });
    let data3 = this.state.tax_raw.map((e) => {
      let ID = 0
      if (this.state.MAinHdr != 0)  {
        ID = e?.ID
      }

      return {
        "ID": `${ID}`,
        "UserId": `${sessionStorage.getItem("dealerid")}`,
        "Invoice_No": `'${e.Invoice_No}'`,
        "HSN_Code": `'${e.HSN_Code}'`,
        "TaxableAmount": `'${e.TaxableAmount}'`,
        "IGST": `'${e.IGST}'`,
        "IGST_AMT": `'${e.IGST_AMT}'`,
        "SGST": `'${e.SGST}'`,
        "SGST_AMT": `'${e.SGST_AMT}'`,
        "CGST": `'${e.CGST}'`,
        "CGST_AMT": `'${e.CGST_AMT}'`,
        "Receipt_id": '0',
        "Receipt_Hdr_ID": '0',
        "TotalAmt_WithTax": `'${e.TotalAmt_WithTax}'`,
        "TotalTaxAmt": `'${e.TotalTaxAmt}'`,

      };
    });



    let combineddata = [data1, data2, data3]
    console.log(combineddata, "vhj");
    const url =
      Urls.python_baseurl + "/Save_Full_DealerPurchaseReceipt";
    var data = JSON.stringify(combineddata);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log('ddddd', res)
        this.setState(
          {
            MAinHdr: res[0][0].ID,
          }, () => {
            alert("Record Confirmed Successfully.");
            this.GetVehgicleBillingDetails();
            this.MasterAPICall()
            this.GetInvoiceList();
          })
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => { });
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Receipt No",
        field: "Receipt No",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },

      { headerName: "Receipt Date", field: "Receipt Date", flex: "1" },
      { headerName: "Invoice Number", field: "INV Details", flex: "1" },
      // { headerName: "Chassis No", field: "Chassis No", flex: "1" },
      // {
      //   headerName: "Vehicle Condition",
      //   field: "Vehicle Condition",
      //   flex: "1",
      // },
      { headerName: "Status", field: "Status", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (event.colDef.headerName === "Receipt No" && event.colDef.field==="Receipt No") {
                this.setState(
                  {
                    showFieldOnAddNew: false,
                    status: event.data.Status,
                    idforconfirm: event.data.ID,
                    MAinHdr:event.data.ID,
                    // iid:event.data.ID,
                    flag: "update",
                  },
                  () => {
                    // alert(this.state.iid)
                    this.GetVehgicleBillingDetails();
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  handelDealerNoOnChange = () => {
    const url =
      Urls.python_baseurl + "/SP_GetDealerPurchaseInvallDetails";
    var data = JSON.stringify({
      Dealer_Id: sessionStorage.getItem("dealerid"),
      ID: "0",
      DeliveryNo: `'${this.state.Delivery_No}'`,
      InvNo: `'${this.state.Invoice_No}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if(res[0][0].chassis_no==null )
            {
              
               this.setState(
                {
                  Delivery_No: '',
                },()=>{
                  alert("Please Check LR No & Invoice No")
                })
            
            }
          for (let i = 0; i < res[0].length; i++) {
            res[0][i]["ID"] = i;
          }
          let totalamt=0;
          for (let i = 0; i < res[1].length; i++) {
            totalamt += res[1][i].TotalAmt_WithTax ? res[1][i].TotalAmt_WithTax : 0;
          }
          let sum = 0,
            IGST = 0,
            CGST = 0,
            SGST = 0,
            TCS = 0;
          for (let i = 0; i < res[0].length; i++) {
            sum += res[0][i].rate ? res[0][i].rate : 0;
            IGST += res[0][i]?.IGST_Amt ? res[0][i]?.IGST_Amt : 0;
            CGST += res[0][i]?.CGST_Amt ? res[0][i]?.CGST_Amt : 0;
            SGST += res[0][i]?.SGST_Amt ? res[0][i]?.SGST_Amt : 0;
            TCS += res[0][i]?.TCS_Amt ? res[0][i]?.TCS_Amt : 0;
          }
          console.log(sum, IGST, CGST, SGST, TCS, "popoopp");
          this.setState({
            ModelRowData: res[0],
            tax_raw: res[1],
            model_details:res[2],           
            
            Invoice_Date: res[0][0]?.Invoice_Date,
              // ? moment(res[0][0]?.Invoice_Date).format("DD-MM-YYYY")
              // : "",
            LR_Date: res[0][0]?.LR_Date,
              // ? moment(res[0][0]?.LR_Date).format("DD-MM-YYYY")
              // : "",
            TotalSum: sum,
            IGST: res[0][0].IGST_Per ? res[0][0].IGST_Per : 0,
            SGST: res[0][0].SGST_Per ? res[0][0].SGST_Per : 0,
            CGST: res[0][0].CGST_Per ? res[0][0].CGST_Per : 0,
            TCS: res[0][0].TCS_Per ? res[0][0].TCS_Per : 0,
            GrandTotalPer: IGST + CGST + SGST + TCS,
            IGST_Amount: IGST,
            SGST_Amount: SGST,
            CGST_Amount: CGST,
            TCS_Amount: TCS,
             GrandTotal_Amount: (IGST + SGST + CGST + TCS + sum).toFixed(2),
             Gross_amount:totalamt

          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  


  maxNo = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      DocName: "'VR'",
      DealerId: sessionStorage.getItem("dealerid"),
    });
    console.log("newdata", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            this.setState({
              double_save:false,
            },()=>{
              alert("Record Saved Successfully.");
              this.GetVehgicleBillingDetails();
              this.MasterAPICall()
            })
           
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelClear = () => {
    {this.Vehicle_Receipt_No() };
    this.GetInvoiceList();
    // this.Vehicle_Receipt_No();
    this.setState({
      showFieldOnAddNew: true,
      flag: "insert",
      double_save:false,
      Supplier_name:"0",
      Supplier_id:0,
      MAinHdr:0,
      status: "Open",
      Invoice_No: "",
      Invoice_Date: "",
      Delivery_No: "",
      PO_No: "",
      PO_Date: "",
      Parking_Location: "",
      Vehicle_Condition: "",
      Model_Category: "",
      Model_Code: "",
      Model: "",
      Model_Price: "",
      Quantity: "",
      Total: "",
      Chassis_No: "",
      Engine_No: "",
      ModelRowData: [],
      GrandTotal_Amount: "",
      TCS_Amount: "",
      CGST_Amount: "",
      IGST_Amount: "",
      SGST_Amount: "",
      GrandTotalPer: "",
      LR_Date:"",
      TCS: "",
      CGST: "",
      SGST: "",
      IGST: "",
      TotalSum: "",
      Receipt_Date: moment(new Date()).format("DD-MM-YYYY"),
      isSelectTag: true,
      idforconfirm: 0,
      tax_raw:[],
      model_details:[],
      Supplier:""
    });
  };

  

  modelTable() {
    const handelDropDwonChange = (data, val) => {
      data.VehConditionID = parseInt(val);
      for (let i = 0; i < this.state.ModelRowData.length; i++) {
        if (data.ID == this.state.ModelRowData[i].ID) {
          this.state.ModelRowData[i] = data;
        }
      }
      this.setState(
        {
          ModelRowData: this.state.ModelRowData,
        },
        () => {
          console.log(this.state.ModelRowData, "jkjkkj");
        }
      );
    };

    const is_Stock = (data, val) => {
      data.is_stock = (val);
      for (let i = 0; i < this.state.ModelRowData.length; i++) {
        console.log("biii",this.state.ModelRowData)
        if (data.ID == this.state.ModelRowData[i].ID) {
          this.state.ModelRowData[i] = data;
        }
      }
      this.setState(
        {
          ModelRowData: this.state.ModelRowData,
        },
        () => {
          console.log(this.state.ModelRowData, "jkjkkj");
        }
      );
    };

    let columnDefs = [
      {
        headerName: "Model",
        field: "model_gr_description",
        width: "120px",
      },
      { headerName: "Model Name", field: "model_name", width: "120px" },
      { headerName: "Model Code", field: "model_code", width: "120px" },
      { headerName: "Colour", field: "colour", width: "120px" },
      // { headerName: "Tax", field: "Tax", width: "120px" },
      { headerName: "Chassis No", field: "chassis_no", width: "120px" },
      { headerName: "Vehicle No", field: "Vehicle_No", width: "120px" },
      { headerName: "Battery No", field: "BatteryNo", width: "120px" },
      { headerName: "Charger No", field: "ChargerNo", width: "120px" },
      {
        headerName: "Controller No",
        field: "Controller_Unit_No",
        width: "120px",
      },
      { headerName: "Motor No", field: "Motor_No", width: "120px" },
      { headerName: "Display No", field: "DispayNo", width: "120px" },
      { headerName: "IOT Device No", field: "IOT_Device_ID", width: "120px" },
      { headerName: "Quantity", field: "Qty", width: "120px" },
      { headerName: "Rate", field: "rate", width: "120px" },
      { headerName: "Discount", field: "Discount", width: "120px" },
      { headerName: "Total", field: "TotalAmt", width: "120px" },
      { headerName: "IGST PER", field: "IGST_Per", width: "120px" },
      { headerName: "IGST AMT", field: "IGST_Amt", width: "120px" },
      { headerName: "SGST PER", field: "SGST_Per", width: "120px" },
      { headerName: "SGST AMT", field: "SGST_Amt", width: "120px" },
      { headerName: "CGST PER", field: "CGST_Per", width: "120px" },
      { headerName: "CGST AMT", field: "CGST_Amt", width: "120px" },
      { headerName: "TCS PER", field: "TCS_Per", width: "120px" },
      { headerName: "TCS AMT", field: "TCS_Amt", width: "120px" },
      { headerName: "DC No", field: "DC_no", width: "120px" },
      { headerName: "DC Date", field: "DC_Date", width: "120px" },
      // { headerName: "DMS PO No", field: "PO_No", width: "120px" },

      // { headerName: "Sales Order No", field: "Sales_Order_No", width: "120px" },

      {
        headerName: "Vehicle Condition",
        field: "",
        width: "150px",
        cellRenderer: function (params) {
          return (
            <select
              onChange={(e) =>
                handelDropDwonChange(params.data, e.target.value)
              }
              value={params?.data?.VehConditionID}
              disabled={params?.data?.Is_Confirm === "Y" ? true : false}
              className=" form-control"
            >
              {/* <option value="0">Select</option> */}
              <option selected value="1">Saleable</option>
              <option value="2">Damaged</option>
            </select>
          );
        },
      },
      {
        headerName: "Upload In Stock",
        field: "",
        width: "150px",
        cellRenderer: function (params) {
          console.log('ssssss',params?.data?.is_stock)
          return (
            
            <select
              onChange={(e) =>
                is_Stock(params.data, e.target.value)
              }
              value={params?.data?.is_stock}
              disabled={params?.data?.Is_Confirm === "Y" ? true : false}
              className=" form-control"
            >
             {/* <option value="0">Select</option> */}
              <option selected value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          );
        },
      },
    ];
    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.ModelRowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => { }}
          ></AgGridReact>
        </div>
      </div>
    );
  }

  
  model_details() {
      
    let columnDefs = [
      {
        headerName: "Model",
        field: "model_gr_description",
        flex:1,
      },
      { headerName: "Model Name", field: "model_name", flex:1 },
      { headerName: "Model Code", field: "model_code", flex:1 },
      { headerName: "Model Description", field: "model_gr_description", flex:1 },
      { headerName: "Colour", field: "colour", flex:1 },
      { headerName: "Quantity", field: "Qty", flex:1 },
      { headerName: "Rate", field: "rate", flex:1 },
  
      { headerName: "Total", field: "TotalAmt", flex:1 },
      { headerName: "HSN Code", field: "HSN_Code", flex:1 },
     

    ];
    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.model_details}
            enableCellTextSelection={true}
            onCellClicked={(event) => { }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  saveFun=()=>{
    this.state.MAinHdr == 0 && this.handelSave()
    this.state.MAinHdr != 0 &&this.handelSave("N")
    
  }
  
  Validate = () => {

    if(!this.state.Vehicle_Receipt_NO){
      alert("Please Click on Add New First To Generate Receipt No.")
      return
    }else if(this.state.Supplier_name==""){
        alert("Please Enter the Supplier Name")
        return
      }
    else if(this.state.Invoice_No==""){
      alert("Please Enter the Invoice No")
      return
    }
    else if(this.state.Delivery_No=="")
    {
      alert("Please Enter the LR No.")
      return
    }
    else
    {
      this.saveFun();
    }

    
  };
  ChangeVal(event, key) {
    console.log("Chassis_No", key, event.target.value);
    this.setState({
      [key]: event.target.value,
    });
    let supplier_dealer_id=0
    if(key=="Supplier_name"){
      supplier_dealer_id = this.state.Supplier_List.filter((e) => {
         console.log("ttthh",e.ID === parseInt(event.target.value));
        return e.ID === parseInt(event.target.value);
      })[0]?.PurchDealer;
      this.setState({
        Supplier_id:event.target.value,
        supplier_dealer_id:supplier_dealer_id,
        Invoice_Option:[]
      },()=>{
        
        this.GetInvoiceList(supplier_dealer_id)
      })
      
    }
  }
  VehicleReceipt = () => {
    return (
      <div>
        <div className=" row justify-content-end mr-3">
          <div className=" row">
            <button
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={() => this.handelClear()}
            >
              Add New
            </button>
            <button
              disabled={this.state.double_save}
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={() => this.Validate()}
            >
              Save
            </button>
            <button
              disabled={this.state.MAinHdr == 0 ? true : this.state.double_save}
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={() => this.handelconfirm("Y")}
            >
              Confirm
            </button>
            {/* <button
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
            >
              Cancel
            </button> */}
          </div>
        </div>
        <br></br>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Receipt No.</label>
            <input
              readOnly
              disabled
              value={this.state.Vehicle_Receipt_NO}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Receipt Date</label>
            <input
              readOnly
              disabled
              value={this.state.Receipt_Date}
              className=" form-control "
            ></input>
          </div>
          {/* <div className=" col-3">
          <label>Supplier Name {this.required()}</label>
          <select
                disabled={this.state.showFieldOnAddNew ? false : true}
                onChange={(event) => this.ChangeVal(event, "Supplier_name")}
                // onChange={(e) => this.setState({ Supplier_name: e.target.value })}
                value={this.state.Supplier_name}
                className=" form-control "
              >
                <option value={"0"}>Select</option>
                {this.state.Supplier_List.map((e) => {
                  return <option value={e.ID}>{e.Supplier_name}</option>;
                })}
              </select>
          </div> */}
          {/* <div className=" col-3">
            <label>Supplier Name {this.required()}</label>
            {this.state.isSelectTag ? (
              <select
                disabled={this.state.showFieldOnAddNew ? false : true}
                onChange={(event) => this.ChangeVal(event, "Supplier_name")}
                // onChange={(e) => this.setState({ Supplier_name: e.target.value })}
                value={this.state.Supplier_name}
                className=" form-control "
              >
                <option value={"0"}>Select</option>
                {this.state.Supplier_List.map((e) => {
                  return <option value={e.ID}>{e.Supplier_name}</option>;
                })}
              </select>
            ) : (
              <input
                disabled={this.state.showFieldOnAddNew ? false : true}
                value={this.state.Supplier_name}
                onChange={(e) => this.setState({ Supplier_name: e.target.value })}
                className=" form-control "
              ></input>
            )}
          </div> */}
          <div className=" col-3">
            <label>Invoice No. {this.required()}</label>
            {this.state.isSelectTag ? (
              <select
                disabled={this.state.showFieldOnAddNew ? false : true}
                onChange={(e) => {
                  let Supplier_name=this.state.Invoice_Option?.filter(ele=>e.target.value==ele.Tr_no)[0].SupplierID
                  let Supplier=this.state.Invoice_Option?.filter(ele=>e.target.value==ele.Tr_no)[0].Dealer_Name
                  this.setState({ Invoice_No: e.target.value,
                    Supplier_name:Supplier_name,
                    Supplier:Supplier,
                    Supplier_id:Supplier_name
                   })
                }}
                value={this.state.Invoice_No}
                className=" form-control "
              >
                <option>Select</option>
                {this.state.Invoice_Option.map((e) => {
                  return <option value={e.Tr_no}>{e.Tr_no}</option>;
                })}
              </select>
            ) : (
              <input
                disabled={this.state.showFieldOnAddNew ? false : true}
                value={this.state.Invoice_No}
                onChange={(e) => this.setState({ Invoice_No: e.target.value })}
                className=" form-control "
              ></input>
            )}
          </div>
          <div className=" col-3">
            <label>Supplier Name {this.required()}</label>

            <input
              disabled
              value={this.state.Supplier}
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label>Invoice Date</label>
            <input
              readOnly
              disabled
              value={this.state.Invoice_Date}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>LR No. {this.required()}</label>
            <input
              disabled={this.state.showFieldOnAddNew ? false : true}
              value={this.state.Delivery_No}
              className=" form-control "
              onChange={(e) => this.setState({ Delivery_No: e.target.value })}
              onMouseLeave={() => {
                this.state.flag != "update" &&
                  this.state.Delivery_No !== "" &&
                  this.state.Delivery_No !== undefined &&
                  this.handelDealerNoOnChange();
                // this.state.Delivery_No !== "" && this.TAXDetails_1();
              }}
              onKeyPress={(e) => {
                const pattern = /[A-Za-z0-9]/;
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault();
                }
              }}
            ></input>
          </div>
          <div className=" col-3">
            <label>LR Date</label>
            <input
              disabled
              value={this.state.LR_Date}
              className=" form-control "
            ></input>
          </div>
          {/* <div className=" col-3">
            <label>Vehicle Condition</label>
            <select
              disabled={this.state.showFieldOnAddNew ? false : true}
              onChange={(e) =>
                this.setState({ Vehicle_Condition: e.target.value })
              }
              value={this.state.Vehicle_Condition}
              className=" form-control "
            >
              <option>Select</option>
              <option>Accident</option>
              <option>Damaged (Repairable)</option>
              <option value="1">Saleable</option>
            </select>
          </div> */}
        </div>
      </div>
    );
  };
  TAXDetails = () => {
    return (
      <table style={{ width: "70%" }}>
        <tr>
          <th style={{ fontWeight: "600" }}></th>
          <th style={{ fontWeight: "600" }}>Total Amount</th>
          <th style={{ fontWeight: "600" }}>IGST</th>
          <th style={{ fontWeight: "600" }}>SGST</th>
          <th style={{ fontWeight: "600" }}>CGST</th>
          <th style={{ fontWeight: "600" }}>TCS</th>
          <th style={{ fontWeight: "600" }}>Grand Total</th>
        </tr>
        <tr>
          <td>
            <label>%</label>
          </td>
          <td>
            {/* <input
              className="form-control mt-1"
              style={{ width: "140px", height: "30px" }}
              disabled
              value={0}
            /> */}
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.IGST}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.SGST}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.CGST}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.TCS}
            />
          </td>
          <td>
            {/* <input
              className="form-control"
              style={{ width: "140px" }}
              disabled
            /> */}
          </td>
        </tr>
        <tr>
          <td>
            <label>Amount</label>
          </td>
          <td>
            <input
              disabled
              style={{ width: "140px" }}
              className="form-control mt-1"
              value={this.state.TotalSum}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.IGST_Amount}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.SGST_Amount}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.CGST_Amount}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.TCS_Amount}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.GrandTotal_Amount}
            />
          </td>
        </tr>
      </table>
    );
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };

  data = () => {
    let columnDefs= [
      {
        headerName: "HSN Code",
        field: "HSN_Code",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      {
        headerName: "HSN Description",
        field: "HSN_Description",
        flex: "2",
      },
      {
        headerName: "Taxable Amount",
        field: "TaxableAmount", aggFunc: "sum",
        //editable: this.state.double_save? false: true,
        valueParser: "Number(newValue)",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
           
      },

      {


        headerName: 'IGST', headerClass: 'custom-header',
        children: [
          { headerName: 'IGST(%)', field: 'IGST',width:"100px", },
          { headerName: 'Amount', field: 'IGST_AMT',width:"100px", }
        ]
      },
      {


        headerName: 'SGST',headerClass: 'custom-header',
        children: [
          { headerName: 'SGST(%)', field: 'SGST' ,width:"100px",},
          { headerName: 'Amount', field: 'SGST_AMT' ,width:"100px",}
        ]
      },
      {


        headerName: 'CGST',headerClass: 'custom-header',
        children: [
          { headerName: 'CGST(%)', field: 'CGST' ,width:"100px",},
          { headerName: 'Amount', field: 'CGST_AMT',width:"100px", }
        ]
      },
      
      {
        headerName: "Total Tax Amt",
        field: "TotalTaxAmt",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      {
        headerName: "Total Amt With Tax",
        field: "TotalAmt_WithTax",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        aggFunc: 'sum',
        cellRendererParams: {
            
            footerValueGetter:  (params) => 
            console.log("params",params)
            // params.value
        }
        
      },

    ]
    var gridOptions = {
      pivotFooter: true, // Enable pivot footer
      groupIncludeFooter: true ,
   
     
    };
    return (
      <div style={{overflowX:"scroll"}}> 
        <div className="ag-theme-balham" style={{ padding: "0",width:"1400px" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            // pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            gridOptions={gridOptions}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.tax_raw}
            enableCellTextSelection={true}
            suppressAggFuncInHeader={true}
              pivotPanelShow={"always"}
             suppressHorizontalScroll={true}
            onCellClicked={(event) => { }}
                         
                  ></AgGridReact>
        </div>
      </div>
    );


  }
  render() {
    return (
      <div>
        <h4 className="heading_text">Purchase Receipt</h4>
        <div class="card mt-5">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                 Purchase Receipt List
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">{this.masterTable()}</div>
          </div>
        </div>
        <div class="card ">
          <div class="card-header" id="headingFour">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                 Purchase Receipt Details
              </button>
            </h5>
          </div>
          <div
            id="collapseFour"
            class="collapse show "
            aria-labelledby="headingFour"
          >
            <div class="card-body">{this.VehicleReceipt()}</div>
          </div>
        </div>
        <div class="card ">
          <div class="card-header" id="headingSeven">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collaoModel"
                aria-expanded="true"
                aria-controls="collaoModel"
              >
                 Model Details
              </button>
            </h5>
          </div>
          <div
            id="collaoModel"
            class="collapse show "
            aria-labelledby="headingSeven"
          >
            <div class="card-body">{this.model_details()}</div>
          </div>
        </div>
        <div class="card ">
          <div class="card-header" id="headingSeven">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collaoseSeven"
                aria-expanded="true"
                aria-controls="collaoseSeven"
              >
                 Chassis Details
              </button>
            </h5>
          </div>
          <div
            id="collaoseSeven"
            class="collapse show "
            aria-labelledby="headingSeven"
          >
            <div class="card-body">{this.modelTable()}</div>
          </div>
        </div>
        <div class="card ">
          <div class="card-header" id="headingEight">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseEight"
                aria-expanded="true"
                aria-controls="collapseEight"
              >
                 Tax Details
              </button>
            </h5>
          </div>
          <div
            id="collapseEight"
            class="collapse show "
            aria-labelledby="headingEight"
          >
            <div class="card-body">
              {/* {this.TAXDetails()} */}

              {this.data()}
              <div>
                <label
                  style={{
                    fontWeight: "600",
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  Grand Total : {parseFloat(this.state.Gross_amount).toFixed(2)}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
