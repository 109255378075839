import React from "react";
import "../../Files/files-styles.css";
// import '../Claims/Dashboard/styles.css'
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import DatePicker from "react-datepicker";
import moment from "moment";
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import { Tiles } from "../../../components/Tiles";
import Strings from "../../../../helpers/Strings";
import Multiselect from "multiselect-react-dropdown";
// import { post } from 'axios';
// import { faL } from "@fortawesome/free-solid-svg-icons";
import { Quotation_Report } from "../../Report/Quotation_Report";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import "./index.css";
const $ = window.$;
export class Enquiry_details extends React.Component {
  constructor(props) {
    console.log("props", props.EnquiryDetails);
    super();
    this.state = {
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      showQuotationReport: false,
      kwh: "",
      isSaveClicked: false,
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      ethnicity_map: [],
      Ethnicity: [],
      Customer_Name: props?.customer_name
        ? props?.customer_name
        : props?.pendingClick?.Dealer_Name,
      id:
        props && props.EnquiryDetails && props.EnquiryDetails.ID
          ? props.EnquiryDetails.ID
          : props?.pendingClick?.ID,
      Title: "",
      enquiry_no:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Lead_inq_no
          : 0,
      enquiry_date:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Lead_Date
          : this.getCurrentDate(),
      Source:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].inq_source_Id
          : 0,
      // props&&props.EnquiryDetails&&props.EnquiryDetails?props.EnquiryDetails.inq_source_Id:"",
      Source_name:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails.Source_name
          : "",
      source_mobile:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails.Source_Ph_no
          : "",
      source_address:
        props && props.EnquiryDetails && props.EnquiryDetails
          ? props.EnquiryDetails.Source_Address
          : "",
      address2: "",
      ext_mit_cust: "",
      pincode: "",
      State: "",
      District: "",
      primary_application: "",
      secondary_application: "",
      startDate:
        props && props.EnquiryDetails && props.EnquiryDetails[1]?.obj_date
          ? moment(
              props &&
                props.EnquiryDetails &&
                props.EnquiryDetails[1][0].obj_date,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD"),
      endDate:
        props && props.EnquiryDetails && props.EnquiryDetails[1]
          ? moment(
              props &&
                props.EnquiryDetails &&
                props.EnquiryDetails[1][0].next_date,
              "DD/MM/YYYY"
            ).format("YYYY-MM-DD")
          : "",
      total_no_fleet: "",
      kam_customer: "",
      call_ticket_number: "",
      call_ticket_date: "",
      HiPaaS_ID: "",
      visit_details:
        props && props.EnquiryDetails && props.EnquiryDetails[1]
          ? props.EnquiryDetails[1][0].obj_id
          : "",
      discussion: "",
      Country_id: 1,
      M_M0Masterid:
        props && props.EnquiryDetails && props.EnquiryDetails[4]
          ? props.EnquiryDetails[4][0].M_M0Masterid
          : "",
      lead_id:
        props && props.EnquiryDetails && props.EnquiryDetails[4]
          ? props.EnquiryDetails[4][0].m0id
          : "",
      M1_id:
        props && props.EnquiryDetails && props.EnquiryDetails[4]
          ? props.EnquiryDetails[4][0].m1id
          : "",
      next_visit_details:
        props && props.EnquiryDetails && props.EnquiryDetails[1]
          ? props.EnquiryDetails[1][0].next_obj_id
          : "",
      Attended_By:
        props && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].AttendedBy
          : 0,
      Allocated_To:
        props && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].AllocatedTo
          : 0,
      HiPaaS_ID: "",
      date: "",
      newdate: "",
      time_spent: "",
      Commitment: "",
      AMC_Details: "",
      loaderforupload: false,
      additional_accessory: "",
      tableData: [
        { id: 1, name: "Item 1", quantity: 0 },
        { id: 2, name: "Item 2", quantity: 0 },
        { id: 3, name: "Item 3", quantity: 0 },
      ],
      vistitdetails: [],
      SourceListOption: [],
      AreaListOption: [],
      PO_Type_Option: [],
      Model_Code: [],
      Model_Category: [],
      Allocated_By: [],
      AllocatedTo: [],
      ModelData: [],
      CompleteData: [],
      Dealer_vehicle_Code: "",
      Additional_accessory_12: [],
      En_no: "",
      finacial_year: "",
      Enquiry_date:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? moment(props.EnquiryDetails[0][0].Lead_Date).format("DD-MM-YYYY")
          : this.getCurrentDate(),
      ModelCategory:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].model_gp
          : 0,
      ModelCode:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Model_ID
          : props?.pendingClick?.Doc_No
          ? props && props.EnquiryDetails && props.EnquiryDetails[0]
            ? props.EnquiryDetails[0][0].Model_ID
            : props?.pendingClick?.Doc_No
          : "",
      Model:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].model_name
          : props?.pendingClick?.Doc_No
          ? props && props.EnquiryDetails && props.EnquiryDetails[0]
            ? props.EnquiryDetails[0][0].model_name
            : props?.pendingClick?.Doc_No
          : "",
      //  Quantity: props?.EnquiryDetails?.Qty,
      Special_Warranty_Details: "",
      ExtendedW_arranty_Details: "",
      Other_Details: "",
      Enquiry_ID: 0,
      AMV_Model_Code: [],
      Model_Data_1: [],
      AVM_Standard_Accessories: [],
      AVM_Additional_Accessories: [
        //   {
        //   key:"Helmet",
        //   MRP:2000
        // },
        // {
        //   key:"mirror",
        //   MRP:100
        // }
      ],
      //PopupAVM
      Total:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.total_amount,
      TotalCopy:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.total_amount,
      Subsidy_Type:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Subsity_Type
          : "",
      Other_Charges_Amount:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.other_charges_amount,
      AMV_Other_Charges:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props?.EnquiryDetails[0][0].other_changes
          : 0,
      AMV_Disbursment_Amount:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.disbursment_amount
          : "",
      AMV_Special_Number_Amount:
        props && props.EnquiryDetails && props && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.special_number_amount
          : "",
      AMV_Dealer_Proposed_Amount:
        props && props.EnquiryDetails && props && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.dealer_propsed_discount
          : "",
      AMV_Central_Subsidy_Amount:
        props && props.EnquiryDetails && props && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.centarl_subsidy_amount
          : "",
      AMV_RTO_Discount_Amount:
        props && props.EnquiryDetails && props && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.RTO_discount_amount
          : "",
      AMV_RTO_Discount:
        props && props.EnquiryDetails && props && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.RTO_discount
          : "",
      AMV_Subsidy_Amount:
        props && props.EnquiryDetails && props && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.subsidiary_amount
          : "",
      AMV_RSA_Amount:
        props && props.EnquiryDetails && props && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.RSA_change
          : "",
      AMV_Smart_Card_Amount:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.Smart_card_amount
          : "",
      AMV_HPA_Charges:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.HPA_Change
          : "",
      AMV_CRTM_Amount:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.CRTM_amount
          : "",
      AMV_Insurance_Amount:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0]?.insurance_amount
          : "",
      AMV_Insurance_Year:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].insurance_year
          : "0",
      Insurance_company:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Insurance_company
          : "",
      AMV_RTO_Amount:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.RTO_Amount,
      AMV_Addational_Accessories_Amount:
        props && props.EnquiryDetails[0] && props.EnquiryDetails
          ? props.EnquiryDetails[0][0].Additional_Accessories_amount
          : 0,
      AMV_Addational_Accessories:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Standard_Accesories
          ? props?.EnquiryDetails[0][0]?.Additional_Accessories
          : [],
      AMV_Standard_Accessories_Amount:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Standard_Accessories_amount,
      Total_Accessories_Amount:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Standard_Accesories,
      AMV_Sale_Rate:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.MRP,
      Quantity:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props?.EnquiryDetails[0][0].Qty
          : 1,
      AMV_Model_Colour:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.model_Colour,
      AMV_Model_Name:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.model_name,
      AMV_Model_Code:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.model_Code,
      AMV_Model_Group:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.model_gp,
      Exchange_Bonus_Amount:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Exchange_bonus_with_tax,
      Additional_access:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Additional_Accessories
          : "",
      standard_access:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Standard_Accesories
          : "",
      insuranceList: [],
      leadType: [],
      leaddistrict: [],
      leadstate: [],
      leadregion: [],
      vistitdetails: [],
      Financier_customer: [],
      rowData: [],
      leadtown: [],
      docno:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.Tr_Num,
      Doc_Date:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.M0_Date,
      email:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.E_mail
          ? props?.EnquiryDetails[4][0]?.E_mail
          : "",
      Country:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.Country_Id,
      Region:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.Region_ID
          ? props?.EnquiryDetails[4][0]?.Region_ID
          : 0,
      Town:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.Taluka_ID
          ? props?.EnquiryDetails[4][0]?.Taluka_ID
          : 0,
      // District:props&&props.EnquiryDetails&&props.EnquiryDetails[0]&&props?.EnquiryDetails[4][0]?.District_ID,
      // State:props&&props.EnquiryDetails&&props.EnquiryDetails[0]&&props?.EnquiryDetails[4][0]?.State_ID,
      City:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.CITY
          ? props &&
            props.EnquiryDetails &&
            props.EnquiryDetails[0] &&
            props?.EnquiryDetails[4][0]?.CITY
          : "",
      pincode:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.PIN
          ? props &&
            props.EnquiryDetails &&
            props.EnquiryDetails[0] &&
            props?.EnquiryDetails[4][0]?.PIN
          : "",
      address2:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.add2
          ? props?.EnquiryDetails[4][0]?.add2
          : "",
      address1:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.add1
          ? props &&
            props.EnquiryDetails &&
            props.EnquiryDetails[0] &&
            props?.EnquiryDetails[4][0]?.add1
          : "",
      Mobileno:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.mobile
          ? props &&
            props.EnquiryDetails &&
            props.EnquiryDetails[0] &&
            props?.EnquiryDetails[4][0]?.mobile
          : "",
      Name:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.name
          ? props &&
            props.EnquiryDetails &&
            props.EnquiryDetails[0] &&
            props?.EnquiryDetails[4][0]?.name
          : "",
      State:
        sessionStorage.getItem("Dealer_state"),
      District:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.District_ID,
      Title:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.Prefix,
      Type:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[4][0]?.type_flag_id
          ? props &&
            props.EnquiryDetails &&
            props.EnquiryDetails[0] &&
            props?.EnquiryDetails[4][0]?.type_flag_id
          : 0,
      Remark:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[1][0]?.Remark
          ? props?.EnquiryDetails[1][0]?.Remark
          : "",
      setCrtAmt:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.CRTM_Amount_flag
          ? props?.EnquiryDetails[0][0]?.CRTM_Amount_flag
          : false,

      setRSACharge:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.RSA_Charge_flag
          ? props?.EnquiryDetails[0][0]?.RSA_Charge_flag
          : false,

      setSmartCartAmt:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Smart_Card_Amount_flag
          ? props?.EnquiryDetails[0][0]?.Smart_Card_Amount_flag
          : false,

      setRtoAmount:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.RTO_Amount_flag
          ? props?.EnquiryDetails[0][0]?.RTO_Amount_flag
          : false,
      saveLoader: false,
      grand_total:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Grand_Total,

      Quantity_Amount:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Amount,

      Ex_ShowRoom_Price:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.MRP,

      Taxable_Amount:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Taxable_Amt,

      AMV_HSRP_Charges:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.HSRP_Charge,

      Other_RTO_Charges:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Other_RTO,

      model_typecode:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Model_gp_type,
      Quotation_No:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Quotation_Number,
      grand_total: "",
      AMV_RTO_Amount: "",
      Taxable_Amount: "",
      Dealer_vehicle_Code: "",
      showPrintButton: this.props?.EnquiryDetails?.length > 0,
      Enquiry_Status: props?.Enquiry_Status ? props?.Enquiry_Status : "",
      fin_year: sessionStorage.getItem("fin_year"),
      Dealer_Code: sessionStorage.getItem("Dealer_Code"),
      newConditionArray_1:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[5],
      ConditionCode: [
        {
          id: 1,
          accid: "",
          accessories_name: "",
          accessories_amount: "",
          hsn: "",
          igst: "",
          igst_amount: "",
          sgst: "",
          sgst_amount: "",
          cgst: "",
          cgst_amount: "",
          accessories_total_withtax: "",
          options: [],
        },
      ],
    rowData:[],
    inq_id:props &&
    props.EnquiryDetails &&
    props.EnquiryDetails[0] &&
    props?.EnquiryDetails[0][0]?.ID,
    Scheme_Amount:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Scheme_Amt
          : 0,
          Scheme_End_Date:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Scheme_End_date
          : 0,
          Scheme_Id:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Scheme_ID
          : 0,
          Scheme_Name:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Scheme_Name
          : 0,
          Scheme_Start_Date:
        props && props.EnquiryDetails && props.EnquiryDetails[0]
          ? props.EnquiryDetails[0][0].Scheme_start_date
          : 0,
        enq_confirm:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.inq_confirm,
        enq_cancel:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.inq_cancel,
        Is_booking:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.Is_booking,
        M1lost:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.M1Lost,
        M1LostConfirm:
        props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[0][0]?.M1LostConfirm,
        Lead_hdr_ID:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props?.EnquiryDetails[3][0]?.Lead_Hdr_ID,

        Cancel_Remark:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[2][0].rsn_Id?props.EnquiryDetails[2][0].rsn_Id:"",

        lost_ID:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[2][0].ID? props.EnquiryDetails[2][0].ID:0,
        // Cancel_Remark:"",
        reasonLost:[],
        Model_Code_test:[],
        
        Model_test:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Model_Group?props.EnquiryDetails[6][0].Model_Group:"",
        ModelCode_test:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Model_Code?props.EnquiryDetails[6][0].Model_Code:"",
        Helmet:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Helmet?props.EnquiryDetails[6][0].Helmet:"",
        Allocated_Vehicle:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Allocated_Vehicle?props.EnquiryDetails[6][0].Allocated_Vehicle:"",
        Staff_accompanying:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Staff_Accompanying?props.EnquiryDetails[6][0].Staff_Accompanying:"",
        License_number:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].License_No?props.EnquiryDetails[6][0].License_No:"",
        Document_Name:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Document_Name?props.EnquiryDetails[6][0].Document_Name:"",
        Model_choice:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Is_ModelChoice?props.EnquiryDetails[6][0].Is_ModelChoice:"",
        vehicles_Condition:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Is_VehicleCondition?props.EnquiryDetails[6][0].Is_VehicleCondition:"",
        duration_test_drive:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Is_DurationSufficient?props.EnquiryDetails[6][0].Is_DurationSufficient:"",
        explanation:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Is_ExplainationSatisfactory?props.EnquiryDetails[6][0].Is_ExplainationSatisfactory:"",
        Rating:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].TestDriveRating?props.EnquiryDetails[6][0].TestDriveRating:"",
        remark:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].Remark?props.EnquiryDetails[6][0].Remark:"",
        document_Path:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].url?props.EnquiryDetails[6][0].url:"",
        newurl: [],
        temparray:[],
        test_ID:props &&
        props.EnquiryDetails &&
        props.EnquiryDetails[0] &&
        props.EnquiryDetails[6][0].ID?props.EnquiryDetails[6][0].ID:0,
    };
  }

  componentDidMount() {
    
    console.log("newConditionArray_1", this.state.newConditionArray_1);
    if (
      this.state.Additional_access != undefined &&
      this.state.Additional_access != ""
    ) {
      const data = this.state.Additional_access.split(",");
      let segment = data.map((item) => {
        return { key: item };
      });
      this.setState({
        selectedValues_1: segment,
      });
    }
    if (
      this.state.standard_access != undefined &&
      this.state.standard_access != ""
    ) {
      const data = this.state.standard_access.split(",");
      let segment_12 = data.map((item) => {
        return { key: item };
      });
      this.setState({
        selectedValues: segment_12,
      });
    }
    // this.displayEnquirydata();
    this.visit_details();
    this.GetSourceList();
    this.GetAreaList();
    this.GetPOType();
    this.GetModelCategory();
    this.GetAllocatedby();
    this.getVisitDetails();
    // this.GetModel_1();
    this.standard_accssories();
    // this.state.newConditionArray_1!=undefined && this.bindaccessories()
    this.state.ModelCode && this.getInsurance(this.state.ModelCode);
    {
      !(this.state.ModelCategory === 0) && this.GetModelCode();
       this.state.Model_test!=0&& this.GetModelCode_test()
    }
    this.Lead_type();
    // this.Lead_no();
    this.lead_state();
    this.lead_district_new();
    this.lead_region();
    this.reason_lost();
    {
      this.state.lead_id === "" && this.Enquir_no();
    }
    {
      this.state.Town != 0 && this.lead_town();
    }
  }

  // standard_accssories = () => {
  //   const url = Urls.python_baseurl + "/GetAccessories_SP_GetAccessories";
  //   var data = JSON.stringify({
  //     Type: "'Standard'",
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         let newdata;
  //         res.forEach((element) => {
  //           if (!element) {
  //             return;
  //           }
  //           newdata = {
  //             key: element.Accesories_Name,
  //             MRP: element.MRP,
  //           };
  //           this.state.AVM_Standard_Accessories.push(newdata);
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  QuotationNumber = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "Q",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Quotation_No:
                this.state.Dealer_Code +
                "" +
                "Q" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              // this.maxNo_Quotation();
              this.saveLead();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  // financiaryear_Quotation = () => {
  //   const url = Urls.python_baseurl + "/SP_GetDataFromDataQuery";
  //   var data = JSON.stringify({
  //     ValGetDataQueryType: "15",
  //     iParentId: "126",
  //     sAdditionalCondition: "''",
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState(
  //           {
  //             finacial_year_quotation: res[0].Value,
  //           },
  //           () => {
  //             this.delarcode_Quotation();
  //           }
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  // delarcode_Quotation = () => {
  //   const url = Urls.python_baseurl + "/Get_Dealer_SP_GET_Dealercode";
  //   var data = JSON.stringify({
  //     DealerID: sessionStorage.getItem("dealerid"),
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState({
  //           delar_code: res[0].Dealer_vehicle_Code,
  //           Quotation_No:
  //             res[0].Dealer_vehicle_Code +
  //             "Q" +
  //             this.state.finacial_year_quotation +
  //             "" +
  //             this.state.Quotationno.toString().padStart(6, '0')
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  //   {
  //     "M1_Hdr": 12,
  //     "Acc_id": 1,
  //     "cr_date": "2024-02-20T00:00:00",
  //     "Accessories_Amount": 1398,
  //     "HSN": "1234",
  //     "IGST": 18,
  //     "IGST_Amount": 251.64,
  //     "SGST": 0,
  //     "SGST_Amount": 0,
  //     "CSGT": 0,
  //     "CSGT_Amount": 0,
  //     "Total_With_Tax": 1649.64
  // }
  bindaccessories = () => {
    console.log("newConditionArray_1", this.state.AccessoriesOption);
    let accesories = [];

    let newcondition = this.state.newConditionArray_1.map((e, index) => {
      return {
        id:
          this.state.ConditionCode[this.state.ConditionCode.length - 1]?.id +
          index,
        accid: e?.Acc_id,
        // accessories_name: e.Accesories_Name,
        accessories_name: this.state.AccessoriesOption.filter((element) => {
          return e?.Acc_id == element.ID;
        })[0]?.Accesories_Name,
        accessories_amount: e?.Accessories_Amount,
        hsn: e?.HSN,
        igst: e?.IGST,
        igst_amount: e?.IGST_Amount,
        sgst: e?.SGST,
        sgst_amount: e?.SGST_Amount,
        cgst: e?.CSGT,
        cgst_amount: e?.CSGT_Amount,
        accessories_total_withtax: e?.Total_With_Tax,
        accesores_ID: e?.id,
        options: this.state.AccessoriesOption,
        ["selectedAccessories"]: this.state.AccessoriesOption.filter(
          (element) => {
            return e?.Acc_id == element.ID;
          }
        )[0],
      };
    });
    accesories.push(...newcondition);

    // for (let index = 0; index < this.state.newConditionArray_1.length; index++) {
    //   let data={
    //     id: index,
    //     accid: this.state.newConditionArray_1[index].Acc_id,
    //     accessories_name: this.state.newConditionArray_1[index].Accesories_Name,
    //     accessories_amount: this.state.newConditionArray_1[index].Accessories_Amount,
    //     hsn: this.state.newConditionArray_1[index].HSN,
    //     igst: this.state.newConditionArray_1[index].IGST,
    //     igst_amount: this.state.newConditionArray_1[index].IGST_Amount,
    //     sgst: this.state.newConditionArray_1[index].SGST,
    //     sgst_amount: this.state.newConditionArray_1[index].SGST_Amount,
    //     cgst: this.state.newConditionArray_1[index].CSGT,
    //     cgst_amount: this.state.newConditionArray_1[index].CSGT_Amount,

    //     accessories_total_withtax: this.state.newConditionArray_1[index].Total_With_Tax,
    //     options:[]
    //   }
    // console.log("data",data);
    // accesories.push(data);
    // }

    this.setState(
      {
        ConditionCode: accesories,
        // AccessoriesOption: res,
        // MaxAccessoriesCount:res.length
      },
      () => {
        this.accessoriesSetup();
      }
    );
  };
  accessoriesSetup = () => {
    let newConditionArray = this.state.ConditionCode;
    let newAccessoriesOptions = this.state.AccessoriesOption;
    if (this.state.ConditionCode.length == 1) {
      for (let i = 0; i < this.state.ConditionCode.length; i++) {
        for (let j = 0; j < this.state.ConditionCode.length; j++) {
          newAccessoriesOptions = newAccessoriesOptions.filter(
            (e) => e.ID != newConditionArray[j].accid
          );
        }
      }
    } else {
      for (let i = 0; i < this.state.ConditionCode.length; i++) {
        for (let j = 0; j < this.state.ConditionCode.length; j++) {
          if (i !== j) {
            newConditionArray[i].options = newConditionArray[i].options.filter(
              (e) => e.ID != newConditionArray[j].accid
            );
            newAccessoriesOptions = newAccessoriesOptions.filter(
              (e) => e.ID != newConditionArray[j].accid
            );
          }
        }
      }
    }
    this.setState(
      {
        AccessoriesOption: newAccessoriesOptions,
      },
      () => {
        console.log(this.state.ConditionCode, "jkjkkj");
      }
    );
  };
  standard_accssories = () => {
    this.state.AccessoriesOption = [];
    const url = Urls.python_baseurl + "/GetAccessories_SP_GetAccessories";
    var data = JSON.stringify({
      Type: "'Standard'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newConditionArray = this.state.ConditionCode.map((e) => {
            return { ...e, options: res };
          });
          this.setState(
            {
              ConditionCode: newConditionArray,
              AccessoriesOption: res,
              MaxAccessoriesCount: res.length,
            },
            () => {
              this.state.newConditionArray_1 != undefined &&
                this.bindaccessories();
              {
                this.state.isSavedClick == "saveClcik" &&
                  this.displayBookingM3();
              }
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_district_new = () => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: this.state.State,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            leaddistrict: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getVisitDetails = () => {
    const url = Urls.python_baseurl + "/SP_Get_VisitDetails";
    var data = JSON.stringify({
      Inq_ID: this.state.inq_id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Lead_type = () => {
    const url = Urls.python_baseurl + "/sp_GetEnquiryCustType";
    var data = JSON.stringify({
      Type: "C",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            leadType: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Lead_no = () => {
    this.setState({
      saveLoader: true,
    });
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "M0",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // lead_no: res[0].DocMaxNo,
              // docno:
              docno:
                this.state.Dealer_Code +
                "" +
                "M0" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              this.maxNo();
              this.saveLead();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  // financiaryear_lead = () => {
  //   const url = Urls.python_baseurl + "/SP_GetDataFromDataQuery";
  //   var data = JSON.stringify({
  //     ValGetDataQueryType: "15",
  //     iParentId: sessionStorage.getItem("dealerid"),
  //     sAdditionalCondition: "''",
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState(
  //           {
  //             finacial_year: res[0].Value,
  //           },
  //           () => {
  //             this.delarcode_lead();
  //           }
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  // delarcode_lead = () => {
  //   const url = Urls.python_baseurl + "/Get_Dealer_SP_GET_Dealercode";
  //   var data = JSON.stringify({
  //     DealerID: sessionStorage.getItem("dealerid"),
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res) {

  //         this.setState({
  //           delar_code: res[0].Dealer_vehicle_Code,

  //           docno:
  //             res[0].Dealer_vehicle_Code +
  //             "" +
  //             "M0" +
  //             "" +
  //             this.state.finacial_year +
  //             "" +
  //             this.state.lead_no.toString().padStart(6, '0'),
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  binddata = () => {
    this.lead_region();
    this.lead_district();
  };
  lead_town = () => {
    const url = Urls.python_baseurl + "/Display_Tehsil_sp_tehsildisplay";
    var data = JSON.stringify({
      state_name: this.state.State,
      district_name: this.state.District,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            leadtown: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getInsurance = (ModelCodedata) => {
    const url = Urls.python_baseurl + "/getInsurance_SP_GetInsurance ";
    var data = JSON.stringify({
      ModelCode: `'${ModelCodedata}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            insuranceList: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_region = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: this.state.State,
      type: "Region",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            leadregion: res,
            Region: res[0].ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  reason_lost = () => {
    const url = Urls.python_baseurl + "/SP_Get_InqLossReason";
    var data = JSON.stringify({
      
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            reasonLost: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_district = () => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: `'${sessionStorage.getItem("Dealer_state")}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            leaddistrict: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_state = () => {
    const url = Urls.python_baseurl + "/SP_EGPDealer_FillDropdown";
    var data = JSON.stringify({
      id: `${this.state.State}`,
      type: "'State'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            leadstate: res,
          },()=>{
            this.ChangeVal({target:{
              value:res[0].ID
            }}, "State");
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getInsurance = (ModelCodedata) => {
    const url = Urls.python_baseurl + "/getInsurance_SP_GetInsurance ";
    var data = JSON.stringify({
      ModelCode: `'${ModelCodedata}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            insuranceList: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  // displayEnquirydata = () => {

  //   const url = Urls.python_baseurl + "/Display_Enquiry_SP_GetM1 ";
  //   var data = JSON.stringify({
  //     POId: this.state.id,
  //     POType: "'all'",
  //     DealerID: parseInt(sessionStorage.getItem("dealerid")),
  //     HOBrID: parseInt(sessionStorage.getItem("dealerid")),
  //     iM0ID: 1,
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState({
  //           // Remark: res[1][0].Remark,
  //           // endDate: res[1][0].next_date?res[1][0].next_date:"",
  //           // next_visit_details: res[1][0].next_obj_id,
  //           // startDate: res[1][0].obj_date,
  //           // visit_details: res[1][0].obj_id,

  //         },()=>{
  //         //  ;
  //         //    console.log("endDate", (moment((this.state.endDate)).format("DD-MM-YYYY")).toDate());
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return moment(`${day}/${month}/${year}`, "DD-MM-YYYY").format("DD-MM-YYYY");
  };
  Enquir_no = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "EN",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // En_no: res[0].DocMaxNo,
              enquiry_no:
                this.state.Dealer_Code +
                "" +
                "EN" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {}
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  save_Enquir_no = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "EN",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // En_no: res[0].DocMaxNo,
              enquiry_no:
                this.state.Dealer_Code +
                "" +
                "EN" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              this.saveEnquiry();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getmobiledata = (mobileno) => {
    const url =
      Urls.python_baseurl + "/get_customer_byMobile_sp_Customerwise_mobile";
    var data = JSON.stringify({
      mobile: `'${mobileno}'`,
      dealerid:`'${sessionStorage.getItem("dealerid")}'`
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0 && res[0].Result != "") {
          console.log("res657878", res);
          this.setState(
            {
              // Enquiry_date: res[0].Customer_name?res[0].Customer_name:this.state.Enquiry_date,
              Mobileno: res[0].Mobile ? res[0].Mobile : this.state.Mobileno,
              email: res[0].E_mail ? res[0].E_mail : this.state.email,
              Type: res[0].Cust_Type ? res[0].Cust_Type : this.state.Type,

              // Country:,
              Region: res[0].Region_id ? res[0].Region_id : this.state.Region,
              Town: res[0].tehsil ? parseInt(res[0].tehsil) : this.state.Town,
              District: res[0].District_ID
                ? res[0].District_ID
                : this.state.District,
              State: res[0].state_id ? res[0].state_id : this.state.State,
              City: res[0].city ? res[0].city : this.state.City,
              pincode: res[0].pincode ? res[0].pincode : this.state.pincode,
              address2: res[0].add2 ? res[0].add2 : this.state.address2,
              address1: res[0].add1 ? res[0].add1 : this.state.address1,
              Mobileno: res[0].mobile ? res[0].mobile : this.state.Mobileno,
              Name: res[0].Customer_name
                ? res[0].Customer_name
                : this.state.Name,
              Title: res[0].Title ? res[0].Title : this.state.Title,
            },
            () => {
              this.lead_region();
              this.lead_district_new();
              this.Lead_type();
              this.lead_town();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  get_PinCode_Details = () => {
    const url =
      Urls.python_baseurl + "/Pincode_Details";
    var data = JSON.stringify({
      Pincode: `${this.state.pincode}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0 && res[0].Result != "") {
          console.log("res657878", res);
          this.setState(
            {
              
              Town: res[0].Tehsil_ID ? parseInt(res[0].Tehsil_ID) : this.state.Town,
              District: res[0].District_ID
                ? res[0].District_ID
                : this.state.District,
              // State: res[0].state_id ? res[0].state_id : this.state.State,
              City: res[0].city ? res[0].city : this.state.City,
              
            },
            () => {
              this.lead_region();
              this.lead_district_new();
              this.Lead_type();
              this.lead_town();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  onChangeName(event, key) {
    this.setState({
      [key]: event.target.value,
    });
    if (key === "Mobileno" && event.target.value.length == 10) {
      this.getmobiledata(event.target.value);
    }
  }
  leadType = (event) => {
    let row = [];
    this.state.leadType.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  leadno = (event) => {
    let row = [];
    this.state.leadno.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.DocMaxNo}>{element.DocMaxNo}</option>);
    });
    return row;
  };
  leaddistrict = (event) => {
    let row = [];
    this.state.leaddistrict.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  leadtown12 = (event) => {
    let row = [];
    this.state.leadtown.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Town_Name}</option>);
    });
    return row;
  };
  leadregion = (event) => {
    let row = [];
    this.state.leadregion.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  GetSubsidy = () => {
    console.log(
      "this.state.AMV_Insurance_Amount",
      this.state.AMV_Insurance_Amount
    );
    //  if(this.state.AMV_Insurance_Amount==""){
    //   this.setState({AMV_Insurance_Amount:0})
    //  }
    const url = Urls.python_baseurl + "/getSubsidy_SP_GetSubsidy";
    var data = JSON.stringify({
      Type: `'${"State"}'`,
      state_id: parseInt(this.state.State),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          {
            let count = 1;
            let subam =
              this.state.kwh * res[1].State_Min > res[1]?.State_Max
                ? res[1]?.State_Max
                : this.state.kwh * res[1].State_Min &&
                  parseFloat(
                    this.state.kwh * res[1].State_Min > res[1]?.State_Max
                      ? res[1]?.State_Max * count
                      : this.state.kwh * res[1].State_Min * count
                  );
            let Centerdata =
              this.state.Ex_ShowRoom_Price *
                (res[0]?.Central_Percentage / 100) &&
              parseFloat(
                this.state.Ex_ShowRoom_Price *
                  (res[0]?.Central_Percentage / 100)
              );
            let statetaxamt =
              this.state.Ex_ShowRoom_Price -
              this.state.Scheme_Amount-
              subam -
              Centerdata -
              (this.state.AMV_Dealer_Proposed_Amount
                ? parseFloat(this.state.AMV_Dealer_Proposed_Amount)
                : 0);
            this.setState(
              {
                AMV_Subsidy_Amount: subam,
                AMV_Central_Subsidy_Amount: Centerdata,
                Taxable_Amount: statetaxamt,
                AMV_RTO_Amount:
                  this.state.model_typecode === "L"
                    ? 0
                    : ((this.state.Ex_ShowRoom_Price != null
                        ? parseFloat(this.state.Ex_ShowRoom_Price)
                        : 0) *
                        (this.state.AMV_RTO_Per != null
                          ? this.state.AMV_RTO_Per
                          : 0)) /
                      100,
              },
              () => {
                this.setState(
                  {
                    AMV_RTO_Discount_Amount:
                      this.state.model_typecode === "L"
                        ? 0
                        : (parseFloat(this.state.AMV_RTO_Amount) *
                            this.state.AMV_RTO_Discount) /
                          100,
                    grand_total:
                      (((this.state.AMV_CRTM_Amount != ""&&this.state.AMV_CRTM_Amount != null)
                        ? parseFloat(this.state.AMV_CRTM_Amount)
                        : 0) +
                        ((this.state.AMV_HPA_Charges != ""&& this.state.AMV_HPA_Charges != null)
                          ? parseFloat(this.state.AMV_HPA_Charges)
                          : 0) +
                        ((this.state.AMV_Smart_Card_Amount != ""&&this.state.AMV_Smart_Card_Amount != null)
                          ? parseFloat(this.state.AMV_Smart_Card_Amount)
                          : 0) +
                        ((this.state.Other_RTO_Charges != ""&&this.state.Other_RTO_Charges != null)
                          ? parseFloat(this.state.Other_RTO_Charges)
                          : 0) +
                        ((this.state.AMV_HSRP_Charges != ""&&this.state.AMV_HSRP_Charges != null)
                          ? parseFloat(this.state.AMV_HSRP_Charges)
                          : 0) +
                        (this.state.AMV_RTO_Amount != null
                          ? parseFloat(this.state.AMV_RTO_Amount)
                          : 0) +
                        (this.state.Total_Accessories_Amount!=undefined&&this.state.Total_Accessories_Amount!=null&&this.state.Total_Accessories_Amount!=""
                          ? parseFloat(this.state.Total_Accessories_Amount)
                          : 0) +
                        (this.state.AMV_Insurance_Amount
                          ? parseFloat(this.state.AMV_Insurance_Amount)
                          : 0) +
                        (this.state.Taxable_Amount != null
                          ? parseFloat(this.state.Taxable_Amount)
                          : 0) -
                        (this.state.model_typecode === "L"
                          ? 0
                          : ((this.state.AMV_RTO_Amount != null
                              ? parseFloat(this.state.AMV_RTO_Amount)
                              : 0) *
                              (this.state.AMV_RTO_Discount != null
                                ? parseFloat(this.state.AMV_RTO_Discount)
                                : 0)) /
                            100)) *
                      this.state.Quantity,
                  },
                  () => {
                    this.setState({
                      Quantity_Amount:
                        this.state.grand_total / this.state.Quantity,
                    });
                  }
                );
              }
            );
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getmodelMRP = (modelId) => {
    const url = Urls.python_baseurl + "/getModelMrp_GetModdelMRP";
    var data = JSON.stringify({
      Dealer: sessionStorage.getItem("dealerid"),
      ModelID: parseInt(modelId),
      StateCode: `${this.state.State ? this.state.State : this.state.stateid}`,

      // ValComboQueryType: 197,
      // iParentId: 0,
      // sAdditionalCondition: `'and Model_cat_ID=${event.target.value}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Ex_ShowRoom_Price: res[0][0]?.ex_showroomprice,
              ModelData: res[0][0],
              AMV_Sale_Rate: res[0][0].MRP,
              AMV_CRTM_Amount: this.state.AMV_CRTM_Amount
                ? this.state.AMV_CRTM_Amount
                : res[2][0].crtm_Amt,
              AMV_HPA_Charges: this.state.AMV_HPA_Charges
                ? this.state.AMV_HPA_Charges
                : res[2][0].HPA_Charge,
              AMV_Smart_Card_Amount: this.state.AMV_Smart_Card_Amount
                ? this.state.AMV_Smart_Card_Amount
                : res[2][0].smartcard_Amt,
              Model: res[1][0]["Model code"],
              Model_Name: res[1][0]["Model name"],
              AMV_Model_Colour: res[1][0].Model_Colour,
              kwh: res[1][0]?.kw ? res[1][0].kw : 1,
              AMV_RTO_Per: res[2][0]?.Percentage,
              Total: res[0][0].MRP + res[0][0].HPA_Charge,
              TotalCopy: res[0][0].MRP + res[0][0].HPA_Charge,
              AMV_HSRP_Charges: this.state.AMV_HSRP_Charges
                ? this.state.AMV_HSRP_Charges
                : res[2][0]?.HSRP_Charge,
              Other_RTO_Charges: this.state.Other_RTO_Charges
                ? this.state.Other_RTO_Charges
                : res[2][0]?.other_rto,
              AMV_RTO_Discount:
                this.state.model_typecode === "L" ? 0 : res[2][0]?.discount_per,
              AMV_RTO_Amount:
                this.state.model_typecode === "L"
                  ? 0
                  : parseFloat(res[0][0]?.ex_showroomprice) *
                    (res[2][0]?.Percentage / 100),
              AMV_RTO_Discount_Amount:
                this.state.model_typecode === "L"
                  ? 0
                  : parseFloat(
                      parseFloat(res[0][0]?.ex_showroomprice) *
                        (res[2][0]?.Percentage / 100) *
                        (res[2][0]?.discount_per / 100)
                    ).toFixed(2),
              Scheme_Name:this.state.Enquiry_Status !== "Open" && this.state.Enquiry_Status !== "" ?this.state.Scheme_Name:res[4][0].Scheme_name,
              Scheme_Amount:this.state.Enquiry_Status !== "Open" && this.state.Enquiry_Status !== ""?this.state.Scheme_Amount:res[4][0].Amount,
              Scheme_Id:this.state.Enquiry_Status !== "Open" && this.state.Enquiry_Status !== ""?this.state.Scheme_Id:res[4][0].ID,
              Scheme_Start_Date:this.state.Enquiry_Status !== "Open" && this.state.Enquiry_Status !== ""?this.state.Scheme_Start_Date:res[4][0].start_date,
              Scheme_End_Date:this.state.Enquiry_Status !== "Open" && this.state.Enquiry_Status !== ""?this.state.Scheme_End_Date:res[4][0].End_date,
              Is_Insurance:this.state.Enquiry_Status !== "Open" && this.state.Enquiry_Status !== ""?this.state.Is_Insurance:res[4][0].Is_Insurance?res[4][0].Is_Insurance:""
              
            },
            () => {
              this.GetSubsidy(this.state.Subsidy_Type);
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  ChangeVal(event, key) {
    console.log("dssd", event.target.value, key);
    this.setState(
      {
        [key]: event.target.value,
      },
      () => {
        if (key !== "ModelCategory" && key !== "State") {
          this.GetSubsidy();
        }
      }
    );
    if (key == "AMV_Insurance_Amount") {
      this.GetSubsidy();
    }
    if (key == "ModelCategory") {
      this.setState({
        Model_Name:"",
        grand_total: "",
        Other_RTO_Charges: "",
        AMV_HSRP_Charges: "",
        AMV_Smart_Card_Amount: "",
        AMV_HPA_Charges: "",
        AMV_CRTM_Amount: "",
        AMV_RTO_Discount_Amount: "",
        AMV_RTO_Discount: "",
        AMV_RTO_Amount: "",
        Taxable_Amount: "",
        AMV_Subsidy_Amount: "",
        Quantity: 1,
        AMV_Central_Subsidy_Amount: "",
        // Subsidy_Type:"",
        AMV_Dealer_Proposed_Amount: "",
        Quantity_Amount: "",
        Ex_ShowRoom_Price: "",
        AMV_Model_Colour: "",
        Model: "",
        ModelCode: "",
        Scheme_Amount:"",
        Scheme_Name:"",
        Scheme_Id:"",
        Scheme_Start_Date:"",
        Scheme_End_Date:"",
        AMV_Insurance_Amount:0,
        AMV_Insurance_Year:"",
        Insurance_company:""
      });
    }
    if (key === "Quantity") {
      this.GetSubsidy();
    }
    if (key=="Model_test" || key == "ModelCategory") {
      
      const url =
        Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
      var data = JSON.stringify({
        ModelGroup: parseInt(event.target.value),
        Active:'N'
      });
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.length > 0) {
            this.setState({
              Model_Code_test: res,
              model_typecode: res[0].Type,
            });
          }
        })
        .catch((err) => {
          process.env.NODE_ENV == "development" && console.log(err);
        });
    }
    if (key == "ModelCategory" ) {
      this.setState({
        Model_test:event.target.value
      })
      const url =
        Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
      var data = JSON.stringify({
        ModelGroup: parseInt(event.target.value),
        Active:'N'
      });
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.length > 0) {
            this.setState({
              Model_Code: res,
              model_typecode: res[0].Type,
            });
          }
        })
        .catch((err) => {
          process.env.NODE_ENV == "development" && console.log(err);
        });
    }
    if (key === "State") {
      for (let index = 0; index < this.state.leadstate.length; index++) {
        if (this.state.leadstate[index].ID === parseInt(event.target.value)) {
          // this.setState(
          //   {
          //     grand_total: "",
          //     Other_RTO_Charges: "",
          //     AMV_HSRP_Charges: "",
          //     AMV_Smart_Card_Amount: "",
          //     AMV_HPA_Charges: "",
          //     AMV_CRTM_Amount: "",
          //     AMV_RTO_Discount_Amount: "",
          //     AMV_RTO_Discount: "",
          //     AMV_RTO_Amount: "",
          //     Taxable_Amount: "",
          //     Quantity: 1,
          //     AMV_Central_Subsidy_Amount: "",
          //     AMV_Subsidy_Amount: "",
          //     AMV_Dealer_Proposed_Amount: "",
          //     Quantity_Amount: "",
          //     Ex_ShowRoom_Price: "",
          //     AMV_Model_Colour: "",
          //     Model: "",
          //     ModelCode: "",
          //     Model_Name: "",
          //     ModelCategory: "",
          //     Town: "",
          //     City: "",
          //     pincode: "",
          //     stateid: this.state.leadstate[index].Name,
          //     AMV_Insurance_Amount: "",
          //     AMV_Insurance_Year: 0,
          //     Insurance_company: 0,
          //     Total_Accessories_Amount: "",
          //   },
          //   () => {
          //     this.binddata();
          //   }
          // );
          this.binddata();
        }
      }
      this.setState(
        {
          ConditionCode: [
            {
              id: 1,
              accid: "",
              accessories_name: "",
              accessories_amount: "",
              hsn: "",
              igst: "",
              igst_amount: "",
              sgst: "",
              sgst_amount: "",
              cgst: "",
              cgst_amount: "",
              accessories_total_withtax: "",
              options: [],
            },
          ],
        },
        () => {
          this.standard_accssories();
        }
      );
    }
    if (key == "Town"){
      this.setState({City:"",pincode:""})
    }
    if (key == "District") {
      this.setState({Town:"",City:"",pincode:""})
      for (let index = 0; index < this.state.leaddistrict.length; index++) {
        if (
          this.state.leaddistrict[index].ID === parseInt(event.target.value)
        ) {
          this.setState(
            {
              district_name: this.state.leaddistrict[index].Name,
            },
            () => {
              this.lead_town();
            }
          );
        }
      }
    }
    if (key == "ModelCode") {
      this.setState({
        ModelCode_test:event.target.value
      })
      let ModelCodedata = "";
      for (let index = 0; index < this.state.Model_Code.length; index++) {
        if (this.state.Model_Code[index].ID === parseInt(event.target.value)) {
          ModelCodedata = this.state.Model_Code[index]["Model code"];
        }
      }
      this.getInsurance(ModelCodedata);
      this.getmodelMRP(event.target.value);
    }
    if (key == "AMV_Model_Group") {
      const url = Urls.python_baseurl + "/SP_GetDataForCombo";
      var data = JSON.stringify({
        type: 198,
        ID: 0,
        condition: `'and Model_cat_ID=${event.target.value}'`,
      });
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.length > 0) {
            this.setState({
              Model_Code: res,
            });
          }
        })
        .catch((err) => {
          process.env.NODE_ENV == "development" && console.log(err);
        });
    }
    if (key == "AMV_Dealer_Proposed_Amount") {
      this.GetSubsidy();
    }
    if (
      key == "AMV_HSRP_Charges" ||
      key == "Other_RTO_Charges" ||
      key == "AMV_Smart_Card_Amount" ||
      key == "AMV_HPA_Charges" ||
      key == "AMV_CRTM_Amount"
    ) {
      this.GetSubsidy();
    }
  }
  handleStartChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  onSelect = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  };
  handledateChange = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  };
  handlecall_ticket_dateChange = (date) => {
    this.setState(
      {
        call_ticket_date: date,
      },
      () => {}
    );
  };
  GetSourceList = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 154,
      ID: 0,
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            SourceListOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetAreaList = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 152,
      ID: sessionStorage.getItem("dealerid"),
      condition: `'and HOBrID=${sessionStorage.getItem("dealerid")}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            AreaListOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetPOType = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 171,
      ID: 0,
      condition: "'and ID in (1,2)'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            PO_Type_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCode_test = () => {
    const url =
      Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
    var data = JSON.stringify({
      ModelGroup: parseInt(this.state.Model_test),
      Active:'N'
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              
              Model_Code_test:res
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCode = () => {
    const url =
      Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
    var data = JSON.stringify({
      ModelGroup: parseInt(this.state.ModelCategory),
      Active:'N'
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Model_Code: res,
            },
            () => {
              console.log(this.state.Model_Code, "jkkjjkjk");
              let ModelCodedata = "";
              for (
                let index = 0;
                index < this.state.Model_Code.length;
                index++
              ) {
                if (
                  this.state.Model_Code[index].ID ===
                  parseInt(this.state.ModelCode)
                ) {
                  ModelCodedata = this.state.Model_Code[index]["Model code"];
                }
              }
              this.getInsurance(ModelCodedata);
              this.getmodelMRP(parseInt(this.state.ModelCode));
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/ModelGroup_GetModelGroup";
    var data = JSON.stringify({
      id: "null",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Category: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModel = (event) => {
    let row = [];
    this.state.Model_Code.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  GetAllocatedby = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 153,
      ID: sessionStorage.getItem("dealerid"),
      condition: `'and HOBrID=${sessionStorage.getItem("dealerid")}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Allocated_By: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetAllocated_To = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 153,
      ID: sessionStorage.getItem("dealerid"),
      condition: `'and HOBrID=${sessionStorage.getItem(
        "dealerid"
      )} and Empl_Type=11'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            AllocatedTo: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModel_1 = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 197,
      ID: 0,
      condition: `'and Model_cat_ID=${this.state.ModelCategory}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model: parseInt(this.state.Model),
            ModelData: res,
            Model_Data_1: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetCategory = (event) => {
    let row = [];
    this.state.Model_Category.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element["Model Group"]}</option>);
    });
    return row;
  };
  GetAllocat = (event) => {
    let row = [];
    this.state.Allocated_By.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  GetAllocate_to = (event) => {
    let row = [];
    this.state.AllocatedTo.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  GetModelList = (event) => {
    let row = [];
    this.state.ModelData.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  visit_details = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "159",
      ID: "'0'",
      condition: "\"and stage='M0'\"",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            vistitdetails: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  vistitdetails = (event) => {
    let row = [];
    this.state.vistitdetails.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  GetSource = (event) => {
    let row = [];
    this.state.SourceListOption.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  GetArea = (event) => {
    let row = [];
    this.state.AreaListOption.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  GetPOT = (event) => {
    let row = [];
    this.state.PO_Type_Option.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  leadstate = (event) => {
    let row = [];
    this.state.leadstate.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  visitDetails = () => {
    return (
      <>
        <div>
          <div class="row">
            <div className="form-group col-md-3">
              <label htmlFor="visit-details">
                Follow-up Details {this.required()}
              </label>
              <select
                className="form-control"
                value={this.state.visit_details}
                onChange={(event) => {
                  this.onSelect(event, "visit_details");
                }}
              >
                <option value="0">Select</option>
                {this.vistitdetails()}
              </select>
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="date">Date {this.required()}</label>
              <input
                type="date"
                className="form-control"
                placeholder=""
                value={this.state.startDate}
                onChange={(event) => {
                  this.handledateChange(event, "startDate");
                }}
                min={new Date().toISOString().split("T")[0]}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="new-visit-details">
                Next Follow-up Scheduled {this.required()}
              </label>
              <select
                className="form-control"
                value={this.state.next_visit_details}
                onChange={(event) => {
                  this.onSelect(event, "next_visit_details");
                }}
              >
                <option value="0">Select</option>
                {this.vistitdetails()}
              </select>
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="date2">Next Follow-up Date {this.required()}</label>
              <input
                type="date"
                className="form-control"
                placeholder=""
                value={this.state.endDate}
                onChange={(event) => {
                  this.handledateChange(event, "endDate");
                }}
                min={
                  (this.state.startDate!=""||this.state.startDate!=null)
                    ? new Date(this.state.startDate).toISOString().split("T")[0]
                    : new Date().toISOString().split("T")[0]
                }
              />
            </div>
          </div>
          <div class="row">
            <div className="form-group col-md-3">
              <label htmlFor="visit-details">Remark</label>
              <textarea
                cols={4}
                value={this.state.Remark}
                onChange={(e) => this.onChangeName(e, "Remark")}
                class="form-control"
                placeholder=""
              ></textarea>
            </div>
          </div>
        </div>
        <div className=" mt-3">{this.visitDetailsTable()}</div>
      </>
    );
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  onChangeMobileNumber = (e) => {
    const { value } = e.target;
    // Regular expression to allow only numbers
    const mobileNumber = value.replace(/\D/g, "").slice(0, 10); // Limit to 10 characters
    this.setState({ Mobileno: mobileNumber }, () => {
      if (this.state.Mobileno != "") {
        this.getmobiledata(mobileNumber);
      }
    });
  };
  ChangeVal_1(event, key) {
    const { value } = event.target;
    if (key === "pincode") {
      const pincode = value.replace(/\D/g, "").slice(0, 6);
      this.setState({ pincode: pincode },()=>{
        this.get_PinCode_Details()
      });
    }
    if(key === "Rating"){
      const Rating = value.replace(/\D/g, "").slice(0, 1);
      this.setState({ Rating: Rating });
    }
  }
  lostenquiry=()=>{
    this.Validate_cancel()
    // $("#enquirylost_Popup").modal("show");
  }
  handelFileDisplay = (id) => {

    console.log("uyyy",this.state.document_Path);

    if (this.state.document_Path) {
      fetch(this.state.document_Path).then((res) => {
        console.log("🚀 ~ fetch ~ res:", res);
        if (res.status === 200) {
          this.state.temparray.push(res.url);
          let data = [{ uri: res.url }];
          
          this.setState(
            {
              newurl: data,
              
            },
            () => {
              const link = document.createElement("a");
              link.href = this.state.document_Path;
              link.setAttribute("download", `${this.state.Document_Name}.xlsx`);
              document.body.appendChild(link);

              link.click();
              document.body.removeChild(link);
              console.log("ngfgg", this.state.newurl);

              $("#ExcelPopUP_3").modal("show");
            }
          );
          // setFile([{ uri: res.url }]);
        } else {
          //  setError(true)
        }
      });
      
    }
  };
  ExcelPOPUP = () => {
    console.log(this.state.newurl, "hjjhhj");
    return (
      <div
        style={{
          backgroundColor: "rgba(33, 37, 41, 0.8)",
        }}
        class="modal"
        id="ExcelPopUP_3"
      >
        <div class="modal-dialog modal-xl_43">
          <div
            class="modal-content"
            style={{ width: "1000px", marginLeft: "-250px" }}
          >
            <div class="modal-header">
              <button
                type="button"
                class="close"
                onClick={() => {
                  this.setState({
                    newurl:[]
                  })
                  $("#ExcelPopUP_3").modal("hide");
                  $("#payment_error_modal_2").modal("hide");
                  $("#payment_error_modal_3").modal("hide");
                  $("#payment_error_modal_3").modal("show");
                  $("#payment_error_modal_2").modal("show"); 
                }
                 
                }
              >
                ×
              </button>
            </div>
            <br></br>
            <div class="container"></div>
            <div className="container_file">
              <DocViewer
                documents={this.state.newurl}
                pluginRenderers={DocViewerRenderers}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderMemberinfo() {
    return (
      <div>
        <div>
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Customer Information
                </button>
                <label
                  style={{
                    fontSize: "12px",
                    float: "right",
                    marginTop: "13px",
                    fontWeight: "700",
                  }}
                >
                  Note-:Please Enter the Mobile No.for Searching Customer
                </label>
              </h2>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label>
                      Enquiry No.<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      disabled={true}
                      value={this.state.enquiry_no}
                      onChange={(e) => this.onChangeName(e, "enquiry_no")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      Enquiry Date<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control"
                      value={this.state.Enquiry_date}
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Customer Name{this.required()}</label>
                    <input
                      value={this.state.Name}
                      onChange={(e) => this.onChangeName(e, "Name")}
                      class="form-control"
                      placeholder=""
                      // pattern="[A-Za-z]+"
                      onKeyPress={(e) => {
                        const pattern = /[A-Za-z\s]/; // Regular expression pattern for alphabetic characters
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault(); // Prevent input if it's not alphabetic
                        }
                      }}
                    ></input>
                  </div>

                  <div class="form-group col-md-3">
                    <label>Mobile {this.required()}</label>
                    <input
                      type="phone"
                      value={this.state.Mobileno}
                      onChange={(e) => this.onChangeMobileNumber(e, "Mobileno")}
                      // onChange={(e) => this.onChangeName(e, "Mobileno")}
                      // maxLength={10}
                      class="form-control"
                    ></input>
                  </div>

                  <div class="form-group col-md-3">
                    <label>
                      Source<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control "
                      value={this.state.Source}
                      onChange={(event) => this.ChangeVal(event, "Source")}
                    >
                      <option value="0">Select</option>
                      {this.GetSource()}
                    </select>
                  </div>
                  {/* <div class="form-group col-md-3">
                    <label>Doc No </label>
                    <input
                      disabled={true}
                      value={this.state.docno}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  
                  <div class="form-group col-md-3">
                    <label>Doc Date {this.required()}</label>
                    <input
                      disabled={true}
                      className="form-control"
                      value={
                        this.state.Doc_Date
                          ? this.state.Doc_Date
                          : moment(new Date()).format("DD/MM/YYYY")
                      }
                    />
                  </div> */}
                  <div class="form-group col-md-3">
                    <label>Type{this.required()}</label>
                    <select
                      className="form-control "
                      value={this.state.Type}
                      onChange={(event) => this.ChangeVal(event, "Type")}
                    >
                      <option value="0">Select</option>
                      {this.leadType()}
                    </select>
                  </div>
                  {/* <div class="form-group col-md-3">
                    <label>Title{this.required()}</label>
                    <select
                      className="form-control "
                      value={this.state.Title}
                      onChange={(event) => this.ChangeVal(event, "Title")}
                    >
                      <option value="0"></option>
                      <option value="1">Mr</option>
                      <option value="2">Miss</option>
                    </select>
                  </div> */}

                  <div class="form-group col-md-3">
                    <label>Address 1{this.required()}</label>
                    <input
                      value={this.state.address1}
                      onChange={(e) => this.onChangeName(e, "address1")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Address 2</label>
                    <input
                      value={this.state.address2}
                      onChange={(e) => this.onChangeName(e, "address2")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>

                  <div class="form-group col-md-3">
                    <label>State{this.required()}</label>
                    <select
                      className="form-control "
                      value={this.state.State}
                      onChange={(event) => {
                        this.ChangeVal(event, "State");
                      }}
                    >
                      {this.leadstate()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>District{this.required()}</label>
                    <select
                      className="form-control "
                      value={this.state.District}
                      onChange={(event) => this.ChangeVal(event, "District")}
                    >
                      <option value="0">Select</option>
                      {this.leaddistrict()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Tehsil</label>
                    <select
                      className="form-control "
                      value={this.state.Town}
                      onChange={(event) => this.ChangeVal(event, "Town")}
                    >
                      <option value="0">Select</option>
                      {this.leadtown12()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>City{this.required()}</label>
                    <input
                      value={this.state.City}
                      onChange={(e) => this.onChangeName(e, "City")}
                      class="form-control"
                      placeholder=""
                      onKeyPress={(e) => {
                        const pattern = /[A-Za-z\s]/; // Regular expression pattern for alphabetic characters
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault(); // Prevent input if it's not alphabetic
                        }
                      }}
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Pincode{this.required()}</label>
                    <input
                      value={this.state.pincode}
                      onChange={(event) => this.ChangeVal_1(event, "pincode")}
                      class="form-control"
                      placeholder=""
                      max={6}
                    ></input>
                  </div>

                  <div class="form-group col-md-3">
                    <label>Region</label>
                    <select
                      className="form-control "
                      value={this.state.Region}
                      onChange={(event) => this.ChangeVal(event, "Region")}
                    >
                      <option value="0">Select</option>
                      {this.leadregion()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Country </label>
                    <input
                      disabled={true}
                      value={"INDIA"}
                      onChange={(e) => this.onChangeName(e, "Country")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Email</label>
                    <input
                      value={this.state.email}
                      onChange={(e) => this.onChangeName(e, "email")}
                      class="form-control"
                      placeholder=""
                      type="email"
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      Attended By<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control "
                      value={this.state.Attended_By}
                      onChange={(event) => this.ChangeVal(event, "Attended_By")}
                    >
                      <option value="0">Select</option>
                      {this.GetAllocat()}
                    </select>
                  </div>
                  {/* <div class="form-group col-md-3">
                    <label>
                      Allocated To<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control "
                      value={this.state.Allocated_To}
                      onChange={(event) =>
                        this.ChangeVal(event, "Allocated_To")
                      }
                    >
                      <option value="0">select</option>
                      {this.GetAllocate_to()}
                    </select>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
         
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Model Details
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div
                  style={{ marginTop: "-10px" }}
                  className="row justify-content-end"
                >
                  <button
                    disabled={
                      this.state.State && this.state.State != 0 ? false : true
                    }
                    onClick={() => {
                      $("#myModal2").modal("show");
                    }}
                    className="btn Opertion_btn clickable mr-2"
                  >
                    Add Model Variant
                  </button>
                </div>
                <div class="form-row mt-2">
                  <div class="form-group col-md-3">
                    <label>
                      Model<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      disabled
                      className="form-control "
                      value={this.state.ModelCategory}
                      onChange={(event) =>
                        this.ChangeVal(event, "ModelCategory")
                      }
                    >
                      <option value="0">Select</option>
                      {this.GetCategory()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      Model Code<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      disabled
                      className="form-control "
                      value={this.state.ModelCode}
                      onChange={(event) => this.ChangeVal(event, "ModelCode")}
                    >
                      <option value="0">Select</option>
                      {this.state.Model_Code.map((e) => {
                        return <option value={e.ID}>{e["Model code"]}</option>;
                      })}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      Model Name<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      disabled
                      className="form-control "
                      value={this.state.Model_Name}
                      onChange={(event) => this.ChangeVal(event, "Model")}
                    >
                      {/* <option value="0">---Select---</option>
                      {this.GetModelList()} */}
                    </input>
                  </div>

                  <div class="form-group col-md-3">
                    <label>
                      Quantity<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      disabled
                      value={this.state.Quantity}
                      onChange={(e) => this.onChangeName(e, "Quantity")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      Total Amount<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      disabled
                      value={
                        this.state.grand_total
                          ? this.state.grand_total.toFixed(2)
                          : 0
                      }
                      onChange={(e) => this.onChangeName(e, "Total")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingSix">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="true"
                  aria-controls="collapseSix"
                >
                  
                  Test Drive Request
                  Process (TDRP)
                </button>
              </h2>
            </div>
            <div
              id="collapseSix"
              class="collapse"
              aria-labelledby="headingSix"
              data-parent="#accordionExample"
            >
              <div class="card-body">
               
                <div class="form-row mt-2">
               
                  <div className="form-group col-md-3">
                    <label htmlFor="model_group">Model</label><span style={{ color: "red" }}> *</span>
                    <select
                      value={this.state.Model_test}
                      onChange={(e) => this.ChangeVal(e, "Model_test")}
                      id="model_group"
                      className=" form-control "
                    >
                      <option value="0">Select</option>
                      {this.GetCategory()}
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                      Model Code
                    </label><span style={{ color: "red" }}> *</span>
                    <select
                      
                      className="form-control "
                      value={this.state.ModelCode_test}
                      onChange={(event) => this.ChangeVal(event, "ModelCode_test")}
                    >
                      <option value="0">Select</option>
                      {this.state.Model_Code_test.length>0&&this.state.Model_Code_test.map((e) => {
                        return <option value={e.ID}>{e["Model code"]}</option>;
                      })}
                    </select>
                  </div>
                  
                  <div class="form-group col-md-3">
                    <label>
                    Helmet Provided
                    </label><span style={{ color: "red" }}> *</span>
                    <select
                      
                      className="form-control "
                      value={this.state.Helmet}
                      onChange={(event) => this.ChangeVal(event, "Helmet")}
                    >
                      <option value="0">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                    Allocated Vehicle for Test Ride Ready
                    </label>
                    <select
                      
                      className="form-control "
                      value={this.state.Allocated_Vehicle}
                      onChange={(event) => this.ChangeVal(event, "Allocated_Vehicle")}
                    >
                      <option value="0">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                    Staff Accompanying for Test Ride
                    </label>
                    <select
                      
                      className="form-control "
                      value={this.state.Staff_accompanying}
                      onChange={(event) => this.ChangeVal(event, "Staff_accompanying")}
                    >
                      <option value="0">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label>
                    License No.
                    </label>
                    <input
                      
                      value={this.state.License_number}
                      onChange={(e) => this.onChangeName(e, "License_number")}
                      class="form-control"
                      placeholder=""
                    ></input>
                  </div>
                  <div class="form-group col-md-3">
                  <label>
                    Upload Document
                    </label>
                    <input
                      style={{
                        marginLeft: "2px",
                        width: "185px",
                        marginTop: "5px",
                      }}
                      key={this.state.theInputKey || ""}
                      type="file"
                      // accept=".csv"
                      onChange={(e) =>
                        this.setState({ selectedFile: e.target.files })
                      }
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label style={{marginLeft:"0px",marginTop:"35px",color: "var(--main-bg-color)", cursor: "pointer"}} onClick={() => this.handelFileDisplay()}>{this.state.Document_Name?this.state.Document_Name:""}</label>
                  </div>
                </div>
                <div class="form-row mt-2">
                  <h5>Customer Feedback</h5>
                  </div>
                  <div class="form-row mt-2">
                  <div class="form-group col-md-6">
                    <label>
                    Did you get test drive on the Model of your choice
                    </label>
                    </div>
                    <div class="form-group col-md-2">
                    <select
                      
                      className="form-control "
                      value={this.state.Model_choice}
                      onChange={(event) => this.ChangeVal(event, "Model_choice")}
                    >
                      <option value="0">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label>
                    Was the condition of the vehicles in which you got test drive good
                    </label>
                    </div>
                    <div class="form-group col-md-2">
                    <select
                      
                      className="form-control "
                      value={this.state.vehicles_Condition}
                      onChange={(event) => this.ChangeVal(event, "vehicles_Condition")}
                    >
                      <option value="0">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label>
                    Was the duration of the test drive sufficient
                    </label>
                    </div>
                    <div class="form-group col-md-2">
                    <select
                      
                      className="form-control "
                      value={this.state.duration_test_drive}
                      onChange={(event) => this.ChangeVal(event, "duration_test_drive")}
                    >
                      <option value="0">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label>
                    Were the explanation given by the salesman to your satisfaction during the test drive
                    </label>
                    </div>
                    <div class="form-group col-md-2">
                    <select
                      
                      className="form-control "
                      value={this.state.explanation}
                      onChange={(event) => this.ChangeVal(event, "explanation")}
                    >
                      <option value="0">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label>
                      <b>Overall feedback/ Suggestion</b> Rating<b> (01 Lowest & 05 Highest)</b> 
                    </label>
                    </div>
                    <div class="form-group col-md-2">
                    <input
                      //type="number"
                      value={this.state.Rating}
                      onChange={(event) => {
                        let number = event.target.value;
                        console.log( number > 0 && number<6,"trtrtr");
                        if (number > 0 && number<6) {
                          this.ChangeVal_1(event, "Rating");
                        } else {
                          this.setState({ Rating: "" });
                        }
                      }}
                     // onChange={(event) => this.ChangeVal_1(event, "Rating")}
                      class="form-control"
                      placeholder=""
                      min="1" max="5"
                    ></input>
                  </div>
                  <div class="form-group col-md-6">
                    <label>
                      Remark
                    </label>
                    <textarea
                      rows={3}
                      onChange={(e) => this.setState({ remark: e.target.value })}
                      value={this.state.remark}
                      className=" form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  {/* <button style={{backgroundColor:"white",fontSize:"16px"}} class="btn1 btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> */}
                  Follow-up Details
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div class="card-body">{this.visitDetails()}</div>
            </div>
          </div>
          {this.state.lead_id != ""?
          <div class="card">
            <div class="card-header" id="headingFour">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  Enquiry Cancel
                </button>
              </h2>
            </div>
            <div
              id="collapseFour"
              class="collapse"
              aria-labelledby="headingFour"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div class="form-row mt-2">
                  <div class="form-group col-md-3">
                  <label htmlFor="Cancel_Remark">Reason for Cancelation {this.required()}</label>
            <select
              value={this.state.Cancel_Remark}
              onChange={(e) => this.ChangeVal(e, "Cancel_Remark")}
              id="Cancel_Remark"
              className=" form-control "
            >
              <option value="0">Select</option>
              {this.state.reasonLost.map((e) => {
                return <option value={e.ID}>{e.reason}</option>;
              })}
            </select>
                    {/* <label>
                      Remark <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      
                      value={this.state.Cancel_Remark}
                      onChange={(e) => this.onChangeName(e, "Cancel_Remark")}
                      class="form-control"
                      placeholder=""
                    ></input> */}
                  </div>
                  <div class="form-group col-md-3">
                    {/* {this.state.enq_confirm != "N" && this.state.enq_cancel != "N" && this.state.Is_booking != "N"  && this.state.M1lost != "N"  && this.state.M1LostConfirm != "N"  ? (
                      ""
                    ) : ( */}
                    {this.state.enq_confirm == "N" && this.state.enq_cancel == "N" && this.state.Is_booking == "N"  && this.state.M1lost == "N"  && this.state.M1LostConfirm == "N"?
                      <button
                        disabled={this.state.isSaveClicked}
                        // hidden={this.state.enq_confirm == "Y" && this.state.enq_cancel == "Y" && this.state.Is_booking == "Y" && this.state.M1lost == "Y" && this.state.M1LostConfirm == "Y"}
                        type="submit"
                        className={"btn Opertion_btn clickable"}
                        onClick={() => { this.lostenquiry() }}
                        style={{ marginTop: "25px" }}
                      >
                        {this.state.saveLoader && (
                          <span
                            class="spinner-grow spinner-grow-sm"
                            style={{ color: "unset", marginRight: "5px" }}
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                        Cancel
                      </button>:null}
                    {/* )} */}

                  </div>
                </div>
              </div>
            </div>
          </div>:null}
        </div>
      </div>
    );
  }
  handleInputChange = (id, event) => {
    const { name, value } = event.target;

    // Update the quantity for the corresponding item
  };
  clickNavigation = (event) => {
    this.props.history.push("/Enquiry", {});
  };
  saveEnquiry = (event) => {
    const url = Urls.python_baseurl + "/Save_Enquery_SP_Save_M1Header";
    var data = JSON.stringify({
      iID: this.state.M1_id ? this.state.M1_id : 0,
      iDealerID: parseInt(sessionStorage.getItem("dealerid")),
      iHOBrId: parseInt(sessionStorage.getItem("dealerid")),
      LeadNo: `'${this.state.enquiry_no}'`,
      LeadDate: `'${this.state.Enquiry_date}'`,
      Inqsource: parseInt(this.state.Source),
      Area: 0,
      AttendedBy: parseInt(this.state.Attended_By),
      AllocatedTo: parseInt(0),
      Route_ID: 0,
      Cust_Type_ID: 0,
      Drive_ID: 0,
      Load_type_ID: 0,
      Finac_Type_ID: 0,
      Industry_type_ID: 0,
      Road_ID: 0,
      pri_app_code: 0,
      sec_app_code: 0,
      LikelyDate: "''",
      MPG: parseInt(this.state.ModelCategory),
      Model: parseInt(this.state.ModelCode),
      Cancel: "'N'",
      Confirm: "'N'",
      modcat: 1,
      Qty: parseInt(this.state.Quantity),
      M0ID: parseInt(this.state.newID_crm_id),
      LikelyBuyID: 0,
      Source_name: `''`,
      Source_Address: `''`,
      Source_Ph_no: `''`,
      Load_Permit_Available: "''",
      Tie_Body_Builder: "''",
      Mech_Name: "''",
      Mech_Address: "''",
      Mech_PH_No: "''",
      Tie_Local_MEch: "''",
      OrderStatus: "'N'",
      PO_Type: 1,
      AmcDet: `'${this.state.AMC_Details}'`,
      SpWarrDet: `'${this.state.Special_Warranty_Details}'`,
      Exwarrdet: `'${this.state.ExtendedW_arranty_Details}'`,
      SpPackages: `''`,
      OtherDet: `'${this.state.Other_Details}'`,
      DoneBy: "'D'",
      Enquiry: "''",
      AppNo: "''",
      DealerGST: "''",
      model_Code: `'${this.state.Model}'`,
      model_name: `'${this.state.Model_Name}'`,
      model_Colour: `'${this.state.AMV_Model_Colour}'`,
      MRP: this.state.Ex_ShowRoom_Price ? this.state.Ex_ShowRoom_Price : 0,
      Additional_Accessories: `''`,
      Standard_Accesories: `${
        this.state.Total_Accessories_Amount
          ? this.state.Total_Accessories_Amount
          : 0
      }`,
      Standard_Accessories_amount: "0",
      Additional_Accessories_amount: "0",
      RTO_Amount: this.state.AMV_RTO_Amount ? this.state.AMV_RTO_Amount : 0,
      Insurance_company: `'${
        this.state.Insurance_company ? this.state.Insurance_company : ""
      }'`,
      insurance_year: `'${
        this.state.AMV_Insurance_Year ? this.state.AMV_Insurance_Year : ""
      }'`,
      insurance_amount: `${
        this.state.AMV_Insurance_Amount ? this.state.AMV_Insurance_Amount : "0"
      }`,
      CRTM_amount: this.state.AMV_CRTM_Amount ? this.state.AMV_CRTM_Amount : 0,
      HPA_Change: `'${this.state.AMV_HPA_Charges}'`,
      Smart_card_amount: (this.state.AMV_Smart_Card_Amount==null || this.state.AMV_Smart_Card_Amount=="")?0:this.state.AMV_Smart_Card_Amount,
      RSA_change: `''`,
      subsidiary_amount: this.state.AMV_Subsidy_Amount
        ? this.state.AMV_Subsidy_Amount
        : "0",
      dealer_propsed_discount: this.state.AMV_Dealer_Proposed_Amount
        ? this.state.AMV_Dealer_Proposed_Amount
        : "0",
      special_number_amount: "0",
      disbursment_amount: "0",
      other_charges_amount: "0",
      RTO_discount: this.state.AMV_RTO_Discount
        ? this.state.AMV_RTO_Discount
        : "0",
      RTO_discount_amount: this.state.AMV_RTO_Discount_Amount
        ? this.state.AMV_RTO_Discount_Amount
        : "0",
      other_changes: `''`,
      Exchange_bonus_with_tax: "0",
      Total_amount: this.state.Quantity_Amount ? this.state.Quantity_Amount : 0,
      Quotation_Number: `'${this.state.Quotation_No}'`,
      Quotation_Date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      Subsity_Type: `'${
        this.state.Subsidy_Type ? this.state.Subsidy_Type : ""
      }'`,
      Is_booking: "'N'",
      Is_moneyreceive: "'N'",
      Is_allocation: "'N'",
      Is_Billing: "'N'",
      Enquiry_Status: "'Open'",
      RSA_Charge_flag: this.state.setRSACharge,
      Smart_Card_Amount_flag: this.state.setSmartCartAmt,
      CRTM_Amount_flag: this.state.setCrtAmt,
      RTO_Amount_flag: this.state.setRtoAmount,
      Model_gp_type: `${this.state.model_typecode}`,
      HSRP_Charge: `${this.state.AMV_HSRP_Charges}`,
      Other_RTO: `${this.state.Other_RTO_Charges}`,
      Taxable_Amt: `${this.state.Taxable_Amount}`,
      Grand_Total: `${this.state.grand_total}`,
      Amount: `${this.state.Quantity_Amount}`,
      centarl_subsidy_amount: `${this.state.AMV_Central_Subsidy_Amount}`,
      DlrCode: `'${this.state.Dealer_Code}'`,
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      Scheme_Name: `'${this.state.Scheme_Name||""}'`,
      Scheme_Amt: `${this.state.Scheme_Amount||0}`,
      Scheme_ID: `${this.state.Scheme_Id||0}`,
      Scheme_start_date: `'${this.state.Scheme_Start_Date||""}'`,
      Scheme_End_date: `'${this.state.Scheme_End_Date||""}'`
    });

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res[0].ID) {
          if (this.state.ConditionCode[0].accid != 0) {
            this.handelAccessoriesSave(res[0].ID);
          }
          this.setState(
            {
              Enquiry_ID: res[0].ID,
              M1_id:res[0].ID
            },
            () => {
              this.Test_driven_save()
              this.visit_details_save();
            }
          );
        } else if (res[0].newID) {
          if (this.state.ConditionCode[0].accid != 0) {
            this.handelAccessoriesSave(res[0].newID);
          }
          this.Test_driven_save()
          alert("Record Updated Succesfully");
          this.setState({
            saveLoader: false,
          });
           window.location.reload();
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Test_driven_save = () => {
    console.log("this.state.selectedFile",this.state.selectedFile);
    var formdata = new FormData();
    formdata.append("file", this.state.selectedFile?this.state.selectedFile[0]:"");
    formdata.append("ID", this.state.test_ID ? this.state.test_ID : 0);
    formdata.append("M1_Header", `${this.state.M1_id ? this.state.M1_id : 0}`);
    formdata.append("Model_Group", this.state.Model_test ? this.state.Model_test : 0);
    formdata.append("Model_Code", `${this.state.ModelCode_test}`);
    formdata.append("Helmet", `'${this.state.Helmet}'`);
    formdata.append("Allocated_Vehicle", `'${this.state.Allocated_Vehicle}'`);
    formdata.append("Staff_Accompanying", `'${this.state.Staff_accompanying}'`);
    formdata.append("License_No", `'${this.state.License_number}'`);
    formdata.append("Document_Name", this.state.selectedFile?`'${this.state.selectedFile[0].name}'`:"''");
    formdata.append("Is_ModelChoice", `'${this.state.Model_choice}'`);
    formdata.append("Is_VehicleCondition", `'${this.state.vehicles_Condition}'`);
    formdata.append("Is_DurationSufficient", `'${this.state.duration_test_drive}'`);
    formdata.append("Is_ExplainationSatisfactory", `'${this.state.explanation}'`);
    formdata.append("TestDriveRating", this.state.Rating?`${this.state.Rating}`:"''");
    formdata.append("Remark", `'${this.state.remark}'`);
    formdata.append("Dealer_ID", `${sessionStorage.getItem("dealerid")}`);

    console.log("formdata",formdata);
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(Urls.python_baseurl + "/Save_Test_Drive_Details", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.MasterAPICall();
        this.getPODetails();
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        this.setState({
          doubleClick: false,
        });
      });
  };
  handelAccessoriesSave = (id) => {
    const url = Urls.python_baseurl + "/SP_Save_M1_Accesories";
    let data1 = this.state.ConditionCode.map((e) => {
      return {
        ID: e.accesores_ID ? `${e.accesores_ID}` : "0",
        M1_Hdr: `${id}`,
        Acc_id: `${e.accid}`,
        cr_date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
        Accessories_Amount: `${e.accessories_amount}`,
        HSN: `'${e.hsn}'`,
        IGST: `${e.igst}`,
        IGST_Amount: `${e.igst_amount}`,
        SGST: `${e.sgst}`,
        SGST_Amount: `${e.sgst_amount}`,
        CSGT: `${e.cgst}`,
        CSGT_Amount: `${e.cgst_amount}`,
        Total_With_Tax: `${e.accessories_total_withtax}`,
      };
    });
    var data = JSON.stringify(data1);
    console.log("hab", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "hhjjkj");
        if (res && res.length > 0) {
          // if (this.state.isSavedClick == "saveClcik") {
          //   alert("Record Updated Succesfully");
          //   window.location.reload();
          //   $("#myModal2").modal("hide");
          //   $("#payment_error_modal_2").modal("hide");
          //   sessionStorage.setItem("ActiveTab", "Vehicle Booking");
          //   this.props.history.push("/tiles_tabs");
          // }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  visit_details_save = (event) => {
    const url = Urls.python_baseurl + "/Save_mo_SP_Save_M1Objectives";
    var data = JSON.stringify({
      ID: "0",
      UserId: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      Lead_hdr_ID: parseInt(this.state.Enquiry_ID),
      obj_Id: this.state.visit_details,
      obj_date: `'${moment(this.state.startDate).format("DD-MM-YYYY")}'`,
      discussion: "''",
      time_spent: "''",
      next_obj_Id: this.state.next_visit_details,
      next_date: `'${moment(this.state.endDate).format("DD-MM-YYYY")}'`,
      commit_det: "''",
      Remark: `'${this.state.Remark}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].newID) {
            // this.maxNo_enquiry();
            alert("Record Saved Succesfully");
            this.setState({
              saveLoader: false,
            });
            // $("#myModal2").modal("hide");
            // $("#payment_error_modal_2").modal("hide");
            // sessionStorage.setItem("ActiveTab", "Enquiry");
            window.location.reload();
          }else if (res[0].ID) {
            // this.maxNo_enquiry();
            alert("Record Saved Succesfully");
            this.setState({
              saveLoader: false,
            });
            // $("#myModal2").modal("hide");
            // $("#payment_error_modal_2").modal("hide");
            // sessionStorage.setItem("ActiveTab", "Enquiry");
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  maxNo_Quotation = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      DocName: "'Q'",
      DealerId: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        window.location.reload();
      });
  };

  maxNo_enquiry = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      DocName: "'EN'",
      DealerId: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            alert("Record Saved Succesfully");
            this.setState({
              saveLoader: false,
            });
            $("#myModal2").modal("hide");
            $("#payment_error_modal_2").modal("hide");
            sessionStorage.setItem("ActiveTab", "Enquiry");
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        // window.location.reload();
      });
  };
  handleSort = (event) => {
    if (event === "m0") {
      this.props.history.push("/" + Strings.Lead, {
        data: [],
      });
    } else if (event === "m1") {
      this.props.history.push("/" + Strings.Enquiry_details, {
        data: [],
      });
    }
  };
  changeDropdown4 = (selectedOptions) => {
    let amount_acc = selectedOptions.map((item) => item.MRP);
    let greater = 0,
      smaller = 0;
    amount_acc.forEach((element) => {
      greater += element;
    });
    if (amount_acc.length == 1) {
      smaller = 0;
    } else {
      smaller = greater - amount_acc[amount_acc.length - 1];
    }
    const segmentData = selectedOptions.map((item) => item.key);
    this.setState(
      {
        selectedValues_1: selectedOptions,
        Additional_accessory_12: segmentData,
        AMV_Addational_Accessories_Amount: greater,
        Total: this.state.Total + greater - smaller,
        TotalCopy: this.state.Total + greater - smaller,
      },
      () => {
        let keysArray = Object.values(this.state.Additional_accessory_12);
        let output = keysArray.join(",");
        this.setState({
          Additional_access: output,
        });
      }
    );
    // this.setState({AMV_Addational_Accessories:result})
  };
  handleRemove = (removedValue) => {
    const testsArray2 = removedValue.map((item) => item.key);
    let data = this.state.selectedValues.filter(
      (item) => item.key && !testsArray2.includes(item.key)
    );
    const segmentData = removedValue.map((item) => item.key);
    let amount_acc = removedValue.map((item) => item.MRP);
    const sum = amount_acc.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    // Update the state to reflect the removed value
    this.setState(
      {
        selectedValues: removedValue,
        CompleteData: segmentData,
        Total: this.state.Total - data[0].MRP,
        TotalCopy: this.state.Total - data[0].MRP,
        AMV_Standard_Accessories_Amount: sum,
      },
      () => {
        let keysArray = Object.values(this.state.CompleteData);
        let output = keysArray.join(",");
        this.setState({
          standard_access: output,
        });
      }
    );
  };
  handleRemove_additional = (removedValue_1) => {
    const testsArray2 = removedValue_1.map((item) => item.key);
    let data = this.state.selectedValues_1.filter(
      (item) => item.key && !testsArray2.includes(item.key)
    );
    const segmentData = removedValue_1.map((item) => item.key);
    let amount_acc = removedValue_1.map((item) => item.MRP);
    const sum = amount_acc.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    // Update the state to reflect the removed value
    this.setState(
      {
        selectedValues_1: removedValue_1,
        CompleteData: segmentData,
        Total: this.state.Total - data[0].MRP,
        TotalCopy: this.state.Total - data[0].MRP,
        AMV_Addational_Accessories_Amount: sum,
      },
      () => {
        let keysArray = Object.values(this.state.CompleteData);
        let output = keysArray.join(",");
        this.setState({
          standard_access: output,
        });
      }
    );
  };
  changeDropdown5 = (selectedOptions) => {
    let amount_acc = selectedOptions.map((item) => item.MRP);
    let greater = 0,
      smaller = 0;
    amount_acc.forEach((element) => {
      greater += element;
    });
    if (amount_acc.length == 1) {
      smaller = 0;
    } else {
      smaller = greater - amount_acc[amount_acc.length - 1];
    }
    const segmentData = selectedOptions.map((item) => item.key);
    this.setState(
      {
        selectedValues: selectedOptions,
        CompleteData: segmentData,
        AMV_Standard_Accessories_Amount: greater,
        Total: this.state.Total + greater - smaller,
        TotalCopy: this.state.Total + greater - smaller,
      },
      () => {
        let keysArray = Object.values(this.state.CompleteData);
        let output = keysArray.join(",");
        this.setState({
          standard_access: output,
        });
      }
    );
  };
  handelCheck = (data) => {
    if (data) {
      this.setState({ RTO_Amount: 353536 });
    } else {
      this.setState({ RTO_Amount: "" });
    }
  };
  showModelVarient = () => {
    return (
      <div>
        <h4 style={{ marginLeft: "0px" }} class="heading_text">
          Model Details
        </h4>
        <div className="form-row mt-4">
          <div className="form-group col-md-3">
            <label htmlFor="model_group">Model</label>
            <select
              value={this.state.ModelCategory}
              onChange={(e) => this.ChangeVal(e, "ModelCategory")}
              id="model_group"
              className=" form-control "
            >
              <option value="0">Select</option>
              {this.GetCategory()}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="model_id">Model Name</label>
            <select
              value={this.state.ModelCode}
              onChange={(e) => this.ChangeVal(e, "ModelCode")}
              id="model_id"
              className=" form-control "
            >
              <option value="0">Select</option>
              {this.state.Model_Code.map((e) => {
                return <option value={e.ID}>{e["Model name"]}</option>;
              })}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="model_name">Model Code</label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "Model")}
              value={this.state.Model}
              id="model_name"
              className=" form-control "
            >
              {/* <option>Select</option>
              {this.state.Model_Data_1.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })} */}
            </input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="model_color">Model Colour</label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_Model_Colour")}
              value={this.state.AMV_Model_Colour}
              id="model_color"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="qunatity">Quantity</label>
            <input
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0 || number % 1 !== 0) {
                  return;
                }
                if (/^0/.test(number)) {
                  number = number.replace(/^0/, "");
                } else {
                  this.ChangeVal(e, "Quantity");
                }
              }}
              value={this.state.Quantity}
              id="qunatity"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="sale_rate">Ex Showroom Price</label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_Sale_Rate")}
              value={this.state.Ex_ShowRoom_Price}
              id="sale_rate"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Scheme Name</label>
            <input
              // onChange={(e) => this.ChangeVal(e, "SchemeName")}
              value={this.state.Scheme_Name?this.state.Scheme_Name:""}
              className=" form-control "
              disabled
            >
            </input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">Scheme Amount Per Unit (-)</label>
            <input
              // onChange={(e) => this.ChangeVal(e, "Scheme_Amount")}
              disabled
              value={this.state.Scheme_Amount?this.state.Scheme_Amount:""}
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="rto_discount">
              Dealer Proposed Discount Per Unit (-)
            </label>
            <input
              // type="number"
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0) {
                  return;
                }
                this.ChangeVal(e, "AMV_Dealer_Proposed_Amount");
              }}
              value={
                this.state.AMV_Dealer_Proposed_Amount
                  ? this.state.AMV_Dealer_Proposed_Amount
                  : ""
              }
              id="rto_discount"
              className=" form-control "
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="other_charges">Subsidy Type</label>
            <select
              disabled={this.state.Quantity ? false : true}
              onChange={(e) => this.ChangeVal(e, "Subsidy_Type")}
              value={this.state.Subsidy_Type}
              className=" form-control "
              id="other_charges"
            >
              <option>Select</option>
              <option value={"State"}>State</option>
              <option value={"Central"}>Central</option>
            </select>
          </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">
              State Subsidy Amount Per Unit (-)
            </label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_Subsidy_Amount")}
              value={
                this.state.AMV_Subsidy_Amount
                  ? parseFloat(this.state.AMV_Subsidy_Amount).toFixed(2)
                  : ""
              }
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">
              Central Subsidy Amount Per Unit (-)
            </label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_Central_Subsidy_Amount")}
              value={
                this.state.AMV_Central_Subsidy_Amount
                  ? parseFloat(this.state.AMV_Central_Subsidy_Amount).toFixed(2)
                  : ""
              }
              id="crtm_amount"
              className=" form-control "
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>
              Taxable Amount<span style={{ color: "red" }}>*</span>
            </label>
            <input
              disabled
              value={
                this.state.Taxable_Amount
                  ? this.state.Taxable_Amount.toFixed(2)
                  : ""
              }
              onChange={(e) => this.onChangeName(e, "Taxable_Amount")}
              class="form-control"
              placeholder=""
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="accessories">Standard Accessories</label>
            <Multiselect
              displayValue="key"
              onKeyPressFn={() => {}}
              onRemove={(removedValue) => this.handleRemove(removedValue)}
              onSearch={() => {}}
              selectedValues={this.state.selectedValues}
              onSelect={(selectedOptions) =>
                this.changeDropdown5(selectedOptions)
              }
              options={this.state.AVM_Standard_Accessories}
              showCheckbox
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="accessoriesamount">
              Standard Accessories Amount
            </label>
            <input
              disabled
              onChange={(e) =>
                this.ChangeVal(e, "AMV_Standard_Accessories_Amount")
              }
              value={this.state.AMV_Standard_Accessories_Amount}
              id="accessoriesamount"
              className=" form-control "
            ></input>
          </div> */}
          {/* <div className="form-group col-md-3">
            <label htmlFor="addationalaccessories">
              Additional Accessories
            </label>
            <Multiselect
              displayValue="key"
              onKeyPressFn={() => {}}
              onRemove={(removedValue_1) =>
                this.handleRemove_additional(removedValue_1)
              }
              onSearch={() => {}}
              selectedValues={this.state.selectedValues_1}
              onSelect={(selectedOptions_1) =>
                this.changeDropdown4(selectedOptions_1)
              }
              options={this.state.AVM_Additional_Accessories}
              showCheckbox
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="addationalaccessories">
              Additional Accessories Amount
            </label>
            <input
              onChange={(e) =>
                this.ChangeVal(e, "AMV_Addational_Accessories_Amount")
              }
              value={this.state.AMV_Addational_Accessories_Amount}
              id="addationalaccessories"
              className=" form-control "
            ></input>
          </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="rtoamount">RTO Amount Per Unit (+)</label>
            <div>
              {/* <input
                onChange={(e) => this.ChangeVal(e, "setRtoAmount")}
                // onClick={(e) => this.handelCheck(e.target.checked)}
                checked={this.state.setRtoAmount}
                style={{ width: "20px", position: "absolute" }}
                class="form-control"
                type="checkbox"
              /> */}
              <input
                disabled
                onChange={(e) => this.ChangeVal(e, "AMV_RTO_Amount")}
                value={
                  this.state.AMV_RTO_Amount
                    ? this.state.AMV_RTO_Amount.toFixed(2)
                    : ""
                }
                className=" form-control "
                id="rtoamount"
              ></input>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="rto_discount">RTO Discount Per Unit (%)</label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_RTO_Discount")}
              value={this.state.AMV_RTO_Discount}
              id="rto_discount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="rto_discount_amount">
              RTO Discount Amount Per Unit (-)
            </label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_RTO_Discount_Amount")}
              value={
                this.state.AMV_RTO_Discount_Amount
                  ? this.state.AMV_RTO_Discount_Amount
                  : ""
              }
              id="rto_discount_amount"
              className=" form-control "
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="insurance_year">Insurance Company</label>
            <select
              onChange={(e) => this.ChangeVal(e, "AMV_Insurance_Company")}
              value={this.state.AMV_Insurance_Company}
              id="insurance_year"
              className=" form-control "
            >
              <option>Select</option>
              {this.state.insuranceList.map((e) => {
                return (
                  <option value={e.Insurance_Company}>
                    {e.Insurance_Company}
                  </option>
                );
              })}
            </select>
          </div> */}

          {/* <div className="form-group col-md-3">
            <label htmlFor="insurance_year">Insurance Year</label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_Insurance_Year")}
              value={this.state.AMV_Insurance_Year}
              id="insurance_year"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="insurance_amount">Insurance Amount</label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "AMV_Insurance_Amount")}
              value={this.state.AMV_Insurance_Amount}
              id="insurance_amount"
              className=" form-control "
            ></input>
          </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="crtm_amount">CRTM Amount Per Unit (+)</label>
            <div>
              <input
                onChange={(e) => {
                  let number = e.target.value;
                  if (isNaN(number) || number < 0) {
                    return;
                  }
                  this.ChangeVal(e, "AMV_CRTM_Amount");
                }}
                value={
                  this.state.AMV_CRTM_Amount ? this.state.AMV_CRTM_Amount : ""
                }
                id="crtm_amount"
                className=" form-control "
              ></input>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="hpacharge">HPA Charge Per Unit (+)</label>
            <input
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0) {
                  return;
                }
                this.ChangeVal(e, "AMV_HPA_Charges");
              }}
              value={
                this.state.AMV_HPA_Charges ? this.state.AMV_HPA_Charges : ""
              }
              id="hpacharge"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="smart_card_amount">
              Smart Card Amount Per Unit (+)
            </label>
            <div>
              <input
                onChange={(e) => {
                  let number = e.target.value;
                  if (isNaN(number) || number < 0) {
                    return;
                  }
                  this.ChangeVal(e, "AMV_Smart_Card_Amount");
                }}
                value={
                  this.state.AMV_Smart_Card_Amount
                    ? this.state.AMV_Smart_Card_Amount
                    : ""
                }
                id="smart_card_amount"
                className=" form-control "
              ></input>
            </div>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="HSRPcharge">HSRP Charge Per Unit (+)</label>
            <input
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0) {
                  return;
                }
                this.ChangeVal(e, "AMV_HSRP_Charges");
              }}
              value={
                this.state.AMV_HSRP_Charges ? this.state.AMV_HSRP_Charges : ""
              }
              id="HSRPcharge"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="OtherRTOCharge">
              Other RTO Charge Per Unit (+)
            </label>
            <input
              onChange={(e) => {
                let number = e.target.value;
                if (isNaN(number) || number < 0) {
                  return;
                }
                this.ChangeVal(e, "Other_RTO_Charges");
              }}
              value={
                this.state.Other_RTO_Charges ? this.state.Other_RTO_Charges : ""
              }
              id="OtherRTOCharge"
              className=" form-control "
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="rsa_charge">RSA Charge</label>
            <div>
              <input
                onChange={(e) => this.ChangeVal(e, "setRSACharge")}
                style={{ width: "20px", position: "absolute" }}
                class="form-control"
                type="checkbox"
                checked={this.state.setRSACharge}
              />
              <input
                disabled
                onChange={(e) => this.ChangeVal(e, "AMV_RSA_Amount")}
                value={this.state.AMV_RSA_Amount}
                
                placeholder="RSA Charge"
                id="rsa_charge"
                className=" form-control "
              ></input>
            </div>
          </div> */}
          {/* <div className="form-group col-md-3">
            <label htmlFor="special_number_amount">Special Number Amount</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Special_Number_Amount")}
              value={this.state.AMV_Special_Number_Amount}
              id="special_number_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="disbursnment_amount">Disbursment Amount</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Disbursment_Amount")}
              value={this.state.AMV_Disbursment_Amount}
              id="disbursnment_amount"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="other_charges">Other Charges</label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Other_Charges")}
              value={this.state.AMV_Other_Charges}
              className=" form-control "
              id="other_charges"
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="oher_charges_amount">Other Charges Amount</label>
            <input
              onChange={(e) => this.ChangeVal(e, "Other_Charges_Amount")}
              value={this.state.Other_Charges_Amount}
              id="oher_charges_amount"
              className=" form-control "
            ></input>
          </div> */}
          {/* <div className="form-group col-md-3">
            <label htmlFor="exhange-bonus">Exchange Bonus With Tax</label>
            <input
              onChange={(e) => this.ChangeVal(e, "Exchange_Bonus_Amount")}
              value={this.state.Exchange_Bonus_Amount}
              id="exhange-bonus"
              className=" form-control "
            ></input>
          </div> */}
          <div hidden className="form-group col-md-3">
            <label htmlFor="sale_rate">Amount</label>
            <input
              disabled
              onChange={(e) => this.ChangeVal(e, "Quantity_Amount")}
              value={
                this.state.Quantity_Amount ? this.state.Quantity_Amount : ""
              }
              id="sale_rate"
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Insurance_company">
              Insurance Company {this.required()}
            </label>
            <select
              // onChange={(e) => this.ChangeVal(e, "Insurance_company")}
              onChange={(e) =>
                this.setState({ Insurance_company: e.target.value })
              }
              value={this.state.Insurance_company}
              id="Insurance_company"
              className=" form-control "
             // disabled={this.state.Is_Insurance=="Y"?true:false}
            >
              <option value="0">Select</option>
              {this.state.insuranceList.map((e) => {
                return (
                  <option value={e.Insurance_Company}>
                    {e.Insurance_Company}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Insurance_company">
              Insurance Type {this.required()}
            </label>
            <select
              // onChange={(e) => this.ChangeVal(e, "Insurance_company")}
              onChange={(e) =>
                this.setState({ AMV_Insurance_Year: e.target.value })
              }
              value={this.state.AMV_Insurance_Year}
              id="Insurance_company"
              className=" form-control "
              //disabled={this.state.Is_Insurance=="Y"?true:false}

            >
              <option value="0">Select</option>
              <option value="1+4 Comprehensive">1+4 Comprehensive</option>
              <option value="1+4 Third Party">1+4 Third Party</option>
            </select>
          </div>
          {/* <div className="form-group col-md-3">
            <label htmlFor="insurance_year">
              Insurance Type {this.required()}
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "AMV_Insurance_Year")}
              value={this.state.AMV_Insurance_Year}
              id="insurance_year"
              className=" form-control "
            ></input>
          </div> */}
          <div className="form-group col-md-3">
            <label htmlFor="insurance_amount">
              Insurance Amount Per Unit (+) {this.required()}
            </label>
            <input
              onChange={(e) => {
                let number = e.target.value;

                if (isNaN(number) || number < 0) {
                  return;
                }
                if (/^0/.test(number)) {
                  number = number.replace(/^0/, "");
                } else {
                  this.ChangeVal(e, "AMV_Insurance_Amount");
                  // this.setState(
                  //   {
                  //     Grand_Total:
                  //       this.state.Taxable_Amt +
                  //       (this.state.AMV_RTO_Amount
                  //         ? parseFloat(this.state.AMV_RTO_Amount)
                  //         : 0) +
                  //       (this.state.Other_Charges
                  //         ? parseFloat(this.state.Other_Charges)
                  //         : 0) +
                  //       (this.state.Total_Accessories_Amount
                  //         ? parseFloat(this.state.Total_Accessories_Amount)
                  //         : 0) +
                  //       (e.target.value ? parseFloat(e.target.value) : 0) +
                  //       (this.state.Other_RTO_Charge
                  //         ? parseFloat(this.state.Other_RTO_Charge)
                  //         : 0) +
                  //       (this.state.HSRP_Charge
                  //         ? parseFloat(this.state.HSRP_Charge)
                  //         : 0) +
                  //       (this.state.AMV_Smart_Card_Amount
                  //         ? parseFloat(this.state.AMV_Smart_Card_Amount)
                  //         : 0) +
                  //       (this.state.AMV_HPA_Charges
                  //         ? parseFloat(this.state.AMV_HPA_Charges)
                  //         : 0) +
                  //       (this.state.AMV_CRTM_Amount
                  //         ? parseFloat(this.state.AMV_CRTM_Amount)
                  //         : 0) -
                  //       (this.state.Exchange_Bonus_Amount
                  //         ? parseFloat(this.state.Exchange_Bonus_Amount)
                  //         : 0) -
                  //       (this.state.AMV_RTO_Discount_Amount
                  //         ? parseFloat(this.state.AMV_RTO_Discount_Amount)
                  //         : 0),
                  //     //   -
                  //     // parseFloat(
                  //     // this.state.SchemeOption.filter(
                  //     // (e) => e.ID == this.state.SchemeName
                  //     // )[0]?.Amount
                  //     // ? this.state.SchemeOption.filter(
                  //     // (e) => e.ID == this.state.SchemeName
                  //     // )[0]?.Amount
                  //     // : 0
                  //     // ),
                  //   },
                  //   () => {
                  //     this.setState({
                  //       Pending_Amount:
                  //         parseFloat(this.state.Grand_Total) -
                  //         parseFloat(this.state.Booking_Advance_Amount),
                  //     });
                  //   }
                  // );
                }
              }}
              // onChange={(e) => { this.ChangeVal(e, "AMV_Insurance_Amount");}}
              value={this.state.AMV_Insurance_Amount}
              id="insurance_amount"
              className=" form-control "
              disabled={this.state.Is_Insurance=="Y"?true:false}

              // type="number"
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="Total_Accessories_Amount">
              Total Accessories Amount Per Unit (+)
            </label>
            <input
              onChange={(e) => this.ChangeVal(e, "Total_Accessories_Amount")}
              disabled
              value={
                this.state.Total_Accessories_Amount
                  ? parseFloat(this.state.Total_Accessories_Amount).toFixed(2)
                  : 0
              }
              id="Total_Accessories_Amount"
              className=" form-control "
            ></input>
          </div>
          <div class="form-group col-md-3">
            <label>Grand Total</label>
            <input
              disabled
              value={
                this.state.grand_total
                  ? parseFloat(this.state.grand_total).toFixed(2)
                  : ""
              }
              onChange={(e) => this.onChangeName(e, "Total")}
              class="form-control"
              placeholder=""
            ></input>
          </div>
        </div>
      </div>
    );
  };
  handelClose = () => {
    $("#myModal2").modal("hide");
    $("#payment_error_modal_2").modal("hide");
    $("#payment_error_modal_3").modal("hide");
    $("#payment_error_modal_3").modal("show");
    $("#payment_error_modal_2").modal("show");
  };
  accessoriesBody() {
    console.log("ConditionCode", this.state.ConditionCode);
    return this.state.ConditionCode?.map((d, index) => {
      return (
        <tr key={d.id}>
          <td>
            {index === this.state.ConditionCode.length - 1 && (
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={
                  this.state.IS_confirm !== "Y" && !this.state.disableaddnew
                    ? {
                        color: "var(--main-bg-color)",
                        fontSize: "25px",
                        cursor: "pointer",
                      }
                    : {
                        color: "var(--main-bg-color)",
                        fontSize: "25px",
                        cursor: "default",
                        opacity: "30%",
                      }
                }
                onClick={() =>
                  this.state.IS_confirm !== "Y" &&
                  !this.state.disableaddnew &&
                  this.accessoriesAddNew(d.id)
                }
              />
            )}
          </td>
          <td>
            <select
              onChange={(e) => {
                this.handelchange("accessories_name", e.target.value, d.id);
              }}
              value={d.accessories_name}
              type="text"
              list="accessories_name"
              className=" form-control "
              disabled={this.state.IS_confirm !== "Y" ? false : true}
            >
              <option value={0}>Select</option>
              {d.options.map((e) => {
                return (
                  <option value={e?.Accesories_Name}>
                    {e?.Accesories_Name}
                  </option>
                );
              })}
            </select>
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("accessories_amount", e.target.value, d.id);
              }}
              value={d.accessories_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="text"
              onChange={(e) => {
                this.handelchange("hsn", e.target.value, d.id);
              }}
              value={d.hsn}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("igst", e.target.value, d.id);
              }}
              value={d.igst}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("igst_amount", e.target.value, d.id);
              }}
              value={d.igst_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("sgst", e.target.value, d.id);
              }}
              value={d.sgst}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("sgst_amount", e.target.value, d.id);
              }}
              value={d.sgst_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("cgst", e.target.value, d.id);
              }}
              value={d.cgst}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange("cgst_amount", e.target.value, d.id);
              }}
              value={d.cgst_amount}
            />
          </td>
          <td>
            <input
              disabled
              className=" form-control "
              type="number"
              onChange={(e) => {
                this.handelchange(
                  "accessories_total_with_tax",
                  e.target.value,
                  d.id
                );
              }}
              value={d.accessories_total_withtax}
            />
          </td>
          {}
          <td style={{ width: "25px", backgroundColor: "white" }}>
            <img
              src={require("../../../components/Images/trash.png")}
              style={
                this.state.IS_confirm !== "Y"
                  ? { width: "20px", cursor: "pointer" }
                  : { width: "20px", cursor: "default", opacity: "30%" }
              }
              onClick={() =>
                this.state.IS_confirm !== "Y" &&
                this.accessoriesDelet(d.id, d.accesores_ID)
              }
            ></img>
          </td>
        </tr>
      );
    });
  }
  handelchange = (key, value, id) => {
    if (key == "accessories_name") {
      let newOptions = this.state.AccessoriesOption;
      let xyz = this.state.ConditionCode.filter((e) => e?.id == id)[0]
        ?.selectedAccessories;
      let newdata = newOptions.filter((e) => {
        return e.Accesories_Name == value;
      });
      let singleaccessoriesamt = 0;
      let data = this.state.ConditionCode;
      let Total_Accessories_Amount = 0;
      if (value == 0) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].id === id) {
            newOptions.push(data[i].selectedAccessories);
            data[i] = {
              id: id,
              accid: "",
              accessories_name: "",
              accessories_amount: "",
              hsn: "",
              igst: "",
              igst_amount: "",
              sgst: "",
              sgst_amount: "",
              cgst: "",
              cgst_amount: "",
              accessories_total_withtax: "",
              options: data[i].options,
              selectedAccessories: {},
            };
          }
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i].id !== id) {
            data[i].options = [
              ...data[i].options,
              newOptions[newOptions.length - 1],
            ];
          }
        }
      } else {
        newOptions = newOptions.filter((e) => {
          return e.Accesories_Name !== value;
        });
        if (xyz && Object.keys(xyz).length > 0) {
          newOptions.push(xyz);
        }
        for (let i = 0; i < data.length; i++) {
          if (data[i]?.id == id) {
            data[i].accessories_name = newdata[0]?.Accesories_Name;
            data[i].accessories_amount = newdata[0]?.NDP;
            data[i].hsn = newdata[0]?.HSN;
            data[i].accid = newdata[0]?.ID;
            data[i]["selectedAccessories"] = newdata[0];
            if (this.state.State == sessionStorage.getItem("Dealer_state")) {
              console.log(Total_Accessories_Amount, "jkjkjkj", 1);
              data[i].igst = 0;
              data[i].sgst = newdata[0]?.SGST;
              data[i].cgst = newdata[0]?.CGST;
              data[i].igst_amount = 0;
              data[i].sgst_amount =
                parseFloat(newdata[0]?.NDP) *
                (parseFloat(newdata[0]?.SGST) / 100);
              data[i].cgst_amount =
                parseFloat(newdata[0]?.NDP) *
                (parseFloat(newdata[0]?.CGST) / 100);
              data[i].accessories_total_withtax = (
                parseFloat(data[i].accessories_amount) +
                data[i].sgst_amount +
                data[i].igst_amount +
                data[i].cgst_amount
              ).toFixed(2);
            } else {
              console.log(Total_Accessories_Amount, "jkjkjkj", 2);
              data[i].igst = newdata[0]?.IGST;
              data[i].sgst = 0;
              data[i].cgst = 0;
              data[i].sgst_amount = 0;
              data[i].cgst_amount = 0;
              data[i].igst_amount =
                parseFloat(newdata[0]?.NDP) *
                (parseFloat(newdata[0]?.IGST) / 100);
              data[i].accessories_total_withtax = (
                parseFloat(data[i].accessories_amount) +
                data[i].sgst_amount +
                data[i].igst_amount +
                data[i].cgst_amount
              ).toFixed(2);
              singleaccessoriesamt = parseFloat(
                data[i].accessories_total_withtax
              );
            }
          } else {
            if (data[i].accessories_name) {
              data[i].options = [...newOptions, data[i].selectedAccessories];
            } else {
              data[i].options = [...newOptions];
            }
          }
        }
      }
      for (let i = 0; i < this.state.ConditionCode.length; i++) {
        Total_Accessories_Amount += this.state.ConditionCode[i]
          .accessories_total_withtax
          ? parseFloat(this.state.ConditionCode[i].accessories_total_withtax)
          : 0;
      }
      this.setState(
        {
          ConditionCode: data,
          Total_Accessories_Amount: Total_Accessories_Amount,
          AccessoriesOption: newOptions,
        },
        () => {
          this.GetSubsidy();
        }
      );
    }
  };
  accessoriesHeader() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        <td width="25px" className="table-text"></td>
        <td width="20%">Accessories Name</td>
        <td width="8%">Accessories Amount</td>
        <td width="8%">HSN</td>
        <td width="8%">IGST</td>
        <td width="8%">IGST Amount</td>
        <td width="8%">SGST</td>
        <td width="8%">SGST Amount</td>
        <td width="8%">CSGT</td>
        <td width="8%">CSGT Amount</td>
        <td width="20%">Accessories Total With Tax</td>
        <td width="25px" className="table-text"></td>
      </tr>
    );
  }
  accessoriesAddNew = (id) => {
    if (this.state.ConditionCode.length < this.state.MaxAccessoriesCount) {
      this.setState(
        (prev) => {
          return {
            ...prev,
            ConditionCode: [
              ...this.state.ConditionCode,
              {
                id: id + 1,
                accid: "",
                accessories_name: "",
                accessories_amount: "",
                hsn: "",
                igst: "",
                igst_amount: "",
                sgst: "",
                sgst_amount: "",
                cgst: "",
                cgst_amount: "",
                accessories_total_withtax: "",
                options: this.state.AccessoriesOption,
              },
            ],
          };
        },
        () => {
          console.log(this.state.ConditionCode, "kkjjk");
        }
      );
    } else {
      this.setState({
        disableaddnew: true,
      });
    }
  };
  accessoriesDelet = (id, accid) => {
    if (accid > 0) {
      $("#GLKickOut_Popup").modal("show");
      this.setState({
        delete_id: id,
        accssory_id: accid,
      });
    } else if (this.state.ConditionCode.length > 1) {
      let newConditionArray = this.state.ConditionCode.filter((e) => {
        return e.id != id;
      });
      const removerlist = this.state.ConditionCode.filter((e) => {
        return e.id == id;
      });
      newConditionArray = newConditionArray.map((e) => {
        if (["selectedAccessories"] in removerlist[0]) {
          return {
            ...e,
            options: [...e.options, removerlist[0].selectedAccessories],
          };
        } else {
          return e;
        }
      });
      let priceofRemoved = removerlist[0]?.accessories_total_withtax;
      if (["selectedAccessories"] in removerlist[0]) {
        this.setState(
          {
            ConditionCode: [...newConditionArray],
            Total_Accessories_Amount: Math.abs(
              this.state.Total_Accessories_Amount - priceofRemoved
            ),
            disableaddnew: false,
            AccessoriesOption: [
              ...this.state.AccessoriesOption,
              removerlist[0].selectedAccessories,
            ],
          },
          () => {
            this.GetSubsidy();
          }
        );
      } else {
        this.setState(
          {
            ConditionCode: [...newConditionArray],
            Total_Accessories_Amount: Math.abs(
              this.state.Total_Accessories_Amount - priceofRemoved
            ),
            disableaddnew: false,
          },
          () => {
            this.GetSubsidy();
          }
        );
      }
    } else {
      const removerlist = this.state.ConditionCode.filter((e) => {
        return e.id == id;
      });
      let priceofRemoved = removerlist[0]?.accessories_total_withtax;
      this.setState(
        {
          ConditionCode: [
            {
              id: 1,
              accid: "",
              accessories_name: "",
              accessories_amount: "",
              hsn: "",
              igst: "",
              igst_amount: "",
              sgst: "",
              sgst_amount: "",
              cgst: "",
              cgst_amount: "",
              accessories_total_withtax: "",
              options: removerlist[0]["selectedAccessories"]
                ? [
                    ...this.state.AccessoriesOption,
                    removerlist[0]["selectedAccessories"],
                  ]
                : [...this.state.AccessoriesOption],
            },
          ],
          Total_Accessories_Amount: Math.abs(
            this.state.Total_Accessories_Amount - priceofRemoved
          ),
          AccessoriesOption: removerlist[0]["selectedAccessories"]
            ? [
                ...this.state.AccessoriesOption,
                removerlist[0]["selectedAccessories"],
              ]
            : [...this.state.AccessoriesOption],
        },
        () => {
          this.GetSubsidy();
        }
      );
    }
  };
  delete_api = () => {
    const url = Urls.python_baseurl + "/sp_delete_M1_Accesories";
    var data = JSON.stringify({
      ID: this.state.accssory_id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (this.state.ConditionCode.length == 1) {
            const removerlist = this.state.ConditionCode.filter((e) => {
              return e.id == this.state.delete_id;
            });
            let priceofRemoved = removerlist[0]?.accessories_total_withtax;
            this.setState(
              {
                ConditionCode: [
                  {
                    id: 1,
                    accid: "",
                    accessories_name: "",
                    accessories_amount: "",
                    hsn: "",
                    igst: "",
                    igst_amount: "",
                    sgst: "",
                    sgst_amount: "",
                    cgst: "",
                    cgst_amount: "",
                    accessories_total_withtax: "",
                    options: [
                      ...this.state.AccessoriesOption,
                      removerlist[0]?.selectedAccessories,
                    ],
                  },
                ],
                AccessoriesOption: [
                  ...this.state.AccessoriesOption,
                  removerlist[0]?.selectedAccessories,
                ],
                Total_Accessories_Amount: Math.abs(
                  this.state.Total_Accessories_Amount - priceofRemoved
                ),
              },
              () => {
                this.GetSubsidy();
              }
            );
          } else {
            let newConditionArray = this.state.ConditionCode.filter((e) => {
              return e.id != this.state.delete_id;
            });
            const removerlist = this.state.ConditionCode.filter((e) => {
              return e.id == this.state.delete_id;
            });
            console.log(removerlist, "klklk");
            newConditionArray = newConditionArray.map((e) => {
              return {
                ...e,
                options: [...e?.options, removerlist[0]?.selectedAccessories],
              };
            });
            let priceofRemoved = removerlist[0]?.accessories_total_withtax;
            this.setState(
              {
                ConditionCode: [...newConditionArray],
                Total_Accessories_Amount:
                  this.state.Total_Accessories_Amount - priceofRemoved,
                AccessoriesOption: [
                  ...this.state.AccessoriesOption,
                  removerlist[0]?.selectedAccessories,
                ],
                disableaddnew: false,
              },
              () => {
                this.GetSubsidy();
              }
            );
          }
          $("#GLKickOut_Popup").modal("hide");
          $("#payment_error_modal_2").modal("hide");
          $("#payment_error_modal_2").modal("show");
          // this.setState({
          //   ConditionCode:[]
          // },()=>{
          //   this.displayBookingM3()
          // })
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GLKickOutPopup = () => {
    return (
      <div
        class="modal"
        id="GLKickOut_Popup"
        style={{ marginLeft: "25%" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog-Memberinfo"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog" style={{ maxHeight: "initial" }}>
            <div>
              <div class="modal-body" style={{ fontSize: "16px" }}>
                Are you sure want to delete?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn light_blue1"
                  data-dismiss="modal"
                  onClick={() => {
                    $("#GLKickOut_Popup").modal("hide");
                    $("#payment_error_modal_2").modal("hide");
                    $("#payment_error_modal_2").modal("show");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn light_blue1"
                  onClick={this.delete_api}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  ModelVarientPopUP = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(33, 37, 41, 0.8)",
          marginLeft: "-1.5%",
        }}
        class="modal "
        id="myModal2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                style={{ opacity: "100%" }}
                class="close"
                onClick={this.handelClose}
              >
                <button
                  style={{ opacity: "100%" }}
                  className="btn Opertion_btn clickable mt-1"
                >
                  Back
                </button>
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.showModelVarient()}
            </div>
            <div class="card">
              <div class="card-header" id="headingFive">
                <h5 class="mb-0">
                  <button
                    style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                    class="btn  collapsed"
                    data-toggle="collapse"
                    data-target="#CollapseFive"
                    aria-expanded="true"
                    aria-controls="CollapseFive"
                  >
                    Accessories
                  </button>
                </h5>
              </div>
              <div
                id="CollapseFive"
                class="collapse show "
                aria-labelledby="headingFive"
              >
                <div style={{ overflowX: "scroll" }} class="card-body">
                  <table
                    // className="table table-bordered mt-2"
                    id="userList"
                    align="center"
                    style={{ width: "130%" }}
                  >
                    <thead>{this.accessoriesHeader()}</thead>
                    <tbody>{this.accessoriesBody()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  close() {
    return (
      <div style={{ marginLeft: "2px" }}>
        <nav>
          <button
            type="button"
            class="close close-btn"
            style={{ marginTop: "-2%" }}
            // data-dismiss="modal"
            // aria-label="Close"
            onClick={() => {
              this.handelClose();
            }}
          >
            <span class="" aria-hidden="true">
              &times;
            </span>
          </button>
        </nav>
      </div>
    );
  }
  saveLead = (event) => {
    this.setState({
      saveLoader: true,
    });
    const url =
      Urls.python_baseurl + "/Save_Call_Tck_Sp_CRM_CustomerMaster_Save";
    var data = JSON.stringify({
      CRMCustID: "0",
      GlobalCustID: "0",
      CustType: `${parseInt(this.state.Type)}`,
      Title: `''`,
      IsMTICust: "'Y'",
      Name: `'${this.state.Name}'`,
      Address1: `'${this.state.address1}'`,
      Address2: `'${this.state.address2}'`,
      pincode: `'${this.state.pincode}'`,
      State: `${parseInt(this.state.State)}`,
      District: `${parseInt(this.state.District)}`,
      Region: `${this.state.Region}`,
      City: `'${this.state.City}'`,
      Country: `${this.state.Country_id}`,
      Mobile: `'${this.state.Mobileno}'`,
      Email: `'${this.state.email}'`,
      priApp: "0",
      secApp: "0",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              CRMCustID: res[0].newID,
              DocName: "CRMPE",
            },
            () => {
              this.saveM0details();

              // this.createleadno()
              // if(this.state.leadno_new.length>0)
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  saveM0details = (event) => {
    const url = Urls.python_baseurl + "/Save_Lead_Sp_M0Master_Save";
    var data = JSON.stringify({
      iID: this.state.M_M0Masterid ? this.state.M_M0Masterid : 0,
      sID: 0,
      iDealerID: sessionStorage.getItem("dealerid"),
      iHOBrId: sessionStorage.getItem("dealerid"),
      CustType: parseInt(this.state.Type),
      prefix: "1",
      Name: `'${this.state.Name}'`,
      firstName: "''",
      lastname: "''",
      Address1: `'${this.state.address1}'`,
      Address2: `'${this.state.address2}'`,
      City: `'${this.state.City}'`,
      pincode: `'${this.state.pincode}'`,
      Region: this.state.Region,
      State: parseInt(this.state.State),
      District: parseInt(this.state.District),
      Taluka: this.state.Town ? parseInt(this.state.Town) : 0,
      Country: "1",
      Mobile: `'${this.state.Mobileno}'`,
      Email: `'${this.state.email}'`,
      priApp: "0",
      secApp: "0",
      MTICust: `'${this.state.ext_mit_cust}'`,
      M0Financier: 0,
      bodybuilder: "''",
      Doneby: "'D'",
      LastPurchdate: `'${moment(this.state.startDate).format("DD-MM-YYYY")}'`,
      FleetQty: "0",
      KAM: `'${this.state.kam_customer}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              M0_id: res[0].ID,
            },
            () => {
              this.lead_CRM_save();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  lead_CRM_save = (event) => {
    const url = Urls.python_baseurl + "/Save_Lead_SP_M0Details";
    var data = JSON.stringify({
      iID: this.state.lead_id ? this.state.lead_id : 0,
      iDealerID: sessionStorage.getItem("dealerid"),
      iHOBrId: sessionStorage.getItem("dealerid"),
      iCustID: parseInt(this.state.M0_id),
      LeadNo: `'${this.state.docno}'`,
      DocDate: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      Confirm: "'Y'",
      Cancel: "'N'",
      TcktID: "0",
      D: "'D'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              newID_crm_id: res[0].newID,
            },
            () => {
              // this.state.lead_id === "" && this.save_Enquir_no();
              this.saveEnquiry();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  maxNo = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      DocName: "'M0'",
      DealerId: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            //
            // this.saveEnquiry();
            // alert("Record Saved Succesfully");
            // sessionStorage.setItem("ActiveTab", "Lead");
            // this.props.history.push("/tiles_tabs");
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Validate = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emailisvalid = emailRegex.test(this.state.email);

    if (!emailisvalid && this.state.email != "") {
      alert("Please Enter Valid Email Id.");
      return;
    }
     else if (this.state.Name == "") {
      alert("Please Enter the Name.");
      return;
    } else if (this.state.Mobileno == "") {
      alert("Please Enter Mobile No.");
      return;
    } else if (this.state.Mobileno.match(/\d/g).length !== 10) {
      alert("Please Enter Valid Mobile No.");
      return;
    } else if (this.state.Source == "" && this.state.Source == 0) {
      alert("Please Select the Source.");
      return;
    } else if (this.state.Type == "" && this.state.Type == 0) {
      alert("Please Select the Type.");
      return;
    } else if (this.state.address1 == "") {
      alert("Please Enter the Address1.");
      return;
    } else if (this.state.pincode == "") {
      alert("Please Enter the Pincode.");
      return;
    } else if (this.state.City == "") {
      alert("Please Enter the City.");
      return;
    } else if (!this.state.State) {
      alert("Please Select the State.");
      return;
    } else if (this.state.State == 0) {
      alert("Please Select the State.");
      return;
    } else if (!this.state.District) {
      alert("Please Select the District.");
      return;
    } else if (this.state.District == 0) {
      alert("Please Select the District.");
      return;
    } else if (this.state.Attended_By == 0) {
      alert("Please Select the Attended By.");
      return;
    } else if (this.state.Attended_By == "Select") {
      alert("Please Select the Attended By.");
      return;
    }
    else if (this.state.ModelCategory == "") {
      alert("Please Select the Model.");
      return;
    } else if (this.state.ModelCategory == 0) {
      alert("Please Select the Model.");
      return;
    } else if (this.state.ModelCode == "") {
      alert("Please Select the Model Code.");
      return;
    } else if (this.state.ModelCode == 0) {
      alert("Please Select the Model Code.");
      return;
    } else if (this.state.Quantity == "") {
      alert("Please Enter the Quantity.");
      return;
    } else if (this.state.visit_details == "") {
      alert("Please Select the Follow-up Details.");
      return;
    } else if (this.state.visit_details == 0) {
      alert("Please Select the Follow-up Details.");
      return;
    } else if (this.state.startDate == "") {
      alert("Please Enter the Follow-up Date.");
      return;
    } else if (this.state.next_visit_details == 0) {
      alert("Please Select the Next Follow-up Date.");
      return;
    } else if (this.state.next_visit_details == "") {
      alert("Please Select the Next Follow-up Date.");
      return;
    } else if (this.state.endDate == "") {
      alert("Please Enter the Next Follow-up Date.");
      return;
    } else if (this.state.Insurance_company == 0 ) {
      alert("Please Select the Insurance Company. ");
      return;
    } else if (
      (this.state.AMV_Insurance_Year == "0" ||
      this.state.AMV_Insurance_Year == "") 
    ) {
      alert("Please Enter the Insurance Type. ");
      return;
    } else if (this.state.AMV_Insurance_Amount == "" && this.state.Is_Insurance!='Y') {
      alert("Please Enter the Insurance Amount. ");
      return;
    } else if (this.state.Model_test == "" || this.state.Model_test==0) {
      alert("Please Enter the Model code. ");
      return;
    }
    else if (this.state.Model_Code_test == "" || this.state.Model_Code_test==0) {
      alert("Please Enter the Model code. ");
      return;
    }else if (this.state.Helmet == "" || this.state.Helmet==0 || this.state.Helmet=="0") {
      alert("Please Enter the Helmet Provided. ");
      return;
    }else if (this.state.License_number != "" && this.state.License_number.length!=16) {
      alert("Please Enter the Correct License Number. ");
      return;
    }
    this.setState({
      isSaveClicked: true,
    });
    // this.state.lead_id === "" && this.Lead_no();
    this.state.lead_id === "" && this.QuotationNumber();
    this.state.lead_id != "" && this.saveLead();
  };
  handelCancel = () => {
    $("#enquirylost_Popup").modal("hide");
  };
  enquirylostPopup = () => {
    return (
      <div
        class="modal"
        id="enquirylost_Popup"
        style={{ marginLeft: "25%" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog-Memberinfo"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog" style={{ maxHeight: "initial" }}>
            <div>
              <div class="modal-body" style={{ fontSize: "16px" }}>
                Are you sure want to Lost Enquiry?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn light_blue1"
                  data-dismiss="modal"
                  onClick={() => {
                    $("#enquirylost_Popup").modal("hide");
                    $("#payment_error_modal_2").modal("hide");
                    $("#payment_error_modal_2").modal("show");
                    this.setState({
                      isSaveClicked: false,
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn light_blue1"
                  onClick={()=>{this.Cancel_Enquiry()}}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  Validate_cancel = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emailisvalid = emailRegex.test(this.state.email);

    if ( this.state.Region == "") {
      alert("Please Enter Valid Region");
      $("#enquirylost_Popup").modal("hide");
      $("#payment_error_modal_2").modal("hide");
      $("#payment_error_modal_2").modal("show");
      return;
    }
     else if (this.state.Cancel_Remark == "" || this.state.Cancel_Remark == "0" ) {
      alert("Please Enter the Remark.");
      $("#enquirylost_Popup").modal("hide");
      $("#payment_error_modal_2").modal("hide");
      $("#payment_error_modal_2").modal("show");
      return;
    }
    this.setState({
      isSaveClicked: true,
    });
    $("#enquirylost_Popup").modal("show");
      // this.Cancel_Enquiry();
  }
  Cancel_Enquiry = (event) => {
    const url = Urls.python_baseurl + "/SP_Save_M1LostReason";
    var data = JSON.stringify({
        "ID": this.state.lost_ID ? this.state.lost_ID : 0,
        "UserId": `'${sessionStorage.getItem("UserId")}'`,
        "HOBrID": `'${sessionStorage.getItem("dealerid")}'`,
        "Lead_hdr_ID": `'${this.state.M1_id ? this.state.M1_id : 0}'`,
        "rsn_Id": `'${this.state.Cancel_Remark}'`,
        "CompID": `''`,
        "CompetitorMake":`''`,
        "Qty":`${ parseInt(this.state.Quantity)}`,
        "UserRoleID": `'${sessionStorage.getItem("UserRole")}'` 
    });

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length>0) {
          if(res[0].newID){
            alert("Enquiry Cancelled Succesfully")
            this.setState({
              isSaveClicked: false,
            });
          }
        
        window.location.reload()
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  hanselShowQuotationReceipt = () => {
    $("#payment_error_modal_2").modal("hide");
    this.props.history.push({
      pathname: "/Quotation_Report",
      state: {
        data: this.props.EnquiryDetails,
        id: this.props.id,
        name: this.props.customer_name,
        Title: this.props.Title,
        Enquiry_Status: this.props.Enquiry_Status,
      },
    });
  };
  visitDetailsTable() {
    let columnDefs = [
      { headerName: "Follow-up Details", field: "obj_desc", width:"200px" },
      { headerName: "Date", field: "obj_date", width:"200px" },
      {
        headerName: "Next Follow-up Scheduled",
        field: "next_obj_desc",
        width:"200px"
      },
      { headerName: "Next Follow-up Date", field: "next_date", width:"200px" },
      {
        headerName: "Remark",
        field: "Remark",
        width:"250px",
      }
    ]
    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {}}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <h5 className="heading_text">
          Enquiry {this.state.Enquiry_Status == "Closed" ? "(Confirmed)" :this.state.Enquiry_Status == "Lost"?"(Lost)": ""}
        </h5>
        <div className=" row justify-content-end  mt-2">
          {this.state.Enquiry_Status == "Closed" || (this.state.Enquiry_Status == "Lost" ) ? (
            ""
          ) : (
            <button
              disabled={this.state.isSaveClicked}
              type="submit"
              className={"btn Opertion_btn clickable"}
              style={{marginRight:"65px"}}
              onClick={this.Validate}
            >
              {this.state.saveLoader && (
                <span
                  class="spinner-grow spinner-grow-sm"
                  style={{ color: "unset", marginRight: "5px" }}
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Save
            </button>
          )}
          <button
            hidden={this.state.Enquiry_Status !== "Open" ? true : false}
            type="submit"
            style={{marginLeft:"-50px"}}
            disabled={
              this.state.Enquiry_Status == "Open" && this.props.EnquiryDetails
                ? false
                : true
            }
            className={"btn Opertion_btn clickable mr-3"}
            onClick={
              this.state.Enquiry_Status == "Open" &&
              this.props.EnquiryDetails &&
              this.hanselShowQuotationReceipt
            }
          >
            Print Quotation
          </button>
        </div>

        <div className="mt-2">
          {this.renderMemberinfo()}
          <br></br>
          <br></br>
        </div>
        {this.ModelVarientPopUP()}
        {this.GLKickOutPopup()}
        {this.enquirylostPopup()}
       {this.state.newurl&&this.ExcelPOPUP()}
      </div>
    );
  }
}
