import React from "react";
import Urls from "../../../helpers/Urls";
import "../../../app/containers/color.css";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
const bcrypt = require("bcryptjs");
const $ = window.$;
let Po_Total = 0,
  Payment_total = 0,
  Previous_Po_Total = 0,
  TotalQuantity = 0,
  TotalApprovedQuantity = 0,
  TotalPayment = 0,
  TotalPaymentPercentage = 0;

export class PaymentApproval extends React.Component {
  constructor(props) {
    console.log("props", props);
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showFieldOnAddNew: false,
      EnquiryOption: [],
      FinancerOPtion: [],
      Dealer_Financier: "",
      Approval_Date: "",
      Model_Option: [],
      Model_Category_Option: [],
      temparray: [],
      newurl: [],
      Model_Code_Option: [],
      newflag: false,
      PO_No: "",
      PO_Date: moment(new Date()).format("DD-MM-YYYY"),
      Enquiry_No: "",
      Depot: "",
      Quantity: "",
      Road_Premit_No: "",
      Validity_Date: "",
      Payment_Details: "",
      Approval_No: "",
      Location: "",
      PlantOption: [],
      newData: [],
      POid_new: 0,
      Status: "",
      modeldata: [],
      AddressType: [],
      Model_Id: null,
      Po_num: "",
      finacial_year_no: "",
      PO_id: 0,
      get_id: 0,
      update_id: 0,
      latest_count: 0,
      MAinHdr:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.POID
          ? props.location.state.POID
          : 0,
      // props.location.state.data[0].Delar_PO_List
      //   ? props.location.state.data[0].Delar_PO_List
      //   : false,
      BilledType: "",
      ShippedType: "",
      ShippedToID: "0",
      BilledToID: "0",
      api_delete_id: 0,
      tabel_disable: false,
      Depot_id: "",
      Depot_list: [],
      Depot: 0,
      delete_rowid: 0,
      fin_year: sessionStorage.getItem("fin_year"),
      Dealer_Code: sessionStorage.getItem("Dealer_Code"),
      Delar_PO_List: false,
      answers_mapname: [
        {
          id: 0,
          Model_Category: "",
          Model_Code: "",
          Model: "",
          Model_Colour: "",
          Model_Price: "",
          Quantity: "",
          discount: 0,
          Total: "",
          Approved_Quantity: 0,
        },
      ],
      paymentDetailsArray: [
        {
          id: 0,
          Company_Account: "",
          Amount: "",
          Account_No: "",
          Payment_Mode: "",
          Bank_Name: "",
          Payment_Reference_No: "",
          Payment_Date: "",
          Payment_Made_By: "",
          Image_Name: "",
          Download_Url: "",
        },
      ],
      Dealer_vehicle_Code: "",
      DealerCategoryOption: [],
      StateOption: [],
      Po_Total: 0,
      Payment_total: 0,
      bulkUpload: false,
      BulkUploadFile: "",
      ModelDetialsCount: "",
      DealerOption: [],
      Dealerid: sessionStorage.getItem("dealerid"),
      payment_remark:""
    };
  }
  callAllAPI() {
    this.getEnquiryOption();
    this.GetFinancerOption();
    this.GetModelCategory();
    this.GetDepot();
    this.getDealerType();
    this.MasterAPICall();
    this.getState();
    this.getDealerDetails();
    this.getAddressType();
  }
  componentDidMount() {
    this.getDealer();
    this.MasterAPICall();
  }
  getDealer = () => {
    const url = Urls.python_baseurl + "/SP_Get_Dealer";
    var data = JSON.stringify({
      Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
      RegionID: "'All'",
      StateID: "'All'",
      DistrictID: "'All'",
      TalukaID: "'All'",
      Dealer_Category: "'All'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            DealerOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  savePO_number = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "PO",
      iDealerID: this.state.Dealerid,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              PO_No:
                this.state.Dealer_Code +
                "" +
                "PO" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              this.handelSave();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  PO_number = () => {
    console.log("sdfghjkl");
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "PO",
      iDealerID: this.state.Dealerid,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // Po_num: res[0].DocMaxNo,
              PO_No:
                this.state.Dealer_Code +
                "" +
                "PO" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              // this.financiaryear_PO_No();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  // financiaryear_PO_No = () => {
  //   const url = Urls.python_baseurl + "/SP_GetDataFromDataQuery";
  //   var data = JSON.stringify({
  //     ValGetDataQueryType: "15",
  //     iParentId: this.state.Dealerid,
  //     sAdditionalCondition: "''",
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState(
  //           {
  //             finacial_year_no: res[0].Value,
  //           },
  //           () => {
  //             this.delarcode_PO_No();
  //           }
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  // delarcode_PO_No = () => {
  //   const url = Urls.python_baseurl + "/Get_Dealer_SP_GET_Dealercode";
  //   var data = JSON.stringify({
  //     DealerID: this.state.Dealerid,
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res) {
  //         this.setState({
  //           Dealer_vehicle_Code: res[0].Dealer_vehicle_Code,
  //           PO_No:
  //             res[0].Dealer_vehicle_Code +
  //             "" +
  //             "PO" +
  //             "" +
  //             this.state.finacial_year_no +
  //             "" +
  //             this.state.Po_num.toString().padStart(6, '0'),
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetPODetailsPaymentApproval";
    var data = JSON.stringify({
      Dealer_ID: `'${this.state.Dealerid}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        const value = {
          data: {
            ID: "",
          },
        };
        if (res && res.length > 0) {
          value.data["PO No"] = res[0]["PO No"];
          this.setState({
            rowData: res,
          });
        } else {
          this.setState({
            rowData: [],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        // this.GetModelDetails();
      });
  };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/ModelGroup_GetModelGroup";
    var data = JSON.stringify({
      id: "null",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Category_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  GetDepot = () => {
    const url = Urls.python_baseurl + "/sp_DepotMaster_display";
    var data = JSON.stringify({
      id: `'${this.state.Depot_id}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Depot_list: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getAddressType = () => {
    const url = Urls.python_baseurl + "/SP_Get_DealerWiseAddressType";
    var data = JSON.stringify({
      DealerID: `${this.state.Dealerid}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            AddressType: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        this.getPODetails();
      });
  };
  GetModelList = (event) => {
    let row = [];
    this.state.Model_Option.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option>{element.Name}</option>);
    });
    return row;
  };

  GetModel = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 197,
      ID: 0,
      condition: "'and Model_cat_ID=1002'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getPODetails = (event) => {
    const url = Urls.python_baseurl + "/DealerPoDisplay_SP_GetDealerVehPO";
    var data = JSON.stringify({
      PO_NO: `''`,
      POType: "'All'",
      DealerId: `${this.state.Dealerid}`,
      HOBrID: `${this.state.Dealerid}`,
      ID: `${this.state.MAinHdr}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("test", res[0]);

        if (res && res.length > 0) {
          Payment_total = res[0][0]?.TotalPaymentReceived;
          Po_Total = res[0][0]?.TotalPaymentReceived;

          this.setState({
            PrevPOTotal: res[0][0]?.TotalPOAmt,
            PO_No: res[0][0]?.PO_No,
            PO_Date: moment(res[0][0]?.PO_Date, "DD-MM-YYYY").format(
              "DD-MM-YYYY"
            ),
            // Customer: res[0][0]?.name,
            Depot: res[0][0]?.Depot,
            Road_Premit_No: res[0][0]?.RoadPermitNo,
            Plant: res[0][0]?.Plant,
            Model_Price: res[0][0]?.ModelPrice,
            tabel_disable: res[0][0]?.Is_Confirm == "Y" ? true : false,
            // MAinHdr: res[0][0][0]?.Main_hdr,
            BilledToID: res[0][0]?.BilledToID,
            BillToAddress: this.state.AddressType?.filter(
              (e) => e.ID == res[0][0]?.BilledToID
            )[0]?.Address,
            ShippedToID: res[0][0]?.ShippedToID,
            shippedToAddress: this.state.AddressType?.filter(
              (e) => e.ID == res[0][0]?.ShippedToID
            )[0]?.Address,
            BilledType: res[0][0]?.BilledType,
            ShippedType: res[0][0]?.ShippedType,
            Role_ID: res[0][0]?.Role_ID,
            disablesendtotally: res[0][0]?.PaymentApproval_Flag == "Y" ? true : false,
            payment_remark:res[0][0]?.Payment_Remark
          });
          this.setState({
            python_API: false,
            count: res && res.length > 0 ? res.length - 1 : 0,
            double_save: false,
          });
          this.createModelDetails(res[0]);
          this.createPaymentDetails(res[1]);
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {});
  };
  createModelDetails = async (array, model_code = "") => {
    let ModelDetails = await Promise.all(
      array.map(async (e, index) => {
        const modelGroupUrl =
          Urls.python_baseurl +
          "/DataFromModelCode_GetModelCode_FromModelGroup";
        const modelGroupData = JSON.stringify({
          ModelGroup: parseInt(e?.Model_GP),
        });

        const modelGroupResponse = await fetch(modelGroupUrl, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: modelGroupData,
        });

        const modelGroupRes = await modelGroupResponse.json();

        if (modelGroupRes && modelGroupRes.length > 0) {
          this.setState({
            newflag: model_code != "" ? true : false,
            ["Model_Code_Option" + index]: modelGroupRes,
            ["Model_Cat_ID" + index]: e?.Model_GP,
          });
          const modelMrpUrl = Urls.python_baseurl + "/getModelMrp_GetModdelMRP";
          const modelMrpData = JSON.stringify({
            Dealer: this.state.Dealerid,
            ModelID: parseInt(e?.Model_ID),
            StateCode: `${sessionStorage.getItem("Dealer_state")}`,
          });
          const modelMrpResponse = await fetch(modelMrpUrl, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: modelMrpData,
          });

          const modelMrpRes = await modelMrpResponse.json();

          if (modelMrpRes) {
            this.setState({
              ["Model_Id" + index]: e?.Model_ID,
              ["Model_Price" + index]:
                this.state.Dealer_Category == "Showroom Distributor"
                  ? e?.ModelPrice
                  : e?.ModelPrice,
              ["Model" + index]: modelMrpRes[1][0]["Model code"],
              ["Model_Colour" + index]: modelMrpRes[1][0].Model_Colour,
              ["discount" + index]:
                e?.Is_Confirm == "Y"
                  ? e?.discount
                  : e?.discount
                  ? parseFloat(e?.discount)
                  : 0,
            });
            console.log(
              ["Model_Code_Option" + index].filter(
                (item) => item.ID == e?.Model_ID
              ),
              "dssdjs"
            );
            return {
              id: index,
              Model_Category: this.state.Model_Category_Option.filter(
                (item) => item.ID === e?.Model_GP
              )[0]?.["Model Group"],
              Model_Code: modelMrpRes[1][0]?.["Model code"],
              Model_id: modelMrpRes[1][0]?.ID,
              Model_Colour: modelMrpRes[1][0]?.["Model_Colour"],
              Model_Price: e?.ModelPrice,
              Quantity: e?.Qty,
              discount: e?.discount,
              Total: e?.total,
              get_id: e.DetailID,
              modelgrp: this.state.Model_Category_Option.filter(
                (item) => item.ID === e?.Model_GP
              )[0]?.ID,
              Approved_Quantity: e.Approve_Quantity
                ? e.Approve_Quantity
                : e?.Qty,
              discount:
                e?.Is_Confirm == "Y"
                  ? e?.discount
                  : modelMrpRes[3][0].Amount
                  ? parseFloat(modelMrpRes[3][0].Amount)
                  : 0,
              Role_ID: e?.Role_ID,
            };
          }
        }
      })
    );
    this.setState({
      answers_mapname: ModelDetails,
    });
  };
  createPaymentDetails = (Array) => {
    let newPaymentDetails = Array.map((e, index) => {
      return {
        id: index,
        Company_Account: e.Company_Account,
        Amount: e.Amount,
        Account_No: e.Amount_No,
        Payment_Mode: e.Mode_Of_Payment,
        Bank_Name: e.Bank_Name_Address,
        Payment_Reference_No: e.Payment_Ref_No,
        Payment_Date: e.Payment_Date
          ? moment(e.Payment_Date).format("YYYY-MM-DD")
          : "",
        Payment_Made_By: "",
        Image_Name: "",
        Download_Url: e.download_url,
        mainhdr_id: e.mainhdr_id,
        Pyament_ID: e?.ID,
        FileName: e?.FileName,
        Is_Confirm: e?.Is_Confirm == "Y" ? true : false,
        isUpdate: true,
        IFSC_Code: e?.Company_Bank_IFSC,
      };
    });
    this.setState({
      paymentDetailsArray: newPaymentDetails,
    });
  };
  getEnquiryOption = () => {
    const url = Urls.python_baseurl + "/DealerPoEnquiry_sp_getEnquiryNew";
    var data = JSON.stringify({
      iDealerID: `${this.state.Dealerid}`,
      iHOBr_id: `${this.state.Dealerid}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "podetails");
        if (res && res.length > 0) {
          this.setState({ EnquiryOption: res });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetFinancerOption = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "83",
      ID: "0",
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "podetails");
        if (res && res.length > 0) {
          this.setState({ FinancerOPtion: res });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Validate = () => {
    let is_ModelValid = true;
    console.log(this.state.answers_mapname, "hgfdse");
    for (let i = 0; i < this.state.answers_mapname.length; i++) {
      if (
        this.state.answers_mapname[i].Model_Price == "" ||
        this.state.answers_mapname[i].Approved_Quantity == "" ||
        this.state.answers_mapname[i].Total == ""
      ) {
        is_ModelValid = false;
        break;
      }
    }
    if (this.state.PO_No == "") {
      alert("Please Click on Add New First To Generate Sales Order No.");
      return;
    } else if (this.state.PO_Date == "") {
      alert("Please Click on Add New First To Generate Sales Order Date.");
      return;
    }
    else if (this.state.payment_remark == "") {
      alert("Please Enter Remark.");
      return;
    }
    else if (!is_ModelValid) {
      alert(
        "All field are mandatory in 'Model Details' and Approved Quantity cannot be zero"
      );
      return;
    } else {
      this.setState(
        {
          double_save: true,
        },
        () => {
          this.state.MAinHdr == 0 && this.handelSave();
          this.state.MAinHdr != 0 && this.handelSave();
        }
      );
    }
  };
  handelSave = () => {
    const result = [];
    for (let i = 0; i < this.state.answers_mapname.length; i++) {
      const entry = this.state.answers_mapname[i];
      let found = false;
      for (let j = 0; j < result.length; j++) {
        if (
          result[j]?.Model_Category === entry?.Model_Category &&
          result[j]?.Model_Code === entry?.Model_Code
        ) {
          result[j].Quantity =
            parseInt(result[j]?.Quantity) + parseInt(entry?.Quantity);
          result[j].discount =
            parseInt(result[j]?.discount) + parseInt(entry?.discount);
          result[j].Total = parseInt(result[j].Total) + parseInt(entry.Total);
          found = true;
          break;
        }
      }
      if (!found) {
        result.push(entry);
      }
    }
    let data1 = result.map((e) => {
      let iid = 0;
      if (e?.get_id) {
        iid = e?.get_id;
      }
      return {
        iID: `${iid}`,
        // `'${this.state.PO_id}'`,
        dealerID: `${this.state.Dealerid}`,
        HOBrID: `${this.state.Dealerid}`,
        PONo: `'${this.state.PO_No}'`,
        PODate: `'${this.state.PO_Date}'`,
        Confirm: this.state.Status !== "Confirmed" ? "'N'" : "'Y'",
        Cancel: "'N'",
        POType: "0",
        M7ID: "0",
        Model: `${e.Model_id}`,
        Plant: `${this.state.Plant ? this.state.Plant : 1}`,
        // depo: `'${this.state.Depot}'`,
        depo: `'1'`,
        qty: `'${e.Quantity}'`,
        modelrate: `'${e.Model_Price}'`,
        RoadPermitNo: `'${this.state.Road_Premit_No}'`,
        RoadPermitDate: "''",
        modelgrp: `${e.modelgrp}`,
        changeqty: "''",
        PaymentDetails: "''",
        AppNo: "''",
        AppDate: "''",
        Appflag: "'N'",
        AppConfirm: "'Y'",
        TypeId: "''",
        Finc: "''",
        Loc: "''",
        DoneBy: "''",
        UserRole: `'${sessionStorage.getItem("UserRole")}'`,
        ModelCat: "0",
        discount: `${e.discount != "" ? e.discount : 0}`,
        total: `${parseFloat(
          e.Model_Price * e.Approved_Quantity -
            (e.discount != "" ? e.discount * e.Approved_Quantity : 0)
        ).toFixed(2)}`,
        MAinHdr: `${this.state.MAinHdr ? this.state.MAinHdr : 0}`,
        DlrCode: `'${this.state.Dealer_Code}'`,
        FinYear: `'${sessionStorage.getItem("fin_year")}'`,
        PO_total: `${Po_Total}`,
        Payment_total: `${Payment_total}`,
        Payment_Percentage: `${TotalPaymentPercentage}`,
        BilledToID: this.state.BilledToID ? `${this.state.BilledToID}` : "0",
        ShippedToID: this.state.ShippedToID ? `${this.state.ShippedToID}` : "0",
        BilledType: this.state.BilledType ? `'${this.state.BilledType}'` : "''",
        ShippedType: this.state.ShippedType
          ? `'${this.state.ShippedType}'`
          : "''",
        Approve_Quantity: `${e?.Approved_Quantity}`,
        TotalOldPOAmt: `${Previous_Po_Total}`,
        TotalOldAmt: `${parseFloat(
          e.Model_Price * e.Quantity -
            (e.discount != "" ? e.discount * e.Quantity : 0)
        ).toFixed(2)}`,
        User_ID: `${sessionStorage.getItem("UserId")}`,
        SendtOTally: "N",
        Po_Approve_Quantity: `${TotalApprovedQuantity}`,
        Total_Quantity: `${TotalQuantity}`,
        Approve_ID: `${sessionStorage.getItem("UserId")}`,
        Remark:this.state.remark?`'${this.state.remark}'`:"''"
      };
    });
    const url = Urls.python_baseurl + "/Save_DealerPo_SP_Save_DealerVehPO";
    var data = JSON.stringify(data1);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "poyhgt");
        if (res && res.length > 0) {
          this.setState({}, () => {
            let a = this.state.MAinHdr ? this.state.MAinHdr : 0;
            if (a === 0) {
              this.setState(
                {
                  MAinHdr: res[0][0].ID,
                  doubleClick: false,
                },
                () => {
                  this.approvePayment("N")
                  this.Main_Header_PaymentDetailsSave("N");
                  alert("Record Approved Succesfully.");
                }
              );
            } else {
              this.approvePayment("N")
              this.Main_Header_PaymentDetailsSave("N");
              alert("Record Approved Succesfully.");
            }
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        this.setState({
          doubleClick: false,
        });
      });
  };
  handelConfirm = () => {
    let is_ModelValid = true;
    console.log(this.state.answers_mapname, "hgfdse");
    for (let i = 0; i < this.state.answers_mapname.length; i++) {
      if (
        this.state.answers_mapname[i].Model_Price == "" ||
        this.state.answers_mapname[i].Approved_Quantity == "" ||
        this.state.answers_mapname[i].Total == ""
      ) {
        is_ModelValid = false;
        break;
      }
    }
    if (this.state.payment_remark == "") {
      alert("Please Enter Remark.");
      return;
    }
    if (!is_ModelValid) {
      alert(
        "All field are mandatory in 'Model Details' and Approved Quantity cannot be zero"
      );
      return;
    }
    if (this.state.PO_No == "") {
      alert("Please Click on Add New First To Generate Sales Order No.");
      return;
    } else if (this.state.PO_Date == "") {
      alert("Please Click on Add New First To Generate Sales Order Date.");
      return;
    } else if (this.state.Plant == "" || this.state.Plant == 0) {
      alert("Please Select Plant");
      return;
    }
    //  else if (this.state.Depot == "" || this.state.Depot == 0) {
    //   alert("Please Select Depot");
    //   return;
    // }
    else {
      this.setState({
        double_save: true,
      });
    }
    const result = [];
    for (let i = 0; i < this.state.answers_mapname.length; i++) {
      const entry = this.state.answers_mapname[i];
      let found = false;

      for (let j = 0; j < result.length; j++) {
        if (
          result[j].Model_Category === entry.Model_Category &&
          result[j].Model_Code === entry.Model_Code &&
          result[j].Model === entry.Model
        ) {
          result[j].Quantity =
            parseInt(result[j].Quantity) + parseInt(entry.Quantity);
          result[j].discount =
            parseInt(result[j].discount) + parseInt(entry.discount);
          result[j].Total = parseInt(result[j].Total) + parseInt(entry.Total);
          found = true;
          break;
        }
      }

      if (!found) {
        result.push({ ...entry });
      }
    }
    if (this.state.MAinHdr > 0) {
      let data1 = result.map((e) => {
        if (e.get_id != 0) {
          let iid = 0;
          if (e?.get_id) {
            iid = e?.get_id;
          }

          return {
            iID: `${iid}`,
            dealerID: `${this.state.Dealerid}`,
            HOBrID: `${this.state.Dealerid}`,
            PONo: `'${this.state.PO_No}'`,
            PODate: `'${this.state.PO_Date}'`,
            Confirm: this.state.Status !== "Confirmed" ? "'N'" : "'Y'",
            Cancel: "'N'",
            POType: "1",
            M7ID: "0",
            Model: `${e.Model_id}`,
            Plant: `${this.state.Plant ? this.state.Plant : 1}`,
            // depo: `'${this.state.Depot}'`,
            depo: `'1'`,
            qty: `'${e.Quantity}'`,
            modelrate: `'${e.Model_Price}'`,
            RoadPermitNo: `'${this.state.Road_Premit_No}'`,
            RoadPermitDate: "''",
            modelgrp: `${e.modelgrp}`,
            changeqty: "''",
            PaymentDetails: "''",
            AppNo: "''",
            AppDate: "''",
            Appflag: "'Y'",
            AppConfirm: "'Y'",
            TypeId: "''",
            Finc: "''",
            Loc: "''",
            DoneBy: "''",
            UserRole: `'${sessionStorage.getItem("UserRole")}'`,
            ModelCat: "0",
            discount: `${e.discount ? e.discount : 0}`,
            total: `${parseFloat(
              e.Model_Price * e.Approved_Quantity -
                (e.discount != "" ? e.discount * e.Approved_Quantity : 0)
            ).toFixed(2)}`,
            MAinHdr: `${this.state.MAinHdr ? this.state.MAinHdr : 0}`,
            DlrCode: `'${this.state.Dealer_Code}'`,
            FinYear: `'${sessionStorage.getItem("fin_year")}'`,
            PO_total: `${Po_Total}`,
            Payment_total: `${Payment_total}`,
            Payment_Percentage: `${TotalPaymentPercentage}`,
            BilledToID: this.state.BilledToID
              ? `${this.state.BilledToID}`
              : "0",
            ShippedToID: this.state.ShippedToID
              ? `${this.state.ShippedToID}`
              : "0",
            BilledType: this.state.BilledType
              ? `'${this.state.BilledType}'`
              : "''",
            ShippedType: this.state.ShippedType
              ? `'${this.state.ShippedType}'`
              : "''",
            Approve_Quantity: `${e?.Approved_Quantity}`,
            TotalOldPOAmt: `${Previous_Po_Total}`,
            TotalOldAmt: `${parseFloat(
              e.Model_Price * e.Quantity -
                (e.discount != "" ? e.discount * e.Quantity : 0)
            ).toFixed(2)}`,
            User_ID: `${sessionStorage.getItem("UserId")}`,
            SendtOTally: "N",
            Po_Approve_Quantity: `${TotalApprovedQuantity}`,
            Total_Quantity: `${TotalQuantity}`,
            Approve_ID: `${sessionStorage.getItem("UserId")}`,
            Remark:this.state.remark?`'${this.state.remark}'`:"''"
          };
        }
      });

      console.log("rrrrrr", data1);
      const url = Urls.python_baseurl + "/Save_DealerPo_SP_Save_DealerVehPO";
      var data = JSON.stringify(data1);
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res, "poyhgt");
          if (res && res.length > 0) {
            this.approvePayment("Y")
            this.Main_Header_PaymentDetailsSave("Y");
            alert("Record Confirmed Succesfully.");
          }
        })
        .catch((err) => {
          process.env.NODE_ENV == "development" && console.log(err);
        })
        .finally(() => {
          this.setState({
            doubleClick: false,
          });
        });
    }
  };
  maxNo = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      DocName: "'PO'",
      DealerId: this.state.Dealerid,
    });
    console.log("newdata", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            this.setState(
              {
                double_save: false,
              },
              () => {
                this.getPODetails();
                alert("Record Saved Succesfully.");
              }
            );

            // window.location.reload();
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  AutoPopulateDetails = (enquiry_no) => {
    const url =
      Urls.python_baseurl + "/onchange_enquiry_GetM7detailsInVehPODealer";
    var data = JSON.stringify({
      DealerId: this.state.Dealerid,
      HOBrID: this.state.Dealerid,
      M7ID: enquiry_no,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "change");
        if (res && res.length > 0) {
          this.setState({
            Customer: res[0][0]?.name,
            Depot: res[0][0]?.Depot,
            Road_Premit_No: res[0][0]?.RoadPermitNo,
            Validity_Date: res[0][0]?.RoadPermitDate
              ? moment(res[0][0]?.RoadPermitDate).format("DD-MM-YYYY")
              : "",
            Dealer_Financier: res[0][0]?.Finc,
            Model_Price: res[0][0]?.ModelPrice,
            Quantity: res[0][0]?.Qty,
            Model_ID: res[0][0]?.Model_ID,
            Approval_No: res[1][0]?.Approval_No,
            Approval_Date: res[1][0]?.Approval_Date
              ? moment(res[1][0]?.Approval_Date).format("DD-MM-YYYY")
              : "",
            Approved_Company_Share: res[1][0]?.Approved_MTIShare,
            Approved_Dealer_Share: res[1][0]?.Approved_DealerShare,
            Final_Amount: res[1][0]?.FinalAmt,
            Remarks: res[1][0]?.remarks,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        this.GetModelDetails();
      });
  };
  openDealerPo = (enquiry_no) => {
    const url = Urls.python_baseurl + "/SP_Update_OpenPO";
    var data = JSON.stringify({
      POID: `${this.state.MAinHdr}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          alert("Po Open Succesfully");
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        this.GetModelDetails();
      });
  };
  GetPlantsOption = (enquiry_no) => {
    const url = Urls.python_baseurl + "/get_plant_SP_GetPlantCode";
    var data = JSON.stringify({
      DealerId: this.state.Dealerid,
      HOBrID: this.state.Dealerid,
      M7ID: enquiry_no,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length) {
          this.setState({ PlantOption: res });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  approvePayment = (flag) => {
    const url = Urls.python_baseurl + "/Payment_Approval";
    var data = JSON.stringify({
      ID:`${this.state.MAinHdr}`,
      Dealer_ID:`${this.state.Dealerid}`,
      Payment_Remark:`'${this.state.payment_remark}'`,
      PaymentApproval_Flag:`'${flag}'`
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Sales Order No",
        field: "PO No",
        width: "150px",
        cellStyle: {
          color: "var(--main-bg-color)",
          cursor: "pointer",
        },
      },
      { headerName: "Dealer Name", field: "Dealer_Name", width: "150px" },
      { headerName: "PO Total", field: "TotalPOAmt", width: "150px" },
      { headerName: "Approved Total", field: "Approve_Amount", width: "150px" },
      { headerName: "Total Quantity", field: "Total_Quantity", width: "150px" },
      {
        headerName: "Approved Quantity",
        field: "Approve_Quantity",
        width: "150px",
      },
      {
        headerName: "Total Payment ",
        field: "TotalPaymentReceived",
        width: "150px",
      },
      {
        headerName: "Total Payment % ",
        field: "Paymentpercentage",
        width: "150px",
      },
      {
        headerName: "Status",
        field: "Acknowledgement_Status",
        width: "150px",
        pinned: "right",
        width: "200px",
      },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              this.handelClear();
              if (event.colDef.headerName == "Sales Order No") {
                this.setState(
                  {
                    answers_mapname: [
                      {
                        id: 0,
                        Model_Category: "",
                        Model_Code: "",
                        Model: "",
                        Model_Colour: "",
                        Model_Price: "",
                        Quantity: "",
                        discount: "",
                        Total: "",
                        Approved_Quantity: 0,
                      },
                    ],
                    MAinHdr: event.data.ID,
                    Status: event.data.Status,
                    update_id: event.data["PO No"],
                    Dealerid: event.data.Dealer_ID,
                    // Dealeridfromsdropdown:event.data.Dealer_ID,
                    Dealer_Category: event.data.category,
                    Dealer_Code: event.data.Dealer_code,
                    remark:event.data.Remark
                  },
                  () => {
                    this.callAllAPI();
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  Model = () => {
    return (
      <div>
        <div className=" row">
          <div className=" col-3">
            <label htmlFor="model-c">Model Category</label>
            <select
              value={this.state.Model_Category}
              onChange={(e) =>
                this.setState({ Model_Category: e.target.value })
              }
              id="model-c"
              className=" form-control"
              readOnly={this.state.showFieldOnAddNew ? false : true}
              disabled={this.state.showFieldOnAddNew ? "" : "disabled"}
            >
              <option>Select</option>
              {this.state.Model_Category_Option.map((e) => {
                return <option value={e.ID}>{e["Model Group"]}</option>;
              })}
            </select>
          </div>
          <div className=" col-3">
            <label htmlFor="model-code">Model Code</label>
            <select
              value={this.state.Model_Code}
              id="model-code"
              className=" form-control "
              readOnly={this.state.showFieldOnAddNew ? false : true}
              disabled={this.state.showFieldOnAddNew ? "" : "disabled"}
              onChange={(e) => this.setState({ Model_Code: e.target.value })}
            >
              <option>Select</option>
              {this.state.Model_Code_Option.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })}
            </select>
          </div>
          <div className=" col-3">
            <label htmlFor="model">Model</label>
            <select
              value={this.state.Model}
              id="model"
              className=" form-control "
              readOnly={this.state.showFieldOnAddNew ? false : true}
              disabled={this.state.showFieldOnAddNew ? "" : "disabled"}
              onChange={(e) => this.setState({ Model: e.target.value })}
            >
              <option>Select</option>
              {this.state.Model_Option.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })}
            </select>
          </div>
          <div className=" col-3">
            <label htmlFor="Model_Price">Model Price</label>
            <input
              value={this.state.Model_Price}
              id="Model_Price"
              className=" form-control "
              readOnly={this.state.showFieldOnAddNew ? false : true}
              disabled={this.state.showFieldOnAddNew ? "" : "disabled"}
              onChange={(e) => this.setState({ Model_Price: e.target.value })}
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label htmlFor="Quantity">Quantity</label>
            <input
              value={this.state.Quantity}
              id="Quantity"
              className=" form-control "
              readOnly={this.state.showFieldOnAddNew ? false : true}
              disabled={this.state.showFieldOnAddNew ? "" : "disabled"}
              onChange={(e) => this.setState({ Quantity: e.target.value })}
            ></input>
          </div>
        </div>
      </div>
    );
  };
  DiscountApprovalDetails = () => {
    return (
      <div>
        <div className=" row">
          <div className=" col-3">
            <label htmlFor="Approval_No">Approval No</label>
            <input
              value={this.state.Approval_No}
              id="Approval_No"
              className=" form-control"
              readOnly
              disabled
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Approval_Date">
              Approval Date {this.required()}
            </label>
            <input
              value={this.state.Approval_Date}
              id="Approval_Date"
              className=" form-control "
              type={this.state.Approval_Date ? "text" : "date"}
              readOnly
              disabled
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Total_Approval_Discount">
              Total Approval Discount
            </label>
            <input
              value={this.state.Total_Approval_Discount}
              id="Total_Approval_Discount"
              className=" form-control "
              readOnly
              disabled
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Approved_Dealer_Share">Approved Dealer Share</label>
            <input
              value={this.state.Approved_Dealer_Share}
              id="Approved_Dealer_Share"
              className=" form-control "
              readOnly
              disabled
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
          <div className=" col-3">
            <label htmlFor="Approved_Company_Share">
              Approved Company Share
            </label>
            <input
              value={this.state.Approved_Company_Share}
              id="Approved_Company_Share"
              className=" form-control "
              readOnly
              disabled
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Final_Amount">Final Amount</label>
            <input
              value={this.state.Final_Amount}
              id="Final_Amount"
              className=" form-control "
              readOnly
              disabled
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Remark">Remarks</label>
            <input
              value={this.state.Remarks}
              id="Remark"
              className=" form-control "
              readOnly
              disabled
            ></input>
          </div>
        </div>
      </div>
    );
  };
  handelClear = () => {
    this.state.answers_mapname.map((e) => {
      this.setState({
        ["Model_Price" + e.id]: "",
        ["Model" + e.id]: "",
        ["Model_Colour" + e.id]: "",
        ["Model_Code_Option" + e.id]: "",
        ["discount" + e.id]: "",
      });
    });
    var fileInput = document.getElementById("fileInput");
    fileInput.value = "";
    this.setState({
      ShippedToID: "",
      BilledToID: "",
      shippedToAddress: "",
      BillToAddress: "",
      bulkUpload: true,
      Status: "Open",
      double_save: false,
      tabel_disable: false,
      get_id: 0,
      POid_new: 0,
      MAinHdr: 0,
      showFieldOnAddNew: true,
      Dealer_Financier: "",
      Model_Category: "",
      Model_Code: "",
      Model: "",
      PO_Date: moment(new Date()).format("DD-MM-YYYY"),
      Customer: "",
      Depot: "",
      Road_Premit_No: "",
      Validity_Date: "",
      Location: "",
      Payment_Details: "",
      Approval_No: "",
      Approval_Date: "",
      Approved_Dealer_Share: "",
      Total_Approval_Discount: "",
      Approved_Company_Share: "",
      Final_Amount: "",
      Remarks: "",
      Model_Price: "",
      Quantity: "",
      Plant: "",
      TotalOldPOAmt: "",
      answers_mapname: [
        {
          id: 0,
          Model_Category: "",
          Model_Code: "",
          Model: "",
          Model_Colour: "",
          Model_Price: "",
          Quantity: "",
          discount: 0,
          Total: "",
          get_id: 0,
          modelgrp: 0,
          Approved_Quantity: 0,
        },
      ],
      paymentDetailsArray: [
        {
          id: 0,
          Company_Account: "",
          Amount: "",
          Account_No: "",
          Payment_Mode: "",
          Bank_Name: "",
          Payment_Reference_No: "",
          Payment_Date: "",
          Payment_Made_By: "",
          Image_Name: "",
          Download_Url: "",
          companynamedetails: [],
          IFSC_Code: "",
        },
      ],
    });
  };
  DealerPo = () => {
    return (
      <div>
        <div
          className=" row justify-content-end mr-1"
          style={{ marginTop: "-15px" }}
        >
          <div className=" row">
            <button
              hidden
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={() => this.handelClear()}
            >
              Add New
            </button>
            <button
              disabled={this.state.disablesendtotally || this.state.double_save}
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={this.Validate}
            >
              Save
            </button>
            <button
              disabled={this.state.disablesendtotally || this.state.double_save}
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={this.handelConfirm}
            >
              Confirm
            </button>
            <button
              hidden
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={this.openDealerPo}
            >
              Open PO
            </button>
          </div>
        </div>
        <div className=" row mt-2">
          <div className="form-group col-3">
            <label>Sales Order No. {this.required()}</label>
            <input
              readOnly
              disabled
              value={this.state.PO_No}
              className="form-group form-control "
            ></input>
          </div>
          <div className="form-group col-3">
            <label>Sales Order Date {this.required()}</label>
            <input
              readOnly
              disabled
              value={this.state.PO_Date}
              className="form-group form-control "
            ></input>
          </div>
          {/* <div className=" col-3">
            <label>Customer</label>
            <input
              readOnly
              disabled
              value={this.state.Customer}
              className=" form-control "
            ></input>
          </div> */}
          {/* <div className=" col-3">
            <label>Plant {this.required()}</label>
              <select
              disabled={this.state.double_save}
              // disabled={this.state.showFieldOnAddNew ? false : true}
              onChange={(e) => this.setState({ Plant: e.target.value })}
              value={this.state.Plant}
              className=" form-control "
            >
              <option value={0}>Select</option>
              <option value="1">
                Wardwizard Innovations & Mobility Limited
              </option>
              <option value="2">Wardwizard Solutions India Pvt. Ltd</option>
            </select>
          </div> */}
          <div className="form-group col-3">
            <label>Depot/Plant {this.required()}</label>
            <select
              readOnly
              disabled
              onChange={(e) => this.setState({ Depot: e.target.value })}
              value={this.state.Depot}
              className=" form-control "
            >
              <option value={0}>Select</option>
              {this.state.Depot_list.map((e) => {
                return <option value={e.ID}>{e.Depo_name}</option>;
              })}
            </select>
          </div>
          <div className="form-group col-3">
            <label>Bill To</label>
            <select
              readOnly
              disabled
              onChange={(e) => {
                this.setState({ BilledToID: e.target.value }, () => {
                  let address = this.state.AddressType?.filter(
                    (e1) => e1.ID == e.target.value
                  )[0]?.Address;
                  let Address_Type = this.state.AddressType?.filter(
                    (e1) => e1.ID == e.target.value
                  )[0]?.Address_Type;
                  this.setState({
                    BillToAddress: address,
                    BilledType: Address_Type,
                  });
                });
              }}
              value={this.state.BilledToID}
              className=" form-control "
            >
              <option value={0}>Select</option>
              {this.state.AddressType.map((e) => {
                return <option value={e.ID}>{e.Address_Type}</option>;
              })}
            </select>
          </div>
          <div className="form-group col-3">
            <label>Shipped To</label>
            <select
              readOnly
              disabled
              onChange={(e) => {
                this.setState({ ShippedToID: e.target.value }, () => {
                  let address = this.state.AddressType?.filter(
                    (e1) => e1.ID == e.target.value
                  )[0]?.Address;
                  let Address_Type = this.state.AddressType?.filter(
                    (e1) => e1.ID == e.target.value
                  )[0]?.Address_Type;
                  this.setState({
                    shippedToAddress: address,
                    ShippedType: Address_Type,
                  });
                });
              }}
              value={this.state.ShippedToID}
              className=" form-control "
            >
              <option value={0}>Select</option>
              {this.state.AddressType.map((e) => {
                return <option value={e.ID}>{e.Address_Type}</option>;
              })}
            </select>
          </div>
          <div className="form-group col-4">
            <label>Bill To Address</label>
            <textarea
              rows={3}
              disabled={true}
              value={this.state.BillToAddress}
              className=" form-control "
            ></textarea>
          </div>

          <div className="form-group col-4">
            <label>Shipped To Address</label>
            <textarea
              rows={3}
              disabled={true}
              value={this.state.shippedToAddress}
              className=" form-control "
            ></textarea>
          </div>
        </div>
        <div className=" row mt-2">
        <div className=" form-group col-md-3">
          <label>Payment Remark {this.required()}</label>
          <textarea
            rows={3}
            onChange={(e)=>this.setState({payment_remark:e.target.value})}
            value={this.state.payment_remark}
            className=" form-control "
          ></textarea>
        </div>
        <div className=" form-group col-md-3">
          <label>BDM Remark </label>
          <textarea
          disabled
            rows={3}
            onChange={(e)=>this.setState({remark:e.target.value})}
            value={this.state.remark}
            className=" form-control "
          ></textarea>
        </div>
        </div>
        {/* <div className=" row mt-2">
          <div className=" col-3">
            <label>Road Permit No {this.required()}</label>
            <input
              disabled={this.state.Status === "Confirmed" ? true : false}
             
              onChange={(e) =>
                this.setState({ Road_Premit_No: e.target.value })
              }
              value={this.state.Road_Premit_No}
              className=" form-control "
            ></input>
          </div>
        
        </div> */}
        <div className=" row mt-2">
          {/* <div className=" col-3">
            <label>Dealer Financier {this.required()}</label>
            <select
              readOnly={this.state.showFieldOnAddNew ? false : true}
              disabled={this.state.showFieldOnAddNew ? "" : "disabled"}
              onChange={(e) =>
                this.setState({ Dealer_Financier: e.target.value })
              }
              value={this.state.Dealer_Financier}
              className=" form-control "
            >
              <option>Select</option>
              {this.state.FinancerOPtion.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })}
            </select>
          </div> */}
          {/* <div className=" col-3">
            <label>Location</label>
            <input
              readOnly={this.state.showFieldOnAddNew ? false : true}
              disabled={this.state.showFieldOnAddNew ? "" : "disabled"}
              onChange={(e) => this.setState({ Location: e.target.value })}
              value={this.state.Location}
              className=" form-control "
              type="text"
            ></input>
          </div> */}
          {/* <div className=" col-3">
            <label>Payment Details</label>
            <input
              readOnly={this.state.showFieldOnAddNew ? false : true}
              disabled={this.state.showFieldOnAddNew ? "" : "disabled"}
              onChange={(e) =>
                this.setState({ Payment_Details: e.target.value })
              }
              value={this.state.Payment_Details}
              className=" form-control "
            ></input>
          </div> */}
        </div>
      </div>
    );
  };
  testclickNavigation2 = () => {
    $("#payment_error_modal_4").modal("show");
  };
  errorDialog4 = () => {
    return (
      <div
        className="modal"
        id="payment_error_modal_4"
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog-view"
          style={{ marginLeft: "165px", width: "950px" }}
        >
          <div className="error-dialog_3">
            <button
              type="button"
              class="close close-btn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.close();
              }}
            >
              <span class="" aria-hidden="true">
                &times;
              </span>
            </button>
            <div id="exTab1">
              <div class="tab-content clearfix">
                <div class="tab-pane active" id="1a">
                  {this.csvHeaderview()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  csvHeaderview() {
    return (
      <div>
        <div className="" style={{ marginTop: "15px" }}>
          <div class="form-group  col-md-12">
            <div
              className="form-group row"
              style={{
                // marginLeft: "2px",
                marginTop: "15px",
                // alignItems: "baseline",
              }}
            >
              <input
                style={{
                  marginLeft: "12px",
                  width: "106px",
                  marginTop: "27px",
                }}
                key={this.state.theInputKey || ""}
                type="file"
                // accept=".csv"
                onChange={(e) =>
                  this.setState({ selectedFile: e.target.files[0] })
                }
              />
              <span style={{ margin: "33px 10px 0 3px" }} className="small">
                {this.state.selectedFile && this.state.selectedFile.name
                  ? this.state.selectedFile.name
                  : "No File Chosen"}
              </span>
              {/* </div> */}
              <div
                className="form-group col-3"
                role="group"
                aria-label="Basic mixed styles example"
              >
                <button
                  type="button"
                  className="btn"
                  style={{
                    backgroundColor: "var(--main-bg-color)",
                    color: "white",
                    marginTop: "25px",
                    marginLeft: "5px",
                  }}
                  onClick={() => this.onFileUpload()}
                >
                  {this.state.loaderforupload32 && (
                    <span
                      className="spinner-grow spinner-grow-sm"
                      style={{ color: "unset", marginRight: "5px" }}
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Upload CSV
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  onFileUpload = (e) => {
    this.setState({ CSVloader: true });
    const exampleFileReader = new FileReader();
    let FileName = this.state.selectedFile.name;
    exampleFileReader.onload = async (e) => {
      let text = e.target.result;
      console.log("text", text);
      this.setState(
        {
          EDIFileResult: text,
        },
        () => {
          console.log("ll", this.state.EDIFileResult);
          this.test(this.state.EDIFileResult, FileName);
          // this.EDI_File_upload(FileName, this.state.EDIFileResult)
        }
      );
    };
    exampleFileReader.readAsText(this.state.selectedFile);
    // }
  };
  test = (EDIFileResult, FileName) => {
    // var check = this.validation_check()
    console.log("ddddddddddddd", EDIFileResult);
    // var check = this.validation_check()
    // if (check) {
    this.setState({ loaderforupload32: true });
    const url = Urls.python_baseurl + "/execute_process_flow";
    var data = JSON.stringify({
      customer_id: sessionStorage.getItem("customer_id"),
      username: sessionStorage.getItem("user-id"),
      process_name: sessionStorage.getItem("process_name"),
      payload: EDIFileResult,
      filename: FileName,
      // "transaction_type":this.state.transc_type
    });
    // if (Strings.isDev) { process.env.NODE_ENV == 'development' && console.log(url) }

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.process_id) {
          alert("Data Converted Sucessfully\nProcessID: " + res.process_id);
          // $("#payment_error_modal_4").modal("hide");
        } else {
          // $("#payment_error_modal_4").modal("hide");
        }
        this.setState({ loaderforupload32: false });
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        this.setState(
          {
            loaderforupload32: false,
            selectedFile: "",
            process_name: "",
          },
          () => {
            $("#payment_error_modal_4").modal("hide");
          }
        );

        window.location.reload();
      });
    // }
    // }
  };
  close() {
    $("#payment_error_modal").modal("hide");
  }
  GetModelDetails = (modei_id) => {
    const url = Urls.python_baseurl + "/model_master_SP_ModelMaster_details";
    var data = JSON.stringify({
      model_id: `'${modei_id ? modei_id : this.state.Model_Id} '`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          console.log(res, "mpop");
          this.setState({
            Model_Category: res[0]?.Model_cat_ID,
            Model_Code: this.state.Model_Code_Option.filter(
              (e) => e.Name == res[0]?.Model_code
            )[0]?.ID,
            Model: this.state.Model_Option.filter(
              (e) => e.Name == res[0]?.model_name
            )[0]?.ID,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  onclickAddNew(id) {
    const nieto = {
      id: id + 1,
      Model_Category: "",
      Model_Code: "",
      Model: "",
      Model_Colour: "",
      Model_Price: "",
      Quantity: "",
      discount: 0,
      Total: "",
      get_id: 0,
      modelgrp: 0,
      Approved_Quantity: 0,
    };
    this.setState(
      (prev) => {
        return {
          ...prev,
          python_API: false,
          count: this.state.count + 1,
          answers_mapname: [...this.state.answers_mapname, nieto],
          ["Model_Price" + id + 1]: "",
          ["Model_Colour" + id + 1]: "",
          ["Model" + id + 1]: "",
        };
      },
      () => {
        console.log(this.state.answers_mapname, "jijjk");
      }
    );
  }
  ondelete(id, rowid, dealer_id) {
    if (true) {
      if (dealer_id > 0) {
        $("#GLKickOut_Popup").modal("show");
        this.setState({
          api_delete_id: dealer_id,
          delete_rowid: rowid,
        });
      } else {
        if (this.state.answers_mapname.length !== 1) {
          this.setState((prevState) => {
            return {
              answers_mapname: prevState.answers_mapname.filter(
                (item) => item.id !== rowid
              ),
              ["Model_Price" + rowid]: "",
              ["Model_Colour" + rowid]: "",
              ["Model" + rowid]: "",
            };
          });
        } else {
          this.setState({
            answers_mapname: [
              {
                id: 0,
                Model_Category: "",
                Model_Code: "",
                Model: "",
                Model_Colour: "",
                Model_Price: "",
                Quantity: "",
                discount: "",
                Total: "",
                Approved_Quantity: 0,
              },
            ],
            ["Model_Price" + rowid]: "",
            ["Model_Colour" + rowid]: "",
            ["Model" + rowid]: "",
          });
        }
      }
    }
  }
  ChangeVal(event, key) {
    this.setState({
      [key]: event.target.value,
    });
    if (key === "Model_Category") {
      const url = Urls.python_baseurl + "/SP_GetDataForCombo";
      var data = JSON.stringify({
        type: 198,
        ID: 0,
        condition: `'and Model_cat_ID=${event.target.value}'`,
      });
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.length > 0) {
            this.setState({
              Model_Code_Option: res,
            });
          }
        })
        .catch((err) => {
          process.env.NODE_ENV == "development" && console.log(err);
        });
    }
    if (key === "Model_Category") {
      const url = Urls.python_baseurl + "/SP_GetDataForCombo";
      var data = JSON.stringify({
        type: 197,
        ID: 0,
        condition: `'and Model_cat_ID=${event.target.value}'`,
      });
      fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res && res.length > 0) {
            this.setState({
              Model_Option: res,
            });
          }
        })
        .catch((err) => {
          process.env.NODE_ENV == "development" && console.log(err);
        });
    }
  }
  GetCategory = (event) => {
    let row = [];
    this.state.Model_Category_Option.forEach((element) => {
      if (!element) {
        return;
      }

      row.push(<option>{element["Model Group"]}</option>);
    });
    return row;
  };
  GetModel_12 = (event) => {
    let row = [];
    this.state.Model_Code_Option.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option>{element.Name}</option>);
    });
    return row;
  };
  getModelCode = async (model_category_id, id, model_code = "") => {
    const url =
      Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
    var data = JSON.stringify({
      ModelGroup: parseInt(model_category_id),
    });
    try {
      const response = await fetch(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: data,
      });
      const res = await response.json();
      if (res && res.length > 0) {
        console.log(res, model_code, "jjhhjhjjh");
        await this.setState({
          newflag: model_code != "" ? true : false,
          ["Model_Code_Option" + id]: res,
          ["Model_Cat_ID" + id]: model_category_id,
        });
      }
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        console.log(err);
      }
    }
  };

  getModelNameAndRate = (model_code_id, id) => {
    const url = Urls.python_baseurl + "/getModelMrp_GetModdelMRP";
    var data = JSON.stringify({
      Dealer: this.state.Dealerid,
      ModelID: parseInt(model_code_id),
      StateCode: "20",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("rtes", res[0][0]);
        if (res && res.length > 0) {
          this.setState(
            {
              ["Model_Id" + id]: model_code_id,
              ["Model_Price" + id]:
                this.state.Dealer_Category == "Showroom Distributor"
                  ? res[0][0].Disributor_rate
                  : res[0][0]?.Dealer_rate,
              ["Model" + id]: res[1][0]["Model code"],
              ["Model_Colour" + id]: res[1][0].Model_Colour,
              ["discount" + id]: res[3][0].Amount
                ? parseFloat(res[3][0].Amount)
                : 0,
            },
            () => {
              this.setState(
                (prev) => {
                  return {
                    ...prev,
                    answers_mapname: [
                      ...prev.answers_mapname.map((el, idx) => {
                        if (el.id === id) {
                          return {
                            ...el,
                            Model_Price:
                              this.state.Dealer_Category ==
                              "Showroom Distributor"
                                ? res[0][0].Disributor_rate
                                : res[0][0]?.Dealer_rate,
                            Model_Colour: res[1][0].Model_Colour,
                            modelgrp:
                              prev["Model_Cat_ID" + id] &&
                              this.state["Model_Cat_ID" + id],
                            Model_id: model_code_id,
                            Total:
                              (prev["Model_Price" + id] &&
                                this.state["Model_Price" + id]) -
                              (prev["discount" + id]
                                ? this.state["discount" + id]
                                : 0),
                            discount: res[3][0].Amount
                              ? parseFloat(res[3][0].Amount)
                              : 0,
                          };
                        } else {
                          return { ...el };
                        }
                      }),
                    ],
                  };
                },
                () => {
                  console.log(this.state.answers_mapname, "hjdshjfjs");
                }
              );
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Mapper_handlInpuChange = (e, id) => {
    console.log(this.state.answers_mapname, id, "hjhjhj");
    const { name, value } = e.target;
    let model_category_id = 0;
    if (name === "Model_Category") {
      model_category_id = this.state.Model_Category_Option.filter((e) => {
        return e["Model Group"] === value;
      })[0]?.ID;
      let copy = this.state.answers_mapname;
      for (let i = 0; i < copy.length; i++) {
        if (copy[i]?.id == id) {
          copy[i].Quantity = "";
          copy[i].discount = "";
          copy[i].Total = "";
          copy[i].Model_Code = "";
          this.setState({
            ["Model" + id]: "",
            ["Model_Colour" + id]: "",
            ["Model_Price" + id]: "",
            ["discount" + id]: 0,
          });
        }
      }
      this.setState({
        answers_mapname: copy,
      });
    }
    let model_code_id = 0;
    if (name === "Model_Code") {
      model_code_id =
        this.state["Model_Code_Option" + id] &&
        this.state["Model_Code_Option" + id].filter((e) => {
          return e["Model code"] === value;
        })[0]?.ID;
    }
    if (name == "Model_Code") {
      this.getModelNameAndRate(model_code_id, id);
    }
    if (name == "Model_Category") {
      this.getModelCode(model_category_id, id);
    }
    if (name === "Approved_Quantity") {
      let copy = this.state.answers_mapname;
      for (let i = 0; i < copy.length; i++) {
        if (copy[i]?.id == id && copy[i]?.Role_ID != 1) {
          copy[i].Quantity = 0;
          copy[i].Total =
            this.state["Model_Price" + i] * value - copy[i].discount * value;
        }
      }
      this.setState({
        answers_mapname: copy,
      });
    }
    if (name == "discount") {
      this.setState({
        ["discount" + id]: value,
      });
    }
    let copy = this.state.answers_mapname;
    for (let i = 0; i < copy.length; i++) {
      if (copy[i]?.id == id) {
        copy[i][name] = value;
      }
    }
    this.setState({
      answers_mapname: copy,
    });
  };
  delete_api = () => {
    const url = Urls.python_baseurl + "/SP_Delete_DealerVehPO";
    var data = JSON.stringify({
      iID: this.state.api_delete_id,
      dealerID: this.state.Dealerid,
      HOBrID: this.state.Dealerid,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          //this.MasterAPICall()
          $("#GLKickOut_Popup").modal("hide");
          if (this.state.answers_mapname.length !== 1) {
            this.setState((prevState) => {
              return {
                answers_mapname: prevState.answers_mapname.filter(
                  (item) => item.id !== this.state.delete_rowid
                ),
                ["Model_Price" + this.state.delete_rowid]: "",
                ["Model_Colour" + this.state.delete_rowid]: "",
                ["Model" + this.state.delete_rowid]: "",
              };
            });
          } else {
            this.setState({
              answers_mapname: [
                {
                  id: 0,
                  Model_Category: "",
                  Model_Code: "",
                  Model: "",
                  Model_Colour: "",
                  Model_Price: "",
                  Quantity: "",
                  discount: "",
                  Total: "",
                  Approved_Quantity: 0,
                },
              ],
              ["Model_Price" + this.state.delete_rowid]: "",
              ["Model_Colour" + this.state.delete_rowid]: "",
              ["Model" + this.state.delete_rowid]: "",
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  GLKickOutPopup = () => {
    return (
      <div
        class="modal"
        id="GLKickOut_Popup"
        style={{ marginLeft: "25%" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog-Memberinfo"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog" style={{ maxHeight: "initial" }}>
            <div>
              <div class="modal-body" style={{ fontSize: "16px" }}>
                Are you sure want to delete?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn light_blue1"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn light_blue1"
                  onClick={this.delete_api}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  GLKickOutPopup2 = () => {
    return (
      <div
        class="modal"
        id="GLKickOutPopup2"
        style={{ marginLeft: "25%" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog-Memberinfo"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog" style={{ maxHeight: "initial" }}>
            <div>
              <div class="modal-body" style={{ fontSize: "16px" }}>
                Are you sure want to delete?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn light_blue1"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn light_blue1"
                  onClick={this.deletePaymentDetails}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  deletePaymentDetails = () => {
    const url = Urls.python_baseurl + "/SP_Delete_PaymentDetails";
    var data = JSON.stringify({
      ID: this.state.selectedID,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          $("#GLKickOutPopup2").modal("hide");
          if (this.state.paymentDetailsArray.length > 1) {
            let newPaymentDetails = this.state.paymentDetailsArray.filter(
              (e) => e.id !== this.state.objid
            );
            this.setState({
              paymentDetailsArray: newPaymentDetails,
            });
          } else {
            $("#GLKickOutPopup2").modal("hide");
            this.setState({
              paymentDetailsArray: [
                {
                  id: 0,
                  Company_Account: "",
                  Amount: "",
                  Account_No: "",
                  Payment_Mode: "",
                  Bank_Name: "",
                  Payment_Reference_No: "",
                  Payment_Date: "",
                  Payment_Made_By: "",
                  Image_Name: "",
                  Download_Url: "",
                },
              ],
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  DelarPoList() {
    const createAnswerMapName = async (array) => {
      let ModelDetails = array.map((e, index) => {
        this.getModelCode(e?.Model_grp_id, index, e?.["Model_Code"]);
        this.setState({
          ["Model_Id" + index]: e?.Model_Id,
          ["Model_Price" + index]: e?.["model_price"],
          ["Model" + index]: e?.["Model_Code"],
          ["Model_Colour" + index]: e?.Model_colour,
        });
        return {
          id: index,
          Model_Category: e?.Model,
          Quantity: e?.["Quantity"],
          discount: e?.["Discount"] ? e?.["Discount"] : 0,
          Model_Price: e?.["model_price"],
          modelcode: e?.["Model_Code"],
          modelgrp: e?.Model_grp_id,
          Model_id: e?.Model_Id,
          Approve_Quantity: e?.Approve_Quantity,
        };
      });
      this.setState({
        answers_mapname: ModelDetails,
      });
    };
    const handelBlukUpload = () => {
      console.log(this.state.BulkUploadFile, "hjhjhhj");
      let formdata = new FormData();
      formdata.append("file", this.state.BulkUploadFile[0]);
      let requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(Urls.python_baseurl + "/Upload_BulkModel_Data", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.length > 0) {
            this.setState({
              ModelDetialsCount: result.length - 1,
            });
            createAnswerMapName(result);
          } else {
            document.getElementById("fileInput").value = "";
            alert(result.msg);
          }
        })
        .catch((error) => console.log("error", error));
    };
    if (this.state.newflag) {
      let newArr = this.state.answers_mapname.map((e) => {
        return { ...e, Model_Code: e?.modelcode };
      });
      this.setState({
        answers_mapname: newArr,
        newflag: false,
      });
    }
    return (
      <div ref={this.messagesContainerRef} className="newMapper-popup">
        <div
          // hidden={!this.state.bulkUpload}
          hidden
          className=" row justify-content-end "
        >
          <input
            onChange={(e) => {
              this.setState({ BulkUploadFile: Array.from(e.target.files) });
            }}
            onClick={(e) => {
              let a = true;
              if (this.state.answers_mapname[0].Model_Category) {
                a = window.confirm(
                  "All select Model Details will be lost if you want to bulk upload."
                );
              }
              if (a) {
                this.state.answers_mapname.map((e) => {
                  this.setState({
                    ["Model_Price" + e.id]: "",
                    ["Model" + e.id]: "",
                    ["Model_Colour" + e.id]: "",
                    ["Model_Code_Option" + e.id]: "",
                  });
                });
                this.setState({
                  answers_mapname: [
                    {
                      id: 0,
                      Model_Category: "",
                      Model_Code: "",
                      Model: "",
                      Model_Colour: "",
                      Model_Price: "",
                      Quantity: "",
                      discount: 0,
                      Total: "",
                      Approved_Quantity: 0,
                    },
                  ],
                });
              } else {
                e.preventDefault();
              }
            }}
            id="fileInput"
            type="file"
          ></input>
          <button
            disabled={this.state.BulkUploadFile == ""}
            onClick={handelBlukUpload}
            className="btn Opertion_btn clickable mr-3"
          >
            Bulk Upload
          </button>
        </div>
        <table
          className="table table-bordered mt-2"
          id="userList"
          align="center"
          style={{ width: "100%" }}
        >
          <thead>{this.renderTableHeader12()}</thead>
          <tbody>
            {this.state.answers_mapname.map((d, index) => {
              return (
                <tr style={{ overflowX: "scroll" }} key={d.id}>
                  <td>
                    {index === this.state.answers_mapname.length - 1 && (
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        style={{
                          color: "var(--main-bg-color)",
                          fontSize: "25px",
                          cursor: "pointer",
                        }}
                        // onClick={() => {
                        //   if (!this.state.disablesendtotally) {
                        //     this.onclickAddNew(d.id);
                        //   }
                        // }}
                      />
                    )}
                  </td>
                  <td style={{ width: "200px" }}>
                    <select
                      disabled
                      autoComplete="off"
                      title={d.Model_Category}
                      style={{ width: "100%" }}
                      list="Model_Category"
                      className=" form-control "
                      value={d.Model_Category}
                      name="Model_Category"
                      onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
                    >
                      <option value={0}>Select</option>
                      {this.GetCategory()}
                    </select>
                  </td>
                  <td style={{ width: "200px" }}>
                    <select
                      disabled
                      title={d.Model_Code}
                      autoComplete="off"
                      style={{ width: "100%" }}
                      list={"Model_Code" + d.id}
                      value={d.Model_Code}
                      name="Model_Code"
                      className=" form-control "
                      onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
                    >
                      <option value={0}>Select</option>
                      {this.state["Model_Code_Option" + d.id] &&
                        this.state["Model_Code_Option" + d.id].map((e) => {
                          console.log("eee", e["Model code"]);
                          return (
                            <option value={e["Model code"]}>
                              {e["Model name"]}
                            </option>
                          );
                        })}
                    </select>
                  </td>
                  <td style={{ width: "150px" }}>
                    <input
                      title={this.state["Model" + d.id]}
                      autoComplete="off"
                      style={{ width: "100%" }}
                      list="Model"
                      disabled={true}
                      value={this.state["Model" + d.id]}
                      name={"Model" + d.id}
                      className=" form-control "
                      onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
                    />
                  </td>
                  <td style={{ width: "150px" }}>
                    <input
                      style={{ width: "100%" }}
                      autoComplete="off"
                      disabled={true}
                      name={"Model_Colour" + d.id}
                      value={this.state["Model_Colour" + d.id]}
                      className=" form-control "
                      // onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
                    />
                  </td>
                  <td style={{ width: "150px" }}>
                    <input
                      style={{ width: "100%" }}
                      autoComplete="off"
                      disabled={true}
                      name={"Model_Price" + d.id}
                      value={this.state["Model_Price" + d.id]}
                      className=" form-control "
                      onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
                    />
                  </td>
                  <td style={{ width: "150px" }}>
                    <input
                      pattern="\d+(\.\d+)?"
                      disabled
                      style={{ width: "100%" }}
                      autoComplete="off"
                      name="Quantity"
                      type="number"
                      min="0"
                      step="1"
                      className=" form-control "
                      value={d.Quantity}
                      onChange={(e) => {
                        let number = e.target.value;
                        if (isNaN(number) || number < 0 || number % 1 !== 0) {
                          return;
                        }
                        if (/^0/.test(number)) {
                          number = number.replace(/^0/, "");
                        } else {
                          this.Mapper_handlInpuChange(e, d.id);
                        }
                      }}
                    />
                  </td>
                  <td style={{ width: "120px" }}>
                    <input
                      disabled
                      pattern="\d+(\.\d+)?"
                      style={{ width: "100%" }}
                      autoComplete="off"
                      name="Approved_Quantity"
                      type="number"
                      min="0"
                      step="1"
                      className=" form-control "
                      value={d.Approved_Quantity}
                      onChange={(e) => {
                        let number = e.target.value;
                        if (isNaN(number) || number < 0 || number % 1 !== 0) {
                          return;
                        }
                        if (/^0/.test(number)) {
                          number = number.replace(/^0/, "");
                        } else {
                          this.Mapper_handlInpuChange(e, d.id);
                        }
                      }}
                    />
                  </td>
                  <td style={{ width: "150px" }}>
                    <input
                      disabled
                      style={{ width: "100%" }}
                      autoComplete="off"
                      name="discount"
                      type="number"
                      className=" form-control "
                      value={this.state["discount" + d.id]}
                      onChange={(e) => {
                        let number = e.target.value;
                        if (isNaN(number) || number < 0 || number % 1 !== 0) {
                          return;
                        }
                        if (/^0/.test(number)) {
                          number = number.replace(/^0/, "");
                        } else {
                          this.Mapper_handlInpuChange(e, d.id);
                        }
                      }}
                    />
                  </td>
                  <td style={{ width: "150px" }}>
                    <input
                      style={{ width: "100%" }}
                      autoComplete="off"
                      name="Total"
                      type="number"
                      className=" form-control "
                      disabled
                      value={
                        this.state["Model_Price" + d.id] * d.Approved_Quantity -
                        d.discount * d.Approved_Quantity
                      }
                      onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
                    />
                  </td>
                  <td style={{ width: "60px", backgroundColor: "white" }}>
                    <img
                      src={require("../../components/Images/trash.png")}
                      style={{ width: "20px", cursor: "pointer" }}
                      onClick={() => {
                        if (!this.state.disablesendtotally) {
                          this.ondelete(index, d.id, d.get_id);
                        }
                      }}
                    ></img>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  getState = () => {
    const url = Urls.python_baseurl + "/SP_Get_State";
    var data = JSON.stringify({
      Dealer_ID: `'${this.state.Dealerid}'`,
      RegionID: "'All'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            StateOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getDealerType = () => {
    const url = Urls.python_baseurl + "/SP_Get_DealerType";
    var data = JSON.stringify({
      Dealer_ID: `'${this.state.Dealerid}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            DealerCategoryOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  renderTableHeader12() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          width: "90%",
          fontSize: "12px",
          color: "white",
          // position: "sticky",
          top: "0",
        }}
      >
        <td className="table-text"></td>

        <td>Model</td>
        <td>Model Name</td>
        <td>Model Code</td>
        <td>Colour</td>
        <td>Model Price</td>
        <td>Quantity</td>
        <td>Approved Quantity</td>
        <td>Discount Per unit</td>
        <td>Total</td>
        <td className="table-text"></td>
      </tr>
    );
  }
  getDealerDetails = () => {
    const url = Urls.python_baseurl + "/Get_dealer_data_for_report";
    var data = JSON.stringify({
      Dealer_ID: this.state.Dealerid,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            GST_No: res[0]?.GST_No,
            Dealer_Category: res[0]?.category,
            Dealer_City: res[0]?.Dealer_City,
            Dealer_Address: res[0]?.Dealer_Address_1,
            Dealer_State_ID: res[0]?.Dealer_State_ID,
            Party_Name: res[0]?.Dealer_Name,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Main_Header_PaymentDetailsSave = (isConfirm) => {
    var formdata = new FormData();
    this.state.paymentDetailsArray.map((e) => {
      const content = "None";
      const blob = new Blob([content], { type: "text/plain" });
      const file = new File([blob], "example.none", { type: "text/plain" });
      console.log(e?.Image_Name, "hjhjjh");
      if (e?.Image_Name == "") {
        formdata.append("ID", e?.Pyament_ID ? e?.Pyament_ID : 0);
        formdata.append("dealer_id", this.state.Dealerid);
        formdata.append("mainhdr_id", `${this.state.MAinHdr}`);
        formdata.append("Company_Account", `${e?.Company_Account}`);
        formdata.append("Amount", `${e?.Amount}`);
        formdata.append("Amount_No", `${e?.Account_No}`);
        formdata.append("Bank_Name_Address", `${e?.Bank_Name}`);
        formdata.append("Mode_Of_Payment", `${e?.Payment_Mode}`);
        formdata.append("Payment_Ref_No", `${e?.Payment_Reference_No}`);
        formdata.append("Payment_Date", `${e?.Payment_Date}`);
        formdata.append("Payment_Made_By", ``);
        formdata.append("TotalPaymentReceived", `${Payment_total}`);
        formdata.append(
          "Paymentpercentage",
          `${(Payment_total * 100) / Po_Total}`
        );
        formdata.append("Filename", `${e.FileName}`);
        formdata.append("files", file);
        formdata.append("Is_Confirm", `${isConfirm}`);
        formdata.append("Company_Bank_IFSC", `${e.IFSC_Code}`);
      } else {
        formdata.append("ID", e?.Pyament_ID ? e?.Pyament_ID : 0);
        formdata.append("dealer_id", this.state.Dealerid);
        formdata.append("mainhdr_id", `${this.state.MAinHdr}`);
        formdata.append("Company_Account", `${e?.Company_Account}`);
        formdata.append("Amount", `${e?.Amount}`);
        formdata.append("Amount_No", `${e?.Account_No}`);
        formdata.append("Bank_Name_Address", `${e?.Bank_Name}`);
        formdata.append("Mode_Of_Payment", `${e?.Payment_Mode}`);
        formdata.append("Payment_Ref_No", `${e?.Payment_Reference_No}`);
        formdata.append("Payment_Date", `${e?.Payment_Date}`);
        formdata.append("Payment_Made_By", ``);
        formdata.append("TotalPaymentReceived", `${Payment_total}`);
        formdata.append(
          "Paymentpercentage",
          `${(Payment_total * 100) / Po_Total}`
        );
        formdata.append(
          "files",
          e?.Image_Name instanceof File ? e.Image_Name : null
        );
        formdata.append(
          "Filename",
          e?.Image_Name instanceof File ? `${e.FileName}` : null
        );
        formdata.append("Is_Confirm", `${isConfirm}`);
        formdata.append("Company_Bank_IFSC", `${e.IFSC_Code}`);
      }
    });
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(Urls.python_baseurl + "/Save_PO_PaymentDetails", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        this.MasterAPICall();
        this.getPODetails();
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        this.setState({
          doubleClick: false,
        });
      });
  };

  handelPaymentDetailsSave = (isConfirm) => {
    var formdata = new FormData();
    this.state.paymentDetailsArray.map((e) => {
      const content = "None";
      const blob = new Blob([content], { type: "text/plain" });
      const file = new File([blob], "example.none", { type: "text/plain" });
      console.log(e?.Image_Name, "hjhjjh");
      if (e?.Image_Name == "") {
        formdata.append("ID", e?.Pyament_ID ? e?.Pyament_ID : 0);
        formdata.append("dealer_id", this.state.Dealerid);
        formdata.append("mainhdr_id", `${this.state.MAinHdr}`);
        formdata.append("Company_Account", `${e?.Company_Account}`);
        formdata.append("Amount", `${e?.Amount}`);
        formdata.append("Amount_No", `${e?.Account_No}`);
        formdata.append("Bank_Name_Address", `${e?.Bank_Name}`);
        formdata.append("Mode_Of_Payment", `${e?.Payment_Mode}`);
        formdata.append("Payment_Ref_No", `${e?.Payment_Reference_No}`);
        formdata.append("Payment_Date", `${e?.Payment_Date}`);
        formdata.append("Payment_Made_By", ``);
        formdata.append("TotalPaymentReceived", `${Payment_total}`);
        formdata.append(
          "Paymentpercentage",
          `${(Payment_total * 100) / Po_Total}`
        );
        formdata.append("Filename", `${e.FileName}`);
        formdata.append("files", file);
        formdata.append(
          "Is_Confirm",
          `${isConfirm == "Y" ? "Y" : e?.Is_Confirm == false ? "N" : "Y"}`
        );
        formdata.append("Company_Bank_IFSC", `${e.IFSC_Code}`);
      } else {
        formdata.append("ID", e?.Pyament_ID ? e?.Pyament_ID : 0);
        formdata.append("dealer_id", this.state.Dealerid);
        formdata.append("mainhdr_id", `${this.state.MAinHdr}`);
        formdata.append("Company_Account", `${e?.Company_Account}`);
        formdata.append("Amount", `${e?.Amount}`);
        formdata.append("Amount_No", `${e?.Account_No}`);
        formdata.append("Bank_Name_Address", `${e?.Bank_Name}`);
        formdata.append("Mode_Of_Payment", `${e?.Payment_Mode}`);
        formdata.append("Payment_Ref_No", `${e?.Payment_Reference_No}`);
        formdata.append("Payment_Date", `${e?.Payment_Date}`);
        formdata.append("Payment_Made_By", ``);
        formdata.append("TotalPaymentReceived", `${Payment_total}`);
        formdata.append(
          "Paymentpercentage",
          `${(Payment_total * 100) / Po_Total}`
        );
        formdata.append(
          "files",
          e?.Image_Name instanceof File ? e.Image_Name : null
        );
        formdata.append(
          "Filename",
          e?.Image_Name instanceof File ? `${e.FileName}` : null
        );
        formdata.append(
          "Is_Confirm",
          `${isConfirm == "Y" ? "Y" : e?.Is_Confirm == false ? "N" : "Y"}`
        );
        formdata.append("Company_Bank_IFSC", `${e.IFSC_Code}`);
      }
    });
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(Urls.python_baseurl + "/Save_PO_PaymentDetails", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (isConfirm == "N") {
          alert("Record Saved Succesfully");
        } else {
          alert("Record Confirmed Succesfully");
        }
        this.getPODetails();
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        this.setState({
          doubleClick: false,
        });
      });
  };
  handelPaymentDetailsConfirm = () => {};
  paymentDetails = () => {
    const validate = (iconfirm) => {
      for (let i = 0; i < this.state.paymentDetailsArray.length; i++) {
        if (
          this.state.paymentDetailsArray[i]?.Account_No == "" ||
          this.state.paymentDetailsArray[i]?.Amount == "" ||
          this.state.paymentDetailsArray[i]?.Company_Account == "" ||
          (this.state.paymentDetailsArray[i]?.Download_Url == "" &&
            this.state.paymentDetailsArray[i]?.Image_Name == "") ||
          this.state.paymentDetailsArray[i]?.Payment_Date == "" ||
          // this.state.paymentDetailsArray[i]?.Payment_Made_By == "" ||
          this.state.paymentDetailsArray[i]?.Payment_Reference_No == "" ||
          this.state.paymentDetailsArray[i]?.Payment_Mode == ""
        ) {
          alert("Please Enter All Payment Details");
          return;
        }
      }
      this.setState(
        {
          doubleClick: true,
        },
        () => {
          this.handelPaymentDetailsSave(iconfirm);
        }
      );
    };
    const Header = () => {
      return (
        <tr
          className="table-head-text-view"
          style={{
            backgroundColor: "var(--main-bg-color)",
            width: "90%",
            fontSize: "12px",
            color: "white",
            position: "sticky",
            top: "0",
            width: "100%",
          }}
        >
          <td width="25px" className="table-text"></td>
          <td style={{ width: "200px" }}>Company Account</td>
          <td style={{ width: "200px" }}>Bank Account No.</td>
          <td style={{ width: "200px" }}>Bank Name</td>
          <td style={{ width: "200px" }}>IFSC Code</td>
          <td style={{ width: "200px" }}>Amount</td>
          <td style={{ width: "200px" }}>Mode of Payment</td>
          <td style={{ width: "200px" }}>Payment Reference No.</td>
          <td style={{ width: "200px" }}>Payment Date</td>
          {/* <td style={{ width: "200px" }}>Payment Made By</td> */}
          <td style={{ width: "250px" }}>Upload</td>
          <td style={{ width: "250px" }}>Download File</td>
          <td className="table-text"></td>
        </tr>
      );
    };
    const handelPaymentDetailsAddNew = (id) => {
      let oldPaymentDetails = this.state.paymentDetailsArray;
      let newPaymentDetails = [
        ...oldPaymentDetails,
        {
          id: id + 1,
          Company_Account: "",
          Amount: "",
          Account_No: "",
          Payment_Mode: "",
          Bank_Name: "",
          Payment_Reference_No: "",
          Payment_Date: "",
          Payment_Made_By: "",
          Image_Name: "",
          Is_Confirm: false,
          Download_Url: "",
        },
      ];
      this.setState(
        {
          paymentDetailsArray: newPaymentDetails,
        },
        () => {
          console.log(this.state.paymentDetailsArray, "jkhjbjhbj");
        }
      );
    };
    const handelPaymentDetailsDelete = (id) => {
      let ItemToBeRemoved = this.state.paymentDetailsArray.filter(
        (e) => e.id == id
      );
      if (ItemToBeRemoved[0]?.isUpdate) {
        $("#GLKickOutPopup2").modal("show");
        this.setState({
          ItemToBeRemoved: ItemToBeRemoved,
          selectedID: ItemToBeRemoved[0]?.Pyament_ID,
          objid: id,
        });
        return;
      } else {
        let fileInput = document.getElementById("fileInput" + id);
        fileInput.value = "";
        let oldPaymentDetails = this.state.paymentDetailsArray;
        let newPaymentDetails = [];
        if (oldPaymentDetails?.length > 1) {
          newPaymentDetails = oldPaymentDetails.filter((e) => e.id !== id);
        } else {
          newPaymentDetails = [
            {
              id: 0,
              Company_Account: "",
              Amount: "",
              Account_No: "",
              Bank_Name: "",
              Payment_Mode: "",
              Payment_Reference_No: "",
              Payment_Date: "",
              Payment_Made_By: "",
              Image_Name: "",
              Download_Url: "",
            },
          ];
        }
        this.setState({
          paymentDetailsArray: newPaymentDetails,
        });
      }
    };

    const handelOnChange = (id, event) => {
      let oldPaymentDetails = this.state.paymentDetailsArray;
      const { name, value } = event.target;
      if (name == "Company_Account") {
        oldPaymentDetails = oldPaymentDetails.map((e) => {
          if (e.id == id) {
            return { ...e, [name]: value };
          } else {
            return e;
          }
        });
        this.getCompanyAccountDetails(event.target.value);
      } else if (name == "Account_No") {
        let selectaccountdetails = this.state.companynamedetails.filter(
          (e) => e.Company_Account_No == event.target.value
        )[0];
        oldPaymentDetails = oldPaymentDetails.map((e) => {
          if (e.id == id) {
            return {
              ...e,
              [name]: value,
              ["IFSC_Code"]: selectaccountdetails.Company_Bank_IFSC,
              ["Bank_Name"]: selectaccountdetails.Company_Bank_Name,
            };
          } else {
            return e;
          }
        });
      }
      // else if (name == "Amount") {
      //   if (this.state.paymentDetailsArray.length > 1) {
      //     let newtotal = 0;
      //     let newTotal2 = 0;
      //     for (let i = 0; i < this.state.paymentDetailsArray.length - 1; i++) {
      //       newtotal =
      //         parseFloat(newtotal) +
      //         parseFloat(
      //           this.state.paymentDetailsArray[i].Amount
      //             ? this.state.paymentDetailsArray[i].Amount
      //             : 0
      //         );
      //       newTotal2 =
      //         parseFloat(newtotal) +
      //         parseFloat(
      //           this.state.paymentDetailsArray[i + 1].Amount
      //             ? this.state.paymentDetailsArray[i + 1].Amount
      //             : 0
      //         );
      //     }
      //     if (newtotal + parseFloat(value) > Po_Total) {
      //       alert("Payment Total Cannot Be Greater Than Po Total");
      //       return;
      //     } else if (newTotal2 + parseFloat(value) > Po_Total) {
      //       alert("Payment Total Cannot Be Greater Than Po Total");
      //       return;
      //     }
      //   } else {
      //     if (parseFloat(value) > Po_Total) {
      //       alert("Payment Total Cannot Be Greater Than Po Total");
      //       return;
      //     }
      //   }
      //   oldPaymentDetails = oldPaymentDetails.map((e) => {
      //     if (e.id == id) {
      //       return { ...e, [name]: value };
      //     } else {
      //       return e;
      //     }
      //   });
      // }
      else if (name == "Image_Name") {
        const selectedFiles = Array.from(event.target.files);
        oldPaymentDetails = oldPaymentDetails.map((e) => {
          if (e.id == id) {
            return {
              ...e,
              [name]: selectedFiles[0],
              FileName: selectedFiles[0].name,
            };
          } else {
            return e;
          }
        });
      } else {
        oldPaymentDetails = oldPaymentDetails.map((e) => {
          if (e.id == id) {
            return { ...e, [name]: value };
          } else {
            return e;
          }
        });
      }
      this.setState(
        {
          paymentDetailsArray: oldPaymentDetails,
        },
        () => {
          console.log(this.state.paymentDetailsArray, "jkcdsjfjsd");
        }
      );
    };
    const handelFileDownlaod = (downloadLink) => {
      console.log("downloadLink", downloadLink);
      fetch(downloadLink).then((res) => {
        console.log("🚀 ~ fetch ~ res:", res);
        if (res.status === 200) {
          this.state.temparray.push(res.url);
          let data = [{ uri: res.url }];
          this.setState(
            {
              newurl: data,
            },
            () => {
              $("#ExcelPopUP").modal("show");
            }
          );
        } else {
        }
      });
    };
    return (
      <div>
        {/* <div className=" row justify-content-end ">
          <button
            hidden={!this.state.double_save}
            disabled={this.state.doubleClick}
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={() => validate("N")}
          >
            Save
          </button>
          <button
            hidden={!this.state.double_save}
            disabled={this.state.doubleClick}
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={() => validate("Y")}
          >
            Confirm
          </button>
        </div> */}
        <div className=" row">
          <div className=" form-group col-md-3">
            <label>SD/D Code</label>
            <input
              value={this.state.Dealer_Code}
              disabled
              className=" form-control "
            ></input>
          </div>
          <div className=" form-group col-md-3">
            <label>Category</label>
            <input
              value={this.state.Dealer_Category}
              disabled
              className=" form-control "
            ></input>
          </div>
          <div className=" form-group col-md-3">
            <label>Party Name</label>
            <input
              value={this.state.Party_Name}
              disabled
              className=" form-control "
            ></input>
          </div>
          <div className=" form-group col-md-3">
            <label>Address</label>
            <input
              value={this.state.Dealer_Address}
              disabled
              className=" form-control "
            ></input>
          </div>
          <div className=" form-group col-md-3">
            <label>City</label>
            <input
              value={this.state.Dealer_City}
              disabled
              className=" form-control "
            ></input>
          </div>
          <div className=" form-group col-md-3">
            <label>State</label>
            <select
              value={this.state.Dealer_State_ID}
              disabled
              className=" form-control "
            >
              <option value={0}>Select</option>
              {this.state.StateOption.map((e) => {
                return <option value={e?.ID}>{e?.State}</option>;
              })}
            </select>
          </div>
          <div className=" form-group col-md-3">
            <label>GST No.</label>
            <input
              value={this.state.GST_No}
              disabled
              className=" form-control "
            ></input>
          </div>
        </div>

        <div style={{ overflowX: "scroll", marginTop: "10px" }}>
          <table style={{ width: "1400px" }}>
            <thead>{Header()}</thead>
            <tbody>
              {this.state.paymentDetailsArray.map((e, index) => {
                console.log(e?.Is_Confirm);
                return (
                  <tr key={index}>
                    <td>
                      {index === this.state.paymentDetailsArray.length - 1 && (
                        <FontAwesomeIcon
                          style={{
                            fontSize: "25px",
                            cursor: "pointer",
                            color: "var(--main-bg-color)",
                          }}
                          icon={faPlusCircle}
                          onClick={() => {
                            handelPaymentDetailsAddNew(e.id);
                          }}
                        />
                      )}
                    </td>
                    <td>
                      <input
                        disabled
                        name="Company_Account"
                        value={e?.Company_Account}
                        onChange={(event) => {
                          handelOnChange(e.id, event);
                        }}
                        className=" form-control"
                      ></input>
                    </td>

                    <td>
                      <input
                        disabled
                        value={e?.Account_No}
                        name="Account_No"
                        onChange={(event) => {
                          handelOnChange(e.id, event);
                        }}
                        className=" form-control"
                      ></input>
                    </td>
                    <td>
                      <input
                        disabled
                        value={e?.Bank_Name}
                        name="Bank_Name"
                        onChange={(event) => {
                          handelOnChange(e.id, event);
                        }}
                        className=" form-control"
                      ></input>
                    </td>
                    <td>
                      <input
                        disabled
                        value={e?.IFSC_Code}
                        name="IFSC_Code"
                        onChange={(event) => {
                          handelOnChange(e.id, event);
                        }}
                        className=" form-control"
                      ></input>
                    </td>
                    <td>
                      <input
                        disabled
                        value={e?.Amount}
                        name="Amount"
                        onChange={(event) => {
                          let number = event.target.value;
                          if (isNaN(number) || number < 0) {
                            return;
                          }
                          handelOnChange(e.id, event);
                        }}
                        className=" form-control"
                      ></input>
                    </td>
                    <td>
                      <select
                        disabled
                        value={e?.Payment_Mode}
                        name="Payment_Mode"
                        onChange={(event) => {
                          handelOnChange(e.id, event);
                        }}
                        className=" form-control"
                      >
                        <option value={0}>Select</option>

                        <option value="RTGS">RTGS</option>
                        <option value="NEFT">NEFT</option>
                        <option value="DD">DD</option>
                        <option value="Cheque">Cheque</option>
                        <option value="IMPS">IMPS</option>
                        <option value="UPI">UPI</option>
                        <option value="Others">Others</option>
                      </select>
                    </td>
                    <td>
                      <input
                        disabled
                        value={e?.Payment_Reference_No}
                        name="Payment_Reference_No"
                        onChange={(event) => {
                          handelOnChange(e.id, event);
                        }}
                        className=" form-control"
                      ></input>
                    </td>
                    <td>
                      <input
                        disabled
                        value={e?.Payment_Date}
                        name="Payment_Date"
                        onChange={(event) => {
                          handelOnChange(e.id, event);
                        }}
                        className=" form-control"
                        type="date"
                      ></input>
                    </td>

                    <td>
                      <input
                        disabled
                        id={"fileInput" + e.id}
                        type="file"
                        accept=".png, .jpg"
                        name="Image_Name"
                        onChange={(event) => {
                          handelOnChange(e.id, event);
                        }}
                        className=" form-control"
                      ></input>
                    </td>
                    <td>
                      <a
                        onClick={() => handelFileDownlaod(e?.Download_Url)}
                        href={e?.Download_Url}
                      >
                        {e?.FileName}
                      </a>
                    </td>
                    <td style={{ width: "25px", backgroundColor: "white" }}>
                      <img
                        style={{
                          width: "20px",
                          cursor: "pointer",
                          opacity: e?.Is_Confirm ? "50%" : "100%",
                        }}
                        src={require("../../components/Images/trash.png")}
                        onClick={() => {
                          if (!e?.Is_Confirm) {
                            handelPaymentDetailsDelete(e.id, e);
                          }
                        }}
                      ></img>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  ExcelPOPUP = () => {
    console.log(this.state.downloadLink, "hjjhhj");
    return (
      <div
        style={{
          backgroundColor: "rgba(33, 37, 41, 0.8)",
        }}
        class="modal"
        id="ExcelPopUP"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                onClick={() => {this.setState({
                  newurl:[]
                })
                $("#ExcelPopUP").modal("hide")}}
              >
                ×
              </button>
            </div>
            <br></br>
            <div class="container"></div>
            <div className="container_file">
              <DocViewer
                documents={this.state.newurl}
                pluginRenderers={DocViewerRenderers}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  showFilter = () => {
    return (
      <div className=" row card flex-row py-4 mt-5">
        <div className=" form-group col-md-3">
          <label>Dealer/Distributor</label>
          <select
            name="Dealer"
            onChange={(e) => {
              if (e.target.value == 0) {
                this.setState(
                  {
                    Dealerid: sessionStorage.getItem("dealerid"),
                  },
                  () => {
                    this.MasterAPICall();
                  }
                );
              } else {
                let dealercode = document
                  .getElementById(e.target.value)
                  .getAttribute("name");
                dealercode = dealercode.split("(")[1].replace(")", "");
                this.setState(
                  {
                    Dealerid: e.target.value,
                    Dealer_Code: dealercode,
                  },
                  () => {
                    this.MasterAPICall();
                  }
                );
              }
            }}
            className=" form-control "
            value={this.state.Dealerid}
          >
            <option value={0}>All</option>
            {this.state.DealerOption.map((e) => {
              return (
                <option id={e?.ID} name={e?.Name} value={e?.ID}>
                  {e?.Name}
                </option>
              );
            })}
          </select>
        </div>
        <div className=" form-group col-md-3">
          <label>SD/D Code</label>
          <input
            value={this.state.Dealer_Code}
            disabled
            className=" form-control "
          ></input>
        </div>
      </div>
    );
  };
  render() {
    Po_Total = 0;
    Payment_total = 0;
    Previous_Po_Total = 0;
    TotalQuantity = 0;
    TotalApprovedQuantity = 0;
    TotalPaymentPercentage = 0;
    this.state.answers_mapname?.map((e) => {
      Po_Total =
        Po_Total + (e?.Model_Price - e?.discount) * e?.Approved_Quantity;
      Previous_Po_Total =
        Previous_Po_Total + (e?.Model_Price - e?.discount) * e?.Quantity;
      TotalQuantity += e?.Quantity && parseInt(e?.Quantity);
      TotalApprovedQuantity +=
        e?.Approved_Quantity && parseInt(e?.Approved_Quantity);
    });
    this.state.paymentDetailsArray?.map((e) => {
      Payment_total = Payment_total + +e?.Amount;
    });

    TotalPaymentPercentage =
      Payment_total &&
      Po_Total &&
      ((+Payment_total / +Po_Total) * 100).toFixed(2);
    return (
      <div>
        <h4 className="heading_text">Payment Approval</h4>
        {this.showFilter()}
        {this.state.Delar_PO_List ? (
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Sales Order List
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">{this.masterTable()}</div>
            </div>
          </div>
        ) : (
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Sales Order List
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">{this.masterTable()}</div>
            </div>
          </div>
        )}

        <div>
          {this.state.Delar_PO_List ? (
            <div class="card ">
              <div class="card-header" id="headingFour">
                <h5 class="mb-0">
                  <button
                    style={{
                      color: "var(--main-bg-color)",
                      fontWeight: "500",
                    }}
                    class="btn  collapsed"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    Sales Order
                  </button>
                </h5>
              </div>
              <div
                id="collapseFour"
                class="collapse  "
                aria-labelledby="headingFour"
              >
                <div class="card-body">{this.DealerPo()}</div>
              </div>
            </div>
          ) : (
            <div class="card ">
              <div class="card-header" id="headingFour">
                <h5 class="mb-0">
                  <button
                    style={{
                      color: "var(--main-bg-color)",
                      fontWeight: "500",
                    }}
                    class="btn  collapsed"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                  >
                    Sales Order Details
                  </button>
                </h5>
              </div>
              <div
                id="collapseFour"
                class="collapse show"
                aria-labelledby="headingFour"
              >
                <div class="card-body">{this.DealerPo()}</div>
              </div>
            </div>
          )}
          {this.state.Delar_PO_List ? (
            <div class="card ">
              <div class="card-header" id="headingSeven">
                <h5 class="mb-0">
                  <button
                    style={{
                      color: "var(--main-bg-color)",
                      fontWeight: "500",
                    }}
                    class="btn  collapsed"
                    data-toggle="collapse"
                    data-target="#collaoseSeven"
                    aria-expanded="true"
                    aria-controls="collaoseSeven"
                  >
                    Model Details
                  </button>
                </h5>
              </div>
              <div
                id="collaoseSeven"
                class="collapse  "
                aria-labelledby="headingSeven"
              >
                <div
                  style={{ maxHeight: "304px", overflowY: "scroll" }}
                  class="card-body"
                >
                  {this.DelarPoList()}
                </div>
              </div>
            </div>
          ) : (
            <div class="card ">
              <div class="card-header" id="headingSeven">
                <h5 class="mb-0">
                  <button
                    style={{
                      color: "var(--main-bg-color)",
                      fontWeight: "500",
                    }}
                    class="btn  collapsed"
                    data-toggle="collapse"
                    data-target="#collaoseSeven"
                    aria-expanded="true"
                    aria-controls="collaoseSeven"
                  >
                    Model Details
                  </button>
                </h5>
              </div>
              <div
                id="collaoseSeven"
                class="collapse show "
                aria-labelledby="headingSeven"
              >
                <div
                  style={{ maxHeight: "304px", overflowY: "scroll" }}
                  class="card-body"
                >
                  {this.DelarPoList()}
                  <div
                    style={{ marginRight: "50px" }}
                    className=" row justify-content-between ml-1 "
                  >
                    <label style={{ fontWeight: "bold" }}>
                      PO Total: {Previous_Po_Total}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      Approved Total: {Po_Total}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      Total Quantity: {TotalQuantity}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      Approved Quantity: {TotalApprovedQuantity}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      Total Payment: {Payment_total}
                    </label>
                    <label style={{ fontWeight: "bold" }}>
                      Total Payment %: {TotalPaymentPercentage}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div class="card">
            <div class="card-header" id="heading98">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapse98"
                  aria-expanded="true"
                  aria-controls="collapse98"
                >
                  Payment Details
                </button>
              </h5>
            </div>

            <div
              id="collapse98"
              class="collapse"
              aria-labelledby="heading98"
              data-parent="#accordion"
            >
              <div class="card-body">
                {this.paymentDetails()}
                <div className="mt-3">
                  <label style={{ fontWeight: "bold" }}>
                    Payment Total: {Payment_total}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.GLKickOutPopup()}
        {this.GLKickOutPopup2()}
        {this.errorDialog4()}
       {this.state.newurl&&this.ExcelPOPUP()}
      </div>
    );
  }
}
