import React from "react";
import "../../Files/files-styles.css";
// import '../Claims/Dashboard/styles.css'
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import DatePicker from "react-datepicker";
import moment from "moment";
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import { Tiles } from "../../../components/Tiles";
import Strings from "../../../../helpers/Strings";
// import { post } from 'axios';
import { Alert } from "../../../../helpers/getDetails";
import { Lead } from "../Lead";
import { Booking_M3 } from "../Booking_M3";
import { Enquiry_details } from "../Enquiry_details";
import { Money_reciept } from "../Money_reciept";
import { Vehical_billing } from "../Vehical_billing";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { ListFilter } from "../../../components/ListFilter";
const $ = window.$;
export class Billling_list extends React.Component {
  constructor(props) {
    console.log(props, "ppppppp");
    super(props);
    this.state = {
      ActiveTab: sessionStorage.getItem("ActiveTab"),
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      ethnicity_map: [],
      showTable: props.location.state && props.location.state.data[0] && props.location.state.data[0].showTable ? props.location.state.data[0].showTable : '',
      Ethnicity: [],
      Title: props.location.state && props.location.state.data[0] && props.location.state.data[0].title ? props.location.state.data[0].title : '',
      enquiry_no: "",
      enquiry_date: "",
      Source: "",
      Source_name: "",
      source_mobile: "",
      address1: "",
      address2: "",
      ext_mit_cust: "",
      pincode: "",
      State: "",
      District: "",
      primary_application: "",
      secondary_application: "",
      startDate: "",
      total_no_fleet: "",
      kam_customer: "",
      call_ticket_number: "",
      call_ticket_date: "",
      HiPaaS_ID: "",
      visit_details: "",
      discussion: "",
      next_visit_details: "",
      HiPaaS_ID: "",
      date: "",
      newdate: "",
      time_spent: "",
      Commitment: "",
      m1_count: 0,
      m2_count: 0,
      m3_count: 0,
      m7_count: 0,
      m8_count: 0,
      loaderforupload: false,
      redirect_page: "",
      tableData: [
        { id: 1, name: "Item 1", quantity: 0 },
        { id: 2, name: "Item 2", quantity: 0 },
        { id: 3, name: "Item 3", quantity: 0 },
      ],
      rowData: [],
      PendingrowData: [],
      flag: false,
       Booking_M3_Details: [],
      name: "",
      pendingflag: false,
      Enquiry_Props: {},
      pendingEvent: "",
      M7_ID:"",
      sqlfor: props.location.state && props.location.state.data[0] && props.location.state.data[0].pendind_data ? props.location.state.data[0].pendind_data : '',
      dashboard_Click: props.location.state && props.location.state.data[0] && props.location.state.data[0].dashboard_Click ? props.location.state.data[0].dashboard_Click : '',
      title: props.location.state && props.location.state.data[0] && props.location.state.data[0].title ? props.location.state.data[0].title : '',
      showtimerange:true,
      startDate: moment().subtract(7, 'd').format('YYYY-MM-DD'),
     endDate: moment().format('YYYY-MM-DD'),

    };
  }

  componentDidMount() {
    this.MasterAPICall()
  }
   handleInputChange = (id, event) => {
    const { name, value } = event.target;

    // Update the quantity for the corresponding item
  };
  clickNavigation = (event) => {
    this.props.history.push("/Enquiry", {});
  };
  PendingList = () => {
    const url =
      Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";

    var data = JSON.stringify({
      GetRecordFor: "'M7'",
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res, "popio");
          this.setState({
            PendingrowData: res[0],

            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
          // $("#payment_error_modal").modal("show");
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
 
  MasterAPICall = (obj) => {
    const url = Urls.python_baseurl + "/SP_VehicleSalesDetails";
    var data =JSON.stringify({
        Dealer_ID: sessionStorage.getItem("dealerid"),
        SqlFor:`'${this.state.sqlfor?this.state.sqlfor:"MoneyReceipt"}'`,
        startdate: `'${moment(this.state.startDate).format("YYYY-MM-DD")}'`,
        enddate: `'${moment(this.state.endDate).format("YYYY-MM-DD")}'`,
        Dashboard_For:`'${this.state.sqlfor}'`

      });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
            const isNullish = Object.values(res[0][0]).every(value => {
                if (value === null || value==="") {
                  return true;
                }
                return false;
              });
              if(isNullish){
                this.setState({
                    rowData: [],
                  });
              }else{
                this.setState({
                    rowData: res[0],
                  });
              }
         
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
 
  displayBookingM3 = (id) => {
    const url = Urls.python_baseurl + "/Booking_display_SP_GetM3";
    var data = JSON.stringify({
      POId: "0",
      POType: "'Max'",
      DealerID: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      iM1ID: id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0&& res[0].length>0) {
          this.setState(
            {
              Booking_M3_Details: res,
              flag: true,
            },
            () => {
              $("#payment_error_modal_2").modal("show");
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  //   displayMoneyReceived = (id) => {
  //     const url = Urls.python_baseurl + "/MoneyReceipt_display_SP_GetM7";
  //     var data = JSON.stringify({
  //       POId: this.state.MoneyReceiptID,
  //       POType: "'All'",
  //       DealerID: sessionStorage.getItem("dealerid"),
  //       HOBrID: sessionStorage.getItem("dealerid"),
  //       iM1ID: "0",
  //       DocType: "'L'"
  //     });
  //     fetch(url, {
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       method: "POST",
  //       body: data,
  //     })
  //       .then((res) => res.json())
  //       .then((res) => {
  //         if (res && res.length > 0&& res[0].length>0) {
  //           this.setState(
  //             {
  //               ReceiptDetails: res,
  //               flag: true,
  //             },
  //             () => {
  //               $("#payment_error_modal_2").modal("show");
  //             }
  //           );
  //         }
  //       })
  //       .catch((err) => {
  //         process.env.NODE_ENV == "development" && console.log(err);
  //       });
  // };
  displayVehicleInvoice = () => {
    $("#payment_error_modal_2").modal("show");
  };
  masterTable2() {
    let columnDefs = [];
       
      columnDefs = [
       
        { headerName: "Dealer Name", field: "Dealer_Name", width: "120px",  },
        { headerName: "Dealer Code", field: "DealerCode", width: "120px",  },
        

        {
          headerName: "Money Received No.",
          field: "MoneyReceipt_No",
          width: "150px",
          cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        },
        { headerName: "Money Received Date", field: "MoneyReceipt_Date", width: "120px", },
        { headerName: "Name", field: "name", width: "120px", },
        { headerName: "Mobile", field: "mobile", width: "120px", },
        { headerName: "Money Received Status", field: "M7Confirm", width: "120px",
        cellRenderer: (data) => {
          if(data.data.M7Confirm==="Y" ){
            return "Confirmed"
             
          }else{
            return "Open"
          }
          
      }},
      { headerName: "Allocation Status", field: "Allocation Status", width: "120px",
     },
        // { headerName: "Model Name", field: "model_name", flex: "1" },
        { headerName: "Enquiry No.", field: "Inq_no", width: "150px",},
        { headerName: "Enquiry Date", field: "Inq_date",width: "120px",  },
        {
          headerName: "Booking No.",
          field: "Booking_No",
          width: "150px",
        },
        { headerName: "Booking Date", field: "Booking_Date",width: "150px",  },

      ];
    

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              console.log("event12", event.data);
             
                if (event.colDef.field == "MoneyReceipt_No") {
                  this.setState(
                    {
                      name: event.data.Name,
                      pendingEvent7: event.data,
                      Status:event.data.M7Confirm,
                      flag: true,
                      pendingflag:false
                    },
                    () => {
                      // this.displayMoneyReceived();
                      $("#payment_error_modal_2").modal("show");
                    }
                  );
                
              } else if (this.state.showTable === "m8") {
                if (event.colDef.field == "M8 No") {
                  this.setState(
                    {
                      name: event.data.Name,
                      VehicleBillingID: event.data.ID,
                      flag: true,
                      Status:event.data.Status
                    },
                    () => {
                      this.displayVehicleInvoice();
                    }
                  );
                }
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  tab() {
    return (
      <div style={{ marginLeft: "2px" }}>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {/* <a
              className={
                this.state.ActiveTab != "" && this.state.ActiveTab == "Lead"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-home-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Lead" }, () =>
                  setTimeout(() => {
                    this.handleSort("m0");
                    sessionStorage.setItem("ActiveTab", "Lead");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Lead
            </a> */}
            <a
              className={
                this.state.ActiveTab != "" && this.state.ActiveTab == "Enquiry"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-home-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Enquiry" }, () =>
                  setTimeout(() => {
                    this.handleSort("m1");
                    sessionStorage.setItem("ActiveTab", "Enquiry");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Enquiry
            </a>
            {/* <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Quotation"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Quotation" }, () =>
                  setTimeout(() => {
                    this.handleSort("m2");
                    sessionStorage.setItem("ActiveTab", "Quotation");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Quotation
            </a> */}
            <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Vehicle Booking"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Vehicle Booking" }, () =>
                  setTimeout(() => {
                    this.handleSort("m3");
                    sessionStorage.setItem("ActiveTab", "Vehicle Booking");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Vehicle Booking
            </a>
            <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Money Received"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Money Received" }, () =>
                  setTimeout(() => {
                    this.handleSort("m7");
                    sessionStorage.setItem("ActiveTab", "Money Received");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Money Received
            </a>
            <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Vehicle Invoice"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Vehicle Invoice" }, () =>
                  setTimeout(() => {
                    this.handleSort("m8");
                    sessionStorage.setItem("ActiveTab", "Vehicle Invoice");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Vehicle Invoice
            </a>

            {/* <a className="nav-item nav-link" id="nav-contact-tab" onClick={() => this.handleSort('Resubmit')} data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Ready to Submit</a> */}
          </div>
        </nav>
      </div>
    );
  }

  _renderSummaryDetails = () => {
    let row = [];
    let summary = [];
    summary = [
      // { name: 'Total Files', value: data ? data.RejectedCount ? Number(data.TotalFiles) + Number(data.RejectedCount) : data.TotalFiles : 0 },
      // {
      //   name: "Total Pending Enquiry",
      //   value: this.state.m1_count,
      //   color: "#129DC9",
      // },
      // {
      //   name: "Total Pending Quotation",
      //   value: this.state.m2_count,
      //   color: "green",
      // },
      {
        name: "Total Pending Vehicle Booking",
        value: this.state.m3_count,
        color: "orange",
      },
      {
        name: "Total Pending Money Received",
        value: this.state.m7_count,
        color: "#55eb34",
      },
      {
        name: "Total Pending Vehicle Invoice",
        value: this.state.m8_count,
        color: "#34ebc6",
      },
    ];
    let array = summary;

    array.forEach((item) => {
      let addon = "";
      let claimStatus = "";
      let subtitle = "";
      let availitySent = "";
      let url = "";
      let data = [];

      row.push(
        <Tiles
          isClickable={item.name != "Eligibility Errors"}
          header_text={item.name}
          value={item.value}
          isenrollment={true}
          color={item.color}
          differentTile={true}
          onClick={() => {
            if (this.state.m1_count && item.name === "Total Pending Enquiry") {
              this.PendingList("M1");
              this.setState({
                redirect_page: "M1",
              });
            } else if (
              this.state.m2_count &&
              item.name === "Total Pending Quotation"
            ) {
              this.PendingList("M2");
              this.setState({
                redirect_page: "M2",
              });
            } else if (
              this.state.m3_count &&
              item.name === "Total Pending Vehicle Booking"
            ) {
              this.PendingList("M3");
              this.setState({
                redirect_page: "M3",
              });
            } else if (
              this.state.m7_count &&
              item.name === "Total Pending Money Received"
            ) {
              this.PendingList("M7");
              this.setState({
                redirect_page: "M7",
              });
            } else if (
              this.state.m8_count &&
              item.name === "Total Pending Vehicle Invoice"
            ) {
              this.PendingList("M8");
              this.setState({
                redirect_page: "M8",
              });
            }
          }}
        />
      );
    });

    return <div className="row padding-left">{row}</div>;
  };
  close() {
    return (
      <div style={{ marginLeft: "2px" }}>
        <nav>
          <button
            type="button"
            class="close close-btn"
            style={{ marginTop: "-2%" }}
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              this.close();
            }}
          >
            <span class="" aria-hidden="true">
              &times;
            </span>
          </button>
        </nav>
      </div>
    );
  }
  masterTable() {
    let columnDefs = [
      {
        headerName: "Booking No",
        field: "Doc_No",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Booking Date", field: "Dealer_Name", flex: "1" },

      { headerName: "Customer Name", field: "Dealer_Name", flex: "1" },
      { headerName: "Mobile", field: "mobile", flex: "1" },
      
    ];
    if(this.state.redirect_page==="M8"){
      columnDefs.push(
        { headerName: "Chassis No", field: "Chassis_no", flex: "1"}
      )
    }

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.PendingrowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (
                event.colDef.field == "Doc_No" 
                
              ) {

                this.setState(
                  {
                    pendingflag: true,
                    pendingEvent7: event.data,
                  },
                  () => {
                    $("#payment_error_modal").modal("hide");
                     $("#payment_error_modal_3").modal("show");
                  }
                );
              } 
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  errorDialog = () => {
    return (
      <div class="modal" id="payment_error_modal">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div style={{ marginTop: "-20px" }} class="modal-body">
              {this.masterTable()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  displayPopUp = () => {
    return (
      <div class="modal" id="payment_error_modal_2">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => {
                  this.setState({
                    flag: false,
                    id:0,
                    EnquiryDetails:[]
                    // pendingEvent: [],
                    // Customer_Name: "",
                  });
                }}
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.state.showTable === "m0" ? (
                <Lead modelid={"saveClcik"} LeadDetails={this.state.LeadDetails}></Lead>
              ) : null}
              {this.state.showTable === "m1" ? (
                <Enquiry_details customer_name={this.state.name} modelid={"saveClcik"} EnquiryDetails={this.state.EnquiryDetails} history={this.props.history} />
              ) : null}
              {this.state.showTable === "m3" ? (
                <Booking_M3
                modelid={"saveClcik"} id={this.state.id} booking={true} history={this.props.history}
                />
              ) : null}
              {(
                <Money_reciept
                modelid={"saveClcik"} pendingClick7={this.state.pendingEvent7} pendingClick={{ ID: this.state.MoneyReceiptID }} ReceiptDetails={this.state.ReceiptDetails} Status={this.state.Status} history={this.props.history}
                />
              ) }
              {this.state.showTable === "m8" ? (
                <Vehical_billing
                 modelid={"saveClcik"} Status={this.state.Status} pendingClick={{ ID: this.state.VehicleBillingID }} history={this.props.history}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  dispalyPending = () => {
    return (
      <div class="modal" id="payment_error_modal_3">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => {
                  this.setState({
                    pendingflag: false,
                    pendingEvent: [],
                    Customer_Name: "",
                  });
                }}
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.state.redirect_page === "M1" && (
                <Enquiry_details modelid={"pendingClick"} pendingClick={this.state.pendingEvent} />
              )}
              {this.state.redirect_page === "M3" && (
                <Booking_M3
                modelid={"pendingClick"} pendingClick3={this.state.pendingEvent3} booking={false}
                ></Booking_M3>
              )}
              { ( this.state.pendingflag?
                <Money_reciept
                modelid={"pendingClick"}  pendingClick7={this.state.pendingEvent7}
                ></Money_reciept>:null
              )}
              {this.state.redirect_page === "M8" && (
                <Vehical_billing
                modelid={"pendingClick"}   pendingClick={this.state.pendingEvent8}
                ></Vehical_billing>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  handelLead() {
    this.setState(
      {
        id:0,
        EnquiryDetails:[],
        // pendingflag: true,
        flag:false
      },
      () => {
        this.PendingList()
        $("#payment_error_modal").modal("show");
      }
    );
  }
  refreshScreen = (startDate, endDate, dealerid) => {
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        dealerid: dealerid,
      },
      () => {
        this.MasterAPICall();
      }
    );
  };
  render() {
    return (
      <div>
        {/* <div class="form-group col-md-12">{this._renderSummaryDetails()}</div> */}
        <h4 className="heading_text" style={{marginLeft:"0px"}}>{this.state.Title}</h4><br></br><br></br>
        {/* { (
          <div
            class="form-group col-md-12"
            style={{ padding: "0px", marginLeft: "-10px " }}
          >
            <button
              // onClick={() => this.props.history.push("/Lead")}
              onClick={() => this.handelLead()}
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right" }}
            >
              Create Money Receipt
            </button>
          </div>
        )} <br></br> */}
        {/* {this.tab()} */}
        <ListFilter
            refreshScreen={this.refreshScreen}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dealerid={this.state.dealerid}
            showtimerange={this.state.showtimerange}
          /><br></br>
        {this.masterTable2()}

        {this.errorDialog()}
        {this.state.flag == true && this.displayPopUp()}
        {this.state.pendingflag == true && this.dispalyPending()}
      </div>
    );
  }
}
