import React from "react";
import "../Files/files-styles.css";
import Urls from "../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import Strings from "../../../helpers/Strings";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Multiselect from "multiselect-react-dropdown";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
const $ = window.$;
let PartData = [];
export class Job_Card_Approval extends React.Component {
  constructor(props) {
    // console.log("data565",props.location.state.data.length>0);
    super(props);
    this.state = {
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      vistitdetails: [],
      rowData: [],
      jobcardID: 0,
      Isconfirm:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.Isconfirm
          ? props.location.state.Isconfirm
          : "open",
      filename_delete: "",
      checklistno: "",
      visit_details: "",
      next_visit_details: "",
      newDate: "",
      Date: "",
      endDate: "",
      startDate: "",
      chassisList: [],
      temparray: [],
      selected_id: 0,
      selected_attchment_id: 0,
      Bay_No: 0,
      create_job_no:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.job_no === "true"
          ? true
          : false,
      answers_mapname: [
        {
          id: 0,
          Part_No: "",
          Part_Name: "",
          Mechanic: "",
          Request_qty: "",
          Use_qty: "",
          Rate: "",
          Total: "",
        },
      ],
      Complaints_Description: "",
      approx_Extimate: "",
      Bay_No: 0,
      KMS: 0,
      Mechanics: "",
      supervisor: "0",
      IOT_No:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.IOT_No
          : "",
      Controller_Unit_No:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.Control_Unit_No
          : "",
      Charger_No:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.ChargerNo
          : "",
      Battery_no:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.BatteryNo
          : "",
      Actual_battery_no:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.BatteryNo
          : "",
      Actual_Charger_no:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.ChargerNo
          : "",
      Motor_no:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.Motor_No
          : "",
      Model_name:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.model_name
          : "",
      Customer_Name: "",
      Model_code:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.model_code
          : "",
      Vehical_Registration_No:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.Vehicle_No
          : "",
      Chassis_No:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.Chassis_no
          : "",
      job_type:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.job_type
          ? props.location.state.job_type
          : "0",
      DisplayNo:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.Display_No
          : "",
      ActualController_Unit_No:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.Control_Unit_No
          : "",
      ActualMotor_No:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.Motor_No
          : "",
      ActualDispayNo:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.Display_No
          : "",
      ActualIOT_Device_ID:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.IOT_No
          : "",
      Model_ID:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.Model_ID
          : "",
      Job_date: moment(new Date()).format("DD-MM-YYYY"),
      Allocation_time: "",
      JOB_no: "",
      Image4: "",
      Image3: "",
      Image2: "",
      Image1: "",
      BackLightRight: "",
      BackLightLeft: "",
      FrontLightRight: "",
      FrontLightLeft: "",
      ScrtchesInfo: "",
      Battery: "",
      Pertrol: "",
      Job_number: 0,
      superviasorList: [],
      Chassisnumber: [],
      JOBType: [],
      MechanicList: [],
      Complaints: [],
      ChassisID:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.data
          ? props.location.state.data.Chassis_Id
          : "",
      // FrontLightLeft: "",
      FrontLightRight: "",
      backlight_left: "",
      backlight_right: "",
      JobID: "",
      InvestigationsList: [],
      ActionList: [],
      BayList: [],
      complaintdata: [],
      investdata: [],
      sqlfor: "",
      api_delete_id: 0,
      fin_year: sessionStorage.getItem("fin_year"),
      Dealer_Code: sessionStorage.getItem("Dealer_Code"),
      actionlist: [],
      double_save: false,
      Jobcard_No: "",
      newurl: [],
      ConditionCode: [
        {
          id: 0,
          Doc_Name: "",
          Doc_Attachment: "",
          Dealer_Id: "",
          File_Name: "",
        },
      ],
      ComplainData: [
        {
          id: 0,
          Complaints: "",
          Complaints_ID: "",
          iid: 0,
        },
      ],
      InvestigationData: [
        {
          id: 0,
          Investigations: "",
          Investigations_ID: 0,
        },
      ],
      ActionData: [
        {
          id: 0,
          Action: "",
          Action_ID: "",
        },
      ],
      TableRowData: [],
      DealerOption: [],
      Dealerid: sessionStorage.getItem("dealerid"),
    };
  }
  componentDidMount() {
    this.getDealer();
    this.TableAPi();
  }
  getDataonAgGridClick() {
    // this.MasterAPICall();
    {
      this.state.jobcardID != 0 && this.Get_JobCard_list();
    }
    {
      this.state.jobcardID != 0 && this.Jobcard_checklist_attachment();
    }
    // ((this.state.jobcardID===0 ||this.state.jobcardID==="")&&this.JOB_No())
    {
      this.state.create_job_no && this.JOB_No();
    }
    this.Job_Type();
    this.SupervisorandMechanic();
    this.ComplainList();
    this.GetInvestigationList();
    this.Get_ActionList();
    this.Bay_No();
  }
  Bay_No = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "150",
      ID: `${this.state.Dealerid}`,
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            BayList: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Get_JobCard_list = () => {
    const url = Urls.python_baseurl + "/Get_jobDetails_Sp_Get_JobcardDetails";
    var data = JSON.stringify({
      dealer_id: `${this.state.Dealerid}`,
      job_id: this.state.jobcardID,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("ghg", res && res.length > 0);
        if (res && res.length > 0) {
          console.log("ghg", res[0][0].motor_no);

          this.setState(
            {
              rowData: res[1][0],
              Vehical_Registration_No: res[0][0].Reg_No,
              IOT_No: res[0][0].iot_device_no,
              Charger_No: res[0][0].charger_no,
              Battery_no: res[0][0].battery_no,
              Motor_no: res[0][0].motor_no,
              Model_name: res[0][0].Model_name,
              Model_code: res[0][0].Model_code,
              Customer_Name: res[0][0].Customer_name,
              DisplayNo: res[0][0].display_no,
              Controller_Unit_No: res[0][0].controller_no,
              Actual_battery_no: res[0][0].actual_battery_no,
              Actual_Charger_no: res[0][0].actual_charger_no,
              Model_ID: res[0][0].Model_ID,
              JOB_no: res[0][0].job_no,
              Job_date: moment(res[0][0].JobDate).format("DD-MM-YYYY"),
              job_type: res[0][0].Job_Ty_ID,
              Chassis_No: res[0][0].Chassis_no,
              ChassisID: res[0][0].Chassis_ID,
              Customer_name: res[0][0].Customer_name,
              Model_code: res[0][0].Model_code,
              Model_name: res[0][0].Model_name,
              supervisor: res[0][0].supervisorname,
              Mechanics: res[0][0].mechanicname,
              KMS: res[0][0].kms,
              Bay_No: res[0][0].BayID,
              complaintdata: res[1],
              investdata: res[2],
              actionlist: res[3],
              ActualController_Unit_No: res[0][0].ActualController_Unit_No,
              ActualMotor_No: res[0][0].ActualMotor_No,
              ActualDispayNo: res[0][0].ActualDispayNo,
              ActualIOT_Device_ID: res[0][0].ActualIOT_Device_ID,
              Allocation_time:
                res[0][0].bay_allot_date === "1900-01-01T00:00:00"
                  ? ""
                  : res[0][0].bay_allot_date,
              Approval_Remark: res[0][0].Approval_Remark,
              Approval_Flag: res[0][0].Approval_Flag,
              Is_Approved: res[0][0].Is_Approved,
              double_save: false,
            },
            () => {
              let complaint_Detail = [];
              let investdata_ = [];
              let actionlist_1 = [];
              for (
                let index = 0;
                index < this.state.complaintdata.length;
                index++
              ) {
                let data = {
                  id: index,
                  Complaints: this.state.complaintdata[index].Complaint,
                  Complaints_ID: this.state.complaintdata[index].Complaint_ID,
                  iid: this.state.complaintdata[index].ID,
                };
                complaint_Detail.push(data);
              }
              for (
                let index = 0;
                index < this.state.investdata.length;
                index++
              ) {
                let data_invest = {
                  id: index,
                  Investigations:
                    this.state.investdata[index].Dealer_Investigation,
                  Investigations_ID:
                    this.state.investdata[index].Investigation_ID,
                  iid: this.state.investdata[index].ID,
                };
                investdata_.push(data_invest);
              }
              for (
                let index = 0;
                index < this.state.actionlist.length;
                index++
              ) {
                let data_invest = {
                  id: index,
                  Action: this.state.actionlist[index].Dealer_Action,
                  Action_ID: this.state.actionlist[index].Action_ID,
                  iid: this.state.actionlist[index].ID,
                };
                actionlist_1.push(data_invest);
              }
              this.setState(
                {
                  ComplainData: complaint_Detail,
                  InvestigationData: investdata_,
                  ActionData: actionlist_1,
                },
                () => {
                  console.log("FrontLightLeft", this.state.ComplainData);
                  console.log("FrontLightLeft", this.state.InvestigationData);
                  console.log("FrontLightLeft", this.state.ActionData);
                  this.Chassisno();
                }
              );
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Jobcard_checklist_attachment = () => {
    const url = Urls.python_baseurl + "/Jobcard_Checklist_Attachments_download";
    var data = JSON.stringify({
      dealer_ID: `${this.state.Dealerid}`,
      Job_HDR_ID: this.state.jobcardID,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res, "kjjkkjkj");
          this.setState(
            {
              ConditionCode: res,
            },
            () => {
              let Checklist_doc = [];
              for (
                let index = 0;
                index < this.state.ConditionCode.length;
                index++
              ) {
                let data_invest = {
                  id: index,
                  Doc_Name: this.state.ConditionCode[index].check_list_no,
                  File_Name: this.state.ConditionCode[index].filename,
                  path: this.state.ConditionCode[index].url,
                  Doc_Attachment: "",
                  pdi_checklist_id: this.state.ConditionCode[index].ID,
                };
                Checklist_doc.push(data_invest);
              }
              this.setState(
                {
                  ConditionCode: Checklist_doc,
                },
                () => {
                  console.log("FrontLightLeft", this.state.ConditionCode);
                }
              );
            }
          );
        } else {
          this.setState({
            ConditionCode: [
              {
                id: 0,
                Doc_Name: "",
                Doc_Attachment: "",
                Dealer_Id: "",
                File_Name: "",
              },
            ],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Get_JobCard_list_delete = () => {
    const url = Urls.python_baseurl + "/Get_jobDetails_Sp_Get_JobcardDetails";
    var data = JSON.stringify({
      dealer_id: `${this.state.Dealerid}`,
      job_id: this.state.jobcardID,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("ghg", res && res.length > 0);
        if (res && res[1].length > 0) {
          const isNullish = Object.values(res[1][0]).every((value) => {
            if (value === null || value === "") {
              return true;
            }
            return false;
          });
          if (isNullish) {
            this.setState({
              complaintdata: [
                {
                  id: 0,
                  Complaints: "",
                  Complaints_ID: "",
                },
              ],
            });
          } else {
            this.setState(
              {
                complaintdata: res[1],
              },
              () => {
                let complaint_Detail = [];
                for (
                  let index = 0;
                  index < this.state.complaintdata.length;
                  index++
                ) {
                  let data = {
                    id: index,
                    Complaints: this.state.complaintdata[index].complaint,
                    Complaints_ID: this.state.complaintdata[index].ID,
                  };
                  complaint_Detail.push(data);
                }
                this.setState({
                  ComplainData: complaint_Detail,
                });
              }
            );
          }
        } else if (res && res[2].length > 0) {
          const isNullish = Object.values(res[2][0]).every((value) => {
            if (value === null || value === "") {
              return true;
            }
            return false;
          });
          if (isNullish) {
            this.setState({
              investdata: [
                {
                  id: 0,
                  Investigations: "",
                  Investigations_ID: "",
                },
              ],
            });
          } else {
            this.setState(
              {
                investdata: res[2],
              },
              () => {
                let complaint_Detail = [];
                let investdata_ = [];
                for (
                  let index = 0;
                  index < this.state.investdata.length;
                  index++
                ) {
                  let data_invest = {
                    id: index,
                    Investigations:
                      this.state.investdata[index].Dealer_Investigation,
                    Investigations_ID: this.state.investdata[index].ID,
                  };
                  investdata_.push(data_invest);
                }
                this.setState({
                  InvestigationData: investdata_,
                });
              }
            );
          }
        } else if (res && res[3].length > 0) {
          const isNullish = Object.values(res[3][0]).every((value) => {
            if (value === null || value === "") {
              return true;
            }
            return false;
          });
          if (isNullish) {
            this.setState({
              actionlist: [
                {
                  id: 0,
                  Action: "",
                  Action_ID: "",
                },
              ],
            });
          } else {
            this.setState(
              {
                actionlist: res[3],
              },
              () => {
                let actionlist_1 = [];

                for (
                  let index = 0;
                  index < this.state.actionlist.length;
                  index++
                ) {
                  let data_invest = {
                    id: index,
                    Action: this.state.actionlist[index].Dealer_Action,
                    Action_ID: this.state.actionlist[index].ID,
                  };
                  actionlist_1.push(data_invest);
                }
                this.setState({
                  ActionData: actionlist_1,
                });
              }
            );
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "D",
      DealerId: `${this.state.Dealerid}`,
      SqlFor: "'M2'",
      HOBrID: `${this.state.Dealerid}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res,
            // Active: res.Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  JOB_No = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "J",
      iDealerID: `${this.state.Dealerid}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // Job_number: res[0].DocMaxNo,
              JOB_no:
                this.state.Dealer_Code +
                "" +
                "J" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              // this.financiaryear();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  // financiaryear = () => {
  //   const url = Urls.python_baseurl + "/SP_GetDataFromDataQuery";
  //   var data = JSON.stringify({
  //     ValGetDataQueryType: "15",
  //     iParentId: `${sessionStorage.getItem("dealerid")}`,
  //     sAdditionalCondition: "''",
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState(
  //           {
  //             finacial_year: res[0].Value,
  //           },
  //           () => {
  //             this.delarcode();
  //           }
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  // delarcode = () => {
  //   const url = Urls.python_baseurl + "/Get_Dealer_SP_GET_Dealercode";
  //   var data = JSON.stringify({
  //     DealerID: sessionStorage.getItem("dealerid"),
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState({
  //           JOB_no:
  //             res[0].Dealer_vehicle_Code +
  //             "" +
  //             "J" +
  //             "" +
  //             this.state.finacial_year +
  //             "" +
  //             this.state.Job_number,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  Chassisno = (data) => {
    const url = Urls.python_baseurl + "/GetChassisList_sp_chassislist";
    var data = JSON.stringify({
      SelectionType: "''",
      SelectionText: "''",
      JobTypeID: "''",
      DealerID: `${this.state.Dealerid}`,
      HOBRID: `${this.state.Dealerid}`,
      type: "'PDI'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Chassisnumber: res[0],
            },
            () => {
              console.log("Chassisnumber", this.state.Chassisnumber);
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Job_Type = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "149",
      ID: "0",
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            JOBType: res,
          },()=>{
            for (let index = 0; index < this.state.JOBType.length; index++) {
              if (
                this.state.JOBType[index].ID === parseInt(this.state.job_type)
              ) {
                this.setState(
                  {
                    Type_name: this.state.JOBType[index].Name,
                  }
                );
              }
            }
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  SupervisorandMechanic = () => {
    const url =
      Urls.python_baseurl +
      "/SupervisorandMechanic_SP_GetSupervisorAndMechanicData";
    var data = JSON.stringify({
      DealerId: `${this.state.Dealerid}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            superviasorList: res[0],
            MechanicList: res[1],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetChassisData = (chassisid) => {
    const url = Urls.python_baseurl + "/SelectPopup_Sp_DisplayChassisdetails";
    var data = JSON.stringify({
      Chassis_id: `'${chassisid}'`,
      JobType:`'${this.state.Type_name}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Vehical_Registration_No: res[0][0].Vehicle_No,
            IOT_No: res[0][0].IOT_Device_Name,
            Charger_No: res[0][0].charger_no,
            Battery_no: res[0][0].Battery_no,
            Motor_no: res[0][0].Motor_No,
            Model_name: res[0][0].Model_name,
            Model_code: res[0][0].Model_code,
            Customer_Name: res[0][0].Customer_name,
            DisplayNo: res[0][0].Display_No,
            Controller_Unit_No: res[0][0].Control_unit_no,
            Actual_battery_no: res[0][0].Battery_no,
            Actual_Charger_no: res[0][0].charger_no,
            Model_ID: res[0][0].Model_ID,
            ActualController_Unit_No: res[0][0].Control_unit_no,
            ActualMotor_No: res[0][0].Motor_No,
            ActualDispayNo: res[0][0].Display_No,
            ActualIOT_Device_ID: res[0][0].IOT_Device_Name,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  ComplainList = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "44",
      ID: `${this.state.Dealerid}`,
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newdata = [];
          this.setState({
            Complaints: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetInvestigationList = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "45",
      ID: `${this.state.Dealerid}`,
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newdata = [];
          this.setState({
            InvestigationsList: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Get_ActionList = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: "165",
      ID: `${this.state.Dealerid}`,
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newdata = [];
          this.setState({
            ActionList: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handledateChange = (event, key) => {
    this.setState({
      [key]: event.target.value,
    });
  };
  ChangeVal(event, key) {
    console.log("Chassis_No", key, event.target.value);
    this.setState({
      [key]: event.target.value,
    });
    let Chassis_ID = "";
    if (key === "Chassis_No") {
      if (event.target.value == "") {
        this.setState({
          Vehical_Registration_No: "",
          IOT_No: "",
          Charger_No: "",
          Battery_no: "",
          Motor_no: "",
          Model_name: "",
          Model_code: "",
          Customer_Name: "",
          DisplayNo: "",
          Controller_Unit_No: "",
          Actual_battery_no: "",
          Actual_Charger_no: "",
          ActualController_Unit_No: "",
          ActualMotor_No: "",
          ActualDispayNo: "",
          ActualIOT_Device_ID: "",
          KMS: "",
          Mechanics: 0,
          supervisor: 0,
          Bay_No: 0,
          Allocation_time: "",
        });
      } else {
        Chassis_ID = this.state.Chassisnumber.filter((e) => {
          console.log("ghhh", e);
          return e.Chassis_No === event.target.value;
        })[0]?.Chassis_ID;
        this.setState(
          {
            ChassisID: Chassis_ID,
          },
          () => {
            this.GetChassisData(event.target.value);
          }
        );
      }
    }
    if (key === "Bay_No") {
      this.setState({
        Allocation_time: this.state.Allocation_time
          ? this.state.Allocation_time
          : moment(new Date()).format("DD-MM-YYYY hh:mm:ss"),
      });
    }
  }

  handleRemove = (removedValue) => {
    const segmentData = removedValue.map((item) => item.key);

    // Update the state to reflect the removed value
    this.setState(
      {
        selectedValues: removedValue,
        CompleteData: segmentData,
      },
      () => {
        let keysArray = Object.values(this.state.CompleteData);
        let output = keysArray.join(",");
        this.setState(
          {
            standard_access: output,
          },
          () => {
            console.log("y7yy", this.state.standard_access);
          }
        );
      }
    );
  };
  changeDropdown5 = (selectedOptions) => {
    let amount_acc = selectedOptions.map((item) => item.MRP);

    const segmentData = selectedOptions.map((item) => item.key);
    this.setState(
      {
        selectedValues: selectedOptions,
        CompleteData: segmentData,
      },
      () => {
        let keysArray = Object.values(this.state.CompleteData);
        let output = keysArray.join(",");
        this.setState({
          selectedcomplaits: output,
        });
      }
    );
  };
  Bayno = (event) => {
    let row = [];
    this.state.BayList.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Name}</option>);
    });
    return row;
  };
  Chassis_details = () => {
    return (
      <div>
        <div className=" row mt-1">
          <div className=" col-3">
            <label>Job Card No.</label>
            <input
              disabled
              readOnly
              value={this.state.JOB_no}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Job Card Date </label>
            <input
              disabled
              value={this.state.Job_date}
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="visit-details">
              Job Card Type {this.required()}
            </label>
            <select
              className="form-control"
              disabled
              value={this.state.job_type}
            >
              <option value={0}>Select</option>
              {this.state.JOBType.map((e) => {
                return <option value={e.ID}>{e.Name}</option>;
              })}
            </select>
          </div>

          <div className=" col-3">
            <label>Chassis No.{this.required()}</label>
            <input
              autoComplete="off"
              className="form-control"
              list="Chassis_No"
              value={this.state.Chassis_No}
              name="Chassis_No"
              style={{ textTransform: "uppercase" }}
              disabled
            />

            <datalist id="Chassis_No" style={{ maxHeight: "20em" }}>
              {this.state.Chassisnumber.map((e) => {
                return <option>{e.Chassis_No}</option>;
              })}
            </datalist>
          </div>
        </div>
        <div className=" row mt-1">
          <div className=" col-3">
            <label>Vehicle No.</label>
            <input
              disabled
              value={this.state.Vehical_Registration_No}
              className=" form-control "
            ></input>
          </div>

          <div className=" col-3">
            <label>Model Code</label>
            <input
              disabled
              value={this.state.Model_code}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Model Name</label>
            <textarea
              disabled
              value={this.state.Model_name}
              className="form-control"
            ></textarea>
          </div>
          <div className=" col-3">
            <label htmlFor="visit-details">Supervisor Name</label>
            <select
              disabled
              className="form-control"
              value={this.state.supervisor}
            >
              <option value="0">Select</option>
              {this.state.superviasorList &&
                this.state.superviasorList.map((e) => {
                  return <option value={e.ID}>{e.Empl_Name}</option>;
                })}
            </select>
          </div>
        </div>
        <div className=" row mt-3">
          <div className=" col-3">
            <label htmlFor="visit-details">Mechanic Name</label>
            <select
              disabled
              className="form-control"
              value={this.state.Mechanics}
            >
              <option value="0">Select</option>
              {this.state.MechanicList &&
                this.state.MechanicList.map((e) => {
                  return <option value={e.ID}>{e.Empl_Name}</option>;
                })}
            </select>
          </div>

          <div className=" col-3">
            <label>KMS</label>
            <input
              disabled
              value={this.state.KMS}
              className=" form-control "
              type="number"
            ></input>
          </div>
          <div className=" col-3">
            <label>Bay No. </label>
            <select
              disabled
              className="form-control "
              value={this.state.Bay_No}
            >
              <option value="0">Select</option>
              {this.Bayno()}
            </select>
          </div>
          <div className=" col-3">
            <label>Bay Allocation Date & Time</label>
            <input
              disabled
              className="form-control"
              placeholder=""
              value={this.state.Allocation_time}
            ></input>
          </div>
        </div>
        <div className=" row mt-3">
          <div className=" col-3">
            <label>Motor No.</label>
            <input
              disabled
              value={this.state.Motor_no}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Battery No.</label>
            <input
              disabled
              value={this.state.Battery_no}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Charger No. </label>
            <input
              disabled
              value={this.state.Charger_No}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Controller No. </label>
            <input
              disabled
              value={this.state.Controller_Unit_No}
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className=" row mt-3">
          <div className=" col-3">
            <label>Replaced Motor No. </label>
            <input
              disabled
              style={
                this.state.ActualMotor_No !== "" &&
                this.state.Motor_no !== "" &&
                this.state.Motor_no !== this.state.ActualMotor_No
                  ? { fontWeight: "700" }
                  : {}
              }
              value={this.state.ActualMotor_No}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Replaced Battery No. {this.required()}</label>
            <input
              disabled
              style={
                this.state.Battery_no !== "" &&
                this.state.Actual_battery_no !== "" &&
                this.state.Actual_battery_no !== this.state.Battery_no
                  ? { fontWeight: "700" }
                  : {}
              }
              value={this.state.Actual_battery_no}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Replaced Charger No. {this.required()}</label>
            <input
              disabled
              style={
                this.state.Charger_No !== "" &&
                this.state.Actual_Charger_no !== "" &&
                this.state.Charger_No !== this.state.Actual_Charger_no
                  ? { fontWeight: "700" }
                  : {}
              }
              value={this.state.Actual_Charger_no}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Replaced Controller No. </label>
            <input
              disabled
              style={
                this.state.Controller_Unit_No !== "" &&
                this.state.ActualController_Unit_No !== "" &&
                this.state.Controller_Unit_No !==
                  this.state.ActualController_Unit_No
                  ? { fontWeight: "700" }
                  : {}
              }
              value={this.state.ActualController_Unit_No}
              className=" form-control "
            ></input>
          </div>
        </div>
        <div className=" row mt-3">
          <div className=" col-3">
            <label>IOT Device No. </label>
            <input
              disabled
              value={this.state.IOT_No}
              className=" form-control "
            ></input>
          </div>

          <div className=" col-3">
            <label>Display No.</label>
            <input
              disabled
              value={this.state.DisplayNo}
              className=" form-control "
            ></input>
          </div>
        </div>

        <div className="row mt-3">
          <div className=" col-3">
            <label>Replaced IOT Device No. </label>
            <input
              disabled
              style={
                this.state.IOT_No !== "" &&
                this.state.ActualIOT_Device_ID !== "" &&
                this.state.IOT_No !== this.state.ActualIOT_Device_ID
                  ? { fontWeight: "700" }
                  : {}
              }
              value={this.state.ActualIOT_Device_ID}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Replaced Display No.</label>
            <input
              disabled
              style={
                this.state.DisplayNo !== "" &&
                this.state.ActualDispayNo !== "" &&
                this.state.DisplayNo !== this.state.ActualDispayNo
                  ? { fontWeight: "700" }
                  : {}
              }
              value={this.state.ActualDispayNo}
              className=" form-control "
            ></input>
          </div>
          <div className=" col-3">
            <label>Remark. {this.required()}</label>
            <input
              value={this.state.Approval_Remark}
              onChange={(e) => {
                this.setState({ Approval_Remark: e.target.value });
              }}
              className=" form-control "
            ></input>
          </div>
        </div>
      </div>
    );
  };
  Customer_complaints = () => {
    return (
      <div>
        <div className=" row mt-1">
          <div className="col-3">
            <label htmlFor="accessories">Complaints</label>
            <Multiselect
              displayValue="key"
              onKeyPressFn={() => {}}
              onRemove={(removedValue) => this.handleRemove(removedValue)}
              onSearch={() => {}}
              selectedValues={this.state.selectedValues}
              onSelect={(selectedOptions) =>
                this.changeDropdown5(selectedOptions)
              }
              options={this.state.Complaints}
              showCheckbox
            />
          </div>
          <div className=" col-5">
            <label>Complaints Description</label>
            <textarea
              rows="4"
              cols="70"
              value={this.state.Job}
              className=" form-control "
            ></textarea>
          </div>
        </div>
      </div>
    );
  };

  onSelect = (event, key) => {
    console.log("event", event.target.value, key);
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
    if (key === "job_type") {
      if (event.target.value == 0) {
        this.setState({
          Vehical_Registration_No: "",
          IOT_No: "",
          Charger_No: "",
          Battery_no: "",
          Motor_no: "",
          Model_name: "",
          Model_code: "",
          Customer_Name: "",
          DisplayNo: "",
          Controller_Unit_No: "",
          Actual_battery_no: "",
          Actual_Charger_no: "",
          ActualController_Unit_No: "",
          ActualMotor_No: "",
          ActualDispayNo: "",
          ActualIOT_Device_ID: "",
          Chassis_No: "",
          KMS: "",
          Mechanics: 0,
          supervisor: 0,
          Bay_No: 0,
          Allocation_time: "",
        });
      } else {
        this.Chassisno(event.target.value);
      }
    }
  };

  handelCancel = () => {
    alert("Are you sure you want to delet this record.");
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  ondelete(id) {
    if (this.state.answers_mapname.length != 1) {
      let indexToDelete = id;
      this.state.answers_mapname.splice(indexToDelete, 1);
      let data = this.state.answers_mapname;

      if (data != 0) {
        let nieto = [];
        let dummy = [];
        let counter = 0;
        data.forEach((element) => {
          nieto = {
            id: counter,
            Part_No: "",
            Part_Name: "",
            Mechanic: "",
            Request_qty: "",
            Use_qty: "",
            Rate: "",
            Total: "",
          };
          dummy.push(nieto);
          counter++;
        });

        console.log("nieto", dummy);

        this.setState({
          answers_mapname: dummy,
        });
      }
    }
  }
  onclickAddNew() {
    let nieto = [];
    let dummy = [];
    let counter = 0;
    let prvcount =
      this.state.answers_mapname.length > 0
        ? this.state.answers_mapname.length - 1
        : 0;
    for (var i = 0; i < 1; i++) {
      nieto = {
        id: prvcount + 1,
        Part_No: "",
        Part_Name: "",
        Mechanic: "",
        Request_qty: "",
        Use_qty: "",
        Rate: "",
        Total: "",
      };
      this.state.answers_mapname.push(nieto);
      counter++;
    }
    this.setState({
      // answers_mapname: dummy,
      python_API: false,
      count: this.state.count + 1,
    });
  }
  ModelVarientPopUP = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(33, 37, 41, 0.8)",
          marginLeft: "-1.5%",
        }}
        class="modal"
        id="myModal2"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" onClick={this.handelClose}>
                ×
              </button>
            </div>
            <br></br>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body mt">
              {this.Part_list()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  JOBCardlistPopUP = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(33, 37, 41, 0.8)",
          marginLeft: "-1.5%",
        }}
        class="modal"
        id="myModa34"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" onClick={this.handelClose}>
                ×
              </button>
            </div>
            <br></br>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body mt">
              {this.Jobcard_list()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  Mapper_handlInpuChange = (e, id) => {
    var checkbox = e.target;
    console.log("dddddddd");
    const { name, value } = e.target;
    console.log(121, name, value);
    this.setState((prev) => {
      return {
        ...prev,
        answers_mapname: [
          ...prev.answers_mapname.map((el, idx) => {
            console.log(idx, id, "jjj");

            if (idx === id) {
              // emp.push({ ...el, [name]: value})
              return { ...el, [name]: value };
            } else {
              return { ...el };
            }
          }),
        ],
      };
    });
  };

  DelarPoList() {
    const data = [];
    let key = "";
    this.state.row = [];
    console.log("yyyyyy", this.state.answers_mapname);

    this.state.answers_mapname.forEach((d, index) => {
      this.state.row.push(
        <tr>
          <td>
            {index === this.state.answers_mapname.length - 1 && (
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{
                  color: "var(--main-bg-color)",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
                onClick={() => this.onclickAddNew()}
              />
            )}
          </td>
          <td style={{ width: "10%" }}>
            <input
              autoComplete="off"
              style={{ width: "100%" }}
              value={d.Part_No}
              name="Part_No"
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              autoComplete="off"
              style={{ width: "100%" }}
              value={d.Part_Name}
              name="Part_Name"
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>

          <td style={{ width: "10%" }}>
            <input
              autoComplete="off"
              style={{ width: "100%" }}
              value={d.Mechanic}
              name="Mechanic"
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              style={{ width: "100%" }}
              autoComplete="off"
              name="Request_qty"
              value={d.Request_qty}
              // onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              style={{ width: "100%" }}
              autoComplete="off"
              name="Use_qty"
              value={d.Use_qty}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              style={{ width: "100%" }}
              autoComplete="off"
              name="Rate"
              value={d.Rate}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%" }}>
            <input
              style={{ width: "100%" }}
              autoComplete="off"
              name="Total"
              type="number"
              value={this.state["Model_Price" + d.id] * d.Quantity - d.discount}
              onChange={(e) => this.Mapper_handlInpuChange(e, d.id)}
            />
          </td>
          <td style={{ width: "10%", backgroundColor: "white" }}>
            <img
              src={require("../../components/Images/trash.png")}
              style={{ width: "20px", cursor: "pointer" }}
              onClick={() => this.ondelete(index, d.id)}
            ></img>
          </td>
        </tr>
      );
    });
    return (
      <div
        ref={this.messagesContainerRef}
        className="newMapper-popup"
        style={{ overflowY: "auto", height: "200px" }}
      >
        {/* <div className="row justify-content-end" style={{ marginTop: "-15px" }}>
          <button
            type="submit"
            className="btn Opertion_btn clickable mr-4"
            style={{ fontSize: "12px", float: "right", clear: "both" }}
            // onClick={() => this.testclickNavigation2()}
          >
            Import CSV
          </button>
        </div> */}
        <table
          className="table table-bordered mt-2"
          id="userList"
          align="center"
          style={{ width: "100%" }}
        >
          <thead>{this.renderTableHeader12()}</thead>
          <tbody>{this.state.row}</tbody>
        </table>
      </div>
    );
  }
  renderTableHeader12() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          width: "90%",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        <td className="table-text"></td>

        <td style={{ width: "14.2%" }}>Part No.</td>
        <td style={{ width: "14.2%" }}>Part Name</td>
        <td style={{ width: "14.2%" }}>Mechanic</td>
        <td style={{ width: "14.2%" }}>Requested Qty</td>
        <td style={{ width: "14.2%" }}>Use Qty</td>
        <td style={{ width: "14.2%" }}>Rate</td>
        <td style={{ width: "14.2%" }}>Total</td>
        <td className="table-text"></td>
      </tr>
    );
  }
  showModelVarient = () => {
    return (
      <div class="card mt-3">
        <div class="card-header" id="headingSeven">
          <h5 class="mb-0">
            <button
              style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
              class="btn  collapsed"
              data-toggle="collapse"
              data-target="#collaoseSeven"
              aria-expanded="true"
              aria-controls="collaoseSeven"
            >
              Part Details
            </button>
          </h5>
        </div>
        <div
          id="collaoseSeven"
          class="collapse show "
          aria-labelledby="headingSeven"
        >
          <div class="card-body">{this.DelarPoList()}</div>
        </div>
      </div>
    );
  };
  checkboxRenderer = (params) => {
    const that = this;
    const input = document.createElement("input");
    if (params.value === true) {
      input.checked = true;
    } else {
      input.checked = false;
    }
    input.addEventListener("click", function (event) {
      if (event.currentTarget.checked) {
        let data = {};
        data.csvname = params.data.name;
        PartData.push(data);
        // that.props.emptySelectedRow(CSVData);
      } else if (event.currentTarget.checked == false) {
        let data = {};
        let already = false;
        data.csvname = "";
        let mmee = PartData.map((el) => {
          if (el.csvname === params.data.name) {
            already = true;
            return null;
          } else {
            return el;
          }
        }).filter((el) => el !== null);
        const newww = already ? [...mmee] : [...mmee, { ...data }];
        PartData = [];
        const flattenedData = [].concat([...newww]);
        PartData.push(...flattenedData);
        // that.props.emptySelectedRow(PartData);
      }
    });
    return input;
  };
  Part_list() {
    let columnDefs = [
      {
        headerName: "",
        field: "",
        width: 85,
        cellRenderer: this.checkboxRenderer,
      },
      { headerName: "Part No.", field: "resource_name", flex: 1 },
      { headerName: "Part Name", field: "resource_name", flex: 1 },
      { headerName: "Mechanics", field: "fhirversion", flex: 1 },
      { headerName: "Request Quantity", field: "username", flex: 1 },
      { headerName: "Use Quantity", field: "name", flex: 1 },
      { headerName: "Rate", field: "version", flex: 1 },
      { headerName: "Total", field: "version", flex: 1 },
    ];
    return (
      <div>
        <div
          className="ag-theme-balham"
          style={{ padding: "0", marginTop: "5px" }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            overlayLoadingTemplate={this.state.overlayLoadingTemplate}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            //  onGridReady={this.onGridReady}
            rowData={this.state.chassisList}
            enableCellMapNameSelection={true}
            onCellClicked={(event) => {
              if (event.colDef.field == "resource_name") {
                this.setState(
                  {
                    ID: event.data.id,
                    csvMapNamefhirobject: event.data.resource_name,
                    csvMapName: event.data.name,
                    newfile: event.data.name,
                    editflag: true,
                    publicprivatecheck: event.data.hipaas_json.Private,
                  },
                  () => {}
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  vehicleCodition = () => {
    return (
      <div class="form-row">
        <div class="form-group col-md-3">
          <label>Petrol</label>
          <input
            onChange={(e) => this.setState({ Petrol: e.target.value })}
            value={this.state.Petrol}
            className=" form-control "
          ></input>
        </div>
        <div class="form-group col-md-3">
          <label>Battery</label>
          <input
            onChange={(e) => this.setState({ Battery: e.target.value })}
            value={this.state.Battery}
            className=" form-control "
          ></input>
        </div>
        <div class="form-group col-md-3">
          <label>Scrtches info</label>
          <textarea
            onChange={(e) => this.setState({ ScrtchesInfo: e.target.value })}
            value={this.state.ScrtchesInfo}
            className=" form-control "
          ></textarea>
        </div>
        <div class="form-group col-md-3 mt-4 " style={{ paddingLeft: "80px" }}>
          <div className=" row">
            <input
              id="fornt-left"
              onChange={(e) =>
                this.setState({ FrontLightLeft: e.target.checked })
              }
              type="checkbox"
              checked={this.state.FrontLightLeft === "true" ? true : false}
              className=" form-control ml-3"
              style={{ width: "20px" }}
            ></input>
            <label className=" ml-3" htmlFor="fornt-left">
              FrontLight(Left)
            </label>
          </div>
        </div>
        <div class="form-group col-md-3 ">
          <div className=" row">
            <input
              id="fornt-right"
              onChange={(e) =>
                this.setState({ FrontLightRight: e.target.checked })
              }
              checked={this.state.FrontLightRight === "true" ? true : false}
              className=" form-control ml-3"
              style={{ width: "20px" }}
              type="checkbox"
            ></input>
            <label className=" ml-3" htmlFor="fornt-right">
              FrontLight(Right)
            </label>
          </div>
        </div>
        <div class="form-group col-md-3">
          <div className=" row">
            <input
              id="back-Left"
              onChange={(e) =>
                this.setState({ BackLightLeft: e.target.checked })
              }
              checked={this.state.BackLightLeft === "true" ? true : false}
              className=" form-control ml-3"
              style={{ width: "20px" }}
              type="checkbox"
            ></input>
            <label className=" ml-3" htmlFor="back-Left">
              BackLight(Left)
            </label>
          </div>
        </div>
        <div class="form-group col-md-3">
          <div className=" row">
            <input
              id="back-right"
              onChange={(e) =>
                this.setState({ BackLightRight: e.target.checked })
              }
              checked={this.state.BackLightRight === "true" ? true : false}
              className=" form-control ml-3"
              style={{ width: "20px" }}
              type="checkbox"
            ></input>
            <label className=" ml-3" htmlFor="back-right">
              BackLight(Right)
            </label>
          </div>
        </div>
      </div>
    );
  };
  Jobcard_list() {
    let columnDefs = [
      // { headerName: "", field: "", width: 85, cellRenderer: this.checkboxRendererCSVmapper, },
      { headerName: "Part No.", field: "resource_name", flex: 1 },
      { headerName: "Part Name", field: "resource_name", flex: 1 },
      { headerName: "Mechanics", field: "fhirversion", flex: 1 },
      { headerName: "Request Quantity", field: "username", flex: 1 },
      { headerName: "Use Quantity", field: "name", flex: 1 },
      { headerName: "Rate", field: "version", flex: 1 },
      { headerName: "Total", field: "version", flex: 1 },
    ];
    return (
      <div>
        <div
          className="ag-theme-balham"
          style={{ padding: "0", marginTop: "5px" }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            overlayLoadingTemplate={this.state.overlayLoadingTemplate}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            //  onGridReady={this.onGridReady}
            rowData={this.state.chassisList}
            enableCellMapNameSelection={true}
            onCellClicked={(event) => {
              if (event.colDef.field == "resource_name") {
                this.setState(
                  {
                    ID: event.data.id,
                    csvMapNamefhirobject: event.data.resource_name,
                    csvMapName: event.data.name,
                    newfile: event.data.name,
                    editflag: true,
                    publicprivatecheck: event.data.hipaas_json.Private,
                  },
                  () => {}
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  handelClose = () => {
    $("#myModal2").modal("hide");
    $("#myModa34").modal("hide");
    $("#payment_error_modal_2").modal("hide");
    $("#payment_error_modal_3").modal("hide");
    $("#payment_error_modal_3").modal("show");
    $("#payment_error_modal_2").modal("show");
  };
  scrollToBottom() {
    if (this.messagesContainerRef.current) {
      const container = this.messagesContainerRef.current;
      container.scrollTop = container.scrollHeight;
    }
  }

  save_Jobcardno = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "J",
      iDealerID: `${this.state.Dealerid}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              JOB_no:
                this.state.Dealer_Code +
                "" +
                "J" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              this.saveJobcard();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Validate_attach = () => {
    console.log("gfhtty", this.state.ConditionCode);
    // let isValid = this.state.job_type !== "" && this.state.ChassisID !== "" &&
    // this.state.Actual_battery_no!== "" && this.state.Actual_Charger_no!== "" && this.state.Bay_No!=0
    // && this.state.ActualController_Unit_No!="" && this.state.ActualMotor_No!="" &&  this.state.ActualDispayNo!=""
    // && this.state.ActualIOT_Device_ID!=""

    // for (let index = 0; index < this.state.ConditionCode.length; index++) {
    //   if (this.state.ConditionCode[index].Doc_Name === "") {
    //     alert("Please Enter PDI Checklist Number");
    //     return;
    //   }
    // }
    // for (let index = 0; index < this.state.ConditionCode.length; index++) {
    //   if (this.state.ConditionCode[index].File_Name === "") {
    //     if (this.state.ConditionCode[index].Doc_Attachment === "") {
    //       alert("Please Select  PDI Attachment");
    //       return;
    //     }
    //   }
    // }
    if (this.state.JOB_no === "") {
      alert("First Click On Add New to Create Job No.");
      return;
    } else if (this.state.job_type === "0" || this.state.job_type === "") {
      alert("Please Enter Valid Job Card Type ");
      return;
    } else if (this.state.ChassisID === "") {
      alert("Please Select Chassis No.");
      return;
    } else if (this.state.Actual_battery_no === "") {
      alert("Please Enter Actual Battery No.");
      return;
    } else if (this.state.Actual_Charger_no === "") {
      alert("Please Enter Actual Charger No.");
      return;
    }
    //  else if(this.state.Bay_No==="" || this.state.Bay_No==="0"){
    //   alert("Please select Bay No")
    //   return
    //  }
    else {
      // this.state.jobcardID!=0&&this.save_confirmed()
      this.save_confirmed();
    }

    // if (isValid) {
    //   this.saveJobcard();
    // } else {
    //   alert("Please fill all mandatory field.");
    // }
  };
  clear = () => {
    var fileInput = document.getElementById("fileInput");
    fileInput.value = "";
    this.setState({
      jobcardID: 0,
      answers_mapname: [
        {
          id: 0,
          Part_No: "",
          Part_Name: "",
          Mechanic: "",
          Request_qty: "",
          Use_qty: "",
          Rate: "",
          Total: "",
        },
      ],
      double_save: false,
      jobcardID: "",
      Allocation_time: "",
      Isconfirm: "",
      Controller_Unit_No: "",
      Complaints_Description: "",
      approx_Extimate: "",
      Bay_No: 0,
      KMS: 0,
      DisplayNo: "",
      Actual_battery_no: "",
      Actual_Charger_no: "",
      ActualController_Unit_No: "",
      ActualMotor_No: "",
      ActualDispayNo: "",
      ActualIOT_Device_ID: "",
      bay_allot_date: "",
      Mechanics: "0",
      supervisor: "0",
      IOT_No: "",
      Charger_No: "",
      Battery_no: "",
      Motor_no: "",
      Model_name: "",
      Model_code: "",
      Customer_Name: "",
      Vehical_Registration_No: "",
      Chassis_No: "",
      job_type: "",
      Job_date: moment(new Date()).format("DD-MM-YYYY"),
      JOB_no: "",
      Image4: "",
      Image3: "",
      Image2: "",
      Image1: "",
      BackLightRight: "",
      BackLightLeft: "",
      FrontLightRight: "",
      FrontLightLeft: "",
      ScrtchesInfo: "",
      Battery: "",
      Pertrol: "",
      Job_number: 0,
      superviasorList: [],
      Chassisnumber: [],
      JOBType: [],
      MechanicList: [],
      Complaints: [],
      ChassisID: "",
      // FrontLightLeft: "",
      FrontLightRight: "",
      backlight_left: "",
      backlight_right: "",
      JobID: "",
      InvestigationsList: [],
      ActionList: [],
      BayList: [],
      complaintdata: [],
      investdata: [],
      actionlist: [],
      ConditionCode: [
        {
          id: 0,
          Doc_Name: "",
          Doc_Attachment: "",
          File_Name: "",
        },
      ],
      ComplainData: [
        {
          id: 0,
          Complaints: "",
          Complaints_ID: "",
        },
      ],
      InvestigationData: [
        {
          id: 0,
          Investigations: "",
          Investigations_ID: 0,
        },
      ],
      ActionData: [
        {
          id: 0,
          Action: "",
          Action_ID: "",
        },
      ],
    });
  };
  addNew = () => {
    this.clear();

    this.MasterAPICall();
    this.JOB_No();
    this.Job_Type();
    // this.Chassisno();
    this.SupervisorandMechanic();
    this.ComplainList();
    this.GetInvestigationList();
    this.Get_ActionList();
    this.Bay_No();
    this.setState({
      theInputKey: "",
      jobcardID: 0,
    });
  };
  save_confirmed = (event) => {
    this.setState({
      saveLoader: true,
      double_save: true,
    });
    const url = Urls.python_baseurl + "/SaveJobcard_SP_JobcardHeader_Save";
    var data = JSON.stringify({
      ID: this.state.jobcardID ? this.state.jobcardID : this.state.JobID,
      job_no: `'${this.state.JOB_no}'`,
      JobDate: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      Job_Ty_ID: this.state.job_type,
      kmsTot: this.state.KMS,
      hrsTot: 0,
      CustID: 0,
      Chassis_ID: this.state.ChassisID,
      Dealer_ID: `${this.state.Dealerid}`,
      BayID: parseInt(this.state.Bay_No),
      bay_allot_date: this.state.Allocation_time
        ? `'${moment(this.state.Allocation_time).format(
            "DD-MM-YYYY hh:mm:ss"
          )}'`
        : "''",
      VehIn: "''",
      JobOpTime: "''",
      VehCommitTime: "''",
      VehOut: "''",
      VehIn_HDR_Id: 0,
      ApPartAmt: 0,
      ApLabAmt: 0,
      ApLubAmt: 0,
      ApMiscAmt: 0,
      DlrBranchID: 0,
      job_confirm: "'Y'",
      job_canc_tag: "'N'",
      JbCd_Confirm: "''",
      UserID: 0,
      SupervisiorID: 0,
      Failure_Date: "''",
      delay_Rsn_Id: 0,
      Aggregate: "''",
      WarrantyTag: "''",
      chassis_sale_date: "''",
      kmsJobcard: 0,
      HrsJobcard: 0,
      OdoMeterChange: "''",
      HrsMeterChange: "''",
      Bfr_Last_SpdMtrChange_Kms: 0,
      Bfr_Last_HrsMtrChange_Hrs: 0,
      net_tr_amt: 0,
      discount_amt: 0,
      before_tax_amt: 0,
      mst_amt: 0,
      cst_amt: 0,
      surcharge_amt: 0,
      tot_amt: 0,
      pf_per: 0,
      pf_amt: 0,
      other_per: 0,
      other_money: 0,
      Jobcard_tot: 0,
      CRM_HDR_ID: 0,
      DocGST: "''",
      Model_ID: this.state.Model_ID,
      AggregateNo: "''",
      TrNo: "''",
      InsCustID: 0,
      Petrol: `'${this.state.Petrol != undefined ? this.state.Petrol : ""}'`,
      Battery: `'${this.state.Battery != undefined ? this.state.Battery : ""}'`,
      Scratchsinfo: `'${
        this.state.ScrtchesInfo != undefined ? this.state.ScrtchesInfo : ""
      }'`,
      frontlight_Left: `'${
        this.state.FrontLightLeft != undefined ? this.state.FrontLightLeft : ""
      }'`,
      frontlight_right: `'${
        this.state.FrontLightRight != undefined
          ? this.state.FrontLightRight
          : ""
      }'`,
      backlight_left: `'${
        this.state.BackLightLeft != undefined ? this.state.BackLightLeft : ""
      }'`,
      backlight_right: `'${
        this.state.BackLightRight != undefined ? this.state.BackLightRight : ""
      }'`,
      job_card_id: 0,
      Motor_No: `'${this.state.Motor_no}'`,
      Battery_No: `'${this.state.Battery_no}'`,
      Charger_No: `'${this.state.Charger_No}'`,
      Controller_No: `'${
        this.state.Controller_Unit_No != undefined
          ? this.state.Controller_Unit_No
          : ""
      }'`,
      Display_No: `'${this.state.DisplayNo}'`,
      IOT_Device_No: `'${this.state.IOT_No}'`,
      Actual_Battery_No: `'${this.state.Actual_battery_no}'`,
      Actual_Charger_No: `'${this.state.Actual_Charger_no}'`,
      supervisorname: this.state.supervisor,
      mechanicname: this.state.Mechanics,
      ActualController_Unit_No:
        this.state.ActualController_Unit_No != undefined
          ? `'${this.state.ActualController_Unit_No}'`
          : "''",
      ActualMotor_No:
        this.state.ActualMotor_No != undefined
          ? `'${this.state.ActualMotor_No}'`
          : "''",
      ActualDispayNo:
        this.state.ActualDispayNo != undefined
          ? `'${this.state.ActualDispayNo}'`
          : "''",
      ActualIOT_Device_ID:
        this.state.ActualIOT_Device_ID != undefined
          ? `'${this.state.ActualIOT_Device_ID}'`
          : "''",
      DlrCode: `'${this.state.Dealer_Code}'`,
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      Approval_Flag: `'${this.state.Approval_Flag}'`,
      Approval_Remark: `'${
        this.state.Approval_Remark ? this.state.Approval_Remark : ""
      }'`,
      Is_Approved: `'${this.state.Is_Approved ? this.state.Is_Approved : ""}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].jobno) {
            this.setState(
              {
                JobID: res[0].jobno,
                double_save: false,
              },
              () => {
                // this.complaint_save();
              }
            );

            alert("Record Confirmed Succesfully");
            this.props.history.push("/JobCard_List", {});
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  saveJobcard = (Is_Approved) => {
    if (
      this.state.Approval_Remark == "" ||
      this.state.Approval_Remark == undefined
    ) {
      alert("Please Enter  Remark");
      return;
    }
    let isApproval = "N";
    if (
      this.state.ActualMotor_No !== "" &&
      this.state.Motor_no !== "" &&
      this.state.Motor_no !== this.state.ActualMotor_No
    ) {
      isApproval = "Y";
    } else if (
      this.state.Battery_no !== "" &&
      this.state.Actual_battery_no !== "" &&
      this.state.Actual_battery_no !== this.state.Battery_no
    ) {
      isApproval = "Y";
    } else if (
      this.state.Charger_No !== "" &&
      this.state.Actual_Charger_no !== "" &&
      this.state.Charger_No !== this.state.Actual_Charger_no
    ) {
      isApproval = "Y";
    } else if (
      this.state.Controller_Unit_No !== "" &&
      this.state.ActualController_Unit_No !== "" &&
      this.state.Controller_Unit_No !== this.state.ActualController_Unit_No
    ) {
      isApproval = "Y";
    } else if (
      this.state.IOT_No !== "" &&
      this.state.ActualIOT_Device_ID !== "" &&
      this.state.IOT_No !== this.state.ActualIOT_Device_ID
    ) {
      isApproval = "Y";
    } else if (
      this.state.DisplayNo !== "" &&
      this.state.ActualDispayNo !== "" &&
      this.state.DisplayNo !== this.state.ActualDispayNo
    ) {
      isApproval = "Y";
    }
    this.setState({
      saveLoader: true,
      double_save: true,
    });
    const url = Urls.python_baseurl + "/SaveJobcard_SP_JobcardHeader_Save";
    var data = JSON.stringify({
      ID: this.state.jobcardID ? this.state.jobcardID : 0,
      job_no: `'${this.state.JOB_no}'`,
      JobDate: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
      Job_Ty_ID: this.state.job_type,
      kmsTot: this.state.KMS ? this.state.KMS : 0,
      hrsTot: "0",
      CustID: "0",
      Chassis_ID: this.state.ChassisID,
      Dealer_ID: `${this.state.Dealerid}`,
      BayID:
        this.state.Bay_No === null || this.state.Bay_No === undefined
          ? "0"
          : parseInt(this.state.Bay_No),
      bay_allot_date: this.state.Allocation_time
        ? `'${moment(this.state.Allocation_time).format(
            "DD-MM-YYYY hh:mm:ss"
          )}'`
        : "''",
      VehIn: "''",
      JobOpTime: "''",
      VehCommitTime: "''",
      VehOut: "''",
      VehIn_HDR_Id: "0",
      ApPartAmt: "0",
      ApLabAmt: "0",
      ApLubAmt: "0",
      ApMiscAmt: "0",
      DlrBranchID: "0",
      job_confirm: "'N'",
      job_canc_tag: "'N'",
      JbCd_Confirm: "''",
      UserID: "0",
      SupervisiorID: "0",
      Failure_Date: "''",
      delay_Rsn_Id: "0",
      Aggregate: "''",
      WarrantyTag: "''",
      chassis_sale_date: "''",
      kmsJobcard: "0",
      HrsJobcard: "0",
      OdoMeterChange: "''",
      HrsMeterChange: "''",
      Bfr_Last_SpdMtrChange_Kms: "0",
      Bfr_Last_HrsMtrChange_Hrs: "0",
      net_tr_amt: "0",
      discount_amt: "0",
      before_tax_amt: "0",
      mst_amt: "0",
      cst_amt: "0",
      surcharge_amt: "0",
      tot_amt: "0",
      pf_per: "0",
      pf_amt: "0",
      other_per: "0",
      other_money: "0",
      Jobcard_tot: "0",
      CRM_HDR_ID: "0",
      DocGST: "''",
      Model_ID: this.state.Model_ID,
      AggregateNo: "''",
      TrNo: "''",
      InsCustID: "0",
      Petrol: `'${this.state.Petrol != undefined ? this.state.Petrol : ""}'`,
      Battery: `'${this.state.Battery != undefined ? this.state.Battery : ""}'`,
      Scratchsinfo: `'${
        this.state.ScrtchesInfo != undefined ? this.state.ScrtchesInfo : ""
      }'`,
      frontlight_Left: `'${
        this.state.FrontLightLeft != undefined ? this.state.FrontLightLeft : ""
      }'`,
      frontlight_right: `'${
        this.state.FrontLightRight != undefined
          ? this.state.FrontLightRight
          : ""
      }'`,
      backlight_left: `'${
        this.state.BackLightLeft != undefined ? this.state.BackLightLeft : ""
      }'`,
      backlight_right: `'${
        this.state.BackLightRight != undefined ? this.state.BackLightRight : ""
      }'`,
      job_card_id: "0",
      Motor_No: `'${this.state.Motor_no}'`,
      Battery_No: `'${this.state.Battery_no}'`,
      Charger_No: `'${this.state.Charger_No}'`,
      Controller_No: `'${
        this.state.Controller_Unit_No != undefined
          ? this.state.Controller_Unit_No
          : ""
      }'`,
      Display_No: `'${this.state.DisplayNo}'`,
      IOT_Device_No: `'${this.state.IOT_No}'`,
      Actual_Battery_No: `'${this.state.Actual_battery_no}'`,
      Actual_Charger_No: `'${this.state.Actual_Charger_no}'`,
      supervisorname: this.state.supervisor ? this.state.supervisor : 0,
      mechanicname: this.state.Mechanics ? this.state.Mechanics : 0,
      ActualController_Unit_No: this.state.ActualController_Unit_No
        ? `'${this.state.ActualController_Unit_No}'`
        : "''",
      ActualMotor_No: `'${this.state.ActualMotor_No}'`,
      ActualDispayNo: `'${this.state.ActualDispayNo}'`,
      ActualIOT_Device_ID: `'${this.state.ActualIOT_Device_ID}'`,
      DlrCode: `'${this.state.Dealer_Code}'`,
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      Approval_Flag: `'${isApproval}'`,
      Approval_Remark: `'${
        this.state.Approval_Remark ? this.state.Approval_Remark : ""
      }'`,
      Is_Approved: `'${Is_Approved}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].jobno) {
            this.setState(
              {
                JobID: res[0].jobno,
                Jobcard_No: res[0].Jobcard_No,
                Job_HDR_ID: res[0].jobno,
              },
              () => {
                if (Is_Approved == "Y") {
                  alert("Record Approved Succesfully");
                  this.TableAPi()
                } else {
                  alert("Record Rejected Succesfully");
                  this.TableAPi()
                }
              }
            );
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        this.Get_JobCard_list();
      });
  };
  complaint_save = (event) => {
    let newcomplain = [];
    let complaint_data = [];
    let data1 = [];
    let data2 = [];
    let data3 = [];
    let ActionData_data = [];
    let InvestigationData_12 = [];
    let newinvestigation = [];
    let newAction = [];
    for (let index = 0; index < this.state.ComplainData.length; index++) {
      if (this.state.ComplainData[index].Complaints != "") {
        // newcomplain=this.state.ConditionCode[index]
        complaint_data.push(this.state.ComplainData[index]);
      }
    }
    for (let index = 0; index < this.state.InvestigationData.length; index++) {
      if (this.state.InvestigationData[index].Investigations != "") {
        // newinvestigation=this.state.InvestigationData[index]
        InvestigationData_12.push(this.state.InvestigationData[index]);
      }
    }
    console.log(InvestigationData_12, "jjjgf");
    for (let index = 0; index < this.state.ActionData.length; index++) {
      if (this.state.ActionData[index].Action != "") {
        // newAction=this.state.ActionData[index]
        ActionData_data.push(this.state.ActionData[index]);
      }
    }
    if (complaint_data.length > 0) {
      data1 = complaint_data.map((e) => {
        return {
          ID: `${e.iid ? e.iid : 0}`,
          Dealer_Id: `${this.state.Dealerid}`,
          DealerHOBrID: `${this.state.Dealerid}`,
          Jobcard_Hdr_ID: `${this.state.JobID ? this.state.JobID : ""}`,
          Complaint_ID: `${e.Complaints_ID ? e.Complaints_ID : 0}`,
          Complaint_Description: `'${e.Complaints ? e.Complaints : ""}'`,
        };
      });
    }
    if (InvestigationData_12.length > 0) {
      data2 = InvestigationData_12.map((e) => {
        return {
          ID: `${e.iid ? e.iid : 0}`,
          Dealer_Id: `${this.state.Dealerid}`,
          Jobcard_Hdr_ID: `${this.state.JobID}`,
          Investigation_ID: `${e.Investigations_ID ? e.Investigations_ID : 0}`,
          Investigation_Description: `'${
            e.Investigations ? e.Investigations : ""
          }'`,
        };
      });
    }
    if (ActionData_data.length > 0) {
      data3 = ActionData_data.map((e) => {
        return {
          ID: `${e.iid ? e.iid : 0}`,
          Dealer_Id: `${this.state.Dealerid}`,
          Jobcard_Hdr_ID: `${this.state.JobID}`,
          Action_ID: `${e.Action_ID ? e.Action_ID : 0}`,
          Dealer_Action: `'${e.Action ? e.Action : ""}'`,
        };
      });
    }
    // temp.forEach((e)=>{
    //   console.log("tttte",e);
    //   console.log("tttte34",e.getAll("file"));

    // })
    let combineddata = [data1, data2, data3];
    console.log("combineddata", combineddata);
    const url = Urls.python_baseurl + "/Save_Full_JobCard";
    var data = JSON.stringify(combineddata);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0][0].NewId) {
            // this.Investigation_save()
            // this.Attachment_save()
            // this.setState({
            //   saveLoader:false
            // },()=>{
            // })
            // window.location.reload()
            this.Get_JobCard_list();
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        // window.location.reload();
      });
  };
  Attachment_save = (event) => {
    let newrecord = [];
    let insertdata = [];
    for (let index = 0; index < this.state.ConditionCode.length; index++) {
      if (this.state.ConditionCode[index].Doc_Attachment != "") {
        newrecord = this.state.ConditionCode[index];
        insertdata.push(this.state.ConditionCode[index]);
      }
    }
    var formdata = new FormData();
    let data1 = insertdata.map((e) => {
      formdata.append("files", e.Doc_Attachment);
      formdata.append("Dealer_Id", `${this.state.Dealerid}`);
      formdata.append("Check_List_no", `'${this.state.Jobcard_No}'`);
      formdata.append("Job_HDR_ID", this.state.JobID);
      formdata.append("ID", e?.pdi_checklist_id ? e?.pdi_checklist_id : 0);
    });
    // const deletedobj=formdata.filter(e=>{
    //   return formdata.files!=undefined
    // });
    // console.log("deletedobj",deletedobj);
    if (insertdata.length) {
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        Urls.python_baseurl + "/Jobcard_Checklist_Attachments_upload",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => this.Jobcard_checklist_attachment())
        .catch((error) => console.log("error", error));
    }
  };
  maxNo_JOBcard = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: "'2324'",
      DocName: "'J'",
      DealerId: `${this.state.Dealerid}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].NewId) {
            this.setState({
              double_save: true,
            });
            alert("Record Saved Succesfully");
            this.setState(
              {
                saveLoader: false,
              },
              () => {}
            );
            // window.location.reload()
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        // window.location.reload();
      });
  };
  Getcomplaint = (event) => {
    let row = [];
    this.state.Complaints.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option>{element.Name}</option>);
    });
    return row;
  };
  GetInvestigations = (event) => {
    let row = [];
    this.state.InvestigationsList.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option>{element.Name}</option>);
    });
    return row;
  };
  GetAction = (event) => {
    let row = [];
    this.state.ActionList.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option>{element.Name}</option>);
    });
    return row;
  };
  ComplainBody() {
    console.log("ComplainDatavhg", this.state.ComplainData);
    return this.state.ComplainData.map((d, index) => {
      return (
        <tr key={d.id}>
          <td>
            {index === this.state.ComplainData.length - 1 && (
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={
                  this.state.Isconfirm !== "Confirmed"
                    ? {
                        fontSize: "25px",
                        cursor: "pointer",
                        color: "var(--main-bg-color)",
                      }
                    : {
                        fontSize: "25px",
                        cursor: "default",
                        opacity: "30%",
                        color: "var(--main-bg-color)",
                        opacity: "30%",
                      }
                }
                // style={{
                //   color: "var(--main-bg-color)",
                //   fontSize: "25px",
                //   cursor: "pointer",
                // }}
                // onClick={() => this.handelcomplainNew(d.id)}
                disabled={this.state.Isconfirm === "Confirmed" ? true : false}
              />
            )}
          </td>
          <td>
            {/* <select
              value={d.Complaints}
              onChange={(e) => {
                this.handelchange_complaints("Complaints", e.target.value, d.id);
              }}
              id="Complaints"
              className=" form-control "
            >
              <option>Select</option>
              {this.Getcomplaint()}
            </select> */}

            <input
              className=" form-control "
              autoComplete="off"
              style={{ width: "100%" }}
              list="Complaints"
              value={d.Complaints}
              name="Complaints"
              disabled
            />

            <datalist id="Complaints" style={{ maxHeight: "20em" }}>
              {this.Getcomplaint()}
            </datalist>
          </td>
          {/* <td>
            <input
              onChange={(e) => {
                this.handelchange_complaints("Complaints_description", e.target.value, d.id);
              }}
              value={d.Complaints_description}
              className="form-control"
              type="text"
            />
          </td> */}
          {/* <td>
            

            <input
              key={this.state.theInputKey || ""}
              type="file"
              accept=".csv"
              onChange={(e) => {
                this.handelchange("Doc_Attachment", e.target.files[0], d.id);
              }}
            />
          </td> */}
          <td style={{ width: "10%", backgroundColor: "white" }}>
            <img
              src={require("../../components/Images/trash.png")}
              style={
                this.state.Isconfirm !== "Confirmed"
                  ? { width: "20px", cursor: "pointer" }
                  : { width: "20px", cursor: "default", opacity: "30%" }
              }
              disabled
            ></img>
          </td>
        </tr>
      );
    });
  }
  renderComplainTableHeader() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          width: "90%",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        <td width="25px" className="table-text"></td>

        <td style={{ width: "100%" }}>Complaint </td>
        {/* <td style={{ width: "50%" }}>Complaint Description</td> */}
        <td className="table-text"></td>
      </tr>
    );
  }
  handelInvestigationsnew = (id) => {
    if (this.state.Isconfirm !== "Confirmed") {
      if (
        this.state.InvestigationData.id === 0 &&
        this.state.InvestigationData.Investigations === ""
      ) {
        this.setState((prev) => {
          return {
            ...prev,
            InvestigationData: [
              ...this.state.InvestigationData,
              {
                id: id,
                Investigations: "",
                Investigations_ID: "",
              },
            ],
          };
        });
      } else {
        this.setState((prev) => {
          return {
            ...prev,
            InvestigationData: [
              ...this.state.InvestigationData,
              {
                id: id + 1,
                Investigations: "",
                Investigations_ID: "",
              },
            ],
          };
        });
      }
    }
    console.log("ComplainData", this.state.ComplainData);
  };
  InvestigationBody() {
    console.log("hjjjjjj2134jjjj", this.state.InvestigationData);
    return this.state.InvestigationData.map((d, index) => {
      return (
        <tr key={d.id}>
          <td>
            {index === this.state.InvestigationData.length - 1 && (
              <FontAwesomeIcon
                icon={faPlusCircle}
                // style={{
                //   color: "var(--main-bg-color)",
                //   fontSize: "25px",
                //   cursor: "pointer",
                // }}
                style={
                  this.state.Isconfirm !== "Confirmed"
                    ? {
                        fontSize: "25px",
                        cursor: "pointer",
                        color: "var(--main-bg-color)",
                      }
                    : { fontSize: "25px", cursor: "default", opacity: "30%" }
                }
                disabled
              />
            )}
          </td>
          <td>
            <input
              className=" form-control "
              autoComplete="off"
              style={{ width: "100%" }}
              list="Investigations"
              value={d.Investigations}
              name="Investigations"
              disabled
            />

            <datalist id="Investigations" style={{ maxHeight: "20em" }}>
              {this.GetInvestigations()}
            </datalist>
          </td>
          <td style={{ width: "10%", backgroundColor: "white" }}>
            <img
              src={require("../../components/Images/trash.png")}
              style={
                this.state.Isconfirm !== "Confirmed"
                  ? { width: "20px", cursor: "pointer" }
                  : { width: "20px", cursor: "default", opacity: "30%" }
              }
              disabled
            ></img>
          </td>
        </tr>
      );
    });
  }
  renderInvestigationTableHeader() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          width: "90%",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        <td width="25px" className="table-text"></td>

        <td style={{ width: "100%" }}>Observation </td>
        {/* <td style={{ width: "50%" }}>Complaint Description</td> */}
        <td className="table-text"></td>
      </tr>
    );
  }
  handelcomplainNew = (id) => {
    if (this.state.Isconfirm !== "Confirmed") {
      console.log(this.state.ComplainData, "jkjkjkjk");
      if (
        this.state.ComplainData.id === 0 &&
        this.state.ComplainData.Complaints === ""
      ) {
        this.setState((prev) => {
          return {
            ...prev,
            ComplainData: [
              ...this.state.ComplainData,
              {
                id: id,
                Complaints: "",
                Complaints_ID: "",
                iid: 0,
              },
            ],
          };
        });
      } else {
        this.setState((prev) => {
          return {
            ...prev,
            ComplainData: [
              ...this.state.ComplainData,
              {
                id: id + 1,
                Complaints: "",
                Complaints_ID: "",
              },
            ],
          };
        });
      }
    }
    console.log("ComplainData", this.state.ComplainData);
  };
  renderFileUploadTableHeader() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          width: "90%",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        <td width="25px" className="table-text"></td>

        {/* <td style={{ width: "33%" }}>PDI Checklist Name</td> */}
        <td style={{ width: "50%" }}>File Name</td>
        <td style={{ width: "50%" }}>{this.state.job_type==28?"VDI Checklist Attachment":"PDI Checklist Attachment"}</td>

        <td className="table-text"></td>
      </tr>
    );
  }
  handelFileUploadAddNew = (id) => {
    if (this.state.Isconfirm !== "Confirmed") {
      this.setState((prev) => {
        return {
          ...prev,
          ConditionCode: [
            ...this.state.ConditionCode,
            {
              id: id + 1,
              Doc_Name: "",
              Doc_Attachment: "",
              File_Name: "",
              pdi_checklist_id: 0,
            },
          ],
        };
      });
    }
  };
  GLKickOutPopup = () => {
    return (
      <div
        class="modal"
        id="GLKickOut_Popup"
        style={{ marginLeft: "25%" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog-Memberinfo"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog" style={{ maxHeight: "initial" }}>
            <div>
              <div class="modal-body" style={{ fontSize: "16px" }}>
                Are you sure want to delete?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn light_blue1"
                  data-dismiss="modal"
                  onClick={() => {
                    this.handelCancel();
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn light_blue1"
                  onClick={this.delete_Complain}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  handelCancel = () => {
    this.setState({
      ComplainData: this.state.ComplainData,
      InvestigationData: this.state.InvestigationData,
      ActionData: this.state.ActionData,
    });
  };
  AttchmentdeleteOutPopup = () => {
    return (
      <div
        class="modal"
        id="GLKickOut_Popup_delete"
        style={{ marginLeft: "25%" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          class="modal-dialog-Memberinfo"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog" style={{ maxHeight: "initial" }}>
            <div>
              <div class="modal-body" style={{ fontSize: "16px" }}>
                Are you sure want to delete?
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn light_blue1"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn light_blue1"
                  onClick={this.delete_Attchment}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  delete_Attchment = () => {
    const newchecklistArray = this.state.ConditionCode.filter((e) => {
      return e.id != this.state.selected_attchment_id;
    });
    this.setState({
      ConditionCode: [...newchecklistArray],
    });
    const url = Urls.python_baseurl + "/Delete_Attachment_Record_DB_S3";
    var data = JSON.stringify({
      Filename: this.state.filename_delete,
      Check_List_no: this.state.checklistno,
      dealer_ID: `${this.state.Dealerid}`,
      Job_HDR_ID: this.state.jobcardID,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.Status === true) {
          alert("Record Deleted Succesfully");

          // window.location.reload()
          $("#GLKickOut_Popup_delete").modal("hide");
          this.setState(
            {
              selected_attchment_id: "",
              // ConditionCode:[]
            },
            () => {
              // this.Jobcard_checklist_attachment()
            }
          );
          if (this.state.ConditionCode.length === 0) {
            this.setState(
              {
                ConditionCode: [
                  {
                    id: 0,
                    Doc_Name: "",
                    Doc_Attachment: "",
                    Dealer_Id: "",
                    File_Name: "",
                  },
                ],
              },
              () => {}
            );
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  delete_Complain = () => {
    if (this.state.sqlfor === "action") {
      const newInvestigationArray = this.state.ActionData.filter((e) => {
        return e.id != this.state.selected_id;
      });
      this.setState({
        ActionData: [...newInvestigationArray],
      });
    }
    if (this.state.sqlfor === "investigation") {
      const newInvestigationArray = this.state.InvestigationData.filter((e) => {
        return e.id != this.state.selected_id;
      });
      this.setState({
        InvestigationData: [...newInvestigationArray],
      });
    }
    if (this.state.sqlfor === "complaint") {
      const newConditionArray = this.state.ComplainData.filter((e) => {
        return e.id != this.state.selected_id;
      });
      console.log(newConditionArray, "jjhjjk");
      this.setState({
        ComplainData: [...newConditionArray],
      });
    }

    const url =
      Urls.python_baseurl + "/SP_Delete_Complaint_Investigation_Action";
    var data = JSON.stringify({
      sqlfor: `'${this.state.sqlfor}'`,
      ID: this.state.api_delete_id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              selected_id: "",
              sqlfor: "",
              actionlist: [],
              complaintdata: [],
              investdata: [],
            },
            () => {
              // alert("Record Deleted Succesfully");
              $("#GLKickOut_Popup").modal("hide");
              // this.Get_JobCard_list_delete();
            }
          );
          // window.location.reload()

          if (
            this.state.sqlfor === "complaint" &&
            this.state.ComplainData.length === 0
          ) {
            this.setState({
              ComplainData: [
                {
                  id: 0,
                  Complaints: "",
                  Complaints_ID: "",
                },
              ],
            });
          } else if (
            this.state.sqlfor === "investigation" &&
            this.state.InvestigationData.length === 0
          ) {
            this.setState({
              InvestigationData: [
                {
                  id: 0,
                  Investigations: "",
                  Investigations_ID: "",
                },
              ],
            });
          } else if (
            this.state.sqlfor === "action" &&
            this.state.ActionData.length === 0
          ) {
            this.setState({
              ActionData: [
                {
                  id: 0,
                  Action: "",
                  Action_ID: "",
                },
              ],
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelComplainDelete = (id, Complaints_ID) => {
    alert(Complaints_ID);
    if (this.state.Isconfirm !== "Confirmed") {
      if (Complaints_ID > 0) {
        $("#GLKickOut_Popup").modal("show");
        this.setState({
          api_delete_id: Complaints_ID,
          sqlfor: "complaint",
          selected_id: id,
        });
      } else {
        if (this.state.ComplainData.length !== 1) {
          const newConditionArray = this.state.ComplainData.filter((e) => {
            return e.id != id;
          });
          this.setState({
            ComplainData: [...newConditionArray],
          });
        } else {
          this.setState({
            ComplainData: [
              {
                id: 0,
                Complaints: "",
                Complaints_ID: "",
              },
            ],
          });
        }
      }
    }
  };
  handel_InvestigationDelete = (id, Investigations_ID) => {
    if (this.state.Isconfirm !== "Confirmed") {
      if (Investigations_ID > 0) {
        $("#GLKickOut_Popup").modal("show");
        // const newInvestigationArray = this.state.InvestigationData.filter((e) => {
        //   return e.id != id;
        // });
        this.setState({
          api_delete_id: Investigations_ID,
          selected_id: id,
          sqlfor: "investigation",
          // InvestigationData: [...newInvestigationArray],
        });
      } else if (this.state.InvestigationData.length > 1) {
        const newInvestigationArray = this.state.InvestigationData.filter(
          (e) => {
            return e.id != id;
          }
        );
        this.setState({
          InvestigationData: [...newInvestigationArray],
        });
      } else {
        this.setState({
          InvestigationData: [
            {
              id: 0,
              Investigations: "",
              Investigations_ID: 0,
            },
          ],
        });
      }
    }
  };
  handel_ActionDelete = (id, Action_ID) => {
    if (this.state.Isconfirm !== "Confirmed") {
      if (Action_ID > 0) {
        $("#GLKickOut_Popup").modal("show");
        // const newInvestigationArray = this.state.ActionData.filter((e) => {
        //   return e.id != id;
        // });
        this.setState({
          api_delete_id: Action_ID,
          selected_id: id,
          sqlfor: "action",
          // ActionData: [...newInvestigationArray],
        });
      } else {
        if (this.state.ActionData.length > 1) {
          const newInvestigationArray = this.state.ActionData.filter((e) => {
            return e.id != id;
          });
          this.setState({
            ActionData: [...newInvestigationArray],
          });
        } else {
          this.setState({
            ActionData: [
              {
                id: 0,
                Action: "",
                Action_ID: "",
              },
            ],
          });
        }
      }
    }
  };
  handelActionnew = (id) => {
    if (this.state.Isconfirm !== "Confirmed") {
      if (
        this.state.ActionData.id === 0 &&
        this.state.ActionData.Action === ""
      ) {
        this.setState((prev) => {
          return {
            ...prev,
            ActionData: [
              ...this.state.ActionData,
              {
                id: id,
                Action: "",
                Action_ID: "",
              },
            ],
          };
        });
      } else {
        this.setState((prev) => {
          return {
            ...prev,
            ActionData: [
              ...this.state.ActionData,
              {
                id: id + 1,
                Action: "",
                Action_ID: "",
              },
            ],
          };
        });
      }
    }
    console.log("ComplainData7", this.state.ComplainData);
  };
  handelchange_Action = (key, value, id) => {
    console.log("value", this.state.ActionData);
    let ActionDataid = 0;
    if (key === "Action" && value != "null") {
      ActionDataid = this.state.ActionList.filter((e) => {
        return e.Name === value;
      })[0]?.ID;
      console.log("complaintdid", ActionDataid);
    }

    if (key == "Action" && value != "null") {
      const obj = this.state.ActionData.filter((e) => {
        return e.id == id;
      });
      obj.Action = value;
      let data = this.state.ActionData;
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == id) {
          data[i].Action = value;
          data[i].Action_ID = ActionDataid === undefined ? 0 : ActionDataid;
        }
      }
      this.setState({
        ActionData: data,
      });
    }

    console.log("ActionDataid", this.state.ActionData);
  };
  ActionBody() {
    console.log("hjjj87554", this.state.ActionData);
    return this.state.ActionData.map((d, index) => {
      return (
        <tr key={d.id}>
          <td>
            {index === this.state.ActionData.length - 1 && (
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={
                  this.state.Isconfirm !== "Confirmed"
                    ? {
                        fontSize: "25px",
                        cursor: "pointer",
                        color: "var(--main-bg-color)",
                      }
                    : { fontSize: "25px", cursor: "default", opacity: "30%" }
                }
                // style={{
                //   color: "var(--main-bg-color)",
                //   fontSize: "25px",
                //   cursor: "pointer",
                // }}
                disabled
              />
            )}
          </td>
          <td>
            <input
              className=" form-control "
              autoComplete="off"
              style={{ width: "100%" }}
              list="Action"
              value={d.Action}
              name="Action"
              disabled
            />

            <datalist id="Action" style={{ maxHeight: "20em" }}>
              {this.GetAction()}
            </datalist>
          </td>
          <td style={{ width: "10%", backgroundColor: "white" }}>
            <img
              src={require("../../components/Images/trash.png")}
              style={
                this.state.Isconfirm !== "Confirmed"
                  ? { width: "20px", cursor: "pointer" }
                  : { width: "20px", cursor: "default", opacity: "30%" }
              }
              disabled
            ></img>
          </td>
        </tr>
      );
    });
  }
  renderActionTableHeader() {
    return (
      <tr
        className="table-head-text-view"
        style={{
          backgroundColor: "var(--main-bg-color)",
          width: "90%",
          fontSize: "12px",
          color: "white",
          position: "sticky",
          top: "0",
        }}
      >
        <td width="25px" className="table-text"></td>

        <td style={{ width: "100%" }}>Action </td>
        {/* <td style={{ width: "50%" }}>Complaint Description</td> */}
        <td className="table-text"></td>
      </tr>
    );
  }
  handelFileUploadDelete = (id, d) => {
    console.log("sgdfvsd", d);
    if (this.state.Isconfirm !== "Confirmed") {
      if (d.Doc_Name != "") {
        $("#GLKickOut_Popup_delete").modal("show");
        // const newchecklistArray = this.state.ConditionCode.filter((e) => {
        //   console.log("dvchga",e);
        //   return e.id != id;
        // });
        this.setState({
          filename_delete: d.File_Name,
          checklistno: d.Doc_Name,
          selected_attchment_id: id,
          //  ConditionCode: [...newchecklistArray],
        });
      } else if (this.state.ConditionCode.length > 1) {
        const newConditionArray = this.state.ConditionCode.filter((e) => {
          return e.id != id;
        });
        this.setState({
          ConditionCode: [...newConditionArray],
        });
      } else {
        var fileInput = document.getElementById("fileInput");
        fileInput.value = "";
        this.setState({
          ConditionCode: [
            {
              id: 0,
              Doc_Name: "",
              Doc_Attachment: "",
              Dealer_Id: "",
              File_Name: "",
              pdi_checklist_id: 0,
            },
          ],
        });
      }
    }
  };
  handelchange_complaints = (key, value, id, complaint_id, iid) => {
    let complaintdid = 0;
    if (key === "Complaints" && value !== "null") {
      complaintdid = this.state.Complaints.filter((e) => {
        return e.Name === value;
      })[0]?.ID;
      console.log("complaintdid", complaintdid);
    }

    if (key == "Complaints" && value != "null") {
      const obj = this.state.ComplainData.filter((e) => {
        return e.id == id;
      });
      obj.Complaints = value;
      let data = this.state.ComplainData;
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == id) {
          data[i].Complaints = value;
          data[i].Complaints_ID = complaintdid === undefined ? 0 : complaintdid;
          data[i].iid = data[i].iid;
        }
      }
      this.setState({
        ComplainData: data,
      });
    }
    // if (key == "Complaints_description") {
    //   const obj = this.state.ComplainData.filter((e) => {
    //     return e.id == id;
    //   });
    //   obj.Complaints_description = value;
    //   let data = this.state.ComplainData;
    //   for (let i = 0; i < data.length; i++) {
    //     if (data[i].id == id) {
    //       data[i].Complaints_description = value;
    //     }
    //   }
    //   this.setState({
    //     ConditionCode: data,
    //   });
    // }

    console.log("Complaintshh", this.state.ComplainData);
  };
  handelchange_Investigations = (key, value, id) => {
    console.log("value", this.state.ComplainData);
    let Investigationid = 0;
    if (key === "Investigations" && value != "null") {
      Investigationid = this.state.InvestigationsList.filter((e) => {
        console.log("Investigationsvvv", e, value);
        return e.Name === value;
      })[0]?.ID;
      console.log("complaintdid", Investigationid);
    }

    if (key == "Investigations" && value != "null") {
      const obj = this.state.InvestigationData.filter((e) => {
        return e.id == id;
      });
      obj.Complaints = value;
      let data = this.state.InvestigationData;
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == id) {
          data[i].Investigations = value;
          data[i].Investigations_ID =
            Investigationid === undefined ? 0 : Investigationid;
        }
      }
      this.setState({
        InvestigationData: data,
      });
    }

    console.log("Compl555aintshh", this.state.InvestigationData);
  };

  handelchange = (key, value, id) => {
    if (key == "Doc_Name" && value != "null") {
      const obj = this.state.ConditionCode.filter((e) => {
        return e.id == id;
      });
      if (value < 0) {
        value = "";
      }
      let data = this.state.ConditionCode;
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == id) {
          data[i].Doc_Name = value;
        }
      }
      this.setState({
        ConditionCode: data,
      });
    }

    if (key == "Doc_Attachment") {
      const selectedFiles = Array.from(value.target.files);
      const obj = this.state.ConditionCode.filter((e) => {
        return e.id == id;
      });
      obj.Doc_Attachment = value.target.files;
      let data = this.state.ConditionCode;
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == id) {
          data[i].Doc_Attachment = selectedFiles[0];
        }
      }
      this.setState(
        {
          ConditionCode: data,
        },
        () => {
          console.log(this.state.ConditionCode, "jashjdjh");
        }
      );
    }
    // if (key == "Doc_Attachment") {
    //   const readFileAsync = async () => {
    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();

    //       reader.onload = (e) => {
    //         const text = e.target.result;
    //         resolve(text);
    //       };

    //       reader.onerror = (error) => {
    //         reject(error);
    //       };

    //       reader.readAsText(value);
    //     });
    //   };
    //   readFileAsync().then((text) => {
    //     value = text;
    //     const obj = this.state.ConditionCode.filter((e) => {
    //       return e.id == id;
    //     });
    //     obj.Doc_Attachment = value;
    //     let data = this.state.ConditionCode;
    //     for (let i = 0; i < data.length; i++) {
    //       if (data[i].id == id) {
    //         data[i].Doc_Attachment = value;
    //       }
    //     }
    //     this.setState(
    //       {
    //         ConditionCode: data,
    //       },
    //       () => {
    //         console.log(this.state.ConditionCode, "jashjdjh");
    //       }
    //     );
    //   });
    // }
  };
  handelFileDownload = (id) => {
    let selected = this.state.ConditionCode.find((e) => {
      return e.id === id;
    });

    if (selected && selected.path) {
      const link = document.createElement("a");
      link.href = selected.path;
      link.setAttribute("download", `${selected.File_Name}.xlsx`);
      document.body.appendChild(link);

      link.click();
      document.body.removeChild(link);
    } else {
      console.error("File path not found for ID:", id);
    }
  };
  handelFileDisplay = (id) => {
    let selected = this.state.ConditionCode.find((e) => {
      return e.id === id;
    });
    console.log("uyyy", selected.path);

    if (selected && selected.path) {
      fetch(selected.path).then((res) => {
        console.log("🚀 ~ fetch ~ res:", res);
        if (res.status === 200) {
          this.state.temparray.push(res.url);
          let data = [{ uri: res.url }];
          // console.log("ngfgg",res.url);
          this.setState(
            {
              newurl: data,
              //newurl:this.state.temparray
            },
            () => {
              const link = document.createElement("a");
              link.href = selected.path;
              link.setAttribute("download", `${selected.File_Name}.xlsx`);
              document.body.appendChild(link);

              link.click();
              document.body.removeChild(link);
              console.log("ngfgg", this.state.newurl);

              $("#ExcelPopUP_3").modal("show");
            }
          );
          // setFile([{ uri: res.url }]);
        } else {
          //  setError(true)
        }
      });
      // this.props.history.push({
      //   pathname: Strings.FileDisplay,
      //   state: { pathValue: selected.path }
      // })
    }
  };
  ExcelPOPUP = () => {
    console.log(this.state.newurl, "hjjhhj");
    return (
      <div
        style={{
          backgroundColor: "rgba(33, 37, 41, 0.8)",
        }}
        class="modal"
        id="ExcelPopUP_3"
      >
        <div class="modal-dialog modal-xl_43">
          <div
            class="modal-content"
            style={{ width: "1000px", marginLeft: "-250px" }}
          >
            <div class="modal-header">
              <button
                type="button"
                class="close"
                onClick={() => {
                  this.setState({
                    newurl:[]
                  })
                  $("#ExcelPopUP_3").modal("hide")}}
              >
                ×
              </button>
            </div>
            <br></br>
            <div class="container"></div>
            <div className="container_file">
              <DocViewer
                documents={this.state.newurl}
                pluginRenderers={DocViewerRenderers}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  fileUploadTableBody() {
    console.log("gfvh", this.state.ConditionCode);
    return this.state.ConditionCode.map((d, index) => {
      return (
        <tr key={d.id}>
          <td>
            {index === this.state.ConditionCode.length - 1 && (
              <FontAwesomeIcon
                icon={faPlusCircle}
                // style={{
                //   color: "var(--main-bg-color)",
                //   fontSize: "25px",
                //   cursor: "pointer",
                // }}
                style={
                  this.state.Isconfirm !== "Confirmed"
                    ? {
                        fontSize: "25px",
                        cursor: "pointer",
                        color: "var(--main-bg-color)",
                      }
                    : { fontSize: "25px", cursor: "default", opacity: "30%" }
                }
                disabled
              />
            )}
          </td>
          {/* <td>
            <input
              onChange={(e) => {
                this.handelchange("Doc_Name", e.target.value, d.id);
              }}
              value={d.Doc_Name}
              className="form-control"
              disabled={this.state.Isconfirm === "Confirmed" ? true : false}
            />
          </td> */}
          <td>
            {d.File_Name ? (
              <label
                style={{ color: "var(--main-bg-color)", cursor: "pointer" }}
                disabled
                onClick={() => this.handelFileDisplay(d.id)}
              >
                {d.File_Name}
              </label>
            ) : null}
          </td>
          <td>
            <input
              //  style={{ marginLeft: "10px", width: "82px", }}
              key={this.state.theInputKey || ""}
              id="fileInput"
              type="file"
              accept=".xlsx, .xls"
              disabled
            />
            {/* <span style={{ margin: "33px 10px 0 3px" }} className="small">
            {d.Doc_Attachment && d.Doc_Attachment
              ? d.Doc_Attachment
              : "No File Chosen"}
          </span> */}
          </td>

          <td style={{ width: "10%", backgroundColor: "white" }}>
            <img
              src={require("../../components/Images/trash.png")}
              style={
                this.state.Isconfirm !== "Confirmed"
                  ? { width: "20px", cursor: "pointer" }
                  : { width: "20px", cursor: "default", opacity: "30%" }
              }
              disabled
            ></img>
          </td>
        </tr>
      );
    });
  }
  Back = () => {
    <div>
      {this.props.history.push("/JobCard_List", {
        data: this.props.location.state.data,
      })}
    </div>;
  };
  TableAPi = () => {
    const url = Urls.python_baseurl + "/SP_Display_PendingApprovalJobCards";
    var data = JSON.stringify({
      From_Date: `'${this.state.startDate}'`,
      To_Date: `'${this.state.endDate}'`,
      Model_Part: "''",
      Dealer_ID: `'${this.state.Dealerid}'`,
      HOBrID: "0",
    });
    console.log("ddd", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          this.setState({
            TableRowData: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Job Card No",
        field: "JobCard No",
        flex: "1",
        cellStyle:{
          color: "var(--main-bg-color)",
          cursor: "pointer",
        },
      },
      { headerName: "Job Card Date", field: "Job Date", flex: "1" },
      { headerName: "Job Card Type", field: "Job Type", flex: "1" },
      { headerName: "Chassis No.", field: "Chassis No", flex: "1" },
      { headerName: "Vehicle No.", field: "Vehicle No", flex: "1" },
      { headerName: "Status", field: "status", flex: "1" },
      {
        headerName: "Approval Status",
        cellRenderer: (params) => {
          let status = "";
          let color = "";

          if (
            params.data.Approval_Flag === "Y" &&
            params.data.Is_Approved === ""
          ) {
            status = "Pending For Approval";
            color = "orange";
          } else if (
            params.data.Approval_Flag === "Y" &&
            params.data.Is_Approved === "Y"
          ) {
            status = "Approved";
            color = "green";
          } else if (
            params.data.Approval_Flag === "Y" &&
            params.data.Is_Approved === "N"
          ) {
            status = "Rejected";
            color = "red";
          } else {
            status = "Approval Not Required";
          }

          return <span style={{ color: color }}>{status}</span>;
        },
        flex: "1",
      },
    ];

    return (
      <div>
        <div
          className="ag-theme-balham mt-3"
          style={{ padding: "0", width: "100% !important" }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            rowData={this.state.TableRowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (true) {
                if (event.colDef.field == "JobCard No") {
                  this.setState(
                    {
                      Active: event.data.Active,
                      Model_Category: event.data["Model Category"],
                      jobcardID: event.data.ID,
                      Dealerid:event.data.Dealer_ID,
                      Dealer_Code:event.data.Dealer_Code
                    },
                    () => {

                      this.getDataonAgGridClick();
                    }
                  );
                }
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  getDealer = () => {
    const url = Urls.python_baseurl + "/SP_Get_Dealer";
    var data = JSON.stringify({
      Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
      RegionID: "'All'",
      StateID: "'All'",
      DistrictID: "'All'",
      TalukaID: "'All'",
      Dealer_Category: "'All'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            DealerOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  showFilter = () => {
    return (
      <div className=" row card flex-row py-4 mt-1">
        <div className=" form-group col-md-3">
          <label>Dealer/Distributor</label>
          <select
            name="Dealer"
            onChange={(e) => {
              if (e.target.value == 0) {
                this.setState(
                  {
                    Dealerid: sessionStorage.getItem("dealerid"),
                    dealercode:""
                  },
                  () => {
                    this.TableAPi();
                  }
                );
              } else {
                let dealercode = document
                  .getElementById(e.target.value)
                  .getAttribute("name");
                dealercode = dealercode.split("(")[1].replace(")", "");
                this.setState(
                  {
                    Dealerid: e.target.value,
                    Dealer_Code: dealercode,
                  },
                  () => {
                    this.TableAPi();
                  }
                );
              }
            }}
            className=" form-control "
            value={this.state.Dealerid}
          >
            <option value={0}>All</option>
            {this.state.DealerOption.map((e) => {
              return (
                <option id={e?.ID} name={e?.Name} value={e?.ID}>
                  {e?.Name}
                </option>
              );
            })}
          </select>
        </div>
        <div className=" form-group col-md-3">
          <label>SD/D Code</label>
          <input
            value={this.state.Dealer_Code}
            disabled
            className=" form-control "
          ></input>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text" style={{ marginLeft: "2px" }}>
          Job Card Approval
        </h4>
        <br></br>
        <br></br>
        <div
          style={{ marginTop: "-10px" }}
          className=" row justify-content-end "
        >
          <button
            disabled={this.state.Is_Approved !== "" || this.state.double_save}
            type="submit"
            className="btn Opertion_btn clickable "
            onClick={() => this.saveJobcard("Y")}
          >
            Approve
          </button>
          <button
            disabled={this.state.Is_Approved !== "" || this.state.double_save}
            type="submit"
            className="btn Opertion_btn clickable mr-4"
            style={{ fontSize: "12px", float: "right", clear: "both" }}
            onClick={() => this.saveJobcard("N")}
          >
            Reject
          </button>
        </div>
        {this.showFilter()}
        {this.masterTable()}

        <div class="card mt-2">
          <div class="card-header" id={"headingFour" + this.props.modelid}>
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target={"#collapseFour" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseFour" + this.props.modelid}
              >
                Job Card Details
              </button>
            </h5>
          </div>
          <div
            id={"collapseFour" + this.props.modelid}
            class="collapse show "
            aria-labelledby={"headingFour" + this.props.modelid}
          >
            <div class="card-body">{this.Chassis_details()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"headingFive" + this.props.modelid}>
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target={"#collapseFive" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseFive" + this.props.modelid}
              >
                Complaint Details
              </button>
            </h5>
          </div>
          <div
            id={"collapseFive" + this.props.modelid}
            class="collapse "
            aria-labelledby={"headingFive" + this.props.modelid}
          >
            <div class="card-body">
              <table
                className="table table-bordered mt-2"
                id="userList"
                align="center"
                style={{ width: "100%" }}
              >
                <thead>{this.renderComplainTableHeader()}</thead>
                <tbody>{this.ComplainBody()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"headingSix" + this.props.modelid}>
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target={"#collapseSix" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseSix" + this.props.modelid}
              >
                Dealer Observation
              </button>
            </h5>
          </div>
          <div
            id={"collapseSix" + this.props.modelid}
            class="collapse "
            aria-labelledby={"headingSix" + this.props.modelid}
          >
            <div class="card-body">
              <table
                className="table table-bordered mt-2"
                id="userList"
                align="center"
                style={{ width: "100%" }}
              >
                <thead>{this.renderInvestigationTableHeader()}</thead>
                <tbody>{this.InvestigationBody()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id={"headingNine" + this.props.modelid}>
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target={"#collapseNine" + this.props.modelid}
                aria-expanded="true"
                aria-controls={"collapseNine" + this.props.modelid}
              >
                Dealer Action
              </button>
            </h5>
          </div>
          <div
            id={"collapseNine" + this.props.modelid}
            class="collapse "
            aria-labelledby={"headingNine" + this.props.modelid}
          >
            <div class="card-body">
              <table
                className="table table-bordered mt-2"
                id="userList"
                align="center"
                style={{ width: "100%" }}
              >
                <thead>{this.renderActionTableHeader()}</thead>
                <tbody>{this.ActionBody()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card ">
          <div class="card-header" id="headingEight">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseEight"
                aria-expanded="true"
                aria-controls="collapseEight"
              >
                {this.state.job_type==28?"VDI Checklist":"PDI Checklist"}
                {/* PDI Checklist */}
              </button>
            </h5>
          </div>
          <div
            id="collapseEight"
            class="collapse "
            aria-labelledby="headingEight"
          >
            <div class="card-body">
              <table
                className="table table-bordered mt-2"
                id="userList"
                align="center"
                style={{ width: "100%" }}
              >
                <thead>{this.renderFileUploadTableHeader()}</thead>
                <tbody>{this.fileUploadTableBody()}</tbody>
              </table>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
       {this.state.newurl&&this.ExcelPOPUP()}
      </div>
    );
  }
}
