import React, { Component } from "react";
import Strings from "../../../helpers/Strings";
import "../Files/files-styles.css";
import Urls from "../../../helpers/Urls";
const bcrypt = require("bcryptjs");
const $ = window.$;
export class Forgot_Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paginationPageSize: 10,
      domLayout: "autoHeight",
      username: "",
      isValid: false,
      userPresent: true,
      message: "",
      forgotStarted: false,
    };
  }
  handel_input_change(event) {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
    const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (pattern.test(event.target.value)) {
      this.setState({
        ...this.state,
        isValid: true,
        username: event.target.value,
      });
    } else {
      this.setState({ ...this.state, isValid: false });
    }
  }
  checkuser = async () => {
    this.setState({ forgotStarted: true });
    fetch(Urls.python_baseurl + "/send_otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache",
        Accept: "application/json",
      },
      body: JSON.stringify({
        login_name: `${this.state.username}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        alert(res.message);
        if (res.Status == false) {
          this.setState({ userPresent: false });
          this.setState({ message: res.message });
          this.setState({ forgotStarted: false });
        } else {
          let data = [];
          data = [{ username: this.state.username }];
          this.props.history.push("/"+Strings.Send_OTP, {
            data: data,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  submit = () => {
    let username = this.state.username ? this.state.username : "";
    if (username) {
      if (!this.state.forgotStarted) {
        this.checkuser();
      }
    } else {
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid bg-body-tertiary d-block ">
          <div
            className=" row justify-content-end "
            style={{ backgroundColor: "var(--main-bg-color)" }}
          >
            <img
              src={require("../../components/Images/Joy_connect_Logo.jpg")}
              alt="logo"
              align="center"
              style={{
                backgroundColor: "var(--main-bg-color)",
                width: "120px",
                marginRight: "20px",
              }}
            />
          </div>
          <div className="row justify-content-center">
            <div className="col-10 mt-3  col-md-6 sendotp">
              {this.state.forgotStarted == true ? (
                <div
                  style={{
                    marginLeft: "45%",
                    position: "absolute",
                    marginTop: "20%",
                    zIndex: "30",
                  }}
                  class="spinner-border text-muted"
                ></div>
              ) : (
                ""
              )}
              <div
                className="card bg-white mb-5 p-5 mt-5 border-0"
                style={{
                  boxShadow: "0 0px 15px rgba(0.1, 0.1, 0.1, .1)",
                  opacity: this.state.forgotStarted == true ? "0.4" : "",
                  zIndex: "10",
                }}
              >
                <div className="card-body px-5 text-center">
                  <h2 className="heading">Forgot Password!</h2>
                  <p className="message">
                    Enter your Username for verification process, We will send 6
                    digit code to your email.
                  </p>
                  <div className="row justify-content-center mt-3">
                    <input
                      onChange={(event) =>
                        this.setState({ username: event.target.value }, () => {
                          if (this.state.username.trim().length > 0) {
                            this.setState({
                              isValid: true,
                            });
                          }
                        })
                      }
                      name="username"
                      className="forgot_password form-control w-50"
                      type="email"
                      placeholder="DSD007SALES"
                      required
                    ></input>
                  </div>
                  {this.state.isValid ? (
                    <button
                      type="submit"
                      onClick={this.submit}
                      className="mt-4 btn Opertion_btn forgot_password"
                    >
                      Continue
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled
                      className="mt-4 btn Opertion_btn forgot_password"
                    >
                      Continue
                    </button>
                  )}
                  {this.state.userPresent ? (
                    <p>{this.state.message}</p>
                  ) : (
                    <p>
                      {this.state.message}
                      <a
                        style={{ color: "blue", cursor: "pointer" }}
                        onClick={(event) =>
                          (window.location.href = "/Customer_Registration")
                        }
                      >
                        Create an Account!
                      </a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
