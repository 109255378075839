import React from "react";
import "../../Files/files-styles.css";
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import Strings from "../../../../helpers/Strings";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";

const bcrypt = require("bcryptjs");

export class Enquiry_List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      Active: "",
      Depot_Code: "",
      Depot_Flag: "",
      Depot_Name: "",
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      rowData: [],
    };
  }
  componentDidMount() {
    this.MasterAPICall()
  }
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    let data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "D",
      DealerId: sessionStorage.getItem("dealerid"),
      SqlFor: "'M1Details'",
      HOBrID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res[0],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  }
  handlePrint = () => {
    window.print();
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Enquiry Number",
        field: "M1 Number",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Mobile", field: "mobile", flex: "1" },
      // { headerName: "Enquiry Date", field: "M1 Date", flex: "1" },
      { headerName: "Customer Name", field: "Name", flex: "1" },
      {
        headerName: "Quotation Print",
        flex: "1",
        field: "M1 Number",
        cellStyle: { color: "green",fontweight:'bold', cursor: "pointer" },
        cellRenderer: (data) => {
        
          return "Print";
       
     },
      },
      {
        headerName: "Booking Status",
        flex: "1",
        field: "M1 Number",
        cellStyle: { color: "green",  cursor: "pointer" },
        cellRenderer: (data) => {
        
          return "Pending";
       
     },
      },
      {
        headerName: "Money Received",
        flex: "1",
        field: "M1 Number",
        cellStyle: { color: "green", fontweight:"bold", cursor: "pointer" },
        
        cellRenderer: (data) => {        
          return "Pending";
       
     },
      },
      {
        headerName: "Vehicle Invoice",
        flex: "1",
        field: "M1 Number",
        cellStyle: { color: "green", fontweight:'900', cursor: "pointer" },
        cellRenderer: (data) => {
        
          return "Pending";
       
     },
      },
   
    ];
    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              alert(event.colDef.field)
              if (event.colDef.field == "M1 Number") {            
                    
                this.setState(
                  {
                    // pendingflag: true,
                    // pendingEvent: true,
                  },
                  () => {
                    this.handlePrint()
                  }
                );
              }
              }}
        
          ></AgGridReact>
        </div>
      </div>
    );
  }
  header = () => {
    return (
      <div className=" card p-3 mt-4">
        <div className=" row">
          <div className=" col-3">
            <label htmlFor="Depot_Code">Depot Code</label>
            <input
              value={this.state.Depot_Code}
              id="Depot_Code"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Depot_Name">Depot Name</label>
            <input
              value={this.state.Depot_Name}
              id="Depot_Name"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Depot_Flag">Depot Flag</label>
            <input
              value={this.state.Depot_Flag}
              id="Depot_Flag"
              className=" form-control "
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Active">Active</label>
            <input
              value={this.state.Active}
              id="Active"
              className=" form-control"
              readOnly
            ></input>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">ENQURIY LIST</h4><br></br>
        <div class="card">
         <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">{this.masterTable()}</div>
          </div>
        </div>
      </div>
    );
  }
}
