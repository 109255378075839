import React from "react";
import moment from "moment";
import "../Dealer_To_Dealer_Tax_Invoice/index.css"
import "../../../containers/color.css";
import Urls from "../../../../helpers/Urls";
import Strings from "../../../../helpers/Strings";
import {setDomLayoutAfterPrint,setDomLayoutForPrint} from "../../../../helpers/getDetails"
export class Form_21 extends React.Component {
  constructor(props) {
    console.log(props,"jkjkkj")
    super(props);
  }
  componentDidMount() {
    if (this?.props?.location?.state?.modelid) {
      this.setState(
        {
          EnquiryDetails: this.props.location.state.data,
        },
        () => {
          var tempTitle = document.title;
          document.title = "F21-"+moment().year()+"-"+this.state.EnquiryDetails.Invoice_No;
          window.print();
          document.title = tempTitle;
          localStorage.setItem("FromHistory", true);
          this.props.history.push({
            pathname: Strings.vehical_invoice,
            state: {
              id: this.props.location.state.id,
              modelid: this.props.location.state.modelid,
              Status: this.props.location.state.Status,
              name: this.props.location.state.name,
              M_7id: this.props.location.state.M_7id,
            },
          });
        }
      );
    }
  }
  Invoice = () => {
    return (
      <div>
        <div className="invoice-container-form-22">
          {/* < */}
          <h4
            style={{ margin: "0", padding: "0" }}
            className="heading_text mb-3"
          >
            Form 21
          </h4>
          <p style={{ fontSize: "12px", fontWeight: "600" }}>
            [See Rule 47(a) and (d)]
          </p>
          <p style={{ fontWeight: "600" }}>SALE CERTIFICATE</p>
          <p>
            (To be issued by manufacturer or dealer or registered E-riskshaw or
            E-cart Association (in case of E-rickshaw or E-cart) or officer of
            Defence Department (in case of military auctioned vehicles) for
            presentation along with the application for registration of a motor
            vehicle)
          </p>
          <p style={{ display:"flex", textAlign: "left", width: "100%" }}>
            Certified that
            <div className="border-div" ><span>Joy E-Bike</span></div>
            (Brand Name of The Vehicle)
          </p>
          <p style={{ display:"flex", textAlign: "left", width: "100%" }}>
            has been delivered by us
            to<div  className="border-div" style={{width:"200px"}}><span>{this.props?.location?.state?.data.Name}</span></div>On<div className="border-div" style={{width:"200px"}}><span>{moment(new Date()).format("DD-MM-YYYY")}</span></div>
            (Date).
          </p>
          <br></br>
          <div style={{ width: "100%" }}>
            <ol style={{listStyle:"none"}}>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Name of The Buyer</div>
                  <div className="right-div">{this.props?.location?.state?.data.Name}</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Son/Wife/Daughter of</div>
                  <div className="right-div"></div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Address (Permanent)</div>
                  <div className="right-div">{this.props?.location?.state?.data?.Address1+(this.props?.location?.state?.data?.City?", ":" ")+this.props?.location?.state?.data?.City+(this.props?.location?.state?.data?.State?", ":" ")+this.props?.location?.state?.data?.State}</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">(Temporary)</div>
                  <div className="right-div">N/A</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">
                    The vehicle is held under agreement of
                    hire-purchase/lease/hypothecation with
                  </div>
                  <div className="right-div">{this.props?.location?.state?.data.Financier?this.props?.location?.state?.data.Financier:"N/A"}</div>
                </div>
              </li>
            </ol>
          </div>
          <p style={{ width: "100%", textAlign: "left" }}>
            The details of the vehicle are given below:
          </p>
          <div style={{ width: "100%" }}>
            <ol style={{paddingLeft:"2%"}}>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Class of Vehicle</div>
                  <div className="right-div">{this.props?.location?.state?.data.ClassOfVehicle}</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Ward waizar Innovation & Mobility</div>
                  <div className="right-div">Joy E-Bike</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Chassis No.</div>
                  <div className="right-div">{this.props?.location?.state?.data.Chassis_No}</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">
                    Engine Number or Motor Number in the case of Battery
                    Operated Vehicles
                  </div>
                  <div className="right-div">{this.props?.location?.state?.data.Motor_No}</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">
                    Horse Power or Cubic Capacity
                  </div>
                  <div className="right-div">N/A</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Fuel Used</div>
                  <div className="right-div">N/A</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Number of Cylinders</div>
                  <div className="right-div">N/A</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">
                    Month and Year of Manufacture
                  </div>
                  <div className="right-div">{this.props?.location?.state?.data.mfg_date?moment(this.props?.location?.state?.data.mfg_date,"YYYY-MM-DD").format("DD-MM-YYYY"):""}</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">
                    Seating Capacity (Including Driver)
                  </div>
                  <div className="right-div">Two</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Unladen Weight (KG)</div>
                  <div className="right-div">{this.props?.location?.state?.data.Uweight}</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">
                    Colour or Colours of The Body
                  </div>
                  <div className="right-div">{this.props?.location?.state?.data.Model_Colour}</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Gross Vehicle Weight (KG)</div>
                  <div className="right-div">{this.props?.location?.state?.data.GrossWt}</div>
                </div>
              </li>
              <li>
                <div
                  style={{ display: "flex", justifyContent: "space-between", marginTop:"1%" }}
                >
                  <div className="left-div ">Type of Body</div>
                  <div className="right-div">{this.props?.location?.state?.data.typeofbody}</div>
                </div>
              </li>
            </ol>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                width: "31%",
                borderTop: "1px solid #837c7c",
                borderTopStyle: "dotted",
              }}
            >
              Signature of the Manufacturer or Dealer
            </div>
          </div>
          <br></br>
          <br></br>
        </div>
      </div>
    );
  };
  render() {
    return (<div id="printArea" style={{maxHeight:"100vh", marginTop:"150px",marginLeft:"-15px"}}>{this.Invoice()}</div>
    );
  }
}
