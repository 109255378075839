import React from "react";
import "../Files/files-styles.css";
// import '../Claims/Dashboard/styles.css'
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import Urls from "../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import { ListFilter } from "../../components/ListFilter";
import moment from "moment";

// import Strings from '../../helpers/Strings';
// // import { post } from 'axios';
// import { Alert } from "../../../../helpers/getDetails";
const $ = window.$;
export class Delar_to_Delar_Sales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      ethnicity_map: [],
      Ethnicity: [],
      Input_Code: "",
      Text: "",
      Output_FHIR_Code: "",
      Code_System: "",
      Display: "",
      Definition: "",
      Type: "",
      rowData: [],
      HiPaaS_ID: "",
      loaderforupload: false,
      startDate:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].startDate
          ? props.location.state.data[0].startDate
          : moment().subtract(7, 'd').format('YYYY-MM-DD'),
      endDate:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].endDate
          ? props.location.state.data[0].endDate
          : moment().format('YYYY-MM-DD'),
          dealerid:
          props.location.state &&
          props.location.state.data[0] &&
          props.location.state.data[0].dealerid
            ? props.location.state.data[0].dealerid
            : sessionStorage.getItem("dealerid"),
            showtimerange:
            props.location.state &&
            props.location.state.data[0] &&
            props.location.state.data[0].showtimernagefilter
              && props.location.state.data[0].showtimernagefilter
              ,
    };
  }
  MasterAPICall = (data) => {
    const url = Urls.python_baseurl + "/SP_VehicleSalesDetails";
    var data = JSON.stringify({
      Dealer_ID: `'${this.state.dealerid}'`,
      SqlFor: "'D2DSalesInv'",
      startdate: `'${this.state.startDate}'`,
      enddate: `'${this.state.endDate}'`,
      Dashboard_For: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          const isNullish = Object.values(res[0][0]).every((value) => {
            if (value === null || value === "") {
              return true;
            }
            return false;
          });
          if (isNullish) {
            this.setState({
              rowData: [],
            });
          } else {
            this.setState({
              rowData: res[0],
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.MasterAPICall();
  };

  masterTable() {
    let columnDefs = [
      { headerName: "Dealer Name", field: "Dealer_Name", width: "140px" },
      { headerName: "Dealer Code", field: "DealerCode", width: "120px" },
      {
        headerName: "Chassis No.",
        field: "Chassis No",
        width: "150px",
        cellStyle: sessionStorage.getItem("UserRole") == "1" && {
          color: "var(--main-bg-color)",
          cursor: "pointer",
        },
      },

      { headerName: "Model", field: "Model_Gr_description", width: "140px" },
      { headerName: "Model Name", field: "Model_name", width: "140px" },
      { headerName: "Invoice No.", field: "Invoice No", width: "150px" },
      { headerName: "Invoice Date", field: "Invoice Date", width: "140px" },

      { headerName: "Customer Name", field: "Customer_name", width: "140px" },
      { headerName: "Status", field: "Status", width: "140px" },
      // { headerName: "PDI Job Card No.", field: "", width: "140px",  },
      // { headerName: "PDI Job Card Date", field: "pdi_date", width: "140px", },
      // { headerName: "Controller No", field: "controller_unit_no", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (sessionStorage.getItem("UserRole") == "1") {
                if (event.colDef.field === "Chassis No")
                  this.setState(
                    {
                      Active: event.data.Active,
                      Model_Category: event.data["Model Category"],
                    },
                    () => {
                      this.props.history.push(
                        "/Vehicle_Receipt_dealer_to_dealer",
                        {
                          delarID: event.data.ID,
                          D2Dsales: true,
                          Isconfirm: event.data.Status,
                        }
                      );
                    }
                  );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  clickNavigation = () => {
    this.props.history.push("/Job_Card_Details", {});
  };
  refreshScreen = (startDate, endDate, dealerid) => {
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        dealerid: dealerid,
      },
      () => {
        this.MasterAPICall();
      }
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text" style={{ marginLeft: "0px" }}>
          Dealer To Dealer Sale
        </h4>
        <br />
        <br />
        <ListFilter
            refreshScreen={this.refreshScreen}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dealerid={this.state.dealerid}
            showtimerange={this.state.showtimerange}
          />
        {/* {this.EthnicityHeaderview()} */}
        {/* <div class="form-group col-md-12" style={{padding:'0px'}}>
                    <button type="submit" className="btn Opertion_btn clickable" style={{ fontSize: "12px", float:'right',marginRight:"30px" }} onClick={this.clickNavigation}>
                        Add JobCard
                    </button>
                    </div>  */}
        <div>
          {this.masterTable()}
          {/* {this.errorDialog()} */}
        </div>
      </div>
    );
  }
}
