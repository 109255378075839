import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import * as XLSX from "xlsx";
const $ = window.$;
let qwert=0;
const bcrypt = require("bcryptjs");
export class Reports extends React.Component {
  constructor(props) {
    console.log(props, "hjjhjh");
    super(props);
    this.state = {
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      paginationPageSize: 10,
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        cellRenderer: "agGroupCellRenderer",
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      selectedReport: "",
      ModelCategoryOption: [],
      ModelCodeOption: [],
      DealerOption: [],
      DealerCategoryOption: [],
      StateOption: [],
      RegionOption: [],
      DistrictOption: [],
      TehsilOption: [],
      ReportData: [],
      UserList: [],
      temparray: [],
      newurl: [],
      selectedReportName:
        this.props?.location?.state?.selectedReport?.report_Name,
      ShowTable2: false,
      ShowTable3: false,
      ShowTable4: false,
      selected_model_code: "",
      selected_dealer_ID: sessionStorage.getItem("dealerid")
        ? sessionStorage.getItem("dealerid")
        : "All",
      PO_ID: "0",
      dealerIDForUserList: "All",
      Model_Colour:"All",
      FinalTotalStock:0,
      FinalTotalamount:0
    };
  }
  componentDidMount() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayStr = yesterday.toISOString().split("T")[0];

    const today = new Date();
    const todayStr = today.toISOString().split("T")[0];

    this.getDealer();
    this.getParameter();
    if (
      this.props?.location?.state?.selectedReport?.report_Name == "User Report"
    ) {
      this.getUserList();
    } else {
      this.GetModelCategory();
      this.getDealerType();
      this.getState();
      this.getRegion();
      this.setState(
        {
          ["From Date"]: yesterdayStr,
          ["To Date"]: todayStr,
        },
        () => {
          if(this.state.selectedReportName==="Enquiry Status Report"){
            this.generateReport_Enquiry()
          }else{
            this.generateReport();
          }    
        }
      );
    }
  }
  getUserList = () => {
    const url = Urls.python_baseurl + "/SP_Report_UserRoleWiseDealers";
    var data = JSON.stringify({
      DealerID: `'${this.state.dealerIDForUserList}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            UserList: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  generateReport_Enquiry = () => {
    const url = Urls.python_baseurl + "/SP_Report_EnquiryStatus";
    var data = JSON.stringify({
      
      Dealer_ID: `'${this.state.selected_dealer_ID}'`,
      ModelGroup:
        this.state["Model Group"] && this.state["Model Group"] != 0
          ? `'${this.state["Model Group"]}'`
          : "'All'",
      DealerCode: sessionStorage.getItem("Dealer_Code")
        ? `'${sessionStorage.getItem("Dealer_Code")}'`
        : "'All'",
        Model_Colour:this.state.Model_Colour!=0
        ? `'${this.state.Model_Colour}'`
        : "'All'",
        StartDate:
        this.state["From Date"] && this.state["From Date"] != 0
          ? `'${this.state["From Date"]}'`
          : "''",
      EndDate:
        this.state["To Date"] && this.state["To Date"] != 0
          ? `'${this.state["To Date"]}'`
          : "''",
      DealerCategory:
          this.state["Dealer Category"] && this.state["Dealer Category"] != 0
            ? `'${this.state["Dealer Category"]}'`
            : "'All'",
      
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newReportData = [];
          this.setState({
            ReportData: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  generateReport = () => {
    const url = Urls.python_baseurl + "/Generate_Report";
    var data = JSON.stringify({
      Dealer_ID: `'${this.state.selected_dealer_ID}'`,
      RegionID:
        this.state["Region"] && this.state["Region"] != 0
          ? `'${this.state["Region"]}'`
          : "'All'",
      StateID:
        this.state["State"] && this.state["State"] != 0
          ? `'${this.state["State"]}'`
          : "'All'",
      ModelCode:
        this.state["Model Code"] && this.state["Model Code"] != 0
          ? `'${this.state["Model Code"]}'`
          : "'All'",
      DealerCategory:
        this.state["Dealer Category"] && this.state["Dealer Category"] != 0
          ? `'${this.state["Dealer Category"]}'`
          : "'All'",
      ModelGroup:
        this.state["Model Group"] && this.state["Model Group"] != 0
          ? `'${this.state["Model Group"]}'`
          : "'All'",
      DealerCode: sessionStorage.getItem("Dealer_Code")
        ? `'${sessionStorage.getItem("Dealer_Code")}'`
        : "'All'",
      StartDate:
        this.state["From Date"] && this.state["From Date"] != 0
          ? `'${this.state["From Date"]}'`
          : "''",
      EndDate:
        this.state["To Date"] && this.state["To Date"] != 0
          ? `'${this.state["To Date"]}'`
          : "''",
      Report_type: this.state.selectedReportName,
      SalesType:
        this.state["Sales Type"] && this.state["Sales Type"] != 0
          ? `'${this.state["Sales Type"]}'`
          : "'All'",
      PurchaseType:
        this.state["Purchase Type"] && this.state["Purchase Type"] != 0
          ? `'${this.state["Purchase Type"]}'`
          : "'All'",
      PO_ID: this.state.PO_ID ? `${this.state.PO_ID}` : "0",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          let newReportData = [];
          this.setState({
            ReportData: res,
            FinalTotalStock:  this.state.selectedReportName == "Vehicle Stock As On Report"  || this.state.selectedReportName == "Sales Register Report"   ? res && res.length >0 && res[3][0].Total_Stock :0,
            FinalTotalamount :  this.state.selectedReportName == "Vehicle Stock As On Report" || this.state.selectedReportName == "Sales Register Report"  ? res && res.length >0 && res[3][0].Total_Stock_Amount:0
          });

       
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getDealer = (value = "All") => {
    const url = Urls.python_baseurl + "/SP_Get_Dealer";
    var data = JSON.stringify({
      Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
      RegionID: this.state["Region"] ? this.state["Region"] : "'All'",
      StateID: this.state["State"] ? this.state["State"] : "'All'",
      DistrictID: this.state["District"] ? this.state["District"] : "'All'",
      TalukaID: this.state["Tehsil"] ? this.state["Tehsil"] : "'All'",
      Dealer_Category: value == "0" ? "'All'" : `'${value}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            DealerOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getRegion = () => {
    const url = Urls.python_baseurl + "/SP_Get_Region";
    var data = JSON.stringify({
      Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            RegionOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getParameter = () => {
    const url = Urls.python_baseurl + "/SP_Get_ReportParameters";
    var data = JSON.stringify({
      Menu: `'${this.props?.location?.state?.selectedReport?.Title}'`,
      Report_ID: `${this.props?.location?.state?.selectedReport?.id}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          let newRes = [];
          for (let i = 0; i < res.length; i++) {
            newRes.push(res[i]?.Parameter_Name);
          }
          this.setState({
            ParameterOption: newRes,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getDealerType = () => {
    const url = Urls.python_baseurl + "/SP_Get_DealerType";
    var data = JSON.stringify({
      Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            DealerCategoryOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getState = () => {
    const url = Urls.python_baseurl + "/SP_Get_State";
    var data = JSON.stringify({
      Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
      RegionID:
        this.state["Region"] && this.state["Region"] != 0
          ? this.state["Region"]
          : "'All'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            StateOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getModelCodeOptions = (value) => {
    const url =
      Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
    var data = JSON.stringify({
      ModelGroup: parseInt(value),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            ModelCodeOption: res,
            ["Model Code"]: this.state.selected_model_code,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/ModelGroup_GetModelGroup";
    var data = JSON.stringify({
      id: "null",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          ModelCategoryOption: res,
        });
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelChange = (e) => {
    const { name, value } = e.target;
 
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name == "Model Group") {
          this.setState(
            {
              ["Model Code"]: "",
            },
            () => {
              this.getModelCodeOptions(value);
            }
          );
        } else if (name == "Dealer Category") {
          this.getRegion();
          this.getDealer(value);
        } else if (name == "Region") {
          this.getState();
        } else if (name == "State") {
          if (value == 0) {
            this.setState({
              DistrictOption: [],
              TehsilOption: [],
            });
          } else {
            this.getDistrict();
          }
        } else if (name == "District") {
          if (value == 0) {
            this.setState({
              TehsilOption: [],
            });
          } else {
            this.getTehsil();
          }
        } else if (name == "Tehsil") {
          this.getDealer();
        } else if (name == "Dealer") {
          if (value == 0) {
            this.setState(
              {
                selected_dealer_ID: sessionStorage.getItem("dealerid"),
                dealerIDForUserList: "All",
              },
              () => {
                if (this.state.selectedReportName == "User Report") {
                  this.getUserList();
                }
              }
            );
          } else {
            this.setState(
              {
                selected_dealer_ID: value,
                dealerIDForUserList: value,
              },
              () => {
                if (this.state.selectedReportName == "User Report") {
                  this.getUserList();
                }
              }
            );
          }
        }
      }
    );
  };
  getDistrict = () => {
    const url = Urls.python_baseurl + "/SP_Filldistrict";
    var data = JSON.stringify({
      StateId: this.state["State"] ? this.state["State"] : "",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            DistrictOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  getTehsil = () => {
    const url = Urls.python_baseurl + "/Display_Tehsil_sp_tehsildisplay";
    var data = JSON.stringify({
      state_name: this.state["State"] ? this.state["State"] : "",
      district_name: this.state["District"] ? this.state["District"] : "",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            TehsilOption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  showFilter = () => {
    return (
      <div className=" row card flex-row py-4 mt-2">
        {this.state?.ParameterOption?.includes("RegionID") && (
          <div className=" form-group col-md-3">
            <label>Region</label>
            <select
              name="Region"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              value={this.state["Region"]}
            >
              <option value={0}>All</option>
              {this.state.RegionOption.map((e) => {
                return <option value={e?.ID}>{e?.Region_Name}</option>;
              })}
            </select>
          </div>
        )}
        {this.state?.ParameterOption?.includes("StateID") && (
          <div className=" form-group col-md-3">
            <label>State</label>
            <select
              name="State"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              value={this.state["State"]}
            >
              <option value={0}>All</option>
              {this.state.StateOption.map((e) => {
                return <option value={e?.ID}>{e?.State}</option>;
              })}
            </select>
          </div>
        )}
        {/* <div className=" form-group col-md-3">
          <label>District</label>
          <select
            name="District"
            onChange={(e) => this.handelChange(e)}
            className=" form-control "
          >
            <option value={0}>All</option>
            {this.state.DistrictOption.map((e) => {
              return <option value={e?.ID}>{e?.Name}</option>;
            })}
          </select>
        </div> */}
        {/* <div className=" form-group col-md-3">
          <label>Tehsil</label>
          <select
            name="Tehsil"
            onChange={(e) => this.handelChange(e)}
            className=" form-control "
          >
            <option value={0}>All</option>
            {this.state.TehsilOption.map((e) => {
              return <option value={e?.ID}>{e?.Town_Name}</option>;
            })}
          </select>
        </div> */}
        {this.state?.ParameterOption?.includes("DealerCategory") && (
          <div className=" form-group col-md-3">
            <label>Dealer Category</label>
            <select
              name="Dealer Category"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              value={this.state["Dealer Category"]}
            >
              <option value={0}>All</option>
              {this.state.DealerCategoryOption.map((e) => {
                return (
                  <option value={e?.Dealer_Type_Des}>
                    {e?.Dealer_Type_Des}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {this.state?.ParameterOption?.includes("Dealer_ID") && (
          <div className=" form-group col-md-3">
            <label>Dealer</label>
            <select
              name="Dealer"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              value={this.state["Dealer"]}
            >
              <option value={0}>All</option>
              {this.state.DealerOption.map((e) => {
                return <option value={e?.ID}>{e?.Name}</option>;
              })}
            </select>
          </div>
        )}
        {this.state?.ParameterOption?.includes("ModelGroup") && (
          <div className=" form-group col-md-3">
            <label>Model</label>
            <select
              name="Model Group"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              value={this.state["Model Group"]}
            >
              <option value={0}>All</option>
              {this.state.ModelCategoryOption.map((e) => {
                return <option value={e?.ID}>{e["Model Group"]}</option>;
              })}
            </select>
          </div>
        )}
        {this.state?.ParameterOption?.includes("ModelCode") && (
          <div className=" form-group col-md-3">
            <label>Model Name</label>
            <select
              name="Model Code"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              value={this.state["Model Code"]}
            >
              <option value={0}>All</option>
              {this.state.ModelCodeOption.map((e) => {
                return <option value={e?.ID}>{e["Model name"]}</option>;
              })}
            </select>
          </div>
        )}
        {this.state?.ParameterOption?.includes("Model_Colour") && (
          <div className=" form-group col-md-3">
            <label>Model_Colour</label>
            <select
              name="Model_Colour"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              value={this.state.Model_Colour}
            >
              <option value={0}>All</option>
              {this.state.ModelCodeOption.map((e) => {
                return <option value={e?.Model_Colour}>{e.Model_Colour}</option>;
              })}
            </select>
          </div>
        )}
        {this.state?.ParameterOption?.includes("StartDate") && (
          <div className=" form-group col-md-3">
            <label>From Date</label>
            <input
              name="From Date"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              type="date"
              value={this.state["From Date"]}
              max={this.state["To Date"]}
            ></input>
          </div>
        )}
        {this.state?.ParameterOption?.includes("EndDate") && (
          <div className=" form-group col-md-3">
            <label>To Date</label>
            <input
              type="date"
              name="To Date"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              value={this.state["To Date"]}
              min={this.state["From Date"]}
              max={this.state["To Date"]}
            ></input>
          </div>
        )}
        {this.state?.ParameterOption?.includes("SalesType") && (
          <div className=" form-group col-md-3">
            <label>Sales Type</label>
            <select
              name="Sales Type"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              value={this.state["Sales Type"]}
            >
              <option value={0}>All</option>
              <option value="D2D">D2D</option>
              <option value="DeliveryChallan">Delivery Challan</option>
              <option value="RetailSale">Retail Sale</option>
            </select>
          </div>
        )}
        {this.state?.ParameterOption?.includes("PurchaseType") && (
          <div className=" form-group col-md-3">
            <label>Purchase Type</label>
            <select
              name="Purchase Type"
              onChange={(e) => this.handelChange(e)}
              className=" form-control "
              value={this.state["Purchase Type"]}
            >
              <option value={0}>All</option>
              <option value="DealerToDealerPurchase">
                Dealer To Dealer Purchase
              </option>
              <option value="CompanyPurchase">Company Purchase</option>
            </select>
          </div>
        )}
      </div>
    );
  };
  TableOne() {
    let columnDefs = [
      {
        headerName: "Region",
        field: "Region",
        flex: 1,
      },

      { headerName: "State", field: "State", flex: 1 },
      {
        headerName: "Dealer Category",
        field: "Dealer Category",
        flex: 1,
      },
      { headerName: "Model", field: "Model Group", flex: 1 },
      {
        headerName: "Total Stock",
        field: "TotalStock",
        flex: 1,
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
          
       
       
      },
      { headerName: "Total Amount", field: "Total_Amt", flex: 1 },
    ];
    
    if (this.state.selectedReportName == "Vehicle Order Status Report") {
      // columnDefs = [
      //   {
      //     headerName: "Region",
      //     field: "Region",
      //     flex: 1,
      //   },

      //   { headerName: "State", field: "State", flex: 1 },
      //   {
      //     headerName: "Dealer Category",
      //     field: "Dealer Category",
      //     width: "150px",
      //   },
      //   { headerName: "Model", field: "Model Group", flex: 1 },
      //   {
      //     headerName: "Quantity",
      //     field: "POQty",
      //     flex: 1,
      //     cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      //   },
      //   { headerName: "Amount", field: "Total_Amt", flex: 1 },

      //   // { headerName: "Total PO ", field: "TotalPO",flex:1},
      // ];
      columnDefs = [
        {
          headerName: "Dealer Category",
          field: "Dealer Category",
          flex: 1,
        },

        { headerName: "Dealer Code", field: "DealerCode", flex: 1 },
        {
          headerName: "Dealer Name",
          field: "Dealer_name",
          width: "150px",
        },
        { headerName: "Region", field: "Region", flex: 1 },
        {
          headerName: "State",
          field: "State",
          flex: 1,
        },
        { headerName: "Total PO", field: "TotalPO", flex: 1,cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" }, },
        
      ];
    } else if (
      this.state.selectedReportName == "Purchase Register Report" ||
      this.state.selectedReportName == "PDI Jobcard Register Report"
    ) {
      columnDefs = [
        {
          headerName: "Region",
          field: "Region",
          flex: 1,
        },

        { headerName: "State", field: "State", flex: 1 },
        {
          headerName: "Dealer Category",
          field: "category",
          flex: 1,
        },
        { headerName: "Model", field: "Model_Group", flex: 1 },

        this.state.selectedReportName == "PDI Jobcard Register Report"
          ? {
              headerName: "Quantity",
              field: "Quantity",
              flex: 1,
              cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
            }
          : {
              headerName: "Total Purchase",
              field: "TotalPurchase",
              flex: 1,
              cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
            },
        this.state.selectedReportName == "Purchase Register Report"
          ? { headerName: "Total Amount", field: "Total_Amt", flex: 1 }
          : { hide: true },
        // { headerName: "Amount", field: "Amount", flex: 1 },
      ];
    } else if (this.state.selectedReportName == "Sales Register Report") {
      columnDefs = [
        {
          headerName: "Region",
          field: "Region_Name",
          width: "120px",
        },

        { headerName: "State", field: "State", width: "120px" },
        {
          headerName: "Dealer Category",
          field: "category",
          width: "180px",
        },
        { headerName: "Model", field: "Model_Gr_description", width: "140px" },
        {
          headerName: "Sales Type",
          field: "SalesType",
          width: "180px",
        },
        // {
        //   headerName: "Total Doc Count",
        //   field: "TotalDocCount",
        //   width: "130px",
        //   // cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        // },
        {
          headerName: "Total Sale",
          field: "TotalSale",
          width: "110px",
          cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        },
        { headerName: "Total Amount", field: "Total_Amt", width: "140px" },
      ];
    }else if (this.state.selectedReportName == "Enquiry Status Report"){
       columnDefs = [
        {
          headerName: "Dealer Code",
          field: "DealerCode",
          flex: 1,
        },
        { headerName: "Dealer Name", field: "Dealer_name", flex: 1 },
        { headerName: "Dealer Category", field: "Dealer_Category", flex: 1 },
        {
          headerName: "Total Enquiry",
          field: "Total Enquiry Qantity",
          flex: 1,
          cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        },
      ]
    }
    else if (this.state.selectedReportName == "Damaged Vehicles Report"){
      columnDefs = [
        { headerName: "Region", field: "Region", flex: 1 },
        {
          headerName: "State",
          field: "State",
          flex: 1,
        },
        {
          headerName: "Dealer Category",
          field: "Dealer Category",
          flex: 1,
        },
        {
          headerName: "Model",
          field: "Model_Group",
          flex: 1,
        },
    
        { headerName: "Total Damaged Vehicles", field: "Total Damaged Vehicles", flex: 1,cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" }, },
        
      ];
   }
    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.ReportData[0]}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (
                event.colDef.field == "TotalStock" ||
                event.colDef.field == "Quantity" ||
                event.colDef.field == "POQty" ||
                event.colDef.field == "TotalPurchase" ||
                event.colDef.field == "TotalSale" ||
                event.colDef.headerName == "Sales Type"||
                event.colDef.headerName == "Total PO" ||
                event.colDef.headerName == "Total Enquiry" ||
                event.colDef.headerName == "Total Damaged Vehicles"

              ) {
                this.setState({
                  ReportData: [
                    this.state.ReportData[0],
                    [],
                    this.state.ReportData[2],
                  ],
                });
                if (
                  this.state.selectedReportName == "Vehicle Stock As On Report"
                ) {
                  this.setState(
                    {
                      Region: event.data.Region_Id,
                      State: event.data.State_Id,
                      ["Model Group"]: event.data.model_gr_id,
                      ["Dealer Category"]: event.data["Dealer Category"],
                      ["Model Code"]: "",
                      ReportDataCopy: this.state.ReportData,
                      ShowTable2: true,
                      TableIndex: 0,
                      ShowTable3: false,
                      ShowTable4: false,
                    },
                    () => {
                      this.handelChange({
                        target: {
                          name: "Dealer Category",
                          value: this.state["Dealer Category"],
                        },
                      });
                      this.generateReport();
                    }
                  );
                } else if (
                  this.state.selectedReportName == "Sales Register Report"
                ) {
                  this.setState(
                    {
                      Region: event.data.Region_Id,
                      State: event.data.State_Id,
                      ["Model Group"]: event.data.model_gr_id,
                      ["Dealer Category"]: event.data.category,
                      ["Sales Type"]: event.data.SalesType,
                      ["Model Code"]: "",
                      ReportDataCopy: this.state.ReportData,
                      ShowTable2: true,
                      TableIndex: 0,
                      ShowTable3: false,
                      ShowTable4: false,
                    },
                    () => {
                      this.handelChange({
                        target: {
                          name: "Dealer Category",
                          value: this.state["Dealer Category"],
                        },
                      });
                      this.generateReport();
                    }
                  );
                } else if (
                  this.state.selectedReportName == "Purchase Register Report"
                ) {
                  this.setState(
                    {
                      Region: event.data.Region_Id,
                      State: event.data.State_Id,
                      ["Model Group"]: event.data.model_gr_id,
                      ["Dealer Category"]: event.data.category,
                      ["Model Code"]: "",
                      ReportDataCopy: this.state.ReportData,
                      ShowTable2: true,
                      TableIndex: 0,
                      ShowTable3: false,
                      ShowTable4: false,
                    },
                    () => {
                      this.handelChange({
                        target: {
                          name: "Dealer Category",
                          value: this.state["Dealer Category"],
                        },
                      });
                      this.generateReport();
                    }
                  );
                } else if (
                  this.state.selectedReportName == "Vehicle Order Status Report"
                ) {
                  this.setState(
                    {
                      Region: event.data.Region_Id,
                      State: event.data.State_Id,
                      ["Model Group"]: event.data.model_gr_id,
                      ["Dealer Category"]: event.data["Dealer Category"],
                      ["Model Code"]: "",
                      ReportDataCopy: this.state.ReportData,
                      ShowTable2: true,
                      TableIndex: 0,
                      ShowTable3: false,
                      ShowTable4: false,
                       selected_dealer_ID:event.data.Dealer_ID,
                    },
                    () => {
                      this.handelChange({
                        target: {
                          name: "Dealer Category",
                          value: this.state["Dealer Category"],
                        },
                      });
                      this.generateReport();
                    }
                  );
                } else if (
                  this.state.selectedReportName == "PDI Jobcard Register Report"
                ) {
                  this.setState(
                    {
                      Region: event.data.Region_Id,
                      State: event.data.State_Id,
                      ["Model Group"]: event.data.model_gr_id,
                      ["Dealer Category"]: event.data.category,
                      ["Sales Type"]: event.data.SalesType,
                      ["Model Code"]: "",
                      ReportDataCopy: this.state.ReportData,
                      ShowTable2: true,
                      TableIndex: 0,
                      ShowTable3: false,
                      ShowTable4: false,
                    },
                    () => {
                      this.handelChange({
                        target: {
                          name: "Dealer Category",
                          value: this.state["Dealer Category"],
                        },
                      });
                      this.generateReport();
                    }
                  );
                }else
                if (
                  this.state.selectedReportName == "Enquiry Status Report"
                ) {
                  this.setState(
                    {
                      Region: event.data.Region_Id,
                      State: event.data.State_Id,
                      ["Dealer Category"]: event.data.Dealer_Category,
                      ["Model Code"]: "",
                      ["Model Group"]:this.state["Model Group"],
                      ["Dealer"]: event.data.Dealer_ID,
                      ReportDataCopy: this.state.ReportData,
                      ShowTable2: true,
                      TableIndex: 0,
                      ShowTable3: false,
                      ShowTable4: false,
                      selected_dealer_ID: event.data.Dealer_ID,
                    },
                    () => {
                      this.handelChange({
                        target: {
                          name: "Dealer Category",
                          value: this.state["Dealer Category"],
                        },
                      });
                      this.generateReport_Enquiry();
                    }
                  );
                }
                else if (
                  this.state.selectedReportName == "Damaged Vehicles Report"
                ) {
                  this.setState(
                    {
                      Region: event.data.Region_Id,
                      State: event.data.State_Id,
                      ["Model Group"]: event.data.model_gr_id,
                      ["Dealer Category"]: event.data["Dealer Category"],
                      ["Model Code"]: "",
                      ReportDataCopy: this.state.ReportData,
                      ShowTable2: true,
                      TableIndex: 0,
                      ShowTable3: false,
                      ShowTable4: false,
                       selected_dealer_ID:event.data.Dealer_ID,
                    },
                    () => {
                      this.handelChange({
                        target: {
                          name: "Dealer Category",
                          value: this.state["Dealer Category"],
                        },
                      });
                      this.generateReport();
                    }
                  );
                }
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  TableTwo() {
    let columnDefs = [
      {
        headerName: "Region",
        field: "Region",
        width: "80px",
      },
      { headerName: "State", field: "State", width: "80px" },
      {
        headerName: "Dealer Category",
        field: "Dealer Category",
        width: "150px",
      },
      {
        headerName: "Dealer Name",
        field: "Dealer_name",
        width: "150px",
        cellRenderer: (data) => {
          if (data) {
            return data.data.Dealer_name + " (" + data.data.DealerCode + ")";
          } else {
            return;
          }
        },
        cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      },
      // { headerName: "Dealer Code", field: "Dealer Code", width: "100px" },
      { headerName: "Model", field: "Model Group", width: "120px" },
      // {
      //   headerName: "Model Name",
      //   field: "Model Name",
      //   width: "230px",
      //   cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      // },
      { headerName: "Model Code", field: "model Code", width: "130px" },
      { headerName: "Model Colour", field: "model colour", width: "130px" },
      {
        headerName:
          this.state.selectedReportName !== "Sales Register Report"
            ? "Total Stock"
            : "Quantity",
        field:
          this.state.selectedReportName !== "Sales Register Report"
            ? "TotalStock"
            : "TotalStock",
        width: "100px",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Total Amount", field: "Total_Amt", width: "140px" },
    ];
    if (this.state.selectedReportName == "Vehicle Order Status Report") {
      // columnDefs = [
      //   {
      //     headerName: "Region",
      //     field: "Region",
      //     width: "88px",
      //   },
      //   { headerName: "State", field: "State", width: "85px" },
      //   {
      //     headerName: "Dealer Category",
      //     field: "Dealer Category",
      //     width: "155px",
      //   },
      //   {
      //     headerName: "Dealer Name",
      //     field: "Dealer_name",
      //     width: "150px",
      //     cellRenderer: (data) => {
      //       if (data) {
      //         return data.data.Dealer_name + " (" + data.data.DealerCode + ")";
      //       } else {
      //         return;
      //       }
      //     },
      //     cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      //   },
      //   // { headerName: "Dealer Code", field: "Dealer Code", width: "100px" },
      //   { headerName: "Model", field: "Model Group", width: "120px" },
      //   { headerName: "Model Code", field: "model Code", width: "120px" },
      //   { headerName: "Model Colour", field: "model colour", width: "120px" },
      //   // {
      //   //   headerName: "Model Name",
      //   //   field: "Model Name",
      //   //   width: "230px",
      //   //   cellRenderer: (data) => {
      //   //     if (data) {
      //   //        return data.data["Model Name"]+" ("+(data.data["model colour"])+")";
      //   //     } else {
      //   //        return ;
      //   //     }
      //   //   },
      //   //   cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      //   // },
      //   {
      //     headerName: "Quantity",
      //     field: "POQty",
      //     width: "100px",
      //     cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      //   },

      //   { headerName: "Amount", field: "Total_Amt", width: "110px" },
      //   // { headerName: "PO Count", field: "PO Count", width: "130px" },
      //   // { headerName: "Total PO", field: "TotalPO", width: "140px" },
      //   // { headerName: "Total Payment Received", field: "TotalPaymentReceived", width: "170px" },
      // ];
      columnDefs = [
        
        { headerName: "PO No.", field: "PO NO", width: "170px",cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" }, },
        { headerName: "PO Date", field: "PO Date", width: "170px" },
        // { headerName: "Total Amount", field: "Total_Amt", width: "140px"},
        // { headerName: "Total Payment Received", field: "TotalPaymentReceived", width: "180px" },
        // { headerName: "Payment Received Percentage (%)", field: "Payment Received Percentage", width: "230px" },
        
        // { headerName: "Dealer Name", field: "Dealer_Name", width: "150px" },
        { headerName: "Po Total", field: "TotalPOAmt", width: "140px" },
        { headerName: "Approved Total", field: "Total_Amt", width: "140px" },
        { headerName: "Total Quantity", field: "Total_Quantity", width: "140px" },
        { headerName: "Approved Quantity", field: "Approve_Quantity", width: "140px" },
        { headerName: "Total Payment ", field: "TotalPaymentReceived", width: "140px" },
        { headerName: "Total Payment % ", field: "Payment Received Percentage", width: "140px" },

        { headerName: "Status", field: "Status", width: "230px"},
      ];
    } else if (
      this.state.selectedReportName == "Purchase Register Report" ||
      this.state.selectedReportName == "PDI Jobcard Register Report"
    ) {
      columnDefs = [
        {
          headerName: "Region",
          field: "Region",
          width: "100px",
        },
        { headerName: "State", field: "State", width: "100px" },
        {
          headerName: "Dealer Category",
          field: "category",
          width: "170px",
        },

        {
          headerName: "Dealer Name",
          field: "Dealer_name",
          width: "165px",
          cellRenderer: (data) => {
            if (data) {
              return (
                data.data.Dealer_name +
                " (" +
                data.data.Dealer_Vehicle_Code +
                ")"
              );
            } else {
              return;
            }
          },
          cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        },
        // { headerName: "Dealer Code", field: "Dealer Code", width: "100px" },
        { headerName: "Model", field: "Model_Group", width: "140px" },
        this.state.selectedReportName == "Purchase Register Report"
          ? { headerName: "Model Code", field: "model_Code", width: "130px" }
          : { headerName: "Model Code", field: "model Code", width: "130px" },
        { headerName: "Model Colour", field: "colour", width: "140px" },
        // {
        //   headerName: "Model Name",
        //   field: "Model_Name",
        //   width: "240px",
        //   cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        //   cellRenderer: (data) => {
        //     if (data) {
        //        return data.data.Model_Name+" ("+(data.data.colour)+")";
        //     } else {
        //        return ;
        //     }
        //   }
        // },
        this.state.selectedReportName == "PDI Jobcard Register Report"
          ? {
              headerName: "Quantity",
              field: "Quantity",
              width: "140px",
              cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
            }
          : {
              headerName: "Total Purchase",
              field: "TotalPurchase",
              width: "140px",
              cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
            },
        // {
        //   headerName:
        //     this.state.selectedReportName !== "Sales Register Report"
        //       ? "Total Purchase"
        //       : "Quantity",
        //   field:
        //     this.state.selectedReportName !== "Sales Register Report"
        //       ? "TotalPurchase"
        //       : "Quantity",
        //   width: "140px",
        //   cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        // },
        // { headerName: "Amount", field: "Amount", width: "90px" },
      ];
    }
    if (this.state.selectedReportName == "Sales Register Report") {
      columnDefs = [
        {
          headerName: "Region",
          field: "Region_Name",
          width: "80px",
        },
        { headerName: "State", field: "State", width: "80px" },
        {
          headerName: "Dealer Category",
          field: "category",
          width: "155px",
        },
        // { headerName: "Dealer Code", field: "Dealer Code", width: "100px",},
        {
          headerName: "Dealer Name",
          field: "Dealer_Name",
          width: "150px",
          cellRenderer: (data) => {
            if (data) {
              return data.data.Dealer_Name + " (" + data.data.DealerCode + ")";
            } else {
              return;
            }
          },
          cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        },

        { headerName: "Model", field: "Model_Gr_description", width: "120px" },
        // {
        //   headerName: "Model Name",
        //   field: "model_name",
        //   width: "230px",
        //   cellRenderer: (data) => {
        //     if (data) {
        //        return data.data.model_name+" ("+(data.data.colour)+")";
        //     } else {
        //        return ;
        //     }
        //   },
        //   cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        // },

        { headerName: "Model Code", field: "model_code", width: "120px" },
        { headerName: "Model Colour", field: "colour", width: "120px" },
        {
          headerName: "Sales Type",
          field: "SalesType",
          width: "140px",
        },
        // {
        //   headerName:
        //     this.state.selectedReportName !== "Sales Register Report"
        //       ? "Quantity"
        //       : "Total Doc Count",
        //   field:
        //     this.state.selectedReportName !== "Sales Register Report"
        //       ? "Quantity"
        //       : "TotalDocCount",
        //   width: "14px",

        // },
        // { headerName: "Sales Type", field: "SalesType", width: "120px", },
        {
          headerName: "Total Sale",
          field: "TotalSale",
          width: "120px",
          cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        },
        { headerName: "Total Amount", field: "Total_Amt", width: "140px" },
      ];
    }
    else if (this.state.selectedReportName == "Enquiry Status Report"){
      columnDefs = [
        {
          headerName: "Region",
          field: "Region",
          width: "80px",
        },
        { headerName: "State", field: "State", width: "80px" },
        {
          headerName: "Dealer Category",
          field: "Dealer_Category",
          width: "150px",
        },
        {
          headerName: "Dealer Name",
          field: "Dealer_name",
          width: "150px",
          cellRenderer: (data) => {
            if (data) {
              return data.data.Dealer_name + " (" + data.data.DealerCode + ")";
            } else {
              return;
            }
          },
          cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        },
       {
         headerName: "Model",
         field: "Model Group",
         flex: 1,
       },
       { headerName: "Model Colour", field: "model colour", flex: 1 },
       {
         headerName: "Total Enquiry",
         field: "Total Enquiry Qantity",
         flex: 1,
         cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
       },
     ]
   }
   else if (this.state.selectedReportName == "Damaged Vehicles Report"){
    columnDefs = [
      {
        headerName: "Region",
        field: "Region",
        width: "80px",
      },
      { headerName: "State", field: "State", width: "80px" },
      {
        headerName: "Dealer Category",
        field: "Dealer Category",
        width: "155px",
      },
      {
        headerName: "Dealer Name",
        field: "Dealer_name",
        width: "150px",
        cellRenderer: (data) => {
          if (data) {
            return data.data.Dealer_name + " (" + data.data.Dealer_Vehicle_Code + ")";
          } else {
            return;
          }
        },
        cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      },

      { headerName: "Model", field: "Model_Group", width: "120px" },
      { headerName: "Model Code", field: "model_Code", width: "120px" },
      { headerName: "Model Colour", field: "colour", width: "120px" },
      { headerName: "Total Damaged Vehicles", field: "Total Damaged Vehicles", width: "200px",
      cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" }, },
 
    ];
 }
    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.ReportData[1]}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (
                event.colDef.field == "Total Stock" ||
                event.colDef.field == "TotalStock" ||
                event.colDef.field == "POQty" ||
                event.colDef.field == "Quantity" ||
                event.colDef.field == "TotalPurchase" ||
                event.colDef.field == "TotalSale"||
                event.colDef.headerName == "PO No." ||
                event.colDef.headerName == "Total Enquiry Qantity" ||
                event.colDef.headerName == "Damaged Vehicles Report"
              )
                this.setState({
                  ReportData: [
                    this.state.ReportData[0],
                    this.state.ReportData[1],
                    [],
                  ],
                });
              if (
                this.state.selectedReportName == "Vehicle Stock As On Report"
              ) {
                this.setState(
                  {
                    ["Dealer Category"]: event.data["Dealer Category"],
                    ["Dealer"]: event.data.Dealer_Id
                      ? event.data.Dealer_Id
                      : event.data.dealer_id
                      ? event.data.dealer_id
                      : event.data["Dealer ID"],
                    ["Model Code"]: event.data.model_id,
                    ["Sales Type"]: event.data.SalesType,
                    ReportDataCopy: this.state.ReportData,
                    ShowTable3: true,
                    TableIndex: 2,
                    selected_model_code: event.data.model_id
                      ? event.data.model_id
                      : "",
                    selected_dealer_ID: event.data.Dealer_Id,
                  },
                  () => {
                    this.handelChange({
                      target: {
                        name: "Model Group",
                        value: this.state["Model Group"],
                      },
                    });
                    this.handelChange({
                      target: {
                        name: "Dealer Category",
                        value: this.state["Dealer Category"],
                      },
                    });
                    this.generateReport();
                  }
                );
              } else if (
                this.state.selectedReportName == "Sales Register Report"
              ) {
                this.setState(
                  {
                    ["Dealer Category"]: event.data["Dealer Category"],
                    ["Dealer"]: event.data.Dealer_Id
                      ? event.data.Dealer_Id
                      : event.data.dealer_id
                      ? event.data.dealer_id
                      : event.data["Dealer ID"],
                    ["Model Code"]: event.data.model_id,
                    ["Sales Type"]: event.data.SalesType,
                    ReportDataCopy: this.state.ReportData,
                    ShowTable3: true,
                    TableIndex: 2,
                    selected_model_code: event.data.model_id
                      ? event.data.model_id
                      : "",
                    selected_dealer_ID: event.data.dealer_id,
                  },
                  () => {
                    this.handelChange({
                      target: {
                        name: "Model Group",
                        value: this.state["Model Group"],
                      },
                    });
                    this.handelChange({
                      target: {
                        name: "Dealer Category",
                        value: this.state["Dealer Category"],
                      },
                    });
                    this.generateReport();
                  }
                );
              } else if (
                this.state.selectedReportName == "Purchase Register Report"
              ) {
                this.setState(
                  {
                    ["Dealer Category"]: event.data["Dealer Category"],
                    ["Dealer"]: event.data.Dealer_Id
                      ? event.data.Dealer_Id
                      : event.data.dealer_id
                      ? event.data.dealer_id
                      : event.data["Dealer ID"],
                    ["Model Code"]: event.data.model_id,
                    ["Sales Type"]: event.data.SalesType,
                    ["Purchase Type"]: event.data.PurchaseType,
                    ReportDataCopy: this.state.ReportData,
                    ShowTable3: true,
                    TableIndex: 2,
                    selected_model_code: event.data.model_id
                      ? event.data.model_id
                      : "",
                    selected_dealer_ID: event.data.Dealer_Id,
                  },
                  () => {
                    this.handelChange({
                      target: {
                        name: "Model Group",
                        value: this.state["Model Group"],
                      },
                    });
                    this.handelChange({
                      target: {
                        name: "Dealer Category",
                        value: this.state["Dealer Category"],
                      },
                    });
                    this.generateReport();
                  }
                );
              } else if (
                this.state.selectedReportName == "Vehicle Order Status Report"
              ) {
                // this.setState(
                //   {
                //     ["Dealer Category"]: event.data["Dealer Category"],
                //     ["Dealer"]: event.data.Dealer_Id
                //       ? event.data.Dealer_Id
                //       : event.data.dealer_id
                //       ? event.data.dealer_id
                //       : event.data["Dealer ID"],
                //     ["Model Code"]: event.data.model_id,
                //     ["Sales Type"]: event.data.SalesType,
                //     ReportDataCopy: this.state.ReportData,
                //     ShowTable3: true,
                //     TableIndex: 2,
                //     selected_model_code: event.data.model_id
                //       ? event.data.model_id
                //       : "",
                //     selected_dealer_ID: event.data.Dealer_Id,
                //   },
                //   () => {
                //     this.handelChange({
                //       target: {
                //         name: "Model Group",
                //         value: this.state["Model Group"],
                //       },
                //     });
                //     this.handelChange({
                //       target: {
                //         name: "Dealer Category",
                //         value: this.state["Dealer Category"],
                //       },
                //     });
                //     this.generateReport();
                //   }
                // );
                this.setState(
                  {
                    
                    PO_ID:event.data.PO,
                    ReportDataCopy: this.state.ReportData,
                    ShowTable3: true,
                    TableIndex: 2,
                
                  },
                  () => {
                    this.handelChange({
                      target: {
                        name: "Model Group",
                        value: this.state["Model Group"],
                      },
                    });
                    this.handelChange({
                      target: {
                        name: "Dealer Category",
                        value: this.state["Dealer Category"],
                      },
                    });
                    this.generateReport();
                  }
                );
              } else if (
                this.state.selectedReportName == "PDI Jobcard Register Report"
              ) {
                this.setState(
                  {
                    ["Dealer Category"]: event.data["Dealer Category"],
                    ["Dealer"]: event.data.Dealer_Id
                      ? event.data.Dealer_Id
                      : event.data.dealer_id
                      ? event.data.dealer_id
                      : event.data["Dealer ID"],
                    ["Model Code"]: event.data.model_id,
                    ["Sales Type"]: event.data.SalesType,
                    ReportDataCopy: this.state.ReportData,
                    ShowTable3: true,
                    TableIndex: 2,
                    selected_model_code: event.data.model_id
                      ? event.data.model_id
                      : "",
                    selected_dealer_ID: event.data.Dealer_Id,
                  },
                  () => {
                    this.handelChange({
                      target: {
                        name: "Model Group",
                        value: this.state["Model Group"],
                      },
                    });
                    this.handelChange({
                      target: {
                        name: "Dealer Category",
                        value: this.state["Dealer Category"],
                      },
                    });
                    this.generateReport();
                  }
                );
              }else if (
                this.state.selectedReportName == "Enquiry Status Report"
              ) {
                this.setState(
                  {
                    ["Dealer Category"]: event.data["Dealer Category"],
                    ["Dealer"]: event.data.Dealer_Id
                      ? event.data.Dealer_Id
                      : event.data.dealer_id
                      ? event.data.dealer_id
                      : event.data["Dealer ID"],
                      ["Model Group"]: event.data.ModelGroup_ID,
                    ["Model Code"]: event.data.model_id,
                    ["Sales Type"]: event.data.SalesType,
                    ReportDataCopy: this.state.ReportData,
                    Model_Colour:event.data["model colour"],
                    ShowTable3: true,
                    TableIndex: 2,
                    selected_model_code: event.data.model_id
                      ? event.data.model_id
                      : "",
                   selected_dealer_ID: event.data.Dealer_ID,
                  },
                  () => {
                    this.handelChange({
                      target: {
                        name: "Model Group",
                        value: this.state["Model Group"],
                      },
                    });
                    this.handelChange({
                      target: {
                        name: "Dealer Category",
                        value: this.state["Dealer Category"],
                      },
                    });
                    this.generateReport_Enquiry();
                  }
                );
              }
              else if (
                this.state.selectedReportName == "Damaged Vehicles Report"
              ) {
                console.log("rrrrrrrrr",event.data)
                this.setState(
                  {
                    ["Dealer Category"]: event.data["Dealer Category"],
                    ["Model Code"]: event.data.model_id,
                    ["Sales Type"]: event.data.SalesType,
                    ["Purchase Type"]: event.data.PurchaseType,
                    ReportDataCopy: this.state.ReportData,
                    ShowTable3: true,
                    TableIndex: 2,
                    selected_model_code: event.data.model_id
                      ? event.data.model_id
                      : "",
                    selected_dealer_ID: event.data.Dealer_ID,
                  },
                  () => {
                    this.handelChange({
                      target: {
                        name: "Model Group",
                        value: this.state["Model Group"],
                      },
                    });
                    this.handelChange({
                      target: {
                        name: "Dealer Category",
                        value: this.state["Dealer Category"],
                      },
                    });
                    this.generateReport();
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  TableThree() {

   let columnDefs = [
    ];
    if (this.state.selectedReportName == "Sales Register Report") {
      if (this.state["Sales Type"] == "DeliveryChallan") {
        columnDefs = [
          { headerName: "Region", field: "Region_Name", width: "90px" },
          { headerName: "State", field: "State", width: "90px" },
          {
            headerName: "Dealer Category",
            field: "category",
            width: "140px",
          },
          // { headerName: "Dealer Code", field: "Dealer Code", width: "140px" },
          {
            headerName: "Dealer Name",
            field: "Dealer_Name",
            width: "150px",
            cellRenderer: (data) => {
              if (data) {
                return (
                  data.data.Dealer_Name + " (" + data.data.DealerCode + ")"
                );
              } else {
                return;
              }
            },
            cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
          },
          {
            headerName: "Model",
            field: "Model_Gr_description",
            width: "140px",
          },
          { headerName: "Model Code", field: "model_code", width: "140px" },
          // {
          //   headerName: "Model Name",
          //   field: "Model_name",
          //   width: "230px",
          //   cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
          //   cellRenderer: (data) => {
          //     if (data) {
          //        return data.data.Model_name+" ("+(data.data.colour)+")";
          //     } else {
          //        return ;
          //     }
          //   },
          // },
          { headerName: "Model Colour", field: "colour", width: "140px" },
          {
            headerName: "Chassis No.",
            field: "Chassis_no",
            width: "140px",
          },
          {
            headerName: "Vehicle Number",
            field: "Vehicle Number",
            width: "140px",
          },
          {
            headerName: "Battery No.",
            field: "Battery Number",
            width: "140px",
          },
          {
            headerName: "Charger No.",
            field: "Charger Number",
            width: "140px",
          },
          { headerName: "Motor No.", field: "Motor Number", width: "140px" },
          { headerName: "IOT No.", field: "IOT Device ID", width: "140px" },
          {
            headerName: "Display No.",
            field: "Display Number",
            width: "140px",
          },
          {
            headerName: "Controller Unit No.",
            field: "Controller_Unit_No",
            width: "160px",
          },
          {
            headerName: "Delivery Challan.",
            field: "Doc No",
            width: "160px",
          },
          {
            headerName: "Delivery Challan Date",
            field: "Doc Date",
            width: "160px",
          },
          {
            headerName: "Amount Before Tax",
            field: "Amount Before Tax",
            width: "140px",
          },
          { headerName: "CGST Amount", field: "CGST Amount", width: "140px" },
          { headerName: "IGST Amount", field: "IGST Amount", width: "140px" },
          { headerName: "SGST Amount", field: "SGST Amount", width: "140px" },
          { headerName: "Total Amount", field: "Total Amount", width: "140px" },
        ];
      } else {
        columnDefs = [
          { headerName: "Region", field: "Region_Name", width: "90px" },
          { headerName: "State", field: "State", width: "90px" },
          {
            headerName: "Dealer Category",
            field: "category",
            width: "140px",
            cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
          },
          {
            headerName: "Customer Name",
            field: "Customer_name",
            width: "170px",
            cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
          },
          {
            headerName: "Dealer Name",
            field: "Dealer Name",
            width: "150px",
            cellRenderer: (data) => {
              if (data) {
                return (
                  data.data.Dealer_Name + " (" + data.data.DealerCode + ")"
                );
              } else {
                return;
              }
            },
            cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
          },
          {
            headerName: "Model",
            field: "Model_Gr_description",
            width: "140px",
          },
          { headerName: "Model Code", field: "model_code", width: "140px" },
          // {
          //   headerName: "Model Name",
          //   field: "Model Name",
          //   width: "230px",
          //   cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
          //   cellRenderer: (data) => {
          //     if (data) {
          //        return data.data.Model_name+" ("+(data.data.colour)+")";
          //     } else {
          //        return ;
          //     }
          //   },
          // },
          { headerName: "Model Colour", field: "colour", width: "140px" },

          { headerName: "Sales Type", field: "SalesType", width: "140px" },
          {
            headerName: "Chassis No.",
            field: "Chassis_no",
            width: "155px",
          },
          {
            headerName: "Vehicle No.",
            field: "Vehicle Number",
            width: "155px",
          },
          {
            headerName: "Battery No.",
            field: "Battery Number",
            width: "155px",
          },
          {
            headerName: "Charger No.",
            field: "Charger Number",
            width: "155px",
          },
          { headerName: "Motor No.", field: "Motor Number", width: "155px" },
          { headerName: "IOT No.", field: "IOT Device ID", width: "155px" },
          {
            headerName: "Display No.",
            field: "Display Number",
            width: "155px",
          },
          {
            headerName: "Controller Unit No.",
            field: "Controller_Unit_No",
            width: "155px",
          },
          {
            headerName: "Invoice No",
            field: "Doc No",
            width: "140px",
          },
          {
            headerName: "Invoice Date",
            field: "Doc Date",
            width: "140px",
          },
          {
            headerName: "Amount Before Tax",
            field: "Amount Before Tax",
            width: "155px",
          },
          { headerName: "CGST Amount", field: "CGST Amount", width: "140px" },
          { headerName: "IGST Amount", field: "IGST Amount", width: "140px" },
          { headerName: "SGST Amount", field: "SGST Amount", width: "140px" },
          { headerName: "Total Amount", field: "Total Amount", width: "140px" },
          { headerName: "Status", field: "Status", width: "140px" },
        ];
      }
    } else if (this.state.selectedReportName == "Purchase Register Report") {
      columnDefs = [
        { headerName: "Region", field: "Region", width: "90px" },
        { headerName: "State", field: "State", width: "90px" },

        {
          headerName: "Dealer Category",
          field: "category",
          width: "160px",
        },

        // { headerName: "Dealer Code", field: "Dealer Code", width: "140px" },
        {
          headerName: "Dealer Name",
          field: "Dealer_Name",
          width: "150px",
          cellRenderer: (data) => {
            if (data) {
              return (
                data.data.Dealer_Name +
                " (" +
                data.data.Dealer_Vehicle_Code +
                ")"
              );
            } else {
              return;
            }
          },
          cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        },
        { headerName: "Model", field: "Model_Group", width: "140px" },
        { headerName: "Model Code", field: "Model_Code", width: "140px" },
        // {
        //   headerName: "Model Name",
        //   field: "Model_Name",
        //   width: "230px",
        //   cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        //   cellRenderer: (data) => {
        //     if (data) {
        //        return data.data.Model_Name+" ("+(data.data.Colour)+")";
        //     } else {
        //        return ;
        //     }
        //   },
        // },
        { headerName: "Model Colour", field: "Colour", width: "140px" },
        {
          headerName: "Invoice Number",
          field: "inv_no",
          width: "140px",
        },
        { headerName: "Invoice Date", field: "inv_date", width: "140px" },
        // { headerName: "PO Number", field: "PO_No", width: "140px" },
        // { headerName: "PO Date", field: "PO_Date", width: "140px" },
        { headerName: "Quantity", field: "Qty", width: "140px" },
        {
          headerName: "Chassis No.",
          field: "Chassis_no",
          width: "155px",
        },
        {
          headerName: "Vehicle No.",
          field: "Vehicle_No",
          width: "155px",
        },
        {
          headerName: "Battery No.",
          field: "BatteryNo",
          width: "155px",
        },
        {
          headerName: "Charger No.",
          field: "ChargerNo",
          width: "155px",
        },
        { headerName: "Motor No.", field: "Motor_No", width: "155px" },
        { headerName: "IOT No.", field: "IOT_Device_ID", width: "155px" },
        {
          headerName: "Display No.",
          field: "DispayNo",
          width: "155px",
        },
        {
          headerName: "Controller Unit No.",
          field: "Controller_Unit_No",
          width: "155px",
        },
        // {
        //   headerName: "Sales Order Number",
        //   field: "Sales_Order_No",
        //   width: "170px",
        // },
        // { headerName: "Model Colour", field: "Model Colour", width: "140px" },

        {
          headerName: "Amount Before Tax",
          field: "Amount_Before_Tax",
          width: "170px",
        },
        { headerName: "CGST Amount", field: "CGST_AMt", width: "140px" },
        { headerName: "CGST Percentage", field: "CGST_PEr", width: "140px" },
        { headerName: "IGST Amount", field: "IGST_Amt", width: "140px" },
        { headerName: "IGST Percentage", field: "IGST_Per", width: "140px" },
        { headerName: "SGST Amount", field: "SGST_AMt", width: "140px" },
        { headerName: "SGST Percentage", field: "SGST_Per", width: "140px" },
        { headerName: "Total Amount", field: "TotalAmt", width: "140px" },
        //  { headerName: "Amount", field: "Amount", width: "120px" },
      ];
    } else if (this.state.selectedReportName == "Vehicle Order Status Report") {
      // columnDefs = [
      //   { headerName: "Region", field: "Region", width: "80px" },
      //   { headerName: "State", field: "State", width: "80px" },
      //   {
      //     headerName: " Dealer Category",
      //     field: "Dealer Category",
      //     width: "170px",
      //   },
      //   // { headerName: "Dealer Code", field: "Dealer Code", width: "140px" },
      //   {
      //     headerName: "Dealer Name",
      //     field: "Dealer_Name",
      //     width: "150px",
      //     cellRenderer: (data) => {
      //       if (data) {
      //         return data.data.Dealer_Name + " (" + data.data.DealerCode + ")";
      //       } else {
      //         return;
      //       }
      //     },
      //     cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      //   },
      //   { headerName: "Model", field: "Model Group", width: "135px" },
      //   { headerName: "Model Code", field: "Model Code", width: "140px" },
      //   { headerName: "Model Colour", field: "Model Colour", width: "140px" },
      //   // {
      //   //   headerName: "Model Name",
      //   //   field: "Model Name",
      //   //   width: "230px",
      //   //   cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      //   //   cellRenderer: (data) => {
      //   //     if (data) {
      //   //        return data.data["Model Name"]+" ("+(data.data["Model Colour"])+")";
      //   //     } else {
      //   //        return ;
      //   //     }
      //   //   },
      //   // },
      //   {
      //     headerName: "PO NO",
      //     field: "PO NO",
      //     width: "145px",
      //     cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      //   },

      //   { headerName: "Total PO Amount", field: "TotalPOAmt", width: "140px" },
      //   {
      //     headerName: "Payment Received Percentage",
      //     field: "Payment Received Percentage",
      //     width: "200px",
      //   },
      //   {
      //     headerName: "Total Payment Received",
      //     field: "TotalPaymentReceived",
      //     width: "190px",
      //   },

      //   { headerName: "Quantity", field: "Quantity", width: "140px" },
      //   { headerName: "PO Date", field: "PO Date", width: "140px" },
      //   {
      //     headerName: "Joye bike SO No",
      //     field: "Joye bike SO No",
      //     width: "140px",
      //   },
      //   {
      //     headerName: "Joye bike SO Date",
      //     field: "Joye bike SO Date",
      //     width: "140px",
      //   },
      //   // { headerName: "PDI Done", field: "pdidONE", width: "90px" },
      //   // { headerName: "Model Colour", field: "Model Colour", width: "140px" },
      //   { headerName: "Amount", field: "Amount", width: "120px" },
      // ];
      columnDefs = [
        {
          headerName: "Region",
          field: "Region",
          width: "88px",
        },
        { headerName: "State", field: "State", width: "85px" },
        {
          headerName: "Dealer Category",
          field: "Dealer Category",
          width: "155px",
        },
        {
          headerName: "Dealer Name",
          field: "Dealer_Name",
          width: "150px",
          cellRenderer: (data) => {
            if (data) {
              return data.data.Dealer_Name + " (" + data.data.DealerCode + ")";
            } else {
              return;
            }
          },
          cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        },
        // { headerName: "PO ID", field: "PO", width: "120px", },
        { headerName: "PO No.", field: "PO NO", width: "160px",cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" }, },
        { headerName: "PO Date", field: "PO Date", width: "120px" },
        { headerName: "Model", field: "Model Group", width: "120px" },
        { headerName: "Model Code", field: "Model Code", width: "120px" },
        { headerName: "Model Colour", field: "Model Colour", width: "120px" },
        {
          headerName: "Quantity",
          field: "Quantity",
          width: "100px",
        },
        {
          headerName: "Approved Quantity",
          field: "Approve_Quantity",
          width: "160px",
        },

        { headerName: "Amount", field: "Amount", width: "110px" },
        // { headerName: "Total Approved Amount", field: "TotalApprovedAmt", width: "180px" },
      ];
    } else if (this.state.selectedReportName == "PDI Jobcard Register Report") {
      columnDefs = [
        { headerName: "Region", field: "Region", width: "90px" },
        { headerName: "State", field: "State", width: "90px" },
        {
          headerName: "Dealer Category",
          field: "category",
          width: "160px",
        },
        {
          headerName: "Dealer Name",
          field: "Dealer_name",
          width: "150px",
          cellRenderer: (data) => {
            if (data) {
              return (
                data.data.Dealer_name +
                " (" +
                data.data.Dealer_Vehicle_Code +
                ")"
              );
            } else {
              return;
            }
          },
          cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        },
        { headerName: "Model", field: "Model_Group", width: "140px" },
        { headerName: "Model Code", field: "Model Code", width: "140px" },
        // {
        //   headerName: "Model Name",
        //   field: "Model_Name",
        //   width: "140px",
        //   width: "230px",
        //   cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        //   cellRenderer: (data) => {
        //     if (data) {
        //        return data.data.Model_Name+" ("+(data.data.colour)+")";
        //     } else {
        //        return ;
        //     }
        //   },
        // },
        { headerName: "Model Colour", field: "colour", width: "140px" },
        {
          headerName: "Chassis No.",
          field: "Chassis Number",
          width: "140px",
        },
        // {
        //   headerName: "Vehicle Number",
        //   field: "Vehicle Number",
        //   width: "140px",
        // },
        {
          headerName: "Battery No.",
          field: "Battery Number",
          width: "140px",
        },
        {
          headerName: "Charger No.",
          field: "Charger Number",
          width: "140px",
        },

        {
          headerName: "Controller Unit No.",
          field: "Controller Number",
          width: "155px",
        },
        {
          headerName: "Motor No.",
          field: "Motor Number",
          width: "140px",
        },
        {
          headerName: "Display No.",
          field: "Display Number",
          width: "140px",
        },
        {
          headerName: "IOT Device No.",
          field: "IOT Device Number",
          width: "140px",
        },

        // {
        //   headerName: "Model Colour",
        //   field: "Model Colour",
        //   width: "120px",
        // },
        {
          headerName: "Job Card No.",
          field: "JobCard Number",
          width: "140px",
        },
        { headerName: "Job Card Date", field: "Job Date", width: "140px" },
        {
          headerName: "Replaced Battery No.",
          field: "Actual Battery Number",
          width: "155px",
        },
        {
          headerName: "Replaced Charger No.",
          field: "Actual Charger Number",
          width: "160px",
        },


        {
          headerName: "Replaced Controller Unit No.",
          field: "Actual Controller Unit Number",
          width: "200px",
        },
        {
          headerName: "Replaced Motor No.",
          field: "Actual Motor Number",
          width: "170px",
        },
        {
          headerName: "Replaced Display No.",
          field: "Actual Display Number",
          width: "170px",
        },
        {
          headerName: "Replaced IOT Device No.",
          field: "Actual IOT Device Number",
          width: "200px",
        },

        // { headerName: "Amount", field: "Amount", width: "120px" },
      ];
    }else if (this.state.selectedReportName == "Enquiry Status Report"){
      columnDefs = [
        {
          headerName: "Dealer Name",
          field: "Dealer_Name",
          width: "150px",
          cellRenderer: (data) => {
            if (data) {
              return data.data.Dealer_Name + " (" + data.data.DealerCode + ")";
            } else {
              return;
            }
          },
          cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        },
        { headerName: "Dealer Code", field: "DealerCode", width: "120px" },
        
        {
          headerName: "Enquiry No.",
          field: "Inq_no",
          width: "155px",
        },
        
        { headerName: "Enquiry Date", field: "Inq_date", width: "140px" },
        { headerName: "Customer Name", field: "name", width: "140px" },
        (sessionStorage.getItem("UserRole") == "1")||(sessionStorage.getItem("UserRole") == "6")
        ?{ headerName: "Mobile No.", field: "mobile", width: "130px" }:{hide:true},
        { headerName: "Enquiry Source", field: "Inq_source", width: "130px" },
        { headerName: "Customer Type", field: "Cust_Type", width: "130px" },
        { headerName: "Model", field: "Model Group", width: "140px" },
        
        { headerName: "Model Colour", field: "model colour", width: "140px" },
        { headerName: "Booking No.", field: "Booking_No", width: "140px" },
        { headerName: "Booking Date", field: "Booking_Date", width: "140px" },
        { headerName: "Money Receipt No.", field: "MoneyReceipt_No", width: "160px" },
        { headerName: "Money Receipt Date", field: "MoneyReceipt_Date", width: "160px" },
        { headerName: "Quantity", field: "Qty", width: "140px" },
        { headerName: "Balance Quantity", field: "Bal_Qty", width: "140px" },
        { headerName: "Enquiry Status", field: "Enquiry_Status", width: "140px" },
        { headerName: "Allocation Status", field: "Allocation Status", width: "140px" },

      ];
    }
    else if(this.state.selectedReportName=="Vehicle Stock As On Report" ){
      columnDefs = [
        { headerName: "Region", field: "Region", width: "90px" },
        { headerName: "State", field: "State", width: "90px" },
        {
          headerName: "Dealer Category",
          field: "Dealer Category",
          width: "155px",
        },
        {
          headerName: "Dealer Name",
          field: "Dealer_Name",
          width: "150px",
          cellRenderer: (data) => {
            if (data) {
              return data.data.Dealer_Name + " (" + data.data.DealerCode + ")";
            } else {
              return;
            }
          },
          cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        },
        { headerName: "Model", field: "Model Group", width: "140px" },
        { headerName: "Model Code", field: "Model Code", width: "140px" },
        { headerName: "Model Colour", field: "Model Colour", width: "140px" },
        { headerName: "Chassis No.", field: "Chassis Number", width: "140px" },
        { headerName: "Battery No.", field: "Battery Number", width: "140px" },
        { headerName: "Charger No.", field: "Charger Number", width: "140px" },
        { headerName: "Motor No.", field: "Motor Number", width: "140px" },
        { headerName: "IOT No.", field: "IOT Device ID", width: "100px" },
        { headerName: "Display No.", field: "Display Number", width: "140px" },
        {
          headerName: "Controller Unit No.",
          field: "Controller_unit_no",
          width: "155px",
        },
        { headerName: "Invoice No.", field: "Invoice No", width: "140px" },
        { headerName: "Invoice Date", field: "Invoice Date", width: "140px" },
  
        { headerName: "PDI Done", field: "PDI Done", width: "100px" },
        { headerName: "Total Amount", field: "Amount", width: "120px" },
      ];
    }
    else if (this.state.selectedReportName == "Damaged Vehicles Report") {
 
      columnDefs = [
        {
          headerName: "Region",
          field: "Region",
          width: "88px",
        },
        { headerName: "State", field: "State", width: "85px" },
        {
          headerName: "Dealer Category",
          field: "Dealer Category",
          width: "155px",
        },
        {
          headerName: "Dealer Name",
          field: "Dealer_name",
          width: "150px",
          cellRenderer: (data) => {
            if (data) {
              return data.data.Dealer_name + " (" + data.data.Dealer_Vehicle_Code + ")";
            } else {
              return;
            }
          },
          cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
        },
        { headerName: "Receipt No", field: "Vehicle Receipt Number", width: "160px"},
        { headerName: "Receipt Date", field: "ReceiptDate", width: "120px" },
        { headerName: "Model", field: "Model_Group", width: "120px" },
        { headerName: "Model Code", field: "Model_Code", width: "120px" },
        { headerName: "Model Colour", field: "Colour", width: "120px" },
        {
          headerName: "Chassis No.",
          field: "Chassis_no",
          width: "140px",
        },
        {
          headerName: "Vehicle No.",
          field: "Vehicle_No",
          width: "140px",
        },
        {
          headerName: "Battery No.",
          field: "BatteryNo",
          width: "140px",
        },
        {
          headerName: "Charger No.",
          field: "ChargerNo",
          width: "140px",
        },
        {
          headerName: "Controller No.",
          field: "Controller_Unit_No",
          width: "140px",
        },
        {
          headerName: "Motor No.",
          field: "Motor_No",
          width: "140px",
        },
        {
          headerName: "Display No.",
          field: "DispayNo",
          width: "140px",
        },
        {
          headerName: "IOT Device No.",
          field: "IOT_Device_ID",
          width: "140px",
        },
        {
          headerName: "Replaced Battery No.",
          field: "Actual_Battery_No",
          width: "155px",
        },
        {
          headerName: "Replaced Charger No.",
          field: "Actual_Charger_No",
          width: "155px",
        },
        {
          headerName: "Replaced Controller No.",
          field: "Actual_Controller_Unit_No",
          width: "140px",
        },
        {
          headerName: "Replaced Motor No.",
          field: "Actual_Motor_No",
          width: "140px",
        },
        {
          headerName: "Replaced Display No.",
          field: "Actual_Dispay_No",
          width: "140px",
        },
        {
          headerName: "Replaced IOT Device No.",
          field: "Actual_IOT_Device_ID",
          width: "140px",
        },
        {
          headerName: "Damaged Proof",
          field: "FileName",
          width: "155px"
          ,cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" }, },
        

      ];
    }

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.ReportData[2]}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (event.colDef.headerName == "PO No.") {
                this.setState(
                  {
                    // Region: event.data["dealer region ID"],
                    // State: event.data["dealer state ID"],
                    // ["Model Group"]: event.data["Model Group ID"],
                    // ["Dealer Category"]: event.data["Dealer Category"],
                    // ["Dealer"]: event.data["Dealer ID"],
                    // ["Model Code"]: event.data["Model Code"],
                    // ["Sales Type"]: event.data.SalesType,
                    // ReportDataCopy: this.state.ReportData,
                    ShowTable4: true,
                    TableIndex: 2,
                    PO_ID: event.data.PO,
                    selected_dealer_ID:event.data.Dealer_ID
                    // selected_model_code:event.data["Model Code"],
                  },
                  () => {
                    this.generateReport();
                  }
                );
              }else if (event.colDef.headerName == "Damaged Proof") {
                console.log(event.data.download_url, "hjjhhj");
                fetch(event.data.download_url).then((res) => {
                  console.log("🚀 ~ fetch ~ res:", res);
                  if (res.status === 200) {
                    this.state.temparray.push(res.url);
                    let data = [{ uri: res.url }];
                    // console.log("ngfgg",res.url);
                    this.setState(
                      {
                        newurl: data,
                        downloadLink: event.data.download_url,
                        //newurl:this.state.temparray
                      },
                      () => {
                        const link = document.createElement("a");
                        link.href = this.state.downloadLink;
                        link.setAttribute("download", ``);
                        document.body.appendChild(link);

                        link.click();
                        document.body.removeChild(link);
                        $("#ExcelPopUP").modal("show");
                      }
                    );
                  } else {
                  }
                });
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  TableFour() {
    let columnDefs = [
      {
        headerName: "PO No.",
        field: "PO Number",
        width: "155px",
      },

      {
        headerName: "Company Account",
        field: "Company Account",
        width: "155px",
      },
      {
        headerName: "Company Bank IFSC",
        field: "Company Bank IFSC",
        width: "155px",
      },
      {
        headerName: "Amount",
        field: "Amount",
        width: "140px",
      },
      { headerName: "Account No.", field: "Amount Number", width: "155px" },
      {
        headerName: "Bank Name & Address",
        field: "Bank Name Address",
        width: "170px",
        cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      },
      {
        headerName: "Mode Of Payment",
        field: "Mode Of Payment",
        width: "140px",
      },
      {
        headerName: "Payment Reference No.",
        field: "Payment Reference Number",
        width: "140px",
      },
      { headerName: "Payment Date", field: "Payment_Date", width: "140px" },
      {
        headerName: "File Name",
        field: "FileName",
        width: "180px",
        cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      },
      {
        headerName: "Payment Proof",
        field: "download_url",
        width: "160px",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.ReportData[3]}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (event.colDef.headerName == "Payment Proof") {
                console.log(event.data.download_url, "hjjhhj");
                fetch(event.data.download_url).then((res) => {
                  console.log("🚀 ~ fetch ~ res:", res);
                  if (res.status === 200) {
                    this.state.temparray.push(res.url);
                    let data = [{ uri: res.url }];
                    // console.log("ngfgg",res.url);
                    this.setState(
                      {
                        newurl: data,
                        downloadLink: event.data.download_url,
                        //newurl:this.state.temparray
                      },
                      () => {
                        const link = document.createElement("a");
                        link.href = this.state.downloadLink;
                        link.setAttribute("download", ``);
                        document.body.appendChild(link);

                        link.click();
                        document.body.removeChild(link);
                        $("#ExcelPopUP").modal("show");
                      }
                    );
                    // setFile([{ uri: res.url }]);
                  } else {
                    //  setError(true)
                  }
                });
                // this.setState(
                //   {
                //     downloadLink: event.data.download_url,
                //   },
                //   () => {
                //     $("#ExcelPopUP").modal("show");
                //   }
                // );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  handelExport = () => {
    let TableName = []
    let exportMapper = []
    if (this.state.selectedReportName == "Vehicle Stock As On Report") {
      TableName = [
        "Dealer Category Wise",
        "Dealer Wise",
        "Model Wise",
        // "Payment Details",
        // "Model Code Wise",
        // "Chassis No. Wise",
      ];
      exportMapper = [
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "Dealer Category" },
          { label: "Model", key: "Model Group" },
          { label: "Total Stock", key: "TotalStock" },
          { label: "Total Amount", key: "Total_Amt" },
        ],
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "Dealer Category" },
          { label: "Dealer Code", key: "DealerCode", },
          { label: "Dealer Name", key: "Dealer_name" },
          { label: "Town", key: "Town Name", },
          { label: "Model", key: "Model Group" },
          { label: "Model Code", key: "model Code" },
          { label: "Model Colour", key: "model colour" },
          { label: "Total Stock", key: "TotalStock" },
          { label: "Total Amount", key: "Total_Amt" },
        ],
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "Dealer Category" },
          { label: "Dealer Code", key: "DealerCode", },
          { label: "Dealer Name", key: "Dealer_Name" },
          { label: "Town", key: "Town Name", },
          { label: "Model", key: "Model Group" },
          { label: "model Code", key: "Model Code" },
          { label: "Model Colour", key: "Model Colour" },
          // { label: "Receipt Date", key: "ReceiptDate" },
          { label: "Chassis No.", key: "Chassis Number" },
          { label: "Battery No.", key: "Battery Number" },
          { label: "Charger No.", key: "Charger Number" },
          { label: "Motor Number", key: "Motor Number" },
          { label: "IOT No.", key: "IOT Device ID" },
          { label: "Display No.", key: "Display Number" },
          { label: "Controller Unit No.", key: "Controller_unit_no" },
          { label: "Invoice No.", key: "Invoice No" },
          { label: "Invoice Date", key: "Invoice Date" },
          { label: "PDI Done", key: "PDI Done" },
          { label: "Total Amount", key: "Amount" },
        ]
      ];

    }else if (this.state.selectedReportName == "Sales Register Report") {
      if (this.state["Sales Type"] == "DeliveryChallan") {
        TableName = [
          "Dealer Category Wise",
          "Dealer Wise",
          "Model Wise",
          // "Payment Details",
          // "Model Code Wise",
          // "Chassis No. Wise",
        ];
        exportMapper = [
          [
            { label: "Region", key: "Region_Name" },
            { label: "State", key: "State" },
            { label: "Dealer Category", key: "category" },
            { label: "Model", key: "Model_Gr_description" },
            { label: "Sales Type", key: "SalesType" },
            { label: "Total Sale", key: "TotalSale" },
            { label: "Total Amount", key: "Total_Amt" },
          ],
          [
            { label: "Region", key: "Region_Name" },
            { label: "State", key: "State" },
            { label: "Dealer Category", key: "category", },
            { label: "Dealer Name", key: "Dealer_Name", },
            { label: "Dealer Code", key: "DealerCode", },
            { label: "Town", key: "Town Name", },
            { label: "Model", key: "Model_Gr_description" },
            { label: "Model Code", key: "model_code" },
            { label: "Model Colour", key: "colour" },
            { label: "Sales Type", key: "SalesType", },
            { label: "Total Sale", key: "TotalSale" },
            { label: "Total Amount", key: "Total_Amt" },
          ],
          [
            { label: "Region", key: "Region_Name" },
            { label: "State", key: "State" },
            { label: "Dealer Category", key: "category" },
            { label: "Dealer Name", key: "Dealer_Name" },
            { label: "Dealer Code", key: "DealerCode", },
            { label: "Town", key: "Town Name", },
            { label: "Model", key: "Model_Gr_description" },
            { label: "Model Code", key: "model_code" },
            { label: "Model Colour", key: "colour" },
            { label: "Chassis No.", key: "Chassis_no" },
            { label: "Vehicle Number", key: "Vehicle Number" },
            { label: "Battery No.", key: "Battery Number", },
            { label: "Charger No.", key: "Charger Number", },
            { label: "Motor No.", key: "Motor Number" },
            { label: "IOT No.", key: "IOT Device ID" },
            { label: "Display No.", key: "Display Number", },
            { label: "Controller Unit No.", key: "Controller_Unit_No", },
            { label: "Delivery Challan.", key: "Doc No" },
            { label: "Delivery Challan Date", key: "Doc Date" },
            { label: "Amount Before Tax", key: "Amount Before Tax" },
            { label: "CGST Amount", key: "CGST Amount" },
            { label: "IGST Amount", key: "IGST Amount" },
            { label: "SGST Amount", key: "SGST Amount" },
            { label: "Total Amount", key: "Total Amount" },
            { label: "Employee Name", key: "Employee Name" },
            { label: "Enquiry No", key: "Enquiry No" },
            { label: "Enquiry Date", key: "Enquiry Date" },
          ]
        ];
      } else {
        TableName = [
          "Dealer Category Wise",
          "Dealer Wise",
          "Model Wise",
          // "Payment Details",
          // "Model Code Wise",
          // "Chassis No. Wise",
        ];
        exportMapper = [
          [
            { label: "Region", key: "Region_Name" },
            { label: "State", key: "State" },
            { label: "Dealer Category", key: "category" },
            { label: "Model", key: "Model_Gr_description" },
            { label: "Sales Type", key: "SalesType" },
            { label: "Total Sale", key: "TotalSale" },
            { label: "Total Amount", key: "Total_Amt" },
          ],
          [
            { label: "Region", key: "Region_Name" },
            { label: "State", key: "State" },
            { label: "Dealer Category", key: "category", },
            { label: "Dealer Code", key: "DealerCode", },
            { label: "Dealer Name", key: "Dealer_Name", },
            { label: "Town", key: "Town Name", },
            { label: "Model", key: "Model_Gr_description" },
            { label: "Model Code", key: "model_code" },
            { label: "Model Colour", key: "colour" },
            { label: "Sales Type", key: "SalesType", },
            { label: "Total Sale", key: "TotalSale" },
            { label: "Total Amount", key: "Total_Amt" },
          ],
          [
            { label: "Region", key: "Region_Name" },
            { label: "State", key: "State" },
            { label: "Dealer Category", key: "category" },
            { label: "Customer Name", key: "Customer_name" },
            { label: "Dealer Code", key: "DealerCode", },
            { label: "Dealer Name", key: "Dealer_Name" },
            { label: "Town", key: "Town Name", },
            { label: "Model", key: "Model_Gr_description" },
            { label: "Model Code", key: "model_code" },
            { label: "Model Colour", key: "colour" },
            { label: "Sales Type", key: "SalesType" },
            { label: "Chassis No.", key: "Chassis_no" },
            { label: "Vehicle No.", key: "Vehicle Number" },
            { label: "Battery No.", key: "Battery Number" },
            { label: "Charger No.", key: "Charger Number" },
            { label: "Motor No.", key: "Motor Number" },
            { label: "IOT No.", key: "IOT Device ID" },
            { label: "Display No.", key: "Display Number" },
            { label: "Controller Unit No.", key: "Controller_Unit_No" },
            { label: "Invoice No", key: "Doc No" },
            { label: "Invoice Date", key: "Doc Date" },
            { label: "Amount Before Tax", key: "Amount Before Tax", },
            { label: "CGST Amount", key: "CGST Amount" },
            { label: "IGST Amount", key: "IGST Amount" },
            { label: "SGST Amount", key: "SGST Amount" },
            { label: "Total Amount", key: "Total Amount" },
            { label: "Status", key: "Status" },
            { label: "Employee Name", key: "Employee Name" },
            { label: "Enquiry No", key: "Enquiry No" },
            { label: "Enquiry Date", key: "Enquiry Date" },
          ]
        ];
      }
    }else if (this.state.selectedReportName == "Enquiry Status Report"){
      TableName = [
        "Dealer Category Wise",
        "Dealer Wise",
        "Model  Wise",
        // "Payment Details",
        // "Model Code Wise",
        // "Chassis No. Wise",
      ];
      exportMapper = [
        [
          { label: "State", key: "State" },
          { label: "Dealer Code", key: "DealerCode" },
          { label: "Dealer Name", key: "Dealer_name" },
          { label: "Town", key: "Town Name" },
          { label: "Dealer Category", key: "Dealer_Category" },
          { label: "Total Enquiry", key: "Total Enquiry Qantity" },
        ],
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "Dealer_Category" },
          { label: "Dealer Code", key: "DealerCode" },
          { label: "Dealer Name", key: "Dealer_name" },
          { label: "Town", key: "Town Name" },
          { label: "Model", key: "Model Group" },
          { label: "Model Colour", key: "model colour" },
          { label: "Total Enquiry", key: "Total Enquiry Qantity" },
        ],
        (sessionStorage.getItem("UserRole") == "1")||(sessionStorage.getItem("UserRole") == "6")?
        [
          { label: "State", key: "State" },
          { label: "Dealer Code", key: "DealerCode", },
          {label: "Dealer Name", key: "Dealer_Name"},
          { label: "Town", key: "Town Name" },
          {label: "Enquiry No.",key: "Inq_no",},
          { label: "Enquiry Date", key: "Inq_date" },
          { label: "Customer Name", key: "name" },
          { label: "Mobile No.", key: "mobile" },
          { label: "Enquiry Source", key: "Inq_source" },
          { label: "Cutomer Type", key: "Cust_Type" },
          { label: "Employee Name", key: "Employee Name" },
          { label: "Model", key: "Model Group" },
          { label: "Model Colour", key: "model colour" },
          { label: "Booking No.", key: "Booking_No" },
          { label: "Booking Date", key: "Booking_Date" },
          { label: "Money Receipt No.", key: "MoneyReceipt_No" },
          { label: "Money Receipt Date", key: "MoneyReceipt_Date" },
          { label: "Quantity", key: "Qty"},
          { label: "Balance Quantity", key: "Bal_Qty"},
          { label: "Enquiry Status", key: "Enquiry_Status"},
          { label: "Enquiry Status", key: "Enquiry_Status"},
          { label: "Reason", key: "Reason"}
        ]:
        [
          { label: "State", key: "State" },
          { label: "Dealer Code", key: "DealerCode", },
          {label: "Dealer Name", key: "Dealer_Name"},
          { label: "Town", key: "Town Name" },
          {label: "Enquiry No.",key: "Inq_no",},
          { label: "Enquiry Date", key: "Inq_date" },
          { label: "Customer Name", key: "name" },
          { label: "Enquiry Source", key: "Inq_source" },
          { label: "Cutomer Type", key: "Cust_Type" },
          { label: "Employee Name", key: "Employee Name" },
          { label: "Model", key: "Model Group" },
          { label: "Model Colour", key: "model colour" },
          { label: "Booking No.", key: "Booking_No" },
          { label: "Booking Date", key: "Booking_Date" },
          { label: "Money Receipt No.", key: "MoneyReceipt_No" },
          { label: "Money Receipt Date", key: "MoneyReceipt_Date" },
          { label: "Quantity", key: "Qty"},
          { label: "Balance Quantity", key: "Bal_Qty"},
          { label: "Enquiry Status", key: "Enquiry_Status"},
          { label: "Allocation Status", key: "Allocation Status"}
        ]
      ];
    }
    else if (this.state.selectedReportName == "Purchase Register Report"){
      TableName = [
        "Dealer Category Wise",
        "Dealer Wise",
        "Model  Wise",
        // "Payment Details",
        // "Model Code Wise",
        // "Chassis No. Wise",
      ];
      exportMapper = [
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "category" },
          { label: "Model", key: "Model_Group" },
          { label: "Total Purchase", key: "TotalPurchase" },
          { label: "Total Amount", key: "Total_Amt" },
        ],
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "category" },
          { label: "Dealer Name", key: "Dealer_name" },
          { label: "Model", key: "Model_Group" },
          { label: "Model Code", key: "model_Code", },
          { label: "Model Colour", key: "colour" },
          { label: "Total Purchase", key: "TotalPurchase" },
        ],
        [
          { label: "Region", key: "Region"},
          { label: "State", key: "State"}, 
          {label: "Dealer Category",key: "category" },
          {label: "Dealer Name",key: "Dealer_Name"},
          { label: "Model", key: "Model_Group" },
          { label: "Model Code", key: "Model_Code"} ,
          { label: "Model Colour", key: "Colour"},
          {label: "Invoice Number",key: "inv_no"},
          { label: "Invoice Date", key: "inv_date"}, 
          // { label: "PO Date", key: "PO_Date"},
          { label: "Quantity", key: "Qty"},
          { label: "Chassis No.",key: "Chassis_no"},
          {label: "Vehicle No.",key: "Vehicle_No"},
          {label: "Battery No.",key: "BatteryNo"},
          {label: "Charger No.",key: "ChargerNo" },
          { label: "Motor No.", key: "Motor_No"}, 
          { label: "IOT No.", key: "IOT_Device_ID"},
          { label: "Display No.",key: "DispayNo"},
          {label: "Controller Unit No.",key: "Controller_Unit_No"},
          {label: "Amount Before Tax",key: "Amount_Before_Tax"},
          { label: "CGST Amount", key: "CGST_AMt"}, 
          { label: "CGST Percentage", key: "CGST_PEr"}, 
          { label: "IGST Amount", key: "IGST_Amt"}, 
          { label: "IGST Percentage", key: "IGST_Per"},
          { label: "SGST Amount", key: "SGST_AMt"},
          { label: "SGST Percentage", key: "SGST_Per"},
          { label: "Total Amount", key: "TotalAmt"},
        ]
      ];
    }
    else if (this.state.selectedReportName == "Vehicle Order Status Report"){
      TableName = [
        "Dealer Category Wise",
        "PO Wise",
        "Model  Wise",
         "Payment Details",
        // "Model Code Wise",
        // "Chassis No. Wise",
      ];
      exportMapper = [
        [
          { label: "Dealer Category", key: "Dealer Category" },
          { label: "Dealer Code", key: "DealerCode" },
          { label: "Dealer Name", key: "Dealer_name" },
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Total PO", key: "TotalPO" },
        ],
        [
          { label: "PO NO.", key: "PO NO" },
          { label: "PO Date", key: "PO Date" },
          { label: "PO Total", key: "TotalPOAmt" },
          { label: "Approved Total", key: "Total_Amt" },
          { label: "Total Quantity", key: "Total_Quantity" },
          { label: "Approved Quantity", key: "Approve_Quantity", },
          { label: "Total Payment", key: "TotalPaymentReceived" },
          { label: "Total Payment %", key: "Payment Received Percentage" },
          { label: "Status", key: "Status" },
        ],
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State", },
          { label: "Dealer Category", key: "Dealer Category" },
          { label: "Dealer Name", key: "Dealer_Name" },
          { label: "PO No.", key: "PO NO" },
          { label: "PO Date", key: "PO Date" },
          { label: "Model", key: "Model Group" },
          { label: "Model Code", key: "Model Code" },
          { label: "Model Colour", key: "Model Colour" },
          { label: "Quantity", key: "Quantity" },
          { label: "Approved Quantity", key: "Approve_Quantity" },
          { label: "Amount", key: "Amount", },
        ],
        [
          { label: "PO NO.", key: "PO Number" },
          { label: "Company Account", key: "Company Account" },
          { label: "Company Bank IFSC", key: "Company Bank IFSC" },
          { label: "Amount", key: "Amount" },
          { label: "Account Number", key: "Amount Number" },
          { label: "Bank Name & Address", key: "Bank Name Address", },
          { label: "Mode Of Payment", key: "Mode Of Payment" },
          { label: "Payment Reference Number", key: "Payment Reference Number" },
          { label: "Payment Date", key: "Payment_Date" },
          { label: "File Name", key: "FileName" },
          { label: "Payment Proof", key: "download_url" },
        ]
      ];
    }else if (this.state.selectedReportName == "Damaged Vehicles Report"){
      TableName = [
        "Dealer Category Wise",
        "Dealer Wise",
        "Model Wise",
        //  "Payment Details",
        // "Model Code Wise",
        // "Chassis No. Wise",
      ];
      exportMapper = [
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "Dealer Category" },
          { label: "Model", key: "Model_Group" },
          { label: "Total Damaged Vehicles", key: "Total Damaged Vehicles" },
        ],
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "Dealer Category" },
          { label: "Dealer Name", key: "Dealer_name" },
          { label: "Model", key: "Model_Group" },
          { label: "Model Code", key: "model_Code" },
          { label: "Model Colour", key: "colour" },
          { label: "Total Damaged Vehicles", key: "Total Damaged Vehicles" },
        ],
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "Dealer Category" },
          { label: "Dealer Name", key: "Dealer_name" },
          { label: "Receipt No", key: "Vehicle Receipt Number" },
          { label: "ReceiptDate", key: "ReceiptDate" },
          { label: "Model", key: "Model_Group" },
          { label: "Model Code", key: "Model_Code" },
          { label: "Model Colour", key: "Colour" },
          { label: "Chassis No.", key: "Chassis_no" },
          { label: "Vehicle No.", key: "Vehicle_No" },
          { label: "Battery No.", key: "BatteryNo" },
          { label: "Charger No.", key: "ChargerNo" },
          { label: "Controller No.", key: "Controller_Unit_No" },
          { label: "Motor No.", key: "Motor_No" },
          { label: "Display No.", key: "DispayNo" },
          { label: "IOT Device No.", key: "IOT_Device_ID" },
          { label: "Replaced Battery No.", key: "Actual_Battery_No" },
          { label: "Replaced Charger No.", key: "Actual_Charger_No", },
          { label: "Replaced Controller No.", key: "Actual_Controller_Unit_No" },
          { label: "Replaced Motor No.", key: "Actual_Motor_No" },
          { label: "Replaced Display No.", key: "Actual_Dispay_No" },
          { label: "Replaced IOT Device No.", key: "Actual_IOT_Device_ID" },
          
          { label: "Damaged Proof", key: "FileName" },
        ]
      ];
    }else if (this.state.selectedReportName == "PDI Jobcard Register Report"){
      TableName = [
        "Dealer Category Wise",
        "Dealer Wise",
        "Model Wise",
        // "Payment Details",
        // "Model Code Wise",
        // "Chassis No. Wise",
      ];
      exportMapper = [
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "category" },
          { label: "Model", key: "Model_Group" },
          { label: "Quantity", key: "Quantity" },
        ],
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "category" },
          { label: "Dealer Code", key: "Dealer_Vehicle_Code" },
          { label: "Dealer Name", key: "Dealer_name" },
          { label: "Town", key: "Town Name" },
          { label: "Model", key: "Model_Group" },
          { label: "Model Code", key: "model Code", },
          { label: "Model Colour", key: "colour" },
          { label: "Quantity", key: "Quantity" },
        ],
        [
          { label: "Region", key: "Region" },
          { label: "State", key: "State" },
          { label: "Dealer Category", key: "category" },
          { label: "Dealer Code", key: "Dealer_Vehicle_Code" },
          { label: "Dealer Name", key: "Dealer_name" },
          { label: "Town", key: "Town Name" },
          { label: "Model", key: "Model_Group" },
          { label: "Model Code", key: "Model Code" },
          { label: "Model Colour", key: "colour" },
          { label: "Chassis No.", key: "Chassis Number" },
          { label: "Battery No.", key: "Battery Number" },
          { label: "Charger No.", key: "Charger Number" },

          { label: "Controller Unit No.", key: "Controller Number" },
          { label: "Motor No.", key: "Motor Number" },
          { label: "Display No.", key: "Display Number" },
          { label: "IOT Device No.", key: "IOT Device Number" },


          { label: "JobCard No.", key: "JobCard Number" },
          { label: "Job Date", key: "Job Date" },
          { label: "Replaced Battery No.", key: "Actual Battery Number" },
          { label: "Replaced Charger No.", key: "Actual Charger Number" },

          { label: "Replaced Controller Unit No.", key: "Actual Controller Unit Number" },
          { label: "Replaced Motor No.", key: "Actual Motor Number" },
          { label: "Replaced Display No.", key: "Actual Display Number" },
          { label: "Replaced IOT Device No.", key: "Actual IOT Device Number" },
         
        ]
      ];
    }
    
    const XLSX = require('xlsx'); // Import or require XLSX library


    if (TableName.length !== exportMapper.length) {
      console.error("Number of sheets in TableName does not match the length of exportMapper");
      return;
    }

    const workbook = XLSX.utils.book_new();

    this.state.ReportData.forEach((dataArray, index) => {
      const currentExportMapper = exportMapper[index];

      if (!currentExportMapper) {
        console.error(`exportMapper for sheet ${TableName[index]} is undefined`);
        return;
      }

      const columnHeaders = currentExportMapper.map(item => item.label);
      const dataWithHeaders = [columnHeaders, ...dataArray.map(row =>
        currentExportMapper.map(mapping => row[mapping.key])
      )];

      const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders);

      XLSX.utils.book_append_sheet(workbook, ws, TableName[index]);
    });
    XLSX.writeFile(workbook, this.state.selectedReportName + ".xlsx");
    const headerStyle = {
      fill: { patternType: "solid", fgColor: { rgb: "FFFF00" } },
    };

    // const workbook = XLSX.utils.book_new();
    // this.state.ReportData.map((e, index) => {
    //   const ws1 = XLSX.utils.json_to_sheet(e);
    //   XLSX.utils.book_append_sheet(workbook, ws1, TableName[index]);
    // });
    // XLSX.writeFile(workbook, this.state.selectedReportName + " Report.xlsx");
  };
  handelUserListExport = () => {
    const TableName = ["User List"];
    const workbook = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(this.state.UserList);
    XLSX.utils.book_append_sheet(workbook, ws1, TableName[0]);
    XLSX.writeFile(workbook, this.state.selectedReportName + " Report.xlsx");
  };
  handelClear = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayStr = yesterday.toISOString().split("T")[0];

    const today = new Date();
    const todayStr = today.toISOString().split("T")[0];
    this.setState(
      {
        ["From Date"]: yesterdayStr,
        ["To Date"]: todayStr,
        ["Purchase Type"]: "",
        ["Sales Type"]: "",
        ["Model Code"]: "",
        ["Model Group"]: "",
        ["Dealer"]: "",
        ["Dealer Category"]: "",
        ["State"]: "",
        ["Region"]: "",
        Model_Colour:"All",
        selected_dealer_ID: sessionStorage.getItem("dealerid"),
        ShowTable2: false,
        ShowTable3: false,
        ShowTable4: false,
        ModelCodeOption: [],
        selected_model_code: "",
        PO_ID: 0,
      },
      () => {
        if(this.state.selectedReportName==="Enquiry Status Report"){
          this.generateReport_Enquiry()
        }else{
          this.generateReport();
        }
      }
    );
  };
  ExcelPOPUP = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(33, 37, 41, 0.8)",
        }}
        class="modal"
        id="ExcelPopUP"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                onClick={() => {
                  this.setState({
                    newurl:[]
                  })
                  $("#ExcelPopUP").modal("hide")}}
              >
                ×
              </button>
            </div>
            <br></br>
            <div class="container"></div>
            <div className="container_file">
              <DocViewer
                documents={this.state.newurl}
                pluginRenderers={DocViewerRenderers}
              />
              {/* <img
                className="mt-3"
                src={this.state.downloadLink}
                alt=""
                width="100%"
                height="500px"
              /> */}
            </div>
          </div>
        </div>
      </div>
    );
  };
  userReportTable() {
    let columnDefs = [
      {
        headerName: "Login Name",
        field: "LoginName",
        width: "155px",
      },

      {
        headerName: "Dealer vehicle Code",
        field: "Dealer_vehicle_Code",
        width: "155px",
      },
      {
        headerName: "Dealer Name",
        field: "Dealer_Name",
        width: "140px",
      },
      { headerName: "ASM", field: "ASM", width: "155px" },
      {
        headerName: "RSM",
        field: "RSM",
        width: "170px",
        cellStyle: { whiteSpace: "normal", wordWrap: "break-word" },
      },
      {
        headerName: "ZSM",
        field: "ZSM",
        width: "140px",
      },
      {
        headerName: "National Head",
        field: "National_Head",
        width: "140px",
      },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.UserList}
            enableCellTextSelection={true}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <h4 className="heading_text">{this.state.selectedReportName}</h4>
        {this.state?.ParameterOption?.length > 0 && (
          <div className="container-fluid mt-3  p-3 pl-4">
            <div className=" row justify-content-end ">
              <button
                onClick={() => {
                  localStorage.setItem("FromHistory", true);
                  this.props.history.push(
                    this.props?.location?.state?.selectedReport?.url,
                    {
                      data: this.props?.location?.state?.selectedReport,
                    }
                  );
                }}
                style={{ marginTop: "-20px" }}
                className="btn Opertion_btn clickable mr-3"
              >
                Back
              </button>
              <button
                onClick={() => this.handelClear()}
                style={{ marginTop: "-20px" }}
                className="btn Opertion_btn clickable mr-3"
              >
                Clear
              </button>
              <button
                onClick={() => {
                  this.setState(
                    {
                      ShowTable2: false,
                      ShowTable3: false,
                      ShowTable4: false,
                    },
                    () => {
                      if(this.state.selectedReportName==="Enquiry Status Report"){
                        this.generateReport_Enquiry()
                      }else{
                        this.generateReport();
                      }
                    }
                  );
                }}
                style={{ marginTop: "-20px" }}
                className="btn Opertion_btn clickable mr-3"
              >
                View Report
              </button>
            </div>
            {this.showFilter()}
            <br></br>
            <br></br>
            <div>
              <div className=" row justify-content-end ">
                <button
                  onClick={() => {
                    if (
                      this.props?.location?.state?.selectedReport
                        ?.report_Name == "User Report"
                    ) {
                      this.handelUserListExport();
                    } else {
                      this.handelExport();
                    }
                  }}
                  style={{ marginTop: "-20px" }}
                  className="btn Opertion_btn clickable mr-3"
                >
                  Export
                </button>
              </div>
              {this.state.selectedReportName != "User Report" && (
                <div>
                  {this.TableOne()}
                 {this.state.selectedReportName == "Vehicle Stock As On Report"  || this.state.selectedReportName == "Sales Register Report" ?
                  <div>
                  
                <label
                  style={{
                    fontWeight: "600",
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                 {(this.state.selectedReportName == "Vehicle Stock As On Report") ?  'Total Stock' :'Total Sales'} : {this.state.FinalTotalStock}
                </label>
               
                <label
                  style={{
                    fontWeight: "600",
                    marginTop: "10px",
                    fontSize: "13px",
                    marginLeft:'30px'
                  }}
                >
                   Total Amount : {parseFloat(this.state.FinalTotalamount).toFixed(2)}

                </label>
                  
              
              
              </div>:null}
                  {this.state.ShowTable2 && this.TableTwo()}
                  {this.state.ShowTable3 && this.TableThree()}
                  {this.state.ShowTable4 && this.TableFour()}
                </div>
              )}
              {this.state.selectedReportName == "User Report" &&
                this.userReportTable()}
             {this.state.newurl&&this.ExcelPOPUP()}
            </div>
          </div>
        )}
      </div>
    );
  }
}
