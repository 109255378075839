import React from "react";
import "../../Files/files-styles.css";
// import '../Claims/Dashboard/styles.css'
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import DatePicker from "react-datepicker";
import moment from "moment";
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import { Tiles } from "../../../components/Tiles";
import Strings from "../../../../helpers/Strings";
// import { post } from 'axios';
import { Alert } from "../../../../helpers/getDetails";
import { Lead } from "../Lead";
import { Booking_M3 } from "../Booking_M3";
import { Enquiry_details } from "../Enquiry_details";
import { Money_reciept } from "../Money_reciept";
import { Vehical_billing } from "../Vehical_billing";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { ListFilter } from "../../../components/ListFilter";
const $ = window.$;
export class Confirmed_Enquiry extends React.Component {
  constructor(props) {
    console.log(props, "ppppppp");
    super(props);
    this.state = {
      ActiveTab: sessionStorage.getItem("ActiveTab"),
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      ethnicity_map: [],
      showTable:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].showTable
          ? props.location.state.data[0].showTable
          : "",
      Ethnicity: [],
      Title:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].title
          ? props.location.state.data[0].title
          : "",
      enquiry_no: "",
      enquiry_date: "",
      Source: "",
      Source_name: "",
      source_mobile: "",
      address1: "",
      address2: "",
      ext_mit_cust: "",
      pincode: "",
      State: "",
      District: "",
      primary_application: "",
      secondary_application: "",
      startDate: "",
      total_no_fleet: "",
      kam_customer: "",
      call_ticket_number: "",
      call_ticket_date: "",
      HiPaaS_ID: "",
      visit_details: "",
      discussion: "",
      next_visit_details: "",
      HiPaaS_ID: "",
      date: "",
      newdate: "",
      time_spent: "",
      Commitment: "",
      m1_count: 0,
      m2_count: 0,
      m3_count: 0,
      m7_count: 0,
      m8_count: 0,
      loaderforupload: false,
      redirect_page: "",
      tableData: [
        { id: 1, name: "Item 1", quantity: 0 },
        { id: 2, name: "Item 2", quantity: 0 },
        { id: 3, name: "Item 3", quantity: 0 },
      ],
      rowData: [],
      PendingrowData: [],
      flag: false,
      Booking_M3_Details: [],
      name: "",
      pendingflag: false,
      Enquiry_Props: {},
      pendingEvent: "",
      Booking_flag: false,
      Enq_flag: false,
      Booking_Savedflag: false,
      money_receipt: false,
      newmoney_recived: false,
      sqlfor:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].open_enquiry
          ? props.location.state.data[0].open_enquiry
          : "",
      dashboard_Click:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].dashboard_Click
          ? props.location.state.data[0].dashboard_Click
          : "",
      Title:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].title
          ? props.location.state.data[0].title
          : "",
      TitleFromHistory: this.props?.location?.state?.Title
        ? this.props?.location?.state?.Title
        : "",
      dealerid:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].dealerid
          ? props.location.state.data[0].dealerid
          : sessionStorage.getItem("dealerid"),
      startDate:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].startDate
          ? props.location.state.data[0].startDate
          : moment().subtract(7, "d").format("YYYY-MM-DD"),
      endDate:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].endDate
          ? props.location.state.data[0].endDate
          : moment().format("YYYY-MM-DD"),
          showtimerange:true
        // props.location.state &&
        // props.location.state.data &&
        // props.location.state.data[0] &&
        // props.location.state.data[0].showtimernagefilter
        //   && props.location.state.data[0].showtimernagefilter,
    };
  }

  componentDidMount() {
    this.MasterAPICall();
    if (this.state.TitleFromHistory) {
      this.setState({
        Title: this.state.TitleFromHistory,
      });
    }
    // this.handleSort();
    // this.m1_count();
    // this.m2_count();
    // this.m3_count();
    // this.m7_count();
    // this.m8_count();
    // window.addEventListener("storage", this.handleStorageChange);
    if (this?.props?.location?.state?.id) {
      let FromHistory = localStorage.getItem("FromHistory");
      if (this?.props?.location?.state?.id && FromHistory === "true") {
        this.setState(
          {
            name: this.props.location.state.customer_name,
            id: this.props.location.state.id,
            Enq_flag: true,
            Enquiry_Status: this.props.location.state.Enquiry_Status,
          },
          () => {
            localStorage.setItem("FromHistory", false);
            this.displayEnquiry(this.props.location.state.id);
          }
        );
      }
    }
  }
  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("storage", this.handleStorageChange);
  }
  componentDidUpdate() {
    if (this.state.ActiveTab != sessionStorage.getItem("ActiveTab")) {
      window.location.reload();
    }
  }
  onChangeName(event, key) {
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
  }
  ChangeVal(event, key) {
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
  }
  handleStartChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  onSelect = (event, key) => {
    console.log("event", event, key);
    this.setState({
      [key]: event.target.options[event.target.selectedIndex].value,
    });
  };
  handledateChange = (event, key) => {
    console.log("key", event.target.value, key);
    this.setState({
      [key]: event.target.value,
    });
  };
  handlecall_ticket_dateChange = (date) => {
    this.setState(
      {
        call_ticket_date: date,
      },
      () => {}
    );
  };
  PendingList = (Docname) => {
    const url = Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";

    var data = JSON.stringify({
      GetRecordFor: Docname,
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res, "popio");
          this.setState({
            PendingrowData: res[0],

            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
          $("#payment_error_modal").modal("show");
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  m1_count = () => {
    const url = Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";
    var data = JSON.stringify({
      GetRecordFor: "'M1'",
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log("ssssssss", res[1][0]?.count);
          this.setState({
            m1_count: res[1][0]?.count,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  m2_count = () => {
    const url = Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";
    var data = JSON.stringify({
      GetRecordFor: "'M2'",
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            m2_count: res[1][0]?.count,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  m3_count = () => {
    const url = Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";
    var data = JSON.stringify({
      GetRecordFor: "'M3'",
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            m3_count: res[1][0]?.count,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  m7_count = () => {
    const url = Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";
    var data = JSON.stringify({
      GetRecordFor: "'M7'",
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            m7_count: res[1][0]?.count,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  m8_count = () => {
    const url = Urls.python_baseurl + "/SP_GetRecord_OnNewClick_VehSale";
    var data = JSON.stringify({
      GetRecordFor: "'M8'",
      sDealerIds: sessionStorage.getItem("dealerid"),
      UserRole: sessionStorage.getItem("UserRole"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            m8_count: res[1][0]?.count,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handleInputChange = (id, event) => {
    const { name, value } = event.target;

    // Update the quantity for the corresponding item
  };
  clickNavigation = (event) => {
    this.props.history.push("/Enquiry", {});
  };
  saveLead = (event) => {};
  handleSort = (event) => {
    this.setState({ rowData: [] });
    if (event === "m0" || this.state.ActiveTab == "Lead") {
      this.setState({ showTable: "m0" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'M0Master'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    } else if (
      this.state.showTable === "m1" ||
      this.state.ActiveTab == "Enquiry"
    ) {
      this.setState({ showTable: "m1" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'M1Details'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    } else if (
      this.state.showTable === "m2" ||
      this.state.ActiveTab == "Quotation"
    ) {
      this.setState({ showTable: "m2" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'M2Details'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    }
    // else if (this.state.showTable=== "m3" || this.state.ActiveTab == "Vehicle Booking") {
    //   this.setState({ showTable: "m3" }, () => {
    //     let data = JSON.stringify({
    //       FromDate: "''",
    //       ToDate: "''",
    //       ModelPart: "D",
    //       DealerId: sessionStorage.getItem("dealerid"),
    //       SqlFor: "'M3Details'",
    //       HOBrID: sessionStorage.getItem("dealerid"),
    //     });
    //     this.MasterAPICall(data);
    //   });
    // }
    else if (event === "m7" || this.state.ActiveTab == "Money Received") {
      this.setState({ showTable: "m7" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'M7Details'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    } else if (event === "m8" || this.state.ActiveTab == "Vehicle Invoice") {
      this.setState({ showTable: "m8" }, () => {
        let data = JSON.stringify({
          FromDate: "''",
          ToDate: "''",
          ModelPart: "D",
          DealerId: sessionStorage.getItem("dealerid"),
          SqlFor: "'m8Details'",
          HOBrID: sessionStorage.getItem("dealerid"),
        });
        this.MasterAPICall(data);
      });
    }
  };
  forcedReferes;
  MasterAPICall = (obj) => {
    const url = Urls.python_baseurl + "/SP_VehicleSalesDetails";
    var data = JSON.stringify({
      Dealer_ID: `'${this.state.dealerid}'`,
      SqlFor: "'Enquiry'",
      startdate: `'${this.state.startDate}'`,
      enddate: `'${this.state.endDate}'`,
      Dashboard_For: `'${this.state.sqlfor}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          const isNullish = Object.values(res[0][0]).every((value) => {
            if (value === null || value === "") {
              return true;
            }
            return false;
          });
          if (isNullish) {
            this.setState({
              rowData: [],
            });
          } else {
            this.setState({
              rowData: res[0],
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  displayLead = (id) => {
    const url = Urls.python_baseurl + "/SP_GetM0";
    var data = JSON.stringify({
      iID: id,
      type: "'all'",
      dealer: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      CRMCust: "0",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              LeadDetails: res[0][0],
              flag: true,
            },
            () => {
              $("#payment_error_modal_2").modal("show");
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  displayEnquiry = (id) => {
    const url = Urls.python_baseurl + "/Display_Enquiry_SP_GetM1";
    var data = JSON.stringify({
      POId: id,
      POType: "'all'",
      DealerID: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      iM0ID: "1",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0 && res[0].length > 0) {
          this.setState(
            {
              EnquiryDetails: res,
              flag: true,
            },
            () => {
              $("#payment_error_modal_2").modal("show");
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  displayBookingM3 = (id) => {
    const url = Urls.python_baseurl + "/Booking_display_SP_GetM3";
    var data = JSON.stringify({
      POId: "0",
      POType: "'Max'",
      DealerID: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      iM1ID: id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0 && res[0].length > 0) {
          this.setState(
            {
              Booking_M3_Details: res,
              flag: true,
            },
            () => {
              $("#payment_error_modal_2").modal("show");
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  displayMoneyReceived = (id) => {
    const url = Urls.python_baseurl + "/MoneyReceipt_display_SP_GetM7";
    var data = JSON.stringify({
      POId: this.state.MoneyReceiptID,
      POType: "'All'",
      DealerID: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      iM1ID: "0",
      DocType: "'L'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0 && res[0].length > 0) {
          this.setState(
            {
              ReceiptDetails: res,
              flag: true,
            },
            () => {
              $("#payment_error_modal_2").modal("show");
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  displayVehicleInvoice = () => {
    $("#payment_error_modal_2").modal("show");
  };
  cellColored2(params) {
    console.log("params", params);
    const Backgroundcolor = {};
    if (
      params.colDef.headerName === "Booking No" &&
      params.data.Booking_No === null &&
      params.data.M3Confirm === null &&
      params.data.M3 === null
    ) {
      return { color: "orange", ...Backgroundcolor, cursor: "pointer" };
    } else {
      return {
        color: "var(--main-bg-color)",
        ...Backgroundcolor,
        cursor: "pointer",
      };
    }
  }
  cellColored4(params) {
    console.log("params", params);
    const Backgroundcolor = {};
    if (
      params.data.Enquiry_Status=="Lost"
    ) {
      return { color: "red", ...Backgroundcolor};
    } 
  }
  cellColored3(params) {
    console.log("params", params);
    const Backgroundcolor = {};
    if (
      params.colDef.headerName === "Money Receipt No" &&
      params.data.MoneyReceipt_No === null &&
      params.data.M7Confirm === null &&
      params.data.M3Confirm === "Y" &&
      params.data.Booking_No != null &&
      params.data.M3 != null &&
      params.data.M7 === null
    ) {
      return { color: "orange", ...Backgroundcolor, cursor: "pointer" };
    } else {
      return {
        color: "var(--main-bg-color)",
        ...Backgroundcolor,
        cursor: "pointer",
      };
    }
  }
  masterTable2() {
    let columnDefs = [];
    this.state.sqlfor != "open enquiry"
      ? (columnDefs = [
          { headerName: "Dealer Name", field: "Dealer_Name", width: "140px" },
          { headerName: "Dealer Code", field: "DealerCode", width: "120px" },
          {
            headerName: "Enquiry No",
            field: "Inq_no",
            width: "150px",
            cellStyle:
              sessionStorage.getItem("UserRole") == "1"
                ? { color: "var(--main-bg-color)", cursor: "pointer" }
                : {},
          },
          { headerName: "Enquiry Date", field: "Inq_date", width: "140px" },
          {
            headerName: "Name",
            field: "name",
            width: "140px",
          },
          (sessionStorage.getItem("UserRole") == "1")||(sessionStorage.getItem("UserRole") == "6")
        ?{ headerName: "Mobile No.", field: "mobile", width: "130px" }:{hide:true},
          // { headerName: "Mobile", field: "mobile", width: "120px" },

          // { headerName: "Model", field: "model_name",flex:1 },
          { headerName: "Status", field: "Enquiry_Status", width: "100px",cellStyle: this.cellColored4 },

          {
            headerName: "Booking No",
            field: "Booking_No",
            width: "160px",
            // cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
            cellRenderer: (data) => {
              console.log("data.data.Enquiry_Status",data.data);
              if(data.data.Enquiry_Status=="Lost"){
                return
              }else
              if (
                data.data.Booking_No === null &&
                data.data.M3Confirm === null &&
                data.data.M3 === null
              ) {
                return "Create Booking";
              } else {
                return data.data.Booking_No;
              }
            },
            cellStyle: this.cellColored2,
          },
          { headerName: "Booking Date", field: "Booking_Date", width: "120px" },
          {
            headerName: "Money Receipt No",
            field: "MoneyReceipt_No",
            width: "148px",
            cellRenderer: (data) => {
              if (
                data.data.MoneyReceipt_No === null &&
                data.data.M7Confirm === null &&
                data.data.M3Confirm === "Y" &&
                data.data.Booking_No != null &&
                data.data.M3 != null &&
                data.data.M7 === null
              ) {
                return "Create Money Receipt";
              } else {
                return data.data.MoneyReceipt_No;
              }
            },
            cellStyle: this.cellColored3,
          },
          {
            headerName: "Money Receipt Date",
            field: "MoneyReceipt_Date",
            width: "155px",
          },
          // {
          //   headerName: "Allocation Status",
          //   field: "Allocation Status",
          //   width: "155px",
          // },
        ])
      : (columnDefs = [
          { headerName: "Dealer Name", field: "Dealer_Name",  width: "120px", },
          { headerName: "Dealer Code", field: "DealerCode", width: "110px" },
          {
            headerName: "Enquiry No",
            field: "Inq_no",
            width: "155px",
            cellStyle:
              sessionStorage.getItem("UserRole") == "1"
                ? { color: "var(--main-bg-color)", cursor: "pointer" }
                : {},
          },
          { headerName: "Enquiry Date", field: "Inq_date", width: "120px", },
          {
            headerName: "Aging Days",
            field: "Inq_date",
            width: "120px",
            // flex: 1,
            cellRenderer: (data) => {
              console.log("Data:", data);
              let newdata = data.data.Inq_date;
              console.log("newdata", newdata);
              let start = moment(data.data.Inq_date, "DD-MM-YYYY").format(
                "MM-DD-YYYY"
              ); // Corrected date format
              console.log("Start Date:", start);
              let future = moment(); // Current date
              console.log("Current Date:", future.format("YYYY-MM-DD"));
              let d = future.diff(start, "days");
              console.log("Difference in Days:", d);
              return d.toString() + " " + "days ago"; // Convert to string and return
            },
          },
          {
            headerName: "Visit Date",
            field: "Visit_Date",
            width: "120px",
          },
          {
            headerName: "Name",
            field: "name",
            width: "120px",
          },
          (sessionStorage.getItem("UserRole") == "1")||(sessionStorage.getItem("UserRole") == "6")
        ?{ headerName: "Mobile No.", field: "mobile", width: "130px" }:{hide:true},
          // { headerName: "Mobile", field: "mobile",  width: "120px", },

          // { headerName: "Model", field: "model_name",flex:1 },
          { headerName: "Status", field: "Enquiry_Status",  width: "90px",cellStyle: this.cellColored4,},

          {
            headerName: "Booking No",
            field: "Booking_No",
            width: "120px",
            cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
            cellRenderer: (data) => {
              console.log("data.data.Enquiry_Status",data.data);
              if(data.data.Enquiry_Status=="Lost"){
                return
              }else
              if (
                data.data.Booking_No === null &&
                data.data.M3Confirm === null &&
                data.data.M3 === null
              ) {
                return "Create Booking";
              } else {
                return data.data.Booking_No;
              }
            },
            cellStyle: this.cellColored2,
          },
          //   { headerName: "Booking Date", field: "Booking_Date", width: "120px", },
          //   { headerName: "Money Receipt No", field: "MoneyReceipt_No", width: "148px",cellStyle:{ color: "var(--main-bg-color)", cursor: "pointer" },
          //   cellRenderer: (data) => {
          //     if(data.data.MoneyReceipt_No===null && data.data.M7Confirm===null  && data.data.M3Confirm==="Y"
          //     && data.data.Booking_No!=null && data.data.M3!=null &&  data.data.M7===null ){
          //       return "Create Money Receipt"

          //     }else{
          //       return data.data.MoneyReceipt_No
          //     }

          // },cellStyle: this.cellColored3 },
          //   { headerName: "Money Receipt Date", field: "MoneyReceipt_Date", width: "148px" },
        ]);

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              console.log("event12", event.data);
              if (sessionStorage.getItem("UserRole") == "1") {
                if (event.colDef.field == "Inq_no") {
                  console.log(event.data);
                  this.setState(
                    {
                      name: event.data.Name,
                      id: event.data.M1,
                      Enq_flag: true,
                      Enquiry_Status: event.data.Enquiry_Status,
                    },
                    () => {
                      this.displayEnquiry(event.data.M1);
                    }
                  );
                } else if (
                  event.data.Enquiry_Status!="Lost"&&
                  event.colDef.field == "Booking_No" &&
                  event.data.Booking_No === null &&
                  event.data.M3Confirm === null &&
                  event.data.M3 === null
                ) {
                  this.setState(
                    {
                      // id: event.data.M1,
                      pendingEvent3: event.data,
                      flag: true,
                      Booking_flag: true,
                    },
                    () => {
                      // this.displayBookingM3(event.data.ID);
                      $("#payment_error_modal_2").modal("show");
                    }
                  );
                } else if (event.colDef.field == "Booking_No" && event.data.Enquiry_Status!="Lost") {
                  this.setState(
                    {
                      id: event.data.M3,
                      flag: true,
                      bookingFlag: true,
                      Booking_Savedflag: true,
                    },
                    () => {
                      // this.displayBookingM3(event.data.ID);
                      $("#payment_error_modal").modal("hide");
                      $("#payment_error_modal_2").modal("show");
                    }
                  );
                } else if (
                  event.colDef.field == "MoneyReceipt_No" &&
                  event.data.M7 != null
                ) {
                  this.setState(
                    {
                      id: event.data.M7,
                      flag: true,
                      money_receipt: true,
                      Status: event.data.M7Confirm,
                      // pendingEvent7: event.data,
                    },
                    () => {
                      // this.displayBookingM3(event.data.ID);
                      $("#payment_error_modal_2").modal("show");
                    }
                  );
                } else if (
                  event.colDef.field == "MoneyReceipt_No" &&
                  event.data.MoneyReceipt_No === null &&
                  event.data.M7 === null &&
                  event.data.M3Confirm === "Y" &&
                  event.data.M3 != null
                ) {
                  this.setState(
                    {
                      id: event.data.M3,
                      flag: true,
                      newmoney_recived: true,
                      pendingEvent7: event.data,
                    },
                    () => {
                      console.log("idy666", this.state.id);
                      // this.displayBookingM3(event.data.ID);
                      $("#payment_error_modal_2").modal("show");
                    }
                  );
                }
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  tab() {
    return (
      <div style={{ marginLeft: "2px" }}>
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {/* <a
              className={
                this.state.ActiveTab != "" && this.state.ActiveTab == "Lead"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-home-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Lead" }, () =>
                  setTimeout(() => {
                    this.handleSort("m0");
                    sessionStorage.setItem("ActiveTab", "Lead");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Lead
            </a> */}
            <a
              className={
                this.state.ActiveTab != "" && this.state.ActiveTab == "Enquiry"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-home-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Enquiry" }, () =>
                  setTimeout(() => {
                    this.handleSort("m1");
                    sessionStorage.setItem("ActiveTab", "Enquiry");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Enquiry
            </a>
            {/* <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Quotation"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Quotation" }, () =>
                  setTimeout(() => {
                    this.handleSort("m2");
                    sessionStorage.setItem("ActiveTab", "Quotation");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Quotation
            </a> */}
            <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Vehicle Booking"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Vehicle Booking" }, () =>
                  setTimeout(() => {
                    this.handleSort("m3");
                    sessionStorage.setItem("ActiveTab", "Vehicle Booking");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Vehicle Booking
            </a>
            <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Money Received"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Money Received" }, () =>
                  setTimeout(() => {
                    this.handleSort("m7");
                    sessionStorage.setItem("ActiveTab", "Money Received");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Money Received
            </a>
            <a
              className={
                this.state.ActiveTab != "" &&
                this.state.ActiveTab == "Vehicle Invoice"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              id="nav-profile-tab"
              onClick={() =>
                this.setState({ ActiveTab: "Vehicle Invoice" }, () =>
                  setTimeout(() => {
                    this.handleSort("m8");
                    sessionStorage.setItem("ActiveTab", "Vehicle Invoice");
                  }, 4)
                )
              }
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Vehicle Invoice
            </a>

            {/* <a className="nav-item nav-link" id="nav-contact-tab" onClick={() => this.handleSort('Resubmit')} data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Ready to Submit</a> */}
          </div>
        </nav>
      </div>
    );
  }

  _renderSummaryDetails = () => {
    let row = [];
    let summary = [];
    summary = [
      // { name: 'Total Files', value: data ? data.RejectedCount ? Number(data.TotalFiles) + Number(data.RejectedCount) : data.TotalFiles : 0 },
      // {
      //   name: "Total Pending Enquiry",
      //   value: this.state.m1_count,
      //   color: "#129DC9",
      // },
      // {
      //   name: "Total Pending Quotation",
      //   value: this.state.m2_count,
      //   color: "green",
      // },
      {
        name: "Total Pending Vehicle Booking",
        value: this.state.m3_count,
        color: "orange",
      },
      {
        name: "Total Pending Money Received",
        value: this.state.m7_count,
        color: "#55eb34",
      },
      {
        name: "Total Pending Vehicle Invoice",
        value: this.state.m8_count,
        color: "#34ebc6",
      },
    ];
    let array = summary;

    array.forEach((item) => {
      let addon = "";
      let claimStatus = "";
      let subtitle = "";
      let availitySent = "";
      let url = "";
      let data = [];

      row.push(
        <Tiles
          isClickable={item.name != "Eligibility Errors"}
          header_text={item.name}
          value={item.value}
          isenrollment={true}
          color={item.color}
          differentTile={true}
          onClick={() => {
            if (this.state.m1_count && item.name === "Total Pending Enquiry") {
              this.PendingList("M1");
              this.setState({
                redirect_page: "M1",
              });
            } else if (
              this.state.m2_count &&
              item.name === "Total Pending Quotation"
            ) {
              this.PendingList("M2");
              this.setState({
                redirect_page: "M2",
              });
            } else if (
              this.state.m3_count &&
              item.name === "Total Pending Vehicle Booking"
            ) {
              this.PendingList("M3");
              this.setState({
                redirect_page: "M3",
              });
            } else if (
              this.state.m7_count &&
              item.name === "Total Pending Money Received"
            ) {
              this.PendingList("M7");
              this.setState({
                redirect_page: "M7",
              });
            } else if (
              this.state.m8_count &&
              item.name === "Total Pending Vehicle Invoice"
            ) {
              this.PendingList("M8");
              this.setState({
                redirect_page: "M8",
              });
            }
          }}
        />
      );
    });

    return <div className="row padding-left">{row}</div>;
  };
  close() {
    return (
      <div style={{ marginLeft: "2px" }}>
        <nav>
          <button
            type="button"
            class="close close-btn"
            style={{ marginTop: "-2%" }}
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              this.close();
            }}
          >
            <span class="" aria-hidden="true">
              &times;
            </span>
          </button>
        </nav>
      </div>
    );
  }
  masterTable() {
    let columnDefs = [
      {
        headerName: "Doc No",
        field: "Doc_No",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Customer Name", field: "Dealer_Name", flex: "1" },
      { headerName: "Mobile", field: "mobile", flex: "1" },
    ];
    if (this.state.redirect_page === "M8") {
      columnDefs.push({
        headerName: "Chassis No",
        field: "Chassis_no",
        flex: "1",
      });
    }

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.PendingrowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (
                event.colDef.field == "Doc_No" &&
                this.state.redirect_page === "M1"
              ) {
                // this.props.history.push("/Enquiry_details", {
                //   data: event.data.ID,
                //   Dealer_Name: event.data.Dealer_Name,
                // });
                // $("#payment_error_modal").modal("hide");
                this.setState(
                  {
                    pendingflag: true,
                    pendingEvent: event.data,
                  },
                  () => {
                    $("#payment_error_modal").modal("hide");
                    $("#payment_error_modal_3").modal("show");
                  }
                );
              } else if (
                event.colDef.field == "Doc_No" &&
                this.state.redirect_page === "M2"
              ) {
                this.props.history.push("/Quotation_M2", {
                  data: event.data,
                });
                $("#payment_error_modal").modal("hide");
              } else if (
                event.colDef.field == "Doc_No" &&
                this.state.redirect_page === "M3"
              ) {
                // this.props.history.push("/Booking_M3", {
                //   data: event.data,
                // });
                this.setState(
                  {
                    pendingflag: true,
                    pendingEvent3: event.data,
                  },
                  () => {
                    $("#payment_error_modal").modal("hide");
                    $("#payment_error_modal_3").modal("show");
                  }
                );
              } else if (
                event.colDef.field == "Doc_No" &&
                this.state.redirect_page === "M7"
              ) {
                // this.props.history.push("/Money_reciept", {
                //   data: event.data,
                // });
                this.setState(
                  {
                    pendingflag: true,
                    pendingEvent7: event.data,
                  },
                  () => {
                    $("#payment_error_modal").modal("hide");
                    $("#payment_error_modal_3").modal("show");
                  }
                );
              } else if (
                event.colDef.field == "Doc_No" &&
                this.state.redirect_page === "M8"
              ) {
                // this.props.history.push("/Vehical_billing", {
                //   data: event.data,
                // });
                this.setState(
                  {
                    pendingflag: true,
                    pendingEvent8: event.data,
                  },
                  () => {
                    $("#payment_error_modal").modal("hide");
                    $("#payment_error_modal_3").modal("show");
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  errorDialog = () => {
    return (
      <div class="modal" id="payment_error_modal">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div style={{ marginTop: "-20px" }} class="modal-body">
              {this.masterTable()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  displayPopUp = () => {
    return (
      <div class="modal" id="payment_error_modal_2">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => {
                  this.setState({
                    flag: false,
                    id: 0,
                    EnquiryDetails: [],
                    Enq_flag: false,
                    Booking_Savedflag: false,
                    money_receipt: false,
                    Booking_flag: false,
                    newmoney_recived: false,
                    // pendingEvent: [],
                    // Customer_Name: "",
                  });
                }}
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.state.Enq_flag ? (
                <Enquiry_details
                  customer_name={this.state.name}
                  modelid={"saveClcik"}
                  Enquiry_Status={this.state.Enquiry_Status}
                  EnquiryDetails={this.state.EnquiryDetails}
                  id={this.state.id}
                  history={this.props.history}
                  Title={this.state.Title}
                />
              ) : null}
              {this.state.Booking_flag ? (
                <Booking_M3
                  modelid={"pendingClick"}
                  pendingClick3={this.state.pendingEvent3}
                  booking={false}
                  history={this.props.history}
                />
              ) : null}
              {this.state.Booking_Savedflag ? (
                <Booking_M3
                  modelid={"saveClcik"}
                  id={this.state.id}
                  booking={true}
                  vehicle={"enquiry"}
                  history={this.props.history}
                />
              ) : null}
              {this.state.money_receipt ? (
                <Money_reciept
                  modelid={"saveClcik"}
                  pendingClick7={this.state.pendingEvent7}
                  pendingClick={{ ID: this.state.MoneyReceiptID }}
                  ReceiptDetails={this.state.ReceiptDetails}
                  Status={this.state.Status}
                  ID={this.state.id}
                  history={this.props.history}
                ></Money_reciept>
              ) : null}
              {this.state.newmoney_recived ? (
                <Money_reciept
                  modelid={"pendingClick"}
                  pendingClick7={this.state.pendingEvent7}
                  history={this.props.history}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  dispalyPending = () => {
    return (
      <div class="modal" id="payment_error_modal_3">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => {
                  this.setState({
                    pendingflag: false,
                    pendingEvent: [],
                    Customer_Name: "",
                  });
                }}
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.state.redirect_page === "M1" && (
                <Enquiry_details
                  modelid={"pendingClick"}
                  pendingClick={this.state.pendingEvent}
                  history={this.props.history}
                  
                />
              )}
              {this.state.redirect_page === "M3" && (
                <Booking_M3
                  modelid={"pendingClick"}
                  pendingClick3={this.state.pendingEvent3}
                  booking={false}
                  history={this.props.history}
                ></Booking_M3>
              )}
              {this.state.redirect_page === "M7" && (
                <Money_reciept
                  modelid={"pendingClick"}
                  pendingClick7={this.state.pendingEvent7}
                  history={this.props.history}
                ></Money_reciept>
              )}
              {this.state.redirect_page === "M8" && (
                <Vehical_billing
                  modelid={"pendingClick"}
                  pendingClick={this.state.pendingEvent8}
                  history={this.props.history}
                ></Vehical_billing>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  handelLead() {
    this.setState(
      {
        id: 0,
        EnquiryDetails: [],
        flag: true,
        Enq_flag: true,
        Enquiry_Status: "",
      },
      () => {
        $("#payment_error_modal_2").modal("show");
      }
    );
  }
  refreshScreen = (startDate, endDate, dealerid) => {
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        dealerid: dealerid,
      },
      () => {
        this.MasterAPICall();
      }
    );
  };
  render() {
    return (
      <div>
        {/* <div class="form-group col-md-12">{this._renderSummaryDetails()}</div> */}
        <h4 className="heading_text" style={{ marginLeft: "0px" }}>
          {this.state.Title}
        </h4>
        <br></br>
        <br></br>
        {/* {this.state.sqlfor!="open enquiry"? */}
        <br></br>
        <div style={{ marginLeft: sessionStorage.getItem("UserRole")=="1"?"0px":"" }}>
          <ListFilter
            refreshScreen={this.refreshScreen}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dealerid={this.state.dealerid}
            showtimerange={this.state.showtimerange}
            
          />
          {sessionStorage.getItem("UserRole") == "1" && (
          <div
            class="form-group col-md-12"
            style={{ padding: "0px", marginLeft: "-10px " }}
          >
            <button
              // onClick={() => this.props.history.push("/Lead")}
              onClick={() => this.handelLead()}
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right",marginTop:"-40px" }}
            >
              Add New
            </button>
          </div>
        )}
        </div>

        {this.masterTable2()}

        {this.errorDialog()}
        {this.state.flag == true && this.displayPopUp()}
        {this.state.pendingflag == true && this.dispalyPending()}
      </div>
    );
  }
}
