import React from "react";
import Urls from "../../../../helpers/Urls";
import "../../color.css";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import Strings from "../../../../helpers/Strings";
import Notification from "../../../components/Notification";
import {
  WARNING,
  SUCCESS,
  INFO,
  ERROR,
} from "../../../components/Notification";
const $ = window.$;
const bcrypt = require("bcryptjs");

export class Vehicle_Receipt_dealer_to_dealer extends React.Component {
  constructor(props) {
    console.log(props, "hjhjhj");
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 100,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
        headerCheckboxSelection: true,
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "multiple",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showFieldOnAddNew: true,
      Model_Category_Option: [],
      Model_Code_Option: [],
      Model_Option: [],
      Invoice_Option: [],
      Model_Id: "",
      ModelRowData: [],
      idforconfirm: 0,
      Receipt_Date: moment(new Date()).format("DD-MM-YYYY"),
      status:
        props &&
        props.location &&
        props.location.state &&
        props.location.state.Isconfirm &&
        props.location.state.Isconfirm === "Confirmed"
          ? "Confirmed"
          : "Open",
      isSelectTag: true,
      flag: "insert",
      tax_raw: [],
      model_details: [],
      MAinHdr:
        props && props.location && props.location.state.delarID
          ? props.location.state.delarID
          : 0,
      D2Dsales:
        props && props.location && props.location.state.D2Dsales
          ? props.location.state.D2Dsales
          : false,
      Gross_Amt: 0,
      chassis_id_list: [],
      Customer_list: [],
      Chassis_list: [],
      customer_ID: "",
      customer_type: "",
      customer_state: "",
      consignee_name: sessionStorage.getItem("dealer_name"),
      LR_No: "",
      LR_Date: "",
      DC_Date: "",
      DC_No: "",
      fin_year: sessionStorage.getItem("fin_year"),
      Dealer_Code: sessionStorage.getItem("Dealer_Code"),
      Customer_Name: "",
      GST_no: "",
      Dealer_cat: "",
      cust_id:
        props && props.location && props.location.state.cust_id
          ? props.location.state.cust_id
          : 0,
      Reference_Option: [],
      message: "",
    };
  }
  componentDidMount() {
    this.MasterAPICall();
    this.GetInvoiceList();
    this.GetModel();
    this.getDealerToDealerPOCreationList();
    //this.GetModelCategory();
    //this.GetModelCode();
    {
      this.state.MAinHdr != 0 && this.Get_Customer_List();
    }
    if (this?.props?.location?.state?.id) {
      let FromHistory = localStorage.getItem("FromHistory");
      if (this?.props?.location?.state?.id && FromHistory === "true") {
        this.setState(
          {
            MAinHdr: this?.props?.location?.state?.id,
            status: "Confirmed",
            cust_id: this?.props?.location?.state?.Customer_Name,
          },
          () => {
            localStorage.setItem("FromHistory", false);
            this.Get_Customer_List();
          }
        );
      }
    }
    // this.state.flag !== "update" && this.Vehicle_Receipt_No();
  }
  getDealerToDealerPOCreationList = () => {
    const url = Urls.python_baseurl + "/SP_GetDealerToDealerPOCreationList";
    var data = JSON.stringify({
      Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0&&res[0][0]?.["PO No"]!==null) {
          this.setState({
            Reference_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Get_Customer_List = () => {
    const url = Urls.python_baseurl + "/sp_GetCustomerForB2BSaleInvoice";
    var data = JSON.stringify({
      Dealer_ID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Customer_list: res,
            },
            () => {
              if (this.state.MAinHdr != 0) {
                this.GetVehgicleBillingDetails();
              }
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Get_Chassis_List = () => {
    const url =
      Urls.python_baseurl + "/SP_GetChassisForDealerToDealerPOCreation";
    var data = JSON.stringify({
      Dealer_ID: sessionStorage.getItem("dealerid"),
      PO_ID: `${this.state.reference_no}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Chassis_list: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  getcustomer_dealer_data = (id) => {
    const url = Urls.python_baseurl + "/Get_dealer_data_for_report";
    var data = JSON.stringify({
      Dealer_ID: id,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            GST_no: res[0]?.GST_No,
            Dealer_cat: res[0]?.category,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  saveVehicle_Receipt_No = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "INV",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              Vehicle_Receipt_NO:
                this.state.Dealer_Code +
                "" +
                "INV" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              this.handelSave("N");
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  Vehicle_Receipt_No = () => {
    const url = Urls.python_baseurl + "/SP_GetMaxDocNo";
    var data = JSON.stringify({
      sFinancialYear: sessionStorage.getItem("fin_year"),
      sDocName: "INV",
      iDealerID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState(
            {
              // docMax: res[0].DocMaxNo,
              Vehicle_Receipt_NO:
                this.state.Dealer_Code +
                "" +
                "INV" +
                "" +
                this.state.fin_year +
                "" +
                res[0].DocMaxNo.toString().padStart(6, "0"),
            },
            () => {
              // this.financiaryear_Vehicle_Receipt();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/sp_GetB2bInvoiceSale";
    var data = JSON.stringify({
      Dealer_ID: `${sessionStorage.getItem("dealerid")}`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          console.log(res, "hjhjh");
          this.setState({
            rowData: res[0],
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  // GetModelCode = () => {
  //   const url = Urls.python_baseurl + "/SP_GetDataForCombo";
  //   var data = JSON.stringify({
  //     type: 198,
  //     ID: 0,
  //     condition: "'and Model_cat_ID=1002'",
  //   });
  //   fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       if (res && res.length > 0) {
  //         this.setState({
  //           Model_Code_Option: res,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       process.env.NODE_ENV == "development" && console.log(err);
  //     });
  // };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 94,
      ID: 0,
      condition: "''",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Category_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModel = () => {
    const url = Urls.python_baseurl + "/SP_GetDataForCombo";
    var data = JSON.stringify({
      type: 197,
      ID: 0,
      condition: "'and Model_cat_ID=1002'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetInvoiceList = () => {
    const url = Urls.python_baseurl + "/DelaerInvno_GetVehInvoiceforDealerPO";
    var data = JSON.stringify({
      dealerId: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Invoice_Option: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  // getModelCode = async (model_category_id) => {
  //   const url =
  //     Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
  //   var data = JSON.stringify({
  //     ModelGroup: parseInt(model_category_id),
  //   });
  //   let res = await fetch(url, {
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //     body: data,
  //   });
  //   res = await res.json();
  //   if (res && res.length > 0) {
  //     console.log(res, "bhjhjbhjb");
  //     return res;
  //   }
  // };

  GetVehgicleBillingDetails = async () => {
    const url = Urls.python_baseurl + "/SP_GetB2bInvoiceSaleDetails";
    var data = JSON.stringify({
      Tr_no: `''`,
      POType: "'All'",
      DealerId: `${sessionStorage.getItem("dealerid")}`,
      HOBrID: `${sessionStorage.getItem("dealerid")}`,
      Hdr_ID: `${this.state.MAinHdr}`,
    });
    let res = await fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    });
    res = await res.json();
    if (res && res.length > 0) {
      let arr = [];
      for (let i = 0; i < res[0].length; i++) {
        arr.push(res[0][i]?.Model_GP);
      }
      let totalamt = 0;
      if (Array.isArray(res[1])) {
        for (let i = 0; i < res[1].length; i++) {
          totalamt += res[1][i].TotalAmt_WithTax
            ? res[1][i].TotalAmt_WithTax
            : 0;
        }
      }
      this.setState(
        {
          cust_id: res[0][0].cust_id ? res[0][0].cust_id : 0,
          double_save: res[0][0]?.Is_Confirm == "Y" ? true : false,
          Customer_Name: res[0][0].customer_name,
          consignee_name: res[0][0]?.consignee_name,
          GST_no: res[0][0].GST_No,
          Dealer_cat: res[0][0]?.Dealer_Category,
          LR_No: res[0][0]?.LR_No,
          LR_Date: res[0][0]?.LR_Date
            ? moment(res[0][0]?.LR_Date, "DD-MM-YYYY").format("YYYY-MM-DD")
            : "",
          DC_No: res[0][0]?.DC_no,
          DC_Date: res[0][0]?.DC_Date
            ? moment(res[0][0]?.DC_Date, "DD-MM-YYYY").format("YYYY-MM-DD")
            : "",
          ModelRowData: res[0],
          tax_raw: res[1].length > 0 ? res[1] : [],
          model_details: res[2].length > 0 ? res[2] : [],
          Vehicle_Receipt_NO: res[0][0]?.Tr_no,
          isSelectTag: false,
          Gross_Amt: totalamt,

          reference_no: res[0][0].Reference_No,
          reference_date: res[0][0].Reference_Date
            ? moment(res[0][0].Reference_Date, "YYYY-MM-DD").format(
                "DD-MM-YYYY"
              )
            : "",
          dtd_po_no: res[0][0].PO_No,
        },
        () => {
          this.modelTable();
          this.Get_Chassis_List();
        }
      );
    }
    if (res && res.length > 0) {
      let sum = 0,
        IGST = 0,
        CGST = 0,
        SGST = 0,
        TCS = 0;

      for (let i = 0; i < res[0].length; i++) {
        sum += res[0][i].rate ? res[0][i].rate : 0;
        IGST += res[0][i]?.IGST_Amt ? res[0][i]?.IGST_Amt : 0;
        CGST += res[0][i]?.CGST_Amt ? res[0][i]?.CGST_Amt : 0;
        SGST += res[0][i]?.SGST_Amt ? res[0][i]?.SGST_Amt : 0;
        TCS += res[0][i]?.TCS_Amt ? res[0][i]?.TCS_Amt : 0;
      }
      this.setState({
        // Receipt_Date: event.data["Receipt Date"],
        // Vehicle_Receipt_NO: event.data["Receipt No"],
        ModelRowData: res[0],
        Invoice_Date: res[0][0]?.inv_date
          ? moment(res[0][0]?.inv_date).format("DD-MM-YYYY")
          : "",
        Receipt_Date: res[0][0]?.ref_date,

        Delivery_No: res[0][0]?.LR_No,
        Vehicle_Condition: res[0][0]?.VehConditionID,
        TotalSum: sum,
        IGST: res[0][0].IGST_Per ? res[0][0].IGST_Per : 0,
        SGST: res[0][0].SGST_Per ? res[0][0].SGST_Per : 0,
        CGST: res[0][0].CGST_Per ? res[0][0].CGST_Per : 0,
        TCS: res[0][0].TCS_Per ? res[0][0].TCS_Per : 0,
        GrandTotalPer: IGST + CGST + SGST + TCS,
        IGST_Amount: IGST,
        SGST_Amount: SGST,
        CGST_Amount: CGST,
        TCS_Amount: TCS,
        GrandTotal_Amount: (IGST + SGST + CGST + TCS + sum).toFixed(2),
      });
    }
  };

  save_hsn_Tax = (id) => {
    let data1 = this.state.tax_raw.map((e) => {
      return {
        ID: `'${e.CGST_AMT}'`,
        UserId: `${sessionStorage.getItem("dealerid")}`,
        Invoice_No: `'${e.Invoice_No}'`,
        HSN_Code: `'${e.HSN_Code}'`,
        TaxableAmount: `'${e.TaxableAmount}'`,
        IGST: `'${e.IGST}'`,
        IGST_AMT: `'${e.IGST_AMT}'`,
        SGST: `'${e.SGST}'`,
        SGST_AMT: `'${e.SGST_AMT}'`,
        CGST: `'${e.CGST}'`,
        CGST_AMT: `'${e.CGST_AMT}'`,
        Receipt_id: "0",
        Receipt_Hdr_ID: `${id}`,
      };
    });

    const url =
      Urls.python_baseurl + "/SP_Save_dealerVehReceipt_HSNWise_TaxDetails";
    var data = JSON.stringify(data1);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          alert("Record Saved Successfully.");
          window.location.reload();
          if (res[0].ID) {
            // this.Investigation_save()
            // this.Attachment_save()
            // this.setState({
            //   saveLoader:false
            // },()=>{
            // })
            // window.location.reload()
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        // window.location.reload();
      });
  };

  save_model = (id) => {
    let data1 = this.state.model_details.map((e) => {
      return {
        ID: "0",
        UserId: `${sessionStorage.getItem("dealerid")}`,
        Invoice_No: `'${this.state.Invoice_No}'`,
        HSN_Code: `'${e.HSN_Code}'`,
        model_id: `${e.model_ID}`,
        qty: `${e.Qty}`,
        rate: `${e.rate}`,
        total: `${e.TotalAmt}`,
        Receipt_id: "0",
        Receipt_Hdr_ID: `${id}`,
      };
    });

    const url = Urls.python_baseurl + "/SP_Save_dealerVehReceipt_ModelWise";
    var data = JSON.stringify(data1);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            // this.Investigation_save()
            // this.Attachment_save()
            // this.setState({
            //   saveLoader:false
            // },()=>{
            // })
            // window.location.reload()
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {
        // window.location.reload();
      });
  };
  handelSave = (status) => {
    this.setState({
      double_save: true,
    });
    // let custid = this.state.Customer_list.filter((e) => {
    //   return e.Name == this.state.Customer_Name;
    // })[0]?.ID;
    let iid = 0;
    let data1 = this.state.ModelRowData?.map((e) => {
      if (this.state.MAinHdr != 0) {
        iid = e?.ID;
      }
      return {
        ID: `${iid}`,
        UserId: `${sessionStorage.getItem("dealerid")}`,
        HOBrID: `${sessionStorage.getItem("dealerid")}`,
        // FinYear: `'${sessionStorage.getItem("fin_year")}'`,
        // DocName: "'INV'",
        Tr_no: `'${this.state.Vehicle_Receipt_NO}'`,
        ref_date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
        Tallyinv_no: `'${e?.tallyInvoice}'`,
        Tallyinv_date: `'${
          e?.tally_date
            ? moment(e?.tally_date, "DD-MM-YYYY").format("DD-MM-YYYY")
            : ""
        }'`,
        LR_No: `'${this.state.LR_No}'`,
        LR_Date: `'${
          this.state.LR_Date
            ? moment(this.state.LR_Date).format("DD-MM-YYYY")
            : ""
        }'`,
        cust_id: `${this.state.cust_id}`,
        consignee_name: `'${this.state.consignee_name}'`,
        chassis_no: `'${e?.chassis_no}'`,
        model_ID: `${e?.model_ID}`,
        Model_GP: `${e?.Model_GP}`,
        rate: `'${e?.Rate}'`,
        Qty: `'${e?.Qty}'`,
        TotalAmt: `'${e?.total}'`,
        Discount: `'${e?.Discount}'`,
        Is_Confirm: "'N'",
        Is_Cancel: "'N'",
        Vehicle_No: `'${e?.Vehicle_No}'`,
        BatteryNo: `'${e?.BatteryNo}'`,
        ChargerNo: `'${e?.ChargerNo}'`,
        Controller_Unit_No: `'${
          e?.Controller_Unit_No ? e?.Controller_Unit_No : ""
        }'`,
        Motor_No: `'${e?.Motor_No}'`,
        DispayNo: `'${e?.DispayNo}'`,
        IOT_Device_ID: `'${e?.IOT_Device_ID ? e?.IOT_Device_ID : ""}'`,
        DC_No: `'${this.state.DC_No ? this.state.DC_No : ""}'`,
        DC_Date: `'${
          this.state.DC_Date
            ? moment(this.state.DC_Date).format("DD-MM-YYYY")
            : ""
        }'`,
        MAinHdr: `${this.state.MAinHdr}`,
        ActualBatteryNo: `'${e?.ActualBatteryNo ? e?.ActualBatteryNo : ""}'`,
        ActualChargerNo: `'${e?.ActualChargerNo ? e?.ActualChargerNo : ""}'`,
        ActualController_Unit_No: `'${
          e?.ActualController_Unit_No ? e?.ActualController_Unit_No : ""
        }'`,
        ActualMotor_No: `'${e?.ActualMotor_No ? e?.ActualMotor_No : ""}'`,
        ActualDispayNo: `'${e?.ActualDispayNo ? e?.ActualDispayNo : ""}'`,
        ActualIOT_Device_ID: `'${
          e?.ActualIOT_Device_ID ? e?.ActualIOT_Device_ID : ""
        }'`,
        Gross_Amt: `${this.state.Gross_Amt}`,
        DlrCode: `'${this.state.Dealer_Code}'`,
        FinYear: `'${sessionStorage.getItem("fin_year")}'`,
        GST_No: `'${this.state.GST_no ? this.state.GST_no : ""}'`,
        Dealer_Category: `'${
          this.state.Dealer_cat ? this.state.Dealer_cat : ""
        }'`,
        Reference_No: `'${this.state.reference_no}'`,
        Reference_Date: `'${
          this.state.reference_date
            ? moment(this.state.reference_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )
            : ""
        }'`,
      };
    });
    let data2 = this.state.model_details.map((e) => {
      let ID = 0;
      if (this.state.MAinHdr != 0) {
        ID = e?.ID;
      }

      return {
        ID: `${ID}`,
        UserId: `${sessionStorage.getItem("dealerid")}`,
        Tr_No: `'${this.state.Vehicle_Receipt_NO}'`,
        HSN_Code: `'${e.HSN_Code}'`,
        model_id: `${e.model_ID}`,
        qty: `${e.Qty}`,
        rate: `${e.rate}`,
        total: `${e.Total}`,
        Receipt_Hdr_ID: "0",
      };
    });
    let data3 = this.state.tax_raw.map((e) => {
      let ID = 0;
      if (this.state.MAinHdr != 0) {
        ID = e?.ID;
      }

      return {
        ID: `${ID}`,
        UserId: `${sessionStorage.getItem("dealerid")}`,
        Tr_No: `'${this.state.Vehicle_Receipt_NO}'`,
        HSN_Code: `'${e.HSN_Code}'`,
        TaxableAmount: `'${e.TaxableAmount}'`,
        IGST: `'${e.IGST}'`,
        IGST_AMT: `'${e.IGST_AMT}'`,
        SGST: `'${e.SGST}'`,
        SGST_AMT: `'${e.SGST_AMT}'`,
        CGST: `'${e.CGST}'`,
        CGST_AMT: `'${e.CGST_AMT}'`,
        Receipt_Hdr_ID: "0",
        TotalAmt_WithTax: `'${e.TotalAmt_WithTax}'`,
        TotalTaxAmt: `'${e.TotalTaxAmt}'`,
        HSN_Description: `'${e?.HSN_Description}'`,
      };
    });

    let combineddata = [data1, data2, data3];
    console.log(combineddata, "vhj");
    const url = Urls.python_baseurl + "/D2D_Vehicle_Invoice";
    var data = JSON.stringify(combineddata);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (this.state.MAinHdr == 0) {
          this.setState(
            {
              MAinHdr: res[0][0].ID,
            },
            () => {
              alert("Record Saved Successfully.");
              this.GetVehgicleBillingDetails();
              this.MasterAPICall();
            }
          );
        } else {
          this.setState(
            {
              MAinHdr: res[0][0].ID,
              double_save: false,
            },
            () => {
              this.GetVehgicleBillingDetails();
              this.MasterAPICall();
            }
          );
          alert("Record Updated Successfully.");
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {});
  };

  handleconfirm = (status) => {
    if (!this.state.Vehicle_Receipt_NO) {
      alert("Please Click on Add New First To Generate Invoice No.");
      return;
    } else if (this.state.Customer_Name == "") {
      alert("Please Select the Customer Name");
      return;
    } else if (this.state.LR_No == "") {
      alert("Please Enter the LR No");
      return;
    } else if (this.state.LR_Date == "") {
      alert("Please Select the LR Date");
      return;
    } else if (this.state.DC_No == "") {
      alert("Please Enter the Delivery Challan No");
      return;
    } else if (this.state.DC_Date == "") {
      alert("Please Select the Delivery Challan Date");
      return;
    } else if (
      this.state.chassis_id_list.length == 0 &&
      this.state.MAinHdr == 0
    ) {
      alert("Please Select the Chassis No");
      return;
    } else {
      this.setState({
        double_save: true,
      });
    }

    let custid = this.state.Customer_list.filter((e) => {
      return e.Name == this.state.Customer_Name;
    })[0]?.ID;
    let iid = 0;
    let data1 = this.state.ModelRowData?.map((e) => {
      if (this.state.MAinHdr != 0) {
        iid = e?.ID;
      }
      return {
        ID: `${iid}`,
        UserId: `${sessionStorage.getItem("dealerid")}`,
        HOBrID: `${sessionStorage.getItem("dealerid")}`,
        Tr_no: `'${this.state.Vehicle_Receipt_NO}'`,
        ref_date: `'${moment(new Date()).format("DD-MM-YYYY")}'`,
        Tallyinv_no: `'${e?.tallyInvoice}'`,
        Tallyinv_date: `'${
          e?.tally_date
            ? moment(e?.tally_date, "DD-MM-YYYY").format("DD-MM-YYYY")
            : ""
        }'`,
        LR_No: `'${this.state.LR_No}'`,
        LR_Date: `'${
          this.state.LR_Date
            ? moment(this.state.LR_Date).format("DD-MM-YYYY")
            : ""
        }'`,
        cust_id: `${this.state.cust_id}`,
        consignee_name: `'${this.state.consignee_name}'`,
        chassis_no: `'${e?.chassis_no}'`,
        model_ID: `${e?.model_ID}`,
        Model_GP: `${e?.Model_GP}`,
        rate: `'${e?.Rate}'`,
        Qty: `'${e?.Qty}'`,
        TotalAmt: `'${e?.total}'`,
        Discount: `'${e?.Discount}'`,
        Is_Confirm: "'Y'",
        Is_Cancel: "'N'",
        Vehicle_No: `'${e?.Vehicle_No}'`,
        BatteryNo: `'${e?.BatteryNo}'`,
        ChargerNo: `'${e?.ChargerNo}'`,
        Controller_Unit_No: `'${
          e?.Controller_Unit_No ? e?.Controller_Unit_No : ""
        }'`,
        Motor_No: `'${e?.Motor_No}'`,
        DispayNo: `'${e?.DispayNo}'`,
        IOT_Device_ID: `''`,
        DC_No: `'${this.state.DC_No ? this.state.DC_No : ""}'`,
        DC_Date: `'${
          this.state.DC_Date
            ? moment(this.state.DC_Date).format("DD-MM-YYYY")
            : ""
        }'`,
        MAinHdr: `${this.state.MAinHdr}`,
        ActualBatteryNo: `'${e?.ActualBatteryNo ? e?.ActualBatteryNo : ""}'`,
        ActualChargerNo: `'${e?.ActualChargerNo ? e?.ActualChargerNo : ""}'`,
        ActualController_Unit_No: `'${
          e?.ActualController_Unit_No ? e?.ActualController_Unit_No : ""
        }'`,
        ActualMotor_No: `'${e?.ActualMotor_No ? e?.ActualMotor_No : ""}'`,
        ActualDispayNo: `'${e?.ActualDispayNo ? e?.ActualDispayNo : ""}'`,
        ActualIOT_Device_ID: `'${
          e?.ActualIOT_Device_ID ? e?.ActualIOT_Device_ID : ""
        }'`,
        Gross_Amt: `${this.state.Gross_Amt}`,
        DlrCode: `'${this.state.Dealer_Code}'`,
        FinYear: `'${sessionStorage.getItem("fin_year")}'`,
        GST_No: `'${this.state.GST_no ? this.state.GST_no : ""}'`,
        Dealer_Category: `'${
          this.state.Dealer_cat ? this.state.Dealer_cat : ""
        }'`,
        Reference_No: `'${this.state.reference_no}'`,
        Reference_Date: `'${
          this.state.reference_date
            ? moment(this.state.reference_date, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              )
            : ""
        }'`,
      };
    });
    let data2 = this.state.model_details.map((e) => {
      let ID = 0;
      if (this.state.MAinHdr != 0) {
        ID = e?.ID;
      }

      return {
        ID: `${ID}`,
        UserId: `${sessionStorage.getItem("dealerid")}`,
        Tr_No: `'${this.state.Vehicle_Receipt_NO}'`,
        HSN_Code: `'${e.HSN_Code}'`,
        model_id: `${e.model_ID}`,
        qty: `${e.Qty}`,
        rate: `${e.rate}`,
        total: `${e.Total}`,
        Receipt_Hdr_ID: "0",
      };
    });
    let data3 = this.state.tax_raw.map((e) => {
      let ID = 0;
      if (this.state.MAinHdr != 0) {
        ID = e?.ID;
      }

      return {
        ID: `${ID}`,
        UserId: `${sessionStorage.getItem("dealerid")}`,
        Tr_No: `'${this.state.Vehicle_Receipt_NO}'`,
        HSN_Code: `'${e.HSN_Code}'`,
        TaxableAmount: `'${e.TaxableAmount}'`,
        IGST: `'${e.IGST}'`,
        IGST_AMT: `'${e.IGST_AMT}'`,
        SGST: `'${e.SGST}'`,
        SGST_AMT: `'${e.SGST_AMT}'`,
        CGST: `'${e.CGST}'`,
        CGST_AMT: `'${e.CGST_AMT}'`,
        Receipt_Hdr_ID: "0",
        TotalAmt_WithTax: `'${e.TotalAmt_WithTax}'`,
        TotalTaxAmt: `'${e.TotalTaxAmt}'`,
        HSN_Description: `'${e?.HSN_Description}'`,
      };
    });

    let combineddata = [data1, data2, data3];
    console.log(combineddata, "vhj");
    const url = Urls.python_baseurl + "/D2D_Vehicle_Invoice";
    var data = JSON.stringify(combineddata);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState(
          {
            MAinHdr: res[0][0].ID,
            double_save: false,
            cust_id_new: 0,
          },
          () => {
            this.GetVehgicleBillingDetails();
            this.MasterAPICall();
          }
        );
        alert("Record Confirmed Successfully.");
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      })
      .finally(() => {});
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Invoice No",
        field: "Invoice No",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },

      { headerName: "Invoice Date", field: "Invoice Date", flex: "1" },
      // { headerName: "Invoice Number", field: "Invoice No", flex: "1" },
      // { headerName: "Chassis No", field: "Chassis No", flex: "1" },
      // {
      //   headerName: "Vehicle Condition",
      //   field: "Vehicle Condition",
      //   flex: "1",
      // },
      { headerName: "Customer Name", field: "Dealer_Name", flex: "1" },
      { headerName: "Status", field: "Status", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              if (event.colDef.headerName === "Invoice No") {
                this.setState(
                  {
                    showFieldOnAddNew: false,
                    status: event.data.Status,
                    idforconfirm: event.data.ID,
                    MAinHdr: event.data.ID,
                    flag: "update",
                    cust_id: event.data.Customer_name,
                  },
                  () => {
                    this.Get_Customer_List();
                    this.GetVehgicleBillingDetails();
                  }
                );
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  handelDealerNoOnChange = (data) => {
    const url = Urls.python_baseurl + "/SP_GetB2BSalesChassisDetails";
    var data = JSON.stringify({
      Dealer_Id: sessionStorage.getItem("dealerid"),
      Chassis_id: `'${this.state.chassis_id_list.join(",")}'`,
      CustType: `${this.state.customer_type}`,
      CustState: `${this.state.customer_state}`,
      DealerState: sessionStorage.getItem("Dealer_state"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0][0].chassis_no == null) {
            this.setState({
              Delivery_No: "",
              tax_raw: [],
            });
          }
          for (let i = 0; i < res[0].length; i++) {
            res[0][i]["ID"] = i;
          }
          let totalamt = 0;
          for (let i = 0; i < res[1].length; i++) {
            totalamt += res[1][i].TotalAmt_WithTax
              ? res[1][i].TotalAmt_WithTax
              : 0;
          }
          let sum = 0,
            IGST = 0,
            CGST = 0,
            SGST = 0,
            TCS = 0;
          for (let i = 0; i < res[0].length; i++) {
            sum += res[0][i].rate ? res[0][i].rate : 0;
            IGST += res[0][i]?.IGST_Amt ? res[0][i]?.IGST_Amt : 0;
            CGST += res[0][i]?.CGST_Amt ? res[0][i]?.CGST_Amt : 0;
            SGST += res[0][i]?.SGST_Amt ? res[0][i]?.SGST_Amt : 0;
            TCS += res[0][i]?.TCS_Amt ? res[0][i]?.TCS_Amt : 0;
          }
          console.log(sum, IGST, CGST, SGST, TCS, "popoopp");
          this.setState({
            Gross_Amt: res[0][0].chassis_no == null ? 0 : totalamt,
            ModelRowData: res[0],
            tax_raw: res[0][0].chassis_no == null ? [] : res[1],
            model_details: res[2],

            Invoice_Date: res[0][0]?.Invoice_Date
              ? moment(res[0][0]?.Invoice_Date).format("DD-MM-YYYY")
              : "",
            LR_Date: res[0][0]?.LR_Date
              ? moment(res[0][0]?.LR_Date).format("DD-MM-YYYY")
              : this.state.LR_Date,
            TotalSum: sum,
            IGST: res[0][0].IGST_Per ? res[0][0].IGST_Per : 0,
            SGST: res[0][0].SGST_Per ? res[0][0].SGST_Per : 0,
            CGST: res[0][0].CGST_Per ? res[0][0].CGST_Per : 0,
            TCS: res[0][0].TCS_Per ? res[0][0].TCS_Per : 0,
            GrandTotalPer: IGST + CGST + SGST + TCS,
            IGST_Amount: IGST,
            SGST_Amount: SGST,
            CGST_Amount: CGST,
            TCS_Amount: TCS,
            GrandTotal_Amount: (IGST + SGST + CGST + TCS + sum).toFixed(2),
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  maxNo = (event) => {
    const url = Urls.python_baseurl + "/Lead_Increment_SP_UpdateMaxDocNo";
    var data = JSON.stringify({
      FinYear: `'${sessionStorage.getItem("fin_year")}'`,
      DocName: "'INV'",
      DealerId: sessionStorage.getItem("dealerid"),
    });
    console.log("newdata", data);
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].ID) {
            this.setState(
              {
                double_save: false,
              },
              () => {
                alert("Record Saved Successfully.");
                this.GetVehgicleBillingDetails();
                this.MasterAPICall();
              }
            );
            // alert(res[0].ID)
            // alert("Record Saved Succesfully.");
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelClear = () => {
    this.Vehicle_Receipt_No();
    this.Get_Customer_List();
    this.setState({
      tax_raw: [],
      GST_no: "",
      Dealer_cat: "",
      chassis_id_list: [],
      // consignee_name:'',
      model_details: [],
      Customer_Name: "",
      double_save: false,
      showFieldOnAddNew: true,
      flag: "insert",
      status: "Open",
      Invoice_No: "",
      Invoice_Date: "",
      DC_Date: "",
      cust_id: 0,
      DC_No: "",
      LR_Date: "",
      LR_No: "",
      PO_Date: "",
      Parking_Location: "",
      Vehicle_Condition: "",
      Model_Category: "",
      Model_Code: "",
      Model: "",
      Model_Price: "",
      Quantity: "",
      Total: "",
      Chassis_No: "",
      Engine_No: "",
      ModelRowData: [],
      GrandTotal_Amount: "",
      TCS_Amount: "",
      CGST_Amount: "",
      IGST_Amount: "",
      SGST_Amount: "",
      GrandTotalPer: "",
      TCS: "",
      CGST: "",
      SGST: "",
      IGST: "",
      TotalSum: "",
      Receipt_Date: moment(new Date()).format("DD-MM-YYYY"),
      isSelectTag: true,
      idforconfirm: 0,
      MAinHdr: 0,
      Gross_Amt: 0,

      reference_no: "",
      reference_date: "",
    });
    // window.location.reload();
  };

  modelTable() {
    const handelDropDwonChange = (data, val) => {
      data.Discount = val;
      for (let i = 0; i < this.state.ModelRowData.length; i++) {
        if (data.ID == this.state.ModelRowData[i].ID) {
          this.state.ModelRowData[i] = data;
        }
      }
      this.setState(
        {
          ModelRowData: this.state.ModelRowData,
        },
        () => {
          console.log(this.state.ModelRowData, "jkjkkj");
        }
      );
    };

    const is_Stock = (data, val) => {
      data.is_stock = val;
      for (let i = 0; i < this.state.ModelRowData.length; i++) {
        console.log("biii", this.state.ModelRowData);
        if (data.ID == this.state.ModelRowData[i].ID) {
          this.state.ModelRowData[i] = data;
        }
      }
      this.setState(
        {
          ModelRowData: this.state.ModelRowData,
        },
        () => {
          console.log(this.state.ModelRowData, "jkjkkj");
        }
      );
    };

    let columnDefs = [
      {
        headerName: "Model",
        field: "model_gr_description",
        width: "120px",
      },
      { headerName: "Model Name", field: "model_name", width: "120px" },
      { headerName: "Model Code", field: "model_code", width: "120px" },

      { headerName: "Colour", field: "colour", width: "120px" },
      // { headerName: "Tax", field: "Tax", width: "120px" },
      { headerName: "Chassis No", field: "chassis_no", width: "120px" },
      { headerName: "Vehicle No", field: "Vehicle_No", width: "120px" },
      { headerName: "Battery No", field: "BatteryNo", width: "120px" },
      { headerName: "Charger No", field: "ChargerNo", width: "120px" },
      {
        headerName: "Controller No",
        field: "Controller_Unit_No",
        width: "120px",
      },
      { headerName: "Motor No", field: "Motor_No", width: "120px" },
      { headerName: "Display No", field: "DispayNo", width: "120px" },
      { headerName: "IOT Device No", field: "IOT_Device_ID", width: "120px" },
      { headerName: "Quantity", field: "Qty", width: "120px" },
      { headerName: "Rate", field: "Rate", width: "120px" },
      // { headerName: "Discount", field: "Discount", width: "120px" },
      {
        headerName: "Discount",
        field: "Discount",
        width: "120px",
        // editable:this.state.double_save? false: true,
      },
      { headerName: "Total", field: "total", editable: false },
      // { headerName: "IGST PER", field: "IGST_Per", width: "120px" },
      // { headerName: "IGST AMT", field: "IGST_Amt", width: "120px" },
      // { headerName: "SGST PER", field: "SGST_Per", width: "120px" },
      // { headerName: "SGST AMT", field: "SGST_Amt", width: "120px" },
      // { headerName: "CGST PER", field: "CGST_Per", width: "120px" },
      // { headerName: "CGST AMT", field: "CGST_Amt", width: "120px" },
      // { headerName: "TCS PER", field: "TCS_Per", width: "120px" },
      // { headerName: "TCS AMT", field: "TCS_Amt", width: "120px" },
      // { headerName: "DC No", field: "DC_no", width: "120px",editable: true },
      // { headerName: "DC Date", field: "DC_Date", width: "120px",editable: true },
      {
        headerName: "Replaced Battery No",
        field: "ActualBatteryNo",
        width: "160px",
        editable: this.state.double_save ? false : true,
      },
      {
        headerName: "Replaced Charger No",
        field: "ActualChargerNo",
        width: "160px",
        editable: this.state.double_save ? false : true,
      },
      {
        headerName: "Replaced Controller Unit No",
        field: "ActualController_Unit_No",
        width: "160px",
        editable: this.state.double_save ? false : true,
      },
      {
        headerName: "Replaced Motor No",
        field: "ActualMotor_No",
        width: "160px",
        editable: this.state.double_save ? false : true,
      },
      {
        headerName: "Replaced Display No",
        field: "ActualDispayNo",
        width: "160px",
        editable: this.state.double_save ? false : true,
      },
      {
        headerName: "Replaced IOT Device No",
        field: "ActualIOT_Device_ID",
        width: "175px",
        editable: this.state.double_save ? false : true,
      },

      { headerName: "Tally Invoice", field: "tallyInvoice", width: "120px" },

      { headerName: "Tally Date", field: "tally_date", width: "120px" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.ModelRowData}
            enableCellTextSelection={true}
            onCellValueChanged={(params) => {
              console.log("parrr", params);
              var updatedRowData = params.api.getRowNode(params.node.id).data;
              var newValue = parseInt(params.newValue);
              if (params.colDef.field === "Discount") {
                if (newValue >= 0) {
                  if (updatedRowData.Rate < newValue) {
                    alert("Discount should be less than Rate");
                    updatedRowData.Discount = 0;
                    newValue = 0;
                  } else if (updatedRowData.Rate < 0) {
                    alert("Enter Valid Amount");
                    updatedRowData.Discount = 0;
                  }
                  let count = parseFloat(updatedRowData.Rate);
                  updatedRowData.total = parseFloat(
                    count - (newValue ? newValue : 0)
                  );
                  let newTaxRow = this.state.tax_raw;
                  let taxableAmount = newTaxRow[0].TaxableAmount;

                  if (
                    updatedRowData["PreviousDiscount"] != newValue &&
                    updatedRowData["PreviousDiscount"]
                  ) {
                    console.log(
                      updatedRowData["PreviousDiscount"],
                      newValue ? parseFloat(newValue) : 0,
                      taxableAmount,
                      "jjjkjkjk"
                    );
                    taxableAmount =
                      parseFloat(taxableAmount) +
                      (updatedRowData["PreviousDiscount"]
                        ? parseFloat(updatedRowData["PreviousDiscount"])
                        : 0);
                  }
                  updatedRowData["PreviousDiscount"] = newValue;
                  let IGST_AMT =
                    parseFloat(newTaxRow[0].IGST / 100) *
                    (+taxableAmount - +(newValue ? newValue : 0).toFixed(2));
                  let CGST_AMT =
                    parseFloat(newTaxRow[0].CGST / 100) *
                    (+taxableAmount - +(newValue ? newValue : 0).toFixed(2));
                  let SGST_AMT =
                    parseFloat(newTaxRow[0].SGST / 100) *
                    (+taxableAmount - +(newValue ? newValue : 0).toFixed(2));
                  console.log(
                    IGST_AMT,
                    CGST_AMT,
                    SGST_AMT,
                    newTaxRow[0],
                    "jkjkjk"
                  );
                  newTaxRow[0].IGST_AMT = IGST_AMT;
                  newTaxRow[0].CGST_AMT = CGST_AMT;
                  newTaxRow[0].SGST_AMT = SGST_AMT;
                  newTaxRow[0].TaxableAmount =
                    +taxableAmount - +(newValue ? newValue : 0);
                  newTaxRow[0].TotalTaxAmt = IGST_AMT + CGST_AMT + SGST_AMT;
                  newTaxRow[0].TotalAmt_WithTax =
                    newTaxRow[0].TaxableAmount + IGST_AMT + CGST_AMT + SGST_AMT;
                  let Gross_Amt = 0;
                  for (let i = 0; i < newTaxRow.length; i++) {
                    Gross_Amt += newTaxRow[i].TotalAmt_WithTax;
                  }
                  this.setState({
                    tax_raw: newTaxRow,
                    Gross_Amt: Gross_Amt,
                  });
                  console.log(newTaxRow, "hhjhj");
                } else {
                  alert("Please Enter Valid Amount");
                  updatedRowData.Discount = 0;
                }
              } else if (params.colDef.field === "DC_no") {
                var newValue = params.newValue;
                updatedRowData.DC_no = newValue;
              } else if (params.colDef.field === "DC_Date") {
                var newValue = params.newValue;
                updatedRowData.DC_Date = newValue;
              } else if (params.colDef.field === "ActualBatteryNo") {
                var newValue = params.newValue;
                updatedRowData.ActualBatteryNo = newValue;
              } else if (params.colDef.field === "ActualChargerNo") {
                var newValue = params.newValue;
                updatedRowData.ActualChargerNo = newValue;
              } else if (params.colDef.field === "ActualController_Unit_No") {
                var newValue = params.newValue;
                updatedRowData.ActualController_Unit_No = newValue;
              } else if (params.colDef.field === "ActualMotor_No") {
                var newValue = params.newValue;
                updatedRowData.ActualMotor_No = newValue;
              } else if (params.colDef.field === "ActualDispayNo") {
                var newValue = params.newValue;
                updatedRowData.ActualDispayNo = newValue;
              } else if (params.colDef.field === "ActualIOT_Device_ID") {
                var newValue = params.newValue;
                updatedRowData.ActualIOT_Device_ID = newValue;
              }
              params.api.refreshCells({ rowNodes: [params.node], force: true });
              params.api.stopEditing(); // Stop editing after the calculation
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }

  model_details() {
    let columnDefs = [
      {
        headerName: "Model",
        field: "model_gr_description",
        flex: 1,
      },
      { headerName: "Model Name", field: "model_name", flex: 1 },
      { headerName: "Model Code", field: "model_code", flex: 1 },

      { headerName: "Colour", field: "colour", flex: 1 },
      { headerName: "HSN Code", field: "HSN_Code", flex: 1 },
      { headerName: "Quantity", field: "Qty", flex: 1 },
      { headerName: "Rate", field: "rate", flex: 1 },

      { headerName: "Total", field: "Total", flex: 1 },
    ];
    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.model_details}
            enableCellTextSelection={true}
            onCellClicked={(event) => {}}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  ChangeVal(event, key) {
    this.setState({
      [key]: event.target.value,
    });
    let dealer_id = "";
    let customer_type = "";
    let customer_state = "";
    let ID = "";
    if (event.target.value != this.state.Customer_Name) {
      this.setState({
        ModelRowData: [],
        model_details: [],
        tax_raw: [],
        chassis_id_list: [],
        Gross_Amt: 0,
      });
    }
    if (key === "Customer_Name") {
      {
        dealer_id = this.state.Customer_list.filter((e) => {
          return e.Name === event.target.value;
        })[0]?.dealer_id;
        customer_type = this.state.Customer_list.filter((e) => {
          return e.Name === event.target.value;
        })[0]?.customer_type;
        customer_type = this.state.Customer_list.filter((e) => {
          return e.Name === event.target.value;
        })[0]?.customer_type;
        customer_state = this.state.Customer_list.filter((e) => {
          return e.Name === event.target.value;
        })[0]?.state;
        ID = this.state.Customer_list.filter((e) => {
          return e.Name === event.target.value;
        })[0]?.ID;
        this.setState(
          {
            dealer_id: dealer_id,
            customer_type: customer_type,
            customer_state: customer_state,
            cust_id: ID,
          },
          () => {
            this.getcustomer_dealer_data(dealer_id);
            this.Get_Chassis_List();
          }
        );
      }
    }
  }

  savFunction = () => {
    this.state.MAinHdr != 0 && this.handelSave("N");
    this.state.MAinHdr == 0 && this.handelSave();
  };
  Validate = () => {
    if (!this.state.Vehicle_Receipt_NO) {
      alert("Please Click on Add New First To Generate Invoice No.");
      return;
    } else if (this.state.Customer_Name == "") {
      alert("Please Select the Customer Name");
      return;
    } else if (this.state.LR_No == "") {
      alert("Please Enter the LR No");
      return;
    } else if (this.state.LR_Date == "") {
      alert("Please Select the LR Date");
      return;
    } else if (this.state.DC_No == "") {
      alert("Please Enter the Delivery Challan No");
      return;
    } else if (this.state.DC_Date == "") {
      alert("Please Select the Delivery Challan Date ");
      return;
    } else if (this.state.reference_no == "") {
      alert("Please Select the Reference No.");
      return;
    } else if (this.state.reference_date == "") {
      alert("Please Select the Reference Date ");
      return;
    } else if (
      this.state.chassis_id_list.length == 0 &&
      this.state.MAinHdr == 0
    ) {
      alert("Please Select the Chassis No");
      return;
    } else {
      this.savFunction();
    }
  };
  handelPrint = () => {
    this.props.history.push({
      pathname: Strings.Dealer_To_Dealer_Tax_Invoice,
      state: {
        data: this.state.ModelRowData,
        tax_raw: this.state.tax_raw,
        Customer_Name: this.state.Customer_Name,
        MAinHdr: this.state.MAinHdr,
        vehicleChallan: false,
      },
    });
  };
  VehicleReceipt = () => {
    return (
      <div>
        <div className=" row justify-content-end mr-3">
          <div className=" row">
            <button
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={() => this.handelClear()}
            >
              Add New
            </button>
            <button
              disabled={this.state.double_save}
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={() => this.Validate()}
            >
              Save
            </button>
            <button
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={() => this.handleconfirm("Y")}
              disabled={this.state.MAinHdr == 0 ? true : this.state.double_save}
            >
              Confirm
            </button>
            <button
              hidden={
                this.state.status == "Confirmed" && this.state.Customer_Name
                  ? false
                  : true
              }
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
              onClick={() => this.handelPrint()}
            >
              Print
            </button>
            {/* <button
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right", clear: "both" }}
            >
              Cancel
            </button> */}
          </div>
        </div>
        <br></br>
        <div className="form-row">
          <div className=" form-group col-md-3">
            <label>Invoice No.</label>
            <input
              readOnly
              disabled
              value={this.state.Vehicle_Receipt_NO}
              className=" form-control "
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label>Invoice Date {this.required()}</label>
            <input
              readOnly
              disabled
              value={this.state.Receipt_Date}
              className=" form-control "
            ></input>
          </div>
          <div className=" form-group col-md-3">
            <label>Reference No. {this.required()}</label>
            {this.state.MAinHdr !== 0 ? (
              <input
                disabled
                value={this.state.dtd_po_no}
                className=" form-control"
              />
            ) : (
              <select
                value={this.state.reference_no}
                className=" form-control "
                onChange={(event) => {
                  if(event.target.value==0){
                    this.setState({
                      message:"",
                      reference_no:""
                    })
                    return
                  }
                  let reference_date = this.state.Reference_Option[0].filter(
                    (e) => e.ID == event.target.value
                  )[0]["PO Date"];
                  let model_details=this.state.Reference_Option[1].filter(
                    (e) => e.PO_ID == event.target.value
                  );
                  this.setState(
                    {
                      reference_no: event.target.value,
                      reference_date: reference_date,
                      dealer_id: this.state.Reference_Option[0].filter(
                        (e) => e.ID == event.target.value
                      )[0].dealer_id,
                      customer_type: this.state.Reference_Option[0].filter(
                        (e) => e.ID == event.target.value
                      )[0].customer_type,
                      customer_state: this.state.Reference_Option[0].filter(
                        (e) => e.ID == event.target.value
                      )[0].state,
                      Customer_Name: this.state.Reference_Option[0].filter(
                        (e) => e.ID == event.target.value
                      )[0].dealer_name,
                      cust_id: this.state.Reference_Option[0].filter(
                        (e) => e.ID == event.target.value
                      )[0].dealer_id,
                      message:""
                    },
                    () => {
                      this.getcustomer_dealer_data(
                        sessionStorage.getItem("dealerid")
                      );
                      this.Get_Chassis_List();
                      let message = `${this.state.Customer_Name} hase raised a Po of`;
                      model_details.map((e,index) => {
                        message =
                          message + `${(model_details.length-1==index&&model_details.length>1)?" and":","} ${e.Model_Name} of Quantity ${e.Qty}\n`;
                      });
                      this.setState(
                        {
                          message: message,
                        }
                      );
                    }
                  );
                }}
              >
                <option value={0}>Select</option>
                {this.state.Reference_Option[0]?.map((e) => {
                  return <option value={e?.ID}>{e?.["PO No"]}</option>;
                })}
              </select>
            )}
          </div>
          <div className=" form-group col-md-3">
            <label>Reference Date</label>
            <input
              readOnly
              disabled
              value={this.state.reference_date}
              className=" form-control "
            ></input>
          </div>
          {/* <div className=" col-3">
            <label>Customer Name{this.required()}</label>
            <select
              onChange={(event) => this.ChangeVal(event, "Customer_Name")}
              value={this.state.Customer_Name}
              className="form-control "
              disabled={(this.state.MAinHdr == 0 ? false : true)||!this.state.reference_no}
            >
              <option>Select</option>
              {this.state.Customer_list.map((e) => {
                return <option value={e.Name}>{e.Name}</option>;
              })}
            </select>
          </div> */}
          <div className=" col-3">
            <label>Customer Name{this.required()}</label>
            <input
              value={this.state.Customer_Name}
              className="form-control "
              disabled
            ></input>
          </div>
          {/* <div className="form-group col-md-3">
            <label>Consignee Name </label>
            <input
            disabled
              onChange={(e) =>
                this.setState({ consignee_name: e.target.value })
              }
              value={this.state.consignee_name}
              className=" form-control "
              // disabled={this.state.double_save}

              onKeyPress={(e) => {
                const pattern = /[A-Za-z\s]/ // Regular expression pattern for alphabetic characters
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault(); // Prevent input if it's not alphabetic
                }
              }}
            ></input>
          </div> */}
          <div className="form-group col-md-3">
            <label>Dealer Category</label>
            <input
              onChange={(e) => this.setState({ Dealer_cat: e.target.value })}
              value={this.state.Dealer_cat}
              className=" form-control "
              disabled={true}
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label>GST No.</label>
            <input
              onChange={(e) => this.setState({ GST_no: e.target.value })}
              value={this.state.GST_no}
              className=" form-control "
              disabled={true}
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label>LR No. {this.required()}</label>
            <input
              onKeyPress={(e) => {
                const pattern = /[A-Za-z0-9]/;
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => this.setState({ LR_No: e.target.value })}
              value={this.state.LR_No}
              className=" form-control "
              disabled={this.state.double_save}
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label>LR Date {this.required()}</label>
            <input
              onChange={(e) => this.setState({ LR_Date: e.target.value })}
              value={this.state.LR_Date}
              className=" form-control "
              type="date"
              disabled={this.state.double_save}
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label>Delivery Challan No. {this.required()}</label>
            <input
              onChange={(e) => this.setState({ DC_No: e.target.value })}
              value={this.state.DC_No}
              className=" form-control "
              // type="number"
              onKeyPress={(e) => {
                const pattern = /[A-Za-z0-9]/;
                const inputChar = String.fromCharCode(e.charCode);
                if (!pattern.test(inputChar)) {
                  e.preventDefault();
                }
              }}
              disabled={this.state.double_save}
            ></input>
          </div>
          <div className="form-group col-md-3">
            <label>Delivery Challan Date {this.required()}</label>
            <input
              onChange={(e) => this.setState({ DC_Date: e.target.value })}
              value={this.state.DC_Date}
              className=" form-control "
              type="date"
              disabled={this.state.double_save}
            ></input>
          </div>
          <div className="form-group col-md-3 mt-4">
            <label
              hidden={this.state.MAinHdr == 0 ? false : true}
              style={{ color: "blue", fontWeight: "bold", marginTop: "8px" }}
              onClick={() => {
                if (this.state.Customer_Name && this.state.reference_no) {
                  $("#myModal2").modal("show");
                } else {
                  alert("Please Select Customer Name and Refernce No. First");
                }
              }}
              className="clickable "
            >
              Select Chassis
            </label>
          </div>
        </div>
      </div>
    );
  };
  TAXDetails = () => {
    return (
      <table style={{ width: "70%" }}>
        <tr>
          <th style={{ fontWeight: "600" }}></th>
          <th style={{ fontWeight: "600" }}>Total Amount</th>
          <th style={{ fontWeight: "600" }}>IGST</th>
          <th style={{ fontWeight: "600" }}>SGST</th>
          <th style={{ fontWeight: "600" }}>CGST</th>
          <th style={{ fontWeight: "600" }}>TCS</th>
          <th style={{ fontWeight: "600" }}>Grand Total</th>
        </tr>
        <tr>
          <td>
            <label>%</label>
          </td>
          <td>
            {/* <input
              className="form-control mt-1"
              style={{ width: "140px", height: "30px" }}
              disabled
              value={0}
            /> */}
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.IGST}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.SGST}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.CGST}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.TCS}
            />
          </td>
          <td>
            {/* <input
              className="form-control"
              style={{ width: "140px" }}
              disabled
            /> */}
          </td>
        </tr>
        <tr>
          <td>
            <label>Amount</label>
          </td>
          <td>
            <input
              disabled
              style={{ width: "140px" }}
              className="form-control mt-1"
              value={this.state.TotalSum}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.IGST_Amount}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.SGST_Amount}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.CGST_Amount}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.TCS_Amount}
            />
          </td>
          <td>
            <input
              disabled
              className="form-control"
              style={{ width: "140px" }}
              value={this.state.GrandTotal_Amount}
            />
          </td>
        </tr>
      </table>
    );
  };
  required = () => {
    return <span style={{ color: "red" }}>*</span>;
  };
  formatNumber = (params) => {
    if (typeof params.value === "number") {
      return params.value.toFixed(2);
    }
  };
  data = () => {
    let columnDefs = [
      {
        headerName: "HSN Code",
        field: "HSN_Code",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      {
        headerName: "HSN Description",
        field: "HSN_Description",
        flex: "2",
      },
      {
        headerName: "Taxable Amount",
        field: "TaxableAmount",
        aggFunc: "sum",
        editable: (params) =>
          this.state.double_save ? false : params.data.HSN_Description !== "",
        // valueParser: "Number(newValue)",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },

      {
        headerName: "IGST",
        headerClass: "custom-header",
        children: [
          { headerName: "IGST(%)", field: "IGST", width: "100px" },
          {
            headerName: "Amount",
            field: "IGST_AMT",
            width: "100px",
            valueFormatter: this.formatNumber,
          },
        ],
      },
      {
        headerName: "SGST",
        headerClass: "custom-header",
        children: [
          { headerName: "SGST(%)", field: "SGST", width: "100px" },
          {
            headerName: "Amount",
            field: "SGST_AMT",
            width: "100px",
            valueFormatter: this.formatNumber,
          },
        ],
      },
      {
        headerName: "CGST",
        headerClass: "custom-header",
        children: [
          { headerName: "CGST(%)", field: "CGST", width: "100px" },
          {
            headerName: "Amount",
            field: "CGST_AMT",
            width: "100px",
            valueFormatter: this.formatNumber,
          },
        ],
      },

      {
        headerName: "Total Tax Amt",
        field: "TotalTaxAmt",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        valueFormatter: this.formatNumber,
      },
      {
        headerName: "Total Amt With Tax",
        field: "TotalAmt_WithTax",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        aggFunc: "sum",
        valueFormatter: this.formatNumber,
      },
    ];
    var gridOptions = {
      pivotFooter: true, // Enable pivot footer
      groupIncludeFooter: true,
    };
    return (
      <div style={{ overflowX: "scroll" }}>
        <div
          className="ag-theme-balham"
          style={{ padding: "0", width: "1400px" }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            // pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            gridOptions={gridOptions}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.tax_raw}
            enableCellTextSelection={true}
            suppressAggFuncInHeader={true}
            pivotPanelShow={"always"}
            suppressHorizontalScroll={true}
            onCellValueChanged={(params) => {
              let updatedRowData = params.api.getRowNode(params.node.id).data;
              var allowedChars = /^[0-9.]+$/;
              if (allowedChars.test(params.newValue)) {
                let IGST_Per = updatedRowData.IGST;
                let SGST_Per = updatedRowData.SGST;
                let CGST_Per = updatedRowData.CGST;
                let newValue = parseFloat(params.newValue);
                if (newValue >= 0) {
                  if (params.colDef.field === "TaxableAmount") {
                    if (newValue > this.state.tax_raw[0].TaxableAmount) {
                      alert("Enter Valid Amount");
                      updatedRowData.TaxableAmount = 0;
                      updatedRowData.IGST_AMT = 0;
                      updatedRowData.CGST_AMT = 0;
                      updatedRowData.SGST_AMT = 0;
                      updatedRowData.TotalTaxAmt = 0;
                      updatedRowData.TotalAmt_WithTax = 0;
                    } else {
                      let IGST_AMT = parseFloat(IGST_Per / 100) * newValue;
                      let CGST_AMT = parseFloat(CGST_Per / 100) * newValue;
                      let SGST_AMT = parseFloat(SGST_Per / 100) * newValue;
                      updatedRowData.IGST_AMT = IGST_AMT;
                      updatedRowData.CGST_AMT = CGST_AMT;
                      updatedRowData.SGST_AMT = SGST_AMT;
                      updatedRowData.TotalTaxAmt =
                        IGST_AMT + SGST_AMT + CGST_AMT;
                      updatedRowData.TotalAmt_WithTax =
                        IGST_AMT + SGST_AMT + CGST_AMT + newValue;
                      this.setState({
                        Gross_Amt:
                          parseFloat(this.state.Gross_Amt) +
                          IGST_AMT +
                          SGST_AMT +
                          CGST_AMT +
                          newValue,
                      });
                    }
                  }
                } else {
                  alert("Enter Valid Amount");
                  if (params.colDef.field === "TaxableAmount") {
                    updatedRowData.TaxableAmount = 0;
                    updatedRowData.IGST_AMT = 0;
                    updatedRowData.CGST_AMT = 0;
                    updatedRowData.SGST_AMT = 0;
                    updatedRowData.TotalTaxAmt = 0;
                    updatedRowData.TotalAmt_WithTax = 0;
                  }
                }
              } else {
                alert("Enter Valid Amount");
                if (params.colDef.field === "TaxableAmount") {
                  updatedRowData.TaxableAmount = 0;
                  updatedRowData.IGST_AMT = 0;
                  updatedRowData.CGST_AMT = 0;
                  updatedRowData.SGST_AMT = 0;
                  updatedRowData.TotalTaxAmt = 0;
                  updatedRowData.TotalAmt_WithTax = 0;
                }
              }

              params.api.refreshCells({ rowNodes: [params.node], force: true });
              params.api.stopEditing();
              this.getgrandtotal();
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  };
  ShowChassiList() {
    let columnDefs = [
      {
        headerName: "",
        field: "",
        width: "60px",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
        checkboxSelection: true,
      },
      {
        headerName: "Chassis No",
        field: "Chassis_no",
        flex: "1",
        // cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },

      { headerName: "Vehicle No", field: "Vehicle_No", flex: "1" },
      { headerName: "Battery No", field: "BatteryNo", flex: "1" },
      { headerName: "Charger No", field: "ChargerNo", flex: "1" },

      { headerName: "Model Name", field: "model_name", flex: "1" },
    ];
    return (
      <div>
        <div
          className="ag-theme-balham"
          style={{ padding: "0", marginTop: "30px" }}
        >
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            overlayLoadingTemplate={this.state.overlayLoadingTemplate}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.Chassis_list}
            enableCellTextSelection={true}
            onCellClicked={(event) => {}}
            onRowSelected={(event) => {
              if (event.node.isSelected()) {
                console.log("pp", event.node.data.Chassis_Id);
                this.setState(
                  {
                    chassis_id_list: [
                      ...this.state.chassis_id_list,
                      event.node.data.Chassis_Id,
                    ],
                  },
                  () => {
                    this.handelDealerNoOnChange(this.state.chassis_id_list);
                    console.log("jhsshjp", this.state.chassis_id_list);
                  }
                );
              } else {
                if (this.state.chassis_id_list.length > 0) {
                  this.setState(
                    {
                      chassis_id_list: this.state.chassis_id_list.filter(
                        (item) => item !== event.node.data.Chassis_Id
                      ),
                    },
                    () => {
                      console.log("jhsshjp", this.state.chassis_id_list);
                      this.handelDealerNoOnChange(this.state.chassis_id_list);
                    }
                  );
                }
              }
            }}
            onFirstDataRendered={({ api }) => {
              const selectedChassisSet = new Set(
                this.state.ModelRowData.map((item) => item.chassis_no)
              );

              api.forEachNode((node) => {
                const isSelected = selectedChassisSet.has(node.data.Chassis_no);
                node.setSelected(isSelected);
              });
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  chassisListPopUp = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(33, 37, 41, 0.8)",
          marginLeft: "-1.5%",
        }}
        class="modal"
        id="myModal2"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                onClick={() => $("#myModal2").modal("hide")}
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.ShowChassiList()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  getgrandtotal = () => {
    return (
      this.state.ModelRowData.length > 0 &&
      this.state.ModelRowData.reduce((prev, curr) => prev.total + curr.total)
    );
  };
  render() {
    return (
      <div>
        {this.state.message && (
          <Notification
            message={`${this.state.message}`}
            type={ERROR}
            margin={50}
            alert_for={"sales_invoice"}
          />
        )}
        <h4 className="heading_text">Sales Invoice </h4>
        <div class="card mt-5">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Sales Invoice List
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">{this.masterTable()}</div>
          </div>
        </div>
        <div class="card ">
          <div class="card-header" id="headingFour">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                Sales Invoice
              </button>
            </h5>
          </div>
          <div
            id="collapseFour"
            class="collapse show "
            aria-labelledby="headingFour"
          >
            <div class="card-body">{this.VehicleReceipt()}</div>
          </div>
        </div>
        <div class="card ">
          <div class="card-header" id="headingSeven">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collaoseSeven"
                aria-expanded="true"
                aria-controls="collaoseSeven"
              >
                Model Details
              </button>
            </h5>
          </div>
          <div
            id="collaoseSeven"
            class="collapse show "
            aria-labelledby="headingSeven"
          >
            <div class="card-body">{this.model_details()}</div>
          </div>
        </div>
        <div class="card ">
          <div class="card-header" id="headingSeven1">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collaoseSeven1"
                aria-expanded="true"
                aria-controls="collaoseSeven1"
              >
                Chassis Details
              </button>
            </h5>
          </div>
          <div
            id="collaoseSeven1"
            class="collapse show "
            aria-labelledby="headingSeven1"
          >
            <div class="card-body">{this.modelTable()}</div>
          </div>
        </div>
        <div class="card ">
          <div class="card-header" id="headingEight">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseEight"
                aria-expanded="true"
                aria-controls="collapseEight"
              >
                Tax Details
              </button>
            </h5>
          </div>
          <div
            id="collapseEight"
            class="collapse show "
            aria-labelledby="headingEight"
          >
            <div class="card-body">
              {/* {this.TAXDetails()} */}

              {this.data()}
              <div>
                <label
                  style={{
                    fontWeight: "600",
                    marginTop: "10px",
                    fontSize: "13px",
                  }}
                >
                  Grand Total : {parseFloat(this.state.Gross_Amt).toFixed(2)}
                </label>
              </div>
            </div>
          </div>
        </div>
        {this.chassisListPopUp()}
      </div>
    );
  }
}
