import React, { Component } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export class DynamicMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuData: [
        {
          heading: "Company Master",
          link: "",
          subheading: [
            {
              heading: "Vehicle",
              link: "",
              subheading: [
                {
                  heading: "Model Category",
                  link: "/Model_Category",
                  subheading: [],
                },
                { heading: "Model Code", link: "/Model_Code", subheading: [] },
                {
                  heading: "Model Price",
                  link: "/Model_Price",
                  subheading: [],
                },
                { heading: "Plan/Depot", link: "/Plan_Depot", subheading: [] },
                { heading: "State", link: "/State", subheading: [] },
              ],
            },
            {
              heading: "Comman",
              link: "",
              subheading: [{ heading: "Chassis", link: "", subheading: [] }],
            },
          ],
        },
        {
          heading: "Dealer Master",
          link: "",
          subheading: [
            {
              heading: "Common",
              link: "",
              subheading: [
                {
                  heading: "Empolyee",
                  link: "",
                  subheading: [{ heading: "page1", link: "", subheading: [] }],
                },
                { heading: "Customer", link: "", subheading: [] },
                { heading: "Supplier", link: "", subheading: [] },
                { heading: "Tax", link: "", subheading: [] },
                { heading: "Customer Support", link: "", subheading: [] },
              ],
            },
          ],
        },
        {
          heading: "Transaction",
          subheading: [
            {
              heading: "Vehicle",
              link: "",
              subheading: [
                {
                  heading: "Empolyee",
                  link: "",
                  subheading: [
                    { heading: "page1", link: "", subheading: [] },
                    { heading: "page2", link: "", subheading: [] },
                  ],
                },
                { heading: "Customer", link: "", subheading: [] },
                { heading: "Supplier", link: "", subheading: [] },
                { heading: "Tax", link: "", subheading: [] },
                { heading: "Customer Support", link: "", subheading: [] },
              ],
            },
            {
              heading: "Vehicle",
              link: "",
              subheading: [
                {
                  heading: "Call Ticket",
                  link: "",
                  subheading: [
                    { heading: "page1", link: "", subheading: [] },
                    { heading: "page2", link: "", subheading: [] },
                  ],
                },
                { heading: "Customer", link: "", subheading: [] },
                { heading: "Supplier", link: "", subheading: [] },
                { heading: "Tax", link: "", subheading: [] },
                { heading: "Customer Support", link: "", subheading: [] },
              ],
            },
          ],
        },
      ],

      ActiveHeading: null,
      ActiveSubHeading: null,
      ActiveSubHeading2: null,
    };
  }

  displayMenu = () => {
    const getLine = () => {
      return (
        <div
          style={{
            width: "2px",
            height: "100% ! important",
            backgroundColor: "var(--main-bg-color)",
          }}
        ></div>
      );
    };
    return (
      <div
        style={{ cursor: "pointer", fontSize: "16px" }}
        className=" row mb-5"
      >
        {this.state.menuData?.map((e, index) => {
          return (
            <div
              id={e.heading}
              className="ml-3"
              onMouseEnter={() =>
                this.setState({
                  ActiveHeading: index,
                  ActiveSubHeading2: null,
                  ActiveSubHeading: null,
                })
              }
              onMouseLeave={() =>
                setTimeout(() => {
                  this.setState({ ActiveHeading: null });
                }, 200)
              }
            >
              {e.heading}
              {this.state.ActiveHeading == index && (
                <div style={{ position: "absolute", zIndex: "10" }}>
                  <div
                    style={{
                      zIndex: "10",
                      marginLeft: "40px",
                      marginTop: "-3px",
                      marginBottom: "-3px",
                      fontWeight: "700",
                    }}
                  >
                    ^
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "200px",
                      height: "fit-content",
                    }}
                    className=" card px-1"
                  >
                    {getLine()}
                    <div>
                      {e?.subheading?.map((e1, index2) => {
                        return (
                          <div
                            onMouseEnter={() =>
                              this.setState({ ActiveSubHeading: index2 })
                            }
                            onMouseLeave={() =>
                              setTimeout(() => {
                                this.setState({ ActiveSubHeading: null });
                              }, 200)
                            }
                            className="py-2"
                            id={e1.heading}
                            style={{ width: "180px" }}
                          >
                            {e1.link == "" ? (
                              <span className=" ml-1 heading_text_menue">
                                {e1.heading}
                              </span>
                            ) : (
                              <span className=" ml-1 heading_text_menue">
                                <Link style={{color:"black", textDecoration:"none"}} to={e1?.link}>{e1.heading}</Link>
                              </span>
                            )}
                            <span
                              style={{
                                fontSize: "12px",
                                position: "absolute",
                                right: "0",
                                marginRight: "5px",
                                color: "grey",
                                fontWeight: "500",
                              }}
                              className="ml-4"
                            >
                              {e1.subheading.length > 0
                                ? this.state.ActiveSubHeading == index2
                                  ? "^"
                                  : ">"
                                : ""}
                            </span>
                            {this.state.ActiveSubHeading == index2 && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  position: "absolute",
                                  zIndex: "10",
                                  height: "fit-content",
                                  width: "200px",
                                  marginLeft: "97%",
                                  marginTop: "-15.5%",
                                }}
                                className=" card px-1"
                              >
                                {getLine()}
                                <div>
                                  {e1?.subheading?.map((e2, index3) => {
                                    return (
                                      <div
                                        id={e2.heading}
                                        onMouseEnter={() =>
                                          this.setState({
                                            ActiveSubHeading2: index3,
                                          })
                                        }
                                        onMouseLeave={() =>
                                          setTimeout(() => {
                                            this.setState({
                                              ActiveSubHeading2: null,
                                            });
                                          }, 200)
                                        }
                                        className="py-2"
                                        style={{ width: "180px" }}
                                      >
                                        {e2?.link == "" ? (
                                          <span className=" ml-1 heading_text_menue">
                                            {e2?.heading}
                                          </span>
                                        ) : (
                                          <span className=" ml-1 heading_text_menue">
                                            <Link style={{color:"black", textDecoration:"none"}} to={e2?.link}>
                                              {e2.heading}
                                            </Link>
                                          </span>
                                        )}
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            position: "absolute",
                                            right: "0",
                                            marginRight: "5px",
                                            color: "grey",
                                            fontWeight: "500",
                                          }}
                                          className="ml-4"
                                        >
                                          {e2.subheading.length > 0
                                            ? this.state.ActiveSubHeading2 ==
                                              index3
                                              ? "^"
                                              : ">"
                                            : ""}
                                        </span>
                                        {this.state.ActiveSubHeading2 ==
                                          index3 && (
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              position: "absolute",
                                              zIndex: "10",
                                              height: "fit-content",
                                              width: "200px",
                                              marginLeft: "97%",
                                              marginTop: "-15.5%",
                                            }}
                                            className=" card px-1"
                                            onMouseLeave={() =>
                                              this.setState({
                                                ActiveSubHeading2: null,
                                                ActiveHeading: null,
                                                ActiveSubHeading: null,
                                              })
                                            }
                                          >
                                            {getLine()}
                                            <div>
                                              {e2?.subheading?.map(
                                                (e3, index3) => {
                                                  return (
                                                    <div
                                                      id={e3.heading}
                                                      className=" py-2"
                                                    >
                                                      {e3.link == "" ? (
                                                        <span className=" ml-1 heading_text_menue">
                                                          {e3.heading}
                                                        </span>
                                                      ) : (
                                                        <span className=" ml-1 heading_text_menue">
                                                          <Link style={{color:"black", textDecoration:"none"}} to={e3.link}>
                                                            {e3.heading}
                                                          </Link>
                                                        </span>
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    return <div className="">{this.displayMenu()}</div>;
  }
}
