import React from "react";
import "./styles.css";
import "../../containers/color.css";
import Urls from "../../../helpers/Urls";
import Strings from "../../../helpers/Strings";
import { Link } from "react-router-dom";
import PersonLogo from "../Images/user.png";
// import logo from "../Images/Logo.png";
import logo from "../Images/Joy_connect_Logo.jpg";
const $ = window.$;
const bcrypt = require("bcryptjs");

export class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      oldText: "",
      newText: "",
      confirmNewText: "",
    };
    this._onBlur = this._onBlur.bind(this);
  }

  checkuser = async () => {
    let query = new URL(Urls.File_upload + "/CheckUser");

    if (Strings.isDev) {
      process.env.NODE_ENV == "development" && console.log(query);
    }
    return fetch(query.href, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "user-id": sessionStorage.getItem("user-id"),
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache",
        Accept: "application/json",
      },
      // body: JSON.stringify({ query: query })
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          if (res[0].UserInfo) {
            if (bcrypt.compareSync(this.state.oldText, res[0].UserInfo)) {
              this.updateText(1);
            } else {
              this.showAlert();
            }
          } else {
            this.showAlert();
          }
        } else {
          this.showAlert();
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  showAlert = () => {
    alert("You have entered wrong old password");
  };

  updateText = (flag) => {
    // event.preventDefault();
    let userId = localStorage.getItem("UserId");
    var salt = bcrypt.genSaltSync(10);
    var EncryptedOldText = bcrypt.hashSync(this.state.oldText, salt);
    var EncryptedNewText = bcrypt.hashSync(this.state.newText, salt);
    $("#myModal10").modal("hide");
    let query =
      `mutation{
            UpdateUserInfoNew(Id:` +
      userId +
      ` OldPassword:"` +
      EncryptedOldText +
      `" NewPassword:"` +
      EncryptedNewText +
      `" ForgotOrNot:1,Login:${flag})
          }`;
    if (Strings.isDev) {
      process.env.NODE_ENV == "development" && console.log(query);
    }
    fetch(Urls.base_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "user-id": sessionStorage.getItem("user-id"),
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache",
        Accept: "application/json",
      },
      body: JSON.stringify({ query: query }),
    })
      .then((res) => res.json())
      .then((res) => {
        let message = res.data.UpdateUserInfoNew;
        alert(message);
        window.location.reload();
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  validation() {
    let errors = {};
    let formIsValid = true;
    debugger;
    if (this.state.oldText == "") {
      formIsValid = false;
      this.refs.oldText.focus();
      alert("Please Enter your Old Password.");
    } else if (this.state.newText == "") {
      formIsValid = false;
      this.refs.newText.focus();
      alert("Please Enter your New Password.");
    } else if (this.state.newText != "") {
      let pattern =
        /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/;
      if (!pattern.test(this.state.newText)) {
        formIsValid = false;
        this.refs.newText.focus();
        alert("Please Enter Valid New Password.");
      } else if (this.state.confirmNewText == "") {
        formIsValid = false;
        this.refs.confirmNewText.focus();
        alert("Please Enter your Confirm Password.");
      } else if (this.state.confirmNewText != "") {
        let _pattern =
          /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/;
        if (!_pattern.test(this.state.confirmNewText)) {
          formIsValid = false;
          this.refs.confirmNewText.focus();
          alert("Please Enter Valid Confirm Password.");
        } else if (this.state.confirmNewText != this.state.newText) {
          formIsValid = false;
          alert("Your New Password and Confirmation Password do not Match.");
        }
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  _onBlur() {
    this.validation();
  }

  onHandleChange = (event, name) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  logoutSession = () => {
    let query = new URL(Urls.File_upload + "/Logout");

    // let query = `{
    //     Logout {
    //       Logout
    //     }
    //   }`
    if (Strings.isDev) {
      process.env.NODE_ENV == "development" && console.log(query.href);
    }
    fetch(query.href, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "user-id": this.state.EmailId,
        "Cache-Control": "no-cache, no-store",
        Expires: 0,
        Pragma: "no-cache",
        Accept: "application/json",
      },
      // body: JSON.stringify({ query: query })
    })
      .then((res) => res.json())
      .then((res) => {})
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  logout = () => {
    // this.logoutSession()
    setTimeout(() => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }, 50);
  };
  siderbar() {
    $('#sidebar').toggleClass('active');
    if ($('#sidebar').attr('class') == "active") {
        $('#Col-12').removeClass("col-10");
        $('#Col-12').addClass('col-12');
    }
    else { $('#Col-12').removeClass("col-12"); $('#Col-12').addClass('col-10'); }
  }

  handleOnClick = () => {
    let isOutbound = sessionStorage.getItem("isOutbound");
    let role_desc = sessionStorage.getItem("role_desc");
    console.log(role_desc === "Payment Poster" && isOutbound === "true");
    if (role_desc === "Payment Poster" && isOutbound === "true") {
      return;
    } else if (isOutbound && isOutbound == "true") {
      sessionStorage.setItem("isOutbound", false);
      this.props.OutToInboundJump();
    }
  };

  render() {
    const InitialScreen =
      sessionStorage.getItem("InitialScreen") == "null"
        ? "Dashboard"
        : "Dashboard";
    return (
      <div>
        <div className="header_container">
          <div style={{width:"fit-content", float:"right"}}>
            <h2 className="header_text">
              <Link to={Strings[InitialScreen]} onClick={this.handleOnClick}>
                <b style={{ color: "white" }}>
                  <img
                    src={logo}
                    style={{ width: "105px", height:"48px",marginTop: "-10px" }}
                  />
                </b>
              </Link>
            
            </h2>
            <label
              style={{ marginTop:"5px",color: "white", marginLeft: "20px", fontSize: "11px" }}
            >
              Powered by DealerPaaS
            </label>
          </div>
          {/* <div style={{ marginLeft: "20px" }}>
                        <img src={require('../Images/header_logo.png')} style={{ width: '70px', backgroundColor: 'white' }} />
                        <span className="Sidebar_drawer" style={{ position: 'absolute', paddingTop: '10px' }} onClick={this.siderbar} id="sidebarCollapse">&#9776; </span>
                    </div>
                    <label style={{ color: "white", marginLeft: "20px", fontSize: "10px", marginBottom: '0px' }}>Powered by HiPaaS</label> */}

          {sessionStorage.getItem("UserId") ? (
            <div
              className="dropdown_logout"
              style={{ float: "right", marginTop: "14px", marginRight:"14px" }}
            >
              <img src={PersonLogo} style={{ width: "25px" }} />
              <div className="dropdown-content">
                {/* <a onClick={this.changeText} data-toggle="modal" data-target="#myModal10">Change Password</a>  */}
                 <Link to={"/"+Strings.Help}>Help</Link>
                <a onClick={this.logout}>Logout</a>
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            style={{
              color: "white",
              marginTop: "14px",
              marginLeft: "10px",
              fontWeight: "500",
            }}
          >
            { sessionStorage.getItem("dealer_name")?sessionStorage.getItem("dealer_name"):sessionStorage.getItem("Username")} (
            {sessionStorage.getItem("Dealer_Code")?sessionStorage.getItem("Dealer_Code"):sessionStorage.getItem("Employee_Code")})
            <span className="Sidebar_drawer ml-3" onClick={this.siderbar} id="sidebarCollapse">&#9776; </span>
          </div>
        </div>
        <div
          className="modal"
          id="myModal10"
          role="dialog"
          aria-labelledby="myModalLabel2"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 style={{ color: "var(--white-color)" }}>Change Password</h4>
              </div>
              <div className="modal-body">
                <div className="form-group col-12">
                  <label className="list-header2">Old Password</label>
                  <input
                    ref="oldText"
                    onChange={(event) => this.onHandleChange(event, "oldText")}
                    name="OldPassword"
                    type="password"
                    className="form-control textInput"
                    placeholder="Enter Old Password"
                    autoComplete="new-password"
                    value={this.state.oldText}
                  />
                  {/* <div className="errorMsg">{this.state.errors.oldText}</div> */}
                </div>
                <div className="form-group col-12">
                  <label className="list-header2">New Password</label>
                  <input
                    ref="newText"
                    onChange={(event) => this.onHandleChange(event, "newText")}
                    name="OldPassword"
                    type="password"
                    className="form-control textInput"
                    placeholder="Enter New Password"
                    autoComplete="off"
                    value={this.state.newText}
                  />
                  {/* <div className="errorMsg">{this.state.errors.newText}</div> */}
                </div>
                <div className="form-group col-12">
                  <label className="list-header2">Confirm New Password</label>
                  <input
                    ref="confirmNewText"
                    onChange={(event) =>
                      this.onHandleChange(event, "confirmNewText")
                    }
                    name="OldPassword"
                    type="password"
                    className="form-control textInput"
                    placeholder="Confirm New Password"
                    autoComplete="off"
                    value={this.state.confirmNewText}
                  />
                  {/* <div className="errorMsg">{this.state.errors.confirmNewText}</div> */}
                </div>

                <div className="row">
                  <div className="col" style={{ marginLeft: "25%" }}>
                    <button
                      className="btn btn-display"
                      onClick={() => {
                        if (this.validation()) {
                          this.checkuser();
                        }
                      }}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-display"
                      style={{ marginLeft: "20px" }}
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
