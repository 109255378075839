import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import {CSVLink} from 'react-csv';
const bcrypt = require("bcryptjs");

export class Stock_Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      Active: "",
      Empolyee_Type_option:[],
      Model_Category:[],
      Model_Code:[],
      csvDataClaimInformation:[],
      exportloader:false,
      ModelCategory:"",
      ModelCode:"",
      District_id: "",
      Region_id: "",
      State_id: "",
      TalukaID:""
    };
    this.myRef = React.createRef();
  }
  componentDidMount() {
    this.MasterAPICall();
    this.GetModelCategory()
  }
  onSelect = (event, name) => {
    this.setState({
      Active: event.target.options[event.target.selectedIndex].text,
    });
  };
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/Get_dealer_data_for_report";
    var data = JSON.stringify({
        Dealer_ID: sessionStorage.getItem("dealerid"),
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res,"pop")
        if (res && res.length > 0) {
          
          this.setState({
            rowData: res,
            District_id:res[0].Dealer_District_ID,
            Region_id:res[0].Dealer_Region_ID,
            State_id:res[0].Dealer_State_ID,
            TalukaID:res[0].Dealer_Taluka_ID
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  GetModelCategory = () => {
    const url = Urls.python_baseurl + "/ModelGroup_GetModelGroup";
    var data = JSON.stringify({
      id: "null",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            Model_Category: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  ChangeVal(event, key) {
    console.log("ghg",key,event.target.value);
    this.setState({
      [key]: event.target.value,
    });
    if (key == "ModelCategory") {
        const url =
          Urls.python_baseurl + "/DataFromModelCode_GetModelCode_FromModelGroup";
        var data = JSON.stringify({
          ModelGroup: parseInt(event.target.value),
        });
        fetch(url, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: data,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res && res.length > 0) {
              this.setState({
                Model_Code: res,
                model_typecode: res[0].Type,
              });
            }
          })
          .catch((err) => {
            process.env.NODE_ENV == "development" && console.log(err);
          });
      }
}
GetCategory = (event) => {
    let row = [];
    this.state.Model_Category.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element["Model Group"]}</option>);
    });
    return row;
  };
  getTransactionData = async () => {
    await this.ExportClaimInformation()
}
ExportClaimInformation = () => {
    this.setState({exportloader:true})
    const url = Urls.python_baseurl + "/Generate_Report";

    var data = JSON.stringify({
        "Dealer_ID":`'${sessionStorage.getItem("dealerid")}'`,
        "RegionID": `'${this.state.Region_id}'`,
        "StateID": `'${this.state.State_id}'`,
        "DistrictID": `'${this.state.District_id}'`,
        "TalukaID": `'${this.state.TalukaID}'`,
        "ModelCode": this.state.ModelCode!=""? `'${this.state.ModelCode}'`:"'All'",
        "DealerCategory": "'All'",
        "ModelGroup": this.state.ModelCategory!=""?`'${this.state.ModelCategory}'`:"'All'",
        "DealerCode":`'${sessionStorage.getItem("Dealer_Code")}'`, 
        "StartDate": "''",
        "EndDate": "''",
        "Report_type": "Vehiclestock",
        "SalesType":"''"
    });

    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
            const link = document.createElement("a");
            link.href = res.download_url;
            link.setAttribute("download", 'Stock_Report.xlsx');
            document.body.appendChild(link);
      
            link.click();
            document.body.removeChild(link);
          } else {
            console.error("File Path not Found", );
          }
       
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      }).finally(()=>{
        this.setState({exportloader: false,ModelCode:"",ModelCategory:""
        })
    });
}
  _renderTopbar = () => {
    return (
        <div className='row'>
            <div className="form-group col-md-3">
            <label htmlFor="model_group">Model Group</label>
            <select
              value={this.state.ModelCategory}
              onChange={(e) => this.ChangeVal(e, "ModelCategory")}
              id="model_group"
              className=" form-control "
            >
              <option value="0">All</option>
              {this.GetCategory()}
            </select>
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="model_id">Model Code</label>
            <select
              value={this.state.ModelCode}
              onChange={(e) => this.ChangeVal(e, "ModelCode")}
              id="model_id"
              className=" form-control "
            >
              <option value="0">All</option>
              {this.state.Model_Code.map((e) => {
                return <option value={e["Model code"]}>{e["Model code"]}</option>;
              })}
            </select>
          </div>
            <div className="form-group col-3">
                <div className="list-dashboard mt-2"></div>
                <button type="submit" onClick={this.getTransactionData} className="btn Opertion_btn clickable" style={{ marginRight: "12px", marginTop: "16px", height: "32px", marginLeft: this.props.btn_width ? "0px" : "5px" }}>
                    {this.state.exportloader && <span class="spinner-grow spinner-grow-sm" style={{ color: 'unset', marginRight: '5px' }} role="status" aria-hidden="true"></span>}Download Report</button>
            </div>
        </div>
        
    )
}
  render() {
    return (
      <div>
        <h4 className="heading_text" style={{marginLeft:"0px"}}>Stock Report</h4>
        <br></br><br></br>
        <div>
            {this._renderTopbar()}
        </div>
        {/* <CSVLink
                        data={this.state.csvDataClaimInformation}
                        filename={ 'Vehicle_Stock.xlsx'}
                        ref={this.myRef}
                        target='_blank'
                    ></CSVLink> */}
      </div>
    );
  }
}
