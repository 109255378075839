import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import moment from "moment";
const $ = window.$;
const bcrypt = require("bcryptjs");

export class Labor_Code extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      dealer_name_option:[],
      Labor_Code:"",
      Labor_Name:"",
      ModelGroup:"" ,
      LaborGroup:"", 
      From_date:"",
      To_date:"",
      Hours:""
    };
  }
  componentDidMount() {
    this.MasterAPICall();
    
  }

  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetFormControlData";
    var data = JSON.stringify({
        "Menu_ID": "'2'"
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          // res=res[0]
          this.setState({
              rowData: res,
              Labor_Code: res[0]["Labor Code"],
              Labor_Name: res[0]["Labor Name"],
              ModelGroup: res[0].ModelGroup,
              LaborGroup: res[0]["Labor Group"],
              Active: res[0].Active,
                         
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };

  masterTable() {
    let columnDefs = [
      { headerName: "Labor Code", field: "Labor Code",flex:1 ,cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },},
      {
        headerName: "Labor Name",
        field: "Labor Name",
        flex:1,
        
      },
      { headerName: "Model Group", field: "ModelGroup", flex:1 },
      { headerName: "Labor Group", field: "Labor Group", flex:1},
      { headerName: "Active", field: "Active", flex:1},
      

    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) =>
            {
              if (event.colDef.field == "Labor Code") {
                  this.setState({
                      Active: event.data.Active,
                      Labor_Code: event.data["Labor Code"],
                      Labor_Name: event.data["Labor Name"],
                      ModelGroup: event.data.ModelGroup,
                      LaborGroup: event.data["Labor Group"],
                      id: event.data.ID,
                })
              }
            }
            }
          ></AgGridReact>
        </div>
      </div>
    );
  }

  header = () => {
      return (
          <div className="row">
              <div className=" col-3 mt-3">
                  <label>Labor Code</label>
                  <input
                      autoComplete='off'
                      onChange={(e) => this.setState({ Labor_Code: e.target.value })}
                      value={this.state.Labor_Code}
                      disabled
                      className=" form-control"
                  ></input>
              </div>
              <div className=" col-3 mt-3">
                  <label>Labor Name</label>
                  <input
                      autoComplete='off'
                      onChange={(e) => this.setState({ Labor_Name: e.target.value })}
                      value={this.state.Labor_Name}
                      disabled
                      className=" form-control"
                  ></input>
              </div>
              <div className=" col-3 mt-3">
                  <label>Model Group</label>
                  <input
                      autoComplete='off'
                      onChange={(e) => this.setState({ ModelGroup: e.target.value })}
                      value={this.state.ModelGroup}
                      disabled
                      className=" form-control"
                  ></input>
              </div>
              <div className=" col-3 mt-3">
                  <label>Labor Group</label>
                  <input
                      autoComplete='off'
                      onChange={(e) => this.setState({ LaborGroup: e.target.value })}
                      value={this.state.LaborGroup}
                      disabled
                      className=" form-control"
                  ></input>
              </div>
              <div className=" col-3 mt-3">
                  <label>Labor Group</label>
                  <input
                      autoComplete='off'
                      onChange={(e) => this.setState({ LaborGroup: e.target.value })}
                      value={this.state.LaborGroup}
                      disabled
                      className=" form-control"
                  ></input>
              </div>
              <div className="col-3 mt-3">
                  <label>Active</label>
                  <select disabled
                      className="form-control list-dashboard"
                      value={this.state.Active}
                      onChange={(event) => {
                          this.onSelect(event, "Active");
                      }}
                  >
                      <option selected value="0">Select</option>
                      <option value="Y">Y</option>
                      <option value="N">N</option>
                  </select>
              </div>
          </div>

      );
  };

  clickNavigation=()=>{
    const url = Urls.python_baseurl + "/Employe_Save_SP_Save_Employee";
    var data = JSON.stringify({
      "ID": this.state.id,
      "dealerID": parseInt(sessionStorage.getItem("dealerid")),
      "HOBr_id": parseInt(sessionStorage.getItem("dealerid")),
      "p1": `'${this.state.emp_name}'`,
      "p2": this.state.Empolyee_Type_option.filter(e=>e.Etype==this.state.emp_type)[0]?.ID,
      "active": `'${this.state.Active}'`,
      "Contact_No": `'${this.state.contact_no}'`,
      "Email_ID": `'${this.state.email}'`,
      "Date_of_joining": `'${this.state.Date_of_joining}'`,
      "Employee_Code": `'${sessionStorage.getItem("Dealer_Code")+"-"+this.state.Empl_code}'`,
    });
        fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
           if(this.state.id){
            alert("Record Updated Scuccesfully")
           }else{
            alert("Record Saved Succesfully")
           }
           window.location.reload()
          //  this.MasterAPICall()
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  }
  Validate = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emailisvalid=emailRegex.test(this.state.email)
    if(this.state.Empl_code == "" ){
      alert("Please Enter the Employee Code.")
      return
    }else
     if(this.state.emp_name == "" ){
      alert("Please Enter the Employee Name.")
      return
    }
    else if(this.state.emp_type == "" && this.state.emp_type == 0){
      alert("Please Select the Designation.")
      return
    } else if(this.state.contact_no == ""){
      alert("Please Select the Mobile No.")
      return
    }
    else if(this.state.Date_of_joining == ""){
      alert("Please Select the Date Of Joining")
      return
    }else 
    if(!emailisvalid&&this.state.email!=""){
      alert("Please Enter Valid Email Id.")
      return
    } 
    else if(this.state.Active == "0" || this.state.Active == "" || this.state.Active == undefined){
      alert("Please Select the Active")
      return
    }
    else
    if (this.state.contact_no != "") {
        if (this.state.contact_no.match(/\d/g).length !== 10) {
          alert("Please Enter Valid Mobile No.")
          return
      } 
    }
       this.clickNavigation()
  }
  close() {
    var fileInput = document.getElementById("fileInput");
    fileInput.value = "";
    this.setState({
      Type:"0",
      selectedFile:""
    })
    $("#payment_error_modal_4").modal("hide");

  }
  errorDialog4 = () => {
    return (
      <div
        className="modal"
        id="payment_error_modal_4"
        style={{ marginLeft: "25%",marginTop:"35px" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog-view" style={{ maxWidth: "50%", right: "30%" }}>
          <div className="error-dialog">
            <button
              type="button"
              class="close close-btn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.close();
              }}
            >
              <span class="" aria-hidden="true">
                &times;
              </span>
            </button>
            <div id="exTab1">
              <div class="tab-content clearfix">
                <div class="tab-pane active" id="1a">
                  {this.csvHeaderview()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  csvHeaderview() {
      return (
          <div>
              <div className="" style={{ marginTop: "15px" }}>
                  <div class="form-group  col-md-12">
                      <div
                          className="form-group row"
                          style={{
                              // marginLeft: "2px",
                              marginTop: "15px",
                              // alignItems: "baseline",
                          }}
                      >
                          <input

                              style={{ marginLeft: "12px", width: "81px", marginTop: "28px" }}
                              key={this.state.theInputKey || ""}
                              id="fileInput"
                              type="file"
                              onChange={(e) => this.setState({ selectedFile: e.target.files[0], result: e.target.result })}
                          />
                          <span style={{ margin: "0 12px 0 3px", marginTop: "30px" }}>{this.state.selectedFile && this.state.selectedFile.name ? this.state.selectedFile.name : "No File Chosen"}</span>

                          {/* </div> */}
                          <div
                              className="form-group col-3"
                              role="group"
                              aria-label="Basic mixed styles example"
                          >
                              <button
                                  disabled={this.state.double_save}
                                  type="button"
                                  className="btn"
                                  style={{
                                      backgroundColor: "var(--main-bg-color)",
                                      color: "white",
                                      marginTop: "20px",
                                      marginLeft: "5px",
                                  }}
                                  onClick={() => this.onFileUpload()}
                              >
                                  {this.state.loaderforupload && (
                                      <span
                                          className="spinner-grow spinner-grow-sm"
                                          style={{ color: "unset", marginRight: "5px", }}
                                          role="status"
                                          aria-hidden="true"
                                      ></span>
                                  )}
                                  Upload
                              </button>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      );
  }
  testclickNavigation2 = () => {
    $("#payment_error_modal_4").modal("show");
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Labor Code</h4><br></br>
        <div class="row justify-content-end ">
          {/* <button
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={this.addnew}
          >
            Clear
          </button>
          <button
            type="submit"
            className="btn Opertion_btn clickable "
            onClick={this.Validate}
          >
            Save
          </button> */}
          {/* <button
            // hidden={sessionStorage.getItem("UserRole") == "10"?false:true}
            // disabled={this.state.double_save}
            type="submit"
            className="btn Opertion_btn clickable mr-5"
            onClick={this.testclickNavigation2}
          >
            Upload File
          </button> */}
        </div>
        <div
          class="form-group col-md-12 mt-2"
        >
          
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Labor Code List
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">{this.masterTable()}</div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Labor Code Details
                </button>
              </h5>
            </div>

            <div
              id="collapseTwo"
              class="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">{this.header()}</div>
            </div>
          </div>
          
        </div>
        {this.errorDialog4()}
      </div>
    );
  }
}
