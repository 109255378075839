import React from "react";
import Urls from "../../../helpers/Urls";
export class ListFilter extends React.Component {
  constructor(props) {
    console.log(props,"sdsfhd")
    super(props);
    this.state = {
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      dealeroption: [],
      dealerid: this.props.dealerid,
      showtimerange:this.props?.showtimerange
    };
  }
  handelChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.refreshScreen(
          this.state.startDate,
          this.state.endDate,
          this.state.dealerid
        );
      }
    );
  };
  componentDidMount() {
    if (sessionStorage.getItem("UserRole") !== "1") {
      this.getDealer();
    }
  }
  getDealer = () => {
    const url = Urls.python_baseurl + "/SP_Get_Dealer";
    var data = JSON.stringify({
      Dealer_ID: `'${sessionStorage.getItem("dealerid")}'`,
      RegionID: "'All'",
      StateID: "'All'",
      DistrictID: "'All'",
      TalukaID: "'All'",
      Dealer_Category: "'All'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res, "pop");
        if (res && res.length > 0) {
          this.setState({
            dealeroption: res,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  render() {
    return (
      <div className=" row ">
        {this.state.showtimerange&&<div className="form-group col-2">
          <div className="list-dashboard">Start Date</div>
          <input
            type="date"
            className="form-control list-dashboard"
            value={this.state?.startDate}
            name="startDate"
            onChange={(e) => this.handelChange(e)}
            max={this.state?.endDate}
          />
        </div>}
        {this.state.showtimerange&&<div className="form-group col-2">
          <div className="list-dashboard">End Date</div>
          <input
            type="date"
            className="form-control list-dashboard"
            value={this.state?.endDate}
            name="endDate"
            onChange={(e) => this.handelChange(e)}
            min={this.state?.startDate}
          />
        </div>}
        {sessionStorage.getItem("UserRole") !== "1" && (
          <div className="form-group col-2">
            <div className="list-dashboard">Dealer</div>
            <select
              className="form-control list-dashboard"
              name="dealerid"
              onChange={(e) => this.handelChange(e)}
              value={this.state.dealerid}
            >
              <option>All</option>
              {this.state.dealeroption.map((e) => (
                <option value={e.ID}>{e.Name}</option>
              ))}
            </select>
          </div>
        )}
      </div>
    );
  }
}
