import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css"
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";

const bcrypt = require("bcryptjs");

export class Tax extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      Aggrid_ClaimLineData: "",
      showerror: "",
      type:"",
      name:"",
      address1:"",
      address2:"",
      address3:"",
      city:"",
      pincode:"",
      State:"",
      Region:"",
      Country:"",
      Mobileno:"",
      Currency:"",
      delar_name:"",
      delar_code:"",
      tax_description:"",
      tax_percentage:"",
      tax_type:"",
      Category:"",
      Active:"",
      tax_applicable:"",
      is_service_tax:"",
      

    };
  }
  componentDidMount() {
    //this.MasterAPICall()
  }
  onSelect = (event, key) => {
    this.setState({
        [key]: event.target.options[event.target.selectedIndex].value,
    })
  }
 

 
  Taxheader = () => {
    return (
      <div className=" card p-3 mt-4">
        <div className=" row">
        <div className=" col-3">
            <label htmlFor="active">Region</label>
            <select className="form-control list-dashboard" id="Active"
              value={this.state.Region}
              onChange={(event) => {
                this.onSelect(event, 'Region')
              }}>
              <option selected value=""></option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>

          </div>
          <div className=" col-3">
            <label htmlFor="active">State</label>
            <select className="form-control list-dashboard" id="Active"
              value={this.state.State}
              onChange={(event) => {
                this.onSelect(event, 'State')
              }}>
              <option selected value=""></option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>

          </div>
          <div className=" col-3">
            <label htmlFor="model-c">Currency</label>
            <input onChange={(e) => this.setState({ Currency: e.target.value })}
              value={this.state.Currency}
              id="model-c"
              className=" form-control"

            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="model-c">Dealer Name</label>
            <input onChange={(e) => this.setState({ delar_name: e.target.value })}
              value={this.state.delar_name}
              id="model-c"
              className=" form-control"

            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="model-c">Dealer Code</label>
            <input onChange={(e) => this.setState({ delar_code: e.target.value })}
              value={this.state.delar_code}
              id="model-c"
              className=" form-control"

            ></input>
          </div>
        </div>
      </div>
    );
  };
  taxmaster_details = () => {
    return (
      <div className="px-3">
        <div className=" row mt-2">
          <div className=" col-3">
            <label htmlFor="model-c">Tax Description</label>
            <input onChange={(e) => this.setState({ tax_description: e.target.value })}
              value={this.state.tax_description}
              id="model-c"
              className=" form-control"

            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="model-c">Tax Percentage</label>
            <input onChange={(e) => this.setState({ tax_percentage: e.target.value })}
              value={this.state.tax_percentage}
              id="model-c"
              className=" form-control"

            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="active">Tax Type</label>
            <select className="form-control list-dashboard" id="Active"
              value={this.state.tax_type}
              onChange={(event) => {
                this.onSelect(event, 'tax_type')
              }}>
              <option selected value=""></option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>

          </div>
          <div className=" col-3">
            <label htmlFor="active">Category</label>
            <select className="form-control list-dashboard" id="Active"
              value={this.state.Category}
              onChange={(event) => {
                this.onSelect(event, 'Category')
              }}>
              <option selected value=""></option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>

          </div>
        </div>
        <div className=" row mt-2">
        <div className=" col-3">
            <label htmlFor="active">Tax Applicable on</label>
            <select className="form-control list-dashboard" id="Active"
              value={this.state.tax_applicable}
              onChange={(event) => {
                this.onSelect(event, 'tax_applicable')
              }}>
              <option selected value=""></option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>

          </div>
          <div className=" col-3">
            <label htmlFor="active">Is Service Tax</label>
            <select className="form-control list-dashboard" id="Active"
              value={this.state.is_service_tax}
              onChange={(event) => {
                this.onSelect(event, 'is_service_tax')
              }}>
              <option selected value=""></option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>

          </div>
          <div className=" col-3">
            <label htmlFor="active">Active</label>
            <select className="form-control list-dashboard" id="Active"
              value={this.state.Active}
              onChange={(event) => {
                this.onSelect(event, 'Active')
              }}>
              <option selected value=""></option>
              <option value="Y">Y</option>
              <option value="N">N</option>
            </select>

          </div>
        </div>
        </div>
    );
  };

  taxmasterTable() {
    let columnDefs = [
      { headerName: "Tax Description", field: "Model Category", flex: "1", cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" } },
      { headerName: "Tax Percentage", field: "Model Category", flex: "1", cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" } },
      { headerName: "Tax Type", field: "Model Category", flex: "1", cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" } },
      { headerName: "Tax Applicable on", field: "Model Category", flex: "1", cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" } },
      { headerName: "From Date", field: "Active", flex: "1" },
      { headerName: "To Date", field: "Active", flex: "1" },
      {
        headerName: "", field: "pencil", width: 50,flex:1, cellRenderer: (data) => {
             if(data){
                return '<i class="fas fa-pencil-alt"></i>'
             }
        }, cellStyle: { cursor: 'pointer' }, pinned: 'right'
    },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) =>{
                if(event.colDef.field == "pencil"){
                this.setState({
                  customer_code: event.data.Active,
                  name:event.data.name,
                  address1:event.data.address1,
                  pincode:event.data.pincode,
                  Mobileno:event.data.Mobileno,
                })
            }
            }
                
            }
          ></AgGridReact>
        </div>
      </div>
    );
  }
  addnew= () => {

    this.setState({
        State:"",
      Region:"",
      Country:"",
      Mobileno:"",
      Currency:"",
      delar_name:"",
      delar_code:"",
      tax_description:"",
      tax_percentage:"",
      tax_type:"",
      Category:"",
      Active:"",tax_applicable:"",
      is_service_tax:"",
    })
  }

  render() {
    return (
      <div>
        <h4 className="heading_text">Tax</h4>
        <div class="row justify-content-end ">
          <button type="submit" className="btn Opertion_btn clickable"  onClick={this.addnew}>
            Add New
          </button>
          <button type="submit" className="btn Opertion_btn clickable mr-5"  onClick={this.clickNavigation}>
            Save
          </button>
        </div>
            <div class="form-group col-md-12 mt-2">
                {/* <div class="card">
                    <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                            <button
                                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                class="btn"
                                data-toggle="collapse"
                                data-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                            >
                             LOCATION DETAILS
                            </button>
                        </h5>
                    </div>

                    <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                    >
                        <div class="card-body">{this.Taxheader()}</div>
                    </div>
                </div> */}
                <div class="card">
                    <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                            <button
                                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                class="btn"
                                data-toggle="collapse"
                                data-target="#collapseTwo"
                                aria-expanded="true"
                                aria-controls="collapseTwo"
                            >
                               Tax Master Details
                            </button>
                        </h2>
                    </div>
                    <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div class="card-body">
                            {this.taxmaster_details()}
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header" id="headingThree">
                        <h2 class="mb-0">
                            <button
                                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                class="btn"
                                data-toggle="collapse"
                                data-target="#collapseThree"
                                aria-expanded="true"
                                aria-controls="collapseThree"
                            >
                                 Tax Master List
                            </button>

                        </h2>
                    </div>

                    <div id="collapseThree" class="collapse " aria-labelledby="headingThree" data-parent="#accordionExample">

                        <div class="card-body">{this.taxmasterTable()}</div>
                    </div>
                </div>
               
            </div>
      </div>
    );
  }
}
