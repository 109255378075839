import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import Strings from "../../../helpers/Strings";
import moment from "moment";
import HiPaaSLogo from "../../components/Images/HiPaaS_logo.png";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";

const bcrypt = require("bcryptjs");

export class Plan_Depot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      Active: "",
      Depot_Code:"",
      Depot_Flag:"",
      Depot_Name:"",
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      rowData: [],
    };
  }
  componentDidMount() {
    this.MasterAPICall()
  }
MasterAPICall = ()=>{
  const url = Urls.python_baseurl + "/sp_DepotMaster_display";
  var data = JSON.stringify({
      "id": "0"
     
  });
  fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: data,
  })
    .then((res) => res.json())
    .then((res) => {
      console.log(res);
      if(res && res.length>0){
        this.setState({
          rowData:res,
          Active: res[0].Active,
                Depot_Code: res[0].Depo_Code,
                Depot_Name: res[0].Depo_name,
                Depot_Flag: res[0].Depo_flag,
                Depo_State: res[0].State,
        })
      }

    })
    .catch(err => {
        process.env.NODE_ENV == 'development' && console.log(err)
    });
}
  masterTable() {
    let columnDefs = [
      { headerName: "Depot Code", field: "Depo_Code", width:"130px",cellStyle:{color:"var(--main-bg-color)", cursor:"pointer"} },
      { headerName: "Depot Name", field: "Depo_name", flex: "2" },
      { headerName: "Depot Flag", field: "Depo_flag", flex: "1" },
      { headerName: "State", field: "State", flex: "1" },
      { headerName: "Active", field: "Active", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) =>{
              if(event.colDef.field==="Depo_Code"){
                this.setState({
                  Active: event.data.Active,
                  Depot_Code: event.data.Depo_Code,
                  Depot_Name: event.data.Depo_name,
                  Depot_Flag: event.data.Depo_flag,
                  Depo_State: event.data.State,
                })
              }
              }
            }
             
          ></AgGridReact>
        </div>
      </div>
    );
  }
  header = () => {
    return (
      <div className=" card p-3 mt-4">
        <div className=" row">
        <div className=" col-3">
            <label htmlFor="Depot_Code">Depot Code</label>
            <input
              value={this.state.Depot_Code}
              id="Depot_Code"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Depot_Name">Depot Name</label>
            <input
              value={this.state.Depot_Name}
              id="Depot_Name"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Depot_Flag">Depot Flag</label>
            <input
              value={this.state.Depot_Flag}
              id="Depot_Flag"
              className=" form-control "
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Depo_State">State</label>
            <input
              value={this.state.Depo_State}
              id="Depo_State"
              className=" form-control"
              readOnly
            ></input>
          </div>
        </div>
        <div className=" row mt-2">
        <div className=" col-3">
            <label htmlFor="Active">Active</label>
            <input
              value={this.state.Active}
              id="Active"
              className=" form-control"
              readOnly
            ></input>
          </div>
          </div>
      </div>
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Depot</h4><br></br>
        <div class="card">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Depot List
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div class="card-body">{this.masterTable()}</div>
          </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Depot Details
                </button>
              </h5>
            </div>

            <div
              id="collapseTwo"
              class="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">{this.header()}</div>
            </div>
          </div>
      </div>
    );
  }
}
