import React from "react";
import "../Files/files-styles.css";
import Urls from "../../../helpers/Urls";

const $ = window.$;
let PartData = [];

export class Upload_Dealer_Data extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paginationPageSize: 20,
      domLayout: 'autoHeight',
      autoGroupColumnDef: {
        headerName: 'Group',
        minWidth: 170,
        field: 'athlete',
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: 'cell-wrap-text',
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: 'never',
      rowGroupPanelShow: 'never',
      pivotPanelShow: 'never',
      selectedFile:'',
      Template_Type:"",
      loaderforupload: false,
      btn_disable:false,
      customer_List:[],
      Dealer_name:"0",
      Dealer:""
    }

  }
  componentDidMount() {
     this.get_delar_details()
  }
  get_delar_details= () => {
    const url = Urls.python_baseurl + "/SP_Get_DealerList";
      var data = JSON.stringify({
        State_ID: "0",
        DepartmentID:"0"
      });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
            this.setState({
              customer_List: res,
            })
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  }
  _fileUpload = () => {
    return (
      <div>
        <input
          
          style={{ marginLeft: "12px", width: "80px" }}
          key={this.state.theInputKey || ""}
          id="fileInput"
          type="file"
          onChange={(e) => this.setState({ selectedFile: e.target.files[0], result: e.target.result })}
        />
        <span style={{ margin: "0 12px 0 3px" }}>{this.state.selectedFile && this.state.selectedFile.name ? this.state.selectedFile.name : "No File Chosen"}</span>


      </div>
    )
  }
  validation_check() {
    this.setState({
      btn_disable: true
    })
    let formIsValid = true;
    
     if (this.state.Template_Type == "") {
      formIsValid = false;
      alert("Please Select Template Type.");
      this.setState({
        btn_disable: false
      })
      return false
    }
    else if (this.state.Dealer_name == "0" || this.state.Dealer_name=="") {
      formIsValid = false;
      alert("Please Select your Dealer.");
      this.setState({
        btn_disable: false
      })
      return false
    }
    else if (this.state.selectedFile == "") {
      formIsValid = false;
      alert("Please Select your File.");
      this.setState({
        btn_disable: false
      })
      return false
    } 


    return formIsValid;
  }
  
  onFileUpload = (e) => {
   let check=this.validation_check()  
   if(check){
    this.setState({ loaderforupload: true })
    let data1 = new FormData();
    data1.append("file", this.state.selectedFile);
    data1.append("template_type", this.state.Template_Type);
    data1.append("dealerid", this.state.Dealer_name);
    data1.append("delaername", this.state.Dealer);
    console.log(data1, "sujjj")
    if (data1) {
    var requestOptions = {
    method: "POST",
    body: data1,
    redirect: "follow",
    };

    fetch(
    Urls.python_baseurl + "/Upload_Dealer_Data",
    requestOptions
    )
        .then((response) => response.json())
    .then((result) =>  {

      if(result.Status){
        alert(result.msg)
      }else{
        alert(result.msg)
      }
   
            
    }
        )
        .catch(err => {
          console.log(err)
        }).finally((r) => {
          // window.location.reload()   
          var fileInput = document.getElementById("fileInput");
        fileInput.value = "";            
    this.setState({
      selectedFile:"",
    loaderforupload: false,
    btn_disable: false,
    Template_Type:"0",
    Dealer_name:"0",
    Dealer:""
    })
    })
    }
  }
  }

  onSelect = (event, name) => {
    if(name=="Template_Type"){
      this.setState({
        Template_Type: event.target.options[event.target.selectedIndex].value,
      });
    }
    
    if (name == "Dealer_name") {
      this.setState({
        Dealer_name: event.target.options[event.target.selectedIndex].value,
        Dealer: event.target.options[event.target.selectedIndex].text,
      })
    }
  };
  customer_List = (event) => {
    let row = [];
    this.state.customer_List.forEach((element) => {
      if (!element) {
        return;
      }
      row.push(<option value={element.ID}>{element.Dealer_name}</option>);
    });
    return row;
  };
  ChangeVal(event, key) {
    this.setState({
      [key]:event.target.value,
    });
  }
  header = () => {
    return (
      <div className=" card p-3">
        <div className="row">
          <div className="col-3 mt-2">
            <label htmlFor="active">Upload Data Template <span style={{ color: "red" }}>*</span></label>
            <select
              className="form-control list-dashboard"
              id="Active"
              value={this.state.Template_Type}
              onChange={(event) => {
                this.onSelect(event, "Template_Type");
              }}
            >
              <option value="0">Select</option>
              
              <option value="vehicle_stock_template">Vehicle Stock Template</option>
              <option value="dealer_employee_template">Dealer Employee Template</option>
              <option value="dealer_customer_template">Dealer Customer Template</option>
            </select>
          </div>
          <div class="col-3 mt-2">
            <label>Dealer List <span style={{ color: "red" }}>*</span></label>
            <select
              className="form-control "
              value={this.state.Dealer_name}
              onChange={(event) => this.onSelect(event, "Dealer_name")}
            >
              <option value="0">Select</option>
              {this.customer_List()}
            </select>
          </div>
          <div style={{marginTop:"20px" }} className="col-3">
            <label></label>
            {this._fileUpload()}
          </div>

          <div style={{marginTop:"35px"}} class="row justify-content-end ">
          <button  type="submit" disabled={this.state.btn_disable}
            className="btn Opertion_btn clickable" style={{height:'30px'}} onClick={this.onFileUpload}> 
           {this.state.loaderforupload && <span class="spinner-grow spinner-grow-sm" style={{color:'unset', marginRight:'5px'}}role="status" aria-hidden="true"></span>}File Upload</button>
              
        </div>
      
        </div>
        <br></br>
        <a style={{width:'150px',color:"blue",cursor:"pointer"}}  onClick={this.handleDownload}>Download Sample File</a>
      </div>
    );
  };
  handleDownload = () => 
  {

    if (this.state.Template_Type == 'dealer_customer_template') {
     let fileUrl = '/DealerCustomerMaster_Sample.xlsx';
      window.location.href = fileUrl;
    }
    else if (this.state.Template_Type == 'dealer_employee_template') {
      let fileUrl = '/DealerEmployeeMaster_Sample.xlsx';
       window.location.href = fileUrl;
     }
    else if (this.state.Template_Type == 'vehicle_stock_template') {
      let fileUrl = '/VehicleStockMaster_Sample.xlsx';
       window.location.href = fileUrl;
     }
     else
     {
       alert('Please select the Template Type.')
     }
    
  }
  render() {
    return (

      <div>
        <h4 className="heading_text">Upload Dealer Data</h4>

        <div
          class="form-group col-md-12 mt-3"
        >
          {this.header()}
          <div class="card">
            {/* <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Employee List
                </button>
              </h5>
            </div> */}

            {/* <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">{this._fileUpload()}</div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
