import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import moment from "moment";

const bcrypt = require("bcryptjs");
const $ = window.$;
export class Part extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      part_no: "",
      part_name: "",
      part_group: "Local Part",
      superseded_part: "",
      block_for_purchase: "Y",
      min_order_qty: "",
      location: "",
      active: "",
      overlayLoading: false,
      disable_field:true,
      id:0
    };
  }
  componentDidMount() {
    this.MasterAPICall();
  }
  MasterAPICall = () => {
    this.setState({
      overlayLoading:true
    })
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      FromDate: "''",
      ToDate: "''",
      ModelPart: "'01'",
      DealerId:  `'${sessionStorage.getItem("dealerid")}'`,
      SqlFor: "'PartMaster'",
      HOBrID: "0",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          rowData: res,
          part_no: res[0]["Part No"],
          part_name: res[0]["Part Name"],
          part_group: "Local Part",
          base_unit:res[0]["Base Unit"],
          superseded_part: res[0]["Base Unit"],
          block_for_purchase: "Y",
          min_order_qty: res[0]["Min Order Qty"],
          active: res[0].Active,
          overlayLoading:false
        });
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  masterTable() {
    let columnDefs = [
      {
        headerName: "Part No",
        field: "Part No",
        flex: 1,
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      {
        headerName: "Part Name",
        field: "Part Name",
        width: "250px",
      },
      { headerName: "Base Unit", field: "Base Unit", flex: 1 },
      { headerName: "Group", field: "Group", flex: 1 },
      {
        headerName: "Min Order Quantity",
        field: "Min Order Qty",
        flex: 1,
      },
      { headerName: "Active", field: "Active", flex: 1 },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            overlayLoading={this.state.overlayLoading}
            overlayNoRowsTemplate="<i class='fas fa-spinner fa-spin'></i>"
            onCellClicked={(event) => {
              if (event.colDef.field == "Part No") {
                this.setState({
                  part_no: "",
                  part_name: "",
                  part_group: "",
                  base_unit:"",
                  superseded_part: "",
                  block_for_purchase: "Y",
                  min_order_qty: "",
                  active: "",
                  location:"",
                  disable_field:true,
                  id:event?.data?.ID
                },()=>{
                  const data={
                    ID:event?.data?.ID
                  }
                  this.getPartData(data)
                })
                
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  getPartData = (data) => {
    const url = Urls.python_baseurl + "/SP_ListAll_PartMaster";
    var data = JSON.stringify({
      "pID": `'${data?.ID}'`,
      "Group_code": "'01'",
      "DealerID":  `'${sessionStorage.getItem("dealerid")}'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            part_no: res[0].parts_no,
            part_name: res[0].part_name,
            part_group: res[0].Gr_Name,
            base_unit: res[0]["Base Unit"],
            superseded_part: res[0]["Superseded Part"],
            block_for_purchase: res[0]["Blk_fr_purch"],
            min_order_qty: res[0].MOQ,
            location: res[0].Location,
            active: res[0].Active,
          });
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  handelAddNew = () => {
    this.setState({
      // part_no: "",
      // part_name: "",
      // part_group: "Local Part",
      // base_unit: "",
      // superseded_part: "",
      location:"",
      // block_for_purchase: "",
      // min_order_qty: "",
      // location: "",
      // active: "",
      // disable_field:false
    })
  };
  header = () => {
    return (
      <div className="row">
        <div className="form-group col-md-3">
          <label htmlFor="part_no">Part No.</label>
          <input
            id="part_no"
            className=" form-control "
            value={this.state.part_no}
            disabled
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="part_name">Part Name</label>
          <input
            id="part_name"
            className=" form-control "
            value={this.state.part_name}
            disabled
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="base_unit">Base Unit</label>
          <input
            id="base_unit"
            className=" form-control "
            value={this.state.base_unit}
            disabled
            placeholder="Ltrs."
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="part_group">Part Group</label>
          <input
            id="part_group"
            className=" form-control "
            value={this.state.part_group}
            disabled
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="superseded_part">Superseded Part</label>
          <input
            id="superseded_part"
            className=" form-control "
            value={this.state.superseded_part}
            disabled
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="block_for_purchase">Block For Purchase</label>
          <input
            id="block_for_purchase"
            className=" form-control "
            value={this.state.block_for_purchase}
            disabled
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="min_order_qty">Min Order Quantity</label>
          <input
            id="min_order_qty"
            className=" form-control "
            value={this.state.min_order_qty}
            disabled
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="location">Location</label>
          <input
            onChange={(e) => this.setState({ location: e.target.value })}
            id="location"
            className=" form-control "
            value={this.state.location}
          ></input>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="active">Active</label>
          <select
            id="active"
            className=" form-control "
            value={this.state.active}
            disabled
          >
            <option>Y</option>
            <option>N</option>
          </select>
        </div>
      </div>
    );
  };
  openPopUp = () => {
    $("#payment_error_modal_4").modal("show");
  };
  closePopUP() {
    var fileInput = document.getElementById("fileInput");
    fileInput.value = "";
    this.setState({
      Type: "0",
      selectedFile: "",
    });
    $("#payment_error_modal_4").modal("hide");
  }
  PopUP = () => {
    return (
      <div
        className="modal"
        id="payment_error_modal_4"
        style={{ marginLeft: "25%", marginTop: "35px" }}
        role="dialog"
        aria-labelledby="myModalLabel2"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div
          className="modal-dialog-view"
          style={{ maxWidth: "50%", right: "30%" }}
        >
          <div className="error-dialog">
            <button
              type="button"
              class="close close-btn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.closePopUP();
              }}
            >
              <span class="" aria-hidden="true">
                &times;
              </span>
            </button>
            <div id="exTab1">
              <div class="tab-content clearfix">
                <div class="tab-pane active" id="1a">
                  {this.uploadFilePopUp()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  uploadFilePopUp() {
    return (
      <div>
        <div className="" style={{ marginTop: "15px" }}>
          <div class="form-group  col-md-12">
            <div
              className="form-group row"
              style={{
                marginTop: "15px",
              }}
            >
              <input
                style={{ marginLeft: "12px", width: "81px", marginTop: "28px" }}
                key={this.state.theInputKey || ""}
                id="fileInput"
                type="file"
                onChange={(e) =>
                  this.setState({
                    selectedFile: e.target.files[0],
                    result: e.target.result,
                  })
                }
              />
              <span style={{ margin: "0 12px 0 3px", marginTop: "30px" }}>
                {this.state.selectedFile && this.state.selectedFile.name
                  ? this.state.selectedFile.name
                  : "No File Chosen"}
              </span>
              <div
                className="form-group col-3"
                role="group"
                aria-label="Basic mixed styles example"
              >
                <button
                  disabled={this.state.double_save}
                  type="button"
                  className="btn"
                  style={{
                    backgroundColor: "var(--main-bg-color)",
                    color: "white",
                    marginTop: "20px",
                    marginLeft: "5px",
                  }}
                  onClick={() => this.onFileUpload()}
                >
                  {this.state.loaderforupload && (
                    <span
                      className="spinner-grow spinner-grow-sm"
                      style={{ color: "unset", marginRight: "5px" }}
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  Validate = () => {
    if(this.state.location == "" ){
      alert("Please Enter the Location.")
      return
    }
   this.clickNavigation()
    
  }
  clickNavigation=()=>{
    const url = Urls.python_baseurl + "/SP_Save_LocalPartMaster";
    var data = JSON.stringify({

      "ID": this.state.id?`${this.state.id}`:"0",
      "Location":`'${this.state.location}'`,
      "DealerID":  `'${sessionStorage.getItem("dealerid")}'`,
      "GroupCode": "'01'",
      "LocalPartName": "''",
    });
        fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
           if(this.state.id){
            alert("Record Updated Scuccesfully")
           }else{
            alert("Record Saved Succesfully")
           }
          //  window.location.reload()
          //  this.MasterAPICall()
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  }
  render() {
    return (
      <div>
        <h4 className="heading_text">Part</h4><br></br>
        <div class="row justify-content-end ">
          <button
            type="submit"
            className="btn Opertion_btn clickable"
            onClick={this.handelAddNew}
          >
            Add New
          </button>
          <button
            type="submit"
            className="btn Opertion_btn clickable mr-5"
            onClick={this.Validate}
          >
            Save
          </button>
          {/* <button
            type="submit"
            className="btn Opertion_btn clickable mr-5"
            // onClick={this.openPopUp}
          >
            Upload
          </button> */}
        </div>
        <div class="form-group col-md-12 mt-2">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Part List
                </button>
              </h5>
            </div>
            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">{this.masterTable()}</div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Part Details
                </button>
              </h5>
            </div>

            <div
              id="collapseTwo"
              class="collapse show"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div class="card-body">{this.header()}</div>
            </div>
          </div>
        </div>
        {this.PopUP()}
      </div>
    );
  }
}
