import React from "react";
import "./index.css";
import Strings from "../../../helpers/Strings";
import { Link } from "react-router-dom";
import Urls from "../../../helpers/Urls";
import moment from "moment";

let isOutbound = JSON.parse(sessionStorage.getItem("isOutbound"));
export class DynamicSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: [],
      menuOptions: {},
    };
  }

  componentDidMount() {
    isOutbound =
      isOutbound === null ? sessionStorage.getItem("isOutbound") : isOutbound;
    this.getData();
  }

  // getData(isData) {

  //   let data = [
  //     {
  //       role_id: 2,
  //       menu_id: 1,
  //       menu_description: "Joy e-Bike Masters",
  //       sequence_id: 0,
  //       parent_node: 0,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "I",
  //       Groupid: 1,
  //       customer_id: "0",
  //     },
  //     // {
  //     //   role_id: 2,
  //     //   menu_id: 2,
  //     //   menu_description: "Model Category",
  //     //   sequence_id: 1,
  //     //   parent_node: 1,
  //     //   menuflag: "1",
  //     //   usermenuflag: 1,
  //     //   is_editor: "0",
  //     //   is_editable: "0",
  //     //   menutype: "B",
  //     //   Groupid: 1,
  //     //   customer_id: "0",
  //     // },
  //     {
  //       role_id: 2,
  //       menu_id: 3,
  //       menu_description: "Model Code",
  //       sequence_id: 2,
  //       parent_node: 1,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 1,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 4,
  //       menu_description: "Model Price",
  //       sequence_id: 3,
  //       parent_node: 1,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 1,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 5,
  //       menu_description: "Depot",
  //       sequence_id: 4,
  //       parent_node: 1,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 1,
  //       customer_id: "0",
  //     },
  //     // {
  //     //   role_id: 2,
  //     //   menu_id: 6,
  //     //   menu_description: "State",
  //     //   sequence_id: 5,
  //     //   parent_node: 1,
  //     //   menuflag: "1",
  //     //   usermenuflag: 1,
  //     //   is_editor: "0",
  //     //   is_editable: "0",
  //     //   menutype: "B",
  //     //   Groupid: 1,
  //     //   customer_id: "0",
  //     // },
  //     {
  //       role_id: 2,
  //       menu_id: 24,
  //       menu_description: "Chassis",
  //       sequence_id: 5,
  //       parent_node: 1,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 1,
  //       customer_id: "0",
  //     },

  //     {
  //       role_id: 2,
  //       menu_id: 7,
  //       menu_description: "Dealer Masters",
  //       sequence_id: 0,
  //       parent_node: 0,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "I",
  //       Groupid: 2,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 8,
  //       menu_description: "Employee",
  //       sequence_id: 1,
  //       parent_node: 7,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 2,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 9,
  //       menu_description: "Customer",
  //       sequence_id: 2,
  //       parent_node: 7,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 2,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 10,
  //       menu_description: "Vehicle Sale",
  //       sequence_id: 0,
  //       parent_node: 0,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 21,
  //       menu_description: "Sales Dashboard",
  //       sequence_id: 1,
  //       parent_node: 10,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 11,
  //       menu_description: "Enquiry",
  //       sequence_id: 2,
  //       parent_node: 10,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 12,
  //       menu_description: "Vehicle Booking",
  //       sequence_id: 2,
  //       parent_node: 10,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 13,
  //       menu_description: "Money Received",
  //       sequence_id: 3,
  //       parent_node: 10,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 14,
  //       menu_description: "Vehicle Invoice",
  //       sequence_id: 5,
  //       parent_node: 10,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 25,
  //       menu_description: "Reports",
  //       sequence_id: 6,
  //       parent_node: 10,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 15,
  //       menu_description: "Vehicle Purchase",
  //       sequence_id: 0,
  //       parent_node: 0,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 16,
  //       menu_description: "Dealer Sales Order (PO)",
  //       sequence_id: 1,
  //       parent_node: 15,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 17,
  //       menu_description: "Vehicle Receipt",
  //       sequence_id: 2,
  //       parent_node: 15,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 26,
  //       menu_description: "Reports",
  //       sequence_id: 3,
  //       parent_node: 15,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 18,
  //       menu_description: "Dealer To Dealer Sale",
  //       sequence_id: 0,
  //       parent_node: 0,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 19,
  //       menu_description: "Sales Invoice",
  //       sequence_id: 1,
  //       parent_node: 18,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 20,
  //       menu_description: "Vehicle Delivery Challan",
  //       sequence_id: 2,
  //       parent_node: 18,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 21,
  //       menu_description: "Service",
  //       sequence_id: 0,
  //       parent_node: 0,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 22,
  //       menu_description: "Job Card",
  //       sequence_id: 1,
  //       parent_node: 21,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     {
  //       role_id: 2,
  //       menu_id: 27,
  //       menu_description: "Reports",
  //       sequence_id: 1,
  //       parent_node: 21,
  //       menuflag: "1",
  //       usermenuflag: 1,
  //       is_editor: "0",
  //       is_editable: "0",
  //       menutype: "B",
  //       Groupid: 3,
  //       customer_id: "0",
  //     },
  //     // {
  //     //   role_id: 2,
  //     //   menu_id: 23,
  //     //   menu_description: "Job Card",
  //     //   sequence_id: 2,
  //     //   parent_node: 21,
  //     //   menuflag: "1",
  //     //   usermenuflag: 1,
  //     //   is_editor: "0",
  //     //   is_editable: "0",
  //     //   menutype: "B",
  //     //   Groupid: 3,
  //     //   customer_id: "0",
  //     // },
  //     // {
  //     //   role_id: 2,
  //     //   menu_id: 25,
  //     //   menu_description: "Report",
  //     //   sequence_id: 0,
  //     //   parent_node: 0,
  //     //   menuflag: "1",
  //     //   usermenuflag: 1,
  //     //   is_editor: "0",
  //     //   is_editable: "0",
  //     //   menutype: "B",
  //     //   Groupid: 3,
  //     //   customer_id: "0",
  //     // },
  //     // {
  //     //   role_id: 2,
  //     //   menu_id: 26,
  //     //   menu_description: "Stock Report",
  //     //   sequence_id: 1,
  //     //   parent_node: 25,
  //     //   menuflag: "1",
  //     //   usermenuflag: 1,
  //     //   is_editor: "0",
  //     //   is_editable: "0",
  //     //   menutype: "B",
  //     //   Groupid: 3,
  //     //   customer_id: "0",
  //     // },
  //   ];
  //   this.setState(
  //     {
  //       menuList: data,
  //     },
  //     () => {
  //       this.sortData();
  //     }
  //   );
  // }

  getData(isData) {
    let role_id = JSON.parse(sessionStorage.getItem("UserRole"));


    const url = Urls.python_baseurl + "/SP_UserRolewiseMenulist";
    var data = JSON.stringify({
      role_id: `${role_id}`,
      menutype: "'I'",
      For: "'S'",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          let _data = res[0];
          this.setState(
            {
              menuList: _data,
            },
            () => {
              this.sortData();
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  }
  getkeys(index) {
    let name = "";
    if (index == 2) {
      name = Strings.Model_Category;
    } else if (index == 3) {
      name = Strings.Model_Code;
    } else if (index == 4) {
      name = Strings.Model_Price;
    } else if (index == 5) {
      name = Strings.Plan_Depot;
    } else if (index == 6) {
      name = Strings.State;
    } else if (index == 8) {
      name = Strings.Employee;
    } else if (index == 9) {
      name = Strings.Customer;
    } else if (index == 11) {
      name = Strings.Confirmed_Enquiry;
    } else if (index == 12) {
      name = Strings.booking_list;
    } else if (index == 13) {
      name = Strings.Billling_list;
    } else if (index == 14) {
      name = Strings.vehical_invoice;
    } else if (index == 21) {
      name = Strings.Dashboard;
    } else if (index == 16) {
      name = Strings.Dealer_Po;
    } else if (index == 17) {
      name = Strings.Dealer_Vehicle_Receipt;
    } else if (index == 22) {
      name = Strings.JobCard_List;
    } else if (index == 23) {
      name = Strings.Chassis_details;
    } else if (index == 19) {
      name = Strings.Vehicle_Receipt_dealer_to_dealer;
    } else if (index == 20) {
      name = Strings.VehicleChallan;
    } else if (index == 24) {
      name = Strings.Chassis_Master;
    } else if (index == 26) {
      name = Strings.Vehicle_Purchase_Report;
    } else if (index == 25) {
      name = Strings.Vehicle_Sales_Report;
    } else if (index == 27) {
      name = Strings.Service_Report;
    }
    else if (index == 30) {
      name = Strings.Dealer_to_Dealer_purches;
    }
    else if (index == 31) {
      name = Strings.Supplier;
    }
    else if (index == 33) {
      name = Strings.UserList;
    }
    else if (index == 34) {
      name = Strings.MenuMaster;
    }
    else if (index == 35) {
      name = Strings.User_Report;
    }
    else if (index == 36) {
      name = Strings.Upload_Dealer_Data;
    }else if (index == 37) {
      name = Strings.DealerPoApproval;
    }
    else if (index == 38) {
      name = Strings.Scheme;
    }else if (index == 39) {
      name = Strings.Job_Card_Approval;
    }
    else if (index == 40) {
      name = Strings.PaymentApproval;
    }
    else if (index == 41) {
      name = Strings.Customer_Dealer_Po;
    }else if (index == 42) {
      name = Strings.UserUsagesReport;
    }

    return name;
  }

  sortData() {
    let data = this.state.menuList;
    let menuOptions = {};
    console.log(data, "kjkjjk");
    data.forEach((item) => {
      if (item.parent_node == 0) {
        menuOptions["Sb" + item.menu_id] = {
          name: "",
          array: [],
        };
        menuOptions["Sb" + item.menu_id].name = item.menu_description;
      } else if (item.parent_node == 40) {
        if (item.menu_description.includes("_Dashboard")) {
          item["key"] = Strings["Common_Dashboard"];
          menuOptions["Sb" + item.parent_node].array.push(item);
        } else if (item.menu_description.includes("_Details")) {
          item["key"] = Strings["Common_Details"];
          menuOptions["Sb" + item.parent_node].array.push(item);
        }
      } else {
        console.log(item, "kjkjjk");
        item["key"] = this.getkeys(item.menu_id);
        menuOptions["Sb" + item.parent_node].array.push(item);
      }
    });

    this.setState({
      menuOptions: menuOptions,
    });
  }

  renderItems(key, value, array) {
    let row = [];
    let data = [];
    array.forEach((element) => {
      let addon = "";
      if (element.key == Strings.Confirmed_Enquiry) {
        data = [
          {
            showTable: "m1",
            title: "Enquiry",
          },
        ];
      } else if (element.key == Strings.booking_list) {
        data = [
          {
            showTable: "m3",
            title: "Vehicle Booking",
          },
        ];
      } else if (element.key == Strings.Billling_list) {
        data = [
          {
            showTable: "m7",
            title: "Money Received",
          },
        ];
      } else if (element.key == Strings.vehical_invoice) {
        data = [
          {
            showTable: "m8",
            title: "Vehicle Invoice",
          },
        ];
      } else if (element.key == Strings.Upload_837_edi) {
        data = [{ flag: "837", subtitle: "Upload 837 EDI Files" }];
      } else if (element.key == Strings.Upload_835_edi) {
        data = [{ flag: "835", subtitle: "Upload 835 EDI Files" }];
      } else if (element.key == Strings.Upload_edi) {
        data = [{ flag: "" }];
      } else if (
        element.key == Strings._ElilgibilityDetails270 ||
        element.key == Strings.elilgibilityErrors270
      ) {
        let index_val = "n";
        if (element.key == Strings.elilgibilityErrors270) {
          index_val = "Fail";
        }
        data = [
          {
            apiflag: "1",
            State: "n",
            selectedTradingPartner: "n",
            startDate: "n",
            endDate: "n",
            transactionId: "n",
            status: index_val,
            count: "n",
          },
        ];
      } else if (
        element.key == Strings.ClaimDetails837 ||
        element.key == Strings._Claim_Details_837_Grid ||
        element.key == Strings.Inbound_EncounterDetails ||
        element.key == Strings._Load_Exception ||
        element.key == Strings.ClaimPayment_835_Exception
      ) {
        data = [
          {
            apiflag: "0",
            State: "n",
            selectedTradingPartner: "n",
            startDate: "n",
            endDate: "n",
            transactionId: "n",
            status: "n",
            count: "n",
            gridflag: "",
            subtitle: "",
          },
        ];
      } else if (element.key == Strings.Files_837) {
        data = [
          {
            flag: "n",
            selectedTradingPartner: "n",
            startDate: "n",
            endDate: "n",
          },
        ];
      } else if (element.key == Strings.claimsDashboard_834_details) {
        data = [{ Total: "total" }];
      } else if (element.key == Strings.EnrollmentError) {
        data = [{ Total: "error" }];
      } else if (element.key == Strings.OutboundPaymentDetails) {
        data = [{ Ininbound: "false" }];
      } else if (
        element.key == Strings.Outbound_Encounter_ClaimDetails837 ||
        element.key == Strings.Outbound_Encounter_BatchDetails837 ||
        element.key == Strings.Outbound_Claim_updated_Details_837_Grid_Kaiser ||
        element.key == Strings.Claim_Details_837 ||
        element.key == Strings._InboundPaymentDetails ||
        element.key === Strings._claimPayment_835_details ||
        element.key === Strings.PremiumPaymentLoadDetails ||
        element.key === Strings.MMRFileLoadDetails ||
        element.key == Strings.EncounterDetails ||
        element.key == Strings.Outbound_ClaimDetails837 ||
        element.key == Strings.Outbound_BatchDetails837 ||
        element.key == Strings.enrollmentLoadDetails ||
        element.key == Strings.Enrollment_Details_Outbound ||
        element.key == Strings._ElilgibilityDetails276 ||
        element.key == Strings.elilgibilityErrors276 ||
        element.key == Strings._277CAResponse
      ) {
        let index_val = "n";
        if (element.key == Strings.elilgibilityErrors276) {
          index_val = "Fail";
        }
        data = [
          {
            apiflag: "0",
            State: "n",
            selectedTradingPartner: "n",
            startDate: "n",
            endDate: "n",
            transactionId: "n",
            status: index_val,
            count: "n",
            Ininbound: "true",
          },
        ];
      } else if (element.key == Strings.serviceDetails278) {
        data = [{ TransStatus: "", ErrorCode: "" }];
      } else if (
        element.key == Strings.Outbound_Encounter_response_999 ||
        element.key == Strings.Outbound_Claim_999_response ||
        element.key == Strings._Outbound_response_999
      ) {
        data = [{ flag999: "1" }];
      } else if (
        element.key == Strings.Inbound_Encounter_response_999 ||
        element.key == Strings.Inbound_Claim_999_response ||
        element.key == Strings._Inbound_response_999
      ) {
        data = [{ flag999: "0" }];
      } else if (
        element.key == Strings.Enrollment_FullFileCompare ||
        element.key == Strings.Enrollment_FullFileCompare_Dashboard
      ) {
        data = [{ incoming_fileId: "834_UT_Audit.da" }];
      } else if (element.key == Strings.Enrollment_eligibiltyDetails) {
        data = [
          {
            status: "n",
            claimStatus: "n",
            inDHS: "Y",
            Audit: "n",
            Add: "n",
            flag: "Y",
            inQnxt: "n",
            MonthlyStatus: "n",
            incoming_fileId: "834_UT_Audit.da",
          },
        ];
      } else if (element.key == Strings.xml_mapper) {
        data = [
          {
            Title: "true",
          },
        ];
      } else if (element.key == Strings.Vehicle_Sales_Report) {
        data = {
          Title: "Vehicle Sales",
        };
      } else if (element.key == Strings.Vehicle_Purchase_Report) {
        data = {
          Title: "Vehicle Purchase",
        };
      } else if (element.key == Strings.Service_Report) {
        data = {
          Title: "Service",
        };
      }
      else if (element.key == Strings.User_Report) {
        data = {
          Title: "Admin",
        };
      }

      row.push(
        <li key={element.menu_description}>
          <Link
            to={{
              pathname: element.key
                ? "/" + (element.key ? element.key : "") + addon
                : "",
              state: { data },
            }}
          >
            {element.menu_description}
          </Link>
        </li>
      );
    });
    return (
      <li className="active" key={key}>
        <a
          href={"#" + key}
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          {value}
        </a>
        <ul
          className="collapse list-unstyled"
          id={key}
          onClick={this.Rightclickedstop}
        >
          {row}
        </ul>
      </li>
    );
  }

  Rightclickedstop = (event) => {
    if (event.ctrlKey && event.button == "0") {
      event.preventDefault();
    }
    const DefinitionCodeHeader = event.target.innerText;
    console.log(event.target.innerText, "event");
    sessionStorage.setItem("DefinitionCodeHeader", DefinitionCodeHeader);
    if (event.target.innerText.includes("_Dashboard")) {
      let DynamicTableName = event.target.innerText.replace("_Dashboard", "");
      sessionStorage.setItem("DynamicTableName", DynamicTableName);
      // window.location.href='/Common_Dashboard'
    } else if (event.target.innerText.includes("_Details")) {
      let DynamicTableName = event.target.innerText.replace("_Details", "");
      sessionStorage.setItem("DynamicTableName", DynamicTableName);
    }
  };

  renderSidebarItems() {
    let menuOptions = this.state.menuOptions;
    let row = [];
    for (let keys in menuOptions) {
      row.push(
        this.renderItems(keys, menuOptions[keys].name, menuOptions[keys].array)
      );
    }

    return <div>{row}</div>;
  }

  renderTabs() {
    return (
      <div id="tabs">
        <div className="row p">
          <div className="col-6 p nopadding">
            <a
              className="clickable"
              onClick={() => {
                isOutbound = false;
                sessionStorage.setItem("isOutbound", false);
                setTimeout(() => {
                  this.getData();
                }, 50);
              }}
            >
              {/* <p className="p smaller-font">Inbound</p>
                            {!isOutbound ? <hr className="underline p" /> : null} */}
            </a>
          </div>

          <div className="col-6 p nopadding">
            <a
              className="clickable"
              onClick={() => {
                isOutbound = true;
                sessionStorage.setItem("isOutbound", true);
                setTimeout(() => {
                  this.getData();
                }, 50);
              }}
            >
              {/* <p className="p smaller-font">Outbound</p>
                            {isOutbound ? <hr className="underline p" /> : null} */}
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderCopyright = () => {
    // fixed-bottom
    return (
      <div className="copyright">
        <br />© {moment().year()} Powered by DealerPaaS,
        <br /> All rights reserved.
      </div>
    );
  };

  render() {
    return (
      <nav
        id="sidebar"
        style={{ marginTop: "10px", zIndex: "10", paddingLeft: "10px" }}
      >
        <ul className="list-unstyled components">
          {this.renderTabs()}
          {this.renderSidebarItems()}
          {this.renderCopyright()}
        </ul>
      </nav>
    );
  }
}
