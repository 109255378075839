import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
import moment from "moment";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import * as XLSX from "xlsx";
const $ = window.$;
const bcrypt = require("bcryptjs");

export class Part_Catalogue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTable: true,
            showtable_1:false,
            paginationPageSize: 10,
            domLayout: "autoHeight",
            autoGroupColumnDef: {
                headerName: "Group",
                minWidth: 170,
                field: "athlete",
                valueGetter: function (params) {
                    if (params.node.group) {
                        return params.node.key;
                    } else {
                        return params.data[params.colDef.field];
                    }
                },
                headerCheckboxSelection: true,
                cellRenderer: "agGroupCellRenderer",
                cellRendererParams: { checkbox: true },
            },
            defaultColDef: {
                cellClass: "cell-wrap-text",
                autoHeight: true,
                sortable: true,
                resizable: true,
                filter: true,
            },
            rowSelection: "never",
            rowGroupPanelShow: "never",
            pivotPanelShow: "never",
            rowData: [],
            showerror: "",
            Aggrid_ClaimLineData: "",
            model_desc: "",
            Model_name: "",
            Model_name_option: [],
            Data:[],
            temparray: [],
            newurl: [],
        };
    }
    componentDidMount() {
        this.MasterAPICall();

    }
    onSelect = (event, name) => {
        this.setState({
            Active: event.target.options[event.target.selectedIndex].text,
        });
    };
    MasterAPICall = () => {
        const url = Urls.python_baseurl + "/SP_Get_ModelCatelogue";
        var data = JSON.stringify({
            "sDcodeOrRcode": "'D'"
        });
        fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                if (res && res.length > 0) {
                    // res=res[0]
                    this.setState({
                        rowData: res,
                        Model_name: res[0].Active,
                        model_desc: res[0]["Contact_No"],
                        // emp_type: res[0]["Employee Type"],
                        // emp_name: res[0]["Employee Name"],
                        // id: res[0].ID,
                    });
                }
            })
            .catch((err) => {
                process.env.NODE_ENV == "development" && console.log(err);
            });
    };
    marsterCatalogue = () => {
        const url = Urls.python_baseurl + "/SP_Get_ModelAggregate";
        var data = JSON.stringify({
            "Model_Cat_ID":`${this.state.id}`,
            // 
        });
        fetch(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            method: "POST",
            body: data,
        })
            .then((res) => res.json())
            .then((res) => {
                if (res && res.length > 0) {
                    // res=res[0]
                    this.setState({
                        Data: res,
                        
                    });
                }
            })
            .catch((err) => {
                process.env.NODE_ENV == "development" && console.log(err);
            });
    };

    masterTable() {
        let columnDefs = [
            { headerName: "Model Name", field: "Model_Name", flex: 1, cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },},
            {
                headerName: "Model Desc",
                field: "Model_desc",
                flex: 1,
                
            },

        ];

        return (
            <div>
                <div className="ag-theme-balham" style={{ padding: "0" }}>
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnDefs}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        defaultColDef={this.state.defaultColDef}
                        suppressRowClickSelection={true}
                        groupSelectsChildren={true}
                        debug={true}
                        rowSelection={this.state.rowSelection}
                        rowGroupPanelShow={this.state.rowGroupPanelShow}
                        pivotPanelShow={this.state.pivotPanelShow}
                        enableRangeSelection={true}
                        paginationAutoPageSize={false}
                        pagination={true}
                        domLayout={this.state.domLayout}
                        paginationPageSize={this.state.paginationPageSize}
                        onGridReady={this.onGridReady}
                        rowData={this.state.rowData}
                        enableCellTextSelection={true}
                        onCellClicked={(event) => {
                            if (event.colDef.field == "Model_Name") {
                                this.setState({
                                    // Model_name: event.data.Active,
                                    // model_desc: event.data["Contact_No"],
                                    Data:[],
                                    id: event.data.ID,
                                    showtable_1:true
                                },()=>{
                                    this.marsterCatalogue()
                                })
                            }
                        }
                        }
                    ></AgGridReact>
                </div>
            </div>
        );
    }
    masterTable_catalogue() {
        let columnDefs = [
            { headerName: "Function Group", field: "Agg_Name", flex: 1, },
            {
                headerName: "File Name",
                field: "File_Name",
                flex: 1,
                cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
            },

        ];

        return (
            <div>
                <div className="ag-theme-balham" style={{ padding: "0" }}>
                    <AgGridReact
                        modules={this.state.modules}
                        columnDefs={columnDefs}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        defaultColDef={this.state.defaultColDef}
                        suppressRowClickSelection={true}
                        groupSelectsChildren={true}
                        debug={true}
                        rowSelection={this.state.rowSelection}
                        rowGroupPanelShow={this.state.rowGroupPanelShow}
                        pivotPanelShow={this.state.pivotPanelShow}
                        enableRangeSelection={true}
                        paginationAutoPageSize={false}
                        pagination={true}
                        domLayout={this.state.domLayout}
                        paginationPageSize={this.state.paginationPageSize}
                        onGridReady={this.onGridReady}
                        rowData={this.state.Data}
                        enableCellTextSelection={true}
                        onCellClicked={(event) => {
                            if (event.colDef.field == "File_Name") {
                                this.setState({
                                    Model_name: event.data.Active,
                                    model_desc: event.data["Contact_No"],
                                    id: event.data.ID,
                                },()=>{
                                    if(event.data && event.data.Path){
                                    fetch(event.data.Path).then((res) => {
                                        console.log("🚀 ~ fetch ~ res:", res);
                                        if (res.status === 200) {
                                          this.state.temparray.push(res.url);
                                          let data = [{ uri: res.url }];
                                          // console.log("ngfgg",res.url);
                                          this.setState(
                                            {
                                              newurl: data,
                                              downloadLink: event.data.download_url,
                                              //newurl:this.state.temparray
                                            },
                                            () => {
                                              const link = document.createElement("a");
                                              link.href = this.state.downloadLink;
                                              link.setAttribute("download", ``);
                                              document.body.appendChild(link);
                      
                                              link.click();
                                              document.body.removeChild(link);
                                              $("#ExcelPopUP").modal("show");
                                            }
                                          );
                                        } else {
                                        }
                                      });
                                    }
                                })
                            }
                        }
                        }
                    ></AgGridReact>
                </div>
            </div>
        );
    }

    handledateChange = (event, key) => {
        this.setState({
            [key]: event.target.value,
        });
    };
    header = () => {
        return (

            <div className="row">


                <div className=" col-4">
                    <label htmlFor="model-c">Model Name</label>
                    <select value={this.state.Model_name} onChange={(e) => this.setState({ Model_name: e.target.value })} id="model-c" className=" form-control ">
                        <option value={"0"}>Select</option>
                        {this.state.Model_name_option && this.state.Model_name_option.map(e => <option value={e.Etype}>{e.Etype}</option>)}
                    </select>
                </div>

                <div className=" col-4">
                    <label htmlFor="model-c">Model Desc</label>
                    <input
                        autoComplete='off'
                        onChange={(e) => this.setState({ model_desc: e.target.value })}
                        value={this.state.model_desc}
                        id="model-c"
                        className=" form-control"
                    ></input>
                </div>

            </div>

        );
    };
   

    ExcelPOPUP = () => {
        return (
          <div
            style={{
              backgroundColor: "rgba(33, 37, 41, 0.8)",
            }}
            class="modal"
            id="ExcelPopUP"
          >
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    onClick={() => {this.setState({
                        newurl:[]
                      })
                      $("#ExcelPopUP").modal("hide")}}
                  >
                    ×
                  </button>
                </div>
                <br></br>
                <div class="container"></div>
                <div className="container_file">
                  <DocViewer
                    documents={this.state.newurl}
                    pluginRenderers={DocViewerRenderers}
                  />
                  {/* <img
                    className="mt-3"
                    src={this.state.downloadLink}
                    alt=""
                    width="100%"
                    height="500px"
                  /> */}
                </div>
              </div>
            </div>
          </div>
        );
      };
    render() {
        return (
            <div>
                <h4 className="heading_text">Part Catalogue</h4><br></br>
                {/* <div class="row justify-content-end ">
                    <button
                        type="submit"
                        className="btn Opertion_btn clickable"
                        onClick={this.addnew}
                    >
                        Clear
                    </button>
                    <button
                        type="submit"
                        className="btn Opertion_btn clickable mr-5"
                        onClick={this.Validate}
                    >
                        Save
                    </button>
                </div> */}
                <div
                    class="form-group col-md-12 mt-2"
                >

                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <h5 class="mb-0">
                                <button
                                    style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                    class="btn"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Part Catalogue List
                                </button>
                            </h5>
                        </div>

                        <div
                            id="collapseOne"
                            class="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                        >
                            <div class="card-body">{this.masterTable()}</div>
                        </div>
                    </div>
                   {this.state.showtable_1? <div class="card">
                        <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button
                                    style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                                    class="btn"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                >
                                    Part Catalogue Details
                                </button>
                            </h5>
                        </div>

                        <div
                            id="collapseTwo"
                            class="collapse show"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div class="card-body">{this.masterTable_catalogue()}</div>
                        </div>
                    </div>:null}

                </div>
               {this.state.newurl&&this.ExcelPOPUP()}
            </div>
        );
    }
}
