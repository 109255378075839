import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import "../Files/files-styles.css"
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";
const $ = window.$;
const bcrypt = require("bcryptjs");

export class Company_Master extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      rowData: [],
      showerror: "",
      Aggrid_ClaimLineData: "",
      Dealername: "",
      Dealer_Short_Name: "",
      Dealer_Code: "",
      Address: "",
      District: "",
      City: "",
      State: "",
      Region: "",
      Country: "",
      Depot:"",
      Mobile: "",
      Phone: "",
      Email: "",
      MD_Email:'',
      Dealer_cat:'',
      Dealer_origin:"",
      PAN_No: "",
      TIN_No: "",
      GSTIN: "",
      Active: "",

      rowData: [],
    };
  }
  componentDidMount() {
    this.MasterAPICall()
  }
  onSelect = (event, name) => {
    this.setState({
      Active: event.target.options[event.target.selectedIndex].text
    })
  }
  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/GetModelCategory";
    var data = JSON.stringify({
      "ID": "null"

    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res,
            // Active: res[0].Active,
            // Model_Category: res[0]["Model Category"],
          })
        }

      })
      .catch(err => {
        process.env.NODE_ENV == 'development' && console.log(err)
      });
  }

  masterTable() {
    let columnDefs = [
      { headerName: "Employee Name", field: "Model Category", flex: "1", cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" } },
      { headerName: "Contact No.", field: "Model Category", flex: "1", },
      { headerName: "Employee Type", field: "Model Category", flex: "1", },
      { headerName: "Dealer Name", field: "Model Category", flex: "1", },
      { headerName: "Active", field: "Active", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) =>
              this.setState({
                Active: event.data.Active,
                Model_Category: event.data["Model Category"],
              })
            }
          ></AgGridReact>
        </div>
      </div>
    );
  }

  addnew = () => {

    this.setState({
      Active: "",
      emp_name: "",
      emp_type: "",
      contact_no: "",
    })
    $('#payment_error_modal').modal('show')
  }
  tab() {
    return (
      <div style={{ marginLeft: '2px' }}>
        <nav>

          <button type="button" class="close close-btn" style={{ marginTop: '-2%' }} data-dismiss="modal" aria-label="Close" onClick={() => {
            this.close()
          }}><span class="" aria-hidden="true">&times;</span></button>
        </nav>

      </div>
    )
  }
  close = () => {
    this.setState({
      Type: "",
      Name: "",
      Address_1: "",
      Address_2: "",
      City: "",
      State: "",
      Region: "",
      Country: "",
      Mobile: "",
      Phone: "",
      Email: "",
      PAN_No: "",
      TIN_No: "",
      GSTIN: "",
      Active: "",
    }, () => { $('#payment_error_modal').modal('hide') })

  }
  onChangeName(event, key) {

    this.setState({
      [key]: event.target.value,
    });

  }
  renderMemberinfo() {

    return (
      <div>
        <div>
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Company Details
                </button>
              </h2>
            </div>

            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label>Dealer Name<span style={{ color: "red" }}>*</span></label>
                    <input value={this.state.Dealername} onChange={(e) => this.onChangeName(e, 'Dealername')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Dealer_Short_Name<span style={{ color: "red" }}>*</span></label>
                    <input value={this.state.Dealer_Short_Name} onChange={(e) => this.onChangeName(e, 'Dealer_Short_Name')} class="form-control" placeholder=""></input>

                  </div>
                  <div class="form-group col-md-3">
                    <label>Dealer_Code<span style={{ color: "red" }}>*</span></label>
                    <input value={this.state.Dealer_Code} onChange={(e) => this.onChangeName(e, 'Dealer_Code')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Address</label>
                    <input value={this.state.Address} onChange={(e) => this.onChangeName(e, 'Address')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>City</label>
                    <input value={this.state.City} onChange={(e) => this.onChangeName(e, 'City')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>District</label>
                    <input value={this.state.District} onChange={(e) => this.onChangeName(e, 'District')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>State<span style={{ color: "red" }}>*</span></label>
                    <input value={this.state.State} onChange={(e) => this.onChangeName(e, 'State')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Depot<span style={{ color: "red" }}>*</span></label>
                    <input value={this.state.Depot} onChange={(e) => this.onChangeName(e, 'Depot')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Dealer Region</label>
                    <input value={this.state.Region} onChange={(e) => this.onChangeName(e, 'Region')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Country</label>
                    <input value={this.state.Country} onChange={(e) => this.onChangeName(e, 'Country')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Mobileno<span style={{ color: "red" }}>*</span></label>
                    <input value={this.state.Mobileno} onChange={(e) => this.onChangeName(e, 'Mobileno')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Phone</label>
                    <input value={this.state.Phone} onChange={(e) => this.onChangeName(e, 'Phone')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Email</label>
                    <input value={this.state.Email} onChange={(e) => this.onChangeName(e, 'Email')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>MD Email</label>
                    <input value={this.state.MD_Email} onChange={(e) => this.onChangeName(e, 'MD_Email')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Dealer Category</label>
                    <input value={this.state.Dealer_cat} onChange={(e) => this.onChangeName(e, 'Dealer_cat')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>Dealer Origin</label>
                    <input value={this.state.Dealer_origin} onChange={(e) => this.onChangeName(e, 'Dealer_origin')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>PAN No</label>
                    <input value={this.state.PAN_No} onChange={(e) => this.onChangeName(e, 'PAN_No')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>TIN_No</label>
                    <input value={this.state.TIN_No} onChange={(e) => this.onChangeName(e, 'TIN_No')} class="form-control" placeholder=""></input>
                  </div>
                  <div class="form-group col-md-3">
                    <label>GSTN NO</label>
                    <input value={this.state.GSTIN} onChange={(e) => this.onChangeName(e, 'GSTIN')} class="form-control" placeholder=""></input>
                  </div>

                  <div className=" col-3">
                    <label htmlFor="active">Active</label>
                    <select className="form-control list-dashboard" id="Active"
                      value={this.state.Active}
                      onChange={(event) => {
                        this.onSelect(event, 'Active')
                      }}>
                      <option selected value=""></option>
                      <option value="Y">Y</option>
                      <option value="N">N</option>
                    </select>

                  </div>



                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }

  render() {
    return (
      <div>
        <h4 className="heading_text">Company Master</h4>
        <div class="row justify-content-end " style={{ padding: '0px' }}>
          <button type="submit" className="btn Opertion_btn clickable" onClick={this.addnew}>
            Add New
          </button>
          <button type="submit" className="btn Opertion_btn clickable mr-5" onClick={this.clickNavigation}>
            Save
          </button>
        </div>
        <div class="form-group col-md-12 mt-2">
          {this.renderMemberinfo()}
          <div class="card">
            <div class="card-header" id="headingtwo">
              <h5 class="mb-0">
                <button
                  style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                  class="btn"
                  data-toggle="collapse"
                  data-target="#collapsetwo"
                  aria-expanded="true"
                  aria-controls="collapsetwo"
                >
                  Supplier List
                </button>
              </h5>
            </div>

            <div
              id="collapsetwo"
              class="collapse show"
              aria-labelledby="headingtwo"
              data-parent="#accordion"
            >
              <div class="card-body">{this.masterTable()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
