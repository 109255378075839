import React from "react";
import "../../Files/files-styles.css";
// import '../Claims/Dashboard/styles.css'
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import DatePicker from "react-datepicker";
import moment from "moment";
import Urls from "../../../../helpers/Urls";
import { AgGridReact } from "ag-grid-react";
import { Tiles } from "../../../components/Tiles";
import Strings from "../../../../helpers/Strings";
// import { post } from 'axios';
import { Alert } from "../../../../helpers/getDetails";
import { Lead } from "../Lead";
import { Booking_M3 } from "../Booking_M3";
import { Enquiry_details } from "../Enquiry_details";
import { Money_reciept } from "../Money_reciept";
import { Vehical_billing } from "../Vehical_billing";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { ListFilter } from "../../../components/ListFilter";
const $ = window.$;
export class FollowUp_List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ActiveTab: sessionStorage.getItem("ActiveTab"),
      paginationPageSize: 10,
      overlayLoadingTemplate:
        '<div class="spinner-border" role="status"> <span class="sr-only">Loading...</span> </div>',
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 300,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      ethnicity_map: [],
      showTable:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].showTable
          ? props.location.state.data[0].showTable
          : "",
      Ethnicity: [],
      Title:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].title
          ? props.location.state.data[0].title
          : "",
      enquiry_no: "",
      enquiry_date: "",
      Source: "",
      Source_name: "",
      source_mobile: "",
      address1: "",
      address2: "",
      ext_mit_cust: "",
      pincode: "",
      State: "",
      District: "",
      primary_application: "",
      secondary_application: "",
      startDate: "",
      total_no_fleet: "",
      kam_customer: "",
      call_ticket_number: "",
      call_ticket_date: "",
      HiPaaS_ID: "",
      visit_details: "",
      discussion: "",
      next_visit_details: "",
      HiPaaS_ID: "",
      date: "",
      newdate: "",
      time_spent: "",
      Commitment: "",
      loaderforupload: false,
      redirect_page: "",
      tableData: [
        { id: 1, name: "Item 1", quantity: 0 },
        { id: 2, name: "Item 2", quantity: 0 },
        { id: 3, name: "Item 3", quantity: 0 },
      ],
      rowData: [],
      PendingrowData: [],
      flag: false,
      Booking_M3_Details: [],
      name: "",
      pendingflag: false,
      Enquiry_Props: {},
      pendingEvent: "",
      Booking_flag: false,
      Enq_flag: false,
      Booking_Savedflag: false,
      money_receipt: false,
      newmoney_recived: false,
      sqlfor:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].open_enquiry
          ? props.location.state.data[0].open_enquiry
          : "",
      dashboard_Click:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].dashboard_Click
          ? props.location.state.data[0].dashboard_Click
          : "",
      Title:
        props.location.state &&
        props.location.state.data[0] &&
        props.location.state.data[0].title
          ? props.location.state.data[0].title
          : "",
      TitleFromHistory: this.props?.location?.state?.Title
        ? this.props?.location?.state?.Title
        : "",
        dealerid:
        props.location.state &&
        props?.location?.state?.data &&
        props.location.state.data[0] &&
        props.location.state.data[0].dealerid
          ? props.location.state.data[0].dealerid
          : sessionStorage.getItem("dealerid"),
          showtimerange:
        props.location.state &&
        props.location.state.data &&
        props.location.state.data[0] &&
        props.location.state.data[0].showtimernagefilter
          && props.location.state.data[0].showtimernagefilter
          ,
    };
  }

  componentDidMount() {
    this.MasterAPICall();
    if (this.state.TitleFromHistory) {
      this.setState({
        Title: this.state.TitleFromHistory,
      });
    }
    if (this?.props?.location?.state?.id) {
      let FromHistory = localStorage.getItem("FromHistory");
      if (this?.props?.location?.state?.id && FromHistory === "true") {
        this.setState(
          {
            name: this.props.location.state.customer_name,
            id: this.props.location.state.id,
            Enq_flag: true,
            Enquiry_Status: this.props.location.state.Enquiry_Status,
          },
          () => {
            localStorage.setItem("FromHistory", false);
            this.displayEnquiry(this.props.location.state.id);
          }
        );
      }
    }
  }
  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("storage", this.handleStorageChange);
  }
  componentDidUpdate() {
    if (this.state.ActiveTab != sessionStorage.getItem("ActiveTab")) {
      window.location.reload();
    }
  }
  clickNavigation = (event) => {
    this.props.history.push("/Enquiry", {});
  };
  MasterAPICall = (obj) => {
    const url = Urls.python_baseurl + "/SP_VehicleSalesDetails";
    var data = JSON.stringify({
      Dealer_ID: `'${this.state.dealerid}'`,
      SqlFor: "'Followup'",
      startdate: "''",
      enddate: "''",
      Dashboard_For: `'Followup'`,
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          const isNullish = Object.values(res[0][0]).every((value) => {
            if (value === null || value === "") {
              return true;
            }
            return false;
          });
          if (isNullish) {
            this.setState({
              rowData: [],
            });
          } else {
            this.setState({
              rowData: res[0],
            });
          }
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  displayEnquiry = (id) => {
    const url = Urls.python_baseurl + "/Display_Enquiry_SP_GetM1";
    var data = JSON.stringify({
      POId: id,
      POType: "'all'",
      DealerID: sessionStorage.getItem("dealerid"),
      HOBrID: sessionStorage.getItem("dealerid"),
      iM0ID: "1",
    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0 && res[0].length > 0) {
          this.setState(
            {
              EnquiryDetails: res,
              flag: true,
            },
            () => {
              $("#payment_error_modal_2").modal("show");
            }
          );
        }
      })
      .catch((err) => {
        process.env.NODE_ENV == "development" && console.log(err);
      });
  };
  displayVehicleInvoice = () => {
    $("#payment_error_modal_2").modal("show");
  };
  cellColored2(params) {
    console.log("params", params);
    const Backgroundcolor = {};
    if (
      params.colDef.headerName === "Booking No" &&
      params.data.Booking_No === null &&
      params.data.M3Confirm === null &&
      params.data.M3 === null
    ) {
      return { color: "orange", ...Backgroundcolor, cursor: "pointer" };
    } else {
      return {
        color: "var(--main-bg-color)",
        ...Backgroundcolor,
        cursor: "pointer",
      };
    }
  }
  cellColored3(params) {
    console.log("params", params);
    const Backgroundcolor = {};
    if (
      params.colDef.headerName === "Money Receipt No" &&
      params.data.MoneyReceipt_No === null &&
      params.data.M7Confirm === null &&
      params.data.M3Confirm === "Y" &&
      params.data.Booking_No != null &&
      params.data.M3 != null &&
      params.data.M7 === null
    ) {
      return { color: "orange", ...Backgroundcolor, cursor: "pointer" };
    } else {
      return {
        color: "var(--main-bg-color)",
        ...Backgroundcolor,
        cursor: "pointer",
      };
    }
  }
  masterTable2() {
    let columnDefs =[
          { headerName: "Dealer Name", field: "Dealer_Name", width: "140px" },
          { headerName: "Dealer Code", field: "DealerCode", width: "120px" },
          {
            headerName: "Enquiry No",
            field: "Inq_no",
            width: "150px",
            cellStyle: sessionStorage.getItem("UserRole")=="1"?{ color: "var(--main-bg-color)", cursor: "pointer" }:{},
          },
          { headerName: "Enquiry Date", field: "Inq_date", width: "140px" },
          {
            headerName: "Name",
            field: "name",
            width: "140px",
          },
          (sessionStorage.getItem("UserRole") == "1")||(sessionStorage.getItem("UserRole") == "6")
          ?{ headerName: "Mobile No.", field: "mobile", width: "130px" }:{hide:true},
          // { headerName: "Mobile", field: "mobile", width: "120px" },

          // { headerName: "Model", field: "model_name",flex:1 },
          { headerName: "Status", field: "Enquiry_Status", width: "100px" },

          {
            headerName: "Booking No",
            field: "Booking_No",
            width: "160px",
            // cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
            cellRenderer: (data) => {
              if (
                data.data.Booking_No === null &&
                data.data.M3Confirm === null &&
                data.data.M3 === null
              ) {
                return "Create Booking";
              } else {
                return data.data.Booking_No;
              }
            },
            cellStyle: this.cellColored2,
          },
          { headerName: "Booking Date", field: "Booking_Date", width: "120px" },
          {
            headerName: "Money Receipt No",
            field: "MoneyReceipt_No",
            width: "148px",
            cellRenderer: (data) => {
              if (
                data.data.MoneyReceipt_No === null &&
                data.data.M7Confirm === null &&
                data.data.M3Confirm === "Y" &&
                data.data.Booking_No != null &&
                data.data.M3 != null &&
                data.data.M7 === null
              ) {
                return "Create Money Receipt";
              } else {
                return data.data.MoneyReceipt_No;
              }
            },
            cellStyle: this.cellColored3,
          },
          {
            headerName: "Money Receipt Date",
            field: "MoneyReceipt_Date",
            width: "155px",
          },
          // {
          //   headerName: "Allocation Status",
          //   field: "Allocation Status",
          //   width: "155px",
          // },
        ]
    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) => {
              console.log("event12", event.data);
              if (sessionStorage.getItem("UserRole") == "1") {
                if (event.colDef.field == "Inq_no") {
                  console.log(event.data);
                  this.setState(
                    {
                      name: event.data.Name,
                      id: event.data.M1,
                      Enq_flag: true,
                      Enquiry_Status: event.data.Enquiry_Status,
                    },
                    () => {
                      this.displayEnquiry(event.data.M1);
                    }
                  );
                } else if (
                  event.colDef.field == "Booking_No" &&
                  event.data.Booking_No === null &&
                  event.data.M3Confirm === null &&
                  event.data.M3 === null
                ) {
                  this.setState(
                    {
                      pendingEvent3: event.data,
                      flag: true,
                      Booking_flag: true,
                    },
                    () => {
                      $("#payment_error_modal_2").modal("show");
                    }
                  );
                } else if (event.colDef.field == "Booking_No") {
                  this.setState(
                    {
                      id: event.data.M3,
                      flag: true,
                      bookingFlag: true,
                      Booking_Savedflag: true,
                    },
                    () => {
                      $("#payment_error_modal").modal("hide");
                      $("#payment_error_modal_2").modal("show");
                    }
                  );
                } else if (
                  event.colDef.field == "MoneyReceipt_No" &&
                  event.data.M7 != null
                ) {
                  this.setState(
                    {
                      id: event.data.M7,
                      flag: true,
                      money_receipt: true,
                      Status: event.data.M7Confirm,
                      // pendingEvent7: event.data,
                    },
                    () => {
                      // this.displayBookingM3(event.data.ID);
                      $("#payment_error_modal_2").modal("show");
                    }
                  );
                } else if (
                  event.colDef.field == "MoneyReceipt_No" &&
                  event.data.MoneyReceipt_No === null &&
                  event.data.M7 === null &&
                  event.data.M3Confirm === "Y" &&
                  event.data.M3 != null
                ) {
                  this.setState(
                    {
                      id: event.data.M3,
                      flag: true,
                      newmoney_recived: true,
                      pendingEvent7: event.data,
                    },
                    () => {
                      console.log("idy666", this.state.id);
                      $("#payment_error_modal_2").modal("show");
                    }
                  );
                }
              }
            }}
          ></AgGridReact>
        </div>
      </div>
    );
  }
  close() {
    return (
      <div style={{ marginLeft: "2px" }}>
        <nav>
          <button
            type="button"
            class="close close-btn"
            style={{ marginTop: "-2%" }}
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              this.close();
            }}
          >
            <span class="" aria-hidden="true">
              &times;
            </span>
          </button>
        </nav>
      </div>
    );
  }
  errorDialog = () => {
    return (
      <div class="modal" id="payment_error_modal">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div style={{ marginTop: "-20px" }} class="modal-body">
              {this.masterTable()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  displayPopUp = () => {
    return (
      <div class="modal" id="payment_error_modal_2">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => {
                  this.setState({
                    flag: false,
                    id: 0,
                    EnquiryDetails: [],
                    Enq_flag: false,
                    Booking_Savedflag: false,
                    money_receipt: false,
                    Booking_flag: false,
                    newmoney_recived: false,
                    // pendingEvent: [],
                    // Customer_Name: "",
                  });
                }}
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.state.Enq_flag ? (
                <Enquiry_details
                  customer_name={this.state.name}
                  modelid={"saveClcik"}
                  Enquiry_Status={this.state.Enquiry_Status}
                  EnquiryDetails={this.state.EnquiryDetails}
                  id={this.state.id}
                  history={this.props.history}
                  Title={this.state.Title}
                />
              ) : null}
              {this.state.Booking_flag ? (
                <Booking_M3
                  modelid={"pendingClick"}
                  pendingClick3={this.state.pendingEvent3}
                  booking={false}
                />
              ) : null}
              {this.state.Booking_Savedflag ? (
                <Booking_M3
                  modelid={"saveClcik"}
                  id={this.state.id}
                  booking={true}
                  vehicle={"enquiry"}
                />
              ) : null}
              {this.state.money_receipt ? (
                <Money_reciept
                  modelid={"saveClcik"}
                  pendingClick7={this.state.pendingEvent7}
                  pendingClick={{ ID: this.state.MoneyReceiptID }}
                  ReceiptDetails={this.state.ReceiptDetails}
                  Status={this.state.Status}
                  ID={this.state.id}
                ></Money_reciept>
              ) : null}
              {this.state.newmoney_recived ? (
                <Money_reciept
                  modelid={"pendingClick"}
                  pendingClick7={this.state.pendingEvent7}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  dispalyPending = () => {
    return (
      <div class="modal" id="payment_error_modal_3">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={() => {
                  this.setState({
                    pendingflag: false,
                    pendingEvent: [],
                    Customer_Name: "",
                  });
                }}
              >
                ×
              </button>
            </div>
            <div class="container"></div>
            <div class="modal-body lift-up-model-body">
              {this.state.redirect_page === "M1" && (
                <Enquiry_details
                  modelid={"pendingClick"}
                  pendingClick={this.state.pendingEvent}
                />
              )}
              {this.state.redirect_page === "M3" && (
                <Booking_M3
                  modelid={"pendingClick"}
                  pendingClick3={this.state.pendingEvent3}
                  booking={false}
                ></Booking_M3>
              )}
              {this.state.redirect_page === "M7" && (
                <Money_reciept
                  modelid={"pendingClick"}
                  pendingClick7={this.state.pendingEvent7}
                ></Money_reciept>
              )}
              {this.state.redirect_page === "M8" && (
                <Vehical_billing
                  modelid={"pendingClick"}
                  pendingClick={this.state.pendingEvent8}
                ></Vehical_billing>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  handelLead() {
    this.setState(
      {
        id: 0,
        EnquiryDetails: [],
        flag: true,
        Enq_flag: true,
        Enquiry_Status: "",
      },
      () => {
        $("#payment_error_modal_2").modal("show");
      }
    );
  }
  refreshScreen = (startDate, endDate, dealerid) => {
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        dealerid: dealerid,
      },
      () => {
        this.MasterAPICall();
      }
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text" style={{ marginLeft: "0px" }}>
          {this.state.Title}
        </h4>
        <br></br>
        <br></br>
        {sessionStorage.getItem("UserRole") == "1" && (
          <div
            class="form-group col-md-12"
            style={{ padding: "0px", marginLeft: "-10px " }}
          >
            <button
              onClick={() => this.handelLead()}
              type="submit"
              className="btn Opertion_btn clickable"
              style={{ fontSize: "12px", float: "right" }}
            >
              Add New
            </button>
          </div>
        )}
        <br></br>
        <ListFilter
            refreshScreen={this.refreshScreen}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dealerid={this.state.dealerid}
            showtimerange={this.state.showtimerange}
          />
        {this.masterTable2()}
        {this.state.flag == true && this.displayPopUp()}
        {this.state.pendingflag == true && this.dispalyPending()}
      </div>
    );
  }
}
