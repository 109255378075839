import React from "react";
import Urls from "../../../helpers/Urls";
import "../color.css";
import Strings from "../../../helpers/Strings";
import moment from "moment";
import HiPaaSLogo from "../../components/Images/HiPaaS_logo.png";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-balham.css";

const bcrypt = require("bcryptjs");

export class Model_Price extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Model_Code: "",
      Model_Name: "",
      Dealer_Code: "",
      Dealer: "",
      From_Date: "",
      MRP: "",
      HSN_Code: "",
      Active: "",
      showTable: true,
      paginationPageSize: 5,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 170,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      showerror: "",
      Aggrid_ClaimLineData: "",
      rowData: [],
      showCompanyMaster1: false
    };
  }

  componentDidMount() {
    this.MasterAPICall()
  }

  MasterAPICall = () => {
    const url = Urls.python_baseurl + "/SP_GetDataToFillSelecionGrid";
    var data = JSON.stringify({
      "FromDate": "''",
      "ToDate": "''",
      "ModelPart": "D",
      "DealerId": 0,
      "SqlFor": "ModelPrice",
      "HOBrID": "0"

    });
    fetch(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res && res.length > 0) {
          this.setState({
            rowData: res,
            Model_Code: res[0]["Model Code"],
                Model_Name: res[0]["Model Name"],
                From_Date: res[0]["From Date"],
                HSN_Code: res[0]["HSN_Code"] ? res[0]["HSN_Code"] : "",
                Active: res[0].Active,
                Model_Price_History: res[0]["Model Price History"],
                Ex_ShowroomPrice:res[0].Ex_ShowroomPrice,
                To_Date:res[0]["To Date"]
            
          })
        }

      })
      .catch(err => {
        process.env.NODE_ENV == 'development' && console.log(err)
      });
  }


  masterTable() {
    let columnDefs = [
      {
        headerName: "Model Code",
        field: "Model Code",
        flex: "1",
        cellStyle: { color: "var(--main-bg-color)", cursor: "pointer" },
      },
      { headerName: "Model Name", field: "Model Name", flex: "1" },
      { headerName: "HSN Code", field: "HSN_Code", flex: "1" },
      { headerName: "Ex_Showroom Price", field: "Ex_ShowroomPrice", flex: "1" },
      { headerName: "From Date", field: "From Date", flex: "1" },
      { headerName: "To Date", field: "To Date", flex: "1" },
      { headerName: "Model Price History", field: "Model Price History", flex: "1" },
      { headerName: "Active", field: "Active", flex: "1" },
    ];

    return (
      <div>
        <div className="ag-theme-balham" style={{ padding: "0" }}>
          <AgGridReact
            modules={this.state.modules}
            columnDefs={columnDefs}
            autoGroupColumnDef={this.state.autoGroupColumnDef}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            groupSelectsChildren={true}
            debug={true}
            rowSelection={this.state.rowSelection}
            rowGroupPanelShow={this.state.rowGroupPanelShow}
            pivotPanelShow={this.state.pivotPanelShow}
            enableRangeSelection={true}
            paginationAutoPageSize={false}
            pagination={true}
            domLayout={this.state.domLayout}
            paginationPageSize={this.state.paginationPageSize}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            enableCellTextSelection={true}
            onCellClicked={(event) =>{
              if(event.colDef.field=="Model Code"){
                this.setState({
                  Model_Code: event.data["Model Code"],
                  Model_Name: event.data["Model Name"],
                  From_Date: event.data["From Date"],
                  HSN_Code: event.data["HSN_Code"] ? event.data["HSN_Code"] : "",
                  Active: event.data.Active,
                  Model_Price_History: event.data["Model Price History"],
                  Ex_ShowroomPrice:event.data.Ex_ShowroomPrice,
                  To_Date:event.data["To Date"]
                })
              }
            }   
            }
          ></AgGridReact>
        </div>
      </div>
    );
  }
  header = () => {
    return (
      <div>
        <div className="row">
          <div className=" col-3">
            <label htmlFor="Model_Code">Model Code</label>
            <input
              value={this.state.Model_Code}
              id="Model_Code"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Model_Name">Model Name</label>
            <input
              value={this.state.Model_Name}
              id="Model_Name"
              className=" form-control "
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="HSN_Code">HSN Code</label>
            <input
              value={this.state.HSN_Code}
              id="HSN_Code"
              className=" form-control "
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Ex_ShowroomPrice">Ex-Showroom Price
</label>
            <input
              value={this.state.Ex_ShowroomPrice}
              id="Ex_ShowroomPrice"
              className=" form-control "
              readOnly
            ></input>
          </div>
          {/* <div className=" col-3">
            <label htmlFor="state_code">State Code</label>
            <input
              value={this.state.state_code}
              id="Dealerstate_code_Code"
              className=" form-control "
              readOnly
            ></input>
          </div> */}
        {/* <div className=" col-3">
            <label htmlFor="Dealer_Code">Dealer Code</label>
            <input
              value={this.state.Dealer_Code}
              id="Dealer_Code"
              className=" form-control "
              readOnly
            ></input>
          </div>
        
        </div>
                  */}
                  </div>
        <div className="row mt-2"> 

          <div className=" col-3">
            <label htmlFor="From_Date">From Date</label>
            <input
              value={this.state.From_Date}
              id="From_Date"
              className=" form-control"
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="To_Date">To Date</label>
            <input
              value={this.state.To_Date}
              id="To_Date"
              className=" form-control "
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Model_Price_History">Model Price History</label>
            <input
              value={this.state.Model_Price_History}
              id="Model_Price_History"
              className=" form-control "
              readOnly
            ></input>
          </div>
          <div className=" col-3">
            <label htmlFor="Active">Active</label>
            <input
              value={this.state.Active}
              id="Active"
              className=" form-control "
              readOnly
            ></input>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div>
        <h4 className="heading_text">Model Price</h4>
        {/* <div class="card mt-4">
          <div class="card-header" id="headingOne">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn "
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Location Details
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
          >
            <div class="card-body"></div>
          </div>
        </div> */}
        <div class="card mt-4">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                Model Price Details
              </button>
            </h5>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
          >
            <div class="card-body">{this.masterTable()}</div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseThree"
                aria-expanded="true"
                aria-controls="collapseThree"
              >
                Model Price Header Details
              </button>
            </h5>
          </div>
          <div
            id="collapseThree"
            class="collapse show "
            aria-labelledby="headingThree"
          >
            <div class="card-body">{this.header()}</div>
          </div>
        </div>
        {/* <div class="card">
          <div class="card-header" id="headingFour">
            <h5 class="mb-0">
              <button
                style={{ color: "var(--main-bg-color)", fontWeight: "500" }}
                class="btn  collapsed"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="true"
                aria-controls="collapseFour"
              >
                Lubricant Price History
              </button>
            </h5>
          </div>
          <div
            id="collapseFour"
            class="collapse show "
            aria-labelledby="headingFour"
          >
            <div class="card-body"></div>
          </div>
        </div> */}
      </div>
    );
  }
}
