import React from "react";
import moment from "moment";
import "../Dealer_To_Dealer_Tax_Invoice/index.css";
import Logo from "../../../components/Images/Logo.png";
import "../../../containers/color.css";
import Urls from "../../../../helpers/Urls";
import {
  setDomLayoutAfterPrint,
  setDomLayoutForPrint,
} from "../../../../helpers/getDetails";
const $ = window.$;
export class Quotation_Report extends React.Component {
  constructor(props) {
    console.log(props, "hjjhjh");
    super(props);
    this.state = {
      showTable: true,
      paginationPageSize: 10,
      domLayout: "autoHeight",
      autoGroupColumnDef: {
        headerName: "Group",
        minWidth: 100,
        field: "athlete",
        valueGetter: function (params) {
          if (params.node.group) {
            return params.node.key;
          } else {
            return params.data[params.colDef.field];
          }
        },
        headerCheckboxSelection: true,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: { checkbox: true },
      },
      defaultColDef: {
        cellClass: "cell-wrap-text",
        autoHeight: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "never",
      rowGroupPanelShow: "never",
      pivotPanelShow: "never",
      EnquiryDetails: null,
      conditioncode: props?.location?.state?.data[5]
        ? props?.location?.state?.data[5]
        : [],
    };
  }
  componentDidMount() {
    if (this?.props?.location?.state?.data) {
      this.setState(
        {
          EnquiryDetails: this.props.location.state.data,
        },
        () => {
          // this.state.conditioncode=this.state.EnquiryDetails[5]
          console.log("hhhh", this.state.EnquiryDetails);
          var tempTitle = document.title;
          document.title = "QUO-"+moment().year()+"-"+this.state.EnquiryDetails[0][0].Quotation_Number;
          window.print();
          document.title = tempTitle;
          // window.print();
          localStorage.setItem("FromHistory", true);
          this.props.history.push({
            pathname: "/EnquiryList",
            state: {
              data: this.props.location.state.data,
              id: this.props.location.state.id,
              name: this.props.location.state.customer_name,
              Title: this.props.location.state.Title,
              Enquiry_Status: this.props.location.state.Enquiry_Status,
            },
          });
        }
      );
    }
  }
  convertToWord = (num) => {
    if (!num) return;
    let splittedNum = num.toString().split(".");
    let nonDecimal = splittedNum[0];
    let decimal = splittedNum[1];
    let TotalAmountWithTaxWord =
      this.price_in_words(Number(nonDecimal)) +
      (decimal ? " Rupees and" : " Rupees Only") +
      this.price_in_words(Number(decimal)) +
      (decimal ? "Paise Only" : "");
    return TotalAmountWithTaxWord;
  };
  price_in_words = (price) => {
    var sglDigit = [
        "Zero",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt
          ? ""
          : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (
          (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];

    // Check if the price is a valid number
    if (((price += ""), isNaN(parseFloat(price)))) str = "";
    else if (parseFloat(price) > 0 && price.length <= 15) {
      // Adjusted to handle larger numbers including decimal places
      var priceParts = price.split("."); // Split the price into integer and fractional parts
      var integerPart = priceParts[0];
      var fractionalPart = priceParts[1] || "0"; // If there is no fractional part, default to '0'

      // Process integer part
      for (digitIdx = integerPart.length - 1; digitIdx >= 0; digitIdx--) {
        digit = integerPart[digitIdx] - 0;
        nxtDigit = digitIdx > 0 ? integerPart[digitIdx - 1] - 0 : 0;
        switch (integerPart.length - digitIdx - 1) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != integerPart[digitIdx + 1] &&
                    0 != integerPart[digitIdx + 2]
                      ? " and"
                      : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, integerPart[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 != digit
                ? " " +
                    sglDigit[digit] +
                    " Hundred" +
                    (0 != integerPart[digitIdx + 1] ||
                    0 != integerPart[digitIdx + 2]
                      ? " and"
                      : " Crore")
                : ""
            );
        }
      }

      str = words.reverse().join("");
    } else str = "";
    return str;
  };
  Invoice = () => {
    return (
      <div>
        <h4 className=" invoice-header heading_text">Quotation</h4>
        <div>
          <table className="invoice-container">
            <tbody>
              <tr>
                <div className=" pl-2">
                  <p className=" bold">
                    {sessionStorage.getItem("dealer_name")}
                  </p>
                  <p>{sessionStorage.getItem("Dealer_Address")}</p>
                  <p>
                    <span className=" bold">Phone</span> :{" "}
                    {sessionStorage.getItem("Dealer_Contact")}
                  </p>
                  <p>
                    <span className=" bold">Email</span> :{" "}
                    {sessionStorage.getItem("Dealer_Email")}
                  </p>
                  <p>
                    <span className=" bold">GST No.</span> :{" "}
                    {sessionStorage.getItem("Dealer_Gst_No")}
                  </p>
                </div>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>
                  <div className="bold">
                    {this.state?.EnquiryDetails?.[4]?.[0]?.name}
                  </div>
                  <div>
                    {" "}
                    {this.state?.EnquiryDetails?.[4]?.[0]?.add1},{" "}
                    {this.state?.EnquiryDetails?.[4]?.[0]?.CITY},{" "}
                    {this.state?.EnquiryDetails?.[4]?.[0]?.distict_name},{" "}
                    {this.state?.EnquiryDetails?.[4]?.[0]?.state}-
                    {this.state?.EnquiryDetails?.[4]?.[0]?.PIN}
                  </div>
                  <div>
                    <span className="bold">Mobile No:</span>
                    <span> {this.state?.EnquiryDetails?.[4]?.[0]?.mobile}</span>
                  </div>
                  {this.state?.EnquiryDetails?.[4]?.[0]?.E_mail ? (
                    <div>
                      <span className="bold">Email:</span>
                      <span>
                        {" "}
                        {this.state?.EnquiryDetails?.[4]?.[0]?.E_mail}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  <div>
                    <span className="bold">Quotation No.:</span>
                    <span>
                      {" "}
                      {this.state?.EnquiryDetails?.[0]?.[0]?.Quotation_Number}
                    </span>
                  </div>
                  <div>
                    <span className="bold">Quotation Date:</span>
                    <span>
                      {" "}
                      {this.state?.EnquiryDetails?.[0]?.[0]?.Quotation_Date
                        ? moment(
                            this.state?.EnquiryDetails?.[0]?.[0]?.Quotation_Date
                          ).format("DD-MM-YYYY")
                        : ""}
                    </span>
                  </div>
                  <div>
                    <span className="bold">Enquiry No.:</span>
                    <span>
                      {" "}
                      {this.state?.EnquiryDetails?.[0]?.[0]?.Lead_inq_no}
                    </span>
                  </div>
                  <div>
                    <span className="bold">Enquiry Date:</span>
                    <span>
                      {" "}
                      {this.state?.EnquiryDetails?.[0]?.[0]?.Lead_Date
                        ? moment(
                            this.state?.EnquiryDetails?.[0]?.[0]?.Lead_Date
                          ).format("DD-MM-YYYY")
                        : ""}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="bold">Vehicle Information</td>
                <td className="row justify-content-center border-0 ">
                  <b>Quotation Amount</b>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "top" }}>
                  <div>
                    <div className="mt-1 row px-3">
                      <div className="bold quotation_name">
                        Model <span> : </span>
                      </div>
                      <div className="itemvalue">
                        {" "}
                        {
                          this.state?.EnquiryDetails?.[0]?.[0]
                            ?.model_gr_description
                        }
                      </div>
                    </div>
                    <div className="mt-1 row px-3">
                      <div className="bold quotation_name">
                        Model Code <span> : </span>
                      </div>
                      <div className="itemvalue">
                        {" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.model_code}
                      </div>
                    </div>
                    <div className="mt-1  row px-3">
                      <div className="bold quotation_name">
                        <span>Model Name</span> <span> : </span>
                      </div>
                      <div className="itemvalue">
                        {this.state?.EnquiryDetails?.[0]?.[0]?.model_name}
                      </div>
                    </div>
                    <div className="mt-1 row px-3">
                      <div className="bold quotation_name">
                        <span>Colour</span> <span> : </span>
                      </div>
                      <div className="itemvalue">
                        {" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.model_Colour}
                      </div>
                    </div>
                    <div className="mt-1 row px-3">
                      <div className="bold quotation_name">
                        <span>Quantity</span> <span> : </span>
                      </div>
                      <div className="itemvalue">
                        {" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.Qty}
                      </div>
                    </div>
                    {this.state?.EnquiryDetails?.[0]?.[0]?.Scheme_Amt!=0&&<div style={{border:"1px solid #ddd", padding:"5px", marginTop:"10px",fontFamily:"mogra"}}>
                    <div style={{fontWeight:"600"}} className="mt-1 row px-3">
                      We are pleased to inform you that you are Entitled to get following:
                    </div>
                    <div className="mt-1 row px-3">
                      <div style={{fontWeight:"600"}}>
                        <span><span className=" text-danger ">*</span> Discount</span> <span> : </span> <span>₹{" "}{this.state?.EnquiryDetails?.[0]?.[0]?.Scheme_Amt} (on Ex-showroom Price)</span>
                      </div>
                    </div>
                    <div>
                      {!this.state?.EnquiryDetails?.[0]?.[0]?.insurance_amount&&<div style={{fontWeight:"600"}}><span className=" text-danger ">*</span> Discount on Insurance (FREE INSURANCE)</div>}
                      <div style={{fontWeight:"600"}}>
                        Please note that the scheme is for limited period only. The Scheme is valid till date: {this.state?.EnquiryDetails?.[0]?.[0]?.Scheme_End_date!=""&&moment(this.state?.EnquiryDetails?.[0]?.[0]?.Scheme_End_date).format("DD-MM-YYYY")}
                      </div>
                    </div>
                    </div>}
                  </div>
                </td>
                <td>
                  <div>
                    <div className=" row  justify-content-between u-border">
                      <div className="bold quotation_name1">Particular</div>
                      <div style={{ fontWeight: "600" }}>Amount</div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">
                        Ex-Showroom Price
                      </div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.MRP
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.MRP.toFixed(2)
                          : 0}
                      </div>
                    </div>
                   {this.state?.EnquiryDetails?.[0]?.[0]?.Scheme_Name? <div className="row  justify-content-between u-border ">
                      <div className="bold quotation_name1">{this.state?.EnquiryDetails?.[0]?.[0]?.Scheme_Name}</div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.Scheme_Amt
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.Scheme_Amt.toFixed(
                              2
                            )
                          : 0}
                      </div>
                    </div>
                    :""}
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">
                        Dealer Proposed Discount
                      </div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]
                          ?.dealer_propsed_discount
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.dealer_propsed_discount.toFixed(
                              2
                            )
                          : 0}
                      </div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">
                        State Subsidy Amount
                      </div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.subsidiary_amount
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.subsidiary_amount.toFixed(
                              2
                            )
                          : 0}
                      </div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">
                        Central Subsidy Amount
                      </div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]
                          ?.centarl_subsidy_amount
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.centarl_subsidy_amount.toFixed(
                              2
                            )
                          : 0}
                      </div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">RTO Amount</div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.RTO_Amount
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.RTO_Amount.toFixed(
                              2
                            )
                          : 0}
                      </div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">
                        RTO Discount Amount
                      </div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]
                          ?.RTO_discount_amount
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.RTO_discount_amount.toFixed(
                              2
                            )
                          : 0}
                      </div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">CRTM Amount</div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.CRTM_amount
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.CRTM_amount.toFixed(
                              2
                            )
                          : 0}
                      </div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">HPA Charges</div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.HPA_Change
                          ? parseFloat(this.state?.EnquiryDetails?.[0]?.[0]?.HPA_Change)?.toFixed(2)
                          : 0}
                      </div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">
                        Smart Card Amount
                      </div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.Smart_card_amount
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.Smart_card_amount.toFixed(
                              2
                            )
                          : 0}
                      </div>
                    </div>

                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">HSRP</div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.HSRP_Charge
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.HSRP_Charge.toFixed(
                              2
                            )
                          : 0}
                      </div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">
                        Other RTO Charges
                      </div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.Other_RTO
                          ? this.state?.EnquiryDetails?.[0]?.[0]?.Other_RTO.toFixed(
                              2
                            )
                          : 0}
                      </div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">
                        Insurance Amount
                      </div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]?.insurance_amount
                          ? parseFloat(
                              this.state?.EnquiryDetails?.[0]?.[0]
                                ?.insurance_amount
                            ).toFixed(2)
                          : 0}
                      </div>
                    </div>
                    <div className="row  justify-content-between u-border">
                      <div className="bold quotation_name1">
                        Standard Accessories Amount
                      </div>
                      <div className="itemvalue2">
                        {" "}
                        ₹{" "}
                        {this.state?.EnquiryDetails?.[0]?.[0]
                          ?.Standard_Accesories
                          ? parseFloat(
                              this.state?.EnquiryDetails?.[0]?.[0]
                                ?.Standard_Accesories
                            ).toFixed(2)
                          : 0}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className=" bold">Total</td>
                <td>
                  <div className=" row px-3 justify-content-end ">
                    ₹{" "}
                    {this.state?.EnquiryDetails?.[0]?.[0]?.Grand_Total
                      ? this.state?.EnquiryDetails?.[0]?.[0]?.Grand_Total.toFixed(
                          2
                        )
                      : 0}
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td colSpan={2} className="bold">
                  {this.convertToWord(
                    parseFloat(
                      this.state?.EnquiryDetails?.[0]?.[0]?.Grand_Total
                        ? this.state?.EnquiryDetails?.[0]?.[0]?.Grand_Total.toFixed(
                            2
                          )
                        : 0
                    )
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          {this.state.conditioncode[0]?.Acc_id != null && (
            <div className="invoice-container" style={{ width: "100%" }}>
              {/* <thead> */}
              <tr style={{ width: "100%" }}>
                <td className="bold_new">Accessories Name</td>
                <td className="bold_new">Accessories Amount</td>
                <td className="bold_new">HSN</td>
                <td className="bold_new">IGST</td>
                <td className="bold_new">IGST Amount</td>
                <td className="bold_new">SGST</td>
                <td className="bold_new">SGST Amount</td>
                <td className="bold_new">CSGT</td>
                <td className="bold_new">CSGT Amount</td>
                <td className="bold_new">Accessories Total With Tax</td>
              </tr>
              {/* </thead> */}
              <tbody style={{ textAlign: "end", fontWeight: "600" }}>
                {console.log(
                  "this.state.conditioncode",
                  this.state.conditioncode
                )}
                {this.state.conditioncode.map((e) => {
                  console.log("vgfg", e);
                  return (
                    <tr>
                      <td style={{ textAlign: "start" }}>
                        {e?.Accesories_Name}
                      </td>
                      <td style={{ textAlign: "start" }}>
                      ₹ {e?.Accessories_Amount}
                      </td>
                      <td> {e?.HSN}</td>
                      <td style={{ width: "92px" }}>{e?.IGST}%</td>
                      <td style={{ width: "92px" }}>
                        ₹ {e?.IGST_Amount?.toFixed(2)}
                      </td>
                      <td style={{ width: "92px" }}>{e?.SGST}%</td>
                      <td style={{ width: "92px" }}>
                        ₹ {e?.SGST_Amount?.toFixed(2)}
                      </td>
                      <td style={{ width: "92px" }}>{e?.CSGT}%</td>
                      <td style={{ width: "92px" }}>
                        ₹ {e?.CSGT_Amount?.toFixed(2)}
                      </td>
                      <td>
                        ₹ {e?.Total_With_Tax && e?.Total_With_Tax.toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </div>
          )}
          <div className="invoice-container" style={{ padding: "0px" }}>
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <div>
                      <div>
                        <div>
                          for,{" "}
                          <span className=" bold">
                            {sessionStorage.getItem("dealer_name")}
                          </span>
                        </div>
                        <div className="mt-5">AUTHORISED SIGINATORY</div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="invoice-container">
            <p style={{ marginLeft: "-12px" }} className=" bold">
              Terms & Conditions
            </p>
            <ol>
              <li>
                All disputes are subjected to the jurisdiction of the courts of
                the law at{" "}
                <span className=" bold">
                  {sessionStorage.getItem("Dealer_District")}
                </span>{" "}
                only.
              </li>
              <li>E & OE</li>
              <li>
                Above Mention Price/Quote are applicable as on date and may vary
                at the time of Sale/Purchase
              </li>
              <li>
                Product specifiction are as above are applicable as on date and
                may vary at the time of Sale/Purchase
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div style={{display:"flex", justifyContent:"center"}}>
        <body id="printArea">
          <div className="page-header" style={{ textAlign: "center" }}></div>
          <div className="page-footer" style={{ textAlign: "center" }}></div>
          <table className=" border-0 ">
            <thead className=" border-0 ">
              <tr className=" border-0 ">
                <td className=" border-0 ">
                  <div className="page-header-space"></div>
                </td>
              </tr>
            </thead>
            <tbody className=" border-0 ">
              <tr className=" border-0 ">
                <td className=" border-0 ">
                  <div className="page">{this.Invoice()}</div>
                </td>
              </tr>
            </tbody>

            <tfoot className=" border-0 ">
              <tr className=" border-0 ">
                <td className=" border-0 ">
                  <div className="page-footer-space"></div>
                </td>
              </tr>
            </tfoot>
          </table>
        </body>
      </div>
    );
  }
}
